import React, {MouseEvent} from 'react';
import {
  Icon,
  Grid,
  Button,
  Hidden, CircularProgress, ButtonProps
} from "@material-ui/core";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {ConfigMenuItem} from "../../../types";
import _ from 'lodash';

type Props = {
  next?: ConfigMenuItem,
  prev?: ConfigMenuItem,
  onNextClick?: (e:MouseEvent) => void,
  nextButtonProps?: { loading?: boolean } & ButtonProps
}

class LoanApplicationControls extends React.Component<Props> {
  onNextClick = (e:MouseEvent) => {
    if(this.props.onNextClick) {
      this.props.onNextClick(e);
    }
  };

  render() {
    const { next, prev, onNextClick, nextButtonProps, ...rest } = this.props;
    const muiNextButtonProps = _.omit(nextButtonProps, ['loading']);
    return (
      <div {...rest}>
        <Grid container justifyContent={prev && next ? 'space-between' : !prev && next ? 'flex-end' : 'flex-start'}>
          {prev &&
            <Grid item>
              <Hidden smUp>
                <Button component={Link}
                        to={prev.to}
                        startIcon={<Icon>arrow_back</Icon>}>
                  <FormattedMessage id="previous" />
                </Button>
              </Hidden>
              <Hidden xsDown>
                <Button variant="contained"
                        component={Link}
                        to={prev.to}
                        startIcon={<Icon>arrow_back</Icon>}>
                  <FormattedMessage id={prev.labelId} />
                </Button>
              </Hidden>
            </Grid>
          }
          {next &&
            <Grid item>
              <Hidden smUp>
                <Button onClick={this.onNextClick}
                        disabled={next.disabled}
                        endIcon={nextButtonProps && nextButtonProps.loading ? <CircularProgress size={12} color="inherit" /> : <Icon>arrow_forward</Icon>}
                        {...muiNextButtonProps}>
                  <FormattedMessage id="next" />
                </Button>
              </Hidden>
              <Hidden xsDown>
                <Button variant="contained"
                        color="primary"
                        onClick={this.onNextClick}
                        disabled={next.disabled}
                        endIcon={nextButtonProps && nextButtonProps.loading ? <CircularProgress size={12} color="inherit" /> : <Icon>arrow_forward</Icon>}
                        {...muiNextButtonProps}>
                  <FormattedMessage id={next.labelId} />
                </Button>
              </Hidden>
            </Grid>
          }
        </Grid>
      </div>
    );
  }
}

export default LoanApplicationControls;
