import React from 'react';
import {
  Typography,
  Icon
} from '@material-ui/core';
import {useStyles} from '../theme/jss/components/noResultsTextStyles';
import clsx from "clsx";

type Props = {
  children?: React.ReactNode,
  icon?: React.ReactNode,
  message: string,
  className?: string
}

const NoResultsCard = (props:Props) => {
  const { children, icon, message, className } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {icon ? icon : <Icon className={classes.icon}>report_problem</Icon>}
      <div>
        {message &&
          <Typography className={classes.message}>
            {message}
          </Typography>
        }
        {children &&
        <div>
          {children}
        </div>
        }
      </div>
    </div>
  );
}

export default NoResultsCard;
