import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
  icon: {
    fontSize: '18px'
  },
  item: {
    display: 'flex',
    alignItems: 'center'
  },
  label: {

  }
});

export default styles;
