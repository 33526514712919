import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {
  SecureMessageSearchRequest, SecureMessageThread,
  SecureMessageThreadAttachmentRequest,
  SecureMessageThreadRequest,
  SecureMessageThreadUpdateRequest
} from "@jerseydev/orca-loans";

export function getSecureMessageThreads(queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getSecureMessageThreads(queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessageThreads = state.secureMessageThreads && state.secureMessageThreads.data ? _.cloneDeep(state.secureMessageThreads.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.FETCHING, secureMessageThreads));

      apiRequest.then(response => {
        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getSecureMessageThread(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getSecureMessageThread(id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessageThreads = state.secureMessageThreads && state.secureMessageThreads.data ? _.cloneDeep(state.secureMessageThreads.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.FETCHING, _.cloneDeep(secureMessageThreads)));

      apiRequest.then(response => {
        const index = secureMessageThreads.findIndex(s => s._id === id);
        if(index > -1) {
          secureMessageThreads.push(response.data);
        } else {
          secureMessageThreads[index] = response.data;
        }
        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function searchSecureMessageThreads(criteria:SecureMessageSearchRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.searchSecureMessageThreads(criteria, queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessageThreads = state.secureMessageThreads && state.secureMessageThreads.data ? _.cloneDeep(state.secureMessageThreads.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.FETCHING, secureMessageThreads));

      apiRequest.then(response => {
        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addSecureMessageThread(secureMessage:SecureMessageThreadRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createSecureMessageThread(secureMessage, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessageThreads = state.secureMessageThreads && state.secureMessageThreads.data ? _.cloneDeep(state.secureMessageThreads.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.ADDING, _.cloneDeep(secureMessageThreads)));

      apiRequest.then(response => {
        //secureMessageThreads.unshift(response.data);
        //dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateSecureMessageThread(id:string, secureMessage:SecureMessageThreadUpdateRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateSecureMessageThread(id, secureMessage, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessageThreads = state.secureMessageThreads && state.secureMessageThreads.data ? _.cloneDeep(state.secureMessageThreads.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.UPDATING, _.cloneDeep(secureMessageThreads)));

      apiRequest.then(response => {
        const index = secureMessageThreads.findIndex(s => s._id === id);
        if(index === -1) {
          secureMessageThreads.push(response.data);
        } else {
          secureMessageThreads[index] = response.data;
        }

        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function readAllSecureMessagesInThread(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.readAllSecureMessagesInThread(id, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessages = state.secureMessages && state.secureMessages.data ? _.cloneDeep(state.secureMessages.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.UPDATING, _.cloneDeep(secureMessages)));

      apiRequest.then(response => {
        response.data.forEach(updatedSecureMessage => {
          const index = secureMessages.findIndex(s => s._id === updatedSecureMessage._id);
          if(index === -1) {
            secureMessages.push(updatedSecureMessage);
          } else {
            secureMessages[index] = updatedSecureMessage;
          }
        });

        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addSecureMessageAttachment(id:string, attachment:SecureMessageThreadAttachmentRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createSecureMessageAttachment(id, attachment, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessageThreads = state.secureMessageThreads && state.secureMessageThreads.data ? _.cloneDeep(state.secureMessageThreads.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.UPDATING, _.cloneDeep(secureMessageThreads)));

      apiRequest.then(response => {
        const secureMessageIndex = secureMessageThreads.findIndex(m => m._id === id);
        if(secureMessageThreads[secureMessageIndex] && secureMessageThreads[secureMessageIndex].attachments) {
          secureMessageThreads![secureMessageIndex].attachments!.unshift(response.data);
          dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        }
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteSecureMessageAttachment(secureMessageId:string, attachmentId:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteSecureMessageAttachment(secureMessageId, attachmentId, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessageThreads = state.secureMessageThreads && state.secureMessageThreads.data ? _.cloneDeep(state.secureMessageThreads.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.UPDATING, _.cloneDeep(secureMessageThreads)));

      apiRequest.then(response => {
        const secureMessageIndex = secureMessageThreads.findIndex(m => m._id === secureMessageId);
        if(secureMessageThreads[secureMessageIndex] && secureMessageThreads[secureMessageIndex].attachments) {
          const attachmentIndex = secureMessageThreads![secureMessageIndex].attachments!.findIndex(a => a._id === attachmentId);
          secureMessageThreads![secureMessageIndex].attachments!.splice(attachmentIndex, 1);
        }
        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateReduxSecureMessageThread(secureMessageThread:SecureMessageThread):ThunkAction<void, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const state = getState();
    const secureMessageThreads = state.secureMessageThreads && state.secureMessageThreads.data ? _.cloneDeep(state.secureMessageThreads.data) : [];
    const index = secureMessageThreads.findIndex(d => d._id === secureMessageThread._id);

    if(index === -1) {
      secureMessageThreads.unshift(secureMessageThread);
    } else {
      secureMessageThreads[index] = secureMessageThread;
    }
    dispatch(setData(types.SET_SECURE_MESSAGE_THREADS, ReduxAsyncOperation.IDLE, secureMessageThreads));
  };
}