import React, {Component} from 'react';
import {Grid, Typography, Icon, withStyles, IconButton, WithStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import Page from '../../../layouts/Page';
import {ResetPasswordForm} from '../forms';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {AppLogo, Snackbar} from "../../../components/index";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {authenticate} from "../../../actions/auth";
import {Redirect} from "react-router-dom";
import clsx from "clsx";
import {Mixpanel} from "mixpanel-browser";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ActionResponse, ReduxUser} from "../../../types";
import {ReduxUtil} from "../../../utils";
import {ReduxState} from "../../../data/initialState";

type Props = {
  mixpanel: Mixpanel,
  authenticate: (email:string, password:string) => ActionResponse,
  user: ReduxUser
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  loading: boolean,
  snackbarOpen: boolean,
  redirectTo?: string
}

class ResetPasswordPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      snackbarOpen: false
    };
  }

  componentDidMount = () => {
    const { user } = this.props;
    if(ReduxUtil.hasData(user)) {
      this.setState({ redirectTo: '/account' });
    }
  };

  onSubmit = async (data:{email:string, password:string}) => {
    try {
      await this.props.authenticate(data.email, data.password).send();
      this.setState({ loading: false, redirectTo: '/dashboard' });
    } catch (e) {
      this.setState({ loading: false, snackbarOpen: true });
    }
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { intl, classes, mixpanel } = this.props;
    const { loading, snackbarOpen, redirectTo } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo}/>
      )
    }

    return (
      <Page pageTitle={IntlFormatter.formatMessage(intl, 'reset_password')}
            loading={loading}>
        <Snackbar open={snackbarOpen}
                  variant="danger"
                  onClose={this.onSnackbarClose}
                  message={IntlFormatter.formatMessage(intl, 'general_error_message')}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />
        <Grid container>
          <Grid item md={6}>
            <div className={classes.houseBg1}>
              <div className={classes.bgOverlay}>

              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className={classes.p4}>
              <div className={clsx(classes.mb3, classes.center)}>
                <AppLogo color="dark" />
              </div>
              <div className={clsx(classes.mt3, classes.textCenter)}>
                <div className={classes.mb2}>
                  <Typography variant="h2" color="inherit">
                    <FormattedMessage id="reset_password" />
                  </Typography>
                </div>
                <Typography variant="subtitle1">
                  <FormattedMessage id="reset_password_instructions" />
                </Typography>
              </div>

              <div className={classes.p3}>
                <div className={classes.mb2}>
                  <ResetPasswordForm mixpanel={mixpanel}
                                     onSubmit={this.onSubmit} />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  authenticate(email:string, password:string) {
    return dispatch(authenticate(email, password))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(ResetPasswordPage)));
