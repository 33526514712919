import React, {ChangeEvent, MouseEvent} from 'react';
import {Link as RouterLink, RouteComponentProps, withRouter} from 'react-router-dom';
import {Form} from '../../../forms';
import {Grid, InputAdornment, Icon, Typography, Link, withStyles, WithStyles} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, TextField, PasswordField} from "../../../components/index";
import translations, {Translations} from '../../../intl/translations/index';
import {connect} from "react-redux";
import {updateIntl} from "react-intl-redux";
import {authenticate} from '../../../actions/auth';
import _ from 'lodash';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import queryString from "query-string";
import {GoogleReCaptchaProvider, GoogleReCaptcha} from "react-google-recaptcha-v3";
import clsx from "clsx";
import {ErrorUtil} from "../../../utils";
import Api from '../../../lib/Api';
import * as Sentry from "@sentry/browser";
import {ActionResponse, IntlLang, ErrorState} from "../../../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {AxiosResponse} from "axios";
import {TokenResponse} from "@jerseydev/orca-loans";

type Props = {
  onSubmit?: (data:AxiosResponse<TokenResponse & {email:string, password:string}>) => void,
  authenticate: (email:string, password:string, data?:any) => ActionResponse,
  switchLocale: (locale:string, messages:any) => void
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps
  & RouteComponentProps

type Form = {
  email: string,
  password: string,
  clientEmail?: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class LoginForm extends BaseForm<Props, State> {
  languages:IntlLang[] = [];
  reCaptchaScore:number = 1;

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        email: '',
        password: ''
      },
      errors: [] 
    };

    for(let key in translations) {
      if(translations.hasOwnProperty(key)) {
        this.languages.push(translations[key as keyof Translations]);
      }
    }
  }

  componentDidMount = async () => {
    const queryParams = queryString.parse(this.props.location.search);
    if(Object.keys(queryParams).length > 0) {
      const form = _.clone(this.state.form);
      if(queryParams.email) {
        form.email = decodeURIComponent(queryParams.email as string);
      }
      if(queryParams.sa) {
        form.clientEmail = '';
      }
      this.setState({ form });
    }

  };

  languageChange = (event:ChangeEvent, index:number, value:string) => {
    this.props.switchLocale(value, translations[value as keyof Translations].messages)
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const { email, password, clientEmail } = this.state.form;
      let data = null;
      if(clientEmail !== undefined) {
        data = { impersonate: clientEmail };
      }

      const result = await this.props.authenticate(email, password, data).send();
      this.setState({ loading: false }, () => {
        if(this.props.onSubmit) {
          result.data.email = email;
          result.data.password = password;
          this.props.onSubmit(_.cloneDeep(result));
        }
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onVerify = async (token:string) => {
    try {
      await Api.verifyRecaptchaToken({ token });
    } catch (e) {
      Sentry.withScope(() => {
        Sentry.captureException(e);
      });
    }
  };

  render() {

    const { intl, classes } = this.props;
    const { loading, form, errors } = this.state;

    return (
      <div>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
          <Form onSubmit={this.onSubmit}>
            <ErrorList errors={errors}
                       className={classes.mv2}
                       onClose={() => { this.setState({ errors: [] }); } } />
            <div className={classes.mv2}>
              <TextField name="email"
                         label={IntlFormatter.formatMessage(intl, 'email')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.email')}
                         value={form.email}
                         fullWidth={true}
                         inputProps={{autoCapitalize: 'none'}}
                         InputProps={{
                           startAdornment: (
                             <InputAdornment position="start">
                               <Icon>email</Icon>
                             </InputAdornment>
                           ),
                         }}
                         validators={['required', 'isEmail']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
            </div>
            <PasswordField name="password"
                           label={IntlFormatter.formatMessage(intl, 'password')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.password')}
                           value={form.password}
                           fullWidth={true}
                           InputProps={{
                             startAdornment: (
                               <InputAdornment position="start">
                                 <Icon>lock</Icon>
                               </InputAdornment>
                             ),
                           }}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />

            {form.clientEmail !== undefined &&
              <div className={classes.mt2}>
                <TextField name="clientEmail"
                           label={IntlFormatter.formatMessage(intl, 'client_email')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.clientEmail')}
                           value={form.clientEmail}
                           fullWidth={true}
                           InputProps={{
                             startAdornment: (
                               <InputAdornment position="start">
                                 <Icon>forward_to_inbox</Icon>
                               </InputAdornment>
                             ),
                           }}
                           validators={['required', 'isEmail']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
              </div>
            }

            <Typography variant="body1">
              <Link component={RouterLink} to="/forgot-password">
                <FormattedMessage id="forgot_password" />
              </Link>
            </Typography>

            <div>
              <GoogleReCaptcha onVerify={this.onVerify} />
            </div>

            <div className={clsx(classes.textCenter, classes.mt2)}>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={9} md={6}>
                  <SubmitButton loading={loading} fullWidth={true} size="large">
                    <FormattedMessage id="sign_in"/>
                  </SubmitButton>
                </Grid>
              </Grid>
            </div>
          </Form>
        </GoogleReCaptchaProvider>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  authenticate(email:string, password:string, data?:any) {
    return dispatch(authenticate(email, password, data))
  },
  switchLocale(locale:string, messages:any) {
    dispatch(updateIntl({
      locale,
      messages
    }))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(withRouter(injectIntl(LoginForm))));
