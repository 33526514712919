import React, {Component} from 'react';
import {Grid, Typography, Icon, withStyles, IconButton, WithStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import Page from '../../../layouts/Page';
import ForgotPasswordForm, {ForgotPasswordSubmitData} from '../forms/ForgotPasswordForm';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {AppLogo, Snackbar} from "../../../components/index";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import clsx from "clsx";
import {Mixpanel} from "mixpanel-browser";
import {Redirect} from "react-router-dom";
import {ReduxUser, SnackbarState} from "../../../types";
import _ from "lodash";
import {ReduxUtil} from "../../../utils";
import {ReduxState} from "../../../data/initialState";

type Props = {
  mixpanel: Mixpanel,
  user: ReduxUser
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  loading: boolean,
  snackbar: SnackbarState,
  redirect?: {
    to: string,
    search?: string
  }
}

class ForgotPasswordPage extends Component<Props, State>  {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      snackbar: {
        open: false,
        message: '',
        variant: 'success'
      }
    };
  }

  componentDidMount = () => {
    const { user } = this.props;
    if(ReduxUtil.hasData(user)) {
      this.setState({ redirect: {to: '/account'} });
    }
  };

  onSubmit = (data:ForgotPasswordSubmitData) => {
    const {intl} = this.props;
    const snackbar = _.clone(this.state.snackbar);
    snackbar.open = true;
    if(data.transport === 'email') {
      snackbar.message = IntlFormatter.formatMessage(intl, 'check_email_for_reset_link');
    } else {
      snackbar.message = IntlFormatter.formatMessage(intl, 'check_sms_for_reset_code');
    }
    this.setState({snackbar,
      redirect: {
        to:`/reset-password`,
        search: `?email=${encodeURIComponent(data.email)}`
      }
    });
  };

  onSnackbarClose = () => {
    const snackbar = _.clone(this.state.snackbar);
    snackbar.open = false;
    this.setState({ snackbar });
  };

  render() {
    const { intl, classes, mixpanel } = this.props;
    const { loading, snackbar, redirect } = this.state;

    if (redirect) {
      return <Redirect to={{
        pathname: redirect.to,
        search: redirect.search,
        state: snackbar.open ? { snackbar } : undefined }}  />
    }

    return (
      <Page pageTitle={IntlFormatter.formatMessage(intl, 'forgot_password')}
            loading={loading}>
        <Snackbar open={snackbar.open}
                  variant={snackbar.variant}
                  onClose={this.onSnackbarClose}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />
        <Grid container>
          <Grid item md={6}>
            <div className={classes.houseBg1}>
              <div className={classes.bgOverlay}>

              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className={classes.p4}>
              <div className={clsx(classes.mb3, classes.center)}>
                <AppLogo color="dark"/>
              </div>
              <div className={clsx(classes.mt3, classes.textCenter)}>
                <Typography variant="h2" color="inherit">
                  <FormattedMessage id="forgot_password" />
                </Typography>
              </div>

              <div className={classes.p3}>
                <div className={classes.mb2}>
                  <Typography variant="subtitle1">
                    <FormattedMessage id="forgot_password_instructions" />
                  </Typography>
                </div>
                <ForgotPasswordForm mixpanel={mixpanel}
                                    onSubmit={this.onSubmit} />
              </div>
            </div>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(ForgotPasswordPage)));
