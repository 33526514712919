import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  helpIcon: {
    color: theme.palette.neutral.light
  }
});

export default styles;
