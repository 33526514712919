import React, {ChangeEvent, MouseEvent} from 'react';
import Form, {SelectValidator} from '../../../forms/Form';
import {
  Grid,
  withStyles,
  MenuItem, Button, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, TextField} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {updatePricingIntegration} from "../../../actions/integrations";
import {AccountUtil, ErrorUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ReduxApp, ActionProps, ErrorState, ReduxAccount} from "../../../types";
import {AxiosResponse} from "axios";
import {AccountRequest, PricingIntegration, PricingIntegrationRequest} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateAccount} from "../../../actions/account";
import {IntegrationHelpAlert} from "../components";
import clsx from "clsx";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  settings?: PricingIntegration,
  onSubmit: (data:AxiosResponse<PricingIntegration>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  account: ReduxAccount,
  updatePricingIntegration: ActionProps["updatePricingIntegration"],
  updateAccount: ActionProps["updateAccount"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  provider: PricingIntegration["provider"]|null,
  data: {
    businessChannelId: string,
    originatorId: string,
    clientId: string,
    clientSecret: string,
    resource: string,
    minPar: string,
    maxPar: string
  }
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

type FormMap = {
  optimalBlue: () => void
}

class PricingSettingsForm extends BaseForm<Props, State> {
  formMap:FormMap;

  constructor(props:Props) {
    super(props);

    let form:Form = {
      provider: props.app.data.enums.pricingProviders.length === 1 ? props.app.data.enums.pricingProviders[0] as PricingIntegration["provider"] : null,
      data: {
        businessChannelId: '',
        originatorId: '',
        resource: '',
        clientId: '',
        clientSecret: '',
        minPar: '',
        maxPar: ''
      }
    }

    if(props.settings) {
      const {provider, data} = props.settings;
      const {businessChannelId, clientId, clientSecret, maxPar, minPar, originatorId, resource} = data;
      form = {
        provider,
        data: {
          businessChannelId,
          originatorId: originatorId ? originatorId : '',
          resource,
          clientId,
          clientSecret,
          minPar: minPar ? minPar.toString() : '',
          maxPar: maxPar ? maxPar.toString() : ''
        }
      }
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    this.formMap = {
      optimalBlue: this.renderOptimalBlueFields
    };
  }

  onProviderChange = (event:ChangeEvent<{value:PricingIntegration["provider"]}>) => {
    const form = _.cloneDeep(this.state.form);
    form.provider = event.target.value;
    this.setState({ form });
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const {provider, data} = this.state.form;
      const {businessChannelId, clientId, clientSecret, maxPar, minPar, originatorId, resource} = data;
      const requestData:PricingIntegrationRequest = {
        provider: provider!,
        data: {
          businessChannelId: businessChannelId.trim(),
          originatorId: originatorId.trim(),
          resource: resource.trim(),
          clientId: clientId.trim(),
          clientSecret: clientSecret.trim(),
          minPar: minPar !== '' ? parseInt(minPar) : null,
          maxPar: maxPar !== '' ? parseInt(maxPar) : null
        }
      };

      const result = await this.props.updatePricingIntegration(requestData).send();
      this.props.mixpanel.track(this.props.settings ? 'Pricing integration updated' : 'Pricing integration created');

      if(!AccountUtil.isSetupRecommendationUsed('integrations', this.props.account.data)) {
        let requestData:AccountRequest = AccountUtil.addSetupRecommendationToRequest('integrations', {}, this.props.account.data);
        await this.props.updateAccount(requestData).send();
      }
      this.setState({ loading: false }, () => {
        this.props.onSubmit(result);
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  renderOptimalBlueFields = () => {
    const { intl, classes } = this.props;
    const { form } = this.state;


    return (
      <div>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField name="optimalBlueBusinessChannelId"
                             label={IntlFormatter.formatMessage(intl, 'business_channel_id')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.data.businessChannelId')}
                             value={form.data.businessChannelId}
                             fullWidth={true}
                             validators={['required']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField name="optimalBlueOriginatorId"
                             label={IntlFormatter.formatMessage(intl, 'originator_id')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.data.originatorId')}
                             value={form.data.originatorId}
                             fullWidth={true}
                             validators={['required']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
          </Grid>
        </div>
        <div className={classes.mb2}>
          <TextField name="optimalBlueClientlId"
                     label={IntlFormatter.formatMessage(intl, 'client_id')}
                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.data.clientId')}
                     value={form.data.clientId}
                     fullWidth={true}
                     validators={['required']}
                     errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
        </div>
        <div className={classes.mb2}>
          <TextField name="optimalBlueClientSecret"
                     label={IntlFormatter.formatMessage(intl, 'client_secret')}
                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.data.clientSecret')}
                     value={form.data.clientSecret}
                     fullWidth={true}
                     validators={['required']}
                     errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
        </div>

        <div className={classes.mb2}>
          <TextField name="optimalBlueResource"
                     label={IntlFormatter.formatMessage(intl, 'resource')}
                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.data.resource')}
                     value={form.data.resource}
                     fullWidth={true}
                     validators={['required']}
                     errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField name="optimalBlueMinPar"
                         label={IntlFormatter.formatMessage(intl, 'min_par')}
                         type="number"
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.data.minPar')}
                         value={form.data.minPar}
                         fullWidth={true} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField name="optimalBlueMaxPar"
                         label={IntlFormatter.formatMessage(intl, 'max_par')}
                         type="number"
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.data.maxPar')}
                         value={form.data.maxPar}
                         fullWidth={true} />
            </Grid>
          </Grid>
        </div>
      </div>
    )
  };

  render() {

    const { intl, classes, app } = this.props;
    const { form, loading, errors } = this.state;


    return (
      <div>
        {form.provider &&
          <div className={classes.mb2}>
            <IntegrationHelpAlert provider={form.provider} />
          </div>
        }

        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } }/>
          {app.data.enums.pricingProviders.length > 1 &&
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectValidator
                name="provider"
                label={IntlFormatter.formatMessage(intl, 'provider')}
                value={form.provider}
                fullWidth={true}
                onChange={this.onProviderChange}
                validators={['required']}
                errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}>
                {app.data.enums.pricingProviders.map((provider, i) => {
                  return (
                    <MenuItem key={i} value={provider}>
                      <FormattedMessage id={provider} />
                    </MenuItem>
                  )
                })}
              </SelectValidator>
            </Grid>
          </Grid>
          }

          {form.provider && this.formMap[form.provider] &&
          <div>
            {app.data.enums.pricingProviders.length === 1 &&
            <div className={clsx(classes.mb2, classes.textCenter)}>
              <img src={`/assets/img/logos/${form.provider}.png`} alt={form.provider} />
            </div>
            }
            {this.formMap[form.provider]()}
          </div>
          }

          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updatePricingIntegration(data:PricingIntegrationRequest) {
    return dispatch(updatePricingIntegration(data));
  },
  updateAccount(data:AccountRequest) {
    return dispatch(updateAccount(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(PricingSettingsForm)));
