export enum ColorVariant {
  DEFAULT = 'default',
  INFO = 'info',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  DANGER = 'danger'
}

export type ColorVariantType = 'default' | 'info' | 'primary' | 'secondary' | 'success' | 'danger'

export enum Size {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large'
}

export enum ReduxAsyncOperation {
  IDLE = 'idle',
  FETCHING = 'fetching',
  ADDING = 'adding',
  UPDATING = 'updating',
  DELETING = 'deleting',
  EMPTY = 'empty',
  ERROR = 'error' // @deprecated
}

export enum DefaultPage {
  BOAT = 'boat',
  PEOPLE = 'people'
}

export enum Locales {
  EN = "en",
  ES = "es",
}
