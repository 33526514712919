import React, {MouseEvent, useEffect} from 'react';
import EncompassLoanTemplateViewer, {EncompassLoanTemplateViewerProps, EncompassLoanTemplateItem} from "./EncompassLoanTemplateViewer";
import IntlFormatter from "../../../intl";
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {
  Button,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from "@material-ui/core";
import _ from 'lodash';

type Props = {
  title: string,
  templatePath?: string
} & EncompassLoanTemplateViewerProps
  & WrappedComponentProps

const EncompassLoanTemplateSelect = (props:Props) => {
  const {intl, title, clientId, clientSecret, instanceId, username, password} = props;
  const [templatePath, setTemplatePath] = React.useState<string>('');
  const [editing, setEditing] = React.useState<boolean>(false);
  const [actionEl, setActionEl] = React.useState<Element|null>(null);

  useEffect(() => {
    if(props.templatePath) {
      setTemplatePath(_.clone(props.templatePath));
    }
  }, [props.templatePath]);

  const onActionMenuClick = (event:MouseEvent) => {
    setActionEl(event.currentTarget);
  };

  const onActionMenuHide = () => {
    setActionEl(null);
  };

  const onAddEditClick = () => {
    setEditing(true);
    setActionEl(null);
  };

  const onAddEditCancel = () => {
    setTemplatePath(props.templatePath ? props.templatePath : '');
    setEditing(false);
    setActionEl(null);
  };

  const onDeleteClick = () => {
    setTemplatePath('');
    setActionEl(null);
  };

  const onTemplateClick = (item:EncompassLoanTemplateItem) => {
    setTemplatePath(item.entityUri || '');
    setEditing(false);
    props.onTemplateClick(item);
  };

  return (
    <div>
      <Menu anchorEl={actionEl}
            open={Boolean(actionEl)}
            onClose={onActionMenuHide}>
        <MenuItem onClick={onAddEditClick}>
          <ListItemIcon>
            <Icon>mode_edit</Icon>
          </ListItemIcon>
          <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')}/>
        </MenuItem>
        <MenuItem onClick={onDeleteClick}>
          <ListItemIcon>
            <Icon>cancel</Icon>
          </ListItemIcon>
          <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')}/>
        </MenuItem>
      </Menu>

      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="body1">
            {title}
          </Typography>
          {!editing &&
            <Typography variant="caption">
              {templatePath === '' ? IntlFormatter.formatMessage(intl, 'no_template_selected') : decodeURIComponent(templatePath)}
            </Typography>
          }
        </Grid>
        <Grid item>
          {(templatePath === '' && !editing) &&
          <Button variant="outlined"
                  color="primary"
                  onClick={onAddEditClick}>
            <FormattedMessage id="add"/>
          </Button>
          }
          {(templatePath !== '' && !editing) &&
            <IconButton onClick={onActionMenuClick}>
              <Icon>more_vert</Icon>
            </IconButton>
          }
          {editing &&
          <Button variant="outlined"
                  color="primary"
                  onClick={onAddEditCancel}>
            <FormattedMessage id="cancel"/>
          </Button>
          }
        </Grid>
      </Grid>
      {editing &&
        <EncompassLoanTemplateViewer
          clientId={clientId}
          clientSecret={clientSecret}
          instanceId={instanceId}
          username={username}
          password={password}
          onTemplateClick={onTemplateClick} />
      }
    </div>
  )
};

export default injectIntl(EncompassLoanTemplateSelect);