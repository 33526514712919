import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    display: 'inline-block',
    borderRadius: '50%',
    width: '13px',
    height: '13px',
    lineHeight: '13px',
    textAlign: 'center'
  },
  default: {
    color: theme.palette.neutral.contrastText,
    backgroundColor: theme.palette.neutral.main
  },
  info: {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main
  },
  primary: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  secondary: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main
  },
  success: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main
  },
  danger: {
    color: theme.palette.danger.contrastText,
    backgroundColor: theme.palette.danger.main
  }
});

export default styles;
