import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {
  AddButton,
  AutoComplete,
  ClipboardText,
  DeleteDialog,
  Dialog,
  ErrorList,
  NoResultsCard,
  PageTitle,
  PaginationHeader,
  SearchBar,
  SortField,
  SubmitButton,
  UserAvatar,
  Pagination
} from '../../../components';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {
  Button,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  withStyles,
  Hidden,
  List,
  ListItem,
  ListItemSecondaryAction,
  Divider,
  ListItemAvatar,
  Tooltip,
  Fab,
  Checkbox,
  FormControlLabel,
  Link, WithStyles
} from "@material-ui/core";
import {SearchUtil, ReduxUtil, DateUtil, ErrorUtil, TextUtil, AclUtil, UserUtil} from "../../../utils";
import _ from "lodash";
import {Link as RouterLink, Redirect, RouteComponentProps} from "react-router-dom";
import pageStyles from '../../../theme/jss/layouts/pageStyles';
import clsx from "clsx";
import qs from "query-string";
import {
  ActionResponse,
  DialogState,
  LabelValuePairType,
  ReduxRoles,
  ReduxLoanSettings,
  ReduxUser,
  ReduxUsers,
  ActionProps,
  SearchCriteriaWithPagingType,
  SnackbarState, SortDirectionType,
  ErrorState
} from "../../../types";
import {Role, User, UserSearchRequest} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  user: ReduxUser,
  users: ReduxUsers,
  roles: ReduxRoles,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  pageLoaded: boolean,
  loading: boolean,
  hasInitialResults?: boolean,
  selectedActionMenu?: Element|null,
  deleteDialogOpen: boolean,
  deleteLoading: boolean,
  selectedUser: User|null,
  searchCriteria: SearchCriteriaWithPagingType & {
    roles: Role[]
  },
  snackbar?: SnackbarState,
  errors: ErrorState[],
  redirectTo?: string|null,
  restoreDialog: DialogState
}

class UsersPage extends Component<Props, State> {

  userSearch:ActionResponse|null;
  sortOptions:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      deleteDialogOpen: false,
      deleteLoading: false,
      selectedUser: null,
      searchCriteria: {
        searchText: '',
        roles: [],
        order: {
          column: 'lastName',
          direction: 'asc',
        },
        limit: 10,
        skip: 0,
        totalCount: 0,
        page: 1,
        deleted: false
      },
      errors: [],
      redirectTo: null,
      restoreDialog: {
        open: false,
        loading: false,
        errors: []
      }
    };

    this.sortOptions = [
      { label: IntlFormatter.formatMessage(props.intl, 'name'), value: 'lastName' },
      { label: IntlFormatter.formatMessage(props.intl, 'email'), value: 'email' },
      { label: IntlFormatter.formatMessage(props.intl, 'last_login'), value: 'lastLogin' },
    ];
  }

  componentDidMount = async () => {
    try {
      await this.addSearchCriteriaFromParams();
      await this.props.routeProps.getRoles().send();
      const result = await this.searchUsers();
      this.setState({ pageLoaded: true, hasInitialResults: result.data.length > 0 });
    } catch (err) {
      this.onError(err);
    }
  };

  addSearchCriteriaFromParams = ():Promise<void> => {
    return new Promise((resolve) => {
      const params = qs.parse(this.props.location.search); // @todo add other search criteria to url params
      if(params.deleted === 'true') {
        const searchCriteria = _.cloneDeep(this.state.searchCriteria);
        searchCriteria.deleted = true;
        this.setState({ searchCriteria }, () => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  };

  searchTextChanged = (value:string) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.searchText = value;
    searchCriteria.skip = 0;
    searchCriteria.page = 1;

    this.setState({ searchCriteria }, this.filterUsers);
  };

  searchTextRemove = () => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.searchText = '';
    searchCriteria.skip = 0;
    searchCriteria.page = 1;

    this.setState({ searchCriteria }, this.filterUsers);
  };

  setRoles = (roles:Role[]) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.roles = _.cloneDeep(roles);
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.filterUsers);
  };

  searchRoles = (searchText:string) => {
    let roles:Role[];
    if(searchText === '') {
      roles = this.props.roles.data;
    } else {
      const regex = new RegExp(TextUtil.escapeRegEx(searchText), 'i');
      roles = this.props.roles.data.filter(a => regex.test(a.name));
    }

    return roles;
  };

  getFilterCount = () => {
    let count = 0;
    const { roles, deleted } = this.state.searchCriteria;
    count += roles.length;
    if(deleted) {
      count++;
    }
    return count;
  };

  filterUsers = async () => {
    try {
      await this.searchUsers();
    } catch (e) {
      this.onError(e);
    }
  };

  searchUsers = ():Promise<AxiosResponse<User[]>> => {
    return new Promise((resolve, reject) => {
      if(this.userSearch) {
        this.userSearch.cancel('Another request was made');
      }

      const {searchCriteria} = this.state;

      this.setState({loading: true}, () => {
        const criteria:UserSearchRequest = {
          searchText: searchCriteria.searchText,
        };

        if (searchCriteria.roles.length > 0) {
          criteria.roles = _.map(searchCriteria.roles, '_id');
        }

        const params = SearchUtil.getParamsFromSearchCriteria(searchCriteria);
        if(!AclUtil.isOwner(this.props.user.data) && params) {
          params.deleted = false;
        }

        this.userSearch = this.props.routeProps.searchUsers(criteria, params);

        this.userSearch.send().then(result => {
          const searchCriteria = _.cloneDeep(this.state.searchCriteria);
          searchCriteria.totalCount = parseInt(result.headers['total-count']);
          this.setState({loading: false, searchCriteria});
          this.userSearch = null;
          resolve(result);
        }).catch(err => {
          this.userSearch = null;
          reject(err);
        });
      });
    });
  };

  onError = (err:any) => {
    this.setState({loading: false, errors: ErrorUtil.formatErrors(err)});
  };


  showDeleteDialog = () => {
    this.setState({
      deleteDialogOpen: true
    });
  };

  hideDeleteDialog = () => {
    this.setState({
      deleteDialogOpen: false,
      selectedUser: null
    });
  };

  deleteUser = async (user:User) => {
    try {
      this.setState({ deleteLoading: true });
      await this.props.routeProps.deleteUser(user._id).send();
      this.props.mixpanel.track("User archived");
      this.setState({
        deleteDialogOpen: false,
        deleteLoading: false,
        selectedUser: null,
        snackbar: {
          open: true,
          variant: 'success',
          message: IntlFormatter.formatMessage(this.props.intl, 'user_archived')
        }
      });

      await this.filterUsers();
    } catch(err) {
      this.setState({ errors: ErrorUtil.formatErrors(err), deleteLoading: false });
    }
  };

  onUserActionMenuClick = (event:MouseEvent, user:User) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ selectedActionMenu: event.currentTarget, selectedUser: user });
  };

  onUserActionMenuClose = () => {
    this.setState({ selectedActionMenu: null });
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'users');
  };

  renderTitleBar = () => {
    const { intl } = this.props;
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <PageTitle title={this.getPageTitle()} icon="people" />
        </Grid>
        <Grid item>
          <Hidden mdUp>
            <Tooltip title={IntlFormatter.formatMessage(intl, 'add_user')}>
              <Fab color="primary"
                   component={RouterLink}
                   size="small"
                   to="/admin/users/add">
                <Icon>add</Icon>
              </Fab>
            </Tooltip>
          </Hidden>
          <Hidden smDown>
            <AddButton variant="contained"
                       color="primary"
                       to="/admin/users/add">
              <FormattedMessage id="add_user" />
            </AddButton>
          </Hidden>
        </Grid>
      </Grid>
    )
  };


  onPageChange = (event:MouseEvent, value:number) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.skip = (value - 1) * searchCriteria.limit;
    searchCriteria.page = value;
    this.setState({ searchCriteria }, this.filterUsers);
  };

  onTableHeadingSortColumnChange = (column:string) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.order.column = column;
    searchCriteria.order.direction = searchCriteria.order.direction === 'asc' ? 'desc' : 'asc';
    this.setState({ searchCriteria }, this.filterUsers);
  };

  onSortFieldOptionChange = (data:LabelValuePairType) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.order.column = data ? data.value : null;
    this.setState({ searchCriteria }, this.filterUsers);
  };

  onSortFieldDirectionChange = (direction:SortDirectionType) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.order.direction = direction;
    this.setState({ searchCriteria }, this.filterUsers);
  };

  onToggleInactiveUsers = () => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.deleted = !searchCriteria.deleted;
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.filterUsers);
  };

  onRestoreUserClick = (user:User) => {
    const restoreDialog = _.cloneDeep(this.state.restoreDialog);
    restoreDialog.open = true;
    this.setState({ selectedUser: _.cloneDeep(user), restoreDialog });
  };

  onRestoreUserConfirm = async () => {
    const restoreDialog = _.cloneDeep(this.state.restoreDialog);
    try {
      restoreDialog.loading = true;
      this.setState({ loading: true, restoreDialog });

      const { selectedUser } = this.state;
      if(selectedUser) {
        if(UserUtil.hasRole(selectedUser, 'ROLE_LOAN_OFFICER') && selectedUser.additionalProps && selectedUser.additionalProps.loanOfficer) {
          await this.props.routeProps.updateLoanOfficer(selectedUser.additionalProps.loanOfficer._id, { deleted: false }, { deleted: true }).send();
        } else {
          await this.props.routeProps.updateUser(selectedUser._id, { deleted: false }, { deleted: true }).send();
        }

        this.props.mixpanel.track("User restored");
      }
      restoreDialog.loading = false;
      restoreDialog.open = false;
      this.setState({ loading: false, selectedUser: null, restoreDialog }, this.filterUsers);
    } catch(e) {
      restoreDialog.errors = ErrorUtil.formatErrors(e);
      restoreDialog.loading = false;
      this.setState({ loading: false, selectedUser: null, restoreDialog });
    }
  };

  onRestoreUserCancel = () => {
    const restoreDialog = _.cloneDeep(this.state.restoreDialog);
    restoreDialog.open = false;
    restoreDialog.loading = false;
    restoreDialog.errors = [];
    this.setState({ selectedUser: null, restoreDialog });
  };

  render() {

    const { intl, users, classes, user, roles, settings } = this.props;
    const { pageLoaded, loading, searchCriteria, deleteDialogOpen, deleteLoading, selectedUser, snackbar, errors, selectedActionMenu, redirectTo, hasInitialResults, restoreDialog } = this.state;
    const pages = Math.ceil(searchCriteria.totalCount / searchCriteria.limit);

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   pageLoaded={pageLoaded}
                   loading={loading}
                   titleBar={this.renderTitleBar()}
                   snackbar={snackbar}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: IntlFormatter.formatMessage(intl, 'users') }
                   ]}>

        {selectedUser &&
          <DeleteDialog open={deleteDialogOpen}
                        title={IntlFormatter.formatMessage(intl, 'archive_user')}
                        item={selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : ''}
                        confirmationMessage={IntlFormatter.formatMessage(intl, 'archive_confirmation', { value: selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : '' })}
                        secondConfirmationMessage={IntlFormatter.formatMessage(intl, 'archive_second_confirmation', { value: selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : '' })}
                        deleteButtonLabel={IntlFormatter.formatMessage(intl, 'archive')}
                        loading={deleteLoading}
                        onCancel={this.hideDeleteDialog}
                        onSubmit={() => this.deleteUser(selectedUser)} />
        }


        <Dialog open={restoreDialog.open}
                icon={<Icon>person_add</Icon>}
                title={IntlFormatter.formatMessage(intl, 'restore_user')}
                color="successAlt"
                onClose={this.onRestoreUserCancel}
                fullWidth={true}
                variant="confirm"
                maxWidth="sm">
          <div className={classes.mb3}>
            <ErrorList className={classes.mb2}
                       errors={restoreDialog.errors} />
            <div className={classes.textCenter}>
              <Typography variant="h4">
                <FormattedMessage id="are_you_sure_restore_user" values={{user: selectedUser ? selectedUser.fullName : ''}} />
              </Typography>
            </div>
          </div>
          <div className={classes.center}>
            <Button onClick={this.onRestoreUserCancel} className={classes.mr1}>
              <FormattedMessage id="cancel" />
            </Button>

            <SubmitButton loading={restoreDialog.loading}
                          onClick={this.onRestoreUserConfirm}>
              <FormattedMessage id="yes"/>
            </SubmitButton>
          </div>
        </Dialog>

        {(selectedUser && !restoreDialog.open) &&
          <Menu anchorEl={selectedActionMenu}
                open={Boolean(selectedActionMenu)}
                onClose={this.onUserActionMenuClose}>
            <MenuItem component={RouterLink} to={selectedUser.additionalProps && selectedUser.additionalProps.loanOfficer ? `/admin/loan-officers/detail/${selectedUser.additionalProps.loanOfficer._id}` : `/admin/users/detail/${selectedUser._id}`}>
              <ListItemIcon>
                <Icon>remove_red_eye</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'view')} />
            </MenuItem>
            <MenuItem component={RouterLink} to={selectedUser.additionalProps && selectedUser.additionalProps.loanOfficer ? `/admin/loan-officers/edit/${selectedUser.additionalProps.loanOfficer._id}` : `/admin/users/edit/${selectedUser._id}`}>
              <ListItemIcon>
                <Icon>mode_edit</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
            </MenuItem>
            <MenuItem onClick={this.showDeleteDialog}
                      disabled={user.data._id === selectedUser._id || settings.data.defaultLoanOfficer!.user._id === selectedUser._id}>
              <ListItemIcon>
                <Icon>cancel</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'archive')} />
            </MenuItem>
          </Menu>
        }
        <ErrorList errors={errors}
                   className={classes.m2}
                   onClose={() => { this.setState({ errors: [] }); } } />


        <div>
          <div className={classes.pageHeaderContainer}>
            <SearchBar onSearchTextChanged={this.searchTextChanged}
                       onSearchTextRemove={this.searchTextRemove}
                       filterDrawerTitle={IntlFormatter.formatMessage(intl, 'filter_users')}
                       filterCount={this.getFilterCount()}>
                {(ReduxUtil.hasData(roles) && roles.data.length > 0) &&
                  <div className={classes.mb2}>
                      <AutoComplete value={searchCriteria.roles}
                                    getOptionLabel={(item:Role) => item.name ? item.name : ''}
                                    getOptionSelected={(option:Role, value:Role) => {
                                      return option._id === value._id;
                                    }}
                                    onChange={this.setRoles}
                                    onTextChange={this.searchRoles}
                                    limitTags={2}
                                    multiple={true}
                                    placeholder={IntlFormatter.formatMessage(intl, 'role')}
                                    openOnFocus={true}/>
                  </div>
                }

                {AclUtil.isOwner(user.data) &&
                  <div className={classes.mb2}>
                    <FormControlLabel label={IntlFormatter.formatMessage(intl, 'archived_users')} control={
                      <Checkbox checked={searchCriteria.deleted}
                                onChange={this.onToggleInactiveUsers}
                                color="primary" />
                    }
                    />
                  </div>
                }

              <div className={classes.mb3}>
                <Divider />
              </div>

              <SortField label={IntlFormatter.formatMessage(intl, 'sort_by')}
                         placeholder={IntlFormatter.formatMessage(intl, 'none')}
                         value={searchCriteria.order.column ? this.sortOptions.find(o => o.value === searchCriteria.order.column) : ''}
                         fullWidth={true}
                         options={this.sortOptions}
                         getOptionLabel={(item:LabelValuePairType) => item.label}
                         direction={searchCriteria.order.direction}
                         onChange={this.onSortFieldOptionChange}
                         onDirectionChange={this.onSortFieldDirectionChange}/>
            </SearchBar>
          </div>
          {ReduxUtil.hasData(users) && users.data.length > 0 &&
          <div>
            <div className={clsx(classes.ph2, classes.pv1)}>
              <PaginationHeader totalResults={searchCriteria.totalCount}
                                currentPage={searchCriteria.page}
                                totalPages={pages} />
            </div>
            <Hidden mdUp implementation="css">
              <Divider />
              <List>
                {users.data.map((user, i) => {
                  return (
                    <ListItem key={i}>
                      <ListItemAvatar>
                        <UserAvatar user={user} />
                      </ListItemAvatar>
                      <ListItemText primary={`${user.firstName} ${user.lastName}`}
                                    secondary={user.email} />
                      <ListItemSecondaryAction>
                        <IconButton onClick={event => this.onUserActionMenuClick(event, user)}>
                          <Icon>more_vert</Icon>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </List>
            </Hidden>
            <Hidden smDown implementation="css">
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel active={searchCriteria.order.column === 'lastName'}
                                        direction={searchCriteria.order.direction}
                                        onClick={() => this.onTableHeadingSortColumnChange('lastName')}>
                          <FormattedMessage id="name"/>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel active={searchCriteria.order.column === 'email'}
                                        direction={searchCriteria.order.direction}
                                        onClick={() => this.onTableHeadingSortColumnChange('email')}>
                          <FormattedMessage id="email"/>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell><FormattedMessage id="roles"/></TableCell>
                      <TableCell>
                        <TableSortLabel active={searchCriteria.order.column === 'lastLogin'}
                                        direction={searchCriteria.order.direction}
                                        onClick={() => this.onTableHeadingSortColumnChange('lastLogin')}>
                          <FormattedMessage id="last_login"/>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel active={searchCriteria.order.column === 'created'}
                                        direction={searchCriteria.order.direction}
                                        onClick={() => this.onTableHeadingSortColumnChange('created')}>
                          <FormattedMessage id="created"/>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.data.map(user => {
                      return (
                        <TableRow key={user._id}>
                          <TableCell component="th" scope="row">
                            <div className={classes.rowCenter}>
                              <UserAvatar user={user} className={classes.mr1} />
                              <RouterLink to={`/admin/users/detail/${user._id}`}>
                                <b>{user.firstName} {user.lastName}</b>
                              </RouterLink>
                            </div>
                          </TableCell>
                          <TableCell>
                            <ClipboardText value={user.email}>
                              {user.email}
                            </ClipboardText>
                          </TableCell>
                          <TableCell>
                            {user.roles.map(role => {
                              return (
                                <div key={role._id}>
                                  <Typography variant="body1">{role.name}</Typography>
                                </div>
                              )
                            })}
                          </TableCell>
                          <TableCell>
                            {user.lastLogin &&
                              <div>{DateUtil.formatUserDate(this.props.user.data, user.lastLogin)}</div>
                            }
                            {!user.lastLogin &&
                              <FormattedMessage id="never" />
                            }
                          </TableCell>
                          <TableCell>
                            {DateUtil.formatUserDate(this.props.user.data, user.created)}
                          </TableCell>
                          <TableCell align="right">
                            {!user.deleted &&
                              <IconButton onClick={event => this.onUserActionMenuClick(event, user)}>
                                <Icon>more_vert</Icon>
                              </IconButton>
                            }
                            {(user.deleted && AclUtil.isOwner(this.props.user.data)) &&
                              <Button variant="outlined"  onClick={() => this.onRestoreUserClick(user)}>
                                <FormattedMessage id="restore" />
                              </Button>
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            </Hidden>
            {pages > 1 &&
              <Pagination count={pages}
                          color="primary"
                          onChange={this.onPageChange} />
            }
          </div>
          }

          {(!loading && ReduxUtil.hasData(users) && users.data.length === 0) &&
          <div className={clsx(classes.p2, classes.mt2)}>
            <NoResultsCard message={IntlFormatter.formatMessage(intl, 'no_users_found')}>
              <Typography variant="body1" color="inherit">
                {!hasInitialResults &&
                <Link component={RouterLink} to="/admin/users/add">
                  <FormattedMessage id="add_user" />
                </Link>
                }
              </Typography>
            </NoResultsCard>
          </div>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    users: state.users,
    roles: state.roles,
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(UsersPage)));
