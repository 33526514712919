import React from 'react';
import {
  Divider,
  Grid,
  Icon,
  Card, withStyles, CardHeader, CardContent, Typography, WithStyles
} from '@material-ui/core';
import {Form} from '../../../forms';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {LoanApplicationPage} from "../layouts";
import {ErrorUtil, LoanUtil, ReduxUtil} from "../../../utils";
import BaseLoanApplicationPage from "./BaseLoanApplicationPage";
import _ from 'lodash';
import {DemographicsFormFields} from '../forms';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {LoanApplicationControls} from "../components";
import {ErrorList, UnsavedChangesPrompt, Well} from "../../../components";
import {ActionProps, ErrorState, ReduxLoanApplication, ReduxLoanSettings} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";
import {
  BorrowerDemographics,
  LoanApplication,
  Borrower,
  BorrowerUpdateRequest,
  LoanApplicationUpdateRequest
} from "@jerseydev/orca-loans";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps


type State = {
  loading: boolean,
  loanApplication: LoanApplication|null,
  redirectTo?: string,
  formPristine: boolean,
  errors: ErrorState[],
  isStepCompleted?: boolean
}

class DemographicsPage extends BaseLoanApplicationPage<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: !ReduxUtil.hasData(props.loanApplication),
      loanApplication: ReduxUtil.hasData(props.loanApplication) ? _.cloneDeep(props.loanApplication.data) : null,
      formPristine: true,
      errors: []
    };
  }

  static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: Readonly<State>){
    if(nextProps.loanApplication && nextProps.loanApplication.data && prevState.isStepCompleted === undefined){
      return {isStepCompleted: LoanUtil.isDemographicsCompleted(nextProps.loanApplication.data)};
    }
    return null;
  };

  componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>) => {
    if(!prevState.loanApplication && ReduxUtil.hasData(this.props.loanApplication)) {
      this.setState({ loanApplication: _.cloneDeep(this.props.loanApplication.data), loading: false });
    }
  };

  /*componentWillReceiveProps = (nextProps:Props) => {
    if(!this.state.loanApplication && ReduxUtil.hasData(nextProps.loanApplication)) {
      this.setState({ loanApplication: _.cloneDeep(nextProps.loanApplication.data), loading: false });
    }
  };*/

  componentWillUnmount = () => {
    const {mixpanel, loanApplication} = this.props;
    if(loanApplication.data && !this.state.isStepCompleted && LoanUtil.isDemographicsCompleted(loanApplication.data)) {
      mixpanel.track("Loan application step completed", {step: 'demographics'});
    }
  };

  onDemographicsChange = (borrower:Borrower, demographics:BorrowerDemographics) => {
    const loanApplication = _.cloneDeep(this.state.loanApplication);
    if(loanApplication && loanApplication.borrowers) {
      const borrowerIndex = loanApplication.borrowers.findIndex(b => b._id === borrower._id);
      loanApplication.borrowers![borrowerIndex].demographics = demographics;
    }

    this.setState({ loanApplication, formPristine: false });
  };

  onNextClick = async () => {
    try {
      const loanApplication = _.cloneDeep(this.state.loanApplication);
      if(loanApplication && loanApplication.borrowers) {
        this.setState({ loading: true, loanApplication });

        const borrowersRequestData:BorrowerUpdateRequest[] = [];
        loanApplication.borrowers.forEach(borrower => {
          const {_id, demographics} = borrower;
          borrowersRequestData.push({
            _id,
            demographics
          });
        });

        const requestData:LoanApplicationUpdateRequest = {
          borrowers: borrowersRequestData
        };

        await this.props.routeProps.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      }
      this.setState({ loading: false, formPristine: true, redirectTo: this.nextMenuItem.to });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes, loanApplication } = this.props;
    const { loading, redirectTo, formPristine, errors } = this.state;


    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <LoanApplicationPage menuId="demographics"
                           loading={loading}>
        <UnsavedChangesPrompt when={!formPristine} />
        <Form onSubmit={this.onNextClick}>
          <div>
            <ErrorList errors={errors}
                       className={classes.p2}
                       onClose={() => { this.setState({ errors: [] }); } }/>
            <div className={classes.content}>

              <div className={classes.mb2}>
                <Well color="primary">
                  <Typography variant="body1">
                    <FormattedMessage id="loan_application_demographics_page_text" />
                  </Typography>
                </Well>
              </div>

              {(ReduxUtil.hasData(loanApplication) && loanApplication.data.borrowers) &&
              <Grid container spacing={4}>
                {loanApplication.data.borrowers.map((borrower, i) => {
                  return (
                    <Grid key={i} item xs={12} sm={12} md={6}>
                      <Card className={classes.card}>
                        <CardHeader title={`${borrower.firstName} ${borrower.lastName}`}
                                    subheader={IntlFormatter.formatMessage(intl, borrower.primaryBorrower ? 'borrower' : 'co_borrower')}
                                    avatar={<Icon>{borrower.primaryBorrower ? 'person' : 'person_outline'}</Icon>}/>
                        <Divider />

                        <CardContent>
                          <DemographicsFormFields demographics={borrower.demographics}
                                                  onChange={demographics => this.onDemographicsChange(borrower, demographics)} />
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                })}
              </Grid>
              }
            </div>
            <div className={classes.content}>
              <LoanApplicationControls prev={this.previousMenuItem}
                                       next={this.nextMenuItem}
                                       nextButtonProps={{ type: 'submit', loading }} />
            </div>
          </div>
        </Form>
      </LoanApplicationPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    settings: state.loanSettings
  };
};


export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(DemographicsPage)));
