import React, {Component} from 'react';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  Icon,
  IconButton,
  Grid,
  Typography,
  withStyles,
  WithStyles,
  List,
  ListItem,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  TableSortLabel
} from "@material-ui/core";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {PageTitle, PriorityIndicator, Snackbar, TicketStatusBadge} from "../../../components";
import {HelpForm} from '../../../forms';
import {Mixpanel} from "mixpanel-browser";
import {ActionProps, ErrorState, ReduxTickets, ReduxUser} from "../../../types";
import {DateUtil, ErrorUtil, ReduxUtil} from "../../../utils";
import {ReduxState} from "../../../data/initialState";
import {connect} from "react-redux";
import {Ticket} from "@jerseydev/orca-loans";
import _ from "lodash";
import clsx from "clsx";

type SearchCriteria = {
  orderBy: string,
  orderByDirection: 'asc'|'desc',
  limit: number,
  skip: number,
  page: number
}

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  tickets: ReduxTickets,
  user: ReduxUser
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoading: boolean,
  loading: boolean,
  snackbarOpen: boolean,
  errors: ErrorState[],
  searchCriteria: SearchCriteria,
  hasMore: boolean
}

class HelpPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoading: true,
      loading: false,
      snackbarOpen: false,
      errors: [],
      searchCriteria: {
        orderBy: 'created',
        orderByDirection: 'desc',
        limit: 10,
        skip: 0,
        page: 0
      },
      hasMore: false
    };
  }

  componentDidMount = async () => {
    await this.getTickets();
  }

  getTickets = async () => {
    try {
      const {orderBy, orderByDirection, limit, skip} = this.state.searchCriteria;
      const result = await this.props.routeProps.getTickets({
        orderBy,
        orderByDirection,
        limit,
        skip
      }).send();
      this.setState({pageLoading:false, hasMore: result.headers['has-more'] === 'true'});
    } catch (e) {
      this.setState({pageLoading:false, errors:ErrorUtil.formatErrors(e)});
    }
  }

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'help');
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="change_history" />
    )
  };

  onSupportFormSubmit = () => {
    this.setState({ snackbarOpen: true }, this.getTickets);
  };

  hideSnackbar = () => {
    this.setState({ snackbarOpen: false });
  };

  onViewTicketClick = (ticket:Ticket) => {
    window.open(`https://jerseydev.freshdesk.com/helpdesk/tickets/${ticket.id}`, '_blank');
  };

  onTableHeadingSortColumnChange = (column:string) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.orderBy = column;
    searchCriteria.orderByDirection = searchCriteria.orderByDirection === 'asc' ? 'desc' : 'asc';
    this.setState({ searchCriteria }, this.getTickets);
  };

  onPreviousClick = () => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.page = searchCriteria.page - 1;
    searchCriteria.skip = searchCriteria.page * searchCriteria.limit;
    this.setState({ searchCriteria }, this.getTickets);
  };

  onNextClick = () => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.page = searchCriteria.page + 1;
    searchCriteria.skip = searchCriteria.page * searchCriteria.limit;
    this.setState({ searchCriteria }, this.getTickets);
  };

  render() {

    const { classes, intl, mixpanel, tickets, user } = this.props;
    const { loading, snackbarOpen, searchCriteria, hasMore } = this.state;

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                    {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                    {title: this.getPageTitle() }
                   ]}
                   loading={loading}>
        <Snackbar open={snackbarOpen}
                  variant="success"
                  onClose={this.hideSnackbar}
                  message={IntlFormatter.formatMessage(intl, 'ticket_created')}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.hideSnackbar}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />
        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="h3" gutterBottom>
                <FormattedMessage id="help_page_subtitle" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage id="help_page_text" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <HelpForm mixpanel={mixpanel}
                        onSubmit={this.onSupportFormSubmit} />
            </Grid>
          </Grid>
          {(ReduxUtil.hasData(tickets) && tickets.data.length > 0) &&
          <div className={classes.mt3}>
            <Typography variant="h4" gutterBottom>
              <FormattedMessage id="my_tickets" />
            </Typography>
            <Hidden mdUp implementation="css">
              <List>
                {tickets.data.map((ticket, i) => {
                  return (
                    <ListItem key={i} divider={true}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body1">
                              {ticket.subject}
                            </Typography>
                            <div>
                              <TicketStatusBadge status={ticket.status} />
                            </div>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined"
                                  onClick={() => this.onViewTicketClick(ticket)}>
                            <FormattedMessage id="view"/>
                          </Button>
                        </Grid>
                      </Grid>
                    </ListItem>
                  )
                })}
              </List>
            </Hidden>
            <Hidden smDown implementation="css">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="status"/>
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="subject"/>
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="type"/>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel active={searchCriteria.orderBy === 'created'}
                                      direction={searchCriteria.orderByDirection}
                                      onClick={() => this.onTableHeadingSortColumnChange('lastName')}>
                        <FormattedMessage id="created"/>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tickets.data.map((ticket, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          <TicketStatusBadge status={ticket.status} />
                        </TableCell>
                        <TableCell>
                          <div className={classes.rowCenter}>
                            <div className={classes.mr1}>
                              <PriorityIndicator priority={ticket.priority} />
                            </div>
                            <Typography variant="body1">
                              {ticket.subject}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {ticket.type}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {DateUtil.formatUserDate(user.data, ticket.created)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Button variant="outlined" onClick={() => this.onViewTicketClick(ticket)}>
                            <FormattedMessage id="view"/>
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Hidden>

            <div className={clsx(classes.mt2, searchCriteria.page === 0 ? classes.rowCenterEnd : classes.rowSpaceBetweenCenter)}>
              {(searchCriteria.page > 0) &&
                <Button onClick={this.onPreviousClick}
                        variant="contained"
                        color="primary">
                  <FormattedMessage id="previous" />
                </Button>
              }
              {hasMore &&
                <Button onClick={this.onNextClick}
                        variant="contained"
                        color="primary">
                  <FormattedMessage id="next" />
                </Button>
              }
            </div>
          </div>
          }
        </div>

      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    tickets: state.tickets,
    user: state.user
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(HelpPage)));
