import {WithStyles, withStyles} from "@material-ui/core";
import React from "react";
import {PairedList} from "../index";
import IntlFormatter from "../../intl";
import styles from "../../theme/jss/layouts/pageStyles";
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {KeyValueMixed, BorrowerOtherIncome} from "@jerseydev/orca-loans";

type Props = {
  otherIncome?: BorrowerOtherIncome[]
} & WithStyles<typeof styles>
  & WrappedComponentProps

function BorrowerMonthlyIncomeSummaryRev2(props:Props) {
  const { intl, otherIncome } = props;

  const getListItems = () => {
    let items:KeyValueMixed[] = [];
    if(otherIncome) {
      otherIncome.forEach(o => {
        items.push(
          {key: o.type ? IntlFormatter.formatMessage(intl, `income_type_${o.type}`) : '', value: o.value ? o.value.toMoney() : IntlFormatter.formatMessage(intl, 'na') }
        )
      });
    }

    return items;
  };

  return (
    <PairedList items={getListItems()} />
  );
}

export default withStyles(styles)(injectIntl(BorrowerMonthlyIncomeSummaryRev2));