import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  fileContainer: {
    marginBottom: theme.spacing(2)
  },
  errorContainer: {
    marginBottom: theme.spacing(2)
  },
  primaryText: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  secondaryText: {
    fontSize: '13px'
  },
  filesContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  fileItem: {
    padding: `${theme.spacing(2)}px 0`,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  }
});

export default styles;
