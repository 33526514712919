import _ from "lodash";
import moment from "moment";
import {ConfigUtil, LoanUtil} from "./index";
import {
  LoanApplication,
  Borrower,
  BorrowerUpdateRequest
} from "@jerseydev/orca-loans";

class LoanRev2Util {
  static getCompletedPercent = (loanApplication:LoanApplication):number => {
    let percent:number;
    const loanApplicationMenu = ConfigUtil.getLoanApplicationMenu(loanApplication.__t);
    const completedSteps = LoanRev2Util.getCompletedSteps(loanApplication);
    percent = Math.round((completedSteps.length / loanApplicationMenu.length) * 100);

    return percent;
  };

  static getBorrowerTotalYearsAtAddresses = (borrower:Borrower):number => {
    let totalYears = 0;

    if(borrower.addresses) {
      totalYears += _.sumBy(borrower.addresses, 'timeAtLocation.years');
      const totalMonths = _.sumBy(borrower.addresses, 'timeAtLocation.months');
      totalYears += Math.floor(totalMonths / 12);
    }

    return totalYears;
  };

  static getBorrowerTotalYearsEmployed = (borrower:Borrower):number => {
    let totalDays = 0;
    if(borrower.currentEmployment) {
      let earliestStartDate:string|null = null;
      borrower.currentEmployment.forEach(employment => {
        if(employment.startDate) {
          if(!earliestStartDate) {
            earliestStartDate = employment.startDate;
          } else if(moment(employment.startDate).isBefore(earliestStartDate)) {
            earliestStartDate = employment.startDate;
          }
        }
      });

      if(earliestStartDate) {
        totalDays += moment().diff(earliestStartDate, 'days');
      }
    }

    if(borrower.previousEmployment) {
      borrower.previousEmployment.forEach(employment => {
        const startDate = moment(employment.startDate);
        const endDate = moment(employment.endDate);
        totalDays += endDate.diff(startDate, 'days');
      });

    }

    return Math.floor(totalDays / 365);
  };

  static getCompletedSteps = (loanApplication:LoanApplication):string[] => {
    const completedSteps:string[] = [];
    if(LoanRev2Util.isPersonalInfoCompleted(loanApplication)) {
      completedSteps.push('personal');
    }

    if(LoanRev2Util.isAddressHistoryCompleted(loanApplication)) {
      completedSteps.push('address');
    }

    if(LoanRev2Util.isLoanInfoCompleted(loanApplication)) {
      completedSteps.push('loan_info');
    }

    if(LoanRev2Util.isPropertyInfoCompleted(loanApplication)) {
      completedSteps.push('property');
    }

    if(LoanRev2Util.isEmploymentCompleted(loanApplication)) {
      completedSteps.push('employment');
    }

    if(LoanRev2Util.isIncomeCompleted(loanApplication)) {
      completedSteps.push('income');
    }

    if(LoanUtil.isAssetsAndLiabilitiesCompleted(loanApplication)) {
      completedSteps.push('assets_liabilities');
    }

    if(LoanRev2Util.isRealEstateCompleted(loanApplication)) {
      completedSteps.push('real_estate');
    }

    if(LoanRev2Util.isDeclarationsCompleted(loanApplication)) {
      completedSteps.push('declarations');
    }

    if(LoanUtil.isDemographicsCompleted(loanApplication)) {
      completedSteps.push('demographics');
    }

    if(LoanRev2Util.isGiftsCompleted(loanApplication)) {
      completedSteps.push('gifts');
    }

    if(loanApplication.completed) {
      completedSteps.push('review');
    }

    return completedSteps;
  };

  static isPersonalInfoCompleted = (loanApplication:LoanApplication):boolean => {
    let completed = true;

    const requiredProps = ['firstName', 'lastName', 'email', 'ssn', 'birthDate', 'maritalStatus', 'militaryService']
    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      loanApplication.borrowers.forEach(borrower => {
        for(let i=0;i<requiredProps.length;i++) {
          const prop = requiredProps[i];
          if(borrower[prop as keyof Borrower] === null || borrower[prop as keyof Borrower] === undefined) {
            completed = false;
            break;
          }
        }

        if(completed && (!borrower.phoneNumbers || (borrower.phoneNumbers && borrower.phoneNumbers.length === 0))) {
          completed = false;
        }
      });
    } else {
      completed = false;
    }

    return completed;
  };

  static isAddressHistoryCompleted = (loanApplication:LoanApplication):boolean => {
    let completed = true;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        const borrower = loanApplication.borrowers[i];
        if(borrower.addresses && borrower.addresses.length > 0) {
          if((LoanRev2Util.getBorrowerTotalYearsAtAddresses(borrower) < 2) || !borrower.addresses.find(a => a.isMailingAddress) || !borrower.addresses.find(a => a.current)) {
            completed = false;
            break;
          }
        } else {
          completed = false;
        }
      }
    } else {
      completed = false;
    }

    return completed;
  };

  static isLoanInfoCompleted = (loanApplication:LoanApplication):boolean => {
    return !!(loanApplication.loan && loanApplication.loan.loanPurpose && loanApplication.loan.loanAmount);
  };

  static isPropertyInfoCompleted = (loanApplication:LoanApplication):boolean => {
    let completed = true;
    const requiredProps = ['property', 'property.address', 'property.address.city', 'property.address.province', 'property.occupancy'];
    for(let i=0;i<requiredProps.length;i++) {
      const propValue = _.get(loanApplication, requiredProps[i]);
      if(propValue === null || propValue === undefined || propValue === '') {
        completed = false;
        break;
      }
    }

    return completed;
  };

  static isEmploymentCompleted = (loanApplication:LoanApplication):boolean => {
    let completed = true;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        const yearsEmployed = LoanRev2Util.getBorrowerTotalYearsEmployed(loanApplication.borrowers[i]);
        if(yearsEmployed < 2) {
          completed = false;
          break;
        }
      }
    } else {
      completed = false;
    }

    return completed;
  };

  static isIncomeCompleted = (loanApplication:LoanApplication):boolean => {
    let completed = true;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        if(loanApplication.borrowers[i].otherIncome !== null && loanApplication.borrowers[i].otherIncome && loanApplication.borrowers![i].otherIncome!.length === 0) {
          completed = false;
          break;
        }
      }
    } else {
      completed = false;
    }

    return completed;
  };

  static isRealEstateCompleted = (loanApplication:LoanApplication):boolean => {
    let completed = true;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        const borrower = loanApplication.borrowers[i];
        if(borrower.realEstateAssets !== null && (Array.isArray(borrower.realEstateAssets) && borrower.realEstateAssets.length === 0)) {
          completed = false;
          break;
        }
      }
    } else {
      completed = false;
    }

    return completed;
  };

  static isDeclarationsCompleted = (loanApplication:LoanApplication):boolean => {
    let completed = true;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        const borrower = loanApplication.borrowers[i];
        if(!borrower.declarations || (borrower.declarations && Object.keys(borrower.declarations).length !== 14)) {
          completed = false;
          break;
        }
      }
    } else {
      completed = false;
    }

    return completed;
  };

  static isGiftsCompleted = (loanApplication:LoanApplication):boolean => {
    let completed = false;
    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      for (let i = 0; i < loanApplication.borrowers.length; i++) {
        const borrower = loanApplication.borrowers[i];
        if((borrower.gifts && borrower.gifts.length > 0) || borrower.gifts === null) {
          completed = true;
          break;
        }
      }
    }
    return completed;
  };

  static borrowersToUpdateRequest = (borrowers:Borrower[]):BorrowerUpdateRequest[] => {
    let borrowersRequest:BorrowerUpdateRequest[] = [];
    borrowers.forEach(borrower => {
      borrowersRequest.push(LoanRev2Util.borrowerToUpdateRequest(borrower));
    })
    return borrowersRequest;
  };

  static borrowerToUpdateRequest = (borrower:Borrower):BorrowerUpdateRequest => {
    return _.omit(borrower, ['fullName', 'assetReports']) as BorrowerUpdateRequest;
  };
}

export default LoanRev2Util