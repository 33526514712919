import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  actions: {
    marginTop: theme.spacing(4)
  },
  propertyAddress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`,
    borderTop: `solid 1px ${theme.palette.divider}`,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  nextSteps: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`,
  },
  nextStepsTitle: {
    display: 'inline-block',
    borderBottom: `solid 2px ${theme.palette.primary.main}`,
  },
  nextStepsList: {
    padding: `${theme.spacing(1)}px 0`,
  }
});

export default styles;
