import {defaultRadius, hexToRgb} from "../index";
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    display:  'inline-block',
    backgroundColor: "rgba(" + hexToRgb(theme.palette.danger.main) + ", .15)",
    borderRadius: '50%',
    textAlign: 'center'
  },
  circle: {
    borderRadius: '50%',
  },
  rounded: {
    borderRadius: defaultRadius,
  },
  large: {
    width: '75px',
    height: '75px',
    '& .MuiIcon-root': {
      lineHeight: '75px',
      fontSize: '2rem',
      height: 'auto'
    }
  },
  standard: {
    width: '44px',
    height: '44px',
    '& .MuiIcon-root': {
      lineHeight: '44px',
      fontSize: '1.5rem',
      height: 'auto'
    }
  },
  small: {
    width: '30px',
    height: '30px',
    '& .MuiIcon-root': {
      lineHeight: '30px',
      fontSize: '1rem',
      height: 'auto'
    }
  },
  icon: {
    color: theme.palette.danger.main,
    fontSize: 26
  },
  default: {
    backgroundColor: theme.palette.neutral.main,
    color: theme.palette.neutral.contrastText,
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText
  },
  infoAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.info.main) + ", .15)",
    color: theme.palette.info.main,
  },
  infoAlt2: {
    backgroundColor: theme.palette.white,
    color: theme.palette.info.main,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  primaryAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ", .15)",
    color: theme.palette.primary.main,
  },
  primaryAlt2: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  secondaryAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.secondary.main) + ", .15)",
    color: theme.palette.secondary.main,
  },
  secondaryAlt2: {
    backgroundColor: theme.palette.white,
    color: theme.palette.secondary.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  successAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.success.main) + ", .15)",
    color: theme.palette.success.main,
  },
  successAlt2: {
    backgroundColor: theme.palette.white,
    color: theme.palette.success.main,
  },
  warn: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  warnAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.warning.main) + ", .15)",
    color: theme.palette.warning.main,
  },
  warnAlt2: {
    backgroundColor: theme.palette.white,
    color: theme.palette.warning.main,
  },
  danger: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrastText,
  },
  dangerAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.danger.main) + ", .15)",
    color: theme.palette.danger.main,
  },
  dangerAlt2: {
    backgroundColor: theme.palette.white,
    color: theme.palette.danger.main,
  },
});

export default styles;
