import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";
import {defaultRadius} from "../index";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  container: {
    background: 'rgba(22, 39, 76, .95)',
    height: '100vh'
  },
  header: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    background: 'rgba(47, 62, 96, .95)',
    borderRadius: defaultRadius,
    marginBottom: theme.spacing(3)
  },
  hero: {
    padding: `${theme.spacing(4)}px`,
  },
  headerContainer: {
    paddingTop: theme.spacing(3),
    width: '90%',
    margin: '0 auto',
    color: theme.palette.white,
    '& .MuiTypography-h2': {
      fontSize: '26px',
      color: theme.palette.white
    },
    '& .MuiTypography-body1': {
      color: '#99a0b3',
      fontSize: 20,
      lineHeight: '1.5em'
    },
    [theme.breakpoints.up("sm")]: {
      width: '75%',
      '& .MuiTypography-h2': {
        fontSize: '37px',
        color: theme.palette.white
      }
    }
  },
  setupContainer: {
    background: theme.palette.white,
    padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`
  },
  setupContent: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.up("sm")]: {
      width: '75%'
    }
  }
});

export default styles;
