import React, {Component} from 'react';
import {Table, TableHead, TableBody, TableRow, TableCell, Hidden, withStyles, Typography, List, ListItem} from '@material-ui/core';
import styles from "../theme/jss/layouts/pageStyles";
import {FormattedMessage} from "react-intl";
import {DateUtil} from "../utils";
import {AmortizationSchedule as Schedule } from "@jerseydev/orca-loans";

type Props = {
  schedule: Schedule[]
}

class AmortizationSchedule extends Component<Props> {
  render() {
    const { schedule } = this.props;

    return (
      <div>
        <Hidden smUp>
          <List>
            {schedule.map((s, i) => {
              return (
                <ListItem key={i}>
                  <div>
                    <Typography variant="h5">
                      <b>{DateUtil.formatDate(s.date)}</b>
                    </Typography>
                    <div>
                      <Typography variant="caption">
                        <FormattedMessage id="principal" />
                      </Typography>
                      <Typography variant="body1">
                        {s.principal.toMoney()}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="caption">
                        <FormattedMessage id="interest" />
                      </Typography>
                      <Typography variant="body1">
                        {s.interest.toMoney()}
                      </Typography>
                    </div>
                    {schedule[0] && schedule[0].pmi ?
                      <div>
                        <Typography variant="caption">
                          <FormattedMessage id="pmi" />
                        </Typography>
                        <Typography variant="body1">
                          {s.pmi ? s.pmi.toMoney() : '$0.00'}
                        </Typography>
                      </div>
                      : null}
                    <div>
                      <Typography variant="caption">
                        <FormattedMessage id="balance" />
                      </Typography>
                      <Typography variant="h6">
                        {s.remainingBalance.toMoney()}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
              )
            })}
          </List>
        </Hidden>
        <Hidden xsDown>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="date" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="principal" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="interest" />
                </TableCell>
                {schedule[0] && schedule[0].pmi ? <TableCell><FormattedMessage id="pmi" /></TableCell> : null }
                <TableCell>
                  <FormattedMessage id="balance" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedule.map((s, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {DateUtil.formatDate(s.date)}
                    </TableCell>
                    <TableCell>
                      {s.principal.toMoney()}
                    </TableCell>
                    <TableCell>
                      {s.interest.toMoney()}
                    </TableCell>
                    {schedule[0] && schedule[0].pmi ? <TableCell>{s.pmi ? s.pmi.toMoney() : '$0.00'}</TableCell> : null}
                    <TableCell>
                      {s.remainingBalance.toMoney()}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Hidden>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AmortizationSchedule);
