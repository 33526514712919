import React, {ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {Checkbox, FormControlLabel, Grid, WithStyles, withStyles} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import BaseForm from "../../../forms/BaseForm";
import {SubmitButton, MoneyInput, TextField, ErrorList, Select} from '../../../components';
import _ from 'lodash';
import IntlFormatter from "../../../intl";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, ErrorState, LabelValuePairType, ReduxApp} from "../../../types";
import {
  Borrower,
  BorrowerLiability,
  BorrowerLiabilityUpdateRequest,
  LoanApplication,
  LoanApplicationRequest, LoanApplicationUpdateRequest
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {AxiosResponse} from "axios";
import {ErrorUtil} from "../../../utils";

type Props = {
  loanApplication: LoanApplication,
  borrower: Borrower,
  liability?: BorrowerLiability|null,
  actions?: React.ReactNodeArray,
  onSubmit: (data:AxiosResponse<LoanApplication>) => void,
  app: ReduxApp,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  name: string,
  type: string,
  financialInstitution: string,
  accountNumber: string,
  balance: string,
  monthlyPayment: string,
  paidOffBeforeClosing: boolean
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class LiabilityForm extends BaseForm<Props, State> {
  liabilityTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      name: '',
      type: '',
      financialInstitution: '',
      accountNumber: '',
      balance: '',
      monthlyPayment: '',
      paidOffBeforeClosing: false
    };

    if(props.liability) {
      const {name, type, financialInstitution, accountNumber, balance, monthlyPayment, paidOffBeforeClosing} = props.liability;
      form = {
        name: name ? name : '',
        type: type ? type : '',
        financialInstitution: financialInstitution ? financialInstitution : '',
        accountNumber: accountNumber ? accountNumber : '',
        balance: balance ? balance.toString() : '',
        monthlyPayment: monthlyPayment ? monthlyPayment.toString() : '',
        paidOffBeforeClosing: typeof paidOffBeforeClosing === 'boolean' ? paidOffBeforeClosing : false
      }
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    props.app.data.enums.liabilityTypes.forEach(type => {
      this.liabilityTypes.push({
        value: type,
        label: IntlFormatter.formatMessage(props.intl, `liability_type_${type}`),
        helpText: IntlFormatter.formatMessage(props.intl, `liability_type_${type}_help`)
      })
    });
  }

  setLiabilityType = (type:LabelValuePairType) => {
    const form = _.clone(this.state.form);
    form.type = type ? type.value : null;
    this.setState({ form });
  };

  onSubmit = async () => {
    try {
      const {loanApplication, borrower, liability} = this.props;
      this.setState({loading:true, errors: []});
      const {name, type, financialInstitution, accountNumber, balance, monthlyPayment, paidOffBeforeClosing} = this.state.form;

      const liabilityRequestData:BorrowerLiabilityUpdateRequest = {
        _id: liability ? liability._id : undefined,
        name,
        type: type as BorrowerLiabilityUpdateRequest["type"],
        financialInstitution,
        accountNumber,
        balance: parseInt(balance),
        monthlyPayment: monthlyPayment ? parseInt(monthlyPayment) : null,
        paidOffBeforeClosing
      };

      const requestData:LoanApplicationUpdateRequest = {
        borrowers: [{
          _id: borrower._id,
          liabilities: [liabilityRequestData]
        }]
      };

      const result = await this.props.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      this.setState({loading:false}, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, actions, classes } = this.props;
    const { loading, form, errors } = this.state;


    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } }/>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField name="name"
                         label={IntlFormatter.formatMessage(intl, 'description')}
                         value={form.name}
                         fullWidth={true}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.name')}
                         helperText={IntlFormatter.formatMessage(intl, 'liability_description_help_text')} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Select name="type"
                      options={this.liabilityTypes}
                      onChange={this.setLiabilityType}
                      value={form.type ? this.liabilityTypes.find(t => t.value === form.type) : ''}
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required'),
                      ]}
                      label={IntlFormatter.formatMessage(intl, 'type')}
                      placeholder={IntlFormatter.formatMessage(intl, 'select')} />
            </Grid>
          </Grid>
        </div>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField name="financialInstitution"
                         label={IntlFormatter.formatMessage(intl, 'financial_institution')}
                         value={form.financialInstitution}
                         fullWidth={true}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.financialInstitution')}
                         validators={['required']}
                         errorMessages={[
                           IntlFormatter.formatMessage(intl, 'validation_required')
                         ]} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField name="accountNumber"
                             label={IntlFormatter.formatMessage(intl, 'account_number')}
                             value={form.accountNumber}
                             fullWidth={true}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.accountNumber')}
                             validators={['required']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required')
                             ]} />
            </Grid>
          </Grid>
        </div>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'balance')}
                          value={form.balance}
                          fullWidth={true}
                          decimalScale={0}
                          validators={['required', 'minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.balance')} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_payment')}
                          value={form.monthlyPayment}
                          fullWidth={true}
                          decimalScale={0}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.monthlyPayment')}
                          validators={['minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.mb2}>
          <FormControlLabel label={IntlFormatter.formatMessage(intl, 'paid_off_before_closing')}
                            control={
                              <Checkbox checked={form.paidOffBeforeClosing}
                                        onChange={() => this.onCheckboxChanged('form.paidOffBeforeClosing')} />
                            } />
        </div>
        <Grid container alignItems="center" justifyContent="flex-end" >
          {actions && actions.length > 0 &&
          <Grid item>
            <div className={classes.mr1}>
              {actions.map((action,i) => {
                return <span key={i}>{action}</span>
              })}
            </div>
          </Grid>
          }
          <Grid item>
            <SubmitButton loading={loading}>
              <FormattedMessage id="submit" />
            </SubmitButton>
          </Grid>
        </Grid>

      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LiabilityForm)));
