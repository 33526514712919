import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  withStyles,
  Icon,
  IconButton,
  ListItemText,
  Typography,
  Button,
  MenuItem,
  ListItemIcon,
  Menu,
  Card,
  CardContent,
  CardHeader,
  Divider,
  WithStyles, Grid, Hidden, Tooltip
} from "@material-ui/core";
import {
  DeleteDialog,
  Dialog,
  ErrorList,
  PageTitle,
  Snackbar,
  Fab, ClipboardText, TextMask, SubmitButton, NoResultsCard, AddButton
} from "../../../components";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ErrorUtil, ReduxUtil} from "../../../utils";
import {OauthClientForm} from "../forms";
import _ from 'lodash';
import {DialogState, SnackbarState, ErrorState, ActionProps, ReduxOAuthClients, ReduxAccount} from "../../../types";
import {OAuthClient} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  oauthClients: ReduxOAuthClients,
  account: ReduxAccount
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  snackbar: SnackbarState|null,
  oauthClientFormOpen: boolean,
  selectedOauthClient: OAuthClient|null,
  deleteDialog: DialogState,
  actionEl: Element|null,
  errors: ErrorState[],
  reissueDialog: DialogState,
  actionMenuEl: Element|null
}

class ApiPage extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      snackbar: null,
      oauthClientFormOpen: false,
      selectedOauthClient: null,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      actionEl: null,
      errors: [],
      reissueDialog: {
        open: false,
        loading: false,
        errors: []
      },
      actionMenuEl: null
    };
  }

  componentDidMount = () => {
    const requests = [
      this.props.routeProps.getOauthClients().send(),
      this.props.routeProps.getAccount().send()
    ];

    Promise.all(requests).then(() => {
      this.setState({ pageLoaded: true });
    }).catch(err => {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(err) });
    });
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'api');
  };

  renderTitleBar = () => {
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={9}>
          <PageTitle title={this.getPageTitle()}
                     subtitle={IntlFormatter.formatMessage(this.props.intl, 'api_page_text')}
                     dangerouslySetInnerHTML={true}
                     icon="public" />
        </Grid>
        <Grid item>
          <Hidden mdUp>
            <Tooltip title={IntlFormatter.formatMessage(this.props.intl, 'create_api_client')}>
              <Fab color="primary"
                   size="small"
                   onClick={this.onAddOauthClientClick}>
                <Icon>add</Icon>
              </Fab>
            </Tooltip>
          </Hidden>
          <Hidden smDown>
            <AddButton variant="contained"
                       color="primary"
                       onClick={this.onAddOauthClientClick}>
              <FormattedMessage id="add_api_client" />
            </AddButton>
          </Hidden>
        </Grid>
      </Grid>
    )
  };

  hideSnackbar = () => {
    this.setState({ snackbar: null });
  };

  onAddOauthClientClick = () => {
    this.setState({ oauthClientFormOpen: true });
  };

  onEditOauthClientClick = () => {
    this.setState({ actionMenuEl: null, oauthClientFormOpen: true });
  };

  onReissueOauthClientSecretClick = () => {
    const reissueDialog = _.clone(this.state.reissueDialog);
    reissueDialog.open = true;
    this.setState({ actionMenuEl: null, reissueDialog });
  };

  onReissueOauthClientSecretCancel = () => {
    const reissueDialog = _.clone(this.state.reissueDialog);
    reissueDialog.open = false;
    reissueDialog.loading = false;
    reissueDialog.errors = [];

    this.setState({ reissueDialog });
  };

  onReissueOauthClientSecretConfirm = async () => {
    const reissueDialog = _.clone(this.state.reissueDialog);
    reissueDialog.loading = true;
    reissueDialog.errors = [];
    this.setState({ reissueDialog });

    try {
      if(this.state.selectedOauthClient) {
        await this.props.routeProps.reissueOauthClientSecret(this.state.selectedOauthClient._id);
        this.props.mixpanel.track("OAuth client secret reissued");
      }
      reissueDialog.loading = false;
      reissueDialog.open = false;
      this.setState({ reissueDialog });
    } catch (e) {
      reissueDialog.loading = false;
      reissueDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({ reissueDialog });
    }
  };

  onActionMenuClick = (event:MouseEvent, oauthClient:OAuthClient) => {
    this.setState({ actionMenuEl: event.currentTarget, selectedOauthClient: _.cloneDeep(oauthClient) });
  };

  onActionMenuClose = () => {
    this.setState({ actionMenuEl: null, selectedOauthClient: null });
  };

  onOauthClientSubmit = () => {
    const snackbar:SnackbarState = {
      open: true,
      variant: 'success',
      message: IntlFormatter.formatMessage(this.props.intl, 'api_client_saved')
    };
    this.setState({ oauthClientFormOpen: false, snackbar, selectedOauthClient: null });
  };

  onOauthClientCancel = () => {
    this.setState({ oauthClientFormOpen: false, selectedOauthClient: null });
  };

  onOauthClientDeleteClick = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = true;
    this.setState({ actionMenuEl: null, deleteDialog });
  };

  onOauthClientDeleteConfirm = async () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    try {
      if(this.state.selectedOauthClient) {
        deleteDialog.loading = true;
        this.setState({ deleteDialog });
        await this.props.routeProps.deleteOauthClient(this.state.selectedOauthClient._id).send();
        this.props.mixpanel.track("OAuth client deleted");
        const snackbar:SnackbarState = {
          open: true,
          variant: 'success',
          message: IntlFormatter.formatMessage(this.props.intl, 'api_client_deleted')
        };
        deleteDialog.open = false;
        deleteDialog.loading = false;
        this.setState({ deleteDialog, snackbar, selectedOauthClient: null });
      }
    } catch (e) {
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({ deleteDialog });
    }
  };

  onOauthClientDeleteCancel = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = false;
    deleteDialog.loading = false;
    deleteDialog.errors = [];
    this.setState({ deleteDialog, selectedOauthClient: null });
  };

  render() {

    const { intl, classes, oauthClients, mixpanel } = this.props;
    const { pageLoaded, loading, snackbar, errors, oauthClientFormOpen, actionMenuEl, deleteDialog, selectedOauthClient, reissueDialog } = this.state;

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: this.getPageTitle() }
                   ]}
                   pageLoaded={pageLoaded}
                   loading={loading}>
        {snackbar &&
        <Snackbar open={!!snackbar}
                  variant={snackbar.variant}
                  onClose={this.hideSnackbar}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.hideSnackbar}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        }

        <Dialog open={reissueDialog.open}
                icon={<Icon>refresh</Icon>}
                title={IntlFormatter.formatMessage(intl, 'reissue_client_secret')}
                color="warnAlt"
                onClose={this.onReissueOauthClientSecretCancel}
                fullWidth={true}
                variant="confirm"
                maxWidth="sm">
          <div className={classes.mb3}>
            <ErrorList className={classes.mb2}
                       errors={reissueDialog.errors} />
            <div className={classes.textCenter}>
              <Typography variant="h4">
                <FormattedMessage id="are_you_sure_reissue_client_secret" />
              </Typography>

              <Typography variant="body1">
                <FormattedMessage id="existing_integrations_using_secret_will_fail" />
              </Typography>
            </div>
          </div>
          <div className={classes.center}>
            <Button onClick={this.onReissueOauthClientSecretCancel} className={classes.mr1}>
              <FormattedMessage id="cancel" />
            </Button>

            <SubmitButton loading={reissueDialog.loading}
                          onClick={this.onReissueOauthClientSecretConfirm}>
              <FormattedMessage id="yes"/>
            </SubmitButton>
          </div>
        </Dialog>

        <Dialog open={oauthClientFormOpen}
                icon={<Icon>public</Icon>}
                title={IntlFormatter.formatMessage(intl, selectedOauthClient ? 'edit_api_client' : 'add_api_client')}
                color="primaryAlt"
                fullWidth={true}
                maxWidth="sm"
                onClose={this.onOauthClientCancel}>
          <OauthClientForm oauthClient={selectedOauthClient}
                           mixpanel={mixpanel}
                           onSubmit={this.onOauthClientSubmit}
                           onCancel={this.onOauthClientCancel} />
        </Dialog>

        <DeleteDialog open={deleteDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'delete_api_client')}
                      item={selectedOauthClient ? selectedOauthClient.name : ''}
                      confirmationMessage={IntlFormatter.formatMessage(intl, 'are_you_sure_delete_api_client')}
                      onCancel={this.onOauthClientDeleteCancel}
                      onSubmit={this.onOauthClientDeleteConfirm} />

        <Menu anchorEl={actionMenuEl}
              open={Boolean(actionMenuEl)}
              onClose={this.onActionMenuClose}>
          <MenuItem onClick={this.onEditOauthClientClick}>
            <ListItemIcon>
              <Icon>mode_edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')}/>
          </MenuItem>
          <MenuItem onClick={this.onReissueOauthClientSecretClick}>
            <ListItemIcon>
              <Icon>refresh</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'reissue_client_secret')}/>
          </MenuItem>
          <MenuItem onClick={this.onOauthClientDeleteClick}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')}/>
          </MenuItem>
        </Menu>

        <div className={classes.content}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } } />
          <div>
            {ReduxUtil.hasData(oauthClients) &&
            <div>
              {oauthClients.data.map((oauthClient, i) => {
                return (
                  <Card key={i} className={classes.mb3}>
                    <CardHeader title={oauthClient.name} action={
                      <IconButton size="small" onClick={(event) => this.onActionMenuClick(event, oauthClient)}>
                        <Icon>more_vert</Icon>
                      </IconButton>
                    } />
                    <Divider />
                    <CardContent>
                      <div className={classes.mb1}>
                        <Typography variant="body2">
                          <ClipboardText value={oauthClient.clientId}>
                            <b><FormattedMessage id="client_id" />:</b> {oauthClient.clientId}
                          </ClipboardText>
                        </Typography>
                      </div>
                      <div className={classes.row}>
                        <Typography variant="body2">
                          <ClipboardText value={oauthClient.clientSecret}>
                            <b><FormattedMessage id="client_secret" />:</b> <TextMask>{oauthClient.clientSecret}</TextMask>
                          </ClipboardText>
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                )
              })}
            </div>
            }

            {(!loading && ReduxUtil.hasData(oauthClients) && oauthClients.data.length === 0) &&
              <NoResultsCard message={IntlFormatter.formatMessage(intl, 'no_api_clients_found')}>
                <AddButton color="primary"
                           variant="contained"
                           onClick={this.onAddOauthClientClick}>
                  <FormattedMessage id="add_api_client" />
                </AddButton>
              </NoResultsCard>
            }
          </div>
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    oauthClients: state.oauthClients,
    account: state.account
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(ApiPage)));
