import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  root: {
    '& .OlCard-content': {
      [theme.breakpoints.up("lg")]: {
        minHeight: 150
      },
      [theme.breakpoints.down("xs")]: {
        display: 'block',
        flexDirection: 'unset',
        alignItems: 'center',
        textAlign: 'center'
      }
    }
  }
});

export default styles;
