import React, {ChangeEvent} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {Typography, Grid, TextField} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import config from "../config";
import _ from 'lodash';
import {Icon, InputAdornment} from "@material-ui/core";
import axios from "axios";
import Script from "react-load-script";
import {injectIntl, WrappedComponentProps} from "react-intl";
import IntlFormatter from "../intl";
import {v4 as uuidv4} from 'uuid';
import {FixMeLater, FormattedPlacesResult, GoogleAddressMap} from "../types";

type AddressResult = {
  streetNumber?: string,
  streetName?: string,
  unit?: string,
  city?: string,
  province?: string,
  postalCode?: string,
  county?: string,
  country?: string,
  formatted?: string
}

type DefaultProps = {
  variant?: 'filled'|'outlined'|'standard'
}

type Props = {
  label?: string,
  value: string,
  error?: boolean,
  onChange?: (address:AddressResult|null) => void,
  onOpen?: () => void,
  onClose?: () => void,
} & WrappedComponentProps
  & Partial<DefaultProps>

const autocompleteId:string = `google-auto-complete-${uuidv4()}`
const autocompleteService:{ current: google.maps.places.AutocompleteService|null } = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

function GooglePlacesAutoComplete(props:Props) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState<string>('');
  const [options, setOptions] = React.useState<any[]>([]);
  const loaded = React.useRef<boolean>(false);
  const {intl, onChange, onOpen, onClose, value, ...rest} = props;

  const onScriptLoaded = () => {
    loaded.current = true;
  };

  const handleChange = (event:ChangeEvent<{value:any}>) => {
    setInputValue(event.target.value);
  };

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        if(autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }
      }, 200),
    [],
  );

  const onAutocompleteChange = async (event:ChangeEvent, value:google.maps.places.AutocompletePrediction) => {
    let address:AddressResult|null = null;
    if(value) {
      const geocodeResult = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?key=${config.google.placesApiKey}&place_id=${value.place_id}`);
      if(geocodeResult && geocodeResult.data && geocodeResult.data.results && geocodeResult.data.results.length > 0) {
        address = formatGoogleAddress(geocodeResult.data.results[0]);
      }
    }
    if(onChange) {
      onChange(address ? _.cloneDeep(address) : null);
    }
  };

  const formatGoogleAddress = (googleAddress:google.maps.GeocoderResult) => {
    const componentForm:GoogleAddressMap = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      administrative_area_level_2: 'long_name',
      postal_code: 'short_name',
      country: 'short_name'
    };

    const addressObj:FixMeLater = {};

    for (let i = 0; i < googleAddress.address_components.length; i++) {
      const addressType = googleAddress.address_components[i].types[0];
      if (componentForm[addressType as keyof GoogleAddressMap]) {
        // @ts-ignore
        addressObj[addressType as keyof AddressResult] = googleAddress.address_components[i][componentForm[addressType as keyof GoogleAddressMap]]
      }
    }

    //console.log(googleAddress, addressObj);

    const address:FormattedPlacesResult = {
      streetNumber: addressObj.street_number,
      streetName: addressObj.route,
      unit: undefined, // @todo see if the google result has unit value
      city: addressObj.locality,
      province: addressObj.administrative_area_level_1,
      postalCode: addressObj.postal_code,
      county: addressObj.administrative_area_level_2,
      country: addressObj.country
    };

    //console.log(address)

    address.formatted = googleAddress.formatted_address;

    return address;
  };

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results:FixMeLater[]) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <div>
      <Script url={`https://maps.googleapis.com/maps/api/js?key=${config.google.placesApiKey}&libraries=places`}
              onLoad={onScriptLoaded}/>
      <Autocomplete
        id={autocompleteId}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        onOpen={onOpen}
        onClose={onClose}
        onChange={onAutocompleteChange}
        noOptionsText={IntlFormatter.formatMessage(intl, 'no_results_found')}
        renderInput={(params) => {
          params.InputProps.startAdornment = (
            <InputAdornment position="start">
              <Icon>search</Icon>
            </InputAdornment>
          );

          return (
            <TextField
              {...params}
              {...rest}
              onChange={handleChange}
            />
            )
        }}
        renderOption={(option) => {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match:google.maps.places.PredictionSubstring) => [match.offset, match.offset + match.length]),
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </div>
  );
}

const defaultProps:DefaultProps = {
  variant: 'outlined'
};

GooglePlacesAutoComplete.defaultProps = defaultProps;

export default injectIntl(GooglePlacesAutoComplete)