import React from 'react';
import MaskedInput from "react-text-mask";
import {TextValidator, TextFieldValidatorComponentProps} from "react-material-ui-form-validator";
import IntlFormatter from "../intl";
import {injectIntl, WrappedComponentProps} from "react-intl";
import FormComponentBase from "./FormComponentBase";
import {FixMeLater} from "../types";

type Props = TextFieldValidatorComponentProps & WrappedComponentProps;

class PhoneNumberInput extends FormComponentBase<Props, {}> {
  defaultValidators:string[] = [];
  defaultErrorMessages:string[] = [];

  constructor(props:FixMeLater) {
    super(props);

    this.defaultValidators = ['matchRegexp:^\\(?(\\d{3})\\)?[- ]?(\\d{3})[- ]?(\\d{4})$'];
    this.defaultErrorMessages = [IntlFormatter.formatMessage(props.intl, 'validation_phone_number')];
  }


  TextMaskField = (props:Props) => {
    const { intl, label, inputRef, value, ...other } = props;

    let mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ',  /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    if(value && value.includes('+1')) {
      mask = [/(.)/, /\d/, '(', /[1-9]/, /\d/, /\d/, ')', ' ',  /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={value}
        mask={mask}
        placeholderChar={'\u2000'}
        guide={false}
        showMask />
    )
  };


  render() {

    const { intl, label, validators, errorMessages, variant, size, ...rest } = this.props;

    return <TextValidator label={this.isRequired() && label ? `${label} *` : label}
                          variant={variant ? variant : 'outlined'}
                          size={size ? size : 'medium'}
                          InputProps={{ inputComponent: this.TextMaskField }}
                          validators={validators && validators.length > 0 ? [...validators, ...this.defaultValidators] : this.defaultValidators}
                          errorMessages={errorMessages && errorMessages.length > 0 ? [...errorMessages, ...this.defaultErrorMessages] : this.defaultErrorMessages}
                          {...rest} />
  }
}

export default injectIntl(PhoneNumberInput);
