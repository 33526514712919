import React, {ChangeEvent, MouseEvent} from 'react';
import Form, {SelectValidator} from '../../../forms/Form';
import {
  Grid,
  withStyles,
  MenuItem,
  Button,
  WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, TextField} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {updateAssetIntegration} from "../../../actions/integrations";
import {AccountUtil, ErrorUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ReduxApp, ActionProps, ErrorState, ReduxAccount} from "../../../types";
import {AccountRequest, AssetIntegration, AssetIntegrationRequest} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {AxiosResponse} from "axios";
import {updateAccount} from "../../../actions/account";
import {IntegrationHelpAlert} from "../components";
import clsx from "clsx";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  settings?: AssetIntegration,
  onSubmit: (data:AxiosResponse<AssetIntegration>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  account: ReduxAccount,
  updateAssetIntegration: ActionProps["updateAssetIntegration"],
  updateAccount: ActionProps["updateAccount"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  provider: AssetIntegration["provider"]|null,
  data: {
    env: AssetIntegration["data"]["env"],
    clientId: string,
    secret: string
  },
  reportHistoryDays: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

type FormMap = {
  plaid: () => void
}

class AssetSettingsForm extends BaseForm<Props, State> {
  formMap:FormMap;

  constructor(props:Props) {
    super(props);

    let form:Form = {
      provider: props.app.data.enums.assetProviders.length === 1 ? props.app.data.enums.assetProviders[0] as AssetIntegration["provider"] : null,
      data: {
        env: 'production',
        clientId: '',
        secret: ''
      },
      reportHistoryDays: '60'
    };

    if(props.settings) {
      form = _.merge(form, props.settings);
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    if(!this.state.form.reportHistoryDays) {
      this.state.form.reportHistoryDays = '60';
    }

    this.formMap = {
      plaid: this.renderPlaidFields
    };
  }

  onProviderChange = (event:ChangeEvent<{value:AssetIntegration["provider"]}>) => {
    const form = _.cloneDeep(this.state.form);
    form.provider = event.target.value;
    this.setState({ form });
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const {provider, data, reportHistoryDays} = this.state.form;
      const requestData:AssetIntegrationRequest = {
        provider: provider as AssetIntegration["provider"],
        reportHistoryDays: parseInt(reportHistoryDays),
        data: {
          env: data.env,
          clientId: data.clientId.trim(),
          secret: data.secret.trim()
        }
      };

      const result = await this.props.updateAssetIntegration(requestData).send();
      this.props.mixpanel.track(this.props.settings ? 'Asset integration updated' : 'Asset integration created');

      if(!AccountUtil.isSetupRecommendationUsed('integrations', this.props.account.data)) {
        let requestData:AccountRequest = AccountUtil.addSetupRecommendationToRequest('integrations', {}, this.props.account.data);
        await this.props.updateAccount(requestData).send();
      }
      this.setState({ loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  renderPlaidFields = () => {
    const { intl, classes } = this.props;
    const { form } = this.state;

    return (
      <div>
        <div className={classes.mb2}>
          <SelectValidator
            name="plaidEnv"
            label={IntlFormatter.formatMessage(intl, 'environment') + ' *'}
            value={form.data.env}
            fullWidth={true}
            onChange={(event:ChangeEvent<{value:string}>) => this.onSelectChange(event, 'form.data.env')}
            variant="outlined"
            validators={['required']}
            errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}>
              <MenuItem value="sandbox">
                <FormattedMessage id="sandbox" />
              </MenuItem>
              <MenuItem value="development">
                <FormattedMessage id="development" />
              </MenuItem>
              <MenuItem value="production">
                <FormattedMessage id="production" />
              </MenuItem>
          </SelectValidator>
        </div>
        <div className={classes.mb2}>
          <TextField name="plaidClientId"
                         label={IntlFormatter.formatMessage(intl, 'client_id')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.data.clientId')}
                         value={form.data.clientId}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
        </div>
        <div className={classes.mb2}>
          <TextField name="plaidSecret"
                         label={IntlFormatter.formatMessage(intl, 'secret')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.data.secret')}
                         value={form.data.secret}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
        </div>
        <div>
          <TextField name="reportHistoryDays"
                     type="number"
                     label={IntlFormatter.formatMessage(intl, 'days_in_report_history')}
                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.reportHistoryDays')}
                     value={form.reportHistoryDays}
                     fullWidth={true}
                     validators={['required', 'maxNumber:90']}
                     errorMessages={[
                       IntlFormatter.formatMessage(intl, 'validation_required'),
                       IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 90 })
                     ]} />
        </div>
      </div>
    )
  };

  render() {

    const { intl, classes, app } = this.props;
    const { form, loading, errors } = this.state;


    return (
      <div>
        {form.provider &&
        <div className={classes.mb2}>
          <IntegrationHelpAlert provider={form.provider} />
        </div>
        }
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } }/>
          {app.data.enums.assetProviders.length > 1 &&
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectValidator
                name="provider"
                label={IntlFormatter.formatMessage(intl, 'provider')}
                value={form.provider}
                fullWidth={true}
                onChange={this.onProviderChange}
                validators={['required']}
                errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}>
                {app.data.enums.assetProviders.map((provider, i) => {
                  return (
                    <MenuItem key={i} value={provider}>
                      <FormattedMessage id={provider} />
                    </MenuItem>
                  )
                })}
              </SelectValidator>
            </Grid>
          </Grid>
          }

          {form.provider && this.formMap[form.provider] &&
          <div>
            {app.data.enums.assetProviders.length === 1 &&
              <div className={clsx(classes.mb2, classes.textCenter)}>
                <img src={`/assets/img/logos/${form.provider}.png`} alt={form.provider} />
              </div>
            }
            {this.formMap[form.provider]()}
          </div>
          }

          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateAssetIntegration(data:AssetIntegrationRequest) {
    return dispatch(updateAssetIntegration(data));
  },
  updateAccount(data:AccountRequest) {
    return dispatch(updateAccount(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(AssetSettingsForm)));
