import {User} from "@jerseydev/orca-loans";

class SettingsUtil {
  static formatLoanMessage = (message:string, data:User & {company?:string}) => {
    let formattedMessage = message.replace('{firstName}', data.firstName);
    formattedMessage = formattedMessage.replace('{lastName}', data.lastName);
    formattedMessage = formattedMessage.replace('{email}', data.email);
    if(data.company) {
      formattedMessage = formattedMessage.replace('{company}', data.company);
    }
    return formattedMessage;
  };
}

export default SettingsUtil;