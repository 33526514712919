import _ from 'lodash';
import passwordGenerator from 'generate-password';
import {User, UserLean} from "@jerseydev/orca-loans";
import moment from "moment-timezone";

export default class UserUtil {
  static getInitials = (user:{firstName:string, lastName:string}) => {
    let initials = '';

    if(user.firstName) {
      initials += user.firstName.charAt(0);
    }

    if(user.lastName) {
      initials += user.lastName.charAt(0);
    }

    return initials.toUpperCase();
  };

  static getFullName = (user:User|UserLean) => {
    return `${user.firstName} ${user.lastName}`;
  };

  static isGranted = (user:User, roleKeys:string[]|string) => {
    if(typeof roleKeys === 'string') {
      roleKeys = [roleKeys];
    }
    let isGranted = false;
    for(let i=0;i<user.roles.length;i++) {
      if(roleKeys.indexOf(user.roles[i].key) > -1) {
        isGranted = true;
        break;
      }
    }
    return isGranted;
  };

  static hasRole = (user:User, roleKey:string) => {
    let hasRole = false;

    if(user && user.roles) {
      for(let i=0;i<user.roles.length;i++) {
        if(user.roles[i].key === roleKey) {
          hasRole = true;
          break;
        }
      }
    }

    return hasRole;
  };

  static isOwner = (user:User) => {
    return UserUtil.hasRole(user, 'ROLE_OWNER');
  };

  static isBranchManager = (user:User|UserLean) => {
    // @ts-ignore
    return UserUtil.hasRole(user, 'ROLE_BRANCH_MANAGER') && user.additionalProps && user.additionalProps.branch;
  };

  static isLoanOfficer = (user:User|UserLean) => {
    // @ts-ignore
    return UserUtil.hasRole(user, 'ROLE_LOAN_OFFICER') && user.additionalProps && user.additionalProps.loanOfficer;
  };

  static isBorrower = (user:User) => {
    return UserUtil.hasRole(user, 'ROLE_MEMBER');
  };

  static hasAnyRole = (user:User, roleKeys:string[]) => {
    let hasRole = false;
    const userRoleKeys = _.map(user.roles, 'key');

    for(let i=0;i<roleKeys.length;i++) {
      if(userRoleKeys.includes(roleKeys[i])) {
        hasRole = true;
        break;
      }
    }

    return hasRole;
  };

  static getPrimaryRole = (user:User) => {
    const accountOwner = user.roles.find(r => r.key === 'ROLE_OWNER');
    if(accountOwner) {
      return accountOwner;
    }

    const branchManager = user.roles.find(r => r.key === 'ROLE_BRANCH_MANAGER');
    if(branchManager) {
      return branchManager;
    }

    const loanOfficer = user.roles.find(r => r.key === 'ROLE_LOAN_OFFICER');
    if(loanOfficer) {
      return loanOfficer;
    }

    const member = user.roles.find(r => r.key === 'ROLE_MEMBER');
    if(member) {
      return member;
    }

    return null;
  };

  static generatePassword = () => {
    return passwordGenerator.generate({
      length: 10,
      uppercase: true,
      lowercase: true,
      symbols: true,
      numbers: true,
      strict: true
    });
  };

  static getAmericanTimezones = ():string[] => {
    const usTimezones:string[] = [];
    const allTimezones = moment.tz.names();
    allTimezones.forEach((tz) => {
      if(tz.includes('America')) {
        usTimezones.push(tz);
      }
    });
    return usTimezones;
  };
}
