import React, {ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {Button, Grid, WithStyles, withStyles} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, TextField, PasswordField, LoanOfficerCard} from "../../../components";
import {ErrorUtil} from "../../../utils";
import _ from 'lodash';
import {connect} from "react-redux";
import {register} from "../../../actions/auth";
import moment from 'moment-timezone';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {setDefaultLoanOfficer} from "../../../actions/loanOfficers";
import clsx from "clsx";
import Api from "../../../lib/Api";
import qs from "query-string";
import {RouteComponentProps, withRouter} from "react-router-dom";
import * as Sentry from "@sentry/browser";
import {ActionProps, ErrorState, ReduxDesign} from "../../../types";
import {LoanOfficerProfile, User, UserRegistrationRequest} from "@jerseydev/orca-loans";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../../../data/initialState";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  onSubmit?: (data:AxiosResponse<User>) => void,
  onCancel?: () => void,
  design: ReduxDesign,
  register: ActionProps["register"],
  setDefaultLoanOfficer: ActionProps["setDefaultLoanOfficer"],
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps
  & RouteComponentProps

type Form = {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  passwordConfirm: string,
  timezone: string,
  account?: string,
  defaultLoanOfficerEmail?: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  defaultLoanOfficer?: LoanOfficerProfile|null
}

class RegistrationForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: true,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        timezone: moment.tz.guess(),
        account: props.design.data.account
      },
      defaultLoanOfficer: null,
      errors: []
    };
  }

  componentDidMount = async () => {
    try {
      const params = qs.parse(this.props.location.search);
      if(params.loanOfficer) {
        const loanOfficerResult = await Api.getLoanOfficerProfileByEmail(params.loanOfficer as string);
        this.props.mixpanel.track("Loan Officer Signup Link", { email: params.loanOfficer });
        this.setState({ defaultLoanOfficer: loanOfficerResult.data });
      }

      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onSubmit = async () => {
    const userData = _.clone(this.state.form);
    const params = qs.parse(this.props.location.search);
    if(params.loanOfficer) {
      userData.defaultLoanOfficerEmail = decodeURIComponent(params.loanOfficer as string);
    }
    this.setState({ loading: true, errors: [] });
    try {
      const result = await this.props.register(userData).send();
      this.setState({ loading: false });
      this.props.setDefaultLoanOfficer(null); // clear the default loan officer after its set to the user

      const {mixpanel} = this.props;
      mixpanel.identify(result.data._id);
      mixpanel.people.set({ "$name": result.data.fullName, "$email": result.data.email });
      mixpanel.track("Account created");

      if(this.props.onSubmit) {
        this.props.onSubmit(_.cloneDeep(result));
      }
    } catch(err) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
    }
  };

  onVerify = async(token:string) => {
    try {
      await Api.verifyRecaptchaToken({ token });
    } catch (e) {
      Sentry.withScope(() => {
        Sentry.captureException(e);
      });
    }
  };

  render() {

    const { intl, classes, mixpanel } = this.props;
    const { loading, form, errors, defaultLoanOfficer } = this.state;

    return (
      <div>
        {defaultLoanOfficer &&
          <div className={classes.mb3}>
            <LoanOfficerCard loanOfficer={defaultLoanOfficer}
                             variant="condensed"
                             mixpanel={mixpanel} />
          </div>
        }

        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
          <Form onSubmit={this.onSubmit}>
            <ErrorList errors={errors}
                       className={classes.mv2}
                       onClose={() => { this.setState({ errors: [] }); } } />

            <div className={classes.mv2}>
              <TextField name="firstName"
                         label={IntlFormatter.formatMessage(intl, 'first_name')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.firstName')}
                         value={form.firstName}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </div>
            <div className={classes.mv2}>
              <TextField name="lastName"
                         label={IntlFormatter.formatMessage(intl, 'last_name')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.lastName')}
                         value={form.lastName}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </div>
            <div className={classes.mv2}>
              <TextField name="email"
                         label={IntlFormatter.formatMessage(intl, 'email')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.email')}
                         value={form.email}
                         fullWidth={true}
                         inputProps={{
                           autoCapitalize: 'none'
                         }}
                         validators={['required', 'isEmail']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
            </div>
            <div className={classes.mv2}>
              <PasswordField name="password"
                             label={IntlFormatter.formatMessage(intl, 'password')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.password')}
                             value={form.password}
                             fullWidth={true}
                             validators={['required', 'isValidPassword']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required'),
                               IntlFormatter.formatMessage(intl, 'validation_password_invalid'),
                             ]} />
            </div>
            <div className={classes.mb2}>
              <PasswordField name="passwordConfirm"
                             label={IntlFormatter.formatMessage(intl, 'confirm_password')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.passwordConfirm')}
                             value={form.passwordConfirm}
                             fullWidth={true}
                             validators={['required', `isPasswordMatch:${form.password}`]}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required'),
                               IntlFormatter.formatMessage(intl, 'validation_password_dont_match')
                             ]} />
            </div>
            <div>
              <GoogleReCaptcha onVerify={this.onVerify} />
            </div>
            <div className={clsx(classes.textCenter, classes.mt2)}>
              <Grid container justifyContent="center">
                {this.props.onCancel &&
                <Grid item>
                  <Button onClick={this.props.onCancel}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Grid>
                }
                <Grid item xs={12} sm={9} md={6}>
                  <SubmitButton loading={loading} fullWidth={true} size="large">
                    <FormattedMessage id="register"/>
                  </SubmitButton>
                </Grid>
              </Grid>
            </div>

          </Form>
        </GoogleReCaptchaProvider>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    defaultLoanOfficer: state.defaultLoanOfficer,
    design: state.design
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  register(data:UserRegistrationRequest) {
    return dispatch(register(data))
  },
  setDefaultLoanOfficer(email:string|null) {
    return dispatch(setDefaultLoanOfficer(email));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(withRouter(RegistrationForm))));

