import './css/app.css';
import {createTheme as createMuiTheme, PaletteColorOptions, ThemeOptions} from '@material-ui/core';
import _ from 'lodash';
import palette from './jss/palette';
import typography from './jss/typography';
import overrides from './overrides';
import {PaletteColor, TypeText} from "@material-ui/core/styles/createPalette";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    black: string,
    white: string,
    icon: string,
    divider: string,
    text: TypeText,
    neutral: ExtendedPaletteColor,
    danger: ExtendedPaletteColor,
    yield: ExtendedPaletteColor,
    rose: ExtendedPaletteColor,
    menu: MenuPaletteColor,
    info: PaletteColor,
    success: PaletteColor,
    warning: PaletteColor,
  }

  interface ExtendedPaletteColor extends PaletteColor {
    mainLight?: string,
    mainDark?: string,
    extraLight?: string,
    extraExtraLight?: string,
    shades?: string[],
    lightest?: string
  }

  interface MenuPaletteColor {
    background?: string,
    backgroundAlt?: string,
    contrastText?: string,
    contrastTextAlt?: string
  }

  interface PaletteOptions {
    black: string,
    white: string,
    neutral: ExtendedPaletteColor,
    danger: ExtendedPaletteColor,
    yield: ExtendedPaletteColor,
    rose: ExtendedPaletteColor,
    menu: MenuPaletteColor,
    icon: string,
    text?: Partial<TypeText>,
    info?: PaletteColorOptions,
    success?: PaletteColorOptions,
    warning?: PaletteColorOptions
  }
}

const constants = {
  gutter: 2
};

const createTheme = (themeOverrides:ThemeOptions) => {
  let theme:ThemeOptions = {
    palette,
    typography,
    overrides,
    zIndex: {
      appBar: 1200,
      drawer: 1100
    }
  };

  theme = _.merge(theme, themeOverrides);

  return createMuiTheme(theme);
};

export default { createTheme, constants };
