import React, {useEffect, useState} from 'react';
import {CircularProgress, Typography} from '@material-ui/core';
import useStyles from "../theme/jss/components/loaderStyles";

type DefaultProps = {
  delay: number
}

type Props = {
  visible: boolean
} & Partial<DefaultProps>

const Loader = (props:Props) => {
  const classes = useStyles();
  const {delay} = props;
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    let visibleTimeout:NodeJS.Timeout;
    function setVisibleVal(val:boolean) {
      if (val) {
        visibleTimeout = setTimeout(() => {
          if(val !== visible) {
            clear();
            setVisible(true);
          }
        }, delay ? delay : 300);
      } else {
        if(val !== visible) {
          clear();
          setVisible(false);
        }
      }
    }

    function clear() {
      if(visibleTimeout) {
        clearTimeout(visibleTimeout);
      }
    }

    if (!visible && props.visible) {
      setVisibleVal(true);
    } else {
      setVisibleVal(props.visible);
    }

    return clear;
  }, [visible, props.visible, setVisible, delay]);


  if(!visible) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <CircularProgress size={20} color="inherit" />
        <Typography className={classes.text}>Loading...</Typography>
      </div>
    </div>
  );
}

export default Loader;