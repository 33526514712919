import React, {MouseEvent, ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  Button,
  withStyles, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, PhoneNumberInput, SubmitButton, TextField, AvatarEditor, Select} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {updateProfile} from "../../../actions/auth";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ErrorUtil, PhoneNumberUtil, UserUtil} from "../../../utils";
import {ActionResponse, ReduxUser, ErrorState} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {User, UserProfileRequest} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  onSubmit?: (data:AxiosResponse<User>) => void,
  onCancel?: () => void,
  user: ReduxUser,
  updateProfile: (data:UserProfileRequest) => ActionResponse
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

type Form = {
  firstName: string,
  lastName: string,
  mobilePhoneNumber: string|null,
  timezone: string,
  description: string|null
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class UserProfileForm extends BaseForm<Props, State> {
  availableTimezones:string[] = [];

  constructor(props:Props) {
    super(props);
    this.availableTimezones = UserUtil.getAmericanTimezones();

    const {firstName, lastName, mobilePhoneNumber, description, timezone} = props.user.data;
    this.state = {
      loading: false,
      form: {
        firstName,
        lastName,
        mobilePhoneNumber: mobilePhoneNumber ? mobilePhoneNumber : '',
        description: description ? description : '',
        timezone
      },
      errors: []
    };
  }


  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });

      const form = _.cloneDeep(this.state.form);
      if(form.mobilePhoneNumber === '') {
        form.mobilePhoneNumber = null;
      }

      const {firstName, lastName, mobilePhoneNumber, description, timezone} = this.state.form;
      const data:UserProfileRequest = {
        firstName,
        lastName,
        mobilePhoneNumber: mobilePhoneNumber ? PhoneNumberUtil.formatPhoneNumber(mobilePhoneNumber) : null,
        description: description ? description : null,
        timezone
      };

      const result = await this.props.updateProfile(data).send();
      this.setState({ loading: false }, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes, user, mixpanel } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Form onSubmit={this.onSubmit}>
              <ErrorList errors={errors}
                         className={classes.mb2}
                         onClose={() => { this.setState({ errors: [] }); }}/>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name="firstName"
                               label={IntlFormatter.formatMessage(intl, 'first_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.firstName')}
                               value={form.firstName}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name="lastName"
                               label={IntlFormatter.formatMessage(intl, 'last_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.lastName')}
                               value={form.lastName}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name="email"
                               label={IntlFormatter.formatMessage(intl, 'email')}
                               value={user.data.email}
                               fullWidth={true}
                               disabled={true}
                               inputProps={{
                                 autoCapitalize: 'none',
                               }}
                               validators={['required', 'isEmail']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PhoneNumberInput name="mobilePhoneNumber"
                                      label={IntlFormatter.formatMessage(intl, 'mobile_phone')}
                                      value={form.mobilePhoneNumber}
                                      fullWidth={true}
                                      onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.mobilePhoneNumber')} />

                  </Grid>
                </Grid>
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Select name="timezone"
                            options={this.availableTimezones}
                            label={IntlFormatter.formatMessage(intl, 'timezone')}
                            value={form.timezone}
                            onChange={(value:string) => this.onItemChange(value, 'form.timezone')}
                            validators={['required']}
                            errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField name="description"
                             label={IntlFormatter.formatMessage(intl, 'about_me')}
                             value={form.description}
                             multiline={true}
                             rows={3}
                             fullWidth={true}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.description')} />
                </Grid>
              </Grid>

              <div className={classes.mt2}>
                <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                  {this.props.onCancel &&
                  <Grid item>
                    <Button onClick={this.props.onCancel}>
                      <FormattedMessage id="cancel" />
                    </Button>
                  </Grid>
                  }
                  <Grid item>
                    <SubmitButton loading={loading}>
                      <FormattedMessage id="save" />
                    </SubmitButton>
                  </Grid>
                </Grid>
              </div>
            </Form>
            </Grid>
          <Grid item xs={12} md={4}>
            <AvatarEditor user={user.data}
                          mixpanel={mixpanel} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateProfile(data:UserProfileRequest) {
    return dispatch(updateProfile(data))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(UserProfileForm)));
