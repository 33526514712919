import moment, {Moment} from 'moment-timezone';
import {User} from "@jerseydev/orca-loans";

export default class DateUtil {
  static defaultDateFormat = 'MMMM Do, YYYY';
  static defaultDateTimeFormat = 'MMMM Do, YYYY h:mm:ss a';

  static localizeDate = (dateString:string, timezone:string) => {
    const date = moment(dateString);
    return date.tz(timezone);
  };

  static formatDate = (date:number|string|Date|Moment, format:string = 'MMMM Do, YYYY') => {
    if(!date) {
      return date;
    }

    if(Number.isInteger(date)) {
      return moment.unix(date as number).format(format);
    } else {
      return moment(date).format(format);
    }
  };

  static formatUtcDate = (date:string|Date|Moment, format:string = 'MMMM Do, YYYY') => {
    if(!date) {
      return date;
    }

    return moment(date).utc().format(format);
  };

  static formatDateTime = (datetime:string|Date|Moment, format:string = 'MMMM Do, YYYY h:mm:ss a') => {
    if(!datetime) {
      return datetime;
    }

    return moment(datetime).format(format);
  };

  static formatUserDate = (user:User, dateString:string, format:string = 'MMMM Do, YYYY') => {
    let timezone = DateUtil.getUserTimezone(user);
    const localeDate = DateUtil.localizeDate(dateString, timezone);
    return DateUtil.formatDate(localeDate, format);
  };

  static formatUserDateTime = (user:User, dateString:string, format:string = 'MMMM Do, YYYY h:mm:ss a') => {
    let timezone = DateUtil.getUserTimezone(user);
    const localeDate = DateUtil.localizeDate(dateString, timezone);
    return DateUtil.formatDateTime(localeDate, format);
  };

  static getUserTimezone = (user:User) => {
    let timezone = moment.tz.guess();
    if(user.timezone) {
      timezone = user.timezone;
    }

    return timezone;
  };
}
