import React, {Component} from 'react';
import {UserAvatar} from "./index";
import {LoanOfficer} from "@jerseydev/orca-loans";
import {UserAvatarProps} from "./UserAvatar";

type Props = {
  loanOfficer: LoanOfficer,
} & Omit<UserAvatarProps, 'user'>

class LoanOfficerAvatar extends Component<Props> {
  render() {
    const { loanOfficer, ...rest } = this.props;
    return <UserAvatar user={loanOfficer.user} {...rest} />
  }
}

export default LoanOfficerAvatar;
