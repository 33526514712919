import {createStyles, Theme} from "@material-ui/core";
import {defaultRadius} from "../index";
import {makeStyles} from "@material-ui/styles";

const styles = (theme:Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    cursor: 'pointer',
    borderRadius: defaultRadius,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '&:hover': {
      background: theme.palette.neutral.extraLight
    }
  },
  progressCircle: {
    [theme.breakpoints.up("sm")]: {
      height: 50,
      width: 50,
    },
    height: 40,
    width: 40,
    marginRight: theme.spacing(1)
  },
  checkAllContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  }
});

export default makeStyles(styles);
