import React, {Component} from 'react';
import {
  Icon, WithStyles,
  withStyles
} from '@material-ui/core';
import styles from "../theme/jss/components/messageListStyles";
import {connect} from "react-redux";
import {UserAvatar, Dot} from '../components'
import clsx from "clsx";
import moment from 'moment';
import IntlFormatter from "../intl";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {ErrorUtil} from "../utils";
import {readSecureMessage} from "../actions/secureMessages";
import {SecureMessage} from "@jerseydev/orca-loans";
import {ActionResponse, ReduxUser, ErrorState} from "../types";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";

type Props = {
  secureMessages: SecureMessage[],
  user: ReduxUser,
  readSecureMessage: (threadId:string, id:string) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  loading: boolean,
  errors: ErrorState[]
}

class MessageList extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      errors: []
    }
  }

  onSetMessageStatus = async (message:SecureMessage, status:'read'|'unread') => {
    try {
      this.props.readSecureMessage(message.thread._id, message._id);
      this.setState({loading:false});
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  formatDate = (date:string|Date) => {
    const { intl } = this.props;
    const momentDate = moment(date);
    let formattedDate = momentDate.format('h:mma') + ' | ';
    const daysDiff = moment().diff(date, 'days');
    if(daysDiff === 0) {
      formattedDate += IntlFormatter.formatMessage(intl, 'today')
    } else if (daysDiff === 1) {
      formattedDate += IntlFormatter.formatMessage(intl, 'yesterday')
    } else {
      formattedDate += momentDate.format('MMM Do, YYYY');
    }
    return formattedDate;
  };

  render() {
    const { secureMessages, classes, user } = this.props;
    const { loading } = this.state;

    if(loading) {
      return null;
    }

    return (
      <div className={classes.root}>
        {secureMessages.map((message, i) => {
          return (
            <div key={i} className={clsx(classes.item, message.from._id === user.data._id ? classes.itemRight : null)}>
              <div className={classes.messageContainer}>
                <div className={message.from._id === user.data._id ? classes.right : classes.left}>
                  <UserAvatar user={message.from}
                              size="large"
                              popoverEnabled={false} />
                  <div className={classes.messageInnerContainer}>
                    <div className={classes.message}>
                      {message.message}
                    </div>
                    <div className={classes.created}>
                      {(!loading && !message.readBy.includes(user.data.email) && message.from._id !== user.data._id) &&
                      <Dot color="info"
                           onClick={() => this.onSetMessageStatus(message, message.readBy.includes(user.data.email) ? 'unread' : 'read')} />
                      }
                      <Icon>schedule</Icon>
                      <div className={classes.createdText}>{this.formatDate(message.created)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  readSecureMessage(threadId:string, id:string) {
    return dispatch(readSecureMessage(threadId, id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(MessageList)));
