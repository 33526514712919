import React, {MouseEvent} from 'react';
import {
  Button,
  Typography,
  Grid,
  Icon,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Card,
  withStyles,
  CardHeader,
  CardContent,
  MenuItem,
  ListItemIcon,
  Menu,
  WithStyles
} from '@material-ui/core';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {LoanApplicationPage} from "../layouts";
import {ErrorUtil, LoanRev2Util, LoanUtil, ReduxUtil} from "../../../utils";
import {CurrentEmploymentForm, PreviousEmploymentForm} from "../forms";
import _ from 'lodash';
import {Redirect, RouteComponentProps} from "react-router-dom";
import BaseLoanApplicationPage from "./BaseLoanApplicationPage";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {DeleteDialog, ErrorList, Dialog, Fab, Alert} from "../../../components";
import {LoanApplicationControls} from "../components";
import {
  ActionProps,
  DialogState,
  ErrorState,
  ReduxLoanApplication,
  ReduxLoanSettings
} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";
import {
  Borrower,
  BorrowerCurrentEmployment,
  BorrowerEmployment,
  BorrowerPreviousEmployment,
  DeletePathsRequest,
} from "@jerseydev/orca-loans";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  loading: boolean,
  formDialog: DialogState,
  deleteDialog: DialogState,
  selectedBorrower: Borrower|null,
  selectedEmployment: BorrowerCurrentEmployment|BorrowerPreviousEmployment|null,
  selectedEmploymentType: 'current'|'previous'|null,
  redirectTo?: string,
  errors: ErrorState[],
  actionMenuEl: Element|null,
  isStepCompleted?: boolean
}

class EmploymentPage extends BaseLoanApplicationPage<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      formDialog: {
        open: false,
        loading: false,
        errors: []
      },
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      selectedBorrower: null,
      selectedEmployment: null,
      selectedEmploymentType: 'current',
      errors: [],
      actionMenuEl: null
    };
  }

  static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: Readonly<State>){
    if(nextProps.loanApplication && nextProps.loanApplication.data && prevState.isStepCompleted === undefined){
      return {isStepCompleted: LoanRev2Util.isEmploymentCompleted(nextProps.loanApplication.data)};
    }
    return null;
  };

  componentWillUnmount = () => {
    const {mixpanel, loanApplication} = this.props;
    if(loanApplication.data && !this.state.isStepCompleted && LoanRev2Util.isEmploymentCompleted(loanApplication.data)) {
      mixpanel.track("Loan application step completed", {step: 'employment'});
    }
  };

  showFormDialog = (event:MouseEvent, employmentType?:'current'|'previous', borrower?:Borrower) => {
    event.preventDefault();
    const state:any = {
      formDialog: {
        open: true
      },
      actionMenuEl: null
    }

    if(employmentType) {
      state.selectedEmploymentType = employmentType;
    }

    if(borrower) {
      state.selectedBorrower = borrower;
    }

    this.setState(state)
  };

  hideFormDialog = () => {
    this.setState({
      formDialog: {
        open: false,
        loading: false,
        errors: []
      },
      selectedBorrower: null,
      selectedEmployment: null,
      selectedEmploymentType: 'current'
    });
  };

  showDeleteDialog = () => {
    this.setState({
      deleteDialog: {
        open: true,
        loading: false,
        errors: []
      },
      actionMenuEl: null
    });
  };

  hideDeleteDialog = () => {
    this.setState({
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      selectedBorrower: null,
      selectedEmployment: null,
      selectedEmploymentType: 'current'
    });
  };

  deleteEmployment = async () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    try {
      deleteDialog.loading = true;
      this.setState({deleteDialog});

      const {loanApplication} = this.props;
      const {selectedBorrower, selectedEmployment} = this.state;

      if(loanApplication.data.borrowers && selectedBorrower && selectedEmployment) {
        const borrowerIndex = loanApplication.data.borrowers.findIndex(b => b._id === selectedBorrower._id);
        let requestData:DeletePathsRequest = [];
        if("ownership" in selectedEmployment) {
          if(selectedBorrower.currentEmployment) {
            const employmentIndex = selectedBorrower.currentEmployment.findIndex(e => e._id === selectedEmployment._id);
            requestData = [`borrowers[${borrowerIndex}].currentEmployment[${employmentIndex}]`];
          }
        } else {
          if(selectedBorrower.previousEmployment) {
            const employmentIndex = selectedBorrower.previousEmployment.findIndex(e => e._id === selectedEmployment._id);
            requestData = [`borrowers[${borrowerIndex}].previousEmployment[${employmentIndex}]`];
          }
        }

        await this.props.routeProps.updateLoanApplication(loanApplication.data._id, requestData, {merge:'delete'}).send();
      }

      this.setState({
        deleteDialog: {
          loading: false,
          open: false,
          errors: []
        },
        selectedBorrower: null,
        selectedEmployment: null,
        selectedEmploymentType: 'current'
      });
    } catch (e) {
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({deleteDialog});
    }
  };

  onEmploymentFormSubmit = () => {
    this.setState({
      loading: false,
      formDialog: {
        open: false,
        loading: false,
        errors: []
      },
      selectedBorrower: null,
      selectedEmployment: null,
      selectedEmploymentType: 'current'
    });
  };

  onNextClick = () => {
    this.setState({ loading: false, redirectTo: this.nextMenuItem.to });
  };

  onShowActionMenu = (event:MouseEvent, borrower:Borrower, form:BorrowerEmployment, employmentType:'current'|'previous') => {
    this.setState({
      actionMenuEl: event.currentTarget,
      selectedBorrower: borrower,
      selectedEmploymentType: employmentType,
      selectedEmployment: form
    });
  };

  onCloseActionMenu = () => {
    this.setState({
      actionMenuEl: null,
      selectedBorrower: null,
      selectedEmployment: null,
      selectedEmploymentType: 'current'
    });
  };

  render() {

    const { intl, classes, loanApplication } = this.props;
    const { loading, formDialog, deleteDialog, selectedEmployment, selectedEmploymentType, redirectTo, errors, actionMenuEl, selectedBorrower } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <LoanApplicationPage menuId="employment"
                           loading={loading}>
        <Dialog open={formDialog.open}
                icon={<Icon>work</Icon>}
                title={IntlFormatter.formatMessage(intl, selectedEmployment ? `edit_${selectedEmploymentType}_employment` : `add_${selectedEmploymentType}_employment`)}
                color="primaryAlt"
                onClose={this.hideFormDialog}
                fullWidth={true}
                maxWidth="md">
          {(selectedBorrower && selectedEmploymentType === 'current') &&
            <CurrentEmploymentForm loanApplication={loanApplication.data}
                                   borrower={selectedBorrower}
                                   employment={selectedEmployment as BorrowerCurrentEmployment}
                                   onSubmit={this.onEmploymentFormSubmit}
                                   actions={[
                                     <Button onClick={this.hideFormDialog}>
                                       <FormattedMessage id="cancel" />
                                     </Button>
                                   ]}/>
          }
          {(selectedBorrower && selectedEmploymentType === 'previous') &&
            <PreviousEmploymentForm loanApplication={loanApplication.data}
                                    borrower={selectedBorrower}
                                    employment={selectedEmployment as BorrowerPreviousEmployment}
                                    onSubmit={this.onEmploymentFormSubmit}
                                    actions={[
                                      <Button onClick={this.hideFormDialog}>
                                        <FormattedMessage id="cancel" />
                                      </Button>
                                    ]}/>
          }
        </Dialog>

        <DeleteDialog open={deleteDialog.open}
                      loading={deleteDialog.loading}
                      errors={deleteDialog.errors}
                      title={IntlFormatter.formatMessage(intl, `delete_${selectedEmploymentType}_employment`)}
                      item={selectedEmployment ? selectedEmployment.name : ''}
                      onCancel={this.hideDeleteDialog}
                      onSubmit={this.deleteEmployment} />

        <Menu anchorEl={actionMenuEl}
              open={Boolean(actionMenuEl)}
              onClose={this.onCloseActionMenu}>

          <MenuItem onClick={event => this.showFormDialog(event)}>
            <ListItemIcon>
              <Icon>mode_edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
          </MenuItem>
          <MenuItem onClick={this.showDeleteDialog}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')} />
          </MenuItem>
        </Menu>


        <div className={classes.content}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } } />

          {(ReduxUtil.hasData(loanApplication) && loanApplication.data.borrowers) &&
          <Grid container spacing={4}>
            {loanApplication.data.borrowers.map((borrower, i) => {
              return (
                // @ts-ignore
                <Grid key={i} item xs={12} sm={12} md={loanApplication.data.borrowers!.length > 1 ? Math.floor(12 / loanApplication.data.borrowers!.length) : 6}>
                  <Card className={classes.card}>
                    <CardHeader title={`${borrower.firstName} ${borrower.lastName}`}
                                subheader={IntlFormatter.formatMessage(intl, borrower.primaryBorrower ? 'borrower' : 'co_borrower')}
                                avatar={<Icon>{borrower.primaryBorrower ? 'person' : 'person_outline'}</Icon>} />
                    <Divider />

                    <CardContent>
                      {LoanUtil.getBorrowerTotalYearsEmployed(loanApplication.data, borrower) < 2 &&
                      <div className={classes.mb2}>
                        <Alert severity="warning">
                          <Typography variant="caption">
                            <FormattedMessage id="two_years_employment_required" />
                          </Typography>
                        </Alert>
                      </div>
                      }

                      <div className={classes.mb4}>
                        <Grid container justifyContent="space-between" alignItems="center">
                          <Grid item>
                            <Typography variant="subtitle1" gutterBottom>
                              <FormattedMessage id="current_employment" />
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Fab color="primary"
                                 size="small"
                                 flat
                                 rounded
                                 onClick={event => this.showFormDialog(event, 'current', borrower)}>
                              <Icon>add</Icon>
                            </Fab>
                          </Grid>
                        </Grid>

                        {borrower.currentEmployment && borrower.currentEmployment.length > 0 &&
                          <List>
                            {borrower.currentEmployment.map((form, i) => {
                              return (
                                <ListItem key={i}>
                                  <ListItemText primary={form.name}
                                                secondary={form.title} />
                                  <ListItemSecondaryAction>
                                    <IconButton onClick={event => this.onShowActionMenu(event, borrower, form, 'current')}>
                                      <Icon>more_vert</Icon>
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            })}
                          </List>
                        }
                        {(!borrower.currentEmployment || (borrower.currentEmployment && borrower.currentEmployment.length === 0)) &&
                          <Button variant="outlined"
                                  color="primary"
                                  onClick={event => this.showFormDialog(event, 'current', borrower)}>
                            <FormattedMessage id="add_employment"/>
                          </Button>
                        }
                      </div>
                      <div>
                        <Grid container justifyContent="space-between" alignItems="center">
                          <Grid item>
                            <Typography variant="subtitle1" gutterBottom>
                              <FormattedMessage id="previous_employment" />
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Fab color="primary"
                                 size="small"
                                 flat
                                 rounded
                                 onClick={event => this.showFormDialog(event, 'previous', borrower)}>
                              <Icon>add</Icon>
                            </Fab>
                          </Grid>
                        </Grid>
                        {borrower.previousEmployment && borrower.previousEmployment.length > 0 &&
                          <List>
                            {borrower.previousEmployment.map((form, i) => {
                              return (
                                <ListItem key={i}>
                                  <ListItemText primary={form.name}
                                                secondary={form.title}/>
                                  <ListItemSecondaryAction>
                                    <IconButton onClick={event => this.onShowActionMenu(event, borrower, form, 'previous')}>
                                      <Icon>more_vert</Icon>
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            })}
                          </List>
                        }

                        {(!borrower.previousEmployment || (borrower.previousEmployment && borrower.previousEmployment.length === 0)) &&
                          <Button variant="outlined"
                                  color="primary"
                                  onClick={event => this.showFormDialog(event, 'previous', borrower)}>
                            <FormattedMessage id="add_employment"/>
                          </Button>
                        }
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
          }
        </div>

        <div className={classes.content}>
          <LoanApplicationControls prev={this.previousMenuItem}
                                   next={{...this.nextMenuItem }}
                                   onNextClick={this.onNextClick} />
        </div>
      </LoanApplicationPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(EmploymentPage)));
