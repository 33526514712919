import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    width: '100%',
    '& > :nth-child(even)': {
      backgroundColor: theme.palette.neutral.extraLight
    }
  },
  row: {
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    }
  },
  textContainer: {
    overflow: 'hidden'
  }
});

export default styles;
