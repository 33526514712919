import React from 'react';
import {Icon, Button, withStyles, WithStyles} from '@material-ui/core';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {usePlaidLink} from 'react-plaid-link';
import {FixMeLater} from "../../../types";

type DefaultProps = {
  env: 'tartan' | 'sandbox' | 'development' | 'production'
  disabled: boolean
}

type Props = {
  token: string,
  children: React.ReactNode,
  onClick?: () => void,
  onSuccess?: (data:FixMeLater) => void,
  onExit?: () => void,
  onEvent?: () => void,
} & WithStyles<typeof pageStyles>
  & Partial<DefaultProps>

const Plaid = (props:Props) => {

  let institution:string;

  const onSuccess = (token:string) => {
    if(props.onSuccess) {
      const data = {
        institution,
        token
      };
      props.onSuccess(data);
    }
  };

  const onEvent = (eventName:string, metadata:any) => {
    institution = metadata.institution_name;
  };

  const config = {
    token: props.token,
    onSuccess: onSuccess,
    onExit: props.onExit,
    onEvent: onEvent,
  };

  const { open, ready, error } = usePlaidLink(config);

  const onClick = () => {
    open();
    if(props.onClick) {
      props.onClick();
    }
  };

  return (
    <Button color="primary"
            variant="contained"
            onClick={onClick}
            disabled={!!(!ready || error || props.disabled)}
            size="large"
            startIcon={<Icon>account_balance</Icon>}>
      {props.children}
    </Button>
  )
}

const defaultProps:DefaultProps = {
  env: 'sandbox',
  disabled: false
};

Plaid.defaultProps = defaultProps;

export default withStyles(pageStyles, { withTheme: true })(Plaid);