import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  IconButton,
  Icon,
  Button,
  Typography,
  withStyles, Link, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {
  ErrorList,
  PhoneNumberInput,
  SubmitButton,
  TextField,
  Select, Fab, Section
} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {addBranch, updateBranch} from "../../../actions/branches";
import {ErrorUtil, PhoneNumberUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {AddressFormFields} from "../../../forms";
import {ActionResponse, LabelValuePairType, PhoneNumberState, ReduxApp, ErrorState} from "../../../types";
import {Branch, BranchCreateRequest, BranchUpdateRequest, Location, UserLean} from "@jerseydev/orca-loans";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../../../data/initialState";
import {AxiosResponse} from "axios";

type Props = {
  branch?: Branch,
  onSubmit: (data:AxiosResponse<Branch>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  addBranch: (data:BranchCreateRequest) => ActionResponse,
  updateBranch: (id:string, data:BranchUpdateRequest) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  name: string,
  email: string,
  description: string,
  phoneNumbers: PhoneNumberState[],
  address?: Location|null,
  manager?: UserLean|null,
  nmlsId: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class BranchForm extends BaseForm<Props, State> {
  phoneNumberTypes:LabelValuePairType[] = [];
  branchManagerRoleId:string;

  constructor(props:Props) {
    super(props);

    let form:Form = {
      name: '',
      email: '',
      description: '',
      phoneNumbers: [],
      address: null,
      manager: null,
      nmlsId: ''
    };

    if(props.branch) {
      const {name, email, description, phoneNumbers, address, manager, nmlsId} = props.branch;
      form = {
        name,
        email,
        description: description ? description : '',
        phoneNumbers,
        address,
        manager,
        nmlsId
      }
    }

    if(!form.description) {
      form.description = '';
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    props.app.data.enums.phoneNumberTypes.forEach(type => {
      this.phoneNumberTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `phone_number_types_${type}`) })
    });
  }

  addPhoneNumber = (event:MouseEvent) => {
    event.preventDefault();
    const form = _.cloneDeep(this.state.form);
    form.phoneNumbers.push({ type: null, number: '', ext: '' });
    this.setState({ form });
  };

  removePhoneNumber = (index:number) => {
    const form = _.cloneDeep(this.state.form);
    form.phoneNumbers.splice(index, 1);
    this.setState({ form });
  };

  setPhoneNumberType = (index:number, type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.phoneNumbers[index].type = type.value;

    this.setState({ form });
  };

  onAddressChange = (address:Location) => {
    const form = _.cloneDeep(this.state.form);
    form.address = address;
    this.setState({ form });
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      let branchResult:AxiosResponse;
      const {name, email, nmlsId, description, phoneNumbers, address, manager} = this.state.form;
      if(this.props.branch && this.props.branch._id) {
        const data:BranchUpdateRequest = {
          name,
          email,
          nmlsId,
          description: description !== '' ? description : null,
          phoneNumbers: PhoneNumberUtil.stateToUpdateRequest(phoneNumbers),
          address,
          manager: manager ? manager._id : null
        }
        branchResult = await this.props.updateBranch(this.props.branch._id, data).send();
      } else {
        const data:BranchCreateRequest = {
          name,
          email,
          nmlsId,
          description: description !== '' ? description : null,
          phoneNumbers: PhoneNumberUtil.stateToCreateRequest(phoneNumbers),
          address,
          manager: manager ? manager._id : null
        }
        branchResult = await this.props.addBranch(data).send();
      }
      this.setState({ loading: false }, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(_.cloneDeep(branchResult));
        }
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <div>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <Form onSubmit={this.onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.mb2}>
                <TextField name="name"
                           label={IntlFormatter.formatMessage(intl, 'name')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.name')}
                           value={form.name}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField name="email"
                               label={IntlFormatter.formatMessage(intl, 'email')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.email')}
                               value={form.email}
                               fullWidth={true}
                               inputProps={{
                                 autoCapitalize: 'none',
                               }}
                               validators={['required', 'isEmail']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="nmlsId"
                               label={IntlFormatter.formatMessage(intl, 'nmls_id')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.nmlsId')}
                               value={form.nmlsId}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                </Grid>
              </div>

              <div className={classes.mt2}>
                <AddressFormFields address={form.address}
                                   onChange={this.onAddressChange}
                                   required={true} />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <aside className={classes.rightSidebar}>
                <Section className={classes.mb4} title={IntlFormatter.formatMessage(intl, 'phone_numbers')} actions={
                  <Fab onClick={this.addPhoneNumber} color="primary" size="small" rounded flat>
                    <Icon>add</Icon>
                  </Fab>
                }>
                  <div>
                    {form.phoneNumbers.length === 0 &&
                    <Typography variant="body1">
                      <Link href="#" onClick={this.addPhoneNumber}><FormattedMessage id="add_phone_number" /></Link>
                    </Typography>
                    }

                    {form.phoneNumbers.map((phoneNumber, i) => {
                      return (
                        <div key={i}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Select name={`phoneNumberType[${i}]`}
                                      options={this.phoneNumberTypes}
                                      onChange={(data:LabelValuePairType) => this.setPhoneNumberType(i, data)}
                                      value={phoneNumber.type ? this.phoneNumberTypes.find(t => t.value === phoneNumber.type) : ''}
                                      label={IntlFormatter.formatMessage(intl, 'type')}
                                      validators={['required']}
                                      errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                            </Grid>
                            <Grid item xs={4}>
                              <PhoneNumberInput name={`phoneNumber${i}`}
                                                label={IntlFormatter.formatMessage(intl, 'number')}
                                                value={form.phoneNumbers[i].number}
                                                fullWidth={true}
                                                onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.phoneNumbers[${i}].number`)}
                                                validators={['required']}
                                                errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField name={`phoneNumbers[${i}].ext`}
                                         label={IntlFormatter.formatMessage(intl, 'ext')}
                                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.phoneNumbers[${i}].ext`)}
                                         value={form.phoneNumbers[i].ext}
                                         fullWidth={true} />
                            </Grid>
                            <Grid item>
                              <IconButton onClick={() => this.removePhoneNumber(i)} size="small">
                                <Icon>cancel</Icon>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    })}
                  </div>
                </Section>
              </aside>
            </Grid>
          </Grid>

          <div className={classes.mt3}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
                <Grid item>
                  <Button onClick={this.props.onCancel}>
                    <FormattedMessage id="back" />
                  </Button>
                </Grid>
              }
              <Grid item>
                <SubmitButton size="large"
                              loading={loading}
                              endIcon={<Icon>arrow_forward</Icon>}>
                  <FormattedMessage id="next" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addBranch(data:BranchCreateRequest) {
    return dispatch(addBranch(data));
  },
  updateBranch(id:string, data:BranchUpdateRequest) {
    return dispatch(updateBranch(id, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(BranchForm)));
