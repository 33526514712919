import React from 'react';
import {
  Typography,
  Link,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {PairedList, Well} from "./index";
import IntlFormatter from "../intl";
import {Link as RouterLink} from "react-router-dom";
import {TextUtil} from "../utils";
import {KeyValueMixed, LoanApplication2009, LoanApplication2009HousingExpense} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009
} & WrappedComponentProps

const LoanHousingSummary = (props:Props) => {

  const {intl, loanApplication} = props;

  if(!loanApplication.housingExpenses) {
    return (
      <Well color="warn">
        <Typography variant="body1">
          <FormattedMessage id="no_housing_expenses_found" />. &nbsp;
          {!loanApplication.completed &&
          <Link component={RouterLink} to="/apply/housing">
            <FormattedMessage id="add_housing"/>
          </Link>
          }
        </Typography>
      </Well>
    )
  }

  const items:KeyValueMixed[] = [];

  const housingExpenseFields = [
    { key: 'rent', labelId: 'rent' },
    { key: 'mortgage', labelId: 'mortgage' },
    { key: 'otherFinancing', labelId: 'other_financing' },
    { key: 'hazardInsurance', labelId: 'hazard_insurance' },
    { key: 'realEstateTaxes', labelId: 'real_estate_taxes' },
    { key: 'mortgageInsurance', labelId: 'mortgage_insurance' },
    { key: 'homeownersAssociationDues', labelId: 'homeowners_association_dues' },
    { key: 'other', labelId: 'other' }
  ];

  housingExpenseFields.forEach(field => {
    items.push({
      key: IntlFormatter.formatMessage(intl, field.labelId),
      value: TextUtil.getMoneyText(loanApplication.housingExpenses![field.key as keyof LoanApplication2009HousingExpense], intl)
    })
  });

  return <PairedList items={items} />
}

export default injectIntl(LoanHousingSummary);