import React from 'react';
import {PersonAvatar} from "./index";
import {User, UserLean} from "@jerseydev/orca-loans";
import {PersonAvatarProps} from "./PersonAvatar";

export type UserAvatarProps = {
  user: User|UserLean,
} & Omit<PersonAvatarProps, "person">

const UserAvatar = (props:UserAvatarProps) => {
  const { user, ...rest } = props;
  const {_id, firstName, lastName, email, avatar} = user;
  return <PersonAvatar person={{
    firstName,
    lastName,
    email,
    avatar: avatar ? avatar.url : null,
    userId: _id
  }} {...rest} />
}


export default UserAvatar;
