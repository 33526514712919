import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {LoanApplication2009, LoanApplication, LoanApplicationSearchRequest} from "@jerseydev/orca-loans";

export function getLoanApplications(queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getLoanApplications(queryParams, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanApplications = state.loanApplications && state.loanApplications.data ? _.cloneDeep(state.loanApplications.data) : [];
      dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.FETCHING, loanApplications));

      apiRequest.then(response => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, loanApplications));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function searchLoanApplications(criteria:LoanApplicationSearchRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.searchLoanApplications(criteria, queryParams, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanApplications = state.loanApplications && state.loanApplications.data ? _.cloneDeep(state.loanApplications.data) : [];

      dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.FETCHING, loanApplications));
      apiRequest.then(response => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, loanApplications));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteLoanApplication(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteLoanApplication(id,{cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanApplications = state.loanApplications && state.loanApplications.data ? _.cloneDeep(state.loanApplications.data) : [];
      dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.DELETING, loanApplications));

      apiRequest.then(response => {
        const index = loanApplications.findIndex((l:LoanApplication2009|LoanApplication) => l._id === id);
        loanApplications.splice(index, 1);
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, loanApplications));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, loanApplications));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}
