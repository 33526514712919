import React, {Component} from 'react';
import {
  withStyles,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Icon, ListItemSecondaryAction, WithStyles
} from '@material-ui/core';
import {DateUtil, ErrorUtil, LoanUtil, LocationUtil, ReduxUtil} from "../utils";
import IntlFormatter from "../intl";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {LoanOfficerCard, LoanStatusBadge, Item, LinearProgress, ErrorList} from "./index";
import styles from "../theme/jss/components/loanSummaryStyles";
import {connect} from "react-redux";
import {Link as RouterLink} from "react-router-dom";
import {
  LoanApplication2009,
  Borrower2009,
  LoanApplication,
  Borrower
} from "@jerseydev/orca-loans";
import {ReduxState} from "../data/initialState";
import {ActionProps, ErrorState, ReduxIntegrations, ReduxLoanDocumentCategories} from "../types";
import {Mixpanel} from "mixpanel-browser";
import {MixpanelConsumer} from "react-mixpanel";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {getLoanDocumentCategories} from "../actions/loanDocumentCategories";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  actions?: React.ReactNode,
  integrations: ReduxIntegrations,
  loanDocumentCategories: ReduxLoanDocumentCategories,
  getLoanDocumentCategories: ActionProps["getLoanDocumentCategories"]
} & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  loading: boolean,
  errors: ErrorState[]
}

class LoanSummary extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: true,
      errors: []
    }
  }

  componentDidMount = async () => {
    const {loanDocumentCategories} = this.props;
    if(!ReduxUtil.hasData(loanDocumentCategories)) {
      try {
        await this.props.getLoanDocumentCategories().send();
      } catch (e) {
        this.setState({errors: ErrorUtil.formatErrors(e)});
      }
    }

    this.setState({loading:false});
  };

  render() {
    const { intl, classes, loanApplication, actions, integrations, loanDocumentCategories } = this.props;
    const { errors, loading } = this.state;
    const percentComplete = LoanUtil.getCompletedPercent(loanApplication);

    if(loading) {
      return null;
    }
    return (
      <div>
        <ErrorList errors={errors}
                   className={classes.mb2} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <div className={classes.mb2}>
              <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                <Grid item xs={8} sm={8} md={9}>
                  <LinearProgress variant="determinate" value={percentComplete} color="primary"/>
                </Grid>
                <Grid item xs={4} sm={4} md={3}>
                  <div className={classes.textRight}>
                    <LoanStatusBadge status={loanApplication.status} />
                  </div>
                </Grid>
              </Grid>
            </div>

            {(loanApplication.property && loanApplication.property.address) &&
            <div className={classes.propertyAddress}>
              <Typography variant="caption">
                <FormattedMessage id="property_address" />
              </Typography>
              <Typography variant="h4">
                {LocationUtil.formatLocation(loanApplication.property.address)}
              </Typography>
            </div>
            }

            <Grid container spacing={4}>
              {loanApplication.borrowers && loanApplication.borrowers.map((borrower:Borrower2009|Borrower, i:number) => {
                return (
                  <Grid key={i} item>
                    <Item label={IntlFormatter.formatMessage(intl, borrower.primaryBorrower ? 'borrower' : 'co_borrower')}
                          icon={borrower.primaryBorrower ? 'person' : 'person_outline'}>
                      <Typography variant="body1">
                        {borrower.firstName} {borrower.lastName}
                      </Typography>
                    </Item>

                  </Grid>
                )
              })}
              <Grid item>
                <Item label={IntlFormatter.formatMessage(intl, 'loan_amount')}
                      icon="attach_money">
                  <Typography variant="body1">
                    {loanApplication.loan && loanApplication.loan.loanAmount ? loanApplication.loan.loanAmount.toMoney() : IntlFormatter.formatMessage(intl, 'na')}
                  </Typography>
                </Item>
              </Grid>
              <Grid item>
                <Item label={IntlFormatter.formatMessage(intl, 'loan_type')}
                      icon="account_balance_wallet">
                  <Typography variant="body1">
                    {IntlFormatter.formatMessage(intl,loanApplication.loan && loanApplication.loan.loanType ?  `loan_types_${loanApplication.loan.loanType}` : 'na')}
                  </Typography>
                </Item>
              </Grid>
              <Grid item>
                <Item label={IntlFormatter.formatMessage(intl, 'loan_purpose')}
                      icon="house">
                  <Typography variant="body1">
                    {IntlFormatter.formatMessage(intl,loanApplication.loan && loanApplication.loan.loanPurpose ? loanApplication.loan.loanPurpose : 'na')}
                  </Typography>
                </Item>
              </Grid>
              <Grid item>
                <Item icon="today"
                      label={IntlFormatter.formatMessage(intl, 'started')}>
                  <Typography variant="body1">
                    {DateUtil.formatDate(loanApplication.created)}
                  </Typography>
                </Item>
              </Grid>
              {loanApplication.completed &&
              <Grid item>
                <Item icon="event"
                      label={IntlFormatter.formatMessage(intl, 'completed')}>
                  <Typography variant="body1">
                    {DateUtil.formatDate(loanApplication.completed)}
                  </Typography>
                </Item>
              </Grid>
              }
            </Grid>


            {loanApplication.completed && loanApplication.status !== 'closed' &&
              <div className={classes.nextSteps}>
                <Typography variant="h5" className={classes.nextStepsTitle}>
                  <FormattedMessage id="next_steps" />
                </Typography>
                <div className={classes.nextStepsList}>
                  <List>
                    {!LoanUtil.hasAllRequiredDocuments(loanApplication, loanDocumentCategories.data, integrations.data) &&
                      <ListItem component={RouterLink} to={`/loan-applications/detail/${loanApplication._id}?tab=documents`} button>
                        <ListItemIcon>
                          <Icon className={classes.warning}>cloud_upload</Icon>
                        </ListItemIcon>
                        <ListItemText primary={IntlFormatter.formatMessage(intl, 'add_required_docs')} />
                        <ListItemSecondaryAction>
                          <Button variant="outlined" size="small" component={RouterLink} to={`/loan-applications/detail/${loanApplication._id}?tab=documents`}>
                            <FormattedMessage id="upload" />
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    }
                    {LoanUtil.hasAllRequiredDocuments(loanApplication, loanDocumentCategories.data, integrations.data) &&
                      <ListItem>
                        <ListItemIcon>
                          <Icon className={classes.success}>check_circle</Icon>
                        </ListItemIcon>
                        <ListItemText primary={IntlFormatter.formatMessage(intl, 'we_have_everything_we_need_at_the_moment')} />
                      </ListItem>
                    }
                  </List>
                </div>
              </div>
            }

            {actions &&
              <div className={classes.actions}>
                {actions}
              </div>
            }
          </Grid>

          {loanApplication.loanOfficer &&
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <MixpanelConsumer>
                {(mixpanel:Mixpanel) => {
                  return <LoanOfficerCard loanOfficer={loanApplication.loanOfficer!}
                                          orientation="horizontal"

                                          mixpanel={mixpanel} />
                }}
              </MixpanelConsumer>
            </Grid>
          }
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    integrations: state.integrations,
    loanDocumentCategories: state.loanDocumentCategories
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getLoanDocumentCategories() {
    return dispatch(getLoanDocumentCategories());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(LoanSummary)));
