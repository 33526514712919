import React, {useEffect, useState} from 'react';
import {
  Breadcrumbs,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Link,
  Typography,
  WithStyles,
  withStyles,
  CircularProgress
} from '@material-ui/core';
import EncompassApi from "../../../lib/EncompassApi";
import _ from 'lodash';
import styles from "../../../theme/jss/components/encompassLoanTemplateViewerStyles";
import {FormattedMessage, WrappedComponentProps, injectIntl} from "react-intl";
import IntlFormatter from "../../../intl";
import {ErrorState} from "../../../types";
import {ErrorUtil} from "../../../utils";
import {ErrorList} from "../../../components";

export type EncompassLoanTemplateViewerProps = {
  clientId: string,
  clientSecret: string,
  instanceId: string,
  username: string,
  password: string,
  onTemplateClick: (item:EncompassLoanTemplateItem) => void
}

type Props = EncompassLoanTemplateViewerProps & WithStyles<typeof styles>
  & WrappedComponentProps

export type EncompassLoanTemplateItem = {
  entityId: string,
  entityName: string,
  entityType: 'Folder'|'File',
  entityUri?: string
}

const EncompassLoanTemplateViewer = (props:Props) => {
  const {classes, intl, onTemplateClick, clientId, clientSecret, instanceId, username, password} = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<EncompassLoanTemplateItem[]>([]);
  const [folderBreadcrumbs, setFolderBreadcrumbs] = useState<string[]>(['Public']);
  const [errors, setErrors] = useState<ErrorState[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authResult = await EncompassApi.getToken(clientId, clientSecret, instanceId, username, password);
        EncompassApi.token = authResult.data.access_token;
        await setLoanTemplateItems(folderBreadcrumbs);
        setLoading(false);
      } catch (e) {
        setErrors(ErrorUtil.formatErrors(e));
      }
    }

    fetchData();
  }, [folderBreadcrumbs, clientId, clientSecret, instanceId, username, password]);

  const setLoanTemplateItems = async (breadcrumbs:string[]) => {
    try {
      setLoading(true);
      const folder = breadcrumbs.join("\\");
      const folderResult = await EncompassApi.getLoanTemplateFolders(folder.toLowerCase());
      const templateResult = await EncompassApi.getLoanTemplateFiles(folder + '\\');
      let items:EncompassLoanTemplateItem[] = [...folderResult.data, ...templateResult.data];
      //console.log(items);
      setLoading(false);
      setItems(items);
    } catch (e) {
      setErrors(ErrorUtil.formatErrors(e));
    }
  };

  const onItemClick = async (item:EncompassLoanTemplateItem) => {
    if(item.entityType === 'Folder') {
      const newFolderBreadcrumbs = _.clone(folderBreadcrumbs);
      newFolderBreadcrumbs.push(item.entityName);
      setFolderBreadcrumbs(newFolderBreadcrumbs);
      await setLoanTemplateItems(newFolderBreadcrumbs);
    } else {
      onTemplateClick(item);
    }
  };

  const onBreadcrumbLinkClick = (index:number) => {
    const newFolderBreadcrumbs = folderBreadcrumbs.slice(0, index + 1);
    setFolderBreadcrumbs(newFolderBreadcrumbs);
  };

  return (
    <div>
      <div className={classes.breadcrumbsContainer}>
        <Typography variant="caption">
          <FormattedMessage id="folder" />
        </Typography>
        <Breadcrumbs>
          {folderBreadcrumbs.map((folder, i) => {
            if(folderBreadcrumbs.length === (i + 1)) {
              return <Typography key={i}>{folder}</Typography>
            }
            return (
              <Link key={i}
                    className={classes.link}
                    onClick={() => onBreadcrumbLinkClick(i)}>{folder}</Link>
            )
          })}
        </Breadcrumbs>
      </div>
      {loading &&
        <div className={classes.loader}>
          <CircularProgress size={15} />
        </div>
      }
      <ErrorList errors={errors}
                 className={classes.errorList} />
      {!loading &&
        <List>
          {items.length === 0 &&
            <ListItem>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'no_results_found')} />
            </ListItem>
          }
          {items.map(item => {
            return (
              <ListItem key={item.entityId} onClick={() => onItemClick(item)} button>
                <ListItemAvatar>
                  <Icon>{item.entityType === 'Folder' ? 'folder_open' : 'article'}</Icon>
                </ListItemAvatar>
                <ListItemText primary={item.entityName} />
              </ListItem>
            )
          })}
        </List>
      }
    </div>
  )
};

export default withStyles(styles, { withTheme: true })(injectIntl(EncompassLoanTemplateViewer));