import React, {Component} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {LoanOfficerAvatar} from "./index";
import IntlFormatter from "../intl";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {LoanOfficer} from "@jerseydev/orca-loans";

type Props = {
  loanOfficer: LoanOfficer,
  subtitle?: string,
  size?: 'small'|'normal'|'large'|'extraLarge',
  popoverEnabled?: boolean
} & WrappedComponentProps

class LoanOfficerReference extends Component<Props> {
  render() {
    const { loanOfficer, subtitle, intl, ...rest } = this.props;

    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <LoanOfficerAvatar loanOfficer={loanOfficer} {...rest} />
        </Grid>
        <Grid item>
          <Typography variant="body1" color="inherit" gutterBottom={false}>
            <b>{`${loanOfficer.firstName} ${loanOfficer.lastName}`}</b>
          </Typography>
          {subtitle &&
            <Typography variant="caption" color="inherit">{subtitle}</Typography>
          }
          {!subtitle &&
          <div>
            <Typography variant="caption" color="inherit">{IntlFormatter.formatMessage(intl, 'nmls_id')} {loanOfficer.nmlsId}</Typography><br/>
            <Typography variant="caption" color="inherit">{loanOfficer.email}</Typography>
          </div>
          }

        </Grid>
      </Grid>
    );
  }
}

export default injectIntl(LoanOfficerReference);
