import React from 'react';
import MaskedInput from "react-text-mask";
import {TextField} from '@material-ui/core';
import {TextValidator, ValidatorComponentProps} from 'react-material-ui-form-validator';
import IntlFormatter from "../intl";
import {injectIntl, WrappedComponentProps} from "react-intl";
import FormComponentBase from "./FormComponentBase";
import {FixMeLater} from "../types";

type DefaultProps = {
  variant: 'filled' | 'outlined' | 'standard',
}

type Props = {
  label?: string
} & ValidatorComponentProps
  & WrappedComponentProps
  & Partial<DefaultProps>;

class SSNInput extends FormComponentBase<Props, {}> {
  static defaultProps:DefaultProps = {
    variant: 'outlined'
  };

  TextMaskField = (props:Readonly<FixMeLater>) => {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        guide={false}
        showMask />
    )
  };


  render() {
    const { intl, label, validators, errorMessages, size, ...rest } = this.props;

    if(validators && validators.length > 0) {
      return <TextValidator label={this.isRequired() && label ? `${label} *` : label}
                            InputProps={{ id: 'ssn-input', inputComponent: this.TextMaskField }}
                            size={size ? size : 'medium'}
                            validators={[...validators, 'matchRegexp:^[0-9]{3}\\-?[0-9]{2}\\-?[0-9]{4}$']}
                            // @ts-ignore
                            errorMessages={[...errorMessages, IntlFormatter.formatMessage(intl, 'validation_ssn')]}
                            {...rest} />
    }

    return (
      <TextField label={label}
                 InputProps={{ id: 'ssn-input', inputComponent: this.TextMaskField }}
                 size={size ? size : 'medium'}
                 {...rest} />
    );
  }
}

export default injectIntl(SSNInput);
