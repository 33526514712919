import React, {Component} from 'react';
import {LinearProgress as MuiLinearProgress, LinearProgressProps, WithStyles, withStyles} from '@material-ui/core';
import styles from "../theme/jss/components/linearProgressStyles";

type DefaultProps = {
  delay: number
}

type Props = {
  value: number
} & WithStyles<typeof styles>
  & LinearProgressProps
  & Partial<DefaultProps>

class LinearProgress extends Component<Props> {
  static defaultProps:DefaultProps = {
    delay: 100
  };

  render() {

    const { classes, value, ...rest} = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.progress}>
          <MuiLinearProgress value={value} {...rest} />
        </div>
        <div className={classes.text}>{value}%</div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LinearProgress);
