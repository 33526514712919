import React, {MouseEvent} from 'react';
import {
  Grid,
  withStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails, Icon, WithStyles
} from '@material-ui/core';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import {getWebHookLogs, setWebHookLogs} from "../../../actions/webHooks";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {DateUtil, ErrorUtil, SearchUtil} from "../../../utils";
import {Badge, NoResultsCard, PaginationHeader, Pagination, PropagateLoader} from "../../../components";
import {WebHookRequestLogDetail} from "./index";
import clsx from "clsx";
import _ from 'lodash';
import {WebHook, WebHookRequestLog} from "@jerseydev/orca-loans";
import {ReduxUser, ActionProps, SearchCriteriaWithPagingType, ErrorState, ReduxWebHookLogs} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import IntlFormatter from "../../../intl";

type Props = {
  webHook: WebHook,
  webHookLogs: ReduxWebHookLogs,
  user: ReduxUser,
  getWebHookLogs: ActionProps["getWebHookLogs"],
  setWebHookLogs: ActionProps["setWebHookLogs"]
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

type State = {
  loading: boolean,
  searchCriteria: SearchCriteriaWithPagingType,
  errors: ErrorState[]
}

class WebHookRequestLogsList extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: true,
      searchCriteria: {
        order: {
          column: 'created',
          direction: 'desc'
        },
        limit: 20,
        skip: 0,
        totalCount: 0,
        page: 1
      },
      errors: []
    }
  }

  componentDidMount = async () => {
    await this.getWebHookLogs();
  };

  componentWillUnmount = async () => {
    await this.props.setWebHookLogs([]);
  };

  onPageChange = (event:MouseEvent, value:number) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.skip = (value - 1) * searchCriteria.limit;
    searchCriteria.page = value;
    this.setState({ searchCriteria }, this.getWebHookLogs);
  };

  getWebHookLogs = async () => {
    try {
      this.setState({loading: true});
      const searchCriteria = _.cloneDeep(this.state.searchCriteria);
      const params = SearchUtil.getParamsFromSearchCriteria(searchCriteria);
      const result = await this.props.getWebHookLogs(this.props.webHook._id, params).send();
      searchCriteria.totalCount = parseInt(result.headers['total-count']);
      this.setState({ loading: false, searchCriteria });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { classes, intl, webHookLogs, user } = this.props;
    const { loading, searchCriteria } = this.state;
    const pages = Math.ceil(searchCriteria.totalCount / searchCriteria.limit);

    return (
      <div>
        {loading &&
          <div className={clsx(classes.textCenter, classes.pv2)}>
            <PropagateLoader loading={true} />
          </div>
        }
        {(!loading && webHookLogs && webHookLogs.data) &&
          <div>
            <div className={clsx(classes.ph2, classes.pv1)}>
              <PaginationHeader totalResults={searchCriteria.totalCount}
                                currentPage={searchCriteria.page}
                                totalPages={pages} />
            </div>
            {webHookLogs.data.map((log) => {
              return (
                <Accordion key={log._id}>
                  <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item>
                        <div className={classes.rowCenter}>
                          <div className={classes.mr1}>
                            <Badge color={log.response.statusCode < 300 ? 'success' : 'danger'} >
                              {log.response.statusCode}
                            </Badge>
                          </div>
                          <Typography variant="body1">
                            {log.request.url}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {DateUtil.formatUserDate(user.data, log.created)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <WebHookRequestLogDetail webHookRequestLog={log} />
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
        }

        {(!loading && webHookLogs.data && webHookLogs.data.length === 0) &&
          <NoResultsCard message={IntlFormatter.formatMessage(intl, 'no_logs_found')}
                         className={classes.p2} />
        }

        {pages > 1 &&
          <Pagination count={pages}
                      color="primary"
                      onChange={this.onPageChange} />
        }
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    webHookLogs: state.webHookLogs
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getWebHookLogs(id:string, params?:any) {
    return dispatch(getWebHookLogs(id, params));
  },
  setWebHookLogs(webHookLogs:WebHookRequestLog[]) {
    return dispatch(setWebHookLogs(webHookLogs));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(WebHookRequestLogsList)));
