import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  withStyles,
  Icon,
  IconButton,
  WithStyles
} from "@material-ui/core";
import {ErrorList, PageTitle, Snackbar} from "../../../components";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {GeneralSettingsForm} from "../forms";
import {ActionProps, ErrorState, SnackbarState} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoaded: boolean,
  snackbar: SnackbarState|null,
  errors: ErrorState[]
}

class GeneralSettingsPage extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: true,
      snackbar: null,
      errors: []
    };
  }

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'general_settings');
  };

  renderTitleBar = () => {
    return <PageTitle title={this.getPageTitle()}
                      subtitle={IntlFormatter.formatMessage(this.props.intl, 'general_settings_text')}
                      icon="settings" />
  };

  onSubmit = () => {
    this.setState({
      snackbar: {
        open: true,
        variant: 'success',
        message: IntlFormatter.formatMessage(this.props.intl, 'general_settings_saved')
      }
    });
  };

  hideSnackbar = () => {
    this.setState({ snackbar: null });
  };

  render() {

    const { intl, classes, mixpanel } = this.props;
    const { pageLoaded, snackbar, errors } = this.state;

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: IntlFormatter.formatMessage(intl, 'settings'), to: '/admin/settings' },
                     {title: this.getPageTitle() }
                   ]}
                   pageLoaded={pageLoaded}>

        {snackbar &&
        <Snackbar open={!!snackbar}
                  variant={snackbar.variant || 'success'}
                  onClose={this.hideSnackbar}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.hideSnackbar}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />
        }

        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <div className={classes.content}>
          <GeneralSettingsForm mixpanel={mixpanel}
                               onSubmit={this.onSubmit}/>
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {

  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(GeneralSettingsPage)));
