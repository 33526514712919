import 'date-fns';
import React from 'react';
import {FormHelperText} from '@material-ui/core';
import {ValidatorComponent, ValidatorComponentProps} from "react-material-ui-form-validator";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {FixMeLater} from "../types";

export type Props = {
  onChange: (event:FixMeLater) => void
} & ValidatorComponentProps
  & WrappedComponentProps

type State = {
  isValid: boolean
}

class DatePicker extends ValidatorComponent<Props, State> {
  onChange = (event:FixMeLater) => {
    if(this.props.onChange) {
      this.props.onChange(event);
    }
  };

  isRequired = () => {
    let required = false;
    if(this.props.validators && this.props.validators.indexOf('required') > -1) {
      required = true;
    }

    return required;
  };

  renderError = () => {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <FormHelperText error>
        {this.getErrorMessage()}
      </FormHelperText>
    );
  };

  renderValidatorComponent() {

    const { intl, validators, errorMessages, label, validatorListener, variant, size, ...rest } = this.props;
    const { isValid } = this.state;

    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            error={!isValid}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="none"
            inputVariant={variant ? variant : 'outlined'}
            size={size ? size : 'medium'}
            label={this.isRequired() && label ? `${label} *` : label}
            // @ts-ignore
            onChange={this.onChange}
            InputLabelProps={ this.props.value ? {shrink: true} : { }}
            {...rest}
          />
        </MuiPickersUtilsProvider>
        {validators && validators.length > 0 &&
        this.renderError()
        }
      </div>
    );
  }
}

export default injectIntl(DatePicker);
