import {defaultRadius, hexToRgb} from "../index";
import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  dropzone: {
    borderColor: `rgba(${hexToRgb(theme.palette.primary.main)}, .15)`,
    borderStyle: 'dashed',
    borderWidth: 2,
    backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ", .05)",
    borderRadius: defaultRadius,
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    transition: 'border-color 200ms linear',
    '&:hover': {
      borderColor: `rgba(${hexToRgb(theme.palette.primary.main)}, .35)`,
    }
  },
  dropzoneActive: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ", .10)",
    borderColor: `rgba(${hexToRgb(theme.palette.primary.main)}, .5)`,
  },
  dropzoneAccepted: {
    border: `dashed 2px ${theme.palette.primary.main}`,
    borderRadius: defaultRadius,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  dropzoneMessage: {
    cursor: 'pointer',
    padding: theme.spacing(2)
  },
  fileContainer: {
    marginBottom: theme.spacing(2)
  },
  errorContainer: {
    marginBottom: theme.spacing(2)
  },
  primaryText: {
    fontSize: '16px'
  },
  secondaryText: {
    fontSize: '11px'
  },
  orText: {
    fontSize: '13px'
  },
  or: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  imgPreview: {
    width: '100%',
    maxWidth: 100,
    marginRight: theme.spacing(2)
  },
  icon: {
    marginBottom: theme.spacing(2)
  },
  label: {
    color: theme.palette.neutral.main,
    fontWeight: 'bold'
  }
});

export default styles;
