import React from 'react';

export type FormBaseProps = {
  validators?: any[],
  errorMessages?: any[],
  isRequired?: () => boolean
}

class FormComponentBase<P, S> extends React.Component<FormBaseProps & P, S> {
  isRequired = () => {
    let required = false;
    if(this.props.validators && this.props.validators.indexOf('required') > -1) {
      required = true;
    }

    return required;
  };
}

export default FormComponentBase;
