import React from 'react';

import {
  Typography,
  Link, Grid, withStyles, List, ListItem, ListItemText, Collapse, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {SubTitle, Well} from "./index";
import IntlFormatter from "../intl";
import {Link as RouterLink} from "react-router-dom";
import pageStyles from "../theme/jss/layouts/pageStyles";
import {
  LoanApplication2009,
  Borrower2009,
  LoanApplication,
  Borrower
} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

const LoanEmploymentSummary = (props:Props) => {

  const {intl, classes, loanApplication} = props;

  const hasValue = (value:any) => {
    return value !== null && value !== undefined;
  };

  if(!loanApplication.borrowers || loanApplication.borrowers.length === 0) {
    return (
      <Well color="warn">
        <Typography variant="body1">
          <FormattedMessage id="no_demographics_found" />
        </Typography>
      </Well>
    );
  }

  const renderBorrowerDemographics = (borrower:Borrower2009|Borrower) => {
    const { intl, classes } = props;

    if(!borrower.demographics) {
      return <div />
    }

    return (
      <div>

        {borrower.demographics.ethnicity &&
        <div>
          <Typography variant="subtitle1">
            <FormattedMessage id="ethnicity" />
          </Typography>
          {borrower.demographics.ethnicity.declined &&
          <List dense>
            <ListItem>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'declined')} />
            </ListItem>
          </List>
          }
          {!borrower.demographics.ethnicity.declined &&
          <div className={classes.pb1}>
            <List dense>
              <ListItem>
                <ListItemText primary={IntlFormatter.formatMessage(intl, hasValue(borrower.demographics.ethnicity.hispanicOrLatino) ? borrower.demographics.ethnicity.hispanicOrLatino ? 'hispanic_or_latino' : 'not_hispanic_or_latino' : 'na')} />
              </ListItem>
              {borrower.demographics.ethnicity.hispanicOrLatino && borrower.demographics.ethnicity.hispanicOrLatinoEthnicities &&
              <Collapse in={true} unmountOnExit>
                <List component="div" disablePadding dense>
                  {borrower.demographics.ethnicity.hispanicOrLatinoEthnicities.map((ethnicity, i) => {
                    return (
                      <ListItem key={i} className={classes.nestedListItem}>
                        <ListItemText primary={IntlFormatter.formatMessage(intl, `hispanic_or_latino_ethnicities_${ethnicity}`)} />
                      </ListItem>
                    );
                  })}
                  {borrower.demographics.ethnicity.otherHispanicOrLatino &&
                  <ListItem className={classes.nestedListItem}>
                    <ListItemText primary={borrower.demographics.ethnicity.otherHispanicOrLatino} />
                  </ListItem>
                  }
                </List>
              </Collapse>
              }
            </List>
          </div>
          }
        </div>
        }

        {borrower.demographics.race &&
        <div className={classes.mb2}>
          <Typography variant="subtitle1">
            <FormattedMessage id="races"/>
          </Typography>
          {borrower.demographics.race.declined &&
          <List dense>
            <ListItem>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'declined')} />
            </ListItem>
          </List>
          }
          {!borrower.demographics.race.declined &&
          <List dense>
            {borrower.demographics.race.americanIndianOrAlaskanNative && borrower.demographics.race.americanIndianOrAlaskanNative.value &&
            <ListItem>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'races_americanIndianOrAlaskanNative')}
                            secondary={borrower.demographics.race.americanIndianOrAlaskanNative.description}/>
            </ListItem>
            }
            {borrower.demographics.race.asian && borrower.demographics.race.asian.value &&
            <div>
              <ListItem>
                <ListItemText primary={IntlFormatter.formatMessage(intl, 'races_asian')}/>
              </ListItem>
              <Collapse in={true} unmountOnExit>
                <List component="div" disablePadding dense>
                  {borrower.demographics.race.asian.races && borrower.demographics.race.asian.races.map((race, i) => {
                    return (
                      <ListItem key={i} className={classes.nestedListItem}>
                        <ListItemText primary={IntlFormatter.formatMessage(intl, `races_${race}`)}/>
                      </ListItem>
                    )
                  })}

                  {borrower.demographics.race.asian.other &&
                  <ListItem className={classes.nestedListItem}>
                    <ListItemText primary={borrower.demographics.race.asian.other}/>
                  </ListItem>
                  }
                </List>
              </Collapse>
            </div>
            }
            {borrower.demographics.race.blackOrAfrican &&
            <ListItem>
              <ListItemText primary={IntlFormatter.formatMessage(intl, "races_blackOrAfrican")}/>
            </ListItem>
            }

            {borrower.demographics.race.nativeHawaiianOrPacificIslander && borrower.demographics.race.nativeHawaiianOrPacificIslander.value &&
            <div>
              <ListItem>
                <ListItemText primary={IntlFormatter.formatMessage(intl, "races_nativeHawaiianOrPacificIslander")}/>
              </ListItem>
              <Collapse in={true} unmountOnExit>
                <List component="div" disablePadding dense>
                  {borrower.demographics.race.nativeHawaiianOrPacificIslander.races && borrower.demographics.race.nativeHawaiianOrPacificIslander.races.length > 0 &&
                  <div>
                    {borrower.demographics.race.nativeHawaiianOrPacificIslander.races.map((race, i) => {
                      return (
                        <ListItem key={i} className={classes.nestedListItem}>
                          <ListItemText primary={IntlFormatter.formatMessage(intl, `races_${race}`)}/>
                        </ListItem>
                      )
                    })}
                  </div>
                  }

                  {borrower.demographics.race.nativeHawaiianOrPacificIslander.other &&
                  <ListItem className={classes.nestedListItem}>
                    <ListItemText primary={borrower.demographics.race.nativeHawaiianOrPacificIslander.other}/>
                  </ListItem>
                  }
                </List>
              </Collapse>
            </div>
            }

            {borrower.demographics.race.white &&
            <ListItem>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'races_white')}/>
            </ListItem>
            }
          </List>
          }
        </div>
        }
        {borrower.demographics.gender &&
        <div>
          <Typography variant="subtitle1">
            <FormattedMessage id="gender"/>
          </Typography>
          {borrower.demographics.gender.declined &&
          <List dense>
            <ListItem>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'declined')} />
            </ListItem>
          </List>
          }

          {borrower.demographics.gender.genders &&
          <List dense>
            {borrower.demographics.gender.genders.map((gender, i) => {
              return (
                <ListItem key={i}>
                  <ListItemText primary={IntlFormatter.formatMessage(intl, gender)} />
                </ListItem>
              )
            })}
          </List>
          }
        </div>
        }
      </div>
    )
  };

  return (
    <div>
      <Grid container spacing={2}>
        {loanApplication.borrowers.map((borrower:Borrower2009|Borrower, borrowerIndex:number) => {
          const smMdSize = loanApplication.borrowers && loanApplication.borrowers.length > 1 ? Math.floor(12 / loanApplication.borrowers.length) : 6;
          return (
            // @ts-ignore
            <Grid key={borrowerIndex}
                  item
                  xs={12}
                  sm={smMdSize}
                  md={smMdSize}>

              <div className={classes.mb2}>
                <SubTitle title={borrower.fullName || IntlFormatter.formatMessage(intl, 'borrower')} />
              </div>

              {borrower.demographics &&
                renderBorrowerDemographics(borrower)
              }

              {!borrower.demographics &&
              <div className={classes.mb2}>
                <Well color="warn">
                  <Typography variant="body1">
                    <FormattedMessage id="no_demographics_found" /> &nbsp;
                    {!loanApplication.completed &&
                    <Link component={RouterLink} to="/apply/demographics">
                      <FormattedMessage id="add_demographics"/>
                    </Link>
                    }
                  </Typography>
                </Well>
              </div>
              }

            </Grid>
          )
        })}
      </Grid>
    </div>
  );
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanEmploymentSummary));