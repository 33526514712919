import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  Icon,
  IconButton,
  InputAdornment, ListItemText,
  Tooltip,
  Typography,
  List,
  ListItem,
  Button,
  withStyles,
  ListItemSecondaryAction,
  Link,
  Checkbox,
  FormControlLabel,
  WithStyles, Divider
} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import IntlFormatter from '../../../intl';
import BaseForm from "../../../forms/BaseForm";
import {
  SubmitButton,
  MoneyInput,
  ErrorList,
  TextField,
  Select,
  InlineCard,
  Dialog,
  Loader,
  GooglePlacesAutoCompleteValidator,
  Alert, RadioGroup, HelperTooltip
} from '../../../components';
import _ from 'lodash';
import {states} from '../../../data/data';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ErrorUtil, LocationUtil} from "../../../utils";
import axios, {AxiosResponse} from 'axios';
import {
  ActionProps,
  ErrorState,
  FixMeLater,
  FormattedPlacesResult,
  GoogleAddressMap,
  LabelValuePairType,
  ReduxApp,
  ReduxLoanSettings,
  USState
} from "../../../types";
import {
  LoanApplication,
  LoanApplicationProperty, LoanApplicationPropertyMortgage,
  LoanApplicationRequest,
  LoanApplicationUpdateRequest,
  Location
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import config from "../../../config";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";

type Props = {
  loanApplication: LoanApplication,
  property?: LoanApplicationProperty|null,
  onSubmit: (data:AxiosResponse<LoanApplication>) => void,
  onSubmitClick?: (e:MouseEvent) => void,
  onChange: (data:Form) => void,
  app: ReduxApp,
  settings: ReduxLoanSettings,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type FormAddress = {
  street1: string,
  unit: string,
  city: string,
  province: string,
  postalCode: string,
  county?: string
}

type FormMortgage = {
  _id?: string,
  creditor: string,
  creditLimit: string,
  lienType: string,
  loanAmount: string,
  monthlyPayment: string
}

type Form = {
  address: FormAddress,
  propertyValue: string,
  numberOfUnits: string,
  occupancy: string,
  fhaSecondaryResidence: boolean,
  mixedUseProperty: boolean,
  manufacturedHome: boolean,
  monthlyRentalIncome: string,
  mortgages: FormMortgage[],
  hasMortgages: 'yes'|'no'|''
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  manualAddressEntry: boolean,
  addressConfirmationDialogOpen: boolean,
  addressConfirmationOptions: FixMeLater[],
}

class PropertyForm extends BaseForm<Props, State> {
  occupancyTypes:LabelValuePairType[] = [];
  lienTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      address: {
        street1: '',
        unit: '',
        city: '',
        province: '',
        postalCode: '',
        county: ''
      },
      propertyValue: '',
      numberOfUnits: '',
      occupancy: '',
      fhaSecondaryResidence: false,
      mixedUseProperty: false,
      manufacturedHome: false,
      monthlyRentalIncome: '',
      mortgages: [],
      hasMortgages: ''
    };

    if(props.property) {
      const {address, propertyValue, numberOfUnits, occupancy, fhaSecondaryResidence, mixedUseProperty, manufacturedHome, monthlyRentalIncome, mortgages} = props.property;
      
      form = {
        address: {
          street1: address && address.street1 ? address.street1 : '',
          unit: address && address.unit ? address.unit : '',
          city: address && address.city ? address.city : '',
          province: address && address.province ? address.province : '',
          postalCode: address && address.postalCode ? address.postalCode : '',
          county: address && address.county ? address.county : ''
        },
        propertyValue: propertyValue ? propertyValue.toString() : '',
        numberOfUnits: numberOfUnits ? numberOfUnits.toString() : '',
        occupancy: occupancy ? occupancy : '',
        fhaSecondaryResidence: typeof fhaSecondaryResidence === 'boolean' ? fhaSecondaryResidence : false,
        mixedUseProperty: typeof mixedUseProperty === 'boolean' ? mixedUseProperty : false,
        manufacturedHome: typeof manufacturedHome === 'boolean' ? manufacturedHome : false,
        monthlyRentalIncome: monthlyRentalIncome ? monthlyRentalIncome.toString() : '',
        mortgages: [],
        hasMortgages: mortgages ? mortgages.length > 0 ? 'yes' : 'no' : ''
      };
      
      if(mortgages && mortgages.length > 0) {
        mortgages.forEach(mortgage => {
          const {_id, creditor, creditLimit, lienType, loanAmount, monthlyPayment} = mortgage;
          form.mortgages.push({
            _id,
            creditor: creditor ? creditor : '', 
            creditLimit: creditLimit ? creditLimit.toString() : '', 
            lienType: lienType ? lienType : '', 
            loanAmount: loanAmount ? loanAmount.toString() : '', 
            monthlyPayment: monthlyPayment ? monthlyPayment.toString() : ''
          })
        });
      }
    }

    this.state = {
      form,
      manualAddressEntry: false,
      errors: [],
      addressConfirmationDialogOpen: false,
      addressConfirmationOptions: [],
      loading: false
    };

    props.app.data.enums.propertyOccupancyTypes.forEach(type => {
      this.occupancyTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `occupancy_types_${type}`) })
    });

    props.app.data.enums.lienTypes.forEach(type => {
      this.lienTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `lien_type_${type}`) })
    });
  }

  componentDidUpdate = (prevProps:Props, prevState:State) => {
    if(this.props.onChange && !_.isEqual(prevState.form, this.state.form)) {
      this.props.onChange(_.cloneDeep(this.state.form));
    }
  };

  setAddressState = (state:USState) => {
    const form = _.cloneDeep(this.state.form);
    if(form.address) {
      form.address.province = state.name;
    }
    this.setState({ form });
  };

  setOccupancyType = (type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.occupancy = type ? type.value : null;
    this.setState({ form });
  };

  onAddressChange = (selectedAddress:FormattedPlacesResult) => {
    let form:FixMeLater = _.cloneDeep(this.state.form);
    if(selectedAddress) {
      if(!form.address) {
        form.address = {};
      }
      const {settings} = this.props;

      if(settings.data.tridFields.includes('propertyStreetAddress') && selectedAddress.streetName) {
        form.address.street1 = `${selectedAddress.streetNumber} ${selectedAddress.streetName}`;
      }
      form.address.city = selectedAddress.city;
      form.address.province = selectedAddress.province;
      form.address.postalCode = selectedAddress.postalCode;
      form.address.county = selectedAddress.county;
      form.address.country = selectedAddress.country;
      //console.log(address);
    } else {
      form.address = null;
    }
    this.setState({ form });
  };

  onShowManualEntry = (e:MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const form = _.cloneDeep(this.state.form);
    form.address = {
      street1: '',
      unit: '',
      city: '',
      province: '',
      postalCode: '',
      county: ''
    };

    this.setState({ manualAddressEntry: true, form });
  };

  onHideManualEntry = (e:MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const form = _.cloneDeep(this.state.form);
    form.address = {
      street1: '',
      unit: '',
      city: '',
      province: '',
      postalCode: '',
      county: ''
    };
    this.setState({ manualAddressEntry: false, form });
  };

  onAddressConfirmationDialogClose = () => {
    this.setState({ addressConfirmationDialogOpen: false });
  };

  onAddressConfirmationOptionClick = (address:Location) => {
    const form = _.cloneDeep(this.state.form);
    form.address = {
      street1: address.street1 ? address.street1 : '',
      unit: address.unit ? address.unit : '',
      city: address.city ? address.city : '',
      province: address.province ? address.province : '',
      postalCode: address.postalCode ? address.postalCode : ''
    };

    this.setState({ form, addressConfirmationDialogOpen: false, addressConfirmationOptions: [] }, async () => {
      await this.onSubmit();
    });
  };

  onAddressConfirmationOptionUseMine = () => {
    this.setState({ addressConfirmationDialogOpen: false, addressConfirmationOptions: [] }, async () => {
      await this.onSubmit();
    });
  };

  getAddressFromGoogleResult = (googleAddress:google.maps.GeocoderResult) => {

    const componentForm:GoogleAddressMap = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      administrative_area_level_2: 'long_name',
      postal_code: 'short_name',
      country: 'short_name'
    };

    const addressObj:FixMeLater = {};

    for (let i = 0; i < googleAddress.address_components.length; i++) {
      const addressType = googleAddress.address_components[i].types[0];
      if (componentForm[addressType as keyof GoogleAddressMap]) {
        // @ts-ignore
        addressObj[addressType] = googleAddress.address_components[i][componentForm[addressType]]
      }
    }

    const address:FixMeLater = {
      street1: addressObj.street_number || addressObj.route ? `${addressObj.street_number} ${addressObj.route}`.trim() : null,
      city: addressObj.locality,
      province: addressObj.administrative_area_level_1,
      postalCode: addressObj.postal_code,
      county: addressObj.administrative_area_level_2,
      country: addressObj.country
    };

    address.formatted = googleAddress.formatted_address;

    const {settings} = this.props;
    if(!settings.data.tridFields.includes('propertyStreetAddress')) {
      address.street1 = null;
    }

    return address;
  };

  formatAddressForGoogle = () => {
    const { address } = this.state.form;
    let formattedAddress = '';
    if(address) {
      if(address.city) {
        formattedAddress += `${address.city} `;
      }
      if(address.province) {
        formattedAddress += `${address.province} `;
      }

      formattedAddress += address.postalCode;
    }

    return formattedAddress;
  };

  clearAddress = () => {
    const form = _.cloneDeep(this.state.form);
    form.address = {
      street1: '',
      unit: '',
      city: '',
      province: '',
      postalCode: '',
      county: ''
    };
    this.setState({ form });
  };

  hasAddress = () => {
    const { address } = this.state.form;
    let hasAddress = false;
    for(let key in address) {
      // @ts-ignore
      if(address.hasOwnProperty(key) && key !== '_id' && key !== 'country' && address[key] && address[key].trim().length > 0) {
        hasAddress = true;
        break;
      }
    }

    return hasAddress;
  };

  isLoanOfficerLicensedInPropertyState = () => {
    let isLicensed = true;
    const { form } = this.state;
    const { loanApplication } = this.props;

    if(loanApplication.loanOfficer && form.address && form.address.province) {
      const state = states.find(s => s.name.toLowerCase() === form.address!.province.toLowerCase());
      if(!state || !loanApplication.loanOfficer.stateLicenses.includes(state.abbreviation.toLowerCase())) {
        isLicensed = false;
      }
    }

    return isLicensed;
  };

  setHasMortgages = (event:ChangeEvent<HTMLInputElement>) => {
    const form = _.cloneDeep(this.state.form);
    form.hasMortgages = event.target.value as 'yes'|'no';
    if(form.hasMortgages === 'no') {
      form.mortgages = [];
    } else {
      form.mortgages.push({
        creditor: '',
        lienType: '',
        monthlyPayment: '',
        loanAmount: '',
        creditLimit: ''
      });
    }

    this.setState({ form });
  };

  onAddMortgageClick = () => {
    const form = _.cloneDeep(this.state.form);
    const mortgage = {
      creditor: '',
      lienType: '',
      monthlyPayment: '',
      loanAmount: '',
      creditLimit: ''
    };
    if(!form.mortgages) {
      form.mortgages = [mortgage];
    } else {
      form.mortgages.push(mortgage);
    }

    this.setState({form});
  };

  onRemoveMortgageClick = (index:number) => {
    const form = _.cloneDeep(this.state.form);
    if(form.mortgages) {
      form.mortgages.splice(index, 1);
      if(form.mortgages.length === 0) {
        form.hasMortgages = 'no';
      }
      this.setState({form});
    }
  };

  setMortgageLienType = (index:number, type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    if(form.mortgages) {
      form.mortgages[index].lienType = type ? type.value : null;
      this.setState({ form });
    }
  };


  onSubmitClick = async (event:MouseEvent) => {
    if(this.props.onSubmitClick) {
      this.props.onSubmitClick(event);
    }
    if(this.state.manualAddressEntry) {
      this.setState({loading: true});
      const geocodeResult = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?key=${config.google.placesApiKey}&address=${this.formatAddressForGoogle()}`);

      const formattedResults:FixMeLater[] = [];
      geocodeResult.data.results.forEach((result:FixMeLater) => {
        formattedResults.push(this.getAddressFromGoogleResult(result));
      });

      if(formattedResults.length === 1) {
        this.setState({ loading: false, addressConfirmationDialogOpen: true, addressConfirmationOptions: formattedResults });
      } else if(geocodeResult.data.results.length > 1) {
        this.setState({ loading: false, addressConfirmationDialogOpen: true, addressConfirmationOptions: formattedResults });
      } else {
        await this.onSubmit();
      }

    } else {
      await this.onSubmit();
    }
  };

  onSubmit = async () => {
    try {
      const {loanApplication} = this.props;
      this.setState({loading: true, errors: []});
      const {address, propertyValue, numberOfUnits, occupancy, fhaSecondaryResidence, mixedUseProperty, manufacturedHome, monthlyRentalIncome, mortgages} = this.state.form;
      const requestData:LoanApplicationUpdateRequest = {
        property: {
          address: {
            street1: address.street1 ? address.street1 : null,
            unit: address.unit ? address.unit : null,
            city: address.city ? address.city : null,
            province: address.province ? address.province : null,
            postalCode: address.postalCode ? address.postalCode : null,
            county: address.county ? address.county : null,
            country: 'US'
          },
          propertyValue: propertyValue ? parseInt(propertyValue) : null,
          numberOfUnits: numberOfUnits ? parseInt(numberOfUnits) : null,
          occupancy: occupancy ? occupancy as LoanApplicationProperty["occupancy"] : null,
          fhaSecondaryResidence,
          mixedUseProperty,
          manufacturedHome,
          monthlyRentalIncome: monthlyRentalIncome ? parseInt(monthlyRentalIncome) : null,
          mortgages: []
        }
      };

      if(mortgages && mortgages.length > 0) {
        mortgages.forEach(mortgage => {
          const {_id, creditor, creditLimit, lienType, loanAmount, monthlyPayment} = mortgage;
          requestData.property!.mortgages!.push({
            _id: _id ? _id : undefined,
            creditor: creditor ? creditor : null,
            creditLimit: creditLimit ? parseInt(creditLimit) : null,
            lienType: lienType ? lienType as LoanApplicationPropertyMortgage["lienType"] : null,
            loanAmount: loanAmount ? parseInt(loanAmount) : null,
            monthlyPayment: monthlyPayment ? parseInt(monthlyPayment) : null
          })
        });
      }

      const result = await this.props.updateLoanApplication(loanApplication._id, requestData).send();
      this.setState({loading: false}, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, children, settings, classes, loanApplication } = this.props;
    const { form, errors, manualAddressEntry, addressConfirmationDialogOpen, addressConfirmationOptions, loading } = this.state;

    const hasAddress = this.hasAddress();

    return (
      <Form onSubmit={this.onSubmitClick}>
        <Loader visible={loading} />

        <Dialog open={addressConfirmationDialogOpen}
                title={IntlFormatter.formatMessage(intl, 'confirm_address')}
                icon={<Icon>location_on</Icon>}
                color="primaryAlt"
                fullWidth={true}
                maxWidth="sm"
                onClose={this.onAddressConfirmationDialogClose}>
          {addressConfirmationOptions.length > 1 &&
          <div>
            <Typography variant="subtitle1">
              <FormattedMessage id="select_address" />
            </Typography>

            <List>
              {addressConfirmationOptions.map((address, i) => {
                return (
                  <ListItem key={i}>
                    <ListItemText primary={address.formatted} />
                    <ListItemSecondaryAction>
                      <Button variant="outlined" color="primary" onClick={() => this.onAddressConfirmationOptionClick(address)}>
                        <FormattedMessage id="select" />
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </List>
          </div>
          }

          {addressConfirmationOptions.length === 1 &&
            <div className={classes.mv2}>
              <Typography variant="body1">
                {addressConfirmationOptions[0].formatted}
              </Typography>
            </div>
          }

          <div className={classes.mt2}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button onClick={this.onAddressConfirmationDialogClose}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              <Grid item>
                <div className={classes.rowCenter}>
                  <div className={classes.mr1}>
                    <Button variant="outlined" onClick={this.onAddressConfirmationOptionUseMine}>
                      <FormattedMessage id="use_mine" />
                    </Button>
                  </div>
                  {addressConfirmationOptions.length === 1 &&
                    <Button variant="contained" color="primary" onClick={() => this.onAddressConfirmationOptionClick(addressConfirmationOptions[0])}>
                      <FormattedMessage id="use_this_address" />
                    </Button>
                  }
                </div>
              </Grid>
            </Grid>
          </div>
        </Dialog>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            {!manualAddressEntry &&
              <div>

                {hasAddress &&
                  <InlineCard title={IntlFormatter.formatMessage(intl, 'address')}
                              icon={<Icon>location_on</Icon>}
                              action={
                                <Button variant="outlined" color="primary" onClick={this.clearAddress} size="small">
                                  <FormattedMessage id="change" />
                                </Button>
                              }>
                    <Typography variant="body1">
                      {LocationUtil.formatLocation(form.address!)}
                    </Typography>
                  </InlineCard>
                }
                {!hasAddress &&
                  <div>
                    <GooglePlacesAutoCompleteValidator name="address"
                                                       value={form.address}
                                                       placeholder={IntlFormatter.formatMessage(intl, 'search')}
                                                       label={IntlFormatter.formatMessage(intl, settings.data.tridFields.includes('propertyStreetAddress') ? 'address' : 'city_state_or_zip')}
                                                       onChange={this.onAddressChange}
                                                       validators={['required']}
                                                       errorMessages={[
                                                         IntlFormatter.formatMessage(intl, 'validation_required'),
                                                       ]}/>
                    <Typography variant="caption">
                      <Link href="#" onClick={this.onShowManualEntry}>
                        <FormattedMessage id="enter_address_manually" />
                      </Link>
                    </Typography>
                  </div>
                }
              </div>
            }

            {(manualAddressEntry && form.address) &&
              <div>
                {settings.data.tridFields.includes('propertyStreetAddress') &&
                  <div className={classes.mb2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={9} md={9}>
                        <TextField name="street1"
                                   label={IntlFormatter.formatMessage(intl, 'street')}
                                   value={form.address.street1}
                                   fullWidth={true}
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address.street1')}
                                   InputProps={{
                                     endAdornment: (
                                       <InputAdornment position="end">
                                         <Tooltip title={IntlFormatter.formatMessage(intl, 'search')}>
                                           <IconButton onClick={this.onHideManualEntry}>
                                             <Icon>location_disabled</Icon>
                                           </IconButton>
                                         </Tooltip>
                                       </InputAdornment>
                                     )
                                   }}
                                   validators={['required']}
                                   errorMessages={[
                                     IntlFormatter.formatMessage(intl, 'validation_required'),
                                   ]}/>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <TextField name="unit"
                                   label={IntlFormatter.formatMessage(intl, 'unit')}
                                   value={form.address.unit}
                                   fullWidth={true}
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address.unit')}/>
                      </Grid>
                    </Grid>
                  </div>
                }
                <div className={classes.mb2}>
                  <TextField name="city"
                             label={IntlFormatter.formatMessage(intl, 'city')}
                             value={form.address.city}
                             fullWidth={true}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address.city')}
                             validators={['required']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required'),
                             ]} />
                </div>

                <div className={classes.mb2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={7}>
                      <Select name="state"
                              options={states}
                              onChange={this.setAddressState}
                              getOptionLabel={(item:USState) => item.name }
                              value={form.address.province ? states.find(s => s.name === form.address!.province) : ''}
                              label={IntlFormatter.formatMessage(intl, 'state')}
                              validators={['required']}
                              errorMessages={[
                                IntlFormatter.formatMessage(intl, 'validation_required'),
                              ]}
                              placeholder={IntlFormatter.formatMessage(intl, 'select')} />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <TextField name="postalCode"
                                 label={IntlFormatter.formatMessage(intl, 'postal_code')}
                                 value={form.address.postalCode}
                                 fullWidth={true}
                                 onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address.postalCode')}
                                 validators={['required', 'minStringLength:5', 'maxStringLength:5']}
                                 errorMessages={[
                                   IntlFormatter.formatMessage(intl, 'validation_required'),
                                   IntlFormatter.formatMessage(intl, 'validation_minlength', {length: 5}),
                                   IntlFormatter.formatMessage(intl, 'validation_maxlength', {length: 5})
                                 ]}/>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Typography variant="caption">
                    <Link href="#" onClick={this.onHideManualEntry}>
                      <FormattedMessage id="search_for_address" />
                    </Link>
                  </Typography>
                </div>
              </div>
            }

            {!this.isLoanOfficerLicensedInPropertyState() &&
              <div className={classes.mt2}>
                <Alert severity="danger" >
                  <FormattedMessage id="loan_officer_not_licensed_warning" />
                </Alert>
              </div>
            }

            <div className={classes.mt2}>
              <Grid container spacing={2}>
                {settings.data.tridFields.includes('propertyValue') &&
                <Grid item xs={12} sm={12} md={6}>
                  <MoneyInput label={IntlFormatter.formatMessage(intl, loanApplication.loan && loanApplication.loan.loanPurpose === 'refinance' ? 'property_value' : 'sale_price')}
                              value={form.propertyValue}
                              fullWidth={true}
                              decimalScale={0}
                              onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.propertyValue')}
                              validators={['minNumber:0']}
                              errorMessages={[
                                IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                              ]}/>
                </Grid>
                }
                <Grid item xs={12} sm={12} md={6}>
                  <TextField name="numberOfUnits"
                             label={IntlFormatter.formatMessage(intl, 'number_of_units')}
                             value={form.numberOfUnits}
                             fullWidth={true}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.numberOfUnits')}
                             type="number"
                             helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'number_of_units_description')}
                                                        text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                             validators={['minNumber:1', 'maxNumber:4']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 1 }),
                               IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 4 })
                             ]} />
                </Grid>
              </Grid>
            </div>
            {(parseInt(form.numberOfUnits) > 1) &&
            <div className={classes.mt2}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_rental_income')}
                          value={form.monthlyRentalIncome}
                          fullWidth={true}
                          decimalScale={0}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.monthlyRentalIncome')}
                          helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'monthly_rental_income_description')}
                                                     text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                          validators={['minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]}/>
            </div>
            }

            <div className={classes.mt2}>
              <div className={classes.mb1}>
              <RadioGroup name="hasMortgages"
                          label={IntlFormatter.formatMessage(intl, 'other_mortgage_loans_on_property')}
                          itemValueProp="value"
                          value={form.hasMortgages}
                          onChange={this.setHasMortgages}
                          items={[
                            {label: IntlFormatter.formatMessage(intl, 'yes'), value: 'yes'},
                            {label: IntlFormatter.formatMessage(intl, 'no'), value: 'no'}
                          ]}
                          validators={['required']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required')
                          ]}
                          row/>
              </div>

              {form.hasMortgages === 'yes' &&
                <div className={classes.mb2}>
                  {form.mortgages.map((mortgage, i) => {
                    return (
                      <div key={i} className={classes.mv1}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={10}>
                            <div className={classes.mb1}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6}>
                                  <TextField name={`mortgageCreditor${i}`}
                                             label={IntlFormatter.formatMessage(intl, 'creditor')}
                                             value={mortgage.creditor}
                                             fullWidth={true}
                                             margin="dense"
                                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.mortgages[${i}].creditor`)}
                                             validators={['required']}
                                             errorMessages={[
                                               IntlFormatter.formatMessage(intl, 'validation_required')
                                             ]} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <Select name={`mortgageLienType${i}`}
                                          options={this.lienTypes}
                                          onChange={(type:LabelValuePairType) => this.setMortgageLienType(i, type)}
                                          value={mortgage.lienType ? this.lienTypes.find(t => t.value === mortgage.lienType) : ''}
                                          size="small"
                                          validators={['required']}
                                          errorMessages={[
                                            IntlFormatter.formatMessage(intl, 'validation_required'),
                                          ]}
                                          label={IntlFormatter.formatMessage(intl, 'lien_type')}
                                          placeholder={IntlFormatter.formatMessage(intl, 'select')}
                                          className={classes.selectInlineMargin} />
                                </Grid>
                              </Grid>
                            </div>
                            <div>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                  <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_payment')}
                                              value={mortgage.monthlyPayment}
                                              fullWidth={true}
                                              margin="dense"
                                              decimalScale={0}
                                              validators={['required', 'minNumber:0']}
                                              errorMessages={[
                                                IntlFormatter.formatMessage(intl, 'validation_required'),
                                                IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                                              ]}
                                              onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.mortgages[${i}].monthlyPayment`)} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <MoneyInput label={IntlFormatter.formatMessage(intl, 'loan_amount')}
                                              value={mortgage.loanAmount}
                                              fullWidth={true}
                                              margin="dense"
                                              decimalScale={0}
                                              validators={['required', 'minNumber:0']}
                                              errorMessages={[
                                                IntlFormatter.formatMessage(intl, 'validation_required'),
                                                IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                                              ]}
                                              onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.mortgages[${i}].loanAmount`)} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <MoneyInput label={IntlFormatter.formatMessage(intl, 'credit_limit')}
                                              value={mortgage.creditLimit}
                                              fullWidth={true}
                                              margin="dense"
                                              decimalScale={0}
                                              validators={['minNumber:0']}
                                              helperText={IntlFormatter.formatMessage(intl, 'if_applicable')}
                                              errorMessages={[
                                                IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                                              ]}
                                              onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.mortgages[${i}].creditLimit`)} />
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton onClick={() => this.onRemoveMortgageClick(i)}>
                              <Icon>cancel</Icon>
                            </IconButton>
                          </Grid>
                        </Grid>
                        {(i < form.mortgages.length - 1) &&
                          <div className={classes.mt1}>
                            <Divider />
                          </div>
                        }
                      </div>
                    )
                  })}
                  <Button color="primary" variant="contained" onClick={this.onAddMortgageClick}>
                    <FormattedMessage id="add_mortgage" />
                  </Button>
                </div>
              }

            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div>
              <Select name="occupancy"
                      options={this.occupancyTypes}
                      onChange={this.setOccupancyType}
                      value={form.occupancy ? this.occupancyTypes.find(t => t.value === form.occupancy) : ''}
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required'),
                      ]}
                      label={IntlFormatter.formatMessage(intl, 'occupancy')}
                      placeholder={IntlFormatter.formatMessage(intl, 'select')} />
            </div>
            {(!loanApplication.loan || (loanApplication.loan && loanApplication.loan.loanType === 'fha')) &&
              <div className={classes.mt2}>
                <FormControlLabel label={IntlFormatter.formatMessage(intl, 'fha_secondary_residence')} control={
                  <Checkbox checked={form.fhaSecondaryResidence}
                            onChange={() => this.onCheckboxChanged('form.fhaSecondaryResidence')}
                            color="primary" />
                }
                />
                <Typography variant="caption" component="div">
                  <FormattedMessage id="fha_secondary_residence_description" />
                </Typography>
              </div>
            }
            <div className={classes.mt2}>
              <FormControlLabel label={IntlFormatter.formatMessage(intl, 'mixed_use_property')} control={
                <Checkbox checked={form.mixedUseProperty}
                          onChange={() => this.onCheckboxChanged('form.mixedUseProperty')}
                          color="primary" />
              }
              />
              <Typography variant="caption" component="div">
                <FormattedMessage id="mixed_use_property_description" />
              </Typography>
            </div>
            <div className={classes.mt2}>
              <FormControlLabel label={IntlFormatter.formatMessage(intl, 'manufactured_home')} control={
                <Checkbox checked={form.manufacturedHome}
                          onChange={() => this.onCheckboxChanged('form.manufacturedHome')}
                          color="primary" />
              }
              />
              <Typography variant="caption" component="div">
                <FormattedMessage id="manufactured_home_description" />
              </Typography>
            </div>
          </Grid>
        </Grid>

        {children}

        {!children &&
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <SubmitButton>
              <FormattedMessage id="next" />
            </SubmitButton>
          </Grid>
        </Grid>
        }

      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    settings: state.loanSettings
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(PropertyForm)));
