import ReduxQueue from 'redux-queue';
import * as types from '../actions/types';
import createReducer from '../lib/createReducer';
import {ReduxState} from "../data/initialState";
import {ReduxActionData} from "../types";

export const secureMessages = ReduxQueue(createReducer({}, {
  [types.SET_SECURE_MESSAGES](state:ReduxState, action:ReduxActionData) {
    let newState = {};
    newState = action.payload;
    return newState;
  }
}));

export const unreadSecureMessages = ReduxQueue(createReducer({}, {
  [types.SET_UNREAD_SECURE_MESSAGES](state:ReduxState, action:ReduxActionData) {
    return action.payload;
  }
}));

export const unreadSecureMessageCount = createReducer(null, {
  [types.SET_UNREAD_SECURE_MESSAGE_COUNT](state:ReduxState, action:ReduxActionData) {
    let newState = {};
    newState = action.payload;
    return newState;
  }
});