import React, {Component} from 'react';
import {WithStyles, withStyles} from '@material-ui/core';
import styles from '../theme/jss/components/sectionStyles';
import clsx from "clsx";
import SectionHeading from "./SectionHeading";

type Props = {
  title?: string,
  subtitle?: string,
  helperText?: string,
  icon?: React.ReactNode,
  actions?: React.ReactNode,
  className?: string
} & WithStyles<typeof styles>

class Section extends Component<Props> {
  render() {
    const { classes, title, subtitle, helperText, icon, className, actions, children } = this.props;
    return (
      <div className={clsx(className, classes.root)}>
        {title &&
          <div className={classes.heading}>
            <SectionHeading title={title}
                            subtitle={subtitle}
                            helperText={helperText}
                            icon={icon}
                            actions={actions} />
          </div>
        }
        <div className={classes.content}>
          {children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Section);
