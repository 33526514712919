import React, {Component} from 'react';
import {Typography, WithStyles, withStyles} from '@material-ui/core';
import styles from '../theme/jss/components/pairedListStyles';

type DefaultProps = {
  key: string,
  value: string
}

type Props = {
  items:any[]
} & Partial<DefaultProps>
  & WithStyles<typeof styles>

class PairedList extends Component<Props> {
  static defaultProps:DefaultProps = {
    key: 'key',
    value: 'value',
  };

  render() {
    const { classes, items, key, value } = this.props;

    if(items.length === 0) {
      return <div />
    }

    return (
      <div className={classes.root}>
        {items.map((item, i) => {
          return (
            <div key={i} className={classes.row}>
              <Typography variant="caption">
                {item[key!]}
              </Typography>
              <Typography variant="body1">
                {item[value!]}
              </Typography>
            </div>
          )
        })}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PairedList);
