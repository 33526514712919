import React, { Component, MouseEvent } from 'react';
import {
  Icon, IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles, MenuItem, Menu, Chip, WithStyles
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import styles from '../theme/jss/layouts/pageStyles';
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {connect} from "react-redux";
import {ErrorUtil, ReduxUtil} from "../utils";
import IntlFormatter from "../intl";
import {getAccount} from "../actions/account";
import {getBankAccounts, addBankAccount, updateBankAccount, deleteBankAccount} from "../actions/bankAccounts";
import {updateDefaultPaymentMethod} from "../actions/subscription";
import _ from "lodash";
import {DeleteDialog, Dialog, Section, Snackbar, Tooltip} from "./index";
import {BankAccountForm, BankAccountVerificationForm} from "../forms";
import Fab from "./Fab";
import {
  ActionResponse,
  DialogState,
  ReduxAccount,
  ReduxBankAccounts,
  SnackbarState,
  ErrorState
} from "../types";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {BankAccount, BankAccountRequest, BankAccountUpdateRequest} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  account: ReduxAccount,
  bankAccounts: ReduxBankAccounts,
  getAccount: () => ActionResponse,
  getBankAccounts: () => ActionResponse,
  addBankAccount: (data:BankAccountRequest) => ActionResponse,
  updateBankAccount: (id:string, data:BankAccountUpdateRequest) => ActionResponse,
  deleteBankAccount: (id:string) => ActionResponse,
  updateDefaultPaymentMethod: (id:string) => ActionResponse
} & WithStyles<typeof styles>
  & WrappedComponentProps

type State = {
  loading: boolean,
  selectedActionMenu?: Element,
  bankAccountDialog: DialogState,
  verifyDialog: DialogState,
  deleteDialog: DialogState,
  snackbar: SnackbarState,
  selectedBankAccount?: BankAccount,
  errors: ErrorState[]
}

class BankAccountList extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: true,
      bankAccountDialog: {
        open: false,
        loading: false,
        errors: []
      },
      verifyDialog: {
        open: false,
        loading: false,
        errors: []
      },
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      snackbar: {
        open: false,
        message: ''
      },
      errors: []
    };
  }

  componentDidMount = async () => {
    try {
      const requests = [
        this.props.getAccount().send(),
        this.props.getBankAccounts().send(),
      ];
      await Promise.all(requests);
      this.setState({ loading: false });
    } catch(err) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
    }
  };

  onActionMenuClick = (event:MouseEvent, bankAccount:BankAccount) => {
    this.setState({
      selectedActionMenu: event.currentTarget,
      selectedBankAccount: bankAccount
    });
  };

  onActionMenuClose = () => {
    this.setState({ selectedActionMenu: undefined, selectedBankAccount: undefined });
  };

  onAddClick = () => {
    const bankAccountDialog = _.cloneDeep(this.state.bankAccountDialog);
    bankAccountDialog.open = true;
    this.setState({ bankAccountDialog });
  };

  onEditClick = () => {
    const bankAccountDialog = _.cloneDeep(this.state.bankAccountDialog);
    bankAccountDialog.open = true;

    this.setState({
      bankAccountDialog,
      selectedActionMenu: undefined
    });
  };

  onBankAccountDialogClose = () => {
    const bankAccountDialog = _.cloneDeep(this.state.bankAccountDialog);
    bankAccountDialog.open = false;
    bankAccountDialog.loading = false;
    bankAccountDialog.errors = [];
    this.setState({ bankAccountDialog, selectedBankAccount: undefined });
  };

  onBankAccountDialogSubmit = () => {
    const bankAccountDialog = _.cloneDeep(this.state.bankAccountDialog);
    bankAccountDialog.open = false;
    bankAccountDialog.loading = false;
    bankAccountDialog.errors = [];

    this.setState({
      bankAccountDialog,
      snackbar: {
        open: true,
        message: IntlFormatter.formatMessage(this.props.intl, 'bank_account_saved')
      }
    });
  };

  onDeleteClick = () => {
    const deleteDialog = _.cloneDeep(this.state.deleteDialog);
    deleteDialog.open = true;
    this.setState({
      deleteDialog,
      selectedActionMenu: undefined,
    });
  };

  onDeleteDialogClose = () => {
    const deleteDialog = _.cloneDeep(this.state.deleteDialog);
    deleteDialog.open = false;
    deleteDialog.loading = false;
    deleteDialog.errors = [];
    this.setState({
      deleteDialog,
      selectedBankAccount: undefined,
    });
  };

  deleteBankAccount = async () => {
    const {selectedBankAccount} = this.state;
    const deleteDialog = _.cloneDeep(this.state.deleteDialog);

    try {
      deleteDialog.loading = true;
      deleteDialog.errors = [];

      this.setState({ deleteDialog });
      if(selectedBankAccount) {
        await this.props.deleteBankAccount(selectedBankAccount.id).send();
        this.props.mixpanel.track('Bank account deleted');
      }

      deleteDialog.open = false;
      deleteDialog.loading = false;

      this.setState({
        deleteDialog,
        selectedBankAccount: undefined,
        snackbar: {
          open: true,
          variant: 'success',
          message: IntlFormatter.formatMessage(this.props.intl, 'bank_account_deleted')
        }
      });
    } catch(err) {
      deleteDialog.loading = true;
      deleteDialog.errors = ErrorUtil.formatErrors(err);
      this.setState({ deleteDialog });
    }
  };

  onSetDefaultPaymentMethodClick = async () => {
    try {
      const {selectedBankAccount} = this.state;
      this.setState({ selectedActionMenu: undefined, loading: true });
      if(selectedBankAccount) {
        await this.props.updateDefaultPaymentMethod(selectedBankAccount.id).send();
        this.props.mixpanel.track('Default payment method updated');
      }
      this.setState({
        selectedBankAccount: undefined,
        loading: false,
        snackbar: {
          open: true,
          variant: 'success',
          message: IntlFormatter.formatMessage(this.props.intl, 'bank_account_saved')
        }
      });
    } catch(err) {
      this.setState({ errors: ErrorUtil.formatErrors(err), loading: false });
    }
  };

  onSnackbarClose = () => {
    this.setState({
      snackbar: {
        open: false,
        message:''
      }
    });
  };

  onUnverifiedClick = (bankAccount:BankAccount) => {
    const verifyDialog = _.clone(this.state.verifyDialog);
    verifyDialog.open = true;
    this.setState({ selectedBankAccount: bankAccount, verifyDialog, selectedActionMenu: undefined });
  };

  onVerifiedDialogClose = () => {
    const verifyDialog = _.clone(this.state.verifyDialog);
    verifyDialog.open = false;
    verifyDialog.loading = false;
    verifyDialog.errors = [];
    this.setState({ selectedBankAccount: undefined, verifyDialog });
  };

  onVerifiedDialogSubmit = () => {
    const verifyDialog = _.clone(this.state.verifyDialog);
    verifyDialog.open = false;
    verifyDialog.loading = false;
    verifyDialog.errors = [];
    this.setState({
      selectedBankAccount: undefined,
      verifyDialog,
      snackbar: {
        open: true,
        variant: 'success',
        message: IntlFormatter.formatMessage(this.props.intl, 'bank_account_verified')
      }
    });
  };

  /*onPlaidSuccess = (public_token:string, metadata:FixMeLater) => {
    //console.log('public_token: ' + public_token);
    //console.log('account ID: ' + metadata.account_id);
  };

  onPlaidExit = (err:any, metadata:FixMeLater) => {

  };*/

  render() {
    const { intl, classes, account, bankAccounts, mixpanel } = this.props;
    const { loading, bankAccountDialog, deleteDialog, selectedBankAccount, selectedActionMenu, snackbar, verifyDialog } = this.state;

    return (
      <div>
        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.onSnackbarClose}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        <DeleteDialog open={deleteDialog.open}
                      title={selectedBankAccount ? IntlFormatter.formatMessage(intl, `delete_bank_account`) : ''}
                      item={selectedBankAccount ? `****${selectedBankAccount.last4}` : ''}
                      loading={deleteDialog.loading}
                      onCancel={this.onDeleteDialogClose}
                      onSubmit={this.deleteBankAccount} />

        <Dialog open={bankAccountDialog.open}
                title={IntlFormatter.formatMessage(intl, selectedBankAccount ? `edit_bank_account` : `add_bank_account`)}
                icon={<Icon>payment</Icon>}
                color="primaryAlt"
                onClose={this.onBankAccountDialogClose}
                fullWidth={true}
                maxWidth="sm" >
          <BankAccountForm bankAccount={selectedBankAccount}
                           mixpanel={mixpanel}
                           onSubmit={this.onBankAccountDialogSubmit}
                           onCancel={this.onBankAccountDialogClose} />
        </Dialog>

        <Dialog open={verifyDialog.open}
                title={IntlFormatter.formatMessage(intl, selectedBankAccount ? `edit_bank_account` : `add_bank_account`)}
                icon={<Icon>lock</Icon>}
                onClose={this.onVerifiedDialogClose}
                fullWidth={true}
                color="primaryAlt"
                maxWidth="sm" >
          {selectedBankAccount &&
            <BankAccountVerificationForm bankAccount={selectedBankAccount}
                                         mixpanel={mixpanel}
                                         onSubmit={this.onVerifiedDialogSubmit}
                                         onCancel={this.onVerifiedDialogClose} />
          }
        </Dialog>

        <Menu anchorEl={selectedActionMenu}
              open={Boolean(selectedActionMenu)}
              onClose={this.onActionMenuClose}>
          {(selectedBankAccount && !selectedBankAccount.default && selectedBankAccount.status === 'verified') &&
          <MenuItem onClick={this.onSetDefaultPaymentMethodClick}>
            <ListItemIcon>
              <Icon>favorite</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'set_default')} />
          </MenuItem>
          }
          {(selectedBankAccount && selectedBankAccount.status !== 'verified') &&
          <MenuItem onClick={() => this.onUnverifiedClick(selectedBankAccount)}>
            <ListItemIcon>
              <Icon>check_circle</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'verify')} />
          </MenuItem>
          }
          <MenuItem onClick={this.onEditClick}>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
          </MenuItem>
          <MenuItem onClick={this.onDeleteClick}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')} />
          </MenuItem>
        </Menu>
        {/*<PlaidLink
                clientName={config.app.name}
                env={process.env.REACT_APP_PLAID_ENV}
                product={["auth"]}
                publicKey={process.env.REACT_APP_PLAID_PUBLIC_KEY}
                selectAccount={true}
                onSuccess={this.onPlaidSuccess}
                onExit={this.onPlaidExit}>
                <FormattedMessage id="connect_to_your_bank_account" />
              </PlaidLink>*/}
        <Section title={IntlFormatter.formatMessage(intl, 'bank_accounts')} actions={
          <Fab color="primary"
               size="small"
               onClick={this.onAddClick}
               flat
               rounded>
            <Icon>add</Icon>
          </Fab>
        }>
          {loading &&
          <div>
            <Skeleton />
            <Skeleton />
          </div>
          }

          {(!loading && ReduxUtil.hasData(bankAccounts) && bankAccounts.data.length === 0) &&
          <div>
            <Typography variant="body1">
              <FormattedMessage id="no_bank_accounts_found" />
            </Typography>
          </div>
          }

          {(!loading && ReduxUtil.hasData(bankAccounts) && bankAccounts.data.length > 0) &&
          <div className={classes.mb2}>
            <List>
              {bankAccounts.data.map(bankAccount => {
                return (
                  <ListItem key={bankAccount.id}>
                    <ListItemIcon>
                      {bankAccount.status !== 'verified' &&
                        <Tooltip title={IntlFormatter.formatMessage(intl, 'not_verified')}>
                          <IconButton onClick={() => this.onUnverifiedClick(bankAccount)} size="small">
                            <Icon className={classes.warning}>warning</Icon>
                          </IconButton>
                        </Tooltip>
                      }
                      {bankAccount.status === 'verified' &&
                        <Icon>account_balance</Icon>
                      }
                    </ListItemIcon>
                    <ListItemText primary={`${bankAccount.bank_name} ****${bankAccount.last4}`}
                                  secondary={bankAccount.id === account.data.defaultPaymentSource ? IntlFormatter.formatMessage(intl, 'default') : null} />
                    <ListItemSecondaryAction>
                      {(bankAccount.id === account.data.defaultPaymentSource) &&
                      <Chip label={IntlFormatter.formatMessage(intl, 'default')}
                            className={classes.chipSuccess}/>
                      }
                      <IconButton onClick={event => this.onActionMenuClick(event, bankAccount)}>
                        <Icon>more_vert</Icon>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </List>

          </div>
          }
        </Section>

      </div>
    );
  }
}


const mapStateToProps = (state:ReduxState) => {
  return {
    bankAccounts: state.bankAccounts,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getAccount() {
    return dispatch(getAccount());
  },
  getBankAccounts() {
    return dispatch(getBankAccounts());
  },
  addBankAccount(data:BankAccountRequest) {
    return dispatch(addBankAccount(data));
  },
  updateBankAccount(id:string, data:BankAccountUpdateRequest) {
    return dispatch(updateBankAccount(id, data));
  },
  deleteBankAccount(id:string) {
    return dispatch(deleteBankAccount(id));
  },
  updateDefaultPaymentMethod(id:string) {
    return dispatch(updateDefaultPaymentMethod(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(BankAccountList)));
