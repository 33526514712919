import {AsyncReduxObject} from "../types";
import {ReduxAsyncOperation} from "../enums";

export default class ReduxUtil {
  static hasData = (prop?:AsyncReduxObject) => {
    if(prop && prop.status !== ReduxAsyncOperation.ERROR && (prop.data && (Array.isArray(prop.data) || Object.keys(prop.data).length > 0 || prop.data.length > 0))) {
      return true;
    }

    return false;
  };

  static isIdle = (prop?:AsyncReduxObject) => {
    if(prop && prop.status) {
      if(prop.status === ReduxAsyncOperation.IDLE) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  };

  static hasErrors = (prop?:AsyncReduxObject) => {
    if(prop && prop.status === ReduxAsyncOperation.ERROR) {
      return true;
    }

    return false;
  };

  static isFetching = (prop?:AsyncReduxObject) => {
    if(prop && prop.status === ReduxAsyncOperation.FETCHING) {
      return true;
    }

    return false;
  };

  static hasAdded = (prop?:AsyncReduxObject, nextProp?:AsyncReduxObject) => {
    if((prop && prop.status === ReduxAsyncOperation.ADDING) && (nextProp && nextProp.status === ReduxAsyncOperation.IDLE)) {
      return true;
    }

    return false;
  };

  static hasUpdated = (prop?:AsyncReduxObject, nextProp?:AsyncReduxObject) => {
    if((prop && prop.status === ReduxAsyncOperation.UPDATING) && (nextProp && nextProp.status === ReduxAsyncOperation.IDLE)) {
      return true;
    }

    return false;
  };

  static hasDeleted = (prop?:AsyncReduxObject, nextProp?:AsyncReduxObject) => {
    if((prop && prop.status === ReduxAsyncOperation.DELETING) && (nextProp && nextProp.status === ReduxAsyncOperation.IDLE)) {
      return true;
    }

    return false;
  };
}
