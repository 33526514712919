import React from 'react';
import MaskedInput from "react-text-mask";
import {TextValidator, TextFieldValidatorComponentProps} from "react-material-ui-form-validator";
import IntlFormatter from "../intl";
import {injectIntl, WrappedComponentProps} from "react-intl";
import FormComponentBase from "./FormComponentBase";

type Props = TextFieldValidatorComponentProps & WrappedComponentProps;

class CreditCardNumberInput extends FormComponentBase<Props, {}> {
  isAmex = () => {
    return ["37", "34"].includes(this.props.value.split("").splice(0, 2).join(""));
  };

  TextMaskField = (props:Props) => {
    const { intl, label, inputRef, value, ...other } = props;

    let mask = [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, ' ',  /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, ' ', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, ' ', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];
    if(this.isAmex()) {
      mask = [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, ' ',  /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/,' ', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];
    }
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={value}
        mask={mask}
        placeholderChar={'\u2000'}
        guide={false}
        showMask />
    )
  };


  render() {

    const { intl, label, validators, errorMessages, variant, size, ...rest } = this.props;

    return <TextValidator label={this.isRequired() && label ? `${label} *` : label}
                          variant={variant ? variant : 'outlined'}
                          size={size ? size : 'medium'}
                          InputProps={{ inputComponent: this.TextMaskField }}
                          validators={validators && validators.length > 0 ? [...validators, `minStringLength:${this.isAmex() ? 17 : 19}`] : `minStringLength:${this.isAmex() ? 17 : 19}`}
                          errorMessages={errorMessages && errorMessages.length > 0 ? [...errorMessages, IntlFormatter.formatMessage(intl, 'validation_credit_card')] : [IntlFormatter.formatMessage(intl, 'validation_credit_card')]}
                          {...rest} />
  }
}

export default injectIntl(CreditCardNumberInput);
