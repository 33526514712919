import en from './en.json';
import es from './es.json';
import {IntlLang} from "../../types";

export type Translations = {
  en: IntlLang,
  es: IntlLang
}

let translations:Translations = {
  en: {
    id: "en",
    name: "English",
    messages: en,
  },
  es: {
    id: "es",
    name: "Spanish",
    messages: es,
  },
};

export default translations;
