import React, {Component} from 'react';
import {Button, Card, CardContent, Grid, Icon, Typography, WithStyles, withStyles} from '@material-ui/core';
import styles from "../theme/jss/layouts/pageStyles";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {DateUtil, ErrorUtil, ReduxUtil} from "../utils";
import {Badge, ColorButton, DeleteDialog, LicensesUsedBadge} from "./index";
import {NumberOfLicensesForm} from "../forms";
import {Link as RouterLink} from "react-router-dom";
import _ from "lodash";
import {cancelSubscription, subscribe} from "../actions/subscription";
import {connect} from "react-redux";
import IntlFormatter from "../intl";
import moment from 'moment';
import {ActionProps, DialogState, ErrorState, FixMeLater, ReduxSubscription} from "../types";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {SubscriptionRequest} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  onNumberOfLicensesSubmit: (data:FixMeLater) => void,
  subscription: ReduxSubscription,
  cancelSubscription: ActionProps["cancelSubscription"],
  subscribe: ActionProps["subscribe"]
} & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  loading: boolean,
  cancelSubscriptionDialog: DialogState,
  errors: ErrorState[]
}

class SubscriptionCard extends Component<Props, State> {
  activeStatuses:string[] = ['active', 'trialing', 'past_due', 'unpaid'];

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      cancelSubscriptionDialog: {
        open: false,
        loading: false,
        errors: []
      },
      errors: []
    }
  }

  onNumberOfLicensesSubmit = (data:FixMeLater) => {
    if(this.props.onNumberOfLicensesSubmit) {
      this.props.onNumberOfLicensesSubmit(data);
    }
  };

  onCancelSubscriptionClick = () => {
    const cancelSubscriptionDialog = _.clone(this.state.cancelSubscriptionDialog);
    cancelSubscriptionDialog.open = true;
    cancelSubscriptionDialog.loading = false;
    cancelSubscriptionDialog.errors = [];
    this.setState({cancelSubscriptionDialog});
  };

  onCancelSubscriptionCancel = () => {
    const cancelSubscriptionDialog = _.clone(this.state.cancelSubscriptionDialog);
    cancelSubscriptionDialog.open = false;
    cancelSubscriptionDialog.loading = false;
    cancelSubscriptionDialog.errors = [];
    this.setState({cancelSubscriptionDialog});
  };

  onCancelSubscriptionConfirm = async () => {
    const cancelSubscriptionDialog = _.clone(this.state.cancelSubscriptionDialog);
    cancelSubscriptionDialog.loading = true;
    cancelSubscriptionDialog.errors = [];
    this.setState({cancelSubscriptionDialog});

    try {
      await this.props.cancelSubscription().send();
      this.props.mixpanel.track("Subscription Cancelled");
      cancelSubscriptionDialog.open = false;
      cancelSubscriptionDialog.loading = false;
      this.setState({ cancelSubscriptionDialog });
    } catch (e) {
      cancelSubscriptionDialog.loading = false;
      cancelSubscriptionDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({ cancelSubscriptionDialog });
    }
  };

  onActivatePlanClick = async () => {
    try {
      this.setState({ loading: true });
      // reactivate the plan
      await this.props.subscribe({ plan: this.props.subscription.data.plan.id }).send();
      this.props.mixpanel.track("Subscription Reactivated");
      this.setState({ loading: false });
    } catch(err) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
    }
  };

  render() {
    const { intl, classes, subscription, mixpanel } = this.props;
    const { cancelSubscriptionDialog } = this.state;

    return (
      <div>
        <DeleteDialog open={cancelSubscriptionDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'cancel_subscription')}
                      confirmationMessage={IntlFormatter.formatMessage(intl, 'are_you_sure_cancel_subscription')}
                      secondConfirmationMessage={IntlFormatter.formatMessage(intl, 'this_will_cancel_subscription_your_subscription')}
                      cancelButtonLabel={IntlFormatter.formatMessage(intl, 'no')}
                      deleteButtonLabel={IntlFormatter.formatMessage(intl, 'yes')}
                      item={ReduxUtil.hasData(subscription) ? subscription.data.plan.product.name : ''}
                      loading={cancelSubscriptionDialog.loading}
                      errors={cancelSubscriptionDialog.errors}
                      onCancel={this.onCancelSubscriptionCancel}
                      onSubmit={this.onCancelSubscriptionConfirm} />
        <Card>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item>
                {ReduxUtil.hasData(subscription) &&
                  <div>
                    <div className={classes.rowCenter}>
                      <div className={classes.mr1}>
                        <Typography variant="h4">
                          {subscription.data.plan.product.description ? subscription.data.plan.product.description : subscription.data.plan.product.name}
                        </Typography>
                      </div>
                      <Badge color="success">
                        <FormattedMessage id={`account_status_${subscription.data.status}`}/>
                      </Badge>
                    </div>

                    <div>
                      {subscription.data.cancel_at_period_end &&
                      <Typography variant="body2">
                        <FormattedMessage id="expires_on_date" values={{
                          date: DateUtil.formatDate(subscription.data.current_period_end)
                        }}/>
                      </Typography>
                      }
                      {!subscription.data.cancel_at_period_end &&
                      <Typography variant="body2">
                        <FormattedMessage id="next_billing_date" values={{
                          date: DateUtil.formatDate(moment.unix(subscription.data.current_period_end).add(1, 'days'))
                        }}/>
                      </Typography>
                      }
                      {subscription.data.status === 'trialing' &&
                      <Typography variant="body2">
                        <FormattedMessage id="trial_expires_on_date" values={{
                          date: DateUtil.formatDate(subscription.data.trial_end)
                        }}/>
                      </Typography>
                      }
                    </div>
                  </div>
                }
              </Grid>
              <Grid item>
                <LicensesUsedBadge subscription={subscription.data} />
              </Grid>

            </Grid>

            <div className={classes.mt3}>
              <NumberOfLicensesForm mixpanel={mixpanel}
                                    onSubmit={this.onNumberOfLicensesSubmit} />
            </div>

            <div className={classes.mt4}>
              <Grid container justifyContent="space-between">
                <Grid item xs={12} sm="auto">
                  <div className={classes.rowCenter}>
                    {(this.activeStatuses.includes(subscription.data.status) && !subscription.data.cancel_at_period_end) &&
                      <div className={classes.mb2}>
                        <ColorButton color="danger"
                                     variant="contained"
                                     startIcon={<Icon>report_problem</Icon>}
                                     onClick={this.onCancelSubscriptionClick}>
                          <FormattedMessage id="cancel_subscription" />
                        </ColorButton>
                      </div>
                    }
                    {!this.activeStatuses.includes(subscription.data.status) &&
                    <div className={classes.mb2}>
                      <ColorButton color="success"
                                   variant="contained"
                                   startIcon={<Icon>card_membership</Icon>}
                                   onClick={this.onActivatePlanClick}>
                        <FormattedMessage id="activate_plan" />
                      </ColorButton>
                    </div>
                    }
                  </div>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Button variant="contained" color="primary" component={RouterLink} to="/plans">
                    <FormattedMessage id={subscription.data.cancel_at_period_end ? 'subscribe' : 'change_plan'} />
                  </Button>
                </Grid>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    subscription: state.subscription
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  cancelSubscription() {
    return dispatch(cancelSubscription());
  },
  subscribe(data:SubscriptionRequest) {
    return dispatch(subscribe(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(SubscriptionCard)));
