import React from 'react';
import {
  CircularProgress, WithStyles,
  withStyles,
} from '@material-ui/core';
import {FormattedMessage} from 'react-intl';
import styles from "../theme/jss/components/networkConnectionAlertStyles";
import {Alert} from "./index";

type Props = {
  open: boolean
} & WithStyles<typeof styles>

const NetworkConnectionAlert = (props:Props) => {
  const { classes, open } = props;

  if(!open) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Alert severity="danger">
          <div className={classes.content}>
            <div className={classes.message}>
              <FormattedMessage id="network_connection_alert_text" />
            </div>
            <CircularProgress size={15} color="inherit" />
          </div>
        </Alert>
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(NetworkConnectionAlert);