import React, {Component} from 'react';
import {Icon, IconButton, WithStyles, withStyles} from '@material-ui/core';
import styles from '../theme/jss/components/textMaskStyles';
import {TextUtil} from "../utils";
import clsx from "clsx";

type Props = {
  children: string,
  className?: string
} & WithStyles<typeof styles>

type State = {
  masked: boolean
}

class TextMask extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      masked: true
    }
  }

  onToggleMasked = () => {
    this.setState({ masked: !this.state.masked });
  }

  render() {
    const { classes, className, children } = this.props;
    const { masked } = this.state;

    return (
      <span className={clsx(classes.root, className)}>
        <span className={classes.text}>
          {masked ? TextUtil.maskText(children) : children}
        </span>
        <IconButton onClick={this.onToggleMasked} size="small">
          <Icon>{masked ? 'remove_red_eye' : 'lock'}</Icon>
        </IconButton>
      </span>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TextMask);
