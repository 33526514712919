import React from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Link, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import pageStyles from "../../theme/jss/layouts/pageStyles";
import {SubTitle, Well} from "../index";
import {LoanUtil, LocationUtil, TextUtil} from "../../utils";
import IntlFormatter from "../../intl";
import {Link as RouterLink} from "react-router-dom";
import {LoanApplication2009} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

const LoanAddressHistorySummaryRev1 = (props:Props) => {

  const {intl, classes, loanApplication} = props;

  const hasValue = (value:any) => {
    return value !== null && value !== undefined;
  };

  if(!loanApplication.borrowers || loanApplication.borrowers.length === 0) {
    return (
      <Well color="warn">
        <Typography variant="body1">
          <FormattedMessage id="no_address_history_found" />
        </Typography>
      </Well>
    );
  }

  return (
    <Grid container spacing={2}>
      {loanApplication.borrowers.map((borrower, borrowerIndex) => {
        const smMdSize = loanApplication.borrowers!.length > 1 ? 12 / loanApplication.borrowers!.length : 6;
        const missingInfoMessages = [];
        if(LoanUtil.getBorrowerTotalYearsAtAddresses(loanApplication, borrower) < 2) {
          missingInfoMessages.push(`* ${IntlFormatter.formatMessage(intl, 'two_years_residence')}`);
        }

        if((borrower.addresses && !borrower.addresses.find(a => a.isMailingAddress)) || !borrower.addresses) {
          missingInfoMessages.push(`* ${IntlFormatter.formatMessage(intl, 'mailing_address_is_required')}`);
        }

        if((borrower.addresses && !borrower.addresses.find(a => a.current)) || !borrower.addresses) {
          missingInfoMessages.push(`* ${IntlFormatter.formatMessage(intl, 'current_address_is_required')}`);
        }

        return (
          // @ts-ignore
          <Grid key={borrowerIndex} item
                xs={12}
                sm={smMdSize}
                md={smMdSize}>
            <div className={classes.mb2}>
              <SubTitle title={borrower.fullName || IntlFormatter.formatMessage(intl, 'borrower')} />
            </div>

            {(missingInfoMessages.length > 0 || (borrower.addresses && borrower.addresses.length === 0)) &&
            <Well color="warn">
              {missingInfoMessages.length > 0 &&
              <div className={classes.mb2}>
                {missingInfoMessages.map((message, i) => {
                  return (
                    <div key={i}>
                      <Typography variant="caption">{message}</Typography>
                    </div>
                  )
                })}
              </div>
              }

              {(!borrower.addresses || (borrower.addresses && borrower.addresses.length === 0)) &&
              <Typography variant="body1">
                <FormattedMessage id="no_address_history_found" />. &nbsp;
                {!loanApplication.completed &&
                <Link component={RouterLink} to="/apply/address-history">
                  <FormattedMessage id="add_address"/>
                </Link>
                }
              </Typography>
              }
            </Well>
            }

            {borrower.addresses &&
              borrower.addresses.map((form, i) => {
                return (
                  <div key={i} className={classes.pv2}>
                    <Typography variant="caption">
                      {TextUtil.getAddressTypeText(form, intl)}
                    </Typography>
                    <Typography variant="body1">
                      {TextUtil.getValueText(LocationUtil.formatLocation(form), intl)}
                    </Typography>

                    {hasValue(form.housing) &&
                    <Typography variant="body1">
                      <FormattedMessage id={form.housing === 'own' ? 'own_for_num_years' : 'rent_for_num_years' } values={{ num: form.yearsAtLocation }} />
                    </Typography>
                    }
                  </div>
                )
              })
            }
          </Grid>
        )
      })}
    </Grid>
  );
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanAddressHistorySummaryRev1));