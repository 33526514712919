import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  IconButton,
  Icon,
  Button,
  Typography,
  withStyles, InputLabel, Link, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl';
import BaseForm from "../../../forms/BaseForm";
import {
  ErrorList,
  SubmitButton,
  PhoneNumberInput,
  AutoComplete,
  TextField,
  Select,
  PasswordField,
  NoLicensesAlert,
  Alert, UnsavedChangesPrompt
} from "../../../components";
import moment from "moment-timezone";
import _ from 'lodash';
import {connect} from "react-redux";
import {addUser, updateUser} from "../../../actions/users";
import {getRoles} from "../../../actions/roles";
import {ErrorUtil, ObjectUtil, ReduxUtil, TextUtil, UserUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import Api from "../../../lib/Api";
import {EnableDeletedUserForm} from "../../../forms";
import {ActionResponse, LabelValuePairType, ReduxApp, ReduxRoles, ReduxSubscription, ErrorState} from "../../../types";
import {
  Branch,
  Role,
  User,
  UserUpdateRequest,
  UserRequest
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  user?: User,
  onSubmit: (data:AxiosResponse<User>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  roles: ReduxRoles,
  subscription: ReduxSubscription,
  getRoles: () => ActionResponse,
  addUser: (data:UserRequest) => ActionResponse,
  updateUser: (id:string, data:UserUpdateRequest) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  passwordConfirm: string,
  roles: Role[],
  mobilePhoneNumber: string,
  timezone: string,
  additionalProps: {
    branch?: Branch|null
  }
  deleted?: boolean
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  existingUser: User|null,
  passwordFieldsOpen: boolean,
  formPristine: boolean
}

class UserForm extends BaseForm<Props, State> {
  availableTimezones:string[] = [];
  phoneNumberTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      roles: [],
      mobilePhoneNumber: '',
      timezone: moment.tz.guess(),
      additionalProps: {}
    }

    if(props.user) {
      form = _.merge(form, _.cloneDeep(props.user));
    }

    this.state = {
      loading: true,
      form,
      existingUser: null,
      passwordFieldsOpen: false,
      errors: [],
      formPristine: true
    };

    this.availableTimezones = UserUtil.getAmericanTimezones();
    props.app.data.enums.phoneNumberTypes.forEach(type => {
      this.phoneNumberTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `phone_number_types_${type}`) })
    });
  }

  componentDidMount = () => {
    if(ReduxUtil.hasData(this.props.roles)) {
      this.setState({ loading: false });
    } else {
      this.props.getRoles().send().then(() => {
        this.setState({ loading: false });
      }).catch(err => {
        this.setState({ errors: ErrorUtil.formatErrors(err), loading: false });
      });
    }
  };

  componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>) => {
    if(this.state.formPristine && !ObjectUtil.isEqual(prevState.form, this.state.form)) {
      this.setState({formPristine:false});
    }
  }

  onRoleAdd = (roles:Role[]) => {
    const form = _.cloneDeep(this.state.form);
    form.roles = _.cloneDeep(roles);

    const branchManagerRole = roles.find(r => r.key === 'ROLE_BRANCH_MANAGER');
    if(branchManagerRole) {
      if(!form.additionalProps.branch) {
        form.additionalProps.branch = null;
      }
    } else {
      form.additionalProps.branch = null;
    }

    this.setState({ form });
  };

  searchRoles = (searchText:string) => {
    let roles:Role[];
    if(searchText === '' || searchText === null) {
      roles = this.props.roles.data.filter(r => r.key !== 'ROLE_LOAN_OFFICER' && r.subscriptionRequired);
    } else {
      const regex = new RegExp(TextUtil.escapeRegEx(searchText), 'i');
      roles = this.props.roles.data.filter(r => regex.test(r.name) && r.key !== 'ROLE_LOAN_OFFICER' && r.subscriptionRequired);
    }

    return roles;
  };

  setBranch = (branch:Branch) => {
    const form = _.cloneDeep(this.state.form);
    form.additionalProps.branch = branch;
    this.setState({ form });
  };

  removeBranch = () => {
    const form = _.cloneDeep(this.state.form);
    form.additionalProps.branch = null;
    this.setState({ form });
  };

  searchBranches = async (searchText:string) => {
    const result = await Api.searchBranches({searchText}, { orderBy: 'name', orderByDirection: 'asc' });
    return result.data;
  };

  onEmailTextChange = (event:ChangeEvent<{value:string}>) => {
    const form = _.cloneDeep(this.state.form);
    form.email = event.target.value;
    this.setState({ form, existingUser: null });
  };

  onUserFormSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    this.setState({ loading: true, errors: [] });
    const data = _.cloneDeep(this.state.form);
    let formValid = false;
    if(this.props.user) {
      formValid = true;
    } else {
      try {
        const emailResult = await Api.getUserByEmail(data.email, { deleted: 'all'});
        this.setState({ loading: false, existingUser: _.cloneDeep(emailResult.data) });
      } catch (e) {
        if(e.response && e.response.status === 404) {
          formValid = true;
        } else {
          this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
        }
      }
    }

    if(formValid) {
      try {
        let result:AxiosResponse<User>;
        const {firstName, lastName, email, roles, password, timezone, mobilePhoneNumber, additionalProps} = this.state.form;

        if(this.props.user && this.props.user._id) {
          const requestData:UserUpdateRequest = {
            firstName,
            lastName,
            roles: _.map(roles, '_id'),
            timezone,
            mobilePhoneNumber: mobilePhoneNumber !== '' ? mobilePhoneNumber : null,
            additionalProps: {
              branch: additionalProps.branch ? additionalProps.branch._id : null
            }
          };
          result = await this.props.updateUser(this.props.user._id, requestData).send();
          this.props.mixpanel.track("User updated");
        } else {
          const requestData:UserRequest = {
            firstName,
            lastName,
            email,
            password,
            roles: _.map(roles, '_id'),
            timezone,
            mobilePhoneNumber: mobilePhoneNumber !== '' ? mobilePhoneNumber : null,
            additionalProps: {
              branch: additionalProps.branch ? additionalProps.branch._id : null
            }
          };
          result = await this.props.addUser(requestData).send();
          this.props.mixpanel.track("User added");
        }

        this.setState({ loading: false }, () => {
          this.props.onSubmit(_.cloneDeep(result));
        });
      } catch (e) {
        this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
      }
    }
  };

  onDeletedUserFormSubmit = (result:AxiosResponse<User>) => {
    this.props.onSubmit(_.cloneDeep(result));
  };

  onDeletedUserFormCancel = () => {
    this.setState({ existingUser: null });
  };

  onUserExistsAlertClose = () => {
    this.setState({ existingUser: null });
  };

  onGeneratePasswordClick = (event:MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    const form = _.cloneDeep(this.state.form);
    const password = UserUtil.generatePassword();
    form.password = password;
    form.passwordConfirm = password;
    this.setState({ form });
  };

  render() {

    const { intl, classes, roles, user, subscription, mixpanel } = this.props;
    const { form, loading, errors, existingUser, formPristine } = this.state;

    return (
      <div>
        <UnsavedChangesPrompt when={!formPristine} />
        {(!user || (user && !user._id)) &&
          <NoLicensesAlert className={classes.mb2} />
        }
        {existingUser &&
          <div className={classes.mb2}>
            {UserUtil.hasRole(existingUser, 'ROLE_LOAN_OFFICER') &&
            <Alert severity="warning">
              <FormattedMessage id="loan_officer_has_been_archived_not_a_user" values={{ user: existingUser.email }} />
            </Alert>
            }

            {!UserUtil.hasRole(existingUser, 'ROLE_LOAN_OFFICER') &&
              <div>
                {existingUser.deleted &&
                <EnableDeletedUserForm user={existingUser}
                                       mixpanel={mixpanel}
                                       onSubmit={this.onDeletedUserFormSubmit}
                                       onCancel={this.onDeletedUserFormCancel} />
                }
                {!existingUser.deleted &&
                  <Alert severity="danger" action={
                    <IconButton onClick={this.onUserExistsAlertClose} size="small">
                      <Icon>cancel</Icon>
                    </IconButton>
                  }>
                    <FormattedMessage id="user_exists_select_another_email" values={{ user: existingUser.email }} />
                  </Alert>
                }
              </div>
            }
          </div>
        }
        <Form onSubmit={this.onUserFormSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } }/>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name="firstName"
                               label={IntlFormatter.formatMessage(intl, 'first_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.firstName')}
                               value={form.firstName}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name="lastName"
                               label={IntlFormatter.formatMessage(intl, 'last_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.lastName')}
                               value={form.lastName}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name="email"
                               label={IntlFormatter.formatMessage(intl, 'email')}
                               onChange={this.onEmailTextChange}
                               value={form.email}
                               fullWidth={true}
                               disabled={!!(user && user._id)}
                               inputProps={{
                                 autoCapitalize: 'none',
                               }}
                               validators={['required', 'isEmail']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select name="timezone"
                            options={this.availableTimezones}
                            label={IntlFormatter.formatMessage(intl, 'timezone')}
                            value={form.timezone}
                            onChange={(value:string) => this.onItemChange(value, 'form.timezone')}
                            validators={['required']}
                            errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                </Grid>
              </div>
              {!user &&
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <PasswordField name="password"
                                   label={IntlFormatter.formatMessage(intl, 'password')}
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.password')}
                                   value={form.password}
                                   fullWidth={true}
                                   validators={['required', 'isValidPassword']}
                                   errorMessages={[
                                     IntlFormatter.formatMessage(intl, 'validation_required'),
                                     IntlFormatter.formatMessage(intl, 'validation_password_invalid'),
                                   ]} />
                    {form.password === '' &&
                      <Typography variant="caption">
                        <Link href="#" onClick={this.onGeneratePasswordClick}>
                          <FormattedMessage id="generate_password" />
                        </Link>
                      </Typography>
                    }
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PasswordField name="passwordConfirm"
                                   label={IntlFormatter.formatMessage(intl, 'confirm_password')}
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.passwordConfirm')}
                                   value={form.passwordConfirm}
                                   fullWidth={true}
                                   validators={['required', `isPasswordMatch:${form.password}`]}
                                   errorMessages={[
                                     IntlFormatter.formatMessage(intl, 'validation_required'),
                                     IntlFormatter.formatMessage(intl, 'validation_password_dont_match')
                                   ]} />
                  </Grid>
                </Grid>
              </div>
              }

              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <PhoneNumberInput name="mobilePhone"
                                      label={IntlFormatter.formatMessage(intl, 'mobile_phone')}
                                      value={form.mobilePhoneNumber}
                                      fullWidth={true}
                                      onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.mobilePhoneNumber')} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {/* @todo check if the user isBorrower condition should be the form.roles prop */}
              {(ReduxUtil.hasData(roles) && ((user && !UserUtil.isBorrower(user)) || !user)) &&
              <div>
                <div className={classes.mb2}>
                  {roles.data.filter(r => r.subscriptionRequired).length > 0 &&
                  <AutoComplete multiple={true}
                                value={form.roles}
                                getOptionLabel={(item:Role) => item && item.name ? item.name : ''}
                                onChange={this.onRoleAdd}
                                onTextChange={this.searchRoles}
                                label={IntlFormatter.formatMessage(intl, 'roles')}
                                placeholder={IntlFormatter.formatMessage(intl, 'select')}
                                getOptionSelected={(option:Role, value:Role) => {
                                  return option._id === value._id;
                                }}
                                validators={['required']}
                                errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}
                                openOnFocus={true}/>
                  }
                </div>
                {form.roles.find(r => r.key === 'ROLE_BRANCH_MANAGER') &&
                  <div className={classes.mb4}>
                    {!form.additionalProps.branch &&
                    <div>
                      <AutoComplete value={form.additionalProps.branch}
                                    getOptionLabel={(item:Branch) => item && item.name ? item.name : ''}
                                    renderOption={(option:Branch) => (
                                      <React.Fragment>
                                        <div>
                                          <Typography variant="body1">{option.name}</Typography>
                                          {option.manager &&
                                          <Typography variant="caption">
                                            <FormattedMessage id="manager" /> {option.manager.fullName}
                                          </Typography>
                                          }
                                        </div>
                                      </React.Fragment>
                                    )}
                                    onChange={this.setBranch}
                                    onTextChange={this.searchBranches}
                                    label={IntlFormatter.formatMessage(intl, 'branch')}
                                    getOptionSelected={(option:Branch, value:Branch) => {
                                      return option._id === value._id;
                                    }}
                                    getOptionDisabled={(option:Branch) => {
                                      return !!option.manager;
                                    }}
                                    validators={['required']}
                                    errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}
                                    placeholder={IntlFormatter.formatMessage(intl, 'search')}
                                    openOnFocus={true}
                                    debounce={500}/>
                    </div>
                    }
                    {form.additionalProps.branch &&
                    <div>
                      <InputLabel shrink={true}>
                        <FormattedMessage id="branch"/>
                      </InputLabel>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography variant="subtitle1">
                            {form.additionalProps.branch.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={this.removeBranch}>
                            <Icon>cancel</Icon>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </div>
                    }
                  </div>
                }
              </div>
              }
            </Grid>
          </Grid>

          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}
                              disabled={loading || (!user && subscription.data && subscription.data.licensesAvailable <= 0)}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    roles: state.roles,
    subscription: state.subscription
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getRoles() {
    return dispatch(getRoles());
  },
  addUser(data:UserRequest) {
    return dispatch(addUser(data));
  },
  updateUser(id:string, data:UserUpdateRequest) {
    return dispatch(updateUser(id, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(UserForm)));
