import {User, SecureMessage} from "@jerseydev/orca-loans";

class MessageUtil {
  static getTotalUnreadMessageCount = (user:User, secureMessages:SecureMessage[]) => {
    let count = 0;

    return count;
  }
}

export default MessageUtil;