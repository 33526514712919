import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const styles = (theme:Theme) => createStyles({
  root: {
    textAlign: 'center',
  },
  message: {
    fontSize: '22px',
    color: theme.palette.danger.dark
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '& .MuiIcon-root': {
      fontSize: 60,
      color: theme.palette.danger.dark
    }
  }
});

const useStyles = makeStyles(styles);
export default styles;
export {useStyles}
