import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import {FormattedMessage} from 'react-intl';
import {Helmet} from "react-helmet";
import Highlight from "react-highlight";
import {WebHookRequestLog} from "@jerseydev/orca-loans";

type Props = {
  webHookRequestLog: WebHookRequestLog
}

class WebHookRequestLogDetail extends React.Component<Props> {
  render() {
    const { webHookRequestLog } = this.props;
    return (
      <div>
        <Helmet>
          <link rel="stylesheet" href="https://highlightjs.org/static/demo/styles/a11y-dark.css"/>
        </Helmet>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="subtitle1">
              <FormattedMessage id="request" /> <FormattedMessage id="body" />
            </Typography>
            <Highlight className="JavaScript">
              {JSON.stringify(webHookRequestLog.request.body, null, 2)}
            </Highlight>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="subtitle1">
              <FormattedMessage id="response" /> <FormattedMessage id="body" />
            </Typography>

            <Highlight className="JavaScript">
              {webHookRequestLog.response.body && webHookRequestLog.response.body !== '' &&
                JSON.stringify(webHookRequestLog.response.body, null, 2)
              }
              {(!webHookRequestLog.response.body || (webHookRequestLog.response.body && webHookRequestLog.response.body === '')) &&
                <FormattedMessage id="empty" />
              }
            </Highlight>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default WebHookRequestLogDetail;
