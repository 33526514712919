import React, {Component} from 'react';
import {Icon, withStyles, WithStyles} from '@material-ui/core';
import styles from "../theme/jss/components/helperTooltipStyles";
import Tooltip from './Tooltip';

type Props = {
  tooltip: string,
  text?: string
} & WithStyles<typeof styles>

class HelperTooltip extends Component<Props> {
  render() {
    const { classes, text, tooltip } = this.props;

    return (
      <Tooltip title={tooltip}
               className={classes.tooltip}
               arrow={false}>
        <span className={classes.container}>
          {text}
          <Icon fontSize="small" className={classes.icon}>info</Icon>
        </span>
      </Tooltip>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HelperTooltip);
