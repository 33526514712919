import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {UserProfileForm, ChangePasswordForm, LoanOfficerAccountForm} from '../forms';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {PageTitle, Snackbar, Dialog, LoanOfficerProfileIncompleteList} from "../../../components";
import {
  Button,
  Fab,
  Grid, Hidden,
  Icon,
  IconButton, Tooltip, WithStyles,
  withStyles
} from '@material-ui/core';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ErrorUtil, ReduxUtil, UserUtil} from "../../../utils";
import {ReduxAccount, ReduxUser, ActionProps, SnackbarState, ErrorState} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  routeProps: ActionProps,
  user: ReduxUser,
  account: ReduxAccount
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  snackbar: SnackbarState,
  changePasswordFormOpen: boolean,
  errors: ErrorState[]
}

class ProfilePage extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      snackbar: {
        open: false,
        message: ''
      },
      changePasswordFormOpen: false,
      errors: []
    };
  }

  componentDidMount = async () => {
    try {
      await this.props.routeProps.getAccount().send();
      this.setState({ pageLoaded: true });
    } catch (e) {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onChangePasswordClick = () => {
    const state = { changePasswordFormOpen: true };
    this.setState(state);
  };

  onChangePasswordSubmit = () => {
    const state = {
      changePasswordFormOpen: false,
      snackbar: { open: true, message: IntlFormatter.formatMessage(this.props.intl, 'password_changed') }
    };
    this.setState(state);
  };

  onChangePasswordCancel = () => {
    const state = { changePasswordFormOpen: false };
    this.setState(state);
  };

  onSubmit = () => {
    this.setState({ snackbar: { open: true, message: IntlFormatter.formatMessage(this.props.intl, 'profile_saved') }});
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'my_account');
  };

  renderTitleBar = () => {
    const { intl, account, user } = this.props;
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <PageTitle title={this.getPageTitle()}
                     icon="person"
                     subtitle={!UserUtil.hasRole(user.data, 'ROLE_MEMBER') && ReduxUtil.hasData(account) ? account.data.accountNumber.toUpperCase() : undefined} />
        </Grid>
        <Grid item>
          <Hidden mdUp>
            <Tooltip title={IntlFormatter.formatMessage(intl, 'change_password')}>
              <Fab color="primary"
                   size="small"
                   onClick={this.onChangePasswordClick}>
                <Icon>lock</Icon>
              </Fab>
            </Tooltip>
          </Hidden>
          <Hidden smDown>
            <Button variant="contained" color="default" onClick={this.onChangePasswordClick}>
              <FormattedMessage id="change_password" />
            </Button>
          </Hidden>
        </Grid>
      </Grid>
    )
  };

  hideSnackbar = () => {
    this.setState({ snackbar: { open: false, message: '' }});
  };

  render() {
    const { intl, classes, user, mixpanel } = this.props;
    const { pageLoaded, snackbar, changePasswordFormOpen, loading } = this.state;

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: this.getPageTitle() }
                     ]}
                   pageLoaded={pageLoaded}
                   loading={loading}>

        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.hideSnackbar}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.hideSnackbar}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        <Dialog open={changePasswordFormOpen}
                icon={<Icon>lock</Icon>}
                title={IntlFormatter.formatMessage(intl, 'reset_password')}
                color="primaryAlt"
                onClose={this.onChangePasswordCancel}
                fullWidth={true}
                maxWidth="sm">
          <ChangePasswordForm mixpanel={mixpanel}
                              onSubmit={this.onChangePasswordSubmit}
                              onCancel={this.onChangePasswordCancel} />
        </Dialog>

        <div className={classes.content}>
          {(user.data.additionalProps && user.data.additionalProps.loanOfficer) &&
            <div>
              <LoanOfficerProfileIncompleteList loanOfficer={user.data.additionalProps.loanOfficer}
                                                className={classes.mb4} />
              <LoanOfficerAccountForm mixpanel={mixpanel}
                                      onSubmit={this.onSubmit} />
            </div>
          }

          {!UserUtil.isLoanOfficer(user.data) &&
            <UserProfileForm mixpanel={mixpanel}
                             onSubmit={this.onSubmit}/>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    account: state.account
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(ProfilePage)));
