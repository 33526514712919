import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Page} from '../../../layouts';
import {withStyles} from '@material-ui/core/styles';
import {Link, Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import styles from '../../../theme/jss/layouts/accountPageStyles';
import {
  DrawerMenu,
  AccessError,
  OrcaFooter,
  PageLoader,
  Tooltip,
  LoanOfficerProfileAvatar
} from "../../../components";
import config from "../../../config";
import {Paper, Grid, Typography, Icon, List, ListItem, ListItemIcon, ListItemText, LinearProgress, Box} from "@material-ui/core";
import {LoanUtil, ReduxUtil} from "../../../utils";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import queryString from "query-string";
import {getLoanApplication} from "../../../actions/loanApplication";
import _ from 'lodash';
import IntlFormatter from "../../../intl";
import {searchLoanOfficers} from "../../../actions/loanOfficers";
import {LoanApplicationPageTitle} from "../components";
import {WithStyles} from "@material-ui/styles";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {LoanOfficerSearchRequest} from "@jerseydev/orca-loans";
import {
  ActionProps,
  ActionResponse,
  Breadcrumb,
  ReduxLoanApplication,
  ReduxLoanSettings,
  SnackbarState
} from "../../../types";

type Props = {
  loading?: boolean,
  menuId: string,
  className?: string,
  breadcrumbs?: Breadcrumb[],
  pageTitle?: string,
  titleBar?: string|React.ReactNode,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings,
  getLoanApplication: ActionProps["getLoanApplication"],
  searchLoanOfficers: ActionProps["searchLoanOfficers"]
} & WrappedComponentProps
  & RouteComponentProps
  & WithStyles<typeof styles>

type State = {
  loading: boolean,
  redirect?: string,
  snackbar?: SnackbarState,
  errorStatusCode?: number
}

class LoanApplicationPage extends Component<Props, State> {
  loanAction?:ActionResponse;

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    const queryParams = queryString.parse(this.props.location.search);
    /*if(process.env.NODE_ENV !== 'production') {
      queryParams.id = '4fb6bbaa-a209-4049-9c0f-06a875e4c404';
      console.error(`Loan id is hardcoded in the loan application. Please remove this before deploying to production`);
    }*/

    if(queryParams.id) {
      if(!ReduxUtil.hasData(this.props.loanApplication) || (ReduxUtil.hasData(this.props.loanApplication) && queryParams.id !== this.props.loanApplication.data._id)) {
        this.setState({ loading: true });

        try {
          this.loanAction = this.props.getLoanApplication(queryParams.id as string);
          const result = await this.loanAction.send();
          this.loanAction = undefined;
          const state:State = { loading: false };
          if(!LoanUtil.canEdit(result.data)) {
            state.snackbar = {
              open: true,
              variant: 'danger',
              message: IntlFormatter.formatMessage(this.props.intl, 'loan_cannot_be_modified')
            };
            state.redirect = '/dashboard';
          }
          this.setState(state);

        } catch (e) {
          this.setState({ loading: false, errorStatusCode: e.response ? e.response.status : e.status });
        }
      }
    } else if(!ReduxUtil.hasData(this.props.loanApplication)){
      const { pathname } = this.props.location;
      const {settings} = this.props;
      if(pathname !== config.menus.loanApplicationMenu[settings.data.loanRevision][0].to) {
        //this.setState({ redirect: { to: config.menus.loanApplicationMenu[0].to }});
        this.setState({ redirect: '/dashboard' });
      }
    }
  };

  componentWillUnmount = () => {
    if(this.loanAction) {
      this.loanAction.cancel();
    }
  }

  getMenuItems = () => {
    const { intl, classes, loanApplication, settings } = this.props;
    const { pathname } = this.props.location;
    const items = _.cloneDeep(config.menus.loanApplicationMenu[settings.data.loanRevision]);

    //let nextItemIndex = null;
    for(let i=0;i<items.length;i++) {

      if(ReduxUtil.hasData(loanApplication)) {
        const completedSteps = LoanUtil.getCompletedSteps(loanApplication.data);
        if(completedSteps.includes(items[i].id)) {
          items[i].icon = <Icon className={classes.success}>check_circle</Icon>;
        } else if(!loanApplication.data.borrowers || loanApplication.data.borrowers.length === 0) {
          items[i].disabled = true;
          if(pathname !== items[i].to) {
            items[i].secondaryAction = <Tooltip title={IntlFormatter.formatMessage(intl, 'personal_information_must_be_completed')}><Icon className={classes.menuWarningIcon}>warning</Icon></Tooltip>;
          }
        }
      } else {
        if(i > 0) {
          items[i].disabled = true;
        }
      }
    }

    return items;
  };

  render() {

    const { intl, classes, children, breadcrumbs, pageTitle, titleBar, loanApplication, menuId, ...rest } = this.props;
    const { loading, redirect, errorStatusCode, snackbar } = this.state;
    const percentComplete = ReduxUtil.hasData(loanApplication) ? LoanUtil.getCompletedPercent(loanApplication.data) : 0;

    if(redirect) {
      return <Redirect to={{
        pathname: redirect,
        state: snackbar ? { snackbar } : undefined
      }} />
    }

    return (
      <Page pageTitle={IntlFormatter.formatMessage(intl, menuId)}
            drawerMenu={true}
            hasAppBar={true}
            {...rest}>

        <DrawerMenu menuItems={this.getMenuItems()} header={
          <div className={classes.dashboardHeader}>
            <List className={classes.dashboardLinkList}>
              <ListItem component={Link} to="/dashboard" className={classes.dashboardLinkListItem} button>
                <ListItemIcon className={classes.dashboardLinkItemIconContainer}>
                  <Icon className={classes.dashboardLinkItemIcon}>dashboard</Icon>
                </ListItemIcon>
                <ListItemText className={classes.dashboardLinkItemText}
                              disableTypography={true}
                              primary={IntlFormatter.formatMessage(intl, 'dashboard')} />
              </ListItem>
            </List>
          </div>

        } />

        <div className={classes.titleBar}>
          <Grid container alignItems="center" justifyContent="space-between" spacing={6}>
            <Grid item xs={12} sm={9}>
              <LoanApplicationPageTitle id={menuId} />
              <div className={classes.progressBar}>
                <Box display="flex" alignItems="center">
                  <Box width="50%" mr={1}>
                    <LinearProgress variant="determinate" value={percentComplete} color="primary"/>
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{`${percentComplete}%`} {IntlFormatter.formatMessage(intl, 'complete')}</Typography>
                  </Box>
                </Box>
              </div>
            </Grid>

            {(ReduxUtil.hasData(loanApplication) && loanApplication.data.loanOfficer) &&
              <Grid item xs={12} sm={3}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <LoanOfficerProfileAvatar loanOfficer={loanApplication.data.loanOfficer}
                                              size="large"
                                              variant="outlined" />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">
                      {loanApplication.data.loanOfficer.fullName}
                    </Typography>
                    <Typography variant="subtitle2">
                      <FormattedMessage id="loan_officer" />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </div>

        <PageLoader visible={loading} className={classes.pageLoader} />

        {!loading &&
          <div className={classes.content}>
            {errorStatusCode &&
              <div className={classes.errorContainer}>
                <AccessError statusCode={errorStatusCode} />
              </div>
            }
            {!errorStatusCode &&
              <Paper>
                {children}
              </Paper>
            }
          </div>
        }
        <OrcaFooter />
      </Page>
    )
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    settings: state.loanSettings
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getLoanApplication(id:string) {
    return dispatch(getLoanApplication(id));
  },
  searchLoanOfficers(criteria:LoanOfficerSearchRequest, params?:any) {
    return dispatch(searchLoanOfficers(criteria, params));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(LoanApplicationPage))));

