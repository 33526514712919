import React, { Component, MouseEvent } from 'react';
import {
  Icon, IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles, MenuItem, Menu, WithStyles, Chip
} from '@material-ui/core';
import styles from '../theme/jss/layouts/pageStyles';
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {connect} from "react-redux";
import {ErrorUtil, ReduxUtil} from "../utils";
import IntlFormatter from "../intl";
import {getCreditCards, addCreditCard, updateCreditCard, deleteCreditCard} from "../actions/creditCards";
import {updateDefaultPaymentMethod} from "../actions/subscription";
import _ from "lodash";
import {DeleteDialog, Dialog, Section, Snackbar, Tooltip} from "./index";
import {CreditCardForm} from "../forms";
import {getAccount} from "../actions/account";
import {Skeleton} from "@material-ui/lab";
import Fab from "./Fab";
import {ActionResponse, DialogState, ReduxAccount, ReduxCreditCards, SnackbarState, ErrorState} from "../types";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {CreditCard, CreditCardRequest, CreditCardUpdateRequest} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";
import moment from 'moment';

type Props = {
  mixpanel: Mixpanel,
  creditCards: ReduxCreditCards,
  account: ReduxAccount,
  getAccount: () => ActionResponse,
  getCreditCards: () => ActionResponse,
  addCreditCard: (data:CreditCardRequest) => ActionResponse,
  updateCreditCard: (id:string, data:CreditCardUpdateRequest) => ActionResponse,
  deleteCreditCard: (id:string) => ActionResponse,
  updateDefaultPaymentMethod: (id:string) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  loading: boolean,
  selectedCreditCard?: CreditCard|null,
  selectedActionMenu: Element|null,
  creditCardDialog: DialogState,
  deleteDialog: DialogState,
  snackbar: SnackbarState,
  errors: ErrorState[]
}

class CreditCardList extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: true,
      selectedActionMenu: null,
      creditCardDialog: {
        open: false,
        loading: false,
        errors: []
      },
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      snackbar: {
        open: false,
        message: ''
      },
      errors: []
    };
  }

  componentDidMount = async () => {
    try {
      const requests = [
        this.props.getAccount().send(),
        this.props.getCreditCards().send(),
      ];

      await Promise.all(requests);
      this.setState({ loading: false });
    } catch(err) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
    }
  };

  onActionMenuClick = (event:MouseEvent, creditCard:CreditCard) => {
    this.setState({
      selectedActionMenu: event.currentTarget,
      selectedCreditCard: creditCard
    });
  };

  onActionMenuClose = () => {
    this.setState({ selectedActionMenu: null, selectedCreditCard: null });
  };

  onAddClick = () => {
    const creditCardDialog = _.cloneDeep(this.state.creditCardDialog);
    creditCardDialog.open = true;
    this.setState({ creditCardDialog });
  };

  onEditClick = () => {
    const creditCardDialog = _.cloneDeep(this.state.creditCardDialog);
    creditCardDialog.open = true;

    this.setState({
      creditCardDialog,
      selectedActionMenu: null
    });
  };

  onCreditCardDialogClose = () => {
    const creditCardDialog = _.cloneDeep(this.state.creditCardDialog);
    creditCardDialog.open = false;
    creditCardDialog.loading = false;
    creditCardDialog.errors = [];
    this.setState({ creditCardDialog, selectedCreditCard: null });
  };

  onCreditCardDialogSubmit = () => {
    const creditCardDialog = _.cloneDeep(this.state.creditCardDialog);
    creditCardDialog.open = false;
    creditCardDialog.loading = false;
    creditCardDialog.errors = [];

    this.setState({
      creditCardDialog,
      snackbar: {
        open: true,
        message: IntlFormatter.formatMessage(this.props.intl, 'credit_card_saved')
      }
    });
  };

  onDeleteClick = () => {
    const deleteDialog = _.cloneDeep(this.state.deleteDialog);
    deleteDialog.open = true;
    this.setState({
      deleteDialog,
      selectedActionMenu: null,
    });
  };

  onDeleteDialogClose = () => {
    const deleteDialog = _.cloneDeep(this.state.deleteDialog);
    deleteDialog.open = false;
    deleteDialog.loading = false;
    deleteDialog.errors = [];
    this.setState({
      deleteDialog,
      selectedCreditCard: null,
    });
  };

  deleteCreditCard = async () => {
    const deleteDialog = _.cloneDeep(this.state.deleteDialog);

    try {
      deleteDialog.loading = true;
      deleteDialog.errors = [];

      this.setState({ deleteDialog });
      if(this.state.selectedCreditCard) {
        await this.props.deleteCreditCard(this.state.selectedCreditCard.id).send();
        this.props.mixpanel.track('Credit card deleted');
      }

      deleteDialog.open = false;
      deleteDialog.loading = false;

      this.setState({
        deleteDialog,
        selectedCreditCard: null,
        snackbar: {
          open: true,
          variant: 'success',
          message: IntlFormatter.formatMessage(this.props.intl, 'credit_card_deleted')
        }
      });
    } catch(err) {
      deleteDialog.loading = true;
      deleteDialog.errors = ErrorUtil.formatErrors(err);
      this.setState({ deleteDialog });
    }
  };

  onSetDefaultPaymentMethodClick = async () => {
    try {
      this.setState({ selectedActionMenu: null, loading: true });
      if(this.state.selectedCreditCard) {
        await this.props.updateDefaultPaymentMethod(_.clone(this.state.selectedCreditCard.id)).send();
        this.props.mixpanel.track('Default payment method updated');
      }
      this.setState({
        selectedCreditCard: null,
        loading: false,
        snackbar: {
          open: true,
          variant: 'success',
          message: IntlFormatter.formatMessage(this.props.intl, 'credit_card_saved')
        }
      });
    } catch(err) {
      this.setState({ errors: ErrorUtil.formatErrors(err), loading: false });
    }
  };

  onSnackbarClose = () => {
    this.setState({
      snackbar: {
        open: false,
        message:''
      }
    });
  };

  isExpired = (creditCard:CreditCard) => {
    const expirationDate = moment(`${creditCard.exp_year}-${creditCard.exp_month}-01`).add('1', 'month');
    return moment().isAfter(expirationDate);
  };

  render() {
    const { intl, classes, account, creditCards, mixpanel } = this.props;
    const { loading, creditCardDialog, deleteDialog, selectedCreditCard, selectedActionMenu, snackbar } = this.state;

    return (
      <div>

        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.onSnackbarClose}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        <DeleteDialog open={deleteDialog.open}
                      title={selectedCreditCard ? IntlFormatter.formatMessage(intl, `delete_credit_card`) : ''}
                      item={selectedCreditCard ? `****${selectedCreditCard.last4}` : ''}
                      loading={deleteDialog.loading}
                      onCancel={this.onDeleteDialogClose}
                      onSubmit={this.deleteCreditCard} />

        <Dialog open={creditCardDialog.open}
                title={IntlFormatter.formatMessage(intl, selectedCreditCard ? `edit_credit_card` : `add_credit_card`)}
                icon={<Icon>payment</Icon>}
                color="primaryAlt"
                onClose={this.onCreditCardDialogClose}
                fullWidth={true}
                maxWidth="sm" >

          <CreditCardForm creditCard={selectedCreditCard}
                          mixpanel={mixpanel}
                          onSubmit={this.onCreditCardDialogSubmit}
                          onCancel={this.onCreditCardDialogClose} />
        </Dialog>

        <Menu anchorEl={selectedActionMenu}
              open={Boolean(selectedActionMenu)}
              onClose={this.onActionMenuClose}>
          {(selectedCreditCard && !selectedCreditCard.default && !this.isExpired(selectedCreditCard)) &&
          <MenuItem onClick={this.onSetDefaultPaymentMethodClick}>
            <ListItemIcon>
              <Icon>favorite</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'set_default')} />
          </MenuItem>
          }
          <MenuItem onClick={this.onEditClick}>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
          </MenuItem>
          <MenuItem onClick={this.onDeleteClick}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')} />
          </MenuItem>
        </Menu>

        <Section title={IntlFormatter.formatMessage(intl, 'credit_cards')} actions={
          <Fab color="primary"
               size="small"
               onClick={this.onAddClick}
               flat
               rounded>
            <Icon>add</Icon>
          </Fab>
        }>

          {loading &&
          <div>
            <Skeleton />
            <Skeleton />
          </div>
          }

          {(!loading && ReduxUtil.hasData(creditCards) && creditCards.data.length === 0) &&
          <div>
            <Typography variant="body1">
              <FormattedMessage id="no_credit_cards_found" />
            </Typography>
          </div>
          }

          {(!loading && ReduxUtil.hasData(creditCards) && creditCards.data.length > 0) &&
          <div className={classes.mb2}>
            <List>
              {creditCards.data.map(creditCard => {
                const isExpired = this.isExpired(creditCard);
                return (
                  <ListItem key={creditCard.id}>
                    <ListItemIcon>
                      {isExpired &&
                        <Tooltip title={IntlFormatter.formatMessage(intl, 'credit_card_expired')}>
                          <Icon className={classes.warning}>warning</Icon>
                        </Tooltip>
                      }
                      {!isExpired &&
                        <Icon>credit_card</Icon>
                      }
                    </ListItemIcon>
                    <ListItemText primary={`${creditCard.brand} ****${creditCard.last4}`}
                                  secondary={`${creditCard.exp_month}/${creditCard.exp_year}`} />
                    <ListItemSecondaryAction>
                      {(creditCard.id === account.data.defaultPaymentSource) &&
                      <Chip label={IntlFormatter.formatMessage(intl, 'default')}
                            className={classes.chipSuccess}/>
                      }
                      <IconButton onClick={event => this.onActionMenuClick(event, creditCard)}>
                        <Icon>more_vert</Icon>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </List>

          </div>
          }
        </Section>

      </div>
    );
  }
}


const mapStateToProps = (state:ReduxState) => {
  return {
    creditCards: state.creditCards,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getAccount() {
    return dispatch(getAccount());
  },
  getCreditCards() {
    return dispatch(getCreditCards());
  },
  addCreditCard(data:CreditCardRequest) {
    return dispatch(addCreditCard(data));
  },
  updateCreditCard(id:string, data:CreditCardUpdateRequest) {
    return dispatch(updateCreditCard(id, data));
  },
  deleteCreditCard(id:string) {
    return dispatch(deleteCreditCard(id));
  },
  updateDefaultPaymentMethod(id:string) {
    return dispatch(updateDefaultPaymentMethod(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(CreditCardList)));
