import React from 'react';
import {
  Typography,
  Link, Grid, withStyles, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {PairedList, SubTitle, Well} from "../index";
import IntlFormatter from "../../intl";
import {Link as RouterLink} from "react-router-dom";
import {LocationUtil, TextUtil} from "../../utils";
import pageStyles from "../../theme/jss/layouts/pageStyles";
import {LoanApplication2009} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

const LoanRealEstateSummaryRev1 = (props:Props) => {
  const {intl, classes, loanApplication} = props;

  if(!loanApplication.borrowers || loanApplication.borrowers.length === 0) {
    return (
      <Well color="warn">
        <Typography variant="body1">
          <FormattedMessage id="no_real_estate_found" />
        </Typography>
      </Well>
    );
  }

  return (
    <Grid container spacing={2}>
      {loanApplication.borrowers.map((borrower, borrowerIndex) => {
        return (
          <Grid key={borrowerIndex} item
                // @ts-ignore
                md={loanApplication.borrowers && loanApplication.borrowers.length > 1 ? Math.floor(12 / loanApplication.borrowers.length) : 6}>
            
            <div className={classes.mb2}>
              <SubTitle title={borrower.fullName || IntlFormatter.formatMessage(intl, 'borrower')} />
            </div>

            {(!borrower.realEstateAssets || (borrower.realEstateAssets && borrower.realEstateAssets.length === 0)) &&
            <Well color="warn">
              <Typography variant="body1">
                <FormattedMessage id="no_real_estate_found" />.&nbsp;
                {!loanApplication.completed &&
                <Link component={RouterLink} to="/apply/real-estate">
                  <FormattedMessage id="add_real_estate"/>
                </Link>
                }
              </Typography>
            </Well>
            }
            {borrower.realEstateAssets &&
            <div>
              {borrower.realEstateAssets.map((asset, i) => {
                return (
                  <div key={i} className={classes.pv2}>
                    {asset.address &&
                    <Typography variant="subtitle2">{LocationUtil.formatLocation(asset.address)}</Typography>
                    }
                    <PairedList items={[
                      {key: IntlFormatter.formatMessage(intl, 'property_type'), value: IntlFormatter.formatMessage(intl, asset.type ? `declaration_property_types_${asset.type}` : 'na')},
                      {key: IntlFormatter.formatMessage(intl, 'market_value'), value: TextUtil.getMoneyText(asset.marketValue, intl)},
                      {
                        key: IntlFormatter.formatMessage(intl, 'mortgage_lien_amount'),
                        value: TextUtil.getMoneyText(asset.mortgageLienAmount, intl)
                      },
                      {
                        key: IntlFormatter.formatMessage(intl, 'mortgage_payment'),
                        value: TextUtil.getMoneyText(asset.mortgagePayment, intl)
                      },
                      {
                        key: IntlFormatter.formatMessage(intl, 'gross_rental_income'),
                        value: TextUtil.getMoneyText(asset.grossRentalIncome, intl)
                      },
                      {
                        key: IntlFormatter.formatMessage(intl, 'net_rental_income'),
                        value: TextUtil.getMoneyText(asset.netRentalIncome, intl)
                      },
                      {
                        key: IntlFormatter.formatMessage(intl, 'insurance_maintenance_taxes_misc'),
                        value: TextUtil.getMoneyText(asset.insTaxDues, intl)
                      }
                    ]}/>
                  </div>
                )
              })}
            </div>
            }
          </Grid>
        )
      })}
    </Grid>
  );
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanRealEstateSummaryRev1));