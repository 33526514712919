import {defaultRadius} from "../index";
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    borderTop: `solid 1px ${theme.palette.neutral.extraLight}`,
    borderRight: `solid 1px ${theme.palette.neutral.extraLight}`,
    borderBottom: `solid 1px ${theme.palette.neutral.extraLight}`,
    borderRadius: defaultRadius
  },
  default: {
    borderLeft: `solid 5px ${theme.palette.neutral.main}`
  },
  info: {
    borderLeft: `solid 5px ${theme.palette.info.main}`
  },
  primary: {
    borderLeft: `solid 5px ${theme.palette.primary.main}`
  },
  secondary: {
    borderLeft: `solid 5px ${theme.palette.secondary.main}`
  },
  success: {
    borderLeft: `solid 5px ${theme.palette.success.main}`
  },
  warn: {
    borderLeft: `solid 5px ${theme.palette.warning.main}`
  },
  danger: {
    borderLeft: `solid 5px ${theme.palette.danger.main}`
  },
});

export default styles;
