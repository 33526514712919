import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {
  withStyles,
  Icon,
  IconButton,
  Button,
  WithStyles,
  Hidden,
  List,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  ListItem,
  Grid, Typography,
} from "@material-ui/core";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {
  ErrorList,
  PageTitle,
  Badge,
} from "../../../components";
import {DateUtil, ErrorUtil, ReduxUtil, StripeUtil} from "../../../utils";
import {ReduxState} from "../../../data/initialState";
import {Invoice} from "@jerseydev/orca-loans";
import {ActionProps, ErrorState, ReduxInvoices} from "../../../types";
import {Mixpanel} from "mixpanel-browser";
import clsx from "clsx";
import _ from 'lodash';

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  invoices: ReduxInvoices,
  getInvoices: ActionProps["getInvoices"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  errors: ErrorState[],
  limit: number,
  hasMore: boolean,
  page: number
}

class InvoicesPage extends Component<Props, State> {
  pageLimit:number = 10;
  constructor(props:Props) {
    super(props);
    this.state = {
      pageLoaded: false,
      loading: false,
      errors: [],
      limit: _.clone(this.pageLimit),
      hasMore: false,
      page: 1
    };
  }

  componentDidMount = async () => {
    await this.getInvoices();
    this.setState({pageLoaded:true});
  };

  getInvoices = async () => {
    try {
      this.setState({loading: true});
      const result = await this.props.routeProps.getInvoices(this.state.limit).send();
      this.setState({ loading:false, hasMore: result.headers['has-more'] === 'true' });
    } catch(err) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
    }
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'invoices');
  };

  renderTitleBar = () => {
    return <PageTitle title={this.getPageTitle()} icon="payments" />
  };

  onPDFClick = (invoice:Invoice) => {
    this.props.mixpanel.track('Invoice Downloaded');
    window.open(invoice.pdf, '_blank');
  };

  onLoadMoreClick = async () => {
    const limit = this.state.limit + this.pageLimit;
    const page = this.state.page + 1;
    this.setState({limit, page }, this.getInvoices);
  };

  render() {
    const { classes, invoices } = this.props;
    const { errors, pageLoaded, loading, hasMore } = this.state;

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   pageLoaded={pageLoaded}
                   loading={loading}>
        <div className={classes.content}>

          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } } />

          {ReduxUtil.hasData(invoices) &&
            <div>
              <Hidden mdUp implementation="css">
                <List>
                  {invoices.data.map((invoice, i) => {
                    return (
                      <ListItem key={i} divider={true}>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Typography variant="body1">
                              ${StripeUtil.formatAmount(invoice.total)}
                            </Typography>
                            <Typography variant="caption">
                              #{invoice.number}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <div className={classes.rowCenter}>
                              <div className={classes.mr2}>
                                <Badge color="info">
                                  <FormattedMessage id={`invoice_status_${invoice.status}`} />
                                </Badge>
                              </div>
                              {invoice.pdf &&
                                <IconButton onClick={() => this.onPDFClick(invoice)}>
                                  <Icon>download</Icon>
                                </IconButton>
                              }
                            </div>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )
                  })}
                </List>
              </Hidden>
              <Hidden smDown implementation="css">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="status"/>
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="number"/>
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="total"/>
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="created"/>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices.data.map((invoice, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            <Badge color="info">
                              <FormattedMessage id={`invoice_status_${invoice.status}`} />
                            </Badge>
                          </TableCell>
                          <TableCell>
                            {invoice.number}
                          </TableCell>
                          <TableCell>
                            ${StripeUtil.formatAmount(invoice.total)}
                          </TableCell>
                          <TableCell>
                            {DateUtil.formatDate(invoice.created)}
                          </TableCell>
                          <TableCell>
                            {invoice.pdf &&
                              <IconButton onClick={() => this.onPDFClick(invoice)}>
                                <Icon>download</Icon>
                              </IconButton>
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Hidden>

              {hasMore &&
                <div className={clsx(classes.textCenter, classes.mv2)}>
                  <Button onClick={this.onLoadMoreClick}>
                    <FormattedMessage id="load_more" />
                  </Button>
                </div>
              }
            </div>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    invoices: state.invoices
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(InvoicesPage)));

