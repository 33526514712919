import React, {useEffect, useState} from 'react';
import {LoanOfficer} from "@jerseydev/orca-loans";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {LoanOfficerUtil, ReduxUtil} from "../utils";
import IntlFormatter from "../intl";
import {ErrorList} from "./index";
import {ActionProps, ErrorState, ReduxIntegrations} from "../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../data/initialState";
import {connect} from "react-redux";
import {getIntegrations} from "../actions/integrations";

type Props = {
  loanOfficer: LoanOfficer,
  className?: string,
  integrations: ReduxIntegrations,
  getIntegrations: ActionProps["getIntegrations"]
} & WrappedComponentProps

const LoanOfficerProfileIncompleteList = (props:Props) => {
  const {intl, className, loanOfficer, integrations, getIntegrations} = props;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(!ReduxUtil.hasData(integrations)) {
          await getIntegrations().send();
        }

        setLoading(false);
      } catch (e) {
        setLoading(false); // ignore error
      }
    }

    fetchData();
  }, [getIntegrations, integrations]);

  const getErrors = ():ErrorState[] => {
    const errors:ErrorState[] = [];
    const warnings = LoanOfficerUtil.getProfileIncompleteWarnings(loanOfficer, integrations.data);
    warnings.forEach(warning => {
      errors.push({
        message: IntlFormatter.formatMessage(intl, `loan_officer_profile_warning_${warning}`)
      })
    });

    return errors;
  }

  if(!loading) {
    const errors = getErrors();

    return (
      <ErrorList title={IntlFormatter.formatMessage(intl, 'profile_incomplete')}
                 errors={errors}
                 className={className} />
    )
  }

  return null;
};

const mapStateToProps = (state:ReduxState) => {
  return {
    integrations: state.integrations
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getIntegrations() {
    return dispatch(getIntegrations());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoanOfficerProfileIncompleteList));