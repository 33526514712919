import React from 'react';
import {Icon, IconButton, InputAdornment, TextField as MUITextField} from '@material-ui/core';
import {TextValidator, ValidatorComponentProps} from "react-material-ui-form-validator";
import FormComponentBase from "./FormComponentBase";
import {FixMeLater} from "../types";

type DefaultProps = {
  variant: 'filled' | 'outlined' | 'standard',
}

type Props = ValidatorComponentProps & Partial<DefaultProps>;

type State = {
  type: 'password'|'text'
}

class PasswordField extends FormComponentBase<Props, State> {
  static defaultProps:DefaultProps = {
    variant: 'outlined'
  };

  constructor(props:Readonly<FixMeLater>) {
    super(props);

    this.state = {
      type: 'password'
    }
  }

  toggleFieldType = () => {
    this.setState({ type: this.state.type === 'password' ? 'text' : 'password'})
  };

  render() {

    const { validators, errorMessages, label, InputProps, ...rest } = this.props;
    const { type } = this.state;
    const endAdornment = (
      <InputAdornment position="end">
        <IconButton onClick={this.toggleFieldType}>
          <Icon>{type === 'password' ? 'visibility' : 'visibility_off'}</Icon>
        </IconButton>
      </InputAdornment>
    );

    if(validators && validators.length > 0) {
      return <TextValidator label={this.isRequired() && label ? `${label} *` : label}
                            validators={validators}
                            errorMessages={errorMessages}
                            {...rest}
                            type={type}
                            InputProps={{
                              ...InputProps,
                              endAdornment: this.props.value.length > 0 ? endAdornment : undefined
                            }} />
    }

    return <MUITextField label={label}
                         {...rest}
                         type={type}
                         InputProps={{
                           ...InputProps,
                           endAdornment: this.props.value.length > 0 ? endAdornment : undefined
                         }} />
  }
}

export default PasswordField;
