import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {Page} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {
  withStyles,
  Icon,
  IconButton, Grid, Paper, Button, WithStyles,
} from "@material-ui/core";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {AppLogo, ErrorList, NavPills, PageLoader, PageTitle, Plan, Snackbar, Alert} from "../../../components";
import IntlFormatter from "../../../intl";
import {AclUtil, ErrorUtil, ReduxUtil} from "../../../utils";
import {Redirect} from "react-router-dom";
import {
  ReduxPlans,
  ReduxSubscription,
  ReduxUser,
  ActionProps,
  SnackbarState,
  ErrorState, ReduxAccount
} from "../../../types";
import {ReduxState} from "../../../data/initialState";

type Props = {
  account: ReduxAccount,
  user: ReduxUser,
  subscription: ReduxSubscription,
  plans: ReduxPlans,
  routeProps: ActionProps
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  errors: ErrorState[],
  snackbar: SnackbarState,
  selectedInterval: 'month'|'year',
  redirectTo?: string
}

class ExpiredSubscriptionPage extends Component<Props, State>  {

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      errors: [],
      snackbar: {
        open: false,
        message: ''
      },
      selectedInterval: 'month'
    }
  }

  componentDidMount = async () => {
    try {
      await this.props.routeProps.getPlans().send();
      await this.props.routeProps.getSubscription().send();

      const { account } = this.props;
      if(account.data.active) {
        this.setState({ redirectTo: '/subscription' });
      } else {
        this.setState({ pageLoaded: true });
      }

    } catch(err) {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(err) });
    }
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'subscription_expired');
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="payments" />
    )
  };

  onSubscribe = () => {
    this.setState({ redirectTo: '/dashboard' });
  };

  onLogoutClick = (event:MouseEvent) => {
    event.preventDefault();
    this.props.routeProps.logout();
    this.setState({ redirectTo: '/login' });
  };

  onSnackbarClose = () => {
    this.setState({
      snackbar: {
        open: false,
        message:''
      }
    });
  };

  onPriceIntervalClick = () => {
    this.setState({ selectedInterval: this.state.selectedInterval === 'month' ? 'year' : 'month' });
  };

  render() {
    const { intl, classes, account, plans, subscription, user } = this.props;
    const {errors, pageLoaded, loading, snackbar, redirectTo, selectedInterval} = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />
    }

    return (
      <Page pageTitle={this.getPageTitle()}
            titleBar={this.renderTitleBar()}
            loading={loading}>

        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.onSnackbarClose}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />
        <div className={classes.houseBg1}>
          <div className={classes.bgOverlay}>
            <div className={classes.center}>
              <div className={classes.mt5}>
                <Paper elevation={2}>
                  <div className={classes.p3}>
                    <div className={classes.mb3}>
                      <Grid container justifyContent="space-between" alignItems="center" spacing={5}>
                        <Grid item>
                          <AppLogo color="dark"/>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" onClick={this.onLogoutClick}>
                            <FormattedMessage id="logout" />
                          </Button>
                        </Grid>
                      </Grid>
                    </div>

                    <ErrorList errors={errors} onClose={() => { this.setState({ errors: [] })}}/>

                    <PageLoader visible={!pageLoaded} className={classes.mv6} />

                    {pageLoaded &&
                      <div>
                        {ReduxUtil.isIdle(subscription) && !account.data.active &&
                        <div className={classes.mb2}>
                          <Alert severity="warning">
                            <FormattedMessage id={AclUtil.isOwner(user.data) ? 'your_subscription_is_not_active' : 'this_account_is_no_longer_active'} />
                          </Alert>
                        </div>
                        }

                        {AclUtil.isOwner(user.data) &&
                          <div>
                            <div className={classes.mb2}>
                              <NavPills
                                alignCenter
                                color="rose"
                                tabs={[
                                  { tabButton: IntlFormatter.formatMessage(intl, 'month')},
                                  { tabButton: IntlFormatter.formatMessage(intl, 'year')}
                                ]}
                                onChange={this.onPriceIntervalClick}
                              />
                            </div>
                            {(!loading && ReduxUtil.hasData(plans) && ReduxUtil.hasData(subscription)) &&
                            <Grid container spacing={3}>
                              {plans.data.map((plan, i) => {
                                return (
                                  <Grid key={i} item xs={12} sm={4}>
                                    <Plan plan={plan}
                                          subscription={subscription.data}
                                          color={subscription.data.plan.product.id === plan.id ? 'rose' : 'default'}
                                          onSubscribe={this.onSubscribe}
                                          interval={selectedInterval} />
                                  </Grid>
                                )
                              })}
                            </Grid>
                            }
                          </div>
                        }
                      </div>
                    }

                  </div>
                </Paper>
              </div>
            </div>

          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    account: state.account,
    user: state.user,
    subscription: state.subscription,
    plans: state.plans,
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(ExpiredSubscriptionPage)));

