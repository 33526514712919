import React from 'react';
import {
  Typography,
  Link, Grid, withStyles, WithStyles
} from '@material-ui/core';
import {FormattedMessage, WrappedComponentProps, injectIntl} from 'react-intl';
import {Badge, SubTitle, Well} from "../index";
import {Link as RouterLink} from "react-router-dom";
import pageStyles from "../../theme/jss/layouts/pageStyles";
import {LoanApplication2009, Borrower2009, Borrower2009Declaration} from "@jerseydev/orca-loans";
import IntlFormatter from "../../intl";

type Props = {
  loanApplication: LoanApplication2009
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

const LoanDeclarationsSummaryRev1 = (props:Props) => {

  const {classes, loanApplication, intl} = props;

  if(!loanApplication.borrowers || loanApplication.borrowers.length === 0) {
    return (
      <Well color="warn">
        <Typography variant="body1">
          <FormattedMessage id="no_declarations_found" />
        </Typography>
      </Well>
    );
  }

  const renderDeclaration = (labelId:string, prop?:Borrower2009Declaration|null) => {
    const { classes } = props;

    return (
      <div className={classes.pv1}>
        <Typography variant="subtitle2" gutterBottom>
          <FormattedMessage id={labelId} />
        </Typography>
        <div>
          {prop &&
          <Badge color="infoAlt">
            <FormattedMessage id={prop.value ? 'yes' : 'no'} />
            {prop.description &&
            <span>
                  &nbsp;- {prop.description}
                </span>
            }
          </Badge>
          }
          {!prop &&
          <Badge color="warnAlt">
            <FormattedMessage id="not_answered" />
          </Badge>
          }
        </div>
      </div>
    )
  };

  const renderBorrowerDeclarations = (borrower:Borrower2009) => {
    const { classes, loanApplication } = props;

    if(!borrower.declarations) {
      return (
        <div>
          <div className={classes.mb2}>
            <Typography variant="body1">
              <FormattedMessage id="no_declarations_found" />.&nbsp;
              {!loanApplication.completed &&
              <Link component={RouterLink} to="/apply/declarations">
                <FormattedMessage id="add_declarations"/>
              </Link>
              }
            </Typography>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className={classes.pv2}>
          {renderDeclaration('declaration_outstanding_judgments', borrower.declarations.outstandingJudgments )}
          {renderDeclaration('declaration_bankruptcy', borrower.declarations.bankruptcy )}
          {renderDeclaration('declaration_foreclosure', borrower.declarations.foreclosure )}
          {renderDeclaration('declaration_lawsuit', borrower.declarations.lawsuit )}
          {renderDeclaration('declaration_obligated_foreclosure', borrower.declarations.obligatedForeclosure )}
          {renderDeclaration('declaration_in_default', borrower.declarations.inDefault )}
          {renderDeclaration('declaration_alimony_child_support_separate_maintenance', borrower.declarations.alimonyChildSupportSeparateMaintenance )}
          {renderDeclaration('declaration_down_payment_borrowed', borrower.declarations.downPaymentBorrowed )}
          {renderDeclaration('declaration_endorser_on_note', borrower.declarations.endorserOnNote )}
          {renderDeclaration('declaration_us_citizen', borrower.declarations.usCitizen )}
          {renderDeclaration('declaration_permanent_resident_alien', borrower.declarations.permanentResidentAlien )}
          {renderDeclaration('declaration_primary_residence', borrower.declarations.primaryResidence )}

          {borrower.declarations.primaryResidence && borrower.declarations.primaryResidence.ownershipLastThreeYears &&
          <div>
            <Typography variant="body1">
              <FormattedMessage id="declaration_ownership_in_last_three_years" />
              <b className={classes.ml1}><FormattedMessage id="yes" /></b>
            </Typography>
            {borrower.declarations.primaryResidence.propertyType &&
            <div className={classes.pv1}>
              <Typography variant="body1">
                <FormattedMessage id="declaration_ownership_property_type" />
                <b className={classes.ml1}><FormattedMessage id={`declaration_property_types_${borrower.declarations.primaryResidence.propertyType}`} /></b>
              </Typography>
            </div>
            }

            {borrower.declarations.primaryResidence.title &&
            <div className={classes.pv1}>
              <Typography variant="body1">
                <FormattedMessage id="declaration_ownership_title"/>
                <b className={classes.ml1}><FormattedMessage
                  id={`declaration_title_types_${borrower.declarations.primaryResidence.title}`}/></b>
              </Typography>
            </div>
            }
          </div>
          }
        </div>
      </div>
    );
  };

  return (
    <div>
      <Grid container spacing={2}>
        {loanApplication.borrowers.map((borrower, borrowerIndex) => {
          return (
            <Grid key={borrowerIndex}
                  item
                  // @ts-ignore
                  md={loanApplication.borrowers && loanApplication.borrowers.length > 1 ? Math.floor(12 / loanApplication.borrowers.length) : 6}>

              <div className={classes.mb2}>
                <SubTitle title={borrower.fullName || IntlFormatter.formatMessage(intl, 'borrower')} />
              </div>

              {borrower.declarations &&
              <div>
                {renderBorrowerDeclarations(borrower)}
              </div>
              }

              {!borrower.declarations &&
              <div>
                <div className={classes.mb2}>
                  <Well color="warn">
                    <Typography variant="body1">
                      <FormattedMessage id="no_declarations_found" />.&nbsp;
                      {!loanApplication.completed &&
                      <Link component={RouterLink} to="/apply/declarations">
                        <FormattedMessage id="add_declarations"/>
                      </Link>
                      }
                    </Typography>
                  </Well>
                </div>
              </div>
              }
            </Grid>
          )
        })}
      </Grid>
    </div>
  );
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanDeclarationsSummaryRev1));