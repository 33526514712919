import React from 'react';
import {
  Button,
  ButtonProps,
  CircularProgress,
  WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/colorButtonStyles';
import {Link} from 'react-router-dom';

type ColorType = 'info' | 'success' | 'warning' | 'danger' | 'primary' | 'secondary'

type DefaultProps = {
  color: ColorType,
  loading: boolean
}

type Props = {
  to?: string,
  color: ColorType
} & Partial<DefaultProps>
  & WithStyles<typeof styles>
  & Omit<ButtonProps, 'color'>

function ColorButton(props:Props) {
  const { classes, children, color, loading, to, endIcon, size, ...rest } = props;
  return (
    // @ts-ignore
    <Button component={to ? Link : undefined}
            className={classes[color]}
            to={to}
            {...rest}
            endIcon={loading ? <CircularProgress size={size === 'large' ? 14 : 10} color="inherit" /> : endIcon}
            variant="contained">
      {children}
    </Button>
  );
}

const defaultProps:DefaultProps = {
  color: 'info',
  loading: false
};

ColorButton.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(ColorButton);
