import React, {Component} from 'react';
import {Typography, WithStyles, withStyles} from '@material-ui/core';
import styles from "../theme/jss/components/inlineCardStyles";
import clsx from "clsx";

type Props = {
  children: React.ReactNode,
  title?: string
  action?: React.ReactNode,
  icon?: React.ReactNode,
} & WithStyles<typeof styles>

class InlineCard extends Component<Props> {
  render() {
    const { classes, children, title, action, icon } = this.props;
    return (
      <div>
        {title &&
          <Typography variant="caption" gutterBottom>{title}</Typography>
        }
        <div className={clsx(classes.contentContainer, action ? classes.contentContainerWithAction: null)}>
          {icon &&
            <div className={classes.icon}>
              {icon}
            </div>
          }
          <div className={classes.content}>
            {children}
          </div>
          {action &&
            <div>
              {action}
            </div>
          }
        </div>

      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(InlineCard);
