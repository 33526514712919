import React, {MouseEvent} from 'react';
import {Button, ButtonProps, CircularProgress, WithStyles, withStyles} from "@material-ui/core";
import pageStyles from "../theme/jss/layouts/pageStyles";

type DefaultProps = {
  type: "button" | "reset" | "submit" | undefined,
}

type Props = {
  children: React.ReactNode,
  loading?: boolean,
  fullWidth?: boolean,
  onClick?: (event:MouseEvent) => void
} & WithStyles<typeof pageStyles>
  & ButtonProps
  & Partial<DefaultProps>

class SubmitButton extends React.Component<Props> {
  static defaultProps:DefaultProps = {
    type: 'submit'
  };

  onClick = (event:MouseEvent) => {
    if(this.props.onClick && !this.props.loading) {
      this.props.onClick(event);
    }
  }

  render() {
    const { classes, loading, children, type, onClick, endIcon, size, ...other } = this.props;
    return (
      <Button type={type}
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={this.onClick}
              {...other}
              endIcon={loading ? <CircularProgress size={size === 'large' ? 14 : 10} color="inherit" /> : endIcon}>
        {children}
      </Button>
    );
  }
}


export default withStyles(pageStyles, { withTheme: true })(SubmitButton);
