import React, {Component, MouseEvent} from 'react';
import {
  Card, CardContent, CardHeader,
  ClickAwayListener,
  Icon, IconButton, Paper, Popper, Typography, WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/colorPickerStyles';
import {SketchPicker, ColorResult} from "react-color";
import {SubmitButton} from "./index";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import clsx from "clsx";

type DefaultProps = {
  loading: boolean
}

type Props = {
  title: string,
  color: string,
  onSubmit: (data:string) => void,
  onClick: (event:MouseEvent<HTMLDivElement>) => void
} & Partial<DefaultProps>
  & WithStyles<typeof styles>

type State = {
  colorPicker: {
    open: boolean,
    color: string
  }
}

class ColorPicker extends Component<Props, State> {
  static defaultProps:DefaultProps = {
    loading: false
  };

  buttonRef:React.RefObject<HTMLDivElement>|null;

  constructor(props:Props) {
    super(props);
    this.state = {
      colorPicker: {
        open: false,
        color: _.clone(props.color)
      }
    }

    this.buttonRef = React.createRef();
  }

  onClick = (e:MouseEvent<HTMLDivElement>) => {
    const colorPicker = _.cloneDeep(this.state.colorPicker);
    colorPicker.open = true;

    this.setState({ colorPicker }, () => {
      if(this.props.onClick) {
        this.props.onClick(e);
      }
    });
  };

  onClose = () => {
    const colorPicker = _.cloneDeep(this.state.colorPicker);
    colorPicker.open = false;
    colorPicker.color = _.clone(this.props.color);

    this.setState({ colorPicker });
  };

  onChange = (color:ColorResult) => {
    const colorPicker = _.cloneDeep(this.state.colorPicker);
    colorPicker.color = color.hex;
    //console.log(color.hex);
    this.setState({ colorPicker });
  };

  onSubmit = () => {
    const colorPicker = _.cloneDeep(this.state.colorPicker);
    colorPicker.open = false;
    this.setState({ colorPicker }, () => {
      if(this.props.onSubmit) {
        this.props.onSubmit(this.state.colorPicker.color);
      }
    });
  };

  render() {
    const { classes, title, loading } = this.props;
    const { colorPicker } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.previewContainer}>
          <div ref={this.buttonRef}
               className={clsx(classes.colorPreview, colorPicker.open ? classes.colorPreviewSelected : null)}
               style={{ backgroundColor: colorPicker.color }}
               onClick={this.onClick}/>
            <Typography variant="body1" color="inherit">
              {title}
            </Typography>
        </div>

        <Popper open={colorPicker.open}
                anchorEl={this.buttonRef!.current}
                role={undefined}
                transition
                placement="right-start"
                className={classes.popper}
                disablePortal>
          <Paper>
            <ClickAwayListener onClickAway={this.onClose}>
              <Card>
                <CardHeader title={title}
                            action={
                              <IconButton onClick={this.onClose} size="small">
                                <Icon>cancel</Icon>
                              </IconButton>
                            }>
                </CardHeader>
                <CardContent>
                  <SketchPicker color={colorPicker.color}
                                onChangeComplete={this.onChange}  />
                </CardContent>
                <div className={classes.actions}>
                  <SubmitButton loading={loading}
                                onClick={this.onSubmit}>
                    <FormattedMessage id="save" />
                  </SubmitButton>
                </div>
              </Card>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ColorPicker);
