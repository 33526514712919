import React, {useState} from "react";
import {Branch, LoanOfficer, LoanOfficerUpdateRequest} from "@jerseydev/orca-loans";
import Api from "../../../lib/Api";
import IntlFormatter from "../../../intl";
import {AutoComplete, ErrorList, SubmitButton} from "../../../components";
import {injectIntl, WrappedComponentProps, FormattedMessage} from "react-intl";
import {Form} from "../../../forms";
import {Button, Typography, withStyles, WithStyles, Grid} from "@material-ui/core";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanOfficer} from "../../../actions/loanOfficers";
import {connect} from "react-redux";
import {ActionProps, ErrorState} from "../../../types";
import {ErrorUtil} from "../../../utils";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  loanOfficer: LoanOfficer,
  onSubmit?: (loanOfficer:LoanOfficer) => void,
  onCancel?: () => void,
  mixpanel: Mixpanel,
  updateLoanOfficer: ActionProps["updateLoanOfficer"],
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

const RestoreArchivedLoanOfficer = (props:Props) => {
  const {intl, classes, loanOfficer, mixpanel, onCancel} = props;

  const [branch, setBranch] = useState<null|Branch>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorState[]>([]);

  const searchBranches = async (searchText:string) => {
    const result = await Api.searchBranches({ searchText });
    return result.data;
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const data:LoanOfficerUpdateRequest = {
        deleted: false
      };

      if(branch) {
        data.branch = branch._id;
      }
      const result = await props.updateLoanOfficer(loanOfficer._id, data, { deleted: true }).send();
      mixpanel.track("Loan officer restored");
      if(props.onSubmit) {
        props.onSubmit(result.data);
      }
    } catch (e) {
      setErrors(ErrorUtil.formatErrors(e));
      setLoading(false);
    }
  };

  const Buttons = () => {
    return (
      <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>

        {onCancel &&
        <Grid>
          <Button onClick={onCancel} className={classes.mr1}>
            <FormattedMessage id="cancel" />
          </Button>
        </Grid>
        }
        <Grid>
          <SubmitButton loading={loading}
                        onClick={loanOfficer.branch.deleted ? undefined : onSubmit}>
            <FormattedMessage id="yes"/>
          </SubmitButton>
        </Grid>
      </Grid>
    )
  };

  return (
    <div>
      <ErrorList errors={errors}
                 className={classes.mb2} />

      <div className={classes.textCenter}>
        <Typography variant="h4" gutterBottom>
          <FormattedMessage id={loanOfficer.branch.deleted ? "loan_officer_branch_deleted" : "are_you_sure_restore_user"}
                            values={loanOfficer.branch.deleted ? undefined : {user: loanOfficer.user.fullName}} />
        </Typography>
      </div>
      {loanOfficer.branch.deleted &&
        <Form onSubmit={onSubmit}>
          <div className={classes.pv2}>
            <AutoComplete value={branch}
                          getOptionLabel={(item:Branch) => {
                            return item.name;
                          }}
                          onChange={setBranch}
                          onTextChange={searchBranches}
                          label={IntlFormatter.formatMessage(intl, 'branch')}
                          getOptionSelected={(option:Branch, value:Branch) => {
                            return option._id === value._id;
                          }}
                          validators={['required']}
                          errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}
                          placeholder={IntlFormatter.formatMessage(intl, 'search')}
                          openOnFocus={true}
                          debounce={500} />
          </div>
          <div className={classes.mt2}>
            <Buttons />
          </div>
        </Form>
      }
      {!loanOfficer.branch.deleted &&
        <div className={classes.mt4}>
          <Buttons />
        </div>
      }
    </div>
  )
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanOfficer(id:string, data:LoanOfficerUpdateRequest, query?:any) {
    return dispatch(updateLoanOfficer(id, data, query));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(RestoreArchivedLoanOfficer)));