import React from 'react';
import {TextField as MUITextField} from '@material-ui/core';
import {
  TextFieldValidatorComponentProps,
  TextValidator,
} from "react-material-ui-form-validator";
import FormComponentBase from "./FormComponentBase";

export type Props = TextFieldValidatorComponentProps;

type DefaultProps = {
  variant: 'filled' | 'outlined' | 'standard',
}

class TextField extends FormComponentBase<Props, {}> {
  static defaultProps:DefaultProps = {
    variant: 'outlined'
  };

  render() {

    const { validators, errorMessages, label, size, ...rest } = this.props;

    if(validators && validators.length > 0) {
      return <TextValidator label={this.isRequired() && label ? `${label} *` : label}
                            size={size ? size : 'medium'}
                            validators={validators}
                            errorMessages={errorMessages}
                            {...rest}/>
    }

    return <MUITextField label={label} size={size ? size : 'medium'} {...rest} />
  }
}

export default TextField;
