import React, {ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {
  Checkbox, FormControlLabel,
  Grid, Typography, WithStyles,
  withStyles
} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl';
import {BaseForm, AddressFormFields} from "../../../forms";
import {ErrorList, SubmitButton, TextField, RadioGroup, Select, MoneyInput} from '../../../components';
import _ from 'lodash';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, ErrorState, LabelValuePairType} from "../../../types";
import {
  LoanApplication,
  Borrower,
  BorrowerAddress,
  BorrowerAddressRequest,
  BorrowerAddressUpdateRequest, LoanApplicationRequest, LoanApplicationUpdateRequest,
  Location
} from "@jerseydev/orca-loans";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {connect} from "react-redux";
import {ErrorUtil} from "../../../utils";
import {AxiosResponse} from "axios";

type Props = {
  loanApplication: LoanApplication,
  borrower: Borrower,
  address?: BorrowerAddress|null,
  actions?: React.ReactNodeArray,
  onSubmit: (data:AxiosResponse<LoanApplication>) => void,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  street1: string,
  city: string,
  province: string,
  postalCode: string,
  county?: string,
  timeAtLocation: {
    years: string,
    months: string
  },
  housing: string,
  current: string,
  isMailingAddress: boolean,
  rentAmount: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class BorrowerAddressForm extends BaseForm<Props, State> {
  addressTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    this.addressTypes = [
      { label: IntlFormatter.formatMessage(props.intl, 'current_address'), value: 'current' },
      { label: IntlFormatter.formatMessage(props.intl, 'previous_address'), value: 'previous' }
    ];

    // only allow 1 mailing address
    const mailingAddressType:LabelValuePairType = { label: IntlFormatter.formatMessage(props.intl, 'mailing_address'), value: 'mailing' };
    if(props.borrower.addresses && props.borrower.addresses.length > 0) {
      const mailingAddress = props.borrower.addresses.find(a => a.isMailingAddress && ((props.address && a._id !== props.address._id) || !props.address));
      if(!mailingAddress) {
        this.addressTypes.push(mailingAddressType);
      }
    } else {
      this.addressTypes.push(mailingAddressType);
    }

    let form:Form = {
      street1: '',
      city: '',
      province: '',
      postalCode: '',
      county: '',
      timeAtLocation: {
        years: '',
        months: ''
      },
      housing: '',
      current: '',
      isMailingAddress: false,
      rentAmount: ''
    };

    if(props.address) {
      const { street1, city, province, postalCode, county, current, housing, isMailingAddress, rentAmount, timeAtLocation } = props.address;
      let currentVal = '';
      if((current === undefined || current === null) && isMailingAddress) {
        currentVal = 'mailing';
      } else {
        currentVal = current ? 'current' : 'previous';
      }
      form = {
        street1: street1 ? street1 : '',
        city: city ? city : '',
        province: province ? province : '',
        postalCode: postalCode ? postalCode : '',
        county: county ? county : '',
        current: currentVal,
        housing: housing ? housing : '',
        isMailingAddress: typeof isMailingAddress === 'boolean' ? isMailingAddress : false,
        rentAmount: rentAmount ? rentAmount.toString() : '',
        timeAtLocation: {
          years: timeAtLocation && typeof timeAtLocation.years === 'number' ? timeAtLocation.years.toString() : '',
          months: timeAtLocation && typeof timeAtLocation.months === 'number' ? timeAtLocation.months.toString() : ''
        }
      }
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };
  }

  onAddressChange = (newAddress:Location) => {
    let form = _.cloneDeep(this.state.form);
    form = _.merge(form, newAddress);
    this.setState({ form });
  };

  onIsMailingAddressChange = () => {
    let form = _.cloneDeep(this.state.form);
    form.isMailingAddress = !form.isMailingAddress;
    this.setState({ form });
  };

  onSubmit = async () => {
    try {
      this.setState({ loading: true, errors: [] });
      const {loanApplication, borrower, address} = this.props;
      const { street1, city, province, postalCode, county, current, housing, isMailingAddress, rentAmount, timeAtLocation } = this.state.form;

      let requestData:LoanApplicationUpdateRequest;
      let addressRequest:BorrowerAddressUpdateRequest;

      if(current === 'mailing') {
        addressRequest = {
          street1,
          city,
          province,
          postalCode,
          county: county ? county : null,
          isMailingAddress: true
        };
      } else {
        addressRequest = {
          _id: address ? address._id : undefined,
          street1,
          city,
          province,
          postalCode,
          county: county ? county : null,
          current: current === 'current',
          housing: housing as BorrowerAddressRequest["housing"],
          isMailingAddress,
          rentAmount: rentAmount !== '' ? parseInt(rentAmount) : null,
          timeAtLocation: {
            years: parseInt(timeAtLocation.years),
            months: parseInt(timeAtLocation.months)
          }
        };
      }

      requestData = {
        borrowers: [{
          _id: borrower._id,
          addresses: [addressRequest]
        }]
      };

      const result = await this.props.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      this.setState({loading:false});
      if(this.props.onSubmit) {
        this.props.onSubmit(result);
      }
    } catch (e) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, actions, classes, borrower } = this.props;
    const { form, loading, errors } = this.state;

    const isMailingAddressOnly = form.current === 'mailing';

    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Select name="current"
                      onChange={(item:LabelValuePairType) => this.onItemChange(item.value, 'form.current')}
                      getOptionLabel={(item:LabelValuePairType) => item.label}
                      value={form.current !== '' ? this.addressTypes.find(t => t.value === form.current) : ''}
                      label={IntlFormatter.formatMessage(intl, 'type')}
                      validators={['required']}
                      errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}
                      options={this.addressTypes} />
            </Grid>

            {((form.current !== 'mailing') && ((borrower && borrower.addresses && !borrower.addresses.find(a => a.isMailingAddress)) || (this.props.address && this.props.address.isMailingAddress))) &&
            <Grid item>
              <FormControlLabel label={IntlFormatter.formatMessage(intl, 'this_is_also_mailing_address')}
                                control={
                                  <Checkbox checked={form.isMailingAddress}
                                            onChange={this.onIsMailingAddressChange} />
                                } />
            </Grid>
            }
          </Grid>
        </div>

        <div className={classes.mb2}>
          <Grid container spacing={2} alignItems={form.city ? "flex-end" : undefined}>
            <Grid item xs={12} sm={12} md={6}>
              <AddressFormFields address={{ street1: form.street1, city: form.city, province: form.province, postalCode: form.postalCode, county: form.county }}
                                 required={true}
                                 onChange={this.onAddressChange} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {!isMailingAddressOnly &&
            <Grid item xs={12} sm={12} md="auto">
              <RadioGroup name="housing"
                          itemValueProp="value"
                          value={form.housing}
                          onChange={event => this.onRadioChange(event, 'form.housing')}
                          items={[
                            {label: IntlFormatter.formatMessage(intl, 'rent'), value: 'rent'},
                            {label: IntlFormatter.formatMessage(intl, 'own'), value: 'own'},
                            {label: IntlFormatter.formatMessage(intl, 'no_housing_expense'), value: 'noExpense'}
                          ]}
                          validators={['required']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required')
                          ]}
                          row/>
            </Grid>
            }
            {form.housing === 'rent' &&
            <Grid item xs={12} sm={12} md="auto">
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_rent_amount')}
                          value={form.rentAmount}
                          fullWidth={true}
                          validators={['required', 'minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.rentAmount`)} />
            </Grid>
            }
          </Grid>
          {!isMailingAddressOnly &&
            <div className={classes.mt2}>
              <div className={classes.mb1}>
                <Typography variant="caption">
                  <FormattedMessage id="time_at_location" />
                </Typography>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md="auto">
                  <TextField name="yearsAtLocation"
                             label={IntlFormatter.formatMessage(intl, 'years')}
                             value={form.timeAtLocation.years}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.timeAtLocation.years`)}
                             type="number"
                             validators={['required', 'minNumber:0', 'maxNumber:125', 'isNumber']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required'),
                               IntlFormatter.formatMessage(intl, 'validation_minvalue', {value: 0}),
                               IntlFormatter.formatMessage(intl, 'validation_maxvalue', {value: 125}),
                               IntlFormatter.formatMessage(intl, 'validation_whole_number'),
                             ]}/>
                </Grid>
                <Grid item xs={12} sm={12} md="auto">
                  <TextField name="monthsAtLocation"
                             label={IntlFormatter.formatMessage(intl, 'months')}
                             value={form.timeAtLocation.months}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.timeAtLocation.months`)}
                             type="number"
                             validators={['required', 'minNumber:0', 'maxNumber:11', 'isNumber']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required'),
                               IntlFormatter.formatMessage(intl, 'validation_minvalue', {value: 0}),
                               IntlFormatter.formatMessage(intl, 'validation_maxvalue', {value: 11}),
                               IntlFormatter.formatMessage(intl, 'validation_whole_number'),
                             ]}/>
                </Grid>
              </Grid>
            </div>
          }
        </div>

        <Grid container alignItems="center" justifyContent="flex-end" >
          {actions && actions.length > 0 &&
          <Grid item>
            <div className={classes.mr1}>
              {actions.map((action,i) => {
                return <span key={i}>{action}</span>
              })}
            </div>
          </Grid>
          }
          <Grid item>
            <SubmitButton loading={loading}>
              <FormattedMessage id="save" />
            </SubmitButton>
          </Grid>
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(BorrowerAddressForm)));
