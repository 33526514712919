import React, {Component} from 'react';
import {Typography, WithStyles, withStyles} from '@material-ui/core';
import styles from '../theme/jss/components/formSectionStyles';
import clsx from "clsx";

type Props = {
  title: string,
  icon?: string,
  className?: string
} & WithStyles<typeof styles>

class FormSection extends Component<Props> {
  render() {
    const { classes, title, className, children } = this.props;
    return (
      <div className={clsx(className, classes.root)}>
        <div>
          <Typography color="inherit" className={classes.title} gutterBottom>
            {title}
          </Typography>
        </div>
        <div>
          {children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FormSection);
