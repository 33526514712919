import React, {Component} from 'react';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  WithStyles,
  withStyles,
} from "@material-ui/core";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {PageTitle, Well} from "../../../components";
import {LoanComparisonForm} from "../forms";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

class LoanComparisonCalculatorPage extends Component<Props> {
  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'loan_comparison');
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="calculate" />
    )
  };

  render() {

    const { intl, classes, mixpanel } = this.props;

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: IntlFormatter.formatMessage(intl, 'calculators'), to: '/calculators' },
                     {title: this.getPageTitle() }
                   ]}>
        <div className={classes.content}>
          <div className={classes.mb3}>
            <Well color="info">
              <FormattedMessage id="loan_comparison_calculator_text" />
            </Well>
          </div>
          <LoanComparisonForm mixpanel={mixpanel} />
        </div>
      </AccountPage>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanComparisonCalculatorPage));
