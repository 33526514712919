import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  root: {

  },
  chipInfo: {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main
  },
  chipSuccess: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main
  },
  chipWarning: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main
  },
  chipDanger: {
    color: theme.palette.danger.contrastText,
    backgroundColor: theme.palette.danger.main
  },
  percentCompleteLabel: {
    color: theme.palette.neutral.main
  },
  percentCompleteText: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1)
  },
  largeIcon: {
    fontSize: '40px',
    color: theme.palette.neutral.main
  }
});

export default styles;
