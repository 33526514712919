import React from "react";
import classNames from "classnames";
import SwipeableViews from "react-swipeable-views";
import {makeStyles} from "@material-ui/core/styles";
import {Tab, Tabs, Grid} from "@material-ui/core";
import styles from '../theme/jss/components/navPillsStyles';
import {FixMeLater} from "../types";

type TabType = {
  tabButton: string,
  tabIcon?: React.ReactNode,
  tabContent?: React.ReactNode
}

type HorizontalType = {
  tabsGrid:FixMeLater,
  contentGrid:FixMeLater
}

type DefaultProps = {
  active: number,
  color: "white"|"primary"|"warning"|"danger"|"success"|"info"|"rose"
}

type Props = {
  tabs: TabType[],
  direction?: string,
  horizontal?: HorizontalType,
  alignCenter?: boolean,
  onChange?: (event:any) => void
} & Partial<DefaultProps>

const useStyles = makeStyles(styles);

export default function NavPills(props:Props) {
  const [active, setActive] = React.useState(props.active);
  const handleChange = (event:any, active:number) => {
    setActive(active);
    if(props.onChange) {
      props.onChange(active);
    }
  };
  const handleChangeIndex = (index:number) => {
    setActive(index);
  };
  const { tabs, direction, color, horizontal, alignCenter } = props;
  const classes = useStyles();
  const flexContainerClasses = classNames({
    [classes.flexContainer]: true,
    [classes.horizontalDisplay]: horizontal !== undefined
  });
  const tabButtons = (
    <Tabs
      classes={{
        root: classes.root,
        fixed: classes.fixed,
        flexContainer: flexContainerClasses,
        indicator: classes.displayNone
      }}
      value={active}
      onChange={handleChange}
      centered={alignCenter}
    >
      {tabs.map((prop, key) => {
        const icon = {};
        if (prop.tabIcon !== undefined) {
          // @ts-ignore
          icon["icon"] = <prop.tabIcon className={classes.tabIcon} />;
        }
        const pillsClasses = classNames({
          [classes.pills]: true,
          [classes.horizontalPills]: horizontal !== undefined,
          [classes.pillsWithIcons]: prop.tabIcon !== undefined
        });
        return (
          <Tab
            label={prop.tabButton}
            key={key}
            {...icon}
            classes={{
              root: pillsClasses,
              selected: classes[color!]
            }}
          />
        );
      })}
    </Tabs>
  );
  const tabContent = (
    <div className={classes.contentWrapper}>
      <SwipeableViews
        axis={direction === "rtl" ? "x-reverse" : "x"}
        index={active}
        onChangeIndex={handleChangeIndex}
      >
        {tabs.map((prop, key) => {
          return (
            <div className={classes.tabContent} key={key}>
              {prop.tabContent}
            </div>
          );
        })}
      </SwipeableViews>
    </div>
  );
  return horizontal !== undefined ? (
    <Grid container>
      <Grid item {...horizontal.tabsGrid}>{tabButtons}</Grid>
      <Grid item {...horizontal.contentGrid}>{tabContent}</Grid>
    </Grid>
  ) : (
    <div>
      {tabButtons}
      {tabContent}
    </div>
  );
}

const defaultProps:DefaultProps = {
  active: 0,
  color: "primary"
};

NavPills.defaultProps = defaultProps;
