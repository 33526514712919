import {hexToRgb} from "../index";
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  dialogContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },
  errors: {
    marginBottom: theme.spacing(2)
  },
  iconContainer: {
    display:  'inline-block',
    backgroundColor: "rgba(" + hexToRgb(theme.palette.danger.main) + ", .15)",
    borderRadius: '50%',
    width: 75,
    height: 75,
    lineHeight: '90px'
  },
  icon: {
    color: theme.palette.danger.main,
    fontSize: 26
  },
  textCenter: {
    textAlign: 'center'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancelButton: {
    marginRight: theme.spacing(2)
  }
});

export default styles;
