import React, {Component} from 'react';
import {
  Icon,
  withStyles,
  IconButton,
  Typography,
  Grid, WithStyles
} from '@material-ui/core';
import {injectIntl, WrappedComponentProps} from "react-intl";
import styles from "../theme/jss/components/dropzoneFieldStyles";
import {DeleteDialog, Dropzone, HelperTooltip} from "./index";
import {DropzoneProps} from "react-dropzone";
import {DialogState, FileWithPreview, FixMeLater} from "../types";
import IntlFormatter from "../intl";
import {ErrorUtil} from "../utils";

type DefaultProps = {
  multiple: boolean,
  preview: boolean,
  replaceFiles: boolean,
  loading: boolean
}

type Props = {
  label?: string,
  message?: string,
  helperText?: string,
  onAdd: (file:FileWithPreview) => void,
  onRemove: () => void,
  imageDimensions?: {
    width: number,
    height: number
  },
  previewClass?: string,
  value?: any,
  confirmRemoveMessage?: string,
  confirmRemove?: boolean
} & Partial<DefaultProps>
  & WithStyles<typeof styles>
  & WrappedComponentProps
  & DropzoneProps

type State = {
  deleteDialog: DialogState
}

class DropzoneField extends Component<Props, State> {
  static defaultProps:DefaultProps = {
    multiple: false,
    preview: true,
    replaceFiles: true,
    loading: false
  };

  constructor(props:Props) {
    super(props);

    this.state = {
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      }
    }
  }

  renderValueItem = (item:FixMeLater, key?:number) => {
    const { classes, previewClass, label, helperText } = this.props;

    return (
      <div>
        <Typography className={classes.label} gutterBottom>
          {label}
          {helperText &&
            <HelperTooltip tooltip={helperText} />
          }
        </Typography>
        <Grid key={key} container alignItems="center" spacing={2}>
          <Grid item>
            {item.mimeType.includes('image') &&
              <div className={previewClass}>
                <img src={item.url} alt={item.name} className={classes.imgPreview}  />
              </div>
            }
            {!item.mimeType.includes('image') &&
              <Typography variant="body1" color="inherit">
                {item.name}
              </Typography>
            }
          </Grid>
          <Grid item>
            <IconButton onClick={this.onRemove}>
              <Icon>cancel</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </div>
    )
  };

  onRemove = () => {
    const {confirmRemove, onRemove} = this.props;
    if(confirmRemove) {
      this.setState({
        deleteDialog: {
          open: true,
          loading: false,
          errors: []
        }
      });
    } else {
      onRemove();
    }
  };

  onDeleteDialogCancel = () => {
    this.setState({
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      }
    });
  };

  onDeleteDialogConfirm = async () => {
    if(this.props.onRemove) {
      try {
        this.setState({
          deleteDialog: {
            open: true,
            loading: true,
            errors: []
          }
        });
        await this.props.onRemove();
        this.setState({
          deleteDialog: {
            open: false,
            loading: false,
            errors: []
          }
        });
      } catch (e) {
        this.setState({
          deleteDialog: {
            open: true,
            loading: false,
            errors: ErrorUtil.formatErrors(e)
          }
        });
      }
    }
  };

  render() {
    const {intl, classes, value, previewClass, confirmRemoveMessage, onRemove, ...rest} = this.props;
    const {deleteDialog} = this.state;

    return (
      <div>
        <DeleteDialog open={deleteDialog.open}
                      loading={deleteDialog.loading}
                      errors={deleteDialog.errors}
                      title={IntlFormatter.formatMessage(intl, 'delete')}
                      item={confirmRemoveMessage ? confirmRemoveMessage : IntlFormatter.formatMessage(intl, 'file')}
                      onCancel={this.onDeleteDialogCancel}
                      onSubmit={this.onDeleteDialogConfirm} />

        {value &&
          <div>
            {Array.isArray(value) &&
              <div>
                {value.map((v, i) => {
                  return this.renderValueItem(v, i)
                })}
              </div>
            }
            {typeof value === 'object' &&
              <div>
                {this.renderValueItem(value)}
              </div>
            }
          </div>
        }

        {!value &&
          <div className={classes.dropzone}>
            <Dropzone {...rest} />
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(DropzoneField));
