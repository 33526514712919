import React, {ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {
  Button,
  Checkbox, Divider,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  WithStyles,
  withStyles
} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import {BaseForm, AddressFormFields} from "../../../forms";
import {SubmitButton, MoneyInput, Select, ErrorList, TextField, RadioGroup} from '../../../components';
import _ from 'lodash';
import IntlFormatter from "../../../intl";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, ErrorState, LabelValuePairType, ReduxApp} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {
  Borrower,
  BorrowerRealEstateAsset,
  BorrowerRealEstateAssetMortgageUpdateRequest,
  BorrowerRealEstateAssetUpdateRequest,
  LoanApplication,
  LoanApplicationRequest,
  LoanApplicationUpdateRequest,
  Location
} from "@jerseydev/orca-loans";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {AxiosResponse} from "axios";
import {ErrorUtil} from "../../../utils";

type Props = {
  loanApplication: LoanApplication,
  borrower: Borrower,
  realEstate?: BorrowerRealEstateAsset|null,
  actions: React.ReactNodeArray,
  onSubmit: (data:AxiosResponse<LoanApplication>) => void,
  app: ReduxApp,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type FormMortgage = {
  _id?: string,
  creditor: string,
  accountNumber: string,
  monthlyMortgagePayment: string,
  balance: string,
  balancePaidBeforeClosing: boolean,
  loanType: string,
  creditLimit: string
}

type Form = {
  occupancy: string,
  status: string,
  marketValue: string,
  monthlyRentalIncome: string,
  monthlyInsTaxDues: string,
  address: {
    street1: string,
    city: string,
    province: string|null,
    postalCode: string
  },
  mortgages: FormMortgage[],
  hasMortgages: 'yes'|'no'|''
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class RealEstateForm extends BaseForm<Props, State> {
  occupancyTypes:LabelValuePairType[] = [];
  statusTypes:LabelValuePairType[] = [];
  loanTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      occupancy: '',
      status: '',
      marketValue: '',
      monthlyRentalIncome: '',
      monthlyInsTaxDues: '',
      address: {
        street1: '',
        city: '',
        province: null,
        postalCode: ''
      },
      mortgages: [],
      hasMortgages: ''
    };

    if(props.realEstate) {
      const {occupancy, status, marketValue, monthlyRentalIncome, monthlyInsTaxDues, address, mortgages} = props.realEstate;
      form = {
        occupancy: occupancy ? occupancy : '',
        status: status ? status : '',
        marketValue: marketValue ? marketValue.toString() : '',
        monthlyRentalIncome: monthlyRentalIncome ? monthlyRentalIncome.toString() : '',
        monthlyInsTaxDues: monthlyInsTaxDues ? monthlyInsTaxDues.toString() : '',
        address: {
          street1: address && address.street1 ? address.street1 : '',
          city: address && address.city ? address.city : '',
          province: address && address.province ? address.province : null,
          postalCode: address && address.postalCode ? address.postalCode : ''
        },
        mortgages: [],
        hasMortgages: ''
      };

      if(mortgages) {
        if(mortgages.length > 0) {
          form.hasMortgages = 'yes';
          mortgages.forEach(mortgage => {
            const {_id, creditor, creditLimit, accountNumber, balance, balancePaidBeforeClosing, loanType, monthlyMortgagePayment, } = mortgage;
            form.mortgages.push({
              _id,
              creditor: creditor ? creditor : '',
              creditLimit: creditLimit ? creditLimit.toString() : '',
              loanType: loanType ? loanType : '',
              balance: balance ? balance.toString() : '',
              accountNumber: accountNumber ? accountNumber : '',
              monthlyMortgagePayment: monthlyMortgagePayment ? monthlyMortgagePayment.toString() : '',
              balancePaidBeforeClosing: typeof balancePaidBeforeClosing === 'boolean' ? balancePaidBeforeClosing : false
            })
          });
        } else {
          form.hasMortgages = 'no';
        }
      }
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    props.app.data.enums.propertyOccupancyTypes.forEach(type => {
      this.occupancyTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `occupancy_types_${type}`) })
    });

    props.app.data.enums.realEstateAssetStatus.forEach(type => {
      this.statusTypes.push({
        value: type,
        label: IntlFormatter.formatMessage(props.intl, `real_estate_status_${type}`),
        helpText: IntlFormatter.formatMessage(props.intl, `real_estate_status_${type}_help`)
      })
    });

    props.app.data.enums.loanTypes.forEach(type => {
      this.loanTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `loan_type_${type}`) })
    });
  }

  onAddressChange = (address:Location) => {
    const form = _.cloneDeep(this.state.form);
    form.address = {
      street1: address.street1 ? address.street1 : '',
      city: address.city ? address.city : '',
      province: address.province ? address.province : '',
      postalCode: address.postalCode ? address.postalCode : ''
    };

    this.setState({ form });
  };

  setOccupancyType = (type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.occupancy = type ? type.value : null;
    this.setState({ form });
  };

  setStatusType = (type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.status = type ? type.value : null;
    this.setState({ form });
  };

  onAddMortgageClick = () => {
    const form = _.cloneDeep(this.state.form);
    const mortgage = {
      creditor: '',
      accountNumber: '',
      monthlyMortgagePayment: '',
      balance: '',
      balancePaidBeforeClosing: false,
      loanType: '',
      creditLimit: ''
    };
    if(!form.mortgages) {
      form.mortgages = [mortgage];
    } else {
      form.mortgages.push(mortgage);
    }

    this.setState({form});
  };
  
  onRemoveMortgageClick = (index:number) => {
    const form = _.cloneDeep(this.state.form);
    if(form.mortgages) {
      form.mortgages.splice(index, 1);
      if(form.mortgages.length === 0) {
        form.hasMortgages = 'no';
      }
      this.setState({form});
    }
  };
  
  setMortgageLoanType = (index:number, type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    if(form.mortgages) {
      form.mortgages[index].loanType = type ? type.value : null;
      this.setState({ form });
    }
  };

  setHasMortgages = (event:ChangeEvent<HTMLInputElement>) => {
    const form = _.cloneDeep(this.state.form);
    form.hasMortgages = event.target.value as Form['hasMortgages'];
    if(form.hasMortgages === 'yes') {
      form.mortgages.push({
        creditor: '',
        accountNumber: '',
        monthlyMortgagePayment: '',
        balance: '',
        balancePaidBeforeClosing: false,
        loanType: '',
        creditLimit: ''
      })
    } else {
      form.mortgages = [];
    }

    this.setState({ form });
  };

  onSubmit = async () => {
    try {
      const {loanApplication, borrower, realEstate} = this.props;
      this.setState({loading:true, errors: []});
      const {occupancy, status, marketValue, monthlyRentalIncome, monthlyInsTaxDues, address, mortgages} = this.state.form;

      const reoRequestData:BorrowerRealEstateAssetUpdateRequest = {
        _id: realEstate ? realEstate._id : undefined,
        occupancy: occupancy as BorrowerRealEstateAssetUpdateRequest["occupancy"],
        status: status as BorrowerRealEstateAssetUpdateRequest["status"],
        marketValue: parseInt(marketValue),
        monthlyRentalIncome: monthlyRentalIncome ? parseInt(monthlyRentalIncome) : null,
        monthlyInsTaxDues: monthlyInsTaxDues ? parseInt(monthlyInsTaxDues) : null,
        address: {
          street1: address && address.street1 ? address.street1 : null,
          city: address && address.city ? address.city : null,
          province: address && address.province ? address.province : null,
          postalCode: address && address.postalCode ? address.postalCode : null
        },
        mortgages: []
      };

      mortgages.forEach(mortgage => {
        const {_id, creditor, creditLimit, loanType, balance, accountNumber, monthlyMortgagePayment, balancePaidBeforeClosing} = mortgage;
        reoRequestData.mortgages!.push({
          _id,
          creditor,
          creditLimit: parseInt(creditLimit),
          loanType: loanType as BorrowerRealEstateAssetMortgageUpdateRequest["loanType"],
          balance: parseInt(balance),
          accountNumber,
          monthlyMortgagePayment: parseInt(monthlyMortgagePayment),
          balancePaidBeforeClosing
        });
      });

      const requestData:LoanApplicationUpdateRequest = {
        borrowers: [{
          _id: borrower._id,
          realEstateAssets: [reoRequestData]
        }]
      };

      const result = await this.props.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      this.setState({loading:false}, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, actions, classes } = this.props;
    const { loading, form, errors } = this.state;


    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } }/>
        <div className={classes.mb2}>
          <AddressFormFields required={true}
                             address={form.address}
                             onChange={this.onAddressChange} />
        </div>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.mb2}>
                <Select name="occupancy"
                        options={this.occupancyTypes}
                        onChange={this.setOccupancyType}
                        value={form.occupancy ? this.occupancyTypes.find(t => t.value === form.occupancy) : ''}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required'),
                        ]}
                        label={IntlFormatter.formatMessage(intl, 'property_type')}
                        placeholder={IntlFormatter.formatMessage(intl, 'select')} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.mb2}>
                <Select name="status"
                        options={this.statusTypes}
                        onChange={this.setStatusType}
                        value={form.status ? this.statusTypes.find(t => t.value === form.status) : ''}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required'),
                        ]}
                        label={IntlFormatter.formatMessage(intl, 'status')}
                        placeholder={IntlFormatter.formatMessage(intl, 'select')} />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'market_value')}
                          value={form.marketValue}
                          fullWidth={true}
                          decimalScale={0}
                          validators={['required', 'minNumber:0', 'isNumber']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                            IntlFormatter.formatMessage(intl, 'validation_whole_number')
                          ]}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.marketValue')} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_rental_income')}
                          value={form.monthlyRentalIncome}
                          fullWidth={true}
                          decimalScale={0}
                          validators={['minNumber:0', 'isNumber']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                            IntlFormatter.formatMessage(intl, 'validation_whole_number')
                          ]}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.monthlyRentalIncome')} />
            </Grid>
          </Grid>
        </div>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_insurance_taxes_dues')}
                          value={form.monthlyInsTaxDues}
                          fullWidth={true}
                          decimalScale={0}
                          validators={['minNumber:0', 'isNumber']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                            IntlFormatter.formatMessage(intl, 'validation_whole_number')
                          ]}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.monthlyInsTaxDues')} />
            </Grid>
          </Grid>
        </div>
        <div className={classes.mb2}>
          <RadioGroup name="hasMortgages"
                      label={IntlFormatter.formatMessage(intl, 'do_you_have_loans_for_this_property')}
                      itemValueProp="value"
                      value={form.hasMortgages}
                      onChange={this.setHasMortgages}
                      items={[
                        { label: IntlFormatter.formatMessage(intl, 'yes'), value: 'yes' },
                        { label: IntlFormatter.formatMessage(intl, 'no'), value: 'no' }
                      ]}
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required')
                      ]}
                      row />

          {form.hasMortgages === 'yes' &&
            <div>
              {form.mortgages.map((mortgage, i) => {
                return (
                  <div key={i} className={classes.mv2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={10} sm={10} md={11}>
                        <div className={classes.mb2}>
                          <TextField name={`mortgageCreditor${i}`}
                                     label={IntlFormatter.formatMessage(intl, 'creditor')}
                                     value={mortgage.creditor}
                                     fullWidth={true}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.mortgages[${i}].creditor`)}
                                     validators={['required']}
                                     errorMessages={[
                                       IntlFormatter.formatMessage(intl, 'validation_required')
                                     ]} />
                        </div>
                        <div className={classes.mb2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                              <TextField name={`mortgageAccountNumber${i}`}
                                         label={IntlFormatter.formatMessage(intl, 'account_number')}
                                         value={mortgage.accountNumber}
                                         fullWidth={true}
                                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.mortgages[${i}].accountNumber`)}
                                         validators={['required']}
                                         errorMessages={[
                                           IntlFormatter.formatMessage(intl, 'validation_required')
                                         ]} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <Select name={`mortgageType${i}`}
                                      options={this.loanTypes}
                                      onChange={(type:LabelValuePairType) => this.setMortgageLoanType(i, type)}
                                      value={mortgage.loanType ? this.loanTypes.find(t => t.value === mortgage.loanType) : ''}
                                      validators={['required']}
                                      errorMessages={[
                                        IntlFormatter.formatMessage(intl, 'validation_required'),
                                      ]}
                                      label={IntlFormatter.formatMessage(intl, 'loan_type')}
                                      placeholder={IntlFormatter.formatMessage(intl, 'select')} />
                            </Grid>
                          </Grid>
                        </div>
                        <div className={classes.mb2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4}>
                              <MoneyInput label={IntlFormatter.formatMessage(intl, 'balance')}
                                          value={mortgage.balance}
                                          fullWidth={true}
                                          decimalScale={0}
                                          validators={['required', 'minNumber:0', 'isNumber']}
                                          errorMessages={[
                                            IntlFormatter.formatMessage(intl, 'validation_required'),
                                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                            IntlFormatter.formatMessage(intl, 'validation_whole_number')
                                          ]}
                                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.mortgages[${i}].balance`)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                              <FormControlLabel label={IntlFormatter.formatMessage(intl, 'balance_paid_before_closing')} control={
                                <Checkbox checked={mortgage.balancePaidBeforeClosing}
                                          onChange={() => this.onCheckboxChanged(`form.mortgages[${i}].balancePaidBeforeClosing`)}
                                          color="primary" />
                              }
                              />
                            </Grid>
                          </Grid>
                        </div>
                        <div className={classes.mb2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                              <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_mortgage_payment')}
                                          value={mortgage.monthlyMortgagePayment}
                                          fullWidth={true}
                                          decimalScale={0}
                                          validators={['required', 'minNumber:0', 'isNumber']}
                                          errorMessages={[
                                            IntlFormatter.formatMessage(intl, 'validation_required'),
                                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                            IntlFormatter.formatMessage(intl, 'validation_whole_number')
                                          ]}
                                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.mortgages[${i}].monthlyMortgagePayment`)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <MoneyInput label={IntlFormatter.formatMessage(intl, 'credit_limit')}
                                          value={mortgage.creditLimit}
                                          helperText={IntlFormatter.formatMessage(intl, 'if_applicable')}
                                          fullWidth={true}
                                          decimalScale={0}
                                          validators={['minNumber:0', 'isNumber']}
                                          errorMessages={[
                                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                            IntlFormatter.formatMessage(intl, 'validation_whole_number')
                                          ]}
                                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.mortgages[${i}].creditLimit`)} />
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1}>
                        <IconButton onClick={() => this.onRemoveMortgageClick(i)}>
                          <Icon>cancel</Icon>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Divider />
                  </div>
                )
              })}
              <div className={classes.mt2}>
                <Button color="primary" variant="contained" onClick={this.onAddMortgageClick}>
                  <FormattedMessage id="add_another_loan" />
                </Button>
              </div>
            </div>
          }

        </div>
        <Grid container alignItems="center" justifyContent="flex-end" >
          {actions && actions.length > 0 &&
          <Grid item>
            <div className={classes.mr1}>
              {actions.map((action,i) => {
                return <span key={i}>{action}</span>
              })}
            </div>
          </Grid>
          }
          <Grid item>
            <SubmitButton loading={loading}>
              <FormattedMessage id="submit" />
            </SubmitButton>
          </Grid>
        </Grid>

      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(RealEstateForm)));
