import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {BankAccountRequest, BankAccountUpdateRequest, BankAccountVerificationRequest} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";

export function getBankAccounts():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getBankAccounts({cancelToken: cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const bankAccounts = state.bankAccounts && state.bankAccounts.data ? _.cloneDeep(state.bankAccounts.data) : [];
      dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.FETCHING, _.cloneDeep(bankAccounts)));
      apiRequest.then(response => {
        dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.IDLE, bankAccounts));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addBankAccount(data:BankAccountRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createBankAccount(data, {cancelToken: cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const bankAccounts = state.bankAccounts && state.bankAccounts.data ? _.cloneDeep(state.bankAccounts.data) : [];

      dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.ADDING, _.cloneDeep(bankAccounts)));

      apiRequest.then(response => {
        bankAccounts.push(response.data);
        dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.IDLE, bankAccounts));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.IDLE, bankAccounts));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateBankAccount(id:string, data:BankAccountUpdateRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const { account_holder_name, account_holder_type } = data;
    const apiRequest = Api.updateBankAccount(id, { account_holder_name, account_holder_type }, {cancelToken: cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const bankAccounts = state.bankAccounts && state.bankAccounts.data ? _.cloneDeep(state.bankAccounts.data) : [];
      dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.UPDATING, _.cloneDeep(bankAccounts)));

      apiRequest.then(response => {
        const index = bankAccounts.findIndex(c => c.id === id);
        if(index > -1) {
          bankAccounts[index] = response.data;
        } else {
          bankAccounts.push(response.data);
        }
        dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.IDLE, bankAccounts));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.IDLE, bankAccounts));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function verifyBankAccount(id:string, data:BankAccountVerificationRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.verifyBankAccount(id, data, {cancelToken: cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const bankAccounts = state.bankAccounts && state.bankAccounts.data ? _.cloneDeep(state.bankAccounts.data) : [];
      dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.UPDATING, _.cloneDeep(bankAccounts)));

      apiRequest.then(response => {
        const index = bankAccounts.findIndex(c => c.id === id);
        if(index > -1) {
          bankAccounts[index] = response.data;
        } else {
          bankAccounts.push(response.data);
        }
        dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.IDLE, bankAccounts));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.IDLE, bankAccounts));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteBankAccount(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteBankAccount(id, {cancelToken: cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const bankAccounts = state.bankAccounts && state.bankAccounts.data ? _.cloneDeep(state.bankAccounts.data) : [];
      dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.DELETING, _.cloneDeep(bankAccounts)));

      apiRequest.then(response => {
        const index = bankAccounts.findIndex(c => c.id === id);
        if(index > -1) {
          bankAccounts.splice(index, 1);
        }
        dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.IDLE, bankAccounts));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BANK_ACCOUNTS, ReduxAsyncOperation.IDLE, bankAccounts));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}
