import React from "react";
import {Typography} from "@material-ui/core";
import {LoanOfficerProfile} from "@jerseydev/orca-loans";
import {useStyles} from "../theme/jss/layouts/pageStyles";
import IntlFormatter from "../intl";
import {useIntl} from "react-intl";
import {LoanOfficerProfileAvatar} from "./index";

export type LoanOfficerProfileReferenceProps = {
  loanOfficer: LoanOfficerProfile
}

const LoanOfficerProfileReference = (props:LoanOfficerProfileReferenceProps) => {
  const {loanOfficer} = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.rowCenter}>
      <div className={classes.mr1}>
        <LoanOfficerProfileAvatar loanOfficer={loanOfficer} />
      </div>
      <div>
        <Typography variant="body1" color="inherit" gutterBottom={false}>
          <b>{`${loanOfficer.firstName} ${loanOfficer.lastName}`}</b>
        </Typography>
        <Typography variant="caption" color="inherit" component="div">
          {IntlFormatter.formatMessage(intl, 'nmls_id')}
          {loanOfficer.nmlsId}
        </Typography>
        <Typography variant="caption" color="inherit">
          {loanOfficer.branch.name}
        </Typography>
      </div>
    </div>
  )
}

export default LoanOfficerProfileReference