import React from 'react';
import {
  withStyles,
} from '@material-ui/core';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import pageStyles from "../theme/jss/layouts/pageStyles";
import {connect} from "react-redux";
import {BorrowerAssetReportList, Section} from "./index";
import IntlFormatter from "../intl";
import {
  LoanApplication2009,
  Borrower2009,
  LoanApplication,
  Borrower
} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  borrower: Borrower2009|Borrower,
  children?: React.ReactNode
} & WrappedComponentProps

const BorrowerAssetReportSection = (props:Props) => {

  const { intl, borrower, loanApplication, children } = props;

  if((!borrower.assetReports || (borrower.assetReports && borrower.assetReports.length === 0))) {
    return null;
  }

  return (
    <Section title={IntlFormatter.formatMessage(intl, 'connected_accounts')}>
      <BorrowerAssetReportList loanApplication={loanApplication}
                               borrower={borrower} />
      {children}
    </Section>
  )
}


const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(BorrowerAssetReportSection)));