import React, {ChangeEvent} from 'react';
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  withStyles, Typography, WithStyles
} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import BaseForm from "../../../forms/BaseForm";
import _ from 'lodash';
import IntlFormatter from "../../../intl";
import {RadioGroup, TextField, CheckboxGroup} from '../../../components';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, FixMeLater, LabelValuePairType, ReduxApp} from "../../../types";
import {BorrowerDemographics, LoanApplicationRequest} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";

type Props = {
  demographics?: BorrowerDemographics|null,
  onChange: (data:FixMeLater) => void,
  app: ReduxApp,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type FormHispanicEthnicity = "mexican" | "puertoRican" | "cuban" | "other";
type Gender = "male" | "female";
type AsianRace = "asianIndian" | "chinese" | "filipino" | "japanese" | "korean" | "vietnamese" | "other";
type HawaiianRace = "nativeHawaiian" | "guamanianOrChamorro" | "samoan" | "other";

type Form = {
  ethnicity: {
    hispanicOrLatino: string,
    hispanicOrLatinoEthnicities: FormHispanicEthnicity[],
    otherHispanicOrLatino: string,
    declined: boolean
  },
  gender: {
    genders: Gender[],
    declined: boolean
  },
  race: {
    americanIndianOrAlaskanNative: {
      value: boolean,
      description: string
    },
    asian: {
      value: boolean,
      races: AsianRace[],
      other: string
    },
    blackOrAfrican: boolean,
    white: boolean,
    nativeHawaiianOrPacificIslander: {
      value: boolean,
      races: HawaiianRace[],
      other: string
    },
    declined: boolean
  }
}

type State = {
  demographics: Form
}

class DemographicsFormFields extends BaseForm<Props, State> {
  genders:LabelValuePairType[] = [];
  hispanicOrLatinoEthnicities:LabelValuePairType[] = [];
  asianRaces:LabelValuePairType[] = [];
  nativeHawaiianOrPacificIslanderRaces:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let demographics:Form = {
      ethnicity: {
        hispanicOrLatino: '',
        hispanicOrLatinoEthnicities: [],
        otherHispanicOrLatino: '',
        declined: false
      },
      gender: {
        genders: [],
        declined: false
      },
      race: {
        americanIndianOrAlaskanNative: {
          value: false,
          description: ''
        },
        asian: {
          value: false,
          races: [],
          other: ''
        },
        blackOrAfrican: false,
        white: false,
        nativeHawaiianOrPacificIslander: {
          value: false,
          races: [],
          other: ''
        },
        declined: false
      }
    };

    if(props.demographics) {
      const {ethnicity, gender, race} = props.demographics;
      demographics = {
        ethnicity: {
          hispanicOrLatino: ethnicity && typeof ethnicity.hispanicOrLatino === 'boolean' ? ethnicity.hispanicOrLatino ? '1' : '0' : '',
          hispanicOrLatinoEthnicities: ethnicity && ethnicity.hispanicOrLatinoEthnicities ? ethnicity.hispanicOrLatinoEthnicities : [],
          otherHispanicOrLatino: ethnicity && ethnicity.otherHispanicOrLatino ? ethnicity.otherHispanicOrLatino : '',
          declined: ethnicity && typeof ethnicity.declined === 'boolean' ? ethnicity.declined : false
        },
        gender: {
          genders: gender && gender.genders ? gender.genders : [],
          declined: gender && typeof gender.declined === 'boolean' ? gender.declined : false
        },
        race: {
          americanIndianOrAlaskanNative: {
            value: race && race.americanIndianOrAlaskanNative && typeof race.americanIndianOrAlaskanNative.value === 'boolean' ? race.americanIndianOrAlaskanNative.value : false,
            description: race && race.americanIndianOrAlaskanNative && race.americanIndianOrAlaskanNative.description ? race.americanIndianOrAlaskanNative.description : ''
          },
          asian: {
            value: race && race.asian && typeof race.asian.value === 'boolean' ? race.asian.value : false,
            races: race && race.asian && race.asian.races ? race.asian.races : [],
            other: race && race.asian && race.asian.other ? race.asian.other : ''
          },
          blackOrAfrican: race && typeof race.blackOrAfrican === 'boolean' ? race.blackOrAfrican : false,
          white: race && typeof race.white === 'boolean' ? race.white : false,
          nativeHawaiianOrPacificIslander: {
            value: race && race.nativeHawaiianOrPacificIslander && typeof race.nativeHawaiianOrPacificIslander.value === 'boolean' ? race.nativeHawaiianOrPacificIslander.value : false,
            races: race && race.nativeHawaiianOrPacificIslander && race.nativeHawaiianOrPacificIslander.races ? race.nativeHawaiianOrPacificIslander.races : [],
            other: race && race.nativeHawaiianOrPacificIslander && race.nativeHawaiianOrPacificIslander.other ? race.nativeHawaiianOrPacificIslander.other : ''
          },
          declined: race && typeof race.declined === 'boolean' ? race.declined : false
        }
      }
    }

    this.state = {
      demographics
    };

    if(props.demographics && props.demographics.ethnicity) {
      if(props.demographics.ethnicity.hispanicOrLatino === undefined || props.demographics.ethnicity.hispanicOrLatino === null) {
        this.state.demographics.ethnicity.hispanicOrLatino = '';
      } else {
        this.state.demographics.ethnicity.hispanicOrLatino = props.demographics.ethnicity.hispanicOrLatino ? '1' : '0'
      }
    }

    props.app.data.enums.hispanicOrLatinoEthnicities.forEach(type => {
      this.hispanicOrLatinoEthnicities.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `hispanic_or_latino_ethnicities_${type}`) });
    });

    props.app.data.enums.asianRaces.forEach(type => {
      this.asianRaces.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `races_${type}`) });
    });

    props.app.data.enums.nativeHawaiianOrPacificIslanderRaces.forEach(type => {
      this.nativeHawaiianOrPacificIslanderRaces.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `races_${type}`) });
    });

    props.app.data.enums.genders.forEach(type => {
      if(type !== 'declined') {
        this.genders.push({ value: type, label: IntlFormatter.formatMessage(props.intl, type) });
      }
    });
  }
  
  onChildRaceChange = (event:ChangeEvent<HTMLInputElement>, key:'asian'|'nativeHawaiianOrPacificIslander') => {
    const demographics = _.cloneDeep(this.state.demographics);
    let value:FixMeLater = event.target.value;
    if(demographics.race[key].races.includes(value)) {
      const raceIndex = demographics.race[key].races.indexOf(value);
      demographics.race[key].races.splice(raceIndex, 1);
    } else {
      demographics.race[key].races.push(value);
    }

    this.setState({ demographics }, () => {
      this.onChange();
    });
  };

  onEthnicityChange = (event:ChangeEvent<HTMLInputElement>) => {
    const demographics = _.cloneDeep(this.state.demographics);
    demographics.ethnicity.hispanicOrLatino = event.target.value;

    if(demographics.ethnicity.hispanicOrLatino) {
      demographics.ethnicity.otherHispanicOrLatino = '';
      demographics.ethnicity.hispanicOrLatinoEthnicities = [];
    }

    this.setState({ demographics }, () => {
      this.onChange();
    });
  };

  onEthnicityDeclinedChange = () => {
    const demographics = _.cloneDeep(this.state.demographics);

    demographics.ethnicity.declined = !demographics.ethnicity.declined;

    if(demographics.ethnicity.declined) {
      demographics.ethnicity.hispanicOrLatino = '';
      demographics.ethnicity.otherHispanicOrLatino = '';
      demographics.ethnicity.hispanicOrLatinoEthnicities = [];
    }


    this.setState({ demographics }, () => {
      this.onChange();
    });
  };

  onGenderChange = (event:ChangeEvent<HTMLInputElement>) => {
    const demographics = _.cloneDeep(this.state.demographics);
    demographics.gender.declined = false;
    let value:FixMeLater = event.target.value;
    if(demographics.gender.genders.includes(value)) {
      const raceIndex = demographics.gender.genders.indexOf(value);
      demographics.gender.genders.splice(raceIndex, 1);
    } else {
      demographics.gender.genders.push(value);
    }

    this.setState({ demographics }, () => {
      this.onChange();
    });
  };

  onRaceDeclinedChange = () => {
    const demographics = _.cloneDeep(this.state.demographics);

    demographics.race.declined = !demographics.race.declined;
    demographics.race.americanIndianOrAlaskanNative = {
      value: false,
      description: ''
    };

    demographics.race.asian = {
      value: false,
      races: [],
      other: ''
    };

    demographics.race.nativeHawaiianOrPacificIslander = {
      value: false,
      races: [],
      other: ''
    };

    demographics.race.blackOrAfrican = false;
    demographics.race.white = false;

    this.setState({ demographics }, () => {
      this.onChange();
    });
  };

  onGenderDeclinedChange = () => {
    const demographics = _.cloneDeep(this.state.demographics);

    demographics.gender.declined = !demographics.gender.declined;
    if(demographics.gender.declined) {
      demographics.gender.genders = [];
    }

    this.setState({ demographics }, () => {
      this.onChange();
    });
  };

  onChange = () => {
    const {ethnicity, gender, race} = this.state.demographics;

    const demographics:BorrowerDemographics = {
      ethnicity: {
        hispanicOrLatino: ethnicity.hispanicOrLatino !== '' ? ethnicity.hispanicOrLatino === '1' : null,
        hispanicOrLatinoEthnicities: ethnicity.hispanicOrLatinoEthnicities,
        otherHispanicOrLatino: ethnicity.otherHispanicOrLatino !== '' ? ethnicity.otherHispanicOrLatino : null,
        declined: ethnicity.declined
      },
      gender: {
        genders: gender.genders,
        declined: gender.declined
      },
      race: {
        americanIndianOrAlaskanNative: {
          value: race.americanIndianOrAlaskanNative.value,
          description: race.americanIndianOrAlaskanNative.description !== '' ? race.americanIndianOrAlaskanNative.description : null
        },
        asian: {
          value: race.asian.value,
          races: race.asian.races,
          other: race.asian.other !== '' ? race.asian.other : null
        },
        blackOrAfrican: race.blackOrAfrican,
        white: race.white,
        nativeHawaiianOrPacificIslander: {
          value: race.nativeHawaiianOrPacificIslander.value,
          races: race.nativeHawaiianOrPacificIslander.races,
          other: race.nativeHawaiianOrPacificIslander.other !== '' ? race.nativeHawaiianOrPacificIslander.other : null
        },
        declined: race.declined
      }
    };

    if(this.props.onChange) {
      this.props.onChange(demographics);
    }
  };

  onHispanicOrLatinoEthnicityChange = (event:ChangeEvent<HTMLInputElement>) => {
    const demographics = _.cloneDeep(this.state.demographics);

    const index = demographics.ethnicity.hispanicOrLatinoEthnicities.findIndex(e => e === event.target.value);
    if(index === -1) {
      demographics.ethnicity.hispanicOrLatinoEthnicities.push(event.target.value as FixMeLater);
    } else {
      demographics.ethnicity.hispanicOrLatinoEthnicities.splice(index, 1);
    }

    this.setState({ demographics }, () => {
      this.onChange();
    });
  };

  render() {

    const { intl, classes } = this.props;
    const { demographics } = this.state;

    return (
     <div>
       <div className={classes.mb2}>
         <div className={classes.mb2}>

           <Typography variant="subtitle2">
             <FormattedMessage id="ethnicity" />
           </Typography>

           <div className={classes.mt2}>
             {!demographics.ethnicity.declined &&
               <RadioGroup name="hispanicOrLatino"
                           label={IntlFormatter.formatMessage(intl, 'hispanic_or_latino')}
                           itemValueProp="value"
                           value={demographics.ethnicity.hispanicOrLatino}
                           onChange={this.onEthnicityChange}
                           items={[
                             {label: IntlFormatter.formatMessage(intl, 'yes'), value: '1'},
                             {label: IntlFormatter.formatMessage(intl, 'no'), value: '0'}
                           ]}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} row/>

             }
           </div>
         </div>
         {demographics.ethnicity.hispanicOrLatino === '1' &&
           <div>
             <FormControl component="fieldset">
               <FormGroup>

                 {this.hispanicOrLatinoEthnicities.map((ethnicity, i) => {
                   return (
                     <FormControlLabel key={i}
                                       label={ethnicity.label}
                                       control={
                                         <Checkbox checked={demographics.ethnicity.hispanicOrLatinoEthnicities.includes(ethnicity.value)}
                                                   onChange={this.onHispanicOrLatinoEthnicityChange}
                                                   value={ethnicity.value} />
                                       } />
                   )
                 })}
               </FormGroup>
             </FormControl>

             {demographics.ethnicity.hispanicOrLatinoEthnicities.includes('other') &&
               <TextField name="ethnicityOther"
                          label={IntlFormatter.formatMessage(intl, 'other')}
                          value={demographics.ethnicity.otherHispanicOrLatino}
                          fullWidth={true}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'demographics.ethnicity.otherHispanicOrLatino', this.onChange)} />
             }
           </div>
         }

         <div className={classes.mt2}>
           <FormControlLabel label={IntlFormatter.formatMessage(intl, 'do_not_wish_to_provide')}
                             control={
                               <Checkbox checked={demographics.ethnicity.declined}
                                         onChange={this.onEthnicityDeclinedChange}/>
                             }/>
         </div>
       </div>
       <div className={classes.mb2}>
         <FormControl component="fieldset">
           <FormLabel component="legend">
             <FormattedMessage id="race"/>
           </FormLabel>
           <FormGroup>
             {!demographics.race.declined &&
               <div>
                 <div>
                   <FormControlLabel label={IntlFormatter.formatMessage(intl, 'races_americanIndianOrAlaskanNative')}
                                     control={
                                       <Checkbox checked={demographics.race.americanIndianOrAlaskanNative.value}
                                                 onChange={() => this.onCheckboxChanged('demographics.race.americanIndianOrAlaskanNative.value', this.onChange)}/>
                                     }/>

                 </div>

                 {demographics.race.americanIndianOrAlaskanNative.value &&
                 <div className={classes.pl3}>
                   <TextField name="americanIndianOrAlaskanNativeDescription"
                              helperText={IntlFormatter.formatMessage(intl, 'enter_name_of_tribe')}
                              value={demographics.race.americanIndianOrAlaskanNative.description}
                              fullWidth={true}
                              onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'demographics.race.americanIndianOrAlaskanNative.description', this.onChange)}/>
                 </div>
                 }

                 <div>
                   <FormControlLabel label={IntlFormatter.formatMessage(intl, 'races_asian')}
                                     control={
                                       <Checkbox checked={demographics.race.asian.value}
                                                 onChange={() => this.onCheckboxChanged('demographics.race.asian.value', this.onChange)}/>
                                     }/>
                 </div>

                 {demographics.race.asian.value &&
                 <div className={classes.pl3}>
                   {this.asianRaces.map((race, i) => {
                     return (
                       <FormControlLabel key={i}
                                         label={race.label}
                                         control={
                                           <Checkbox checked={demographics.race.asian.races.includes(race.value)}
                                                     onChange={event => this.onChildRaceChange(event, 'asian')}
                                                     value={race.value}/>
                                         }/>
                     )
                   })}
                   {demographics.race.asian.races.includes('other') &&
                   <div className={classes.mt2}>
                     <TextField name="asianOther"
                                label={IntlFormatter.formatMessage(intl, 'other')}
                                value={demographics.race.asian.other}
                                fullWidth={true}
                                onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'demographics.race.asian.other', this.onChange)}/>
                   </div>
                   }
                 </div>
                 }

                  <div>
                     <FormControlLabel label={IntlFormatter.formatMessage(intl, 'races_blackOrAfrican')}
                                       control={
                                         <Checkbox checked={demographics.race.blackOrAfrican}
                                                   onChange={() => this.onCheckboxChanged('demographics.race.blackOrAfrican', this.onChange)}/>
                                       }/>
                  </div>

                 <div>
                   <FormControlLabel label={IntlFormatter.formatMessage(intl, 'races_nativeHawaiianOrPacificIslander')}
                                     control={
                                       <Checkbox checked={demographics.race.nativeHawaiianOrPacificIslander.value}
                                                 onChange={() => this.onCheckboxChanged('demographics.race.nativeHawaiianOrPacificIslander.value', this.onChange)}/>
                                     }/>
                 </div>
                 {demographics.race.nativeHawaiianOrPacificIslander.value &&
                 <div className={classes.pl3}>
                   {this.nativeHawaiianOrPacificIslanderRaces.map((race, i) => {
                     return (
                       <FormControlLabel key={i}
                                         label={race.label}
                                         control={
                                           <Checkbox
                                             checked={demographics.race.nativeHawaiianOrPacificIslander.races.includes(race.value)}
                                             onChange={event => this.onChildRaceChange(event, 'nativeHawaiianOrPacificIslander')}
                                             value={race.value}/>
                                         }/>
                     )
                   })}

                   {demographics.race.nativeHawaiianOrPacificIslander.races.includes('other') &&
                   <div className={classes.mt2}>
                     <TextField name="asianOther"
                                label={IntlFormatter.formatMessage(intl, 'other')}
                                value={demographics.race.nativeHawaiianOrPacificIslander.other}
                                fullWidth={true}
                                onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'demographics.race.nativeHawaiianOrPacificIslander.other', this.onChange)}/>
                   </div>
                   }
                 </div>
                 }

                 <div>
                   <FormControlLabel label={IntlFormatter.formatMessage(intl, 'races_white')}
                                     control={
                                       <Checkbox checked={demographics.race.white}
                                                 onChange={() => this.onCheckboxChanged('demographics.race.white', this.onChange)}/>
                                     }/>
                 </div>
               </div>
             }
             <div>
               <FormControlLabel label={IntlFormatter.formatMessage(intl, 'do_not_wish_to_provide')}
                                 control={
                                   <Checkbox checked={demographics.race.declined}
                                             onChange={this.onRaceDeclinedChange} />
                                 } />
             </div>
           </FormGroup>
         </FormControl>
       </div>
       <div className={classes.mb2}>
         <FormLabel component="legend">
           <FormattedMessage id="gender" />
         </FormLabel>
         <div className={classes.row}>

           {!demographics.gender.declined &&
           <div>
             <CheckboxGroup name="genders"
                            items={this.genders}
                            value={demographics.gender.genders}
                            onChange={this.onGenderChange}
                            validators={['oneOrMore']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_at_least_one_value_is_required')
                            ]} row/>
           </div>
           }
           <div>
             <FormControlLabel label={IntlFormatter.formatMessage(intl, 'do_not_wish_to_provide')}
                               control={
                                 <Checkbox checked={demographics.gender.declined}
                                           onChange={this.onGenderDeclinedChange} />
                               } />
           </div>
         </div>
       </div>
     </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(DemographicsFormFields)));
