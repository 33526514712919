import React from "react";
import {FormattedMessage} from "react-intl";
import {Typography, TypographyProps} from "@material-ui/core";
import _ from "lodash";

type Props = {
  years?: number|null,
  months?: number|null
} & TypographyProps

function YearsMonthsSummary(props:Props) {
  const { years, months, ...rest } = props;
  if(_.isNil(years) && _.isNil(months)) {
    return null;
  }

  return (
    <Typography {...rest}>
      {(!_.isNil(years) && !_.isNil(months)) &&
      <FormattedMessage id="num_years_months"
                        values={{
                          years: years,
                          months: months
                        }} />
      }
      {(_.isNil(years) && !_.isNil(months)) &&
      <FormattedMessage id="num_months"
                        values={{months: months}} />
      }
      {(!_.isNil(years) && _.isNil(months)) &&
      <FormattedMessage id="num_years"
                        values={{years: years}} />
      }
    </Typography>
  )
}

export default YearsMonthsSummary;