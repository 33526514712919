import {Theme} from "@material-ui/core";
import {createStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const styles = (theme:Theme) => createStyles({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  }
});

export default makeStyles(styles);

