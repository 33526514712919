import React, {ChangeEvent, MouseEvent} from "react";
import {
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  Checkbox,
  IconButton,
  Icon,
  Link, Hidden
} from "@material-ui/core";
import useStyles from "../theme/jss/components/setupRecommendationsStyles";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {AccountRequest, AccountRecommendedFeature} from "@jerseydev/orca-loans";
import {updateAccount} from "../actions/account";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {Badge, Snackbar} from "./index";
import clsx from "clsx";
import IntlFormatter from "../intl";
import {Link as RouterLink} from "react-router-dom";
import _ from 'lodash';

type Props = {
  className: string
}

function SetupRecommendations(props:Props) {
  const {className} = props;
  const classes = useStyles();
  const intl = useIntl();
  const account = useSelector((state:ReduxState) => state.account);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<Element|null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState<boolean>(false);

  const getPercentageComplete = () => {
    let percentageComplete = 100;

    if(account?.data.setupRecommendations) {
      const total = account?.data.setupRecommendations.length;
      const usedTotal = account?.data.setupRecommendations.filter(f => f.used).length;
      percentageComplete = Math.round((usedTotal / total) * 100);
    }

    return percentageComplete;
  };

  const onPopoverButtonClick = (event:MouseEvent) => {
    setAnchorEl(event.currentTarget);
  }

  const onPopoverClose = () => {
    setAnchorEl(null);
  }

  const onFeatureCheckClick = async (e:ChangeEvent<HTMLInputElement>, feature:AccountRecommendedFeature) => {
    if(account?.data.setupRecommendations && account?.data.setupRecommendations.length > 0) {
      try {
        setLoading(true);
        const used = e.target.checked;
        const setupRecommendations = _.cloneDeep(account?.data.setupRecommendations);
        const recommendedFeature = setupRecommendations.find(f => f.key === feature.key);
        if(recommendedFeature) {
          recommendedFeature.used = used;
          const requestData:AccountRequest = {
            setupRecommendations
          };

          await dispatch(updateAccount(requestData)).send();
        }
        setLoading(false);
      } catch (e) {
        setErrorSnackbarOpen(true);
        setLoading(false)
      }
    }
  };

  const onCheckAllClick = async () => {
    if(account?.data.setupRecommendations && account?.data.setupRecommendations.length > 0) {
      try {
        setLoading(true);
        const setupRecommendations = _.cloneDeep(account?.data.setupRecommendations);
        for (let i=0; i<setupRecommendations.length; i++) {
          setupRecommendations[i].used = true;
        }

        const requestData: AccountRequest = {
          setupRecommendations
        };

        await dispatch(updateAccount(requestData)).send();
        setLoading(false);
      } catch (e) {
        setErrorSnackbarOpen(true);
        setLoading(false)
      }
    }
  };

  if(!account?.data.setupRecommendations || (account?.data.setupRecommendations && account?.data.setupRecommendations.filter(f => !f.used).length === 0)) {
    return null;
  }

  const open = Boolean(anchorEl);
  const percentage = getPercentageComplete();

  return (
    <div className={clsx(classes.root, className)}>
      <Snackbar open={errorSnackbarOpen}
                variant="danger"
                onClose={() => setErrorSnackbarOpen(false)}
                message={IntlFormatter.formatMessage(intl, 'error_occurred')}
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setErrorSnackbarOpen(false)}>
                    <Icon>close</Icon>
                  </IconButton>
                ]} />
      <div onClick={onPopoverButtonClick} className={classes.button}>
        <CircularProgressbar value={percentage}
                             text={`${percentage}%`}
                             className={classes.progressCircle} />
        <Hidden xsDown>
          <Badge color="infoAlt">
            <FormattedMessage id="setup_completed" />
          </Badge>
        </Hidden>
      </div>

      <Popover
        id="popover"
        open={open}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List dense={true}>
          {account?.data.setupRecommendations.map((feature, i) => {
            return (
              <ListItem key={i}>
                <ListItemIcon>
                  <Checkbox checked={feature.used}
                            disabled={loading}
                            onChange={(e) => onFeatureCheckClick(e, feature)} />
                </ListItemIcon>
                <ListItemText primary={
                  feature.url ?
                    <Link component={RouterLink} to={feature.url}><FormattedMessage id={`feature_${feature.key}`} /></Link>
                    :
                    IntlFormatter.formatMessage(intl, `feature_${feature.key}`)}/>
              </ListItem>
            )
          })}
        </List>
        <Divider />
        <div className={classes.checkAllContainer}>
          <Button onClick={onCheckAllClick}
                  disabled={loading}
                  fullWidth={true}>
            <FormattedMessage id="check_all" />
          </Button>
        </div>
      </Popover>
    </div>
  )
}

export default SetupRecommendations;