import React, {ChangeEvent} from 'react';
import {
  FormLabel,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup as MUIRadioGroup,
  FormHelperText, RadioGroupProps
} from '@material-ui/core';
import {ValidatorComponent, ValidatorComponentProps} from "react-material-ui-form-validator";

type Props = {} & ValidatorComponentProps
  & RadioGroupProps

type State = {
  isValid: boolean
}

class RadioGroup extends ValidatorComponent<Props, State> {
  isRequired = () => {
    let required = false;
    if(this.props.validators && this.props.validators.indexOf('required') > -1) {
      required = true;
    }

    return required;
  };

  renderError = () => {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <FormHelperText>
        {this.getErrorMessage()}
      </FormHelperText>
    );
  };

  onChange = (event:ChangeEvent<HTMLInputElement>) => {
    if(this.props.onChange) {
      this.props.onChange(event, event.target.value);
    }
  };

  renderValidatorComponent() {

    const { label, value, items, itemLabelProp, itemValueProp, errorMessages, validators, requiredError, validatorListener, disabled, size, ...rest } = this.props;
    const { isValid } = this.state;

    return (
      <div>
        <FormControl component="fieldset"
                     fullWidth={true}
                     error={!isValid} disabled={disabled} size={size ? size : 'medium'}>
          <FormLabel component="legend">{this.isRequired() && label ? `${label} *` : label}</FormLabel>
          <MUIRadioGroup
            aria-label={label}
            value={value}
            onChange={this.onChange} {...rest}>
            {items.map((item:any, i:number) => {
              return <FormControlLabel key={i}
                                       value={itemValueProp ? item[itemValueProp] : item}
                                       control={<Radio size={size ? size : 'medium'} />}
                                       label={itemLabelProp ? item[itemLabelProp] : item.label} />
            })}
          </MUIRadioGroup>
          {this.renderError()}
        </FormControl>
      </div>
    );
  }
}

export default RadioGroup;
