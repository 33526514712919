import React, {ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  withStyles, InputAdornment, FormHelperText, WithStyles
} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import BaseForm from "../../../forms/BaseForm";
import {SubmitButton, ErrorList, TextField, MoneyInput, Select} from '../../../components';
import _ from 'lodash';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import IntlFormatter from "../../../intl";
import Api from "../../../lib/Api";
import {ErrorUtil} from "../../../utils";
import {ErrorState, LabelNumberType, LabelValuePairType, ReduxApp, ReduxLoanSettings} from "../../../types";
import {
  LoanApplicationPriceSearchRequest,
  LoanApplicationPriceSearchResult,
  LoanApplication2009,
  LoanApplication,
  LoanSettings
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {AxiosResponse} from "axios";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  data: Partial<LoanApplicationPriceSearchRequest>,
  onSubmit: (result:AxiosResponse<LoanApplicationPriceSearchResult[]>, form:Form) => void,
  onCancel?: () => void,
  app: ReduxApp,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

export type Form = {
  appraisalValue: string,
  salePrice: string,
  loanAmount: string,
  //downPayment: string,
  //downPaymentPercentage: string,
  representativeFICO: string,
  dtiRatio: string,
  monthsOfReserves: string,
  loanType: LoanApplicationPriceSearchRequest["loanType"],
  loanTerms: LoanApplicationPriceSearchRequest["loanTerms"],
  amortizationTypes: LoanApplicationPriceSearchRequest["amortizationTypes"],
  armFixedTerms: LoanApplicationPriceSearchRequest["armFixedTerms"],
  desiredLockPeriod: string,
  firstTimeHomeBuyer: boolean,
  county: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class PricingForm extends BaseForm<Props, State> {
  loanTypes:LabelValuePairType[] = [];
  loanTerms:LabelNumberType[] = [];
  amortizationTypes:LabelValuePairType[] = [];
  armFixedTerms:LabelNumberType[] = [];

  constructor(props:Props) {
    super(props);

    /*let form = {
      appraisalValue: 253000,
      salePrice: 340000,
      loanAmount: 250000,
      downPayment: 250000 * .2,
      downPaymentPercentage: 20,
      representativeFICO: 720,
      dtiRatio: 40,
      monthsOfReserves: 36,
      loanTypes: ['fha', 'conventional', 'va'],
      loanTerms: [360, 300, 240, 180, 120, 84, 60],
      amortizationTypes: ["fixed", "balloon", "arm"],
      armFixedTerms: [1, 3, 6, 12, 24, 36, 120],
      desiredLockPeriod: 30,
      firstTimeHomeBuyer: false
    };*/

    let form:Form = {
      appraisalValue: '',
      salePrice: '',
      loanAmount: '',
      //downPayment: '',
      //downPaymentPercentage: '',
      representativeFICO: '720',
      dtiRatio: '40',
      monthsOfReserves: '32',
      loanType: 'conventional',
      loanTerms: [360],
      amortizationTypes: ["fixed"],
      armFixedTerms: [],
      desiredLockPeriod: '30',
      firstTimeHomeBuyer: false,
      county: ''
    };

    //console.log(props.data);

    if(props.data) {
      form = _.merge(form, _.clone(props.data));
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    props.app.data.enums.loanTypes.forEach((type:LoanSettings["loanTypes"][0]) => {
      if(props.settings.data.loanTypes.includes(type)) {
        this.loanTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `loan_types_${type}`) })
      }
    });

    props.app.data.enums.amortizationTypes.forEach(type => {
      this.amortizationTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `amortization_types_${type}`) })
    });

    this.loanTerms = [
      { value: 30 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 30 })},
      { value: 25 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 25 })},
      { value: 20 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 20 })},
      { value: 15 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 15 })},
      { value: 10 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 10 })},
      { value: 7 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 7 })},
      { value: 5 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 5 })},
    ];

    this.armFixedTerms = [
      { value: 1, label: IntlFormatter.formatMessage(props.intl, 'num_mo', { num: 1 })},
      { value: 3, label: IntlFormatter.formatMessage(props.intl, 'num_mo', { num: 3 })},
      { value: 6, label: IntlFormatter.formatMessage(props.intl, 'num_mo', { num: 6 })},
      { value: 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 1 })},
      { value: 2 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 2 })},
      { value: 3 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 3 })},
      { value: 5 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 5 })},
      { value: 7 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 7 })},
      { value: 10 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 10 })},
      { value: 15 * 12, label: IntlFormatter.formatMessage(props.intl, 'num_yr', { num: 15 })},
    ];
  }

  onPricingDetailCheckboxChange = (event:ChangeEvent<HTMLInputElement>, key:string, value:string|number) => {
    const form = _.cloneDeep(this.state.form);
    if(Array.isArray(form[key as keyof Form])) {
      const index = (form[key as keyof Form] as any[]).indexOf(value);
      if(event.target.checked && index === -1) {
        (form[key as keyof Form] as any[]).push(value);
      } else if(!event.target.checked && index >= 0) {
        (form[key as keyof Form] as any[]).splice(index, 1);
      }

      this.setState( { form });
    }
  };

  isValid = () => {
    const { loanTerms, amortizationTypes } = this.state.form;
    return loanTerms.length > 0 && amortizationTypes.length > 0;
  };

  onSubmit = async () => {
    try {
      this.setState({ loading: true, errors: [] });
      const {amortizationTypes, appraisalValue, armFixedTerms, county, desiredLockPeriod, dtiRatio, firstTimeHomeBuyer, loanAmount, loanTerms, loanType, monthsOfReserves, representativeFICO, salePrice} = this.state.form;
      const requestData:LoanApplicationPriceSearchRequest = {
        amortizationTypes,
        appraisalValue: parseInt(appraisalValue),
        armFixedTerms,
        county,
        desiredLockPeriod: parseInt(desiredLockPeriod),
        dtiRatio: parseInt(dtiRatio),
        firstTimeHomeBuyer,
        loanAmount: parseInt(loanAmount),
        loanTerms,
        loanType,
        monthsOfReserves: parseInt(monthsOfReserves),
        representativeFICO: parseInt(representativeFICO),
        salePrice: parseInt(salePrice)
      };

      const result = await Api.searchLoanApplicationPrices(this.props.loanApplication._id, requestData);
      this.setState({ loading: false }, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result, _.cloneDeep(this.state.form));
        }
      });
    } catch(e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }

  };

  render() {

    const { intl, classes } = this.props;
    const { loading, form, errors } = this.state;

    return (

      <div>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <Form onSubmit={this.onSubmit}>
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'appraisal_value')}
                            value={form.appraisalValue}
                            fullWidth={true}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.appraisalValue')} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'sale_price')}
                            value={form.salePrice}
                            fullWidth={true}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.salePrice')} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'loan_amount')}
                            value={form.loanAmount}
                            fullWidth={true}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loanAmount')} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField name="desiredLockPeriod"
                           label={IntlFormatter.formatMessage(intl, 'desired_lock_period')}
                           value={form.desiredLockPeriod}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.desiredLockPeriod')}
                           type="number"
                           fullWidth={true}
                           InputProps={{
                             endAdornment: <InputAdornment position="end">days</InputAdornment>
                           }}
                           validators={['required', 'minNumber:0']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required'),
                             IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                           ]} />
              </Grid>
            </Grid>
          </div>
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField name="representativeFICO"
                           label={IntlFormatter.formatMessage(intl, 'representative_fico')}
                           value={form.representativeFICO}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.representativeFICO')}
                           type="number"
                           fullWidth={true}
                           validators={['required', 'minNumber:0', 'maxNumber:850']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required'),
                             IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                             IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 850 })
                           ]} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField name="dtiRatio"
                           label={IntlFormatter.formatMessage(intl, 'dti_ratio')}
                           value={form.dtiRatio}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.dtiRatio')}
                           type="number"
                           fullWidth={true}
                           validators={['required', 'minNumber:0']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required'),
                             IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                           ]} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField name="monthsOfReserves"
                           label={IntlFormatter.formatMessage(intl, 'months_of_reserves')}
                           value={form.monthsOfReserves}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.monthsOfReserves')}
                           type="number"
                           fullWidth={true}
                           validators={['required', 'minNumber:0']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required'),
                             IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                           ]} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField name="county"
                           label={IntlFormatter.formatMessage(intl, 'county')}
                           value={form.county}
                           fullWidth={true}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.county')}
                           validators={['required']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required')
                           ]} />
              </Grid>
            </Grid>
          </div>

          <div className={classes.mb2}>
            <Select name="loanType"
                    options={this.loanTypes}
                    placeholder={IntlFormatter.formatMessage(intl, 'select')}
                    onChange={(item:LabelValuePairType) => this.onItemChange(item ? item.value : '', 'form.loanType')}
                    value={this.loanTypes.find(m => m.value === form.loanType)}
                    validators={['required']}
                    errorMessages={[
                      IntlFormatter.formatMessage(intl, 'validation_required')
                    ]}
                    label={IntlFormatter.formatMessage(intl, 'loan_term')} />
          </div>

          <div className={classes.mb2}>
            <FormControl component="fieldset" error={form.loanTerms.length === 0}>
              <FormLabel component="legend">
                <FormattedMessage id="loan_term" />
              </FormLabel>
              <FormGroup row>
                {this.loanTerms.map((loanTerm:LabelNumberType, i:number) => {
                  return (
                    <FormControlLabel key={i}
                                      control={<Checkbox checked={form.loanTerms.includes(loanTerm.value)}
                                                         onChange={event => this.onPricingDetailCheckboxChange(event, 'loanTerms', loanTerm.value)}/>}
                                      label={loanTerm.label}/>
                  )
                })}
              </FormGroup>
              {form.loanTerms.length === 0 &&
              <FormHelperText>
                <FormattedMessage id="validation_at_lease_one_value_required" />
              </FormHelperText>
              }
            </FormControl>
          </div>

          <div className={classes.mb2}>
            <FormControl component="fieldset" error={form.amortizationTypes.length === 0}>
              <FormLabel component="legend">
                <FormattedMessage id="amortization_type" />
              </FormLabel>
              <FormGroup row>
                {this.amortizationTypes.map((type, i) => {
                  return (
                    <FormControlLabel key={i}
                                      control={<Checkbox checked={form.amortizationTypes.includes(type.value)}
                                                         onChange={event => this.onPricingDetailCheckboxChange(event, 'amortizationTypes',  type.value)}/>}
                                      label={type.label}/>
                  )
                })}
              </FormGroup>
              {form.amortizationTypes.length === 0 &&
                <FormHelperText>
                  <FormattedMessage id="validation_at_lease_one_value_required" />
                </FormHelperText>
              }
            </FormControl>
          </div>

          <div className={classes.mb2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <FormattedMessage id="arm_fixed_term" />
              </FormLabel>
              <FormGroup row>
                {this.armFixedTerms.map((term, i) => {
                  return (
                    <FormControlLabel key={i}
                                      control={<Checkbox checked={form.armFixedTerms && form.armFixedTerms.includes(term.value)}
                                                         onChange={event => this.onPricingDetailCheckboxChange(event, 'armFixedTerms',  term.value)}/>}
                                      label={term.label}/>
                  )
                })}
              </FormGroup>
            </FormControl>
          </div>

          <div className={classes.mb2}>
            <FormControlLabel control={<Checkbox checked={form.firstTimeHomeBuyer}
                                                 onChange={() => this.onCheckboxChanged('form.firstTimeHomeBuyer')}/>}
                              label={IntlFormatter.formatMessage(intl, 'first_time_home_buyer')}/>
          </div>

          <Grid container alignItems="center" justifyContent="flex-end">
            {this.props.onCancel &&
            <Grid item>
              <Button onClick={this.props.onCancel}>
                <FormattedMessage id="cancel" />
              </Button>
            </Grid>
            }
            <Grid item>
              <SubmitButton loading={loading} disabled={!this.isValid()}>
                <FormattedMessage id="search"/>
              </SubmitButton>
            </Grid>
          </Grid>

        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    settings: state.loanSettings
  };
};


export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(PricingForm)));
