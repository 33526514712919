import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {
  Grid,
  Icon,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  withStyles, Typography, WithStyles
} from "@material-ui/core";
import {DeleteDialog, ErrorList, PageTitle, PairedList, UserAvatar} from "../../../components";
import clsx from "clsx";
import {Redirect, Link, RouteComponentProps} from "react-router-dom";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import _ from 'lodash';
import {KeyValueMixed, User} from "@jerseydev/orca-loans";
import {ActionProps, ErrorState, ReduxLoanSettings, ReduxUser, SnackbarState} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";
import {ErrorUtil} from "../../../utils";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  user: ReduxUser,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps<{id:string}>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  user: User|null,
  selectedActionMenu: Element|null,
  deleteDialogOpen: boolean,
  errors: ErrorState[],
  errorStatusCode?: number,
  redirectTo?: string,
  snackbar?: SnackbarState
}

class UserDetailPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      user: null,
      selectedActionMenu: null,
      deleteDialogOpen: false,
      errors: []
    };
  }

  componentDidMount = () => {
    this.props.routeProps.getUser(this.props.match.params.id).send().then(result => {
      this.setState({ pageLoaded: true, user: _.cloneDeep(result.data) });
    }).catch(e => {
      this.setState({ pageLoaded: true, errorStatusCode: e.response ? e.response.status : e.status });
    });
  };

  getPageTitle = () => {
    const { user } = this.state;
    if(user) {
      return `${user.firstName} ${user.lastName}`;
    }

    return '';
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon={this.state.user && this.state.user.avatar ? <UserAvatar user={this.state.user} size="large" variant="square" /> : 'person'} />
    )
  };

  getDetailItems = () => {
    const { intl } = this.props;
    const { user } = this.state;

    let items:KeyValueMixed[] = [];
    if(user) {
      items = [
        { key: IntlFormatter.formatMessage(intl, 'email'), value: user.email },
        { key: IntlFormatter.formatMessage(intl, 'timezone'), value: user.timezone },
        { key: IntlFormatter.formatMessage(intl, 'mobile_phone'), value: user.mobilePhoneNumber ? user.mobilePhoneNumber : IntlFormatter.formatMessage(intl, 'not_provided') },
        { key: IntlFormatter.formatMessage(intl, 'roles'), value: _.map(user.roles, 'name').join(', ') }
      ];
    }

    return items;
  };

  onActionMenuClick = (event:MouseEvent) => {
    this.setState({ selectedActionMenu: event.currentTarget });
  };

  onActionMenuClose = () => {
    this.setState({ selectedActionMenu: null });
  };

  showDeleteDialog = () => {
    this.setState({ selectedActionMenu: null, deleteDialogOpen: true });
  };

  hideDeleteDialog = () => {
    this.setState({ deleteDialogOpen: false });
  };

  onDeleteSubmit = async () => {
    try {
      if(this.state.user) {
        this.setState({ deleteDialogOpen: false, loading: true });
        await this.props.routeProps.deleteUser(this.state.user._id).send();
        this.props.mixpanel.track("User archived");
      }
      this.setState({
        loading: false,
        redirectTo: '/admin/users',
        snackbar: {
          open: true,
          variant: 'success',
          message: IntlFormatter.formatMessage(this.props.intl, 'user_archived')
        },
      });
    } catch (e) {
      this.setState({loading:false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, classes, settings } = this.props;
    const { pageLoaded, user, selectedActionMenu, deleteDialogOpen, redirectTo, snackbar, errors, errorStatusCode } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo, state: { snackbar } }}  />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   pageLoaded={pageLoaded}
                   titleBar={this.renderTitleBar()}
                   errorStatusCode={errorStatusCode}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: IntlFormatter.formatMessage(intl, 'users'), to: '/admin/users' },
                     {title: this.getPageTitle() }
                   ]}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />
        {user &&
        <div>
          <DeleteDialog open={deleteDialogOpen}
                        title={IntlFormatter.formatMessage(intl, 'archive_user')}
                        item={`${user.firstName} ${user.lastName}`}
                        confirmationMessage={IntlFormatter.formatMessage(intl, 'archive_confirmation', { value: `${user.firstName} ${user.lastName}` })}
                        secondConfirmationMessage={IntlFormatter.formatMessage(intl, 'archive_second_confirmation', { value: `${user.firstName} ${user.lastName}` })}
                        deleteButtonLabel={IntlFormatter.formatMessage(intl, 'archive')}
                        onCancel={this.hideDeleteDialog}
                        onSubmit={this.onDeleteSubmit} />
          <div>
            <Menu anchorEl={selectedActionMenu}
                  open={Boolean(selectedActionMenu)}
                  onClose={this.onActionMenuClose}>
              <MenuItem component={Link} to={`/admin/users/edit/${user._id}`}>
                <ListItemIcon>
                  <Icon>mode_edit</Icon>
                </ListItemIcon>
                <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
              </MenuItem>
              <MenuItem onClick={this.showDeleteDialog}
                        disabled={this.props.user.data._id === user._id || settings.data.defaultLoanOfficer!.user._id === user._id}>
                <ListItemIcon>
                  <Icon>cancel</Icon>
                </ListItemIcon>
                <ListItemText primary={IntlFormatter.formatMessage(intl, 'archive')} />
              </MenuItem>
            </Menu>
          </div>
          <div style={{ overflow: 'hidden' }}>
            <div className={classes.ph2}>
              <Grid container alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <IconButton onClick={this.onActionMenuClick}>
                    <Icon>more_vert</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <Divider />
            <div className={classes.p2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8}>
                  <PairedList items={this.getDetailItems()} />
                  {user.description &&
                  <div className={clsx(classes.mt2, classes.p2)}>
                    <Typography variant="subtitle1">
                      <FormattedMessage id="bio" />
                    </Typography>
                    <Typography variant="body1">
                      {user.description}
                    </Typography>
                  </div>
                  }
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        }
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(UserDetailPage)));
