import React, {RefObject, MouseEvent, ChangeEvent} from 'react';
import {Form} from '../../../forms';

import {Grid, Button, withStyles, Icon, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {
  ErrorList,
  MoneyInput, PercentageTextField, Section,
  Select,
  SubmitButton,
  TextField
} from "../../../components/index";
import {CalculatorUtil, ErrorUtil} from '../../../utils';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import Api from '../../../lib/Api';
import {ChartComponentProps, Doughnut} from "react-chartjs-2";
import CalculatorResults, {CalculatorResultsProps} from "../components/CalculatorResults";
import {ErrorState, FixMeLater, LabelNumberType} from "../../../types";
import themePalette from "../../../theme/jss/palette";
import {
  LoanComparisonCalculatorLoan,
  LoanComparisonCalculatorRequest,
  LoanComparisonCalculatorResponse
} from "@jerseydev/orca-loans";
import {CalculatorResultSetProps} from "../components/CalculatorResultSet";
import {Mixpanel} from "mixpanel-browser";

const palette:FixMeLater = themePalette;

type Props = {
  mixpanel: Mixpanel,
  onSubmit?: (data:LoanComparisonCalculatorResponse) => void,
  onCancel?: () => void
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type FormLoan = {
  interestRate: string,
  term: string,
  points: string,
  originationFees: string,
  closingCosts: string
}

type Form = {
  loanAmount: string,
  loans: FormLoan[]
}

type State = {
  loading: boolean,
  form: Form,
  result?: CalculatorResultsProps|null,
  errors: ErrorState[],
}

class LoanComparisonForm extends BaseForm<Props, State> {
  terms:LabelNumberType[] = [];
  resultsRef:RefObject<HTMLDivElement>;

  chartColors:string[] = [
    palette.info.main,
    palette.danger.main,
    palette.warning.main,
    palette.success.main,
  ];

  chartOptions = CalculatorUtil.getPieChartOptions();

  constructor(props:Props) {
    super(props);

    this.resultsRef = React.createRef();

    /*const testFormData = {
      loanAmount: 250000,
      loans: [
        {
          interestRate: 5.5,
          term: 30,
          points: 1,
          originationFees: 0,
          closingCosts: 1200
        },
        {
          interestRate: 3.5,
          term: 15,
          points: 1.2,
          originationFees: .5,
          closingCosts: 700
        }
      ]
    };*/

    this.state = {
      loading: false,
      form: {
        loanAmount: '',
        loans: [
          {
            interestRate: '',
            term: '',
            points: '',
            originationFees: '',
            closingCosts: ''
          },
          {
            interestRate: '',
            term: '',
            points: '',
            originationFees: '',
            closingCosts: ''
          }
        ]
      },
      result: null,
      errors: [],
    };

    //this.state.form = testFormData;

    this.terms = CalculatorUtil.getSelectLoanTerms(props.intl)
  }

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    try {
      this.setState({ loading: true, result: null, errors: [] });
      const {loanAmount, loans} = this.state.form;
      const requestData:LoanComparisonCalculatorRequest = {
        loanAmount: parseInt(loanAmount),
        loans: []
      };
      loans.forEach(loan => {
        const {closingCosts, interestRate, originationFees, points, term} = loan;
        requestData.loans.push({
          interestRate: parseFloat(interestRate),
          term: parseInt(term),
          closingCosts: parseInt(closingCosts),
          originationFees: parseFloat(originationFees),
          points: parseFloat(points)
        });
      })
      const result = await Api.calculateLoanComparison(requestData);

      this.setState({ loading: false, result: this.formatResult(result.data) });

      this.resultsRef.current!.scrollIntoView({behavior:'smooth'});
      this.props.mixpanel.track("Mortgage Calculator", { name: "Loan Comparison", action: "submitted" });
      if(this.props.onSubmit) {
        this.props.onSubmit(result.data);
      }
    } catch(e) {
      this.onError(e);
    }
  };

  onError = (err:any) => {
    this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
  };

  formatResult = (result:LoanComparisonCalculatorResponse) => {
    const {intl} = this.props;

    let formattedResult:CalculatorResultsProps = {
      summary: result.description,
      results: []
    };

    result.loans.forEach((loan, i) => {
      formattedResult.results.push({
        title: IntlFormatter.formatMessage(intl, `loan${i + 1}`),
        items: [
          { color: this.chartColors[0], label: IntlFormatter.formatMessage(intl, 'monthly_principal_interest'), value: loan.monthlyPrincipalAndInterest.toMoney() },
          { color: this.chartColors[1], label: IntlFormatter.formatMessage(intl, 'points'), value: loan.points.toMoney() },
          { color: this.chartColors[2], label: IntlFormatter.formatMessage(intl, 'origination_fees'), value: loan.originationFees.toMoney() },
          { color: this.chartColors[3], label: IntlFormatter.formatMessage(intl, 'closing_costs'), value: loan.closingCosts.toMoney() }
        ],
        amortization: loan.amortization,
        chart: <Doughnut data={this.getChartData(loan)} options={this.chartOptions} redraw />
      })
    });

    return formattedResult;
  };

  getChartData = (resultData:LoanComparisonCalculatorLoan):ChartComponentProps["data"] => {
    const { intl } = this.props;

    return {
      datasets: [{
        data: [
          resultData.monthlyPrincipalAndInterest.toFixed(2),
          resultData.points.toFixed(2),
          resultData.originationFees.toFixed(2),
          resultData.closingCosts.toFixed(2),
        ],
        backgroundColor: this.chartColors
      }],
      labels: [
        IntlFormatter.formatMessage(intl, 'monthly_principal_interest'),
        IntlFormatter.formatMessage(intl, 'points'),
        IntlFormatter.formatMessage(intl, 'origination_fees'),
        IntlFormatter.formatMessage(intl, 'closing_costs'),
      ]
    };
  };

  getResultItems = (result:LoanComparisonCalculatorResponse) => {
    const {intl} = this.props;

    const items:CalculatorResultSetProps["items"] = [
      { label: IntlFormatter.formatMessage(intl, result.totalSavings >= 0 ? 'total_savings' : 'total_losses'), value: result.totalSavings.toMoney(), size: 'large' },
    ];

    return items;
  };

  render() {

    const { intl, classes, onCancel } = this.props;
    const { form, loading, errors, result } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] })}} />
          <div className={classes.mb3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'loan_amount')}
                            value={form.loanAmount}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loanAmount')}
                            validators={['required', 'minNumber:1']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 1 })
                            ]} />
              </Grid>
            </Grid>
          </div>

          {form.loans.map((loan, i) => {
            return (
              <Section key={i}
                       title={IntlFormatter.formatMessage(intl, `loan${i + 1}`)}
                       className={classes.mb3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={2}>
                    <Select name="term"
                            options={this.terms}
                            placeholder={IntlFormatter.formatMessage(intl, 'select_term')}
                            onChange={(item:LabelNumberType) => this.onItemChange(item ? item.value : '', `form.loans[${i}].term`)}
                            value={form.loans[i].term !== '' ? this.terms.find(m => m.value === parseInt(form.loans[i].term)) : ''}
                            validators={['required']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required')
                            ]}
                            label={IntlFormatter.formatMessage(intl, 'term')} />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <PercentageTextField name="interestRate"
                                         label={IntlFormatter.formatMessage(intl, 'interest_rate')}
                                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.loans[${i}].interestRate`)}
                                         value={form.loans[i].interestRate}
                                         fullWidth={true}
                                         validators={['required', 'minFloat:0', 'maxFloat:100']}
                                         errorMessages={[
                                           IntlFormatter.formatMessage(intl, 'validation_required'),
                                           IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                           IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 }),
                                         ]} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField name="points"
                               label={IntlFormatter.formatMessage(intl, 'points')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.loans[${i}].points`)}
                               value={form.loans[i].points}
                               type="number"
                               fullWidth={true}
                               validators={['required', 'minFloat:0', 'maxFloat:100']}
                               errorMessages={[
                                 IntlFormatter.formatMessage(intl, 'validation_required'),
                                 IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                 IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                               ]}/>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <PercentageTextField name="originationFees"
                                         label={IntlFormatter.formatMessage(intl, 'origination_fees')}
                                         value={form.loans[i].originationFees}
                                         fullWidth={true}
                                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.loans[${i}].originationFees`)}
                                         validators={['required', 'minFloat:0', 'maxFloat:100']}
                                         errorMessages={[
                                           IntlFormatter.formatMessage(intl, 'validation_required'),
                                           IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                           IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                         ]} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <MoneyInput label={IntlFormatter.formatMessage(intl, 'closing_costs')}
                                value={form.loans[i].closingCosts}
                                fullWidth={true}
                                onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.loans[${i}].closingCosts`)}
                                validators={['required', 'minNumber:0']}
                                errorMessages={[
                                  IntlFormatter.formatMessage(intl, 'validation_required'),
                                  IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                                ]} />
                  </Grid>
                </Grid>
              </Section>
            )
          })}


          <div className={classes.mt3}>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              {onCancel &&
              <Grid item>
                <Button onClick={onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}
                              size="large"
                              startIcon={<Icon>calculate</Icon>}>
                  <FormattedMessage id={result ? 'recalculate' : 'calculate'} />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>

        <div ref={this.resultsRef}>
          {result &&
            <div className={classes.mt3}>
              <CalculatorResults summary={result.summary}
                                 results={result.results} />

            </div>
          }
        </div>
      </div>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanComparisonForm));
