import React, {ChangeEvent, MouseEvent} from 'react';
import {
  Grid,
  withStyles, Typography, Link, Button, Icon, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import IntlFormatter from '../intl';
import BaseForm from "./BaseForm";
import {
  ErrorList,
  Select,
  TextField,
  InlineCard, GooglePlacesAutoCompleteValidator,
} from '../components';
import _ from 'lodash';
import {states} from '../data/data';
import pageStyles from "../theme/jss/layouts/pageStyles";
import {LocationUtil} from "../utils";
import {Location} from "@jerseydev/orca-loans";
import {FormattedPlacesResult, ErrorState, USState} from "../types";

type DefaultProps = {
  required: boolean
}

type Props = {
  address?: Location|null,
  onChange?: (address:Location) => void
} & Partial<DefaultProps>
  & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  address: Location,
  manualAddressEntry: boolean,
  errors: ErrorState[],
  hasAddress: boolean
}

class AddressFormFields extends BaseForm<Props, State> {
  static defaultProps:DefaultProps = {
    required: false
  };

  defaultAddress:Location = {
    street1: '',
    city: '',
    province: '',
    postalCode: '',
    county: ''
  };

  constructor(props:Props) {
    super(props);

    this.state = {
      address: props.address ? _.cloneDeep(props.address) : _.clone(this.defaultAddress),
      manualAddressEntry: false,
      hasAddress: false,
      errors: []
    };
  }

  hasAddress = () => {
    const { address } = this.state;
    let hasAddress = false;
    for(let key in address) {
      if(address.hasOwnProperty(key)) {
        const prop = address[key as keyof Location];
        if(key !== '_id' && key !== 'country' && prop && ((typeof prop === 'string' && prop!.trim().length > 0) || typeof prop === 'number')) {
          hasAddress = true;
          break;
        }
      }
    }

    return hasAddress;
  };

  setAddressState = (state:USState) => {
    const address = _.cloneDeep(this.state.address);
    address.province = state.name;
    this.setState({ address }, () => {
      if(this.props.onChange) {
        this.props.onChange(_.clone(address));
      }
    });
  };

  onAddressChange = (selectedAddress:FormattedPlacesResult) => {
    let address:Location = {};

    if(selectedAddress) {
      address = _.cloneDeep(this.state.address);
      address.street1 = null;
      if(selectedAddress.streetName) {
        if(selectedAddress.streetNumber) {
          address.street1 = `${selectedAddress.streetNumber} ${selectedAddress.streetName}`;
        } else {
          address.street1 = selectedAddress.streetName;
        }
      }

      address.city = null;
      if(selectedAddress.city) {
        address.city = selectedAddress.city;
      }

      address.province = null;
      if(selectedAddress.province) {
        address.province = selectedAddress.province;
      }

      address.postalCode = null;
      if(selectedAddress.postalCode) {
        address.postalCode = selectedAddress.postalCode;
      }

      address.county = null;
      if(selectedAddress.county) {
        address.county = selectedAddress.county;
      }
    }

    this.setState({ address, hasAddress: true }, () => {
      if(this.props.onChange) {
        this.props.onChange(_.clone(this.state.address));
      }
    });
  };

  onShowManualEntry = (e:MouseEvent) => {
    e.preventDefault();
    this.setState({
      manualAddressEntry: true,
      address: _.clone(this.defaultAddress)
    });
  };

  onHideManualEntry = (e:MouseEvent) => {
    e.preventDefault();
    this.setState({
      manualAddressEntry: false,
      address: _.clone(this.defaultAddress)
    });
  };

  onTextChange = (event:ChangeEvent<{ value: string }>, key:string) => {
    const state:any = _.cloneDeep(this.state);
    if(key.includes('.')) {
      _.set(state, key, event.target.value);
    } else {
      state[key] = event.target.value;
    }

    this.setState(state, () => {
      if(this.props.onChange) {
        this.props.onChange(_.clone(this.state.address));
      }
    });
  };

  clearAddress = () => {
    const address = _.clone(this.defaultAddress);
    this.setState({ address }, () => {
      if(this.props.onChange) {
        this.props.onChange(_.clone(address));
      }
    });
  };

  render() {

    const { intl, classes, required } = this.props;
    const { address, errors, manualAddressEntry } = this.state;

    return (
      <div>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />


        {!manualAddressEntry &&
          <div>
            {(address && address.province) &&
            <InlineCard title={IntlFormatter.formatMessage(intl, 'address')} icon={<Icon>location_on</Icon>} action={
              <Button variant="outlined" color="primary" onClick={this.clearAddress} size="small">
                <FormattedMessage id="clear" />
              </Button>
            }>
              <Typography variant="body1">
                {LocationUtil.formatLocation(address)}
              </Typography>
            </InlineCard>
            }
            {(!address || (address && !address.province)) &&
              <div>
                <GooglePlacesAutoCompleteValidator name="addressAutocomplete"
                                                   placeholder={IntlFormatter.formatMessage(intl, 'search')}
                                                   value={address}
                                                   label={IntlFormatter.formatMessage(intl, 'address')}
                                                   onChange={this.onAddressChange}
                                                   validators={required ?
                                                      ['required', 'isCompleteAddress']
                                                        :
                                                      []
                                                   }
                                                   errorMessages={required ?
                                                      [
                                                        IntlFormatter.formatMessage(intl, 'validation_required'),
                                                        IntlFormatter.formatMessage(intl, 'validation_address_invalid_address'),
                                                      ]
                                                      :
                                                      []}/>
                <Typography variant="caption">
                  <Link href="#" onClick={this.onShowManualEntry}>
                    <FormattedMessage id="enter_address_manually" />
                  </Link>
                </Typography>
              </div>
            }
          </div>
        }
        {manualAddressEntry &&
          <div>
            <div className={classes.mb2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9} md={9}>
                  <TextField name="street1"
                             label={IntlFormatter.formatMessage(intl, 'street')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'address.street1')}
                             value={address.street1}
                             fullWidth={true}
                             validators={['required']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField name="unit"
                             label={IntlFormatter.formatMessage(intl, 'unit')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'address.unit')}
                             value={address.unit}
                             fullWidth={true}/>
                </Grid>
              </Grid>
            </div>


            <div className={classes.mb2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField name="city"
                             label={IntlFormatter.formatMessage(intl, 'city')}
                             value={address.city}
                             fullWidth={true}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `address.city`)}
                             validators={['required']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Select name="state"
                          options={states}
                          onChange={this.setAddressState}
                          getOptionLabel={(item:USState) => item.name}
                          value={address.province ? states.find(s => s.name === address.province) : ''}
                          label={IntlFormatter.formatMessage(intl, 'state')}
                          placeholder={IntlFormatter.formatMessage(intl, 'select_state')}
                          validators={['required']}
                          errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField name="postalCode"
                             label={IntlFormatter.formatMessage(intl, 'postal_code')}
                             value={address.postalCode}
                             fullWidth={true}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `address.postalCode`)}
                             validators={['required', 'minStringLength:5', 'maxStringLength:5']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required'),
                               IntlFormatter.formatMessage(intl, 'validation_minlength', {length: 5}),
                               IntlFormatter.formatMessage(intl, 'validation_maxlength', {length: 5})
                             ]}/>
                </Grid>
              </Grid>
              <Typography variant="caption">
                <Link href="#" onClick={this.onHideManualEntry}>
                  <FormattedMessage id="search_for_address" />
                </Link>
              </Typography>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(AddressFormFields)));
