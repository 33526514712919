import React, {MouseEvent} from 'react';
import {
  Icon,
  withStyles,
  ListItem,
  Grid,
  Typography,
  List,
  IconButton,
  MenuItem,
  ListItemIcon, ListItemText, Menu, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import pageStyles from "../theme/jss/layouts/pageStyles";
import {connect} from "react-redux";
import {Badge, DeleteDialog, ErrorList, Loader} from "./index";
import IntlFormatter from "../intl";
import _ from 'lodash';
import {refreshLoanApplicationAssetReport, deleteLoanApplicationAssetReport, getLoanApplication} from "../actions/loanApplication";
import {AclUtil, ErrorUtil} from "../utils";
import {ActionResponse, DialogState, ReduxUser, ErrorState} from "../types";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {
  LoanApplicationAssetReport,
  LoanApplication2009,
  Borrower2009,
  LoanApplication,
  Borrower
} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  borrower: Borrower2009|Borrower,
  onRefreshClick?: (event:MouseEvent) => void,
  user: ReduxUser,
  getLoanApplication: (id:string) => ActionResponse,
  refreshLoanApplicationAssetReport: (loanApplicationId:string, borrowerId:string, reportId:string) => ActionResponse,
  deleteLoanApplicationAssetReport: (loanApplicationId:string, borrowerId:string, reportId:string) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

const BorrowerAssetReportList = (props:Props) => {

  const { intl, classes, loanApplication, borrower, user } = props;
  const [actionMenuEl, setActionMenuEl] = React.useState<Element|null>(null);
  const [selectedItem, setSelectedItem] = React.useState<LoanApplicationAssetReport|null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [deleteDialog, setDeleteDialog] = React.useState<DialogState>({
    open: false,
    loading: false,
    errors: []
  });

  const [errors, setErrors] = React.useState<ErrorState[]>([]);

  const statusMap:any = {
    queued: 'info',
    failed: 'danger',
    refreshing: 'info',
    completed: 'success'
  }

  if((!borrower.assetReports || (borrower.assetReports && borrower.assetReports.length === 0))) {
    return null;
  }

  const onActionMenuClose = () => {
    setActionMenuEl(null);
  };

  const onRefreshClick = async (event:MouseEvent) => {
    setActionMenuEl(null);
    setLoading(true);
    try {
      if(selectedItem) {
        await props.refreshLoanApplicationAssetReport(loanApplication._id, borrower._id, selectedItem._id).send();
      }
      setLoading(false);
      if(props.onRefreshClick) {
        props.onRefreshClick(event);
      }
    } catch (e) {
      setLoading(false);
      setErrors(ErrorUtil.formatErrors(e));
    }
  };

  const onDeleteClick = () => {
    const data = _.cloneDeep(deleteDialog);
    data.open = true;
    setDeleteDialog(data);
    setActionMenuEl(null);
  };

  const onActionMenuClick = (event:MouseEvent<HTMLButtonElement>, selectedItem:LoanApplicationAssetReport) => {
    setActionMenuEl(event.currentTarget);
    setSelectedItem(selectedItem);
  };

  const onDeleteDialogConfirm = async () => {
    try {
      setLoading(true);
      setDeleteDialog({
        open: true,
        loading: true,
        errors: []
      })
      if(selectedItem) {
        await props.deleteLoanApplicationAssetReport(loanApplication._id, borrower._id, selectedItem._id).send();
      }
      await props.getLoanApplication(loanApplication._id); // reload loan because assets/liabilities will delete
      setSelectedItem(null);
      setLoading(false);
      onDeleteDialogClose();
    } catch (e) {
      setDeleteDialog({
        open: true,
        loading: false,
        errors: ErrorUtil.formatErrors(e)
      });
      setLoading(false);
    }
  };

  const onDeleteDialogClose = () => {
    setDeleteDialog({
      open: false,
      loading: false,
      errors: []
    })
  };

  return (
    <div>
      <Loader visible={loading} />

      <DeleteDialog open={deleteDialog.open}
                    title={IntlFormatter.formatMessage(intl, 'delete_account')}
                    item={selectedItem ? selectedItem.institution : ''}
                    errors={deleteDialog.errors}
                    loading={deleteDialog.loading}
                    onCancel={onDeleteDialogClose}
                    onSubmit={onDeleteDialogConfirm} />

      <Menu anchorEl={actionMenuEl}
            open={Boolean(actionMenuEl)}
            onClose={onActionMenuClose}>
        {(!AclUtil.hasRole(user.data,'ROLE_MEMBER') && selectedItem && selectedItem.status === 'completed') &&
          <MenuItem onClick={onRefreshClick}>
            <ListItemIcon>
              <Icon>refresh</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'update')} />
          </MenuItem>
        }
        <MenuItem onClick={onDeleteClick}>
          <ListItemIcon>
            <Icon>cancel</Icon>
          </ListItemIcon>
          <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')} />
        </MenuItem>
      </Menu>

      <ErrorList errors={errors} className={classes.mb2} />

      <List>
        {borrower.assetReports.map((assetReport:LoanApplicationAssetReport, i:number) => {
          return (
            <ListItem key={i}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <div className={classes.rowCenter}>
                    <div className={classes.mr1}>
                      <Typography variant="body1">
                        {assetReport.institution}
                      </Typography>
                    </div>
                    <Badge color={statusMap[assetReport.status]}>
                      <FormattedMessage id={assetReport.status} />
                    </Badge>
                  </div>
                </Grid>
                <Grid item>
                  <IconButton onClick={(event) => onActionMenuClick(event, assetReport)}>
                    <Icon>more_vert</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getLoanApplication(id:string,) {
    return dispatch(getLoanApplication(id));
  },
  refreshLoanApplicationAssetReport(loanApplicationId:string, borrowerId:string, reportId:string) {
    return dispatch(refreshLoanApplicationAssetReport(loanApplicationId, borrowerId, reportId));
  },
  deleteLoanApplicationAssetReport(loanApplicationId:string, borrowerId:string, reportId:string) {
    return dispatch(deleteLoanApplicationAssetReport(loanApplicationId, borrowerId, reportId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(BorrowerAssetReportList)));