import {defaultRadius, hexToRgb} from "../index";
import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const styles = (theme:Theme) => createStyles({
  rounded: {
    borderRadius: defaultRadius
  },
  flat: {
    boxShadow: 'none'
  },
  default: {
    backgroundColor: theme.palette.neutral.main,
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    '&:hover' : {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.info.contrastText,
    }
  },
  infoAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.info.main) + ", .15)",
    color: theme.palette.info.main,
    '&:hover' : {
      backgroundColor: "rgba(" + hexToRgb(theme.palette.info.main) + ", .25)"
    }
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover' : {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  primaryAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ", .15)",
    color: theme.palette.primary.main,
    '&:hover' : {
      backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ", .25)"
    }
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover' : {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,
    }
  },
  secondaryAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.secondary.main) + ", .15)",
    color: theme.palette.secondary.main,
    '&:hover' : {
      backgroundColor: "rgba(" + hexToRgb(theme.palette.secondary.main) + ", .25)"
    }
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover' : {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.success.contrastText,
    }
  },
  successAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.success.main) + ", .15)",
    color: theme.palette.success.main,
    '&:hover' : {
      backgroundColor: "rgba(" + hexToRgb(theme.palette.success.main) + ", .25)"
    }
  },
  warn: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover' : {
      backgroundColor: theme.palette.warning.dark,
      color: theme.palette.warning.contrastText,
    }
  },
  warnAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.warning.main) + ", .15)",
    color: theme.palette.warning.main,
    '&:hover' : {
      backgroundColor: "rgba(" + hexToRgb(theme.palette.warning.main) + ", .25)"
    }
  },
  danger: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrastText,
    '&:hover' : {
      backgroundColor: theme.palette.danger.dark,
      color: theme.palette.danger.contrastText,
    }
  },
  dangerAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.danger.main) + ", .15)",
    color: theme.palette.danger.main,
    '&:hover' : {
      backgroundColor: "rgba(" + hexToRgb(theme.palette.danger.main) + ", .25)"
    }
  },
});

const useStyles = makeStyles(styles);
export default styles;
export {useStyles}
