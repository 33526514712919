import React, {ChangeEvent} from 'react';
import {InputAdornment, TextField, WithStyles, withStyles} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import {TextValidator, ValidatorComponent} from "react-material-ui-form-validator";
import styles from "../theme/jss/components/moneyInputStyles";
import {FixMeLater} from "../types";

type NumberFormatProps = {
  inputRef: any,
  onChange: (event:FixMeLater) => void,
  allowNegative?: boolean,
  allowLeadingZeros?: boolean,
  decimalScale?: number
} & WithStyles<typeof styles>

const NumberFormatCustom = (props:Readonly<NumberFormatProps>) => {
  const { inputRef, onChange, decimalScale, allowNegative, ...other } = props;
  return (
    <NumberFormat
      {...other}
      decimalScale={typeof decimalScale === "number" ? decimalScale : 2}
      allowNegative={typeof allowNegative === "boolean" ? allowNegative : false}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix=""
    />
  );
};

type DefaultProps = {
  variant: 'filled' | 'outlined' | 'standard',
}

type Props = {
  onChange: (event:ChangeEvent<{value:string}>) => void
} & Partial<DefaultProps>

class MoneyInput extends ValidatorComponent<Props, {}> {
  onChange = (event:ChangeEvent<{value:FixMeLater}>) => {
    event.target.value = parseFloat(event.target.value);
    this.props.onChange(event);
  };

  isRequired = () => {
    let required = false;
    if(this.props.validators && this.props.validators.indexOf('required') > -1) {
      required = true;
    }

    return required;
  };

  renderValidatorComponent() {
    const { classes, label, validators, errorMessages, validatorListener, variant, allowNegative, allowLeadingZeros, decimalScale, ...rest } = this.props;
    
    if(validators && validators.length > 0) {
      return <TextValidator label={this.isRequired() && label ? `${label} *` : label}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              inputProps: {allowNegative, allowLeadingZeros, decimalScale},
                              startAdornment:
                                <InputAdornment position="start">$</InputAdornment>
                            }}
                            variant={variant ? variant : 'outlined'}
                            // @ts-ignore
                            onChange={this.onChange}
                            validators={validators}
                            errorMessages={errorMessages}
                            {...rest} />
    }

    return (
      <TextField label={label}
                 // @ts-ignore
                 onChange={this.onChange}
                 variant={variant ? variant : 'outlined'}
                 InputProps={{
                   inputComponent: NumberFormatCustom,
                   inputProps: {allowNegative, allowLeadingZeros, decimalScale},
                   startAdornment:
                     <InputAdornment position="start">$</InputAdornment>
                 }}
                 {...rest} />
    );
  }
}

export default withStyles(styles, { withTheme: true })(MoneyInput);
