import React, {ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {Grid, WithStyles, withStyles} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import BaseForm from "../../../forms/BaseForm";
import {SubmitButton, MoneyInput, Select} from '../../../components';
import _ from 'lodash';
import IntlFormatter from "../../../intl";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {connect} from "react-redux";
import {ActionProps, ErrorState, FixMeLater, LabelValuePairType, ReduxApp} from "../../../types";
import {
  LoanApplication,
  BorrowerOtherLiability,
  LoanApplicationRequest,
  Borrower,
  LoanApplicationUpdateRequest,
  BorrowerOtherLiabilityUpdateRequest
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {ErrorUtil} from "../../../utils";

type Props = {
  loanApplication: LoanApplication,
  borrower: Borrower,
  otherLiability?: BorrowerOtherLiability|null,
  actions?: React.ReactNodeArray,
  onSubmit: (data:FixMeLater) => void,
  app: ReduxApp,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  type: string,
  monthlyPayment: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class OtherLiabilityForm extends BaseForm<Props, State> {
  liabilityTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      type: '',
      monthlyPayment: ''
    };

    if(props.otherLiability) {
      const {monthlyPayment, type} = props.otherLiability;
      form = {
        type: type ? type : '',
        monthlyPayment: monthlyPayment ? monthlyPayment.toString() : ''
      };
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    props.app.data.enums.otherLiabilityTypes.forEach(type => {
      this.liabilityTypes.push({ 
        value: type, 
        label: IntlFormatter.formatMessage(props.intl, `other_liability_type_${type}`)
      })
    });
  }

  setLiabilityType = (type:LabelValuePairType) => {
    const form = _.clone(this.state.form);
    form.type = type ? type.value : null;
    this.setState({ form });
  };

  onSubmit = async () => {
    try {
      const {loanApplication, borrower, otherLiability} = this.props;
      this.setState({loading:true, errors: []});
      const {type, monthlyPayment} = this.state.form;

      const liabilityRequestData:BorrowerOtherLiabilityUpdateRequest = {
        _id: otherLiability ? otherLiability._id : undefined,
        type: type as BorrowerOtherLiabilityUpdateRequest["type"],
        monthlyPayment: monthlyPayment ? parseInt(monthlyPayment) : null,
      };

      const requestData:LoanApplicationUpdateRequest = {
        borrowers: [{
          _id: borrower._id,
          otherLiabilities: [liabilityRequestData]
        }]
      };

      const result = await this.props.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      this.setState({loading:false}, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, actions, classes } = this.props;
    const { loading, form } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Select name="type"
                      options={this.liabilityTypes}
                      onChange={this.setLiabilityType}
                      value={form.type ? this.liabilityTypes.find(t => t.value === form.type) : ''}
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required'),
                      ]}
                      label={IntlFormatter.formatMessage(intl, 'type')}
                      placeholder={IntlFormatter.formatMessage(intl, 'select')} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_payment')}
                          value={form.monthlyPayment}
                          fullWidth={true}
                          decimalScale={0}
                          validators={['required', 'minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.monthlyPayment`)} />
            </Grid>
          </Grid>
        </div>
        <Grid container alignItems="center" justifyContent="flex-end" >
          {actions && actions.length > 0 &&
          <Grid item>
            <div className={classes.mr1}>
              {actions.map((action,i) => {
                return <span key={i}>{action}</span>
              })}
            </div>
          </Grid>
          }
          <Grid item>
            <SubmitButton loading={loading}>
              <FormattedMessage id="submit" />
            </SubmitButton>
          </Grid>
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(OtherLiabilityForm)));
