import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  withStyles,
  Icon,
  IconButton,
  Grid, WithStyles
} from "@material-ui/core";
import {ErrorList, PageTitle, Snackbar} from "../../../components";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {LoanSettingsForm} from "../forms";
import {ErrorUtil, ReduxUtil} from "../../../utils";
import {DocumentCategoryList, HiddenMilestoneList} from "../components";
import {ActionProps, ErrorState, ReduxLoanSettings, SnackbarState} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoaded: boolean,
  snackbar: SnackbarState|null,
  errors: ErrorState[]
}

class LoanSettingsPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      snackbar: null,
      errors: []
    };
  }

  componentDidMount = () => {
    this.props.routeProps.getLoanSettings().send().then(() => {
      this.setState({ pageLoaded: true });
    }).catch(err => {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(err) });
    });
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'loan_settings');
  };

  renderTitleBar = () => {
    return <PageTitle title={this.getPageTitle()}
                      subtitle={IntlFormatter.formatMessage(this.props.intl, 'loan_settings_text')}
                      icon="settings" />
  };

  onSubmit = () => {
    this.setState({
      snackbar: {
        open: true,
        variant: 'success',
        message: IntlFormatter.formatMessage(this.props.intl, 'loan_settings_saved')
      }
    });
  };

  hideSnackbar = () => {
    this.setState({ snackbar: null });
  };

  render() {

    const { intl, classes, settings, mixpanel } = this.props;
    const { pageLoaded, snackbar, errors } = this.state;

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   pageLoaded={pageLoaded}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: IntlFormatter.formatMessage(intl, 'settings'), to: '/admin/settings' },
                     {title: this.getPageTitle() }
                   ]}>

        {snackbar &&
        <Snackbar open={!!snackbar}
                  variant={snackbar.variant || 'success'}
                  onClose={this.hideSnackbar}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.hideSnackbar}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        }

        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <div className={classes.content}>
          {ReduxUtil.hasData(settings) &&
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={8}>
                  <LoanSettingsForm settings={settings.data}
                                    mixpanel={mixpanel}
                                    onSubmit={this.onSubmit}/>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <div className={classes.mb4}>
                    <DocumentCategoryList mixpanel={mixpanel}/>
                  </div>

                  <HiddenMilestoneList mixpanel={mixpanel}/>
                </Grid>
              </Grid>
            </div>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoanSettingsPage)));
