import React, {useCallback} from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Button,
  Link, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import pageStyles from "../../theme/jss/layouts/pageStyles";
import {BorrowerAssetReportSection, ErrorList, Loader, SubTitle, Well, Alert} from "../index";
import IntlFormatter from "../../intl";
import {ErrorUtil, LoanUtil, SocketUtil, TextUtil} from "../../utils";
import {Link as RouterLink} from "react-router-dom";
import Item from "../Item";
import {getLoanApplication} from "../../actions/loanApplication";
import {connect} from "react-redux";
import {LoanApplication2009, Borrower2009} from "@jerseydev/orca-loans";
import {AnyAction} from "redux";
import {ActionResponse, ErrorState} from "../../types";
import {ThunkDispatch} from "redux-thunk";

type Props = {
  loanApplication: LoanApplication2009,
  getLoanApplication: (id:string) => ActionResponse
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

const LoanAssetsLiabilitiesSummary = (props:Props) => {

  const {intl, classes, loanApplication} = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [updateAvailable, setUpdateAvailable] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ErrorState[]>([]);

  const onLoanUpdate = useCallback((data) => {
    const assetReportsChanged = LoanUtil.assetReportsCompleted(loanApplication, data);
    setUpdateAvailable(assetReportsChanged);
  }, [loanApplication]);

  React.useEffect(() => {
    SocketUtil.socket.on('loanApplication.save', onLoanUpdate);

    return () => {
      SocketUtil.socket.off('loanApplication.save', onLoanUpdate);
    };
  }, [onLoanUpdate]);

  const renderBorrowerSubtitle = (borrower:Borrower2009, action?:React.ReactNode) => {
    if(loanApplication.borrowers && loanApplication.borrowers.length > 1) {
      return (
        <div className={classes.mb2}>
          <SubTitle title={`${borrower.firstName} ${borrower.lastName}`} action={action} />
        </div>
      )
    }
  };

  const getMoneyText = (value:number|null|undefined) => {
    return value !== null && value !== undefined ? value.toMoney() : IntlFormatter.formatMessage(intl, 'na');
  };

  const onRefreshLoan = async () => {
    try {
      setLoading(true);
      await props.getLoanApplication(loanApplication._id).send();
      setLoading(false);
      setUpdateAvailable(false);
    } catch (e) {
      setLoading(false);
      setErrors(ErrorUtil.formatErrors(e))
    }
  };

  if(!loanApplication.borrowers || loanApplication.borrowers.length === 0) {
    return (
      <Well color="warn">
        <Typography variant="body1">
          <FormattedMessage id="no_assets_or_liabilities_found" />
        </Typography>
      </Well>
    );
  }

  return (
    <div>
      <Loader visible={loading} />
      <ErrorList errors={errors} className={classes.mb2} />
      {updateAvailable &&
      <div className={classes.mb2}>
        <Alert severity="info" action={
          <Button variant="outlined" color="inherit" onClick={onRefreshLoan}>
            <FormattedMessage id="refresh" />
          </Button>
        }>
          <FormattedMessage id="account_report_available_text" />
        </Alert>
      </div>
      }

      {(loanApplication.jointAssetsAndLiabilities && loanApplication.borrowers && loanApplication.borrowers.length > 0) &&
      <div className={classes.mb2}>
        <Typography variant="subtitle1">
          {loanApplication.borrowers[0].firstName} {loanApplication.borrowers[0].lastName}
          {loanApplication.borrowers.length > 1 &&
            <span>&amp; {loanApplication.borrowers[1].firstName} {loanApplication.borrowers[1].lastName}</span>
          }
        </Typography>
      </div>
      }
      <Grid container spacing={2}>
        {loanApplication.borrowers.map((borrower, borrowerIndex) => {
          if(borrowerIndex === 1 && loanApplication.jointAssetsAndLiabilities) {
            return null;
          }

          const smMdSize = loanApplication.borrowers && loanApplication.borrowers.length > 1 ? 12 / loanApplication.borrowers.length : 6;
          return (
            //@ts-ignore
            <Grid key={borrowerIndex}
                  item
                  xs={12}
                  sm={smMdSize}
                  md={smMdSize}>

              {!loanApplication.jointAssetsAndLiabilities &&
                renderBorrowerSubtitle(borrower)
              }

              {(borrower.assetReports && borrower.assetReports.length > 0) &&
              <div className={classes.mb2}>
                <BorrowerAssetReportSection loanApplication={loanApplication}
                                            borrower={borrower} />
              </div>
              }

              {(!borrower.accountAssets || borrower.accountAssets.length === 0) &&
              <div className={classes.mb2}>
                <Well color="warn">
                  <Typography variant="body1">
                    <FormattedMessage id="no_assets_found" />.&nbsp;
                    {!loanApplication.completed &&
                    <Link component={RouterLink} to="/apply/assets-and-liabilities">
                      <FormattedMessage id="add_assets"/>
                    </Link>
                    }
                  </Typography>
                </Well>
              </div>
              }

              {borrower.accountAssets &&
              <div>
                {borrower.accountAssets.map((asset, i) => {
                  return (
                    <div key={i} className={classes.pv1}>
                      <Item label={`${asset.financialInstitution} ${IntlFormatter.formatMessage(intl, asset.type ? `account_types_${asset.type}`: 'na')} ${asset.accountNumber ? TextUtil.maskText(asset.accountNumber) : ''}`}
                            icon="account_balance">
                        <Typography variant="body1">
                          {getMoneyText(asset.value)}
                        </Typography>
                      </Item>
                    </div>
                  )
                })}
              </div>
              }
            </Grid>
          )
        })}
      </Grid>

      <Grid container spacing={2}>
        {loanApplication.borrowers.map((borrower, borrowerIndex) => {
          if(borrowerIndex === 1 && loanApplication.jointAssetsAndLiabilities) {
            return null;
          }
          return (
            <Grid key={borrowerIndex}
                  item
                  //@ts-ignore
                  md={loanApplication.borrowers && loanApplication.borrowers.length > 1 ? Math.floor(12 / loanApplication.borrowers.length) : 6}>

              {!loanApplication.jointAssetsAndLiabilities &&
                renderBorrowerSubtitle(borrower)
              }

              {(!borrower.liabilities || borrower.liabilities.length === 0) &&
              <div className={classes.mb2}>
                <Well color="warn">
                  <Typography variant="body1">
                    <FormattedMessage id="no_liabilities_found" />.&nbsp;
                    {!loanApplication.completed &&
                    <Link component={RouterLink} to="/apply/assets-and-liabilities">
                      <FormattedMessage id="add_liabilities"/>
                    </Link>
                    }
                  </Typography>
                </Well>
              </div>
              }

              {borrower.liabilities &&
              <div>
                {borrower.liabilities.map((liability, i) => {
                  return (
                    <div key={i} className={classes.pv1}>
                      <Item label={`${liability.financialInstitution} ${liability.accountNumber ? TextUtil.maskText(liability.accountNumber) : ''}`}
                            icon="money_off">
                        {(liability.balance !== null && liability.balance !== undefined && liability.balance > 0) &&
                        <Typography variant="body1">
                          <FormattedMessage id="balance" />: {getMoneyText(liability.balance)}
                        </Typography>
                        }
                        {(liability.monthlyPayment !== null && liability.monthlyPayment !== undefined && liability.monthlyPayment > 0) &&
                        <Typography variant="body1"><FormattedMessage id="monthly_payment" />: {getMoneyText(liability.monthlyPayment)}</Typography>
                        }
                        {(liability.monthsLeftToPay !== null && liability.monthsLeftToPay !== undefined && liability.monthsLeftToPay > 0) &&
                        <Typography variant="body1">{liability.monthsLeftToPay} <FormattedMessage id="months_left_to_pay" /></Typography>
                        }
                      </Item>
                    </div>
                  )
                })}
              </div>
              }
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getLoanApplication(id:string) {
    return dispatch(getLoanApplication(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoanAssetsLiabilitiesSummary)));