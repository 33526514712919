import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  IconButton,
  Icon,
  Button,
  InputLabel, withStyles, MenuItem, Checkbox, ListItemText, FormControl, Select as RNSelect, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {
  ColorButton,
  ErrorList,
  RadioGroup,
  TextField,
  Alert
} from "../../../components";
import moment from "moment-timezone";
import _ from 'lodash';
import {connect} from "react-redux";
import {addLoanOfficer, updateLoanOfficer} from "../../../actions/loanOfficers";
import {ErrorUtil, PhoneNumberUtil, ReduxUtil, UserUtil} from "../../../utils";
import Api from "../../../lib/Api";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {getRoles} from "../../../actions/roles";
import {getSubscription, updateSubscription} from "../../../actions/subscription";
import {
  ActionResponse,
  LabelValuePairType,
  PhoneNumberState,
  ReduxApp,
  ReduxSubscription,
  ErrorState, ReduxUser
} from "../../../types";
import {
  Branch,
  LoanOfficer,
  LoanOfficerRequest,
  LoanOfficerUpdateRequest,
  SubscriptionUpdateRequest,
  User,
  Role
} from "@jerseydev/orca-loans";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../../../data/initialState";
import {LoanOfficerRequestBase} from "@jerseydev/orca-loans/src/index";
import {AxiosResponse} from "axios";

type Props = {
  loanOfficer?: Partial<LoanOfficer>,
  onSubmit: (data:AxiosResponse<LoanOfficer>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  subscription: ReduxSubscription,
  user: ReduxUser,
  addLoanOfficer: (data:LoanOfficerRequest) => ActionResponse,
  updateLoanOfficer: (id:string, data:LoanOfficerUpdateRequest) => ActionResponse,
  getRoles: () => ActionResponse,
  getSubscription: () => ActionResponse
  updateSubscription: (data:SubscriptionUpdateRequest) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  firstName: string,
  lastName: string,
  email: string,
  description: string,
  title: string,
  nmlsId: string,
  branch: Branch|null,
  phoneNumbers: PhoneNumberState[],
  stateLicenses: string[],
  timezone: string,
  roles: Role[],
  data: any,
  ownerIsLoanOfficer: ''|'yes'|'no',
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  existingUser: User|null
}

class LoanOfficerForm extends BaseForm<Props, State> {
  availableTimezones:string[] = [];
  phoneNumberTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      firstName: '',
      lastName: '',
      email: '',
      description: '',
      title: '',
      nmlsId: '',
      branch: null,
      phoneNumbers: [],
      stateLicenses: [],
      timezone: moment.tz.guess(),
      roles: [],
      data: {},
      ownerIsLoanOfficer: props.loanOfficer && props.loanOfficer.user ? props.loanOfficer.user._id === props.user.data._id ? 'yes' : 'no' : ''
    };

    if(props.loanOfficer) {
      form = _.merge(form, _.cloneDeep(props.loanOfficer));
      form.roles = props.loanOfficer.user ? props.loanOfficer.user.roles : [];
    }

    if(!form.title) {
      form.title = '';
    }

    if(!form.description) {
      form.description = '';
    }
    //form = _.merge(form, this.testData);

    this.state = {
      loading: true,
      form,
      errors: [],
      existingUser: null,
    };

    this.availableTimezones = UserUtil.getAmericanTimezones();

    props.app.data.enums.phoneNumberTypes.forEach(type => {
      this.phoneNumberTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `phone_number_types_${type}`) })
    });
  }

  componentDidMount = async () => {
    try {
      const {subscription} = this.props;
      if(!ReduxUtil.hasData(subscription) && ReduxUtil.isIdle(subscription)) {
        await this.props.getSubscription().send();
      }
      this.setState({loading:false});

    } catch (e) {
      this.setState({loading:false, errors:ErrorUtil.formatErrors(e)});
    }
  };

  onStateLicenseChange = (event:ChangeEvent<{value:string[]}>) => {
    const form = _.cloneDeep(this.state.form);
    form.stateLicenses = _.clone(event.target.value);
    this.setState({ form });
  };

  renderStateLicenses = (selected:string[]) => {
    let abbreviations:string[] = [];
    selected.forEach(s => {
      abbreviations.push(s.toUpperCase());
    });
    return abbreviations.join(',');
  };

  onLoanOfficerExistsAlertClose = () => {
    this.setState({ existingUser: null });
  };

  onOwnerIsLoanOfficerChange = (event:ChangeEvent<HTMLInputElement>) => {
    const {user} = this.props;
    const form = _.cloneDeep(this.state.form);
    form.ownerIsLoanOfficer = event.target.value as Form["ownerIsLoanOfficer"];
    if(form.ownerIsLoanOfficer === 'yes') {
      form.firstName = user.data.firstName;
      form.lastName = user.data.lastName;
      form.email = user.data.email;
    } else if(form.ownerIsLoanOfficer === 'no') {
      form.firstName = '';
      form.lastName = '';
      form.email = '';
      form.nmlsId = '';
      form.stateLicenses = [];
    }
    this.setState({form});
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    this.setState({ loading: true, errors: [] });
    const form = _.cloneDeep(this.state.form);
    let formValid = false;
    if(this.props.loanOfficer) {
      formValid = true;
    } else {
      try {
        const emailResult = await Api.getUserByEmail(form.email, { deleted: 'all' });
        this.setState({ loading: false, existingUser: _.cloneDeep(emailResult.data) });
      } catch (e) {
        if(e.response && e.response.status === 404) {
          formValid = true;
        } else {
          this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
        }
      }
    }

    if(formValid) {
      if(form.data && Object.keys(form.data).length === 0) {
        form.data = null;
      } else {
        for(let key in form.data) {
          if(form.data.hasOwnProperty(key) && form.data[key] === '') {
            form.data[key] = undefined;
          }
        }
      }

      try {
        let result:AxiosResponse<LoanOfficer>;
        const {firstName, lastName, email, title, nmlsId, phoneNumbers, description, branch, timezone, roles, stateLicenses, ownerIsLoanOfficer} = this.state.form;
        let requestBase:LoanOfficerRequestBase = {
          firstName,
          lastName,
          email,
          nmlsId,
          timezone,
          roles: _.map(roles, '_id'),
          stateLicenses,
          branch: branch!._id,
          title: title !== '' ? title : null,
          description: description !== '' ? description : null
        }
        if(this.props.loanOfficer && this.props.loanOfficer._id) {
          const requestData:LoanOfficerUpdateRequest = {
            ...requestBase,
            phoneNumbers: PhoneNumberUtil.stateToUpdateRequest(phoneNumbers)
          };
          result = await this.props.updateLoanOfficer(this.props.loanOfficer._id, requestData).send();
        } else {
          // add a license
          if(this.props.subscription.data.quantity === 1 && ownerIsLoanOfficer === 'no') {
            await this.props.updateSubscription({ quantity: 2 }).send();
          }
          const requestData:LoanOfficerRequest = {
            ...requestBase,
            phoneNumbers: PhoneNumberUtil.stateToCreateRequest(phoneNumbers)
          }
          result = await this.props.addLoanOfficer(requestData).send();
        }
        this.setState({ loading: false }, () => {
          this.props.onSubmit(_.cloneDeep(result));
        });
      } catch(e) {
        this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
      }
    }
  };

  render() {

    const { intl, classes, app, loanOfficer, subscription } = this.props;
    const { form, loading, errors, existingUser } = this.state;

    return (
      <div>
        {existingUser &&
        <div className={classes.mb2}>
          <Alert severity="danger" action={
            <IconButton onClick={this.onLoanOfficerExistsAlertClose} size="small">
              <Icon>cancel</Icon>
            </IconButton>
          }>
            <FormattedMessage id="user_exists_select_another_email" values={{ user: existingUser.email }} />
          </Alert>
        </div>
        }
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } }/>

          <div className={classes.mb2}>
            <RadioGroup name="ownerIsLoanOfficer"
                        itemValueProp="value"
                        value={form.ownerIsLoanOfficer}
                        label={IntlFormatter.formatMessage(intl, 'setup_wizard_are_you_the_loan_officer')}
                        onChange={this.onOwnerIsLoanOfficerChange}
                        items={[
                          {label: IntlFormatter.formatMessage(intl, 'yes'), value: 'yes'},
                          {label: IntlFormatter.formatMessage(intl, 'no'), value: 'no'}
                        ]}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required')
                        ]} row />
          </div>

          {(form.ownerIsLoanOfficer === 'yes') &&
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField name="nmlsId"
                             label={IntlFormatter.formatMessage(intl, 'nmls_id')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.nmlsId')}
                             value={form.nmlsId}
                             fullWidth={true}
                             validators={['required']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="state-licenses-label">{IntlFormatter.formatMessage(intl, 'state_licenses')}</InputLabel>
                    <RNSelect
                      labelId="state-licenses-label"
                      id="state-licenses-select"
                      multiple
                      value={form.stateLicenses}
                      renderValue={this.renderStateLicenses}
                      onChange={this.onStateLicenseChange}
                      label={IntlFormatter.formatMessage(intl, 'state_licenses')}
                    >
                      {app.data.enums.states.map(state => (
                        <MenuItem key={state} value={state}>
                          <Checkbox checked={form.stateLicenses.indexOf(state) > -1} />
                          <ListItemText primary={IntlFormatter.formatMessage(intl, `state_${state}`)} />
                        </MenuItem>
                      ))}
                    </RNSelect>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          }
          {(form.ownerIsLoanOfficer === 'no') &&
            <div>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField name="firstName"
                               label={IntlFormatter.formatMessage(intl, 'first_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.firstName')}
                               value={form.firstName}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="lastName"
                               label={IntlFormatter.formatMessage(intl, 'last_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.lastName')}
                               value={form.lastName}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField name="email"
                               label={IntlFormatter.formatMessage(intl, 'email')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.email')}
                               value={form.email}
                               fullWidth={true}
                               disabled={!!(loanOfficer && loanOfficer._id)}
                               inputProps={{
                                 autoCapitalize: 'none',
                               }}
                               validators={['required', 'isEmail']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="nmlsId"
                               label={IntlFormatter.formatMessage(intl, 'nmls_id')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.nmlsId')}
                               value={form.nmlsId}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.mv2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="state-licenses-label">{IntlFormatter.formatMessage(intl, 'state_licenses')}</InputLabel>
                      <RNSelect
                        labelId="state-licenses-label"
                        id="state-licenses-select"
                        multiple
                        value={form.stateLicenses}
                        renderValue={this.renderStateLicenses}
                        onChange={this.onStateLicenseChange}
                        label={IntlFormatter.formatMessage(intl, 'state_licenses')}
                      >
                        {app.data.enums.states.map(state => (
                          <MenuItem key={state} value={state}>
                            <Checkbox checked={form.stateLicenses.indexOf(state) > -1} />
                            <ListItemText primary={IntlFormatter.formatMessage(intl, `state_${state}`)} />
                          </MenuItem>
                        ))}
                      </RNSelect>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
          }
          <div className={classes.mt3}>
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel} size="large">
                  <FormattedMessage id="back" />
                </Button>
              </Grid>
              }
              <Grid item>
                <ColorButton type="submit"
                             loading={loading}
                             size="large"
                             color="success"
                             endIcon={<Icon>verified</Icon>}
                             disabled={loading || (!loanOfficer && subscription.data.licensesAvailable <= 0)}>
                  <FormattedMessage id="get_started" />
                </ColorButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    subscription: state.subscription,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addLoanOfficer(data:LoanOfficerRequest) {
    return dispatch(addLoanOfficer(data));
  },
  updateLoanOfficer(id:string, data:LoanOfficerUpdateRequest) {
    return dispatch(updateLoanOfficer(id, data));
  },
  getRoles() {
    return dispatch(getRoles());
  },
  getSubscription() {
    return dispatch(getSubscription());
  },
  updateSubscription(data:SubscriptionUpdateRequest) {
    return dispatch(updateSubscription(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoanOfficerForm)));
