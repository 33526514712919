import {Subscription} from "@jerseydev/orca-loans";

class SubscriptionUtil {
  static isExpired = (status:string) => {
    const expiredStatus = ['incomplete_expired', 'canceled'];

    return expiredStatus.includes(status);
  };

  static isCancelling = (subscription:Subscription) => {
    return !!subscription.cancel_at_period_end && subscription.status !== 'canceled';
  };

  static isBasic = (subscription:Subscription) => {
    return subscription.plan.key === 'basic';
  };

  static isProfessional = (subscription:Subscription) => {
    return subscription.plan.key === 'professional';
  };


  static isEnterprise = (subscription:Subscription) => {
    return subscription.plan.key === 'enterprise';
  };
}

export default SubscriptionUtil;