import React, {useState} from 'react';
import {Button} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {LoanApplication} from "@jerseydev/orca-loans";
import {HelpFormDialog} from "./index";

type Props = {
  loanApplication: LoanApplication
}

const LoanHelpAlert = (props:Props) => {
  const {loanApplication} = props;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const onClick = () => {
    setDialogOpen(true);
  };

  const onClose = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <HelpFormDialog open={dialogOpen}
                      refID={loanApplication._id}
                      refCategory="loan"
                      onSubmit={onClose}
                      onClose={onClose} />

      <Button variant="outlined" onClick={onClick}>
        <FormattedMessage id="need_help_with_loan" />
      </Button>
    </div>
  )
};

export default LoanHelpAlert;