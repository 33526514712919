import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {CreditCardRequest, CreditCardUpdateRequest} from "@jerseydev/orca-loans";

export function getCreditCards():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getCreditCards({cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const creditCards = state.creditCards && state.creditCards.data ? _.cloneDeep(state.creditCards.data) : [];
      dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.FETCHING, _.cloneDeep(creditCards)));

      apiRequest.then(response => {
        dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.IDLE, creditCards));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addCreditCard(data:CreditCardRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createCreditCard(data, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const creditCards = state.creditCards && state.creditCards.data ? _.cloneDeep(state.creditCards.data) : [];

      dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.ADDING, _.cloneDeep(creditCards)));
      
      apiRequest.then(response => {
        creditCards.push(response.data);
        dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.IDLE, creditCards));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.IDLE, creditCards));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateCreditCard(id:string, data:CreditCardUpdateRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    //const { name, exp_month, exp_year, address_line1, address_city, address_state, address_zip, address_country } = data;
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateCreditCard(id, data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const creditCards = state.creditCards && state.creditCards.data ? _.cloneDeep(state.creditCards.data) : [];
      dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.UPDATING, _.cloneDeep(creditCards)));

      apiRequest.then(response => {
        const index = creditCards.findIndex(c => c.id === id);
        if(index > -1) {
          creditCards[index] = response.data;
        } else {
          creditCards.push(response.data);
        }
        dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.IDLE, creditCards));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.IDLE, creditCards));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteCreditCard(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteCreditCard(id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const creditCards = state.creditCards && state.creditCards.data ? _.cloneDeep(state.creditCards.data) : [];
      dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.DELETING, _.cloneDeep(creditCards)));

      apiRequest.then(response => {
        const index = creditCards.findIndex(c => c.id === id);
        if(index > -1) {
          creditCards.splice(index, 1);
        }
        dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.IDLE, creditCards));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_CREDIT_CARDS, ReduxAsyncOperation.IDLE, creditCards));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}