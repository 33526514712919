import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  Button,
  withStyles, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, NoLicensesAlert, SubmitButton, TextField} from "../../../components";
import moment from "moment-timezone";
import _ from 'lodash';
import {connect} from "react-redux";
import {addUser} from "../../../actions/users";
import {getRoles} from "../../../actions/roles";
import {ErrorUtil, UserUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {AxiosResponse} from "axios";
import {Branch, Role, User, UserRequest} from "@jerseydev/orca-loans";
import {ActionResponse, ReduxRoles, ErrorState} from "../../../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  branch: Branch
  onSubmit: (data:AxiosResponse<User>) => void,
  onCancel?: () => void,
  roles: ReduxRoles,
  getRoles: () => ActionResponse,
  addUser: (data:UserRequest) => ActionResponse
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

type Form = {
  firstName: string,
  lastName: string,
  password: string,
  email: string,
  roles: Role[],
  additionalProps: {
    branch: string|null
  },
  timezone: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  roleSearchText: string
}

class BranchManagerForm extends BaseForm<Props, State> {
  availableTimezones:string[] = [];

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: true,
      form: {
        firstName: '',
        lastName: '',
        password: UserUtil.generatePassword(),
        email: '',
        roles: [],
        additionalProps: {
          branch: props.branch ? props.branch._id : null
        },
        timezone: moment.tz.guess()
      },
      errors: [],
      roleSearchText: ''
    };

    const allTimezones = moment.tz.names();
    allTimezones.forEach((tz) => {
      if(tz.includes('America')) {
        this.availableTimezones.push(tz);
      }
    });
  }

  componentDidMount = async () => {
    try {
      const result = await this.props.getRoles().send();
      const branchManagerRole = result.data.find((r:Role) => r.key === 'ROLE_BRANCH_MANAGER');
      const form = _.cloneDeep(this.state.form);
      form.roles = [_.cloneDeep(branchManagerRole)];
      this.setState({ form, loading: false });
    } catch (e) {
      this.setState({ errors: ErrorUtil.formatErrors(e), loading: false });
    }
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const {firstName, lastName, email, password, timezone, roles, additionalProps} = this.state.form;
      const data:UserRequest = {
        firstName,
        lastName,
        email,
        password,
        forcePasswordReset: true,
        roles: _.map(roles, '_id'),
        additionalProps,
        timezone
      };
      const result = await this.props.addUser(data).send();
      this.props.mixpanel.track("User added");
      this.setState({ loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {
    const { intl, classes } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <NoLicensesAlert className={classes.mb2} />

        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField name="firstName"
                         label={IntlFormatter.formatMessage(intl, 'first_name')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.firstName')}
                         value={form.firstName}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="lastName"
                         label={IntlFormatter.formatMessage(intl, 'last_name')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.lastName')}
                         value={form.lastName}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField name="email"
                       label={IntlFormatter.formatMessage(intl, 'email')}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.email')}
                       value={form.email}
                       fullWidth={true}
                       inputProps={{
                         autoCapitalize: 'none',
                       }}
                       validators={['required', 'isEmail']}
                       errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
          </Grid>
        </Grid>

        <div className={classes.mt2}>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
            {this.props.onCancel &&
            <Grid item>
              <Button onClick={this.props.onCancel}>
                <FormattedMessage id="cancel" />
              </Button>
            </Grid>
            }
            <Grid item>
              <SubmitButton loading={loading}>
                <FormattedMessage id="save" />
              </SubmitButton>
            </Grid>
          </Grid>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    roles: state.roles
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getRoles() {
    return dispatch(getRoles());
  },
  addUser(data:UserRequest) {
    return dispatch(addUser(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(BranchManagerForm)));
