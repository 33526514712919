import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from './data';
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {
  AssetIntegrationRequest, CreditIntegrationRequest,
  LosIntegrationRequest,
  PricingIntegrationRequest,
} from "@jerseydev/orca-loans";

export function getIntegrations():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getIntegrations({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      let integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.FETCHING, integrations));
      apiRequest.then(response => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getLosIntegration():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getLosIntegration({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      let integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.FETCHING, integrations));
      apiRequest.then(response => {
        integrations.los = response.data;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateLosIntegration(data:LosIntegrationRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateLosIntegration(data, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.UPDATING, integrations));

      apiRequest.then(response => {
        integrations.los = response.data;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteLosIntegration():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteLosIntegration({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.UPDATING, integrations));

      apiRequest.then(response => {
        integrations.los = null;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getPricingIntegration():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getPricingIntegration({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      let integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.FETCHING, integrations));
      apiRequest.then(response => {
        integrations.pricing = response.data;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updatePricingIntegration(data:PricingIntegrationRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updatePricingIntegration(data, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.UPDATING, integrations));

      apiRequest.then(response => {
        integrations.pricing = response.data;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deletePricingIntegration():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deletePricingIntegration({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.UPDATING, integrations));

      apiRequest.then(response => {
        integrations.pricing = null;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getAssetIntegration():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getAssetIntegration({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      let integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.FETCHING, integrations));
      apiRequest.then(response => {
        integrations.assets = response.data;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateAssetIntegration(data:AssetIntegrationRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateAssetIntegration(data, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.UPDATING, integrations));

      apiRequest.then(response => {
        integrations.assets = response.data;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteAssetIntegration():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteAssetIntegration({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.UPDATING, integrations));

      apiRequest.then(response => {
        integrations.assets = null;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getCreditIntegration():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getCreditIntegration({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      let integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.FETCHING, integrations));
      apiRequest.then(response => {
        integrations.credit = response.data;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateCreditIntegration(data:CreditIntegrationRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateCreditIntegration(data, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.UPDATING, integrations));

      apiRequest.then(response => {
        integrations.credit = response.data;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteCreditIntegration():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteCreditIntegration({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const integrations = state.integrations && state.integrations.data ? _.cloneDeep(state.integrations.data) : {};
      dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.UPDATING, integrations));

      apiRequest.then(response => {
        integrations.credit = null;
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}