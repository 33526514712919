import React, {ChangeEvent} from 'react';
import {
  FormLabel,
  FormControlLabel,
  FormControl,
  FormGroup,
  FormHelperText, Checkbox
} from '@material-ui/core';
import {ValidatorComponent, ValidatorComponentProps} from "react-material-ui-form-validator";

type DefaultProps = {
  itemLabelProp: string
}

type Props = {
  items: any[],
  label?: string,
  value?: any,
  onChange?: (event:ChangeEvent) => void,
  itemValueProp?: string
} & ValidatorComponentProps
  & Partial<DefaultProps>

type State = {
  isValid: boolean
}

class CheckboxGroup extends ValidatorComponent<Props, State> {
  isRequired = () => {
    let required = false;
    if(this.props.validators && this.props.validators.indexOf('required') > -1) {
      required = true;
    }

    return required;
  };

  renderError = () => {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <FormHelperText>
        {this.getErrorMessage()}
      </FormHelperText>
    );
  };

  onChange = (event:ChangeEvent) => {
    if(this.props.onChange) {
      this.props.onChange(event);
    }
  };

  renderValidatorComponent() {

    //const { label, value, items, itemLabelProp, itemValueProp, errorMessages, validators, requiredError, validatorListener, disabled, row, ...rest } = this.props;
    const { label, items, disabled, row, value } = this.props;
    const { isValid } = this.state;

    return (
      <FormControl component="fieldset"
                   fullWidth={true}
                   error={!isValid}
                   disabled={disabled}>
        {label &&
          <FormLabel component="legend">
            {label}
          </FormLabel>
        }
        <FormGroup row={row}>
          {items.map((item, i) => {
            return <FormControlLabel key={i}
                                     label={item.label}
                                     control={
                                       <Checkbox value={item.value}
                                                 checked={value.includes(item.value)}
                                                 onChange={this.onChange} />
                                     } />
          })}

        </FormGroup>
        {this.renderError()}
      </FormControl>
    );
  }
}

export default CheckboxGroup;
