import React from 'react';
import {Alert} from "../../../components";
import {Button} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

type Props = {
  provider: string
}

const IntegrationHelpAlert = (props:Props) => {
  return (
    <Alert severity="info"
           action={
             <Button variant="outlined"
                     size="small"
                     onClick={() => window.open(`https://docs.orcaloans.com/docs/integrations-${props.provider.toLowerCase()}`, '_blank')}>
               <FormattedMessage id="view_docs" />
             </Button>
           }>
      <FormattedMessage id="integration_help" />
    </Alert>
  )
};

export default IntegrationHelpAlert;