import palette from "../palette";
import {defaultRadius} from "../constants";
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  itemsContainer: {
    marginBottom: theme.spacing(3)
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0`,
    borderBottom: `solid 1px ${palette.divider}`,
    '&:last-child': {
      borderBottom: 'none'
    },
    '& .MuiTypography-body1': {
      fontSize: 18
    }
  },
  itemContent: {
    [theme.breakpoints.up("sm")]: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  },
  itemLarge: {
    padding: `${theme.spacing(2)}px 0`,
    '& .MuiTypography-body1': {
      fontSize: 20
    }
  },
  itemLeft: {
    justifyContent: 'flex-start'
  },
  itemRight: {
    [theme.breakpoints.up("sm")]: {
      justifyContent: 'flex-end'
    }
  },
  itemIndicator: {
    borderRadius: defaultRadius,
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1)
  },
  itemLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemValue: {
    '& .MuiTypography-body1': {
      fontWeight: 'bold'
    }
  },
  amortizationSchedule: {
    marginTop: theme.spacing(3)
  },
  amortizationScheduleTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  amortizationScheduleTitleIcon: {
    marginRight: theme.spacing(1)
  },
});

export default styles;
