import React, {MouseEvent} from 'react';
import {
  Button,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem, WithStyles,
  withStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import BaseForm from "../../../forms/BaseForm";
import {connect} from "react-redux";
import {getHiddenMilestones, deleteHiddenMilestone} from "../../../actions/hiddenMilestones";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {DeleteDialog, Dialog, ErrorList, Fab, PropagateLoader, Section, Snackbar} from "../../../components";
import IntlFormatter from '../../../intl/index';
import _ from "lodash";
import {ErrorUtil, ReduxUtil} from "../../../utils";
import {HiddenMilestoneForm} from '../forms';
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {HiddenMilestone} from "@jerseydev/orca-loans";
import {
  DialogState,
  ActionProps,
  SnackbarState,
  ReduxHiddenMilestones,
  ErrorState
} from "../../../types";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  hiddenMilestones: ReduxHiddenMilestones,
  getHiddenMilestones: ActionProps["getHiddenMilestones"]
  deleteHiddenMilestone: ActionProps["deleteHiddenMilestone"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  loading: boolean,
  selectedItem: HiddenMilestone|null,
  addEditDialogOpen: boolean,
  deleteDialog: DialogState,
  snackbar: SnackbarState,
  actionMenuEl: Element|null,
  errors: ErrorState[]
}

class HiddenMilestoneList extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: true,
      selectedItem: null,
      addEditDialogOpen: false,
      actionMenuEl: null,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      snackbar: {
        open: false,
        message: ''
      },
      errors: []
    }
  }

  componentDidMount = async () => {
    try {
      if(!ReduxUtil.hasData(this.props.hiddenMilestones) && !ReduxUtil.isFetching(this.props.hiddenMilestones)) {
        await this.props.getHiddenMilestones().send();
      }

      this.setState({loading:false});
    } catch (e) {
      this.setState({loading:false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  renderLoanTypes = (hiddenMilestone:HiddenMilestone) => {
    const loanTypes:string[] = [];
    hiddenMilestone.loanTypes.forEach(loanType => {
      loanTypes.push(IntlFormatter.formatMessage(this.props.intl, `loan_type_${loanType}`));
    });
    return loanTypes.join(', ');
  };

  onActionMenuClick = (event:MouseEvent, hiddenMilestone:HiddenMilestone) => {
    this.setState({ actionMenuEl: event.currentTarget, selectedItem: _.cloneDeep(hiddenMilestone) });
  };

  onActionMenuClose = () => {
    this.setState({ actionMenuEl: null, selectedItem: null });
  };

  onAddClick = () => {
    this.setState({ addEditDialogOpen: true });
  };

  onEditClick = () => {
    this.setState({ actionMenuEl: null, addEditDialogOpen: true });
  };

  onCancel = () => {
    this.setState({ addEditDialogOpen: false, selectedItem: null });
  };

  onSubmit = () => {
    this.setState({
      addEditDialogOpen: false,
      selectedItem: null,
      snackbar: {
        open: true,
        message: IntlFormatter.formatMessage(this.props.intl, 'hidden_milestone_saved')
      }
    });
  };

  onDeleteClick = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = true;
    this.setState({ actionMenuEl: null, deleteDialog });
  };

  onDeleteConfirm = async () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    try {
      deleteDialog.loading = true;
      deleteDialog.errors = [];
      this.setState({ deleteDialog });

      const { selectedItem } = this.state;
      if(selectedItem) {
        await this.props.deleteHiddenMilestone(selectedItem._id).send();
        this.props.mixpanel.track("Hidden milestone deleted");
      }

      deleteDialog.open = false;
      deleteDialog.loading = false;
      this.setState({
        deleteDialog,
        selectedItem: null,
        snackbar: {
          open: true,
          message: IntlFormatter.formatMessage(this.props.intl, 'hidden_milestone_deleted')
        }
      });

    } catch (e) {
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({ deleteDialog });
    }
  };

  onDeleteCancel = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = false;
    deleteDialog.loading = false;
    deleteDialog.errors = [];

    this.setState({ deleteDialog, selectedItem: null });
  };

  onSnackbarClose = () => {
    this.setState({
      snackbar: {
        open: false,
        message:''
      }
    });
  };

  render() {

    const { intl, hiddenMilestones, mixpanel, classes } = this.props;
    const { addEditDialogOpen, deleteDialog, selectedItem, snackbar, actionMenuEl, loading, errors } = this.state;

    return (
      <div>
        <PropagateLoader loading={loading} />

        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.onSnackbarClose}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        <DeleteDialog open={deleteDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'delete_category')}
                      item={selectedItem ? selectedItem.name : ''}
                      loading={deleteDialog.loading}
                      errors={deleteDialog.errors}
                      onCancel={this.onDeleteCancel}
                      onSubmit={this.onDeleteConfirm} />

        <Dialog open={addEditDialogOpen}
                title={IntlFormatter.formatMessage(intl, selectedItem ? 'edit_hidden_milestone' : 'add_hidden_milestone')}
                icon={<Icon>label</Icon>}
                color="infoAlt"
                fullWidth={true}
                maxWidth="sm"
                onClose={this.onCancel}>
          <HiddenMilestoneForm hiddenMilestone={selectedItem}
                               mixpanel={mixpanel}
                               onCancel={this.onCancel}
                               onSubmit={this.onSubmit} />
        </Dialog>

        <Menu anchorEl={actionMenuEl}
              open={Boolean(actionMenuEl)}
              onClose={this.onActionMenuClose}>
          <MenuItem onClick={this.onEditClick}>
            <ListItemIcon>
              <Icon>mode_edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')}/>
          </MenuItem>
          <MenuItem onClick={this.onDeleteClick}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')}/>
          </MenuItem>
        </Menu>

        <Section title={IntlFormatter.formatMessage(intl, 'hidden_milestones')}
                 helperText={IntlFormatter.formatMessage(intl, 'hidden_milestones_help')}
                 actions={
                   <Fab color="primary"
                        size="small"
                        onClick={this.onAddClick}
                        flat
                        rounded>
                     <Icon>add</Icon>
                   </Fab>
                 }>
          <ErrorList errors={errors} className={classes.mv2} />
          {ReduxUtil.hasData(hiddenMilestones) &&
            <div>
              {hiddenMilestones.data.length === 0 &&
              <div>
                <Button variant="outlined" color="primary" onClick={this.onAddClick}>
                  <FormattedMessage id="add_hidden_milestone" />
                </Button>
              </div>
              }
              <List>
                {hiddenMilestones.data.map((hiddenMilestone:HiddenMilestone, i:number ) => {
                  const loanTypesText = this.renderLoanTypes(hiddenMilestone);
                  return (
                    <ListItem key={i}>
                      <ListItemText primary={hiddenMilestone.name}
                                    secondary={loanTypesText} />
                      <ListItemSecondaryAction>
                        <IconButton onClick={(event) => this.onActionMenuClick(event, hiddenMilestone)}>
                          <Icon>more_vert</Icon>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </List>
            </div>
          }
        </Section>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    hiddenMilestones: state.hiddenMilestones
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getHiddenMilestones() {
    return dispatch(getHiddenMilestones());
  },
  deleteHiddenMilestone(id:string) {
    return dispatch(deleteHiddenMilestone(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(HiddenMilestoneList)));
