import React, {Component} from 'react';
import {Grid, Typography} from '@material-ui/core';
import UserAvatar from './UserAvatar';
import {User, UserLean} from "@jerseydev/orca-loans";

type DefaultProps = {
  popoverEnabled: boolean,
}

type Props = {
  user: User|UserLean,
  subtitle?: string,
  size?: 'small'|'normal'|'large'|'extraLarge'
} & Partial<DefaultProps>

class UserReference extends Component<Props> {
  static defaultProps:DefaultProps = {
    popoverEnabled: true
  };

  render() {
    const { user, subtitle, popoverEnabled, size } = this.props;

    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <UserAvatar user={user} popoverEnabled={popoverEnabled} size={size} />
        </Grid>
        <Grid item>
          <Typography variant="body1">
            <b>{user.firstName} {user.lastName}</b>
          </Typography>
          {subtitle &&
            <div>
              <Typography variant="caption">{subtitle}</Typography>
            </div>
          }
        </Grid>
      </Grid>
    );
  }
}

export default UserReference;
