import React, {Component} from 'react';
import {
  Typography,
  CardContent,
  Card,
  withStyles,
  CardHeader,
  Divider, WithStyles,
} from '@material-ui/core';
import IntlFormatter from "../../../intl";
import {Doughnut, ChartComponentProps} from "react-chartjs-2";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import styles from "../../../theme/jss/components/loanStatusChart";
import clsx from "clsx";
import _ from 'lodash';
import {FixMeLater} from "../../../types";
import themePalette from "../../../theme/jss/palette";

const palette:FixMeLater = themePalette;

type PercentageDataSet = {
  title: string,
  value: number,
  color: string
}

type Data = {
  chartData: ChartComponentProps["data"],
  percentageData: PercentageDataSet[]
}

export type LoanStatusChartProps = {
  data: Data
}

type Props = LoanStatusChartProps
  & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  selectedIntervalIndex: number,
  intervalPopperOpen: boolean
}

const options:ChartComponentProps["options"] = {
  legend: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 70,
  layout: { padding: 0 },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text!.primary,
    bodyFontColor: palette.text!.secondary,
    footerFontColor: palette.text!.secondary
  }
};

class LoanStatusChart extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      selectedIntervalIndex: 0,
      intervalPopperOpen: false,
    };
  }

  hasData = () => {
    const totalValue = _.sumBy(this.props.data.percentageData, 'value');
    return totalValue > 0;
  }

  render() {
    const { intl, classes, data } = this.props;
    const hasData = this.hasData();

    return (
      <Card className={classes.root}>
        <CardHeader title={IntlFormatter.formatMessage(intl, 'loans_by_status')}/>
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <div className={clsx(classes.chartContainer, !hasData ? classes.center : null)}>
              {!hasData &&
              <Typography variant="subtitle2">
                <FormattedMessage id="no_data_to_display_chart" />
              </Typography>
              }
              {hasData &&
              <Doughnut data={data.chartData} options={options} />
              }
            </div>

          </div>
          {hasData &&
            <div className={classes.stats}>
              {data.percentageData.map(status => (
                <div className={classes.device} key={status.title}>
                  <Typography variant="body1">{status.title}</Typography>
                  <Typography style={{ color: status.color }} variant="h2">
                    {status.value}%
                  </Typography>
                </div>
              ))}
            </div>
          }
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(LoanStatusChart));
