import * as AppActions from './app';
import * as AccountActions from './account';
import * as AuthActions from './auth';
import * as UsersActions from './users';
import * as RolesActions from './roles';
import * as LoanApplicationActions from './loanApplication';
import * as LoanApplicationsActions from './loanApplications';
import * as LoanOfficersActions from './loanOfficers';
import * as BranchesActions from './branches';
import * as SettingsActions from './settings';
import * as SecureMessageThreadsActions from './secureMessageThreads';
import * as SecureMessagesActions from './secureMessages';
import * as CreditCardsActions from './creditCards';
import * as BankAccountsActions from './bankAccounts';
import * as PlansActions from './plans';
import * as SubscriptionActions from './subscription';
import * as NotificationActions from './notifications';
import * as OauthClientActions from './oauthClients';
import * as WebHookActions from './webHooks';
import * as DesignActions from './design';
import * as IntegrationActions from './integrations';
import * as LoanDocumentCategoryActions from './loanDocumentCategories';
import * as HiddenMilestoneActions from './hiddenMilestones';
import * as InvoiceActions from './invoices';
import * as TicketActions from './tickets';

export const ActionCreators = Object.assign({},
  AppActions,
  AccountActions,
  AuthActions,
  UsersActions,
  RolesActions,
  LoanApplicationActions,
  LoanApplicationsActions,
  LoanOfficersActions,
  BranchesActions,
  SettingsActions,
  SecureMessageThreadsActions,
  SecureMessagesActions,
  CreditCardsActions,
  BankAccountsActions,
  PlansActions,
  SubscriptionActions,
  NotificationActions,
  OauthClientActions,
  WebHookActions,
  DesignActions,
  IntegrationActions,
  LoanDocumentCategoryActions,
  HiddenMilestoneActions,
  InvoiceActions,
  TicketActions
);
