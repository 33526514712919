import {ConfigUtil} from "../../../utils";
import {PageTitle} from "../../../components";
import IntlFormatter from "../../../intl";
import React from "react";
import {connect} from "react-redux";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {ReduxState} from "../../../data/initialState";
import {ReduxLoanSettings} from "../../../types";

type Props = {
  id: string,
  settings: ReduxLoanSettings
} & WrappedComponentProps

function LoanApplicationPageTitle(props:Props) {
  const {settings, id} = props;
  const menuItem = ConfigUtil.getLoanApplicationMenuItem(settings.data.loanRevision, id);

  return (
    <PageTitle icon={menuItem ? menuItem.icon : undefined}
               title={IntlFormatter.formatMessage(props.intl, id)}
               subtitle={IntlFormatter.formatMessage(props.intl, `loan_application_${id}_page_text`)} />
  )
}

const mapStateToProps = (state:ReduxState) => {
  return {
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(injectIntl(LoanApplicationPageTitle));