import React, {Component} from 'react';
import {
  Grid,
  Typography, WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/orcaFooterStyles';
import config from "../config";
import {FormattedMessage} from "react-intl";

type Props = WithStyles<typeof styles>

class OrcaFooter extends Component<Props> {

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm="auto">
            <Typography variant="body1" color="inherit">
              {config.app.copyright}
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Grid container spacing={1} alignItems="baseline">
              <Grid item>
                <Typography variant="subtitle2" color="inherit">
                  <FormattedMessage id="powered_by" />
                </Typography>
              </Grid>
              <Grid item>
                <a href="https://www.orcaloans.com" target="_blank" rel="noopener noreferrer">
                  <img src="https://storage.googleapis.com/orcaloans/assets/img/orca-logo-sm-dark.png" alt={config.app.name} />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(OrcaFooter);
