import React, { Component, MouseEvent } from 'react';
import {
  Icon,
  IconButton,
  Popper,
  Fade,
  ClickAwayListener,
  Typography,
  withStyles,
  PopperProps,
  PopperPlacementType,
  WithStyles
} from '@material-ui/core';
import {FormattedMessage} from "react-intl";
import styles from '../theme/jss/components/clipboardTextStyles';

type DefaultProps = {
  timeout: number,
  placement: PopperPlacementType
}

type Props = {
  value: string
} & Omit<PopperProps, 'open'>
  & Partial<DefaultProps>
  & WithStyles<typeof styles>

type State = {
  anchorEl: Element|null
  clicked: boolean
}

class ClipboardText extends Component<Props, State> {
  static defaultProps:DefaultProps = {
    timeout: 2000,
    placement: 'right'
  };

  timeout:NodeJS.Timeout;

  constructor(props:Props) {
    super(props);
    this.state = {
      anchorEl: null,
      clicked: false
    };
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  onCopyToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.value = this.props.value;
    textArea.setAttribute('class', 'clipboard-text');

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");

    this.setState({clicked: true});
    this.timeout = setTimeout(() => {
      this.setState({anchorEl: null});
      setTimeout(() => {
        this.setState({ clicked: false });
      }, 300)
    }, this.props.timeout);
  };

  onOpen = (event:MouseEvent) => {
    this.setState({anchorEl: event.currentTarget});
  };

  onClose = () => {
    if(!this.state.clicked) {
      this.setState({anchorEl: null});
    }
  };

  render() {
    const {classes, children, value, placement} = this.props;
    const {anchorEl, clicked} = this.state;

    if(!value) {
      return null;
    }

    return (
      <span className={classes.root}>
        <span className={classes.container}
             onMouseOver={this.onOpen}
             onMouseLeave={this.onClose}>
          <span className={classes.content}>
            {children}
          </span>
          <IconButton onClick={this.onCopyToClipboard}
                      data-clipboard-text={value}
                      size="small"
                      aria-describedby="clipboard-popper"
                      className={anchorEl ? undefined : classes.button}>
            <Icon fontSize="small" color="inherit">content_paste</Icon>
          </IconButton>
        </span>
        <Popper id="clipboard-popper"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement={placement}
                transition
                className={classes.popper}>
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={this.onClose}>
              <Fade {...TransitionProps} timeout={1000}>
                <Typography variant="body2" color="inherit">
                  <FormattedMessage id={clicked ? 'copied' : 'copy'} />
                </Typography>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </span>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ClipboardText);
