import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AdminDashboardPage, AccountDashboardPage, BranchManagerDashboardPage} from '../pages';
import {AclUtil} from '../../../utils';
import {ReduxState} from "../../../data/initialState";
import {ActionProps, ReduxUser} from "../../../types";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  routeProps: ActionProps,
  user: ReduxUser
}

class DashboardPage extends Component<Props> {

  render() {

    const { user, children, ...rest } = this.props;
    if(AclUtil.hasRole(user.data,'ROLE_MEMBER')) {
      // @ts-ignore
      return <AccountDashboardPage {...rest} />
    } else if(AclUtil.hasRole(user.data,'ROLE_BRANCH_MANAGER')) {
      return <BranchManagerDashboardPage {...rest} />
    } else {
      return <AdminDashboardPage {...rest} />
    }
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(DashboardPage);
