import React from "react";
import {NoResultsText} from "../../../components";
import {List} from "@material-ui/core";
import {PricingScenarioListItem} from "./index";
import {useStyles} from "../../../theme/jss/layouts/pageStyles";
import {LoanApplication, LoanApplication2009} from "@jerseydev/orca-loans";
import IntlFormatter from "../../../intl";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ReduxState} from "../../../data/initialState";
import {UserUtil} from "../../../utils";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
};

const PricingScenarioList = (props:Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const {loanApplication} = props;
  const user = useSelector((state:ReduxState) => state.user);
  let pricing = loanApplication.pricing ? loanApplication.pricing : [];
  const isBorrower = UserUtil.isBorrower(user!.data);
  if(isBorrower) {
    pricing = pricing.filter(p => p.sharedWithBorrower);
  }
  return (
    <div>
      {(pricing.length === 0) &&
        <div className={classes.ph1}>
          <NoResultsText message={IntlFormatter.formatMessage(intl, "no_saved_pricing_scenarios")} />
        </div>

      }
      {pricing.length > 0 &&
      <List>
        {pricing.map((p, i) =>
          (
            <PricingScenarioListItem key={i}
                                     loanApplication={loanApplication}
                                     price={p}
                                     isBorrower={isBorrower}/>
          )
        )}
      </List>
      }
    </div>
  )
};

export default PricingScenarioList;