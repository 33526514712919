export const SET_APP = 'SET_APP';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_TOKENS = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_CONFIGURED = 'SET_CONFIGURED';
export const SET_USERS = 'SET_USERS';
export const SET_ROLES = 'SET_ROLES';
export const LOGOUT = 'LOGOUT';
export const SET_LOAN_APPLICATION = 'SET_LOAN_APPLICATION';
export const SET_LOAN_APPLICATIONS = 'SET_LOAN_APPLICATIONS';
export const SET_LOAN_OFFICERS = 'SET_LOAN_OFFICERS';
export const SET_BRANCHES = 'SET_BRANCHES';
export const SET_MESSAGE_SETTINGS = 'SET_MESSAGE_SETTINGS';
export const SET_LOAN_SETTINGS = 'SET_LOAN_SETTINGS';
export const SET_SECURE_MESSAGES = 'SET_SECURE_MESSAGES';
export const SET_SECURE_MESSAGE_THREADS = 'SET_SECURE_MESSAGE_THREADS';
export const SET_UNREAD_SECURE_MESSAGES = 'SET_UNREAD_SECURE_MESSAGES';
export const SET_UNREAD_SECURE_MESSAGE_COUNT = 'SET_UNREAD_SECURE_MESSAGE_COUNT';
export const SET_CREDIT_CARDS = 'SET_CREDIT_CARDS';
export const SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_PLANS = 'SET_PLANS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_INACTIVITY_LOGOUT = 'SET_INACTIVITY_LOGOUT';
export const SET_DEFAULT_LOAN_OFFICER = 'SET_DEFAULT_LOAN_OFFICER';
export const SET_OAUTH_CLIENTS = 'SET_OAUTH_CLIENTS';
export const SET_WEBHOOKS = 'SET_WEBHOOKS';
export const SET_WEBHOOK_LOGS = 'SET_WEBHOOK_LOGS';
export const SET_DESIGN = 'SET_DESIGN';
export const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
export const SET_LOAN_DOCUMENT_CATEGORIES = 'SET_LOAN_DOCUMENT_CATEGORIES';
export const SET_HIDDEN_MILESTONES = 'SET_HIDDEN_MILESTONES';
export const SET_INVOICES = 'SET_INVOICES';
export const SET_TICKETS = 'SET_TICKETS';
export const ADD_DONT_PROMPT_MOBILE_PHONE = 'ADD_DONT_PROMPT_MOBILE_PHONE';
export const REMOVE_DONT_PROMPT_MOBILE_PHONE = 'REMOVE_DONT_PROMPT_MOBILE_PHONE';