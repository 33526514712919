import _ from "lodash";
import moment from "moment";
import {ConfigUtil, LoanUtil} from "./index";
import {LoanApplication2009, Borrower2009} from "@jerseydev/orca-loans";

class LoanRev1Util {
  static getCompletedPercent = (loanApplication:LoanApplication2009) => {
    let percent:number;
    const loanApplicationMenu = ConfigUtil.getLoanApplicationMenu(loanApplication.__t);
    const completedSteps = LoanRev1Util.getCompletedSteps(loanApplication);
    percent = Math.round((completedSteps.length / loanApplicationMenu.length) * 100);

    return percent;
  };

  static getBorrowerTotalYearsAtAddresses = (borrower:Borrower2009) => {
    let totalYears = 0;

    if(borrower.addresses) {
      totalYears += _.sumBy(borrower.addresses, 'yearsAtLocation');
    }

    return totalYears;
  };

  static getBorrowerTotalYearsEmployed = (borrower:Borrower2009) => {
    let totalYears = 0;
    if(borrower.currentEmployment) {
      totalYears += _.sumBy(borrower.currentEmployment, 'yearsOnJob');

    }

    if(borrower.previousEmployment) {
      let previousEmploymentDays = 0;
      borrower.previousEmployment.forEach(employment => {
        const startDate = moment(employment.startDate);
        const endDate = moment(employment.endDate);
        previousEmploymentDays += endDate.diff(startDate, 'days');
        totalYears += Math.floor(previousEmploymentDays / 365);
      });
    }

    return totalYears;
  };

  static getCompletedSteps = (loanApplication:LoanApplication2009) => {
    const completedSteps = [];
    if(LoanRev1Util.isPersonalInfoCompleted(loanApplication)) {
      completedSteps.push('personal');
    }

    if(LoanRev1Util.isAddressHistoryCompleted(loanApplication)) {
      completedSteps.push('address');
    }

    if(LoanRev1Util.isLoanInfoCompleted(loanApplication)) {
      completedSteps.push('loan_info');
    }

    if(LoanRev1Util.isPropertyInfoCompleted(loanApplication)) {
      completedSteps.push('property');
    }

    if(LoanRev1Util.isEmploymentCompleted(loanApplication)) {
      completedSteps.push('employment');
    }

    if(LoanRev1Util.isMonthlyIncomeCompleted(loanApplication)) {
      completedSteps.push('income');
    }

    if(LoanRev1Util.isHousingCompleted(loanApplication)) {
      completedSteps.push('housing');
    }

    if(LoanUtil.isAssetsAndLiabilitiesCompleted(loanApplication)) {
      completedSteps.push('assets_liabilities');
    }

    if(LoanRev1Util.isRealEstateCompleted(loanApplication)) {
      completedSteps.push('real_estate');
    }

    if(LoanRev1Util.isDeclarationsCompleted(loanApplication)) {
      completedSteps.push('declarations');
    }

    if(LoanUtil.isDemographicsCompleted(loanApplication)) {
      completedSteps.push('demographics');
    }

    if(loanApplication.completed) {
      completedSteps.push('review');
    }

    return completedSteps;
  };

  static isPersonalInfoCompleted = (loanApplication:LoanApplication2009) => {
    let completed = true;

    const requiredProps = ['firstName', 'lastName', 'email', 'ssn', 'birthDate', 'yearsOfSchool', 'maritalStatus', 'militaryVeteran'];
    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      loanApplication.borrowers.forEach(borrower => {
        for(let i=0;i<requiredProps.length;i++) {
          const prop = requiredProps[i];
          if(borrower[prop as keyof Borrower2009] === null || borrower[prop as keyof Borrower2009] === undefined) {
            completed = false;
            break;
          }
        }

        if(completed && (!borrower.phoneNumbers || (borrower.phoneNumbers && borrower.phoneNumbers.length === 0))) {
          completed = false;
        }
      });
    } else {
      completed = false;
    }

    return completed;
  };

  static isAddressHistoryCompleted = (loanApplication:LoanApplication2009) => {
    let completed = true;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        const borrower = loanApplication.borrowers[i];
        if(borrower.addresses && borrower.addresses.length > 0) {
          if((LoanRev1Util.getBorrowerTotalYearsAtAddresses(borrower) < 2) || !borrower.addresses.find(a => a.isMailingAddress) || !borrower.addresses.find(a => a.current)) {
            completed = false;
            break;
          }
        } else {
          completed = false;
        }
      }
    } else {
      completed = false;
    }

    return completed;
  };

  static isLoanInfoCompleted = (loanApplication:LoanApplication2009) => {
    let completed = true;
    if(loanApplication.loan && loanApplication.loan.loanPurpose) {
      const requiredPurchaseProps = ['downPayment'];
      const requiredRefiProps = ['refinance.purpose', 'refinance.yearAcquired', 'refinance.originalCost', 'refinance.existingLiens'];
      let requiredProps = ['loanType', 'term', 'loanAmount'];
      if(loanApplication.loan.loanPurpose === 'purchase') {
        requiredProps = [...requiredProps, ...requiredPurchaseProps];
      } else {
        requiredProps = [...requiredProps, ...requiredRefiProps];
      }
      for(let i=0;i<requiredProps.length;i++) {
        const propValue = _.get(loanApplication.loan, requiredProps[i]);
        if(propValue === null || propValue === undefined) {
          completed = false;
          break;
        }
      }
    } else {
      completed = false;
    }
    return completed;
  };

  static isPropertyInfoCompleted = (loanApplication:LoanApplication2009) => {
    let completed = true;
    const requiredProps = ['property', 'property.address', 'property.address.city', 'property.address.province', 'property.occupancy'];
    for(let i=0;i<requiredProps.length;i++) {
      const propValue = _.get(loanApplication, requiredProps[i]);
      if(propValue === null || propValue === undefined || propValue === '') {
        completed = false;
        break;
      }
    }

    return completed;
  };

  static isEmploymentCompleted = (loanApplication:LoanApplication2009) => {
    let completed = true;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        const yearsEmployed = LoanRev1Util.getBorrowerTotalYearsEmployed(loanApplication.borrowers[i]);
        if(yearsEmployed < 2) {
          completed = false;
          break;
        }
      }
    } else {
      completed = false;
    }

    return completed;
  };

  static isMonthlyIncomeCompleted = (loanApplication:LoanApplication2009) => {
    let completed = true;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        if(!loanApplication.borrowers[i].monthlyIncome || (loanApplication.borrowers[i].monthlyIncome && Object.keys(loanApplication.borrowers![i].monthlyIncome!).length === 0)) {
          completed = false;
          break;
        }
      }
    } else {
      completed = false;
    }

    return completed;
  };

  static isHousingCompleted = (loanApplication:LoanApplication2009) => {
    return loanApplication.housingExpenses && Object.keys(loanApplication.housingExpenses).length > 0;
  };

  static isRealEstateCompleted = (loanApplication:LoanApplication2009) => {
    let completed = true;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        const borrower = loanApplication.borrowers[i];
        if(borrower.realEstateAssets !== null && (Array.isArray(borrower.realEstateAssets) && borrower.realEstateAssets.length === 0)) {
          completed = false;
          break;
        }
      }
    } else {
      completed = false;
    }

    return completed;
  };

  static isDeclarationsCompleted = (loanApplication:LoanApplication2009) => {
    let completed = true;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        const borrower = loanApplication.borrowers[i];
        if(!borrower.declarations || (borrower.declarations && Object.keys(borrower.declarations).length !== 12)) {
          completed = false;
          break;
        }
      }
    } else {
      completed = false;
    }

    return completed;
  };
}

export default LoanRev1Util