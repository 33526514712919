import config from "../config";
import {LoanRevision} from "../types";

class ConfigUtil {
  static getLoanApplicationMenu = (revision:LoanRevision) => {
    return config.menus.loanApplicationMenu[revision];
  };

  static getLoanApplicationMenuItem = (revision:LoanRevision, id:string) => {
    const configMenu = config.menus.loanApplicationMenu[revision];
    return configMenu.find(m => m.id === id);
  };

  static getFirstLoanApplicationMenuItem = (revision:LoanRevision) => {
    const configMenu = config.menus.loanApplicationMenu[revision];
    return configMenu ? configMenu[0] : null;
  };

  static getLastLoanApplicationMenuItem = (revision:LoanRevision) => {
    const configMenu = config.menus.loanApplicationMenu[revision];
    return configMenu ? configMenu[configMenu.length - 1] : null;
  };
}

export default ConfigUtil;