import React, {RefObject, MouseEvent, ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {Grid, Button, withStyles, Icon, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {
  ErrorList, HelperTooltip,
  MoneyInput, PercentageTextField,
  Select,
  SubmitButton,
} from "../../../components/index";
import {CalculatorUtil, ErrorUtil} from '../../../utils';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import Api from '../../../lib/Api';
import {ChartComponentProps, Doughnut} from "react-chartjs-2";
import themePalette from "../../../theme/jss/palette";
import CalculatorResults from "../components/CalculatorResults";
import {LabelNumberType, ErrorState, FixMeLater} from "../../../types";
import {AffordabilityCalculatorRequest, AffordabilityCalculatorResponse} from "@jerseydev/orca-loans";
import {CalculatorResultsProps} from "../components/CalculatorResults";
import {CalculatorResultSetProps} from "../components/CalculatorResultSet";
import {Mixpanel} from "mixpanel-browser";

const palette:FixMeLater = themePalette;

type Props = {
  mixpanel: Mixpanel,
  onSubmit?: (data:AffordabilityCalculatorResponse) => void,
  onCancel?: () => void
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  downPayment: string,
  interestRate: string,
  term: string,
  frontRatio: string,
  backRatio: string,
  totalMonthlyIncome: string,
  totalMonthlyExpenses: string,
  annualTaxes: string,
  annualInsurance: string,
  annualPMI: string
}

type State = {
  loading: boolean,
  form: Form,
  result?: CalculatorResultsProps|null,
  errors: ErrorState[],
}

class AffordabilityForm extends BaseForm<Props, State> {
  terms:LabelNumberType[] = [];
  resultsRef:RefObject<HTMLDivElement>;

  chartColors:string[] = [
    palette.info.main,
    palette.danger.main,
    palette.warning.main,
    palette.success.main,
  ];

  chartOptions = CalculatorUtil.getPieChartOptions();

  constructor(props:Props) {
    super(props);

    this.resultsRef = React.createRef();

    /*const testFormData = {
      downPayment: 10,
      interestRate: 5.8,
      term: 30,
      frontRatio: 30,
      backRatio: 36,
      totalMonthlyIncome: 8000,
      totalMonthlyExpenses: 910,
      annualTaxes: 3000,
      annualInsurance: 1500,
      annualPMI: .5
    };*/

    this.state = {
      loading: false,
      form: {
        downPayment: '',
        interestRate: '',
        term: '',
        frontRatio: '',
        backRatio: '',
        totalMonthlyIncome: '',
        totalMonthlyExpenses: '',
        annualTaxes: '',
        annualInsurance: '',
        annualPMI: ''
      },
      result: null,
      errors: [],
    };

    //this.state.form = testFormData;

    this.terms = CalculatorUtil.getSelectLoanTerms(props.intl);
  }

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    try {
      this.setState({ loading: true, result: null, errors: [] });
      const {downPayment, interestRate, term, frontRatio, backRatio, totalMonthlyIncome, totalMonthlyExpenses, annualTaxes, annualInsurance, annualPMI} = this.state.form;
      const requestData:AffordabilityCalculatorRequest = {
        downPayment: parseInt(downPayment),
        interestRate: parseFloat(interestRate),
        term: parseInt(term),
        frontRatio: parseInt(frontRatio),
        backRatio: parseInt(backRatio),
        totalMonthlyIncome: parseInt(totalMonthlyIncome),
        totalMonthlyExpenses: parseInt(totalMonthlyExpenses),
        annualTaxes: parseInt(annualTaxes),
        annualInsurance: parseInt(annualInsurance),
        annualPMI: parseFloat(annualPMI)
      }
      const result = await Api.calculateAffordability(requestData);
      this.setState({
        loading: false,
        result: {
          summary: result.data.description,
          results: [{
            amortization: result.data.amortization,
            chart: <Doughnut data={this.getChartData(result.data)} options={this.chartOptions} redraw />,
            items: this.getResultItems(result.data)
          }]
        }
      });

      this.resultsRef.current!.scrollIntoView({behavior:'smooth'});
      this.props.mixpanel.track("Mortgage Calculator", { name: "Affordability", action: "submitted" });
      if(this.props.onSubmit) {
        this.props.onSubmit(result.data);
      }
    } catch(e) {
      this.onError(e);
    }
  };

  onError = (err:any) => {
    this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
  };

  getChartData = (resultData:AffordabilityCalculatorResponse):ChartComponentProps["data"] => {
    const { intl } = this.props;

    return {
      datasets: [{
        data: [
          resultData.monthlyPrincipalAndInterest.toFixed(2),
          resultData.monthlyTaxes.toFixed(2),
          resultData.monthlyInsurance.toFixed(2),
          resultData.monthlyPMI.toFixed(2),
        ],
        backgroundColor: this.chartColors
      }],
      labels: [
        IntlFormatter.formatMessage(intl, 'monthly_principal_interest'),
        IntlFormatter.formatMessage(intl, 'monthly_taxes'),
        IntlFormatter.formatMessage(intl, 'monthly_insurance'),
        IntlFormatter.formatMessage(intl, 'monthly_pmi'),
      ]
    };
  };

  getResultItems = (result:AffordabilityCalculatorResponse) => {
    const {intl} = this.props;

    const items:CalculatorResultSetProps["items"] = [
      { color: this.chartColors[0], label: IntlFormatter.formatMessage(intl, 'monthly_principal_interest'), value: result.monthlyPrincipalAndInterest.toMoney() },
      { color: this.chartColors[1], label: IntlFormatter.formatMessage(intl, 'monthly_taxes'), value: result.monthlyTaxes.toMoney() },
      { color: this.chartColors[2], label: IntlFormatter.formatMessage(intl, 'monthly_insurance'), value: result.monthlyInsurance.toMoney() },
    ];

    if(result.monthlyPMI > 0) {
      items.push(
        { color: this.chartColors[3], label: IntlFormatter.formatMessage(intl, 'monthly_pmi'), value: result.monthlyPMI.toMoney() },
      )
    }

    items.push(
      { label: IntlFormatter.formatMessage(intl, 'home_value'), value: result.homeValue.toMoney(), align: 'right' },
      { label: IntlFormatter.formatMessage(intl, 'down_payment'), value: result.downPayment.toMoney(), align: 'right' },
      { label: IntlFormatter.formatMessage(intl, 'loan_amount'), value: result.loanAmount.toMoney(), align: 'right', size: 'large' },
    );

    return items;
  };

  render() {

    const { intl, classes, onCancel } = this.props;
    const { form, loading, errors, result } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] })}} />
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4}>
                <PercentageTextField name="downPayment"
                                     label={IntlFormatter.formatMessage(intl, 'down_payment')}
                                     value={form.downPayment}
                                     fullWidth={true}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.downPayment')}
                                     validators={['required', 'minFloat:0', 'maxFloat:99']}
                                     errorMessages={[
                                       IntlFormatter.formatMessage(intl, 'validation_required'),
                                       IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                       IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 99 })
                                     ]} />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Select name="term"
                        options={this.terms}
                        placeholder={IntlFormatter.formatMessage(intl, 'select_term')}
                        onChange={(item:LabelNumberType) => this.onItemChange(item ? item.value : '', 'form.term')}
                        value={form.term !== '' ? this.terms.find(m => m.value === parseInt(form.term)) : ''}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required')
                        ]}
                        label={IntlFormatter.formatMessage(intl, 'term')} />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <PercentageTextField name="interestRate"
                                     label={IntlFormatter.formatMessage(intl, 'interest_rate')}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.interestRate')}
                                     value={form.interestRate}
                                     fullWidth={true}
                                     validators={['required', 'minFloat:0', 'maxFloat:100']}
                                     errorMessages={[
                                       IntlFormatter.formatMessage(intl, 'validation_required'),
                                       IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                       IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                     ]} />
              </Grid>
            </Grid>
          </div>

          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3}>
                <PercentageTextField name="frontRatio"
                                     label={IntlFormatter.formatMessage(intl, 'front_ratio')}
                                     placeholder="28"
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.frontRatio')}
                                     value={form.frontRatio}
                                     fullWidth={true}
                                     helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'front_ratio_help')}
                                                                text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                                     validators={['required', 'minFloat:0', 'maxFloat:100']}
                                     errorMessages={[
                                       IntlFormatter.formatMessage(intl, 'validation_required'),
                                       IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                       IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                     ]} />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <PercentageTextField name="backRatio"
                                     label={IntlFormatter.formatMessage(intl, 'back_ratio')}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.backRatio')}
                                     value={form.backRatio}
                                     placeholder="36"
                                     fullWidth={true}
                                     helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'back_ratio_help')}
                                                                text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                                     validators={['required', 'minFloat:0', 'maxFloat:100']}
                                     errorMessages={[
                                       IntlFormatter.formatMessage(intl, 'validation_required'),
                                       IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                       IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                     ]} />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_income')}
                            value={form.totalMonthlyIncome}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.totalMonthlyIncome')}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]} />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_expenses')}
                            value={form.totalMonthlyExpenses}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.totalMonthlyExpenses')}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]} />
              </Grid>
            </Grid>
          </div>
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'annual_taxes')}
                            value={form.annualTaxes}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.annualTaxes')}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]} />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'annual_insurance')}
                            value={form.annualInsurance}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.annualInsurance')}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]} />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <PercentageTextField name="pmi"
                                     label={IntlFormatter.formatMessage(intl, 'annual_pmi')}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.annualPMI')}
                                     value={form.annualPMI}
                                     fullWidth={true}
                                     helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'pmi_help')}
                                                                text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                                     validators={['required', 'minFloat:0', 'maxFloat:100']}
                                     errorMessages={[
                                       IntlFormatter.formatMessage(intl, 'validation_required'),
                                       IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                       IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                     ]} />
              </Grid>
            </Grid>
          </div>

          <div className={classes.mt3}>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              {onCancel &&
              <Grid item>
                <Button onClick={onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}
                              size="large"
                              startIcon={<Icon>calculate</Icon>}>
                  <FormattedMessage id={result ? 'recalculate' : 'calculate'} />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>

        <div ref={this.resultsRef}>
          {result &&
            <div className={classes.mt3}>
              <CalculatorResults summary={result.summary}
                                 results={result.results} />

            </div>
          }
        </div>
      </div>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(AffordabilityForm));
