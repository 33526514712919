import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  default: {

  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText
  },
  danger: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '20px',
    paddingRight: '5px'
  }
});

export default styles;
