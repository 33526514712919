import React from 'react';
import {
  Typography, WithStyles,
  withStyles,
} from "@material-ui/core";
import styles from "../../../theme/jss/layouts/pageStyles";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Plaid} from "./index";
import {ErrorUtil, IntegrationUtil} from "../../../utils";
import Api from '../../../lib/Api';
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {
  Borrower,
  LoanApplicationUpdateRequest,
  PlaidAuthRequest
} from "@jerseydev/orca-loans";
import {ActionProps, ErrorState, FixMeLater, ReduxIntegrations} from "../../../types";
import IntlFormatter from "../../../intl";

type Props = {
  borrower: Borrower,
  onClick?: () => void,
  onSuccess?: (data:FixMeLater) => void,
  onExit?: () => void,
  onEvent?: () => void,
  integrations: ReduxIntegrations,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  loading: boolean,
  authData?: any,
  errors: ErrorState[]
}

type IntegrationMap = {
  plaid: () => void
}

class AssetsIntegration extends React.Component<Props, State> {
  integrationMap:IntegrationMap;

  constructor(props:Props) {
    super(props);

    this.integrationMap = {
      plaid: this.renderPlaid
    };

    this.state = {
      loading: true,
      errors: []
    };
  }

  componentDidMount = async () => {
    try {
      const { borrower } = this.props;
      if(borrower.firstName && borrower.lastName && borrower.ssn) {
        const plaidData:PlaidAuthRequest = {
          id: borrower._id,
          firstName: borrower.firstName,
          middleName: borrower.middleName,
          lastName: borrower.lastName,
          ssn: borrower.ssn
        };

        const authResult = await Api.authorizeAssets(plaidData);
        this.setState({ loading: false, authData: authResult.data });

      } else {
        this.setState({ loading: false, errors: [{message:IntlFormatter.formatMessage(this.props.intl, 'asset_integration_missing_info')}] });
      }
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  renderPlaid = () => {
    const { borrower } = this.props;
    const { authData } = this.state;

    return (
      <Plaid
        token={authData.link_token}
        onClick={this.props.onClick}
        onSuccess={this.props.onSuccess}
        onExit={this.props.onExit}
        onEvent={this.props.onEvent}
        disabled={!(borrower.firstName && borrower.lastName && borrower.ssn)}>
        <FormattedMessage id="connect_to_your_bank_account" />
      </Plaid>
    )
  };

  render() {

    const { integrations, borrower, classes } = this.props;
    const { loading } = this.state;

    if(loading || !IntegrationUtil.hasIntegration(integrations.data, 'assets')) {
      return null;
    }

    return (
      <div>
        {this.integrationMap[integrations.data.assets!.provider]()}
        {!(borrower.firstName && borrower.lastName && borrower.ssn) &&
          <Typography component="div" variant="caption" color="error" className={classes.mt1}>
            <FormattedMessage id="asset_borrower_info_error_text" />
          </Typography>
        }
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    integrations: state.integrations
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, loanApplication:LoanApplicationUpdateRequest) {
    return dispatch(updateLoanApplication(id, loanApplication));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(AssetsIntegration)));

