import React, {MouseEvent, ReactNode, useState} from 'react';
import {Card, Typography, Avatar, IconButton, Icon} from '@material-ui/core';
import {useStyles} from "../theme/jss/components/personCardStyles";
import {Mixpanel} from "mixpanel-browser";
import {SecureMessage} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ReduxState} from "../data/initialState";
import {Alert, Dialog, Fab, Snackbar} from "./index";
import IntlFormatter from "../intl";
import {SecureMessageThreadForm} from "../forms";
import {UserUtil} from "../utils";

type DefaultProps = {
  variant: 'normal'|'condensed'
}

export type PersonCardProps = {
  mixpanel: Mixpanel,
  person: {
    firstName: string,
    lastName: string,
    email: string,
    deleted?: boolean,
    userId?:string,
    avatar?: string|null,
  },
  elevation?: number,
  onMessageSubmit?: (data:AxiosResponse<SecureMessage>) => void,
  children?: (ReactNode | Element)[]|(ReactNode | Element),
  variant?: 'normal'|'condensed',
  action?: React.ReactNode,
  orientation?: 'vertical'|'horizontal',
  title?: string
}

const PersonCard = (props:PersonCardProps) => {
  const classes = useStyles();
  const intl = useIntl();
  const {person, elevation, mixpanel, action, variant, orientation, children, title} = props;
  const loggedInUser = useSelector((state:ReduxState) => state.user);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);

  const onMessageDialogClick = (event:MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setMessageDialogOpen(true);
  };

  const onMessageDialogCancel = (event:MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setMessageDialogOpen(false);
  };

  const onMessageDialogSubmit = (event:MouseEvent, data:AxiosResponse<SecureMessage>) => {
    setMessageDialogOpen(false);
    setSnackbarOpen(true);
    if(props.onMessageSubmit) {
      props.onMessageSubmit(data);
    }
  };

  const onSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Snackbar open={snackbarOpen}
                variant="success"
                onClose={onSnackbarClose}
                message={IntlFormatter.formatMessage(intl, 'message_sent')}
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={onSnackbarClose}>
                    <Icon>close</Icon>
                  </IconButton>
                ]} />

      <Dialog open={messageDialogOpen}
              icon={<Icon>message</Icon>}
              title={IntlFormatter.formatMessage(intl, 'send_message')}
              color="primaryAlt"
              onClose={onMessageDialogCancel}
              fullWidth={true}
              maxWidth="sm">

        {person.userId &&
        <SecureMessageThreadForm to={{_id: person.userId, ...person}}
                                 mixpanel={mixpanel}
                                 onCancel={onMessageDialogCancel}
                                 onSubmit={onMessageDialogSubmit} />
        }
      </Dialog>

      <Card className={orientation === 'vertical' ? classes.vertical : classes.horizontal}
            elevation={elevation}>
        <div className={classes.avatarContainer}>
          {person.avatar &&
          <Avatar src={person.avatar} className={classes.avatar} />
          }

          {!person.avatar &&
          <Avatar className={classes.avatar}>{UserUtil.getInitials(person)}</Avatar>
          }
        </div>
        <div className={classes.content}>
          <div className={classes.info}>
            <div className={classes.name}>
              <Typography variant="h4">
                {person.firstName} {person.lastName}
              </Typography>
            </div>

            {person.deleted &&
            <div className={classes.archivedUser}>
              <Alert severity="warning">
                <FormattedMessage id="archived_user" />
              </Alert>
            </div>
            }

            {title &&
            <Typography variant="body1" color="inherit" className={classes.title}>
              {title}
            </Typography>
            }

            <Typography variant="body1" color="inherit">
              {person.email}
            </Typography>

            <div>
              {children}
            </div>
          </div>

          <div className={classes.actions}>
            {!person.deleted && person.userId && (loggedInUser && loggedInUser.data && loggedInUser.data._id !== person.userId) &&
            <Fab color="primary" onClick={onMessageDialogClick}>
              <Icon>mail</Icon>
            </Fab>
            }
            {(action && variant === 'normal') &&
            <div className={classes.action}>{action}</div>
            }
          </div>
        </div>
      </Card>
    </div>
  )
}

const defaultProps:DefaultProps = {
  variant: 'normal'
};

PersonCard.defaultProps = defaultProps;

export default PersonCard;
