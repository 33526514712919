import React, {Component} from 'react';
import {Grid, Link, WithStyles, withStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import pageStyles from "../theme/jss/layouts/pageStyles";
import clsx from "clsx";
import {ReduxState} from "../data/initialState";
import {ReduxDesign} from "../types";

type Props = {
  design: ReduxDesign
} & WithStyles<typeof pageStyles>

class UrlFooter extends Component<Props> {

  hasFooterUrl = () => {
    const { design } = this.props;
    return (design.data && (design.data.privacyPolicyUrl || design.data.termsOfUseUrl || design.data.supportUrl));
  };

  render() {
    const { classes, design } = this.props;
    const hasFooterUrl = this.hasFooterUrl();

    if(!hasFooterUrl) {
      return null;
    }

    return (
      <div className={clsx(classes.mt4)}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          {design.data.privacyPolicyUrl &&
          <Grid item>
            <Link href={design.data.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="privacy_policy"/>
            </Link>
          </Grid>
          }
          {design.data.termsOfUseUrl &&
          <Grid item>
            <Link href={design.data.termsOfUseUrl} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="terms_of_use"/>
            </Link>
          </Grid>
          }
          {design.data.supportUrl &&
          <Grid item>
            <Link href={design.data.supportUrl} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="support"/>
            </Link>
          </Grid>
          }
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    design: state.design
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(UrlFooter));
