import React from 'react';
import {Tooltip} from "./index";
import IntlFormatter from "../intl";
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {Icon, withStyles, WithStyles} from "@material-ui/core";
import styles from "../theme/jss/components/priorityIndicatorStyles";

type Priority = 'low'|'medium'|'high'|'urgent';
type PriorityData = {
  icon: string,
  color: string
}
type Props = {
  priority: Priority
} & WrappedComponentProps
  & WithStyles<typeof styles>

type PriorityMap = Record<Priority, PriorityData>

const PriorityIndicator = (props:Props) => {
  const {intl, classes, priority} = props;
  const priorityMap:PriorityMap = {
    low: {
      icon: 'arrow_downward',
      color: classes.low
    },
    medium: {
      icon: 'remove',
      color: classes.medium
    },
    high: {
      icon: 'arrow_upward',
      color: classes.high
    },
    urgent: {
      icon: 'priority_high',
      color: classes.urgent
    }
  };

  const priorityData:PriorityData = priorityMap[priority];

  return (
    <Tooltip title={IntlFormatter.formatMessage(intl, priority)}>
      <Icon className={priorityData.color}>{priorityData.icon}</Icon>
    </Tooltip>
  )
};

export default withStyles(styles, { withTheme: true })(injectIntl(PriorityIndicator));