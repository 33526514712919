import React from "react";
import Rev1 from "./BorrowerMonthlyIncomeSummaryRev1";
import Rev2 from "./BorrowerMonthlyIncomeSummaryRev2";
import {
  LoanApplication2009,
  Borrower2009MonthlyIncome, Borrower2009OtherIncome,
  LoanApplication, BorrowerOtherIncome
} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  monthlyIncome?: Borrower2009MonthlyIncome|null,
  otherIncome?: Borrower2009OtherIncome[]|BorrowerOtherIncome[]|null
}

function BorrowerMonthlyIncomeSummary(props:Props) {

  const { loanApplication, monthlyIncome, otherIncome } = props;

  if(loanApplication.__t === 'Rev1') {
    return <Rev1 monthlyIncome={monthlyIncome} otherIncome={otherIncome as Borrower2009OtherIncome[]}  />
  }

  return <Rev2 otherIncome={otherIncome as BorrowerOtherIncome[]} />
}

export default BorrowerMonthlyIncomeSummary;