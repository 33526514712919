import React, {ChangeEvent, MouseEvent} from 'react';
import Form from './Form';
import {
  Grid,
  Button,
  withStyles, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../intl';
import BaseForm from "../forms/BaseForm";
import {ErrorList, SubmitButton, TextField, Alert} from "../components";
import _ from 'lodash';
import {ErrorUtil} from "../utils";
import pageStyles from "../theme/jss/layouts/pageStyles";
import Api from "../lib/Api";
import {Mixpanel} from "mixpanel-browser";
import {User} from "@jerseydev/orca-loans";
import {ErrorState} from "../types";
import {AxiosResponse} from "axios";

type Props = {
  mixpanel: Mixpanel,
  onSubmit: (data:AxiosResponse<User>) => void,
  onCancel: () => void,
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  email: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class ResendApplicantInviteForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        email: ''
      },
      errors: []
    };

  }

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });

      const result = await Api.resendBorrowerInvite({ email: this.state.form.email });
      this.props.mixpanel.track("Borrower Invite", { email: this.state.form.email, initial: false });
      this.setState({ loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <div>
        <div className={classes.mb2}>
          <Alert severity="warning">
            <FormattedMessage id="resend_borrower_invite_warning_text" />
          </Alert>
        </div>


        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); }}/>

          <TextField name="email"
                     label={IntlFormatter.formatMessage(intl, 'email')}
                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.email')}
                     value={form.email}
                     fullWidth={true}
                     validators={['required', 'isEmail']}
                     errorMessages={[
                       IntlFormatter.formatMessage(intl, 'validation_required'),
                       IntlFormatter.formatMessage(intl, 'validation_email_invalid')
                     ]} />

          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}



export default withStyles(pageStyles, { withTheme: true })(injectIntl(ResendApplicantInviteForm));
