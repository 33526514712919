import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Grid, Typography, WithStyles, withStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {ChangePasswordForm} from '../forms';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {Page} from '../../../layouts';
import {AppLogo, UrlFooter, Well} from "../../../components";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import clsx from "clsx";
import {Mixpanel} from "mixpanel-browser";
import {ReduxUtil} from "../../../utils";
import {ReduxUser} from "../../../types";
import {ReduxState} from "../../../data/initialState";

type Props = {
  mixpanel: Mixpanel,
  user: ReduxUser
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  loading: boolean,
  redirectTo?: string
}

class ChangePasswordPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount = () => {
    const { user } = this.props;
    if(ReduxUtil.hasData(user) && !user.data.forcePasswordReset) {
      this.setState({ redirectTo: '/account' });
    }
  };

  onSubmit = () => {
    this.setState({ redirectTo: '/dashboard' });
  };

  onCancel = () => {
    this.setState({ redirectTo: '/login' });
  };

  render() {
    const { intl, classes, mixpanel } = this.props;
    const { loading, redirectTo } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <Page pageTitle={IntlFormatter.formatMessage(intl, 'change_password')} loading={loading}>
        <Grid container>
          <Grid item md={6}>
            <div className={classes.houseBg1}>
              <div className={classes.bgOverlay}>

              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className={classes.p4}>
              <div className={clsx(classes.mb3, classes.center)}>
                <AppLogo color="dark"/>
              </div>
              <div className={clsx(classes.mt3, classes.textCenter)}>
                <Typography variant="h2" color="inherit">
                  <FormattedMessage id="change_password" />
                </Typography>
              </div>

              <div className={classes.p3}>
                <div className={classes.mb2}>
                  <Well color="warn">
                    <Typography variant="body1">
                      <FormattedMessage id="change_password_page_text" />
                    </Typography>
                  </Well>
                </div>
                <ChangePasswordForm mixpanel={mixpanel}

                                    onSubmit={this.onSubmit} />
              </div>
              <UrlFooter/>
            </div>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(ChangePasswordPage)));
