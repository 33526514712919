import React from 'react';
import {ConfigUtil} from "../../../utils";
import {ConfigMenuItem, ReduxLoanSettings} from "../../../types";
import {RouteComponentProps} from "react-router-dom";

type Props = {
  settings: ReduxLoanSettings,
} & RouteComponentProps

class BaseLoanApplicationPage<P, S> extends React.Component<Props & P, S> {

  previousMenuItem:ConfigMenuItem;
  currentMenuItem:ConfigMenuItem;
  nextMenuItem:ConfigMenuItem;

  constructor(props:Props & P) {
    super(props);

    const {settings} = props;
    const menu = ConfigUtil.getLoanApplicationMenu(settings.data.loanRevision);
    const menuIndex = menu.findIndex(i => i.to === props.location.pathname);
    this.currentMenuItem = menu[menuIndex];

    if(menuIndex > 0) {
      this.previousMenuItem = menu[menuIndex - 1];
    }

    if(menuIndex < menu.length - 1) {
      this.nextMenuItem = menu[menuIndex + 1];
    }
  }
}

export default BaseLoanApplicationPage;
