import React, {Component, ErrorInfo} from 'react';
import {Button, Paper, Typography, WithStyles, withStyles} from "@material-ui/core";
import styles from "../theme/jss/layouts/pageStyles";
import clsx from "clsx";
import {AppLogo} from "./index";
import {FormattedMessage} from "react-intl";
import * as Sentry from '@sentry/browser';

type Props = {
  children?: React.ReactNode
} & WithStyles<typeof styles>

type State = {
  error: string,
  hasError: boolean,
  errorInfo?: ErrorInfo,
  eventId?: string
}

class ErrorBoundary extends Component<Props, State> {

  constructor(props:Props) {
    super(props);
    this.state = {
      error: '',
      hasError: false,
    };
  }

  static getDerivedStateFromError(error:Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error:Error, errorInfo:ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo as any);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    });
  }

  render() {
    const {classes} = this.props;
    const { hasError, errorInfo } = this.state;
    if (hasError) {
      return (
        <div className={classes.houseBg1}>
          <div className={classes.bgOverlay}>
            <div className={classes.center}>
              <div className={classes.mt5}>
                <Paper elevation={2}>
                  <div className={clsx(classes.p3, classes.center)}>
                    <AppLogo color="dark" />
                  </div>
                  <div className={classes.textCenter}>
                    <Typography variant="h3" color="inherit">
                      <FormattedMessage id="error_occurred" />
                    </Typography>
                  </div>

                  <div className={classes.p3}>
                    <div>
                      <Typography variant="body1">
                        <FormattedMessage id="error_occurred_text" />
                      </Typography>
                      <div className={clsx(classes.textCenter, classes.mt2)}>
                        <Button variant="contained" color="primary" onClick={() => {window.location.reload();}}>
                          <FormattedMessage id="refresh_page"/>
                        </Button>
                      </div>
                    </div>

                    {process.env.NODE_ENV === 'development' &&
                    <div className={classes.mt2}>
                      <details className="error-details">
                        <summary>Click for error details</summary>
                        {errorInfo && errorInfo.componentStack.toString()}
                      </details>
                    </div>
                    }
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withStyles(styles, { withTheme: true })(ErrorBoundary);