import React, {MouseEvent, useState} from "react";
import {LoanApplication, LoanApplication2009, LoanApplicationPrice} from "@jerseydev/orca-loans";
import {
  Divider,
  Grid,
  Icon,
  IconButton,
  ListItem,
  ListItemIcon, ListItemSecondaryAction, ListItemText, Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@material-ui/core";
import IntlFormatter from "../../../intl";
import {FormattedMessage, useIntl} from "react-intl";
import {ErrorUtil} from "../../../utils";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {useDispatch} from "react-redux";
import {useStyles} from "../../../theme/jss/layouts/pageStyles";
import _ from "lodash";
import {DialogState, SnackbarState} from "../../../types";
import {DeleteDialog, Snackbar} from "../../../components";
import {updateLoanApplicationPricing, deleteLoanApplicationPricing} from "../../../actions/loanApplication";

type Props = {
  loanApplication:LoanApplication|LoanApplication2009,
  price: LoanApplicationPrice,
  isBorrower: boolean
}

const PricingScenarioListItem = (props:Props) => {
  const {loanApplication, price, isBorrower} = props;
  const intl = useIntl();
  const classes = useStyles();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [loading, setLoading] = useState<boolean>();
  const [snackbar, setSnackbar] = useState<SnackbarState>({open:false, message: '', variant: 'success'});
  const [deleteDialog, setDeleteDialog] = useState<DialogState>({open: false, loading: false, errors: []});
  const [menuEl, setMenuEl] = useState<EventTarget & Element|null>(null);

  const onShareScenarioPress = async () => {
    try {
      setMenuEl(null);
      setLoading(true);
      await dispatch(updateLoanApplicationPricing(loanApplication._id, price._id, { sharedWithBorrower: !price.sharedWithBorrower })).send();
      setLoading(false);
      setSnackbar({
        open: true,
        variant: 'success',
        message: IntlFormatter.formatMessage(intl, !price.sharedWithBorrower ? 'pricing_scenario_shared' : 'pricing_scenario_not_shared')
      });
    } catch (e) {
      setLoading(false);
      setSnackbar({
        open: true,
        variant: 'danger',
        message: IntlFormatter.formatMessage(intl, 'error_occurred')
      });
    }

  };

  const onDeletePricingClick = () => {
    const dialog = _.cloneDeep(deleteDialog);
    dialog.open = true;
    setDeleteDialog(dialog);
    setMenuEl(null);
  };

  const onDeletePricingCancel = () => {
    setDeleteDialog({
      open: false,
      loading: false,
      errors: []
    });
  };

  const onDeletePricingConfirm = async () => {
    try {
      const dialog = _.cloneDeep(deleteDialog);
      dialog.loading = true;
      setDeleteDialog(dialog);

      await dispatch(deleteLoanApplicationPricing(loanApplication._id, price._id)).send();
      setDeleteDialog({
        open: false,
        loading: false,
        errors: []
      });
    } catch (e) {
      setDeleteDialog({
        open: true,
        loading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  const onSnackbarClose = () => {
    setSnackbar({
      open:false,
      message: '',
      variant: snackbar.variant
    })
  };

  const onMenuClick = (event:MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setMenuEl(null);
  };

  return (
    <div>
      <Snackbar open={snackbar.open}
                variant={snackbar.variant}
                onClose={onSnackbarClose}
                message={snackbar.message}
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={onSnackbarClose}>
                    <Icon>close</Icon>
                  </IconButton>
                ]} />

      <DeleteDialog open={deleteDialog.open}
                    title={IntlFormatter.formatMessage(intl, 'delete_pricing_scenario')}
                    item={price.name}
                    loading={deleteDialog.loading}
                    errors={deleteDialog.errors}
                    onCancel={onDeletePricingCancel}
                    onSubmit={onDeletePricingConfirm} />

      <Menu anchorEl={menuEl}
            open={Boolean(menuEl)}
            onClose={onMenuClose}>
        <MenuItem onClick={onShareScenarioPress} disabled={loading}>
          <ListItemIcon>
            <Icon>share</Icon>
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id={price.sharedWithBorrower ? 'hide_from_borrower': 'share_with_borrower'} />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={onDeletePricingClick}>
          <ListItemIcon>
            <Icon>cancel</Icon>
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="delete"/>
          </ListItemText>
        </MenuItem>
      </Menu>

      <ListItem style={{ display: 'block'}}>
        <div>
          <div className={classes.rowCenter}>
            <Typography variant="subtitle1">
              {price.name}
            </Typography>
            {(!isBorrower && price.sharedWithBorrower) &&
              <div className={classes.ml2}>
                <Tooltip title={IntlFormatter.formatMessage(intl, 'shared_with_borrower')}>
                  <Icon fontSize="small" className={classes.success}>share</Icon>
                </Tooltip>
              </div>
            }
          </div>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="caption">
                <FormattedMessage id="rate" />: {price.rate}%
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <FormattedMessage id="apr" />: {price.apr}%
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <FormattedMessage id="P&I" />: {price.principalAndInterest.toMoney()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <FormattedMessage id="price" />: {price.price}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {price.closingCost !== undefined &&
            <Grid item>
              <Typography variant="caption">
                <FormattedMessage id="closing_cost"/>: {price.closingCost.toMoney()}
              </Typography>
            </Grid>
            }
            {price.rebate !== undefined &&
            <Grid item>
              <Typography variant="caption">
                <FormattedMessage id="discount_rebate"/>: {price.rebate.toMoney()}
              </Typography>
            </Grid>
            }
          </Grid>
        </div>
        {(!isBorrower && loanApplication.status !== 'closed') &&
          <ListItemSecondaryAction>
            <IconButton onClick={onMenuClick}>
              <Icon>more_vert</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        }
      </ListItem>
      <Divider />
    </div>
  )
};

export default PricingScenarioListItem;