import React, {ChangeEvent, MouseEvent} from 'react';
import Form from './Form';
import {
  Grid,
  Button,
  IconButton,
  Icon,
  withStyles, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../intl';
import BaseForm from "./BaseForm";
import {
  Alert,
  AutoComplete,
  Dropzone,
  ErrorList, PersonReference,
  SubmitButton,
  TextField,
  UserAvatar
} from "../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {ErrorUtil, UserUtil} from "../utils";
import {addSecureMessage} from '../actions/secureMessages';
import {addSecureMessageThread, addSecureMessageAttachment} from '../actions/secureMessageThreads';
import pageStyles from "../theme/jss/layouts/pageStyles";
import Api from "../lib/Api";
import {
  SecureMessage,
  SecureMessageThreadAttachmentRequest,
  SecureMessageRequest,
  UserContact,
  SecureMessageThreadRequest, User, UserLean
} from "@jerseydev/orca-loans";
import {ActionResponse, FixMeLater, ReduxUser, ErrorState, FileWithPreview} from "../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../data/initialState";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type To = {
  _id: string,
  firstName: string,
  lastName: string,
  email: string,
  additionalProps?: User["additionalProps"]|UserLean["additionalProps"]
}

type Props = {
  mixpanel: Mixpanel,
  to?: To|null,
  secureMessage?: SecureMessage,
  onSubmit: (event:MouseEvent, data:AxiosResponse<SecureMessage>) => void,
  onCancel?: (event:MouseEvent) => void,
  user: ReduxUser,
  addSecureMessageThread: (data:SecureMessageThreadRequest) => ActionResponse,
  addSecureMessage: (threadId:string, data:SecureMessageRequest) => ActionResponse,
  addSecureMessageAttachment: (id:string, attachment:SecureMessageThreadAttachmentRequest) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  to: To|null,
  subject: string,
  message: string,
  attachments: FileWithPreview[]
}

type State = {
  loading: boolean,
  form: Form,
  userSearchCriteria: {
    searchText: string,
    ids: string[]
  },
  toClearable: boolean,
  errors: ErrorState[],
  contacts: (User|UserContact)[],
  noContacts: boolean
}

class SecureMessageThreadForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    let form:Form = {
      to: null,
      subject: '',
      message: '',
      attachments: []
    };

    if(props.secureMessage) {
      form = _.merge(form, _.cloneDeep(props.secureMessage));
    }

    if(props.to) {
      form.to = _.cloneDeep(props.to);
    }

    this.state = {
      loading: true,
      form,
      userSearchCriteria: {
        searchText: '',
        ids: []
      },
      toClearable: !(props.secureMessage),
      errors: [],
      contacts: [],
      noContacts: false
    };

    if(!this.state.form.subject) {
      this.state.form.subject = '';
    }

    if(!this.state.form.message) {
      this.state.form.message = '';
    }

    if(!this.state.form.attachments) {
      this.state.form.attachments = [];
    }
  }

  componentDidMount = async () => {
    try {
      const contacts = await this.searchContacts('');
      const state:FixMeLater = {
        loading: false,
        contacts: _.cloneDeep(contacts),
        form: _.cloneDeep(this.state.form),
        noContacts: contacts.length === 0
      };

      if(state.contacts!.length === 1) {
        state.form!.to = "user" in state.contacts[0] ? state.contacts[0].user : state.contacts[0];
        state.toClearable = false;
      }

      this.setState(state);
    } catch(e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  searchContacts = async (searchText:string):Promise<(User|UserContact)[]> => {
    if(UserUtil.isBorrower(this.props.user.data)) {
      const result = await Api.searchProfileContacts({ searchText });
      return result.data;
    }

    const result = await Api.searchUsers({ searchText });
    result.data = result.data.filter(u => u._id !== this.props.user.data._id);
    return result.data;
  };

  setTo = (user:User|UserContact) => {
    const form = _.cloneDeep(this.state.form);
    const {_id, firstName, lastName, email} = user;
    form.to = {
      _id,
      firstName,
      lastName,
      email
    };
    if("additionalProps" in user) {
      form.to!.additionalProps = user.additionalProps;
    }
    this.setState({ form });
  };

  removeTo = () => {
    const form = _.cloneDeep(this.state.form);
    form.to = null;
    this.setState({ form });
  };

  onAddAttachments = async (files:FileWithPreview[]) => {
    const form = _.cloneDeep(this.state.form);
    for(let file of files) {
      form.attachments.push(file);
    }
    this.setState({ form, errors: [] });
  };

  onDeleteAttachmentClick = (index:number) => {
    const form = _.cloneDeep(this.state.form);
    form.attachments.splice(index, 1);
    this.setState({ form });
  };

  onSubmit = async (event:MouseEvent) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      this.setState({ loading: true, errors: [] });
      const {to, subject, message, attachments} = this.state.form;
      const threadRequestData:SecureMessageThreadRequest = {
        subscribers: [to!._id],
        subject
      };

      const threadResult = await this.props.addSecureMessageThread(threadRequestData).send();
      const messageResult = await this.props.addSecureMessage(threadResult.data._id, {message}).send();
      this.props.mixpanel.track("Secure message added");

      if(attachments && attachments.length > 0) {
        for(let attachment of attachments) {
          await this.props.addSecureMessageAttachment(threadResult.data._id, {attachment}).send();
        }
        this.props.mixpanel.track("Secure message attachment added");
      }
      this.setState({ loading: false }, () => {
        this.props.onSubmit(event, _.cloneDeep(messageResult));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes } = this.props;
    const { form, loading, errors, toClearable, noContacts } = this.state;

    if(noContacts) {
      return (
        <Alert severity="warning">
          <FormattedMessage id="no_contacts_alert" />
        </Alert>
      )
    }

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); }}/>

          <div className={classes.mb2}>
            {form.to &&
              <div className={classes.rowCenter}>
                <div className={classes.mr2}>
                  <FormattedMessage id="to" />:
                </div>
                <PersonReference person={form.to}
                                 subtitle={form.to.email}
                                 popoverEnabled={false} />
                {toClearable &&
                  <IconButton onClick={this.removeTo} size="small">
                    <Icon>cancel</Icon>
                  </IconButton>
                }
              </div>
            }
            {!form.to &&
              <AutoComplete value={form.to}
                            getOptionLabel={(item:User|UserContact) => {
                              return item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : item.email;
                            }}
                            renderOption={(item:User|UserContact) => {
                              const name = item.firstName && item.lastName ? `${item.firstName} ${item.lastName} (${item.email})` : item.email;
                              const user:User = "user" in item ? (item as UserContact).user as any : item as User;
                              return (
                                <div className={classes.rowCenter}>
                                  <UserAvatar user={user} />
                                  <div className={classes.ml1}>
                                    <Typography>{name}</Typography>
                                    {user.additionalProps && user.additionalProps.branch && user.additionalProps.branch.name &&
                                      <Typography variant="caption">{user.additionalProps.branch.name}</Typography>
                                    }
                                  </div>
                                </div>
                              )
                            }}
                            getOptionSelected={(option:User|UserContact, value:User|UserContact) => {
                              return "user" in option ? (option as UserContact).user!._id === value._id : option._id === value._id;
                            }}
                            onChange={this.setTo}
                            onTextChange={this.searchContacts}
                            placeholder={IntlFormatter.formatMessage(intl, 'to')}
                            openOnFocus={true}
                            debounce={500}/>

            }
          </div>

          <div className={classes.mb2}>
            <TextField name="subject"
                       label={IntlFormatter.formatMessage(intl, 'subject')}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.subject')}
                       value={form.subject}
                       fullWidth={true}
                       helperText={IntlFormatter.formatMessage(intl, 'do_not_include_sensitive_information_in_subject')}
                       validators={['required']}
                       errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
          </div>
          <div className={classes.mb2}>
            <TextField name="message"
                           label={IntlFormatter.formatMessage(intl, 'message')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.message')}
                           value={form.message}
                           fullWidth={true}
                           multiline={true}
                           rows={3}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
          </div>
          <div className={classes.mb2}>
            <Dropzone multiple={true}
                      preview={false}
                      condensed={true}
                      onAdd={this.onAddAttachments} />
          </div>
          {form.attachments.length > 0 &&
          <List>
            {form.attachments.map((a,i) => {
              return (
                <ListItem key={i}>
                  <ListItemText primary={a.name} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => this.onDeleteAttachmentClick(i)} size="small">
                      <Icon>cancel</Icon>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
          }
          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading} disabled={!form.to}>
                  <FormattedMessage id="send" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addSecureMessage(threadId:string, data:SecureMessageRequest) {
    return dispatch(addSecureMessage(threadId, data));
  },
  addSecureMessageThread(data:SecureMessageThreadRequest) {
    return dispatch(addSecureMessageThread(data));
  },
  addSecureMessageAttachment(id:string, attachment:SecureMessageThreadAttachmentRequest) {
    return dispatch(addSecureMessageAttachment(id, attachment));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(SecureMessageThreadForm)));
