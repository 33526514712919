import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";
import {hexToRgb} from "../index";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  slider: {
    width: 75,
    marginRight: theme.spacing(1)
  },
  dropzoneContainer: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  avatarEditContainerActive: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.success.light) + ", .5)",
    border: `dotted 2px ${theme.palette.success.light}`,
    padding: theme.spacing(1),
    display: 'inline-block',
    cursor: 'pointer',
    position: 'relative'
  },
  avatarEditContainer: {
    border: `dotted 2px ${theme.palette.neutral!.shades![4]}`,
    borderRadius: 2,
    padding: theme.spacing(1),
    display: 'inline-block',
    cursor: 'pointer',
    position: 'relative'
  },
  avatarEditContainerRejected: {
    border: `dotted 2px ${theme.palette.danger.main}`,
    backgroundColor: "rgba(" + hexToRgb(theme.palette.danger.light) + ", .5)",
    borderRadius: 2,
    padding: theme.spacing(1),
    display: 'inline-block',
    cursor: 'pointer',
    position: 'relative'
  },
  avatarEditIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10
  }
});

export default styles;
