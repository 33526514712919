import {Integrations, IntegrationType} from "../types";

export default class IntegrationUtil {
  static hasIntegration = (integrations:Integrations, type:IntegrationType) => {
    let hasIntegration = false;
    if(integrations) {
      hasIntegration = !!integrations[type as keyof Integrations];
    }

    return hasIntegration;
  };
}
