import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import PersonAvatar from "./PersonAvatar";
import {Person} from "../types";

type DefaultProps = {
  popoverEnabled: boolean,
}

type Props = {
  person: Person,
  subtitle?: string,
  size?: 'small'|'normal'|'large'|'extraLarge'
} & Partial<DefaultProps>

const PersonReference = (props: Props) => {
  const { person, subtitle, popoverEnabled, size } = props;

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <PersonAvatar person={person}
                      popoverEnabled={popoverEnabled}
                      size={size} />
      </Grid>
      <Grid item>
        <Typography variant="body1">
          <b>{person.firstName} {person.lastName}</b>
        </Typography>
        {subtitle &&
          <div>
            <Typography variant="caption">{subtitle}</Typography>
          </div>
        }
      </Grid>
    </Grid>
  );
}

const defaultProps:DefaultProps = {
  popoverEnabled: true
}

PersonReference.defaultProps = defaultProps;

export default PersonReference;
