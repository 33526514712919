import React, {Component} from 'react';
import {Typography, Paper, withStyles, Button, WithStyles} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {Page} from '../../../layouts';
import {AppLogo, UrlFooter, Alert} from "../../../components";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import clsx from "clsx";
import {Link} from "react-router-dom";

type Props = WrappedComponentProps
  & WithStyles<typeof pageStyles>

class AppNotFoundPage extends Component<Props> {
  render() {
    const { intl, classes } = this.props;

    return (
      <Page pageTitle={IntlFormatter.formatMessage(intl, 'error')}
            loading={false}>
        <div className={classes.houseBg1}>
          <div className={classes.bgOverlay}>
            <div className={classes.center}>
              <div className={classes.mt5}>
                <Paper elevation={2}>
                  <div className={clsx(classes.p3, classes.center)}>
                    <AppLogo color="dark" />
                  </div>
                  <div className={classes.textCenter}>
                    <Typography variant="h3" color="inherit">
                      <FormattedMessage id="error" />
                    </Typography>
                  </div>
                  <div className={classes.p3}>
                    <Alert severity="warning">
                      <Typography variant="body1">
                        <FormattedMessage id="general_error_message" />
                      </Typography>
                    </Alert>
                    <div className={clsx(classes.mt2, classes.textCenter)}>
                      <Button color="primary" variant="contained" component={Link} to="/dashboard">
                        <FormattedMessage id="go_to_home_page" />
                      </Button>
                    </div>
                  </div>
                  <div className={classes.pb3}>
                    <UrlFooter/>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(AppNotFoundPage));

