import {createStyles} from "@material-ui/core";

const styles = () => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  select: {
    minWidth: 200
  },
  icon: {
    transition: "all .3s",
    transform: 'rotate(180deg)'
  },
  descending: {
    transform: 'rotate(0deg)'
  }
});

export default styles;
