import {defaultRadius} from "../index";
import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  lightLogoPreview: {
    backgroundColor: theme.palette.neutral.light,
    borderRadius: defaultRadius
  }
});

export default styles;
