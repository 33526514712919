import React from 'react';
import {
  Typography,
  Link, Grid, withStyles, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {BorrowerMonthlyIncomeSummary, SubTitle, Well} from "../index";
import {Link as RouterLink} from "react-router-dom";
import pageStyles from "../../theme/jss/layouts/pageStyles";
import {LoanApplication2009} from "@jerseydev/orca-loans";
import IntlFormatter from "../../intl";

type Props = {
  loanApplication: LoanApplication2009
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

const LoanIncomeSummaryRev1 = (props:Props) => {

  const {classes, loanApplication, intl} = props;

  if(!loanApplication.borrowers || loanApplication.borrowers.length === 0) {
    return (
      <Well color="warn">
        <Typography variant="body1">
          <FormattedMessage id="no_income_found" />
        </Typography>
      </Well>
    );
  }

  return (
    <Grid container spacing={2}>
      {loanApplication.borrowers.map((borrower, borrowerIndex) => {
        const smMdSize = loanApplication.borrowers && loanApplication.borrowers.length > 1 ? Math.floor(12 / loanApplication.borrowers.length) : 6;
        return (
          //@ts-ignore
          <Grid key={borrowerIndex}
                item
                xs={12}
                sm={smMdSize}
                md={smMdSize}>

            <div className={classes.mb2}>
              <SubTitle title={borrower.fullName || IntlFormatter.formatMessage(intl, 'borrower')} />
            </div>

            {(!borrower.monthlyIncome && ((borrower.otherIncome && borrower.otherIncome.length === 0) || !borrower.otherIncome)) &&
            <div>
              <div className={classes.mb2}>
                <Well color="warn">
                  <Typography variant="body1">
                    <FormattedMessage id="no_income_found" />. &nbsp;
                    {!loanApplication.completed &&
                    <Link component={RouterLink} to="/apply/income">
                      <FormattedMessage id="add_income"/>
                    </Link>
                    }
                  </Typography>
                </Well>
              </div>
            </div>
            }
            <BorrowerMonthlyIncomeSummary loanApplication={loanApplication}
                                          monthlyIncome={borrower.monthlyIncome}
                                          otherIncome={borrower.otherIncome} />
          </Grid>
        )
      })}
    </Grid>
  );
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanIncomeSummaryRev1));