import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  withStyles,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, TextField} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {addHiddenMilestone, updateHiddenMilestone} from "../../../actions/hiddenMilestones";
import {ErrorUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ReduxApp, ActionProps, ErrorState} from "../../../types";
import {AxiosResponse} from "axios";
import {
  HiddenMilestone,
  HiddenMilestoneRequest,
  HiddenMilestoneUpdateRequest,
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  hiddenMilestone?: HiddenMilestone|null,
  onSubmit: (data:AxiosResponse<HiddenMilestone>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  addHiddenMilestone: ActionProps["addHiddenMilestone"]
  updateHiddenMilestone: ActionProps["updateHiddenMilestone"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  name: string,
  loanTypes: HiddenMilestone["loanTypes"],
  loanPurposes: HiddenMilestone["loanPurposes"]
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class HiddenMilestoneForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    let form:Form = {
      name: '',
      loanTypes: [],
      loanPurposes: []
    }

    if(props.hiddenMilestone) {
      form = _.merge(form, _.cloneDeep(props.hiddenMilestone));
    } else {
      form.loanPurposes = _.cloneDeep(props.app.data.enums.loanPurposes) as HiddenMilestone["loanPurposes"];
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };
  }

  onLoanTypesChange = (event:ChangeEvent<{value:HiddenMilestone["loanTypes"]}>) => {
    const form = _.cloneDeep(this.state.form);
    form.loanTypes = _.clone(event.target.value);
    this.setState({ form });
  };

  renderLoanTypes = (selected:HiddenMilestone["loanTypes"]) => {
    let loanTypes:string[] = [];

    selected.forEach(t => {
      loanTypes.push(IntlFormatter.formatMessage(this.props.intl, `loan_type_${t}`));
    });

    return loanTypes.join(', ');
  };

  onLoanPurposesChange = (event:ChangeEvent<{value:HiddenMilestone["loanPurposes"]}>) => {
    const form = _.cloneDeep(this.state.form);
    form.loanPurposes = _.clone(event.target.value);
    this.setState({ form });
  };

  renderLoanPurposes = (selected:HiddenMilestone["loanPurposes"]) => {
    let loanPurposes:string[] = [];

    selected.forEach(purpose => {
      loanPurposes.push(IntlFormatter.formatMessage(this.props.intl, purpose));
    });

    return loanPurposes.join(', ');
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      const { hiddenMilestone, mixpanel } = this.props;
      this.setState({ loading: true, errors: [] });

      const {name, loanPurposes, loanTypes} = this.state.form;
      let result:AxiosResponse<HiddenMilestone>;
      if(hiddenMilestone && hiddenMilestone._id) {
        const requestData:HiddenMilestoneUpdateRequest = {
          name,
          loanPurposes,
          loanTypes
        };
        result = await this.props.updateHiddenMilestone(hiddenMilestone._id, requestData).send();
        mixpanel.track("Hidden milestone updated");
      } else {

        const requestData:HiddenMilestoneRequest = {
          name,
          loanPurposes,
          loanTypes
        };
        result = await this.props.addHiddenMilestone(requestData).send();
        mixpanel.track("Hidden milestone added");
      }


      this.setState({ loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes,  app } = this.props;
    const { form, loading, errors} = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } }/>

        <div className={classes.mb2}>
          <TextField name="name"
                     label={IntlFormatter.formatMessage(intl, 'name')}
                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.name')}
                     value={form.name}
                     fullWidth={true}
                     validators={['required']}
                     errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
        </div>

        <div className={classes.mb2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="loan-types-label">{IntlFormatter.formatMessage(intl, 'loan_types')} *</InputLabel>
            <Select
              labelId="loan-types-label"
              id="loan-types-select"
              multiple
              value={form.loanTypes}
              renderValue={this.renderLoanTypes}
              onChange={this.onLoanTypesChange}
              label={IntlFormatter.formatMessage(intl, 'loan_types')}
            >
              {app.data.enums.loanTypes.map((loanType:HiddenMilestone["loanTypes"][0]) => (
                <MenuItem key={loanType} value={loanType}>
                  <Checkbox checked={form.loanTypes.indexOf(loanType) > -1} />
                  <ListItemText primary={IntlFormatter.formatMessage(intl, `loan_type_${loanType}`)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={classes.mb2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="loan-purposes-label">{IntlFormatter.formatMessage(intl, 'loan_purpose')} *</InputLabel>
            <Select
              labelId="loan-purposes-label"
              id="loan-purposes-select"
              multiple
              value={form.loanPurposes}
              renderValue={this.renderLoanPurposes}
              onChange={this.onLoanPurposesChange}
              label={IntlFormatter.formatMessage(intl, 'loan_purpose')}
            >
              {app.data.enums.loanPurposes.map((loanPurpose:HiddenMilestone["loanPurposes"][0]) => (
                <MenuItem key={loanPurpose} value={loanPurpose}>
                  <Checkbox checked={form.loanPurposes.indexOf(loanPurpose) > -1} />
                  <ListItemText primary={IntlFormatter.formatMessage(intl, loanPurpose)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={classes.mt2}>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
            {this.props.onCancel &&
            <Grid item>
              <Button onClick={this.props.onCancel}>
                <FormattedMessage id="cancel" />
              </Button>
            </Grid>
            }
            <Grid item>
              <SubmitButton loading={loading} disabled={form.loanTypes.length === 0 || form.loanPurposes.length === 0}>
                <FormattedMessage id="save" />
              </SubmitButton>
            </Grid>
          </Grid>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addHiddenMilestone(data:HiddenMilestoneRequest) {
    return dispatch(addHiddenMilestone(data));
  },
  updateHiddenMilestone(id:string, data:HiddenMilestoneUpdateRequest) {
    return dispatch(updateHiddenMilestone(id, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(HiddenMilestoneForm)));
