import React, {Component} from 'react';
import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, WithStyles,
  withStyles
} from '@material-ui/core';
import styles from "../theme/jss/layouts/pageStyles";
import {connect} from "react-redux";
import {
  LoanApplicationMilestone,
  LoanApplication2009,
  LoanApplication
} from "@jerseydev/orca-loans";
import {ActionProps, ErrorState, ReduxHiddenMilestones} from "../types";
import {ReduxState} from "../data/initialState";
import {ErrorUtil, ReduxUtil} from "../utils";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {getHiddenMilestones} from "../actions/hiddenMilestones";
import {ErrorList} from "./index";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  hiddenMilestones: ReduxHiddenMilestones,
  getHiddenMilestones: ActionProps["getHiddenMilestones"]
} & WithStyles<typeof styles>

type State = {
  loading: boolean,
  errors: ErrorState[]
}

class LoanMilestoneList extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: true,
      errors: []
    }
  }

  componentDidMount = async () => {
    try {
      if(!ReduxUtil.hasData(this.props.hiddenMilestones) && !ReduxUtil.isFetching(this.props.hiddenMilestones)) {
        await this.props.getHiddenMilestones().send();
      }

      this.setState({loading:false});
    } catch (e) {
      this.setState({loading:false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  isMilestoneVisible = (milestone:LoanApplicationMilestone) => {
    const { loanApplication, hiddenMilestones } = this.props;
    let milestoneVisible = true;

    if(loanApplication.loan && loanApplication.loan.loanPurpose && loanApplication.loan.loanType && ReduxUtil.hasData(hiddenMilestones)) {
      milestoneVisible = !hiddenMilestones.data.find(m => m.name === milestone.name && m.loanTypes.includes(loanApplication.loan!.loanType!) && m.loanPurposes.includes(loanApplication.loan!.loanPurpose!));
    }

    return milestoneVisible;
  };

  render() {
    const { loanApplication, classes } = this.props;
    const { loading, errors } = this.state;

    if(loading) {
      return null;
    }

    if(!loanApplication.milestones || (loanApplication.milestones && loanApplication.milestones.length === 0)) {
      return null;
    }

    if(!loading && errors.length > 0) {
      return <ErrorList errors={errors} />
    }

    return (
      <List>
        {loanApplication.milestones.map((milestone, i) => {
          if(!this.isMilestoneVisible(milestone)) {
            return null;
          }
          return (
            <ListItem key={i}>
              <ListItemIcon>
                <Icon className={milestone.completed ? classes.success : classes.warning}>{milestone.completed ? 'check_circle' : 'schedule'}</Icon>
              </ListItemIcon>
              <ListItemText primary={milestone.name} />
            </ListItem>
          )
        })}
      </List>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    hiddenMilestones: state.hiddenMilestones
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getHiddenMilestones() {
    return dispatch(getHiddenMilestones());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(LoanMilestoneList));
