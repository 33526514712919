import React, {Component} from 'react';
import {Button, Icon, Typography, ButtonGroup, withStyles, IconButton, WithStyles} from '@material-ui/core';
import styles from '../theme/jss/components/planStyles';
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {StripeUtil, SubscriptionUtil} from "../utils";
import IntlFormatter from "../intl";
import _ from "lodash";
import {Dialog, NavPills, Snackbar} from "./index";
import {SubscribeForm} from "../views/plans/forms";
import clsx from "clsx";
import {Alert} from "./index";
import {Plan as PlanObj, Subscription} from "@jerseydev/orca-loans";
import {DialogState, FixMeLater, SnackbarState} from "../types";
import {Mixpanel} from "mixpanel-browser";
import {MixpanelConsumer} from "react-mixpanel";

type PlanIntervalType = 'month'|'year'

type DefaultProps = {
  color: 'default'|'rose'
}

type Props = {
  interval: PlanIntervalType,
  subscription: Subscription,
  plan: PlanObj,
  onSubscribe?: () => void
} & Partial<DefaultProps>
  & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  selectedInterval: PlanIntervalType,
  subscribeDialog: DialogState,
  snackbar: SnackbarState,
  selectedPlan?: PlanObj|null,
  selectedTierIndex: number
}

class Plan extends Component<Props, State> {
  static defaultProps:DefaultProps = {
    color: "default"
  }

  constructor(props:Props) {
    super(props);

    const state:State = {
      selectedInterval: props.subscription.plan.interval || 'month',
      subscribeDialog: {
        open: false,
        loading: false,
        errors: []
      },
      snackbar: {
        open: false,
        message: ''
      },
      selectedPlan: null,
      selectedTierIndex: props.plan.pricing[0].tiers.length - 1
    };

    //console.log(props.plan, props.subscription.plan);
    for(let i=0;i<props.plan.pricing[0].tiers.length;i++) {
      if(props.plan.pricing[0].tiers[i].up_to && props.subscription.quantity < props.plan.pricing[0].tiers[i].up_to!) {
        state.selectedTierIndex = i;
        break;
      }
    }

    this.state = state;
  }

  static getDerivedStateFromProps = (nextProps:Props, prevState:State) => {
    if(nextProps.interval && prevState.selectedInterval !== nextProps.interval) {
      return { selectedInterval: nextProps.interval };
    }
    return null;
  };

  onPriceIntervalClick = (index:number) => {
    const { plan } = this.props;
    this.setState({ selectedInterval: plan.pricing[index].interval });
  };

  onSubscribeClick = (plan:PlanObj) => {
    const subscribeDialog = _.cloneDeep(this.state.subscribeDialog);
    subscribeDialog.open = true;
    const selectedPlan = _.cloneDeep(plan);
    this.setState({ subscribeDialog, selectedPlan });
  };

  onSubscribeCancel = () => {
    const subscribeDialog = _.cloneDeep(this.state.subscribeDialog);
    subscribeDialog.open = false;
    subscribeDialog.loading = false;
    subscribeDialog.errors = [];
    this.setState({ subscribeDialog });
  };

  onSubscribeSubmit = () => {
    const subscribeDialog = _.cloneDeep(this.state.subscribeDialog);
    subscribeDialog.open = false;
    subscribeDialog.loading = false;
    subscribeDialog.errors = [];

    this.setState({
      subscribeDialog,
      snackbar: {
        open: true,
        message: IntlFormatter.formatMessage(this.props.intl, 'subscribe_thank_you' )
      }
    });

    if(this.props.onSubscribe) {
      this.props.onSubscribe();
    }
  };

  isSubscribed = () => {
    const { subscription, plan } = this.props;
    const { selectedInterval } = this.state;

    const pricing = plan.pricing.find(p => p.interval === selectedInterval);
    return pricing && pricing.id === subscription.plan.id;
  };

  getPillTabs = () => {
    const { intl, plan } = this.props;

    const tabs:FixMeLater[] = [];

    plan.pricing.forEach(price => {
      tabs.push({ tabButton: IntlFormatter.formatMessage(intl, price.interval)});
    });

    return tabs;
  };

  onSnackbarClose = () => {
    this.setState({
      snackbar: {
        open: false,
        message:''
      }
    });
  };

  onTierClick = (index:number) => {
    this.setState({ selectedTierIndex: index });
  };

  isDowngradingPlan = () => {
    const { subscription } = this.props;
    const { selectedPlan } = this.state;
    const currentPlan = subscription.plan.key;
    return selectedPlan && ((currentPlan === 'enterprise' && (selectedPlan.metadata._plan === 'professional' || selectedPlan.metadata._plan === 'basic')) || (currentPlan === 'professional' && selectedPlan.metadata._plan === 'basic'));
  };

  render() {
    const { intl, classes, plan, color, subscription, interval } = this.props;
    const { selectedInterval, selectedTierIndex, subscribeDialog, selectedPlan, snackbar } = this.state;
    const isSubscribed = this.isSubscribed();
    const isCurrentPlan = subscription.plan.product.id === plan.id;
    const isExpired = SubscriptionUtil.isExpired(subscription.status);
    const isCancelling = SubscriptionUtil.isCancelling(subscription);

    return (
      // @ts-ignore
      <div className={clsx(classes.root, classes[color])}>
        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.onSnackbarClose}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        <Dialog open={subscribeDialog.open}
                title={selectedPlan ? selectedPlan.description ? selectedPlan.description : selectedPlan.name : ''}
                icon={<Icon>card_membership</Icon>}
                color="successAlt"
                fullWidth={true}
                maxWidth="sm"
                onClose={this.onSubscribeCancel}>

          {selectedPlan &&
            <div>
              {this.isDowngradingPlan() &&
                <div className={classes.mb3}>
                  <Alert severity="danger">
                    <strong><FormattedMessage id="downgrade_plan_warning" /></strong>
                  </Alert>
                </div>
              }
              <MixpanelConsumer>
                {(mixpanel: Mixpanel) => {
                  return (
                    <SubscribeForm plan={selectedPlan}
                                   mixpanel={mixpanel}
                                   // @ts-ignore
                                   pricing={selectedPlan.pricing.find(p => p.interval === selectedInterval)}
                                   onSubmit={this.onSubscribeSubmit}
                                   onCancel={this.onSubscribeCancel}/>
                  )
                }}
              </MixpanelConsumer>
            </div>
          }
        </Dialog>

        <Typography variant="h4" color="inherit" gutterBottom className={classes.planName}>
          {plan.description ? plan.description : plan.name}
        </Typography>

        {!interval &&
          <NavPills
            alignCenter
            color={color === 'rose' ? 'white' : 'rose'}
            tabs={this.getPillTabs()}
            onChange={this.onPriceIntervalClick}
          />
        }

        <div className={classes.details}>
          {plan.pricing.map((p, i) => {
            if(!p.tiers || p.interval !== selectedInterval) {
              return null;
            }

            return (
              <div key={i} className={classes.textCenter}>
                <div>
                  <span className={classes.currency}>$</span><span className={classes.price}>{StripeUtil.formatAmount(p.tiers[selectedTierIndex].unit_amount).replace('.00', '')}</span>/<span className={classes.priceDetails}>{IntlFormatter.formatMessage(intl, 'per_user')}</span>
                </div>
                <div className={classes.priceTierDetails}>
                  <ButtonGroup color="inherit">
                    {p.tiers.map((tier, j) => {
                      return (
                        <Button key={j}
                                className={selectedTierIndex === j ? classes.selectedTierButton : undefined}
                                color={color !== 'rose' ? "primary" : undefined}
                                onClick={() => this.onTierClick(j)}>
                          {j === 0 ? '1' : (p.tiers[j-1].up_to ? p.tiers[j-1].up_to! + 1 : 1)}

                          {tier.up_to &&
                          <span>-{tier.up_to}</span>
                          }
                          {!tier.up_to &&
                          <span>+</span>
                          }
                        </Button>
                      )
                    })}
                  </ButtonGroup>
                  <Typography variant="body2" color="inherit" className={classes.mt1}>
                    <FormattedMessage id="volume_pricing" />
                  </Typography>
                </div>
              </div>
            )
          })}

          <div className={classes.featureList}>
            {Object.keys(plan.metadata).filter(m => m[0] !== '_').map((key, i) => {
              return (
                <div key={i} className={classes.featureListItem} >
                  <div className={classes.featureItem}
                       dangerouslySetInnerHTML={{ __html: plan.metadata[key]}} />
                </div>
              )
            })}
          </div>
        </div>

        {isSubscribed &&
          <div className={classes.center}>
            {isExpired &&
              <Button variant="contained" color={isCurrentPlan ? "default" : "primary"} onClick={() => this.onSubscribeClick(plan)}>
                <FormattedMessage id="select_plan"/>
              </Button>
            }
            {!isExpired &&
              <div>
                {isCancelling &&
                  <Button variant="contained" color={isCurrentPlan ? "default" : "primary"} onClick={() => this.onSubscribeClick(plan)}>
                    <FormattedMessage id="select_plan"/>
                  </Button>
                }
                {!isCancelling &&
                  <div className={classes.currentPlan}>
                    <div className={classes.rowCenter}>
                      <div className={classes.mr1}>
                        <Icon>check_circle</Icon>
                      </div>
                      <Typography variant="subtitle1" color="inherit">
                        <FormattedMessage id="current_plan" />
                      </Typography>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
        {!isSubscribed &&
          <div className={classes.center}>
            <Button variant="contained"
                    color={isCurrentPlan ? "default" : "primary"}
                    size="large"
                    onClick={() => this.onSubscribeClick(plan)}>
              <FormattedMessage id="select_plan"/>
            </Button>
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(Plan));
