import React, {ChangeEvent} from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel, WithStyles,
  withStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import _ from 'lodash';
import IntlFormatter from "../../../intl";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {Select, RadioGroup, TextField, MoneyInput} from '../../../components';
import {FixMeLater, LabelValuePairType, ReduxApp} from "../../../types";
import {BorrowerDeclarations, LoanApplication} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {BorrowerDeclarationBankruptcy} from "@jerseydev/orca-loans/src/index";

type Props = {
  loanApplication: LoanApplication,
  declarations?: BorrowerDeclarations|null,
  onChange: (data:BorrowerDeclarations) => void,
  app: ReduxApp
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  primaryResidence: {
    value: string,
    description: string,
    interestInLastThreeYears?: '0'|'1'|'',
    propertyType?: "primaryResidence" | "secondHome" | "investmentProperty" | "other" | null;
    titleHeld?: "self" | "jointlyWithSpouse" | "jointlyWithAnother" | null;
  },
  relationshipWithSeller: {
    value: string,
    description: string
  },
  borrowingMoney: {
    value: string,
    description: string,
    amount?: string
  },
  applyingForAnotherMortgage: {
    value: string,
    description: string
  },
  applyingForNewCredit: {
    value: string,
    description: string
  },
  subjectToLienPriority: {
    value: string,
    description: string
  },
  coSignerOnDebt: {
    value: string,
    description: string
  },
  outstandingJudgments: {
    value: string,
    description: string
  },
  inDelinquencyOrDefault: {
    value: string,
    description: string
  },
  partyToLawsuit: {
    value: string,
    description: string
  },
  conveyedTitleToPropertyForeclosure: {
    value: string,
    description: string
  },
  preForeclosureOrShortSale: {
    value: string,
    description: string
  },
  foreclosure: {
    value: string,
    description: string
  },
  bankruptcy: {
    value: string,
    description: string,
    types: BorrowerDeclarationBankruptcy["types"]
  }
}

type State = {
  declarations: Form
}

class DeclarationsFormFields extends React.Component<Props, State> {
  propertyTypes:LabelValuePairType[] = [];
  titleTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let declarations:Form = {
      primaryResidence: {
        value: '',
        description: ''
      },
      relationshipWithSeller: {
        value: '',
        description: ''
      },
      borrowingMoney: {
        value: '',
        description: ''
      },
      applyingForAnotherMortgage: {
        value: '',
        description: ''
      },
      applyingForNewCredit: {
        value: '',
        description: ''
      },
      subjectToLienPriority: {
        value: '',
        description: ''
      },
      coSignerOnDebt: {
        value: '',
        description: ''
      },
      outstandingJudgments: {
        value: '',
        description: ''
      },
      inDelinquencyOrDefault: {
        value: '',
        description: ''
      },
      partyToLawsuit: {
        value: '',
        description: ''
      },
      conveyedTitleToPropertyForeclosure: {
        value: '',
        description: ''
      },
      preForeclosureOrShortSale: {
        value: '',
        description: ''
      },
      foreclosure: {
        value: '',
        description: ''
      },
      bankruptcy: {
        value: '',
        description: '',
        types: []
      }
    }

    if(props.declarations) {
      declarations = _.merge(declarations, props.declarations);
      for(let key in declarations) {
        if(declarations.hasOwnProperty(key) && declarations[key as keyof Form] && typeof declarations[key as keyof Form] === 'object' && declarations[key as keyof Form].value !== null && declarations[key as keyof Form].value !== undefined) {
          declarations[key as keyof Form].value = declarations[key as keyof Form].value ? '1' : '0';
        }
      }

      if(declarations.primaryResidence && declarations.primaryResidence.interestInLastThreeYears !== null && declarations.primaryResidence.interestInLastThreeYears !== undefined) {
        declarations.primaryResidence.interestInLastThreeYears = declarations.primaryResidence.interestInLastThreeYears ? '1' : '0';
      }
    }

    this.state = {
      declarations
    };

    props.app.data.enums.declarationPropertyTypes.forEach(type => {
      this.propertyTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `declaration_property_types_${type}`) })
    });

    props.app.data.enums.declarationTitleHeldTypes.forEach(type => {
      this.titleTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `declaration_title_types_${type}`) })
    });
  }

  setPrimaryResidencePropertyType = (propertyType:LabelValuePairType) => {
    const declarations = _.cloneDeep(this.state.declarations);
    declarations.primaryResidence.propertyType = propertyType.value;
    this.setState({ declarations }, () => {
      this.onChange();
    });
  };

  setPrimaryResidenceTitle = (title:LabelValuePairType) => {
    const declarations = _.cloneDeep(this.state.declarations);
    declarations.primaryResidence.titleHeld = title.value;
    this.setState({ declarations }, () => {
      this.onChange();
    });
  };
  
  onDeclarationChange = (event:ChangeEvent<HTMLInputElement>, key:string, explanationValue:string = '1') => {
    const state = _.cloneDeep(this.state);
    _.set(state, `declarations.${key}.value`, event.target.value);

    if(event.target.value !== explanationValue) {
      _.set(state, `declarations.${key}.description`, '');
    }

    this.setState(state, () => {
      this.onChange();
    });
  };

  onTextChange = (event:ChangeEvent<{value:string}>, key:string) => {
    const state = _.cloneDeep(this.state);
    _.set(state, key, event.target.value);

    this.setState(state,() => {
      this.onChange();
    });
  };

  onRadioChange = (event:ChangeEvent<{value:string}>, key:string) => {
    const state = _.cloneDeep(this.state);
    _.set(state, key, event.target.value);

    this.setState(state,() => {
      this.onChange();
    });
  };

  onInterestInLastThreeYearsChange = (event:ChangeEvent<HTMLInputElement>) => {
    if(this.state.declarations.primaryResidence) {
      const declarations = _.cloneDeep(this.state.declarations);
      if(declarations.primaryResidence) {
        declarations.primaryResidence.interestInLastThreeYears = event.target.value ? event.target.value as Form["primaryResidence"]["interestInLastThreeYears"] : '0';
        if(declarations.primaryResidence.interestInLastThreeYears === '0') {
          declarations.primaryResidence.propertyType = null;
          declarations.primaryResidence.titleHeld = null;
        }
      }

      this.setState({ declarations }, () => {
        this.onChange();
      });
    }
  };

  onBankruptcyTypeChange = (event:ChangeEvent<{value:string}>) => {
    const declarations = _.cloneDeep(this.state.declarations);
    if(declarations.bankruptcy) {
      if(!declarations.bankruptcy.types) {
        declarations.bankruptcy.types = [];
      }
      const value:"chapter7" | "chapter11" | "chapter12" | "chapter13" = event.target.value as any;
      const index = declarations.bankruptcy.types.indexOf(value);
      if(index === -1) {
        declarations.bankruptcy.types.push(value);
      } else {
        declarations.bankruptcy.types.splice(index, 1);
      }

      this.setState({ declarations }, () => {
        this.onChange();
      });
    }
  };

  onChange = () => {
    if(this.props.onChange) {
      const borrowerDeclarations:BorrowerDeclarations = {};
      const {declarations} = this.state;
      for(let key in declarations) {
        if(declarations.hasOwnProperty(key)) {
          const value:boolean = declarations[key as keyof Form].value !== '' ? declarations[key as keyof Form].value === '1' : false;
          const description:string|null = declarations[key as keyof Form].description !== '' ? declarations[key as keyof Form].description : null
          borrowerDeclarations[key as keyof BorrowerDeclarations] = {
            value,
            description
          };

          if(key === 'primaryResidence') {
            borrowerDeclarations.primaryResidence!.interestInLastThreeYears = declarations.primaryResidence.interestInLastThreeYears !== '' ? declarations.primaryResidence.interestInLastThreeYears === '1' : null;
            borrowerDeclarations.primaryResidence!.propertyType = declarations.primaryResidence.propertyType;
            borrowerDeclarations.primaryResidence!.titleHeld = declarations.primaryResidence.titleHeld;
          } else if(key === 'borrowingMoney') {
            borrowerDeclarations.borrowingMoney!.amount = declarations.borrowingMoney.amount ? parseInt(declarations.borrowingMoney.amount) : null;
          } else if(key === 'bankruptcy') {
            borrowerDeclarations.bankruptcy!.types = declarations.bankruptcy.types;
          }
        }
      }

      this.props.onChange(borrowerDeclarations);
    }
  };

  renderQuestion = (label:string, prop:string, explanationValue:string = '1') => {
    const { classes, intl } = this.props;
    const { declarations } = this.state;

    return(
      <div className={classes.mb2}>
        <RadioGroup name={prop}
                    label={label}
                    itemValueProp="value"
                    value={declarations[prop as keyof Form] ? declarations[prop as keyof Form].value : null}
                    onChange={event => this.onDeclarationChange(event, prop, explanationValue)}
                    items={[
                      { label: IntlFormatter.formatMessage(intl, 'yes'), value: '1' },
                      { label: IntlFormatter.formatMessage(intl, 'no'), value: '0' }
                    ]}
                    validators={['required']}
                    errorMessages={[
                      IntlFormatter.formatMessage(intl, 'validation_required')
                    ]}
                    row />

        {(explanationValue !== undefined && (declarations[prop as keyof Form] && declarations[prop as keyof Form].value === explanationValue)) &&
          <TextField name={prop}
                     label={IntlFormatter.formatMessage(intl, 'please_explain')}
                     value={declarations[prop as keyof Form] ? declarations[prop as keyof Form].description : null}
                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `declarations.${prop}.description`)}
                     multiline={true}
                     fullWidth={true} />
        }
      </div>
    )
  };

  render() {

    const { intl, classes, loanApplication, app } = this.props;
    const { declarations } = this.state;

    return (
     <div>
       <div className={classes.mb2}>
         <RadioGroup name="primaryResidence"
                     label={IntlFormatter.formatMessage(intl, 'declaration_primary_residence')}
                     itemValueProp="value"
                     value={declarations.primaryResidence ? declarations.primaryResidence.value : null}
                     onChange={event => this.onDeclarationChange(event, 'primaryResidence')}
                     items={[
                       { label: IntlFormatter.formatMessage(intl, 'yes'), value: '1' },
                       { label: IntlFormatter.formatMessage(intl, 'no'), value: '0' }
                     ]}
                     validators={['required']}
                     errorMessages={[
                       IntlFormatter.formatMessage(intl, 'validation_required')
                     ]}
                     row />
         {declarations.primaryResidence.value === '1' &&
           <div>
             <div className={classes.mb2}>
               <RadioGroup name="interestInLastThreeYears"
                           label={IntlFormatter.formatMessage(intl, 'declaration_ownership_in_last_three_years')}
                           itemValueProp="value"
                           value={declarations.primaryResidence.interestInLastThreeYears ? declarations.primaryResidence.interestInLastThreeYears : null}
                           onChange={this.onInterestInLastThreeYearsChange}
                           items={[
                             { label: IntlFormatter.formatMessage(intl, 'yes'), value: '1' },
                             { label: IntlFormatter.formatMessage(intl, 'no'), value: '0' }
                           ]}
                           validators={['required']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required')
                           ]}
                           row />
             </div>
             {declarations.primaryResidence.interestInLastThreeYears === '1' &&
               <div>
                 <div className={classes.mb2}>
                   <Select name="residencePropertyType"
                           options={this.propertyTypes}
                           onChange={this.setPrimaryResidencePropertyType}
                           validators={['required']}
                           label={IntlFormatter.formatMessage(intl, 'declaration_ownership_property_type')}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required'),
                           ]}
                           value={declarations.primaryResidence.propertyType ? this.propertyTypes.find(t => t.value === declarations.primaryResidence.propertyType) : ''}/>
                 </div>
                 <div className={classes.mb2}>
                   <Select name="ownershipTitle"
                           options={this.titleTypes}
                           onChange={this.setPrimaryResidenceTitle}
                           label={IntlFormatter.formatMessage(intl, 'declaration_ownership_title')}
                           validators={['required']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required'),
                           ]}
                           value={declarations.primaryResidence.titleHeld ? this.titleTypes.find(t => t.value === declarations.primaryResidence.titleHeld) : ''}/>
                 </div>
               </div>
             }
           </div>
         }
       </div>
       {loanApplication.loan && loanApplication.loan.loanPurpose === 'purchase' &&
        this.renderQuestion(IntlFormatter.formatMessage(intl, 'declaration_relationship_with_seller'), 'relationshipWithSeller')
       }
       <div className={classes.mb2}>
         <RadioGroup name="borrowingMoney"
                     label={IntlFormatter.formatMessage(intl, 'declaration_borrowing_money')}
                     itemValueProp="value"
                     value={declarations.borrowingMoney ? declarations.borrowingMoney.value : null}
                     onChange={event => this.onDeclarationChange(event, 'borrowingMoney')}
                     items={[
                       { label: IntlFormatter.formatMessage(intl, 'yes'), value: '1' },
                       { label: IntlFormatter.formatMessage(intl, 'no'), value: '0' }
                     ]}
                     validators={['required']}
                     errorMessages={[
                       IntlFormatter.formatMessage(intl, 'validation_required')
                     ]}
                     row />

         {declarations.borrowingMoney.value === '1' &&
           <div className={classes.mt2}>
             <MoneyInput label={IntlFormatter.formatMessage(intl, 'amount')}
                         value={declarations.borrowingMoney.amount}
                         fullWidth={true}
                         decimalScale={0}
                         validators={['required', 'minNumber:0', 'isNumber']}
                         errorMessages={[
                           IntlFormatter.formatMessage(intl, 'validation_required'),
                           IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                           IntlFormatter.formatMessage(intl, 'validation_whole_number')
                         ]}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `declarations.borrowingMoney.amount`)} />
           </div>
         }
       </div>
       {this.renderQuestion(IntlFormatter.formatMessage(intl, 'declaration_applying_for_another_mortgage'), 'applyingForAnotherMortgage')}
       {this.renderQuestion(IntlFormatter.formatMessage(intl, 'declaration_applying_for_new_credit'), 'applyingForNewCredit')}
       {this.renderQuestion(IntlFormatter.formatMessage(intl, 'declaration_subject_to_lien_priority'), 'subjectToLienPriority')}
       {this.renderQuestion(IntlFormatter.formatMessage(intl, 'declaration_cosigner_on_debt'), 'coSignerOnDebt')}
       {this.renderQuestion(IntlFormatter.formatMessage(intl, 'declaration_outstanding_judgments'), 'outstandingJudgments')}
       {this.renderQuestion(IntlFormatter.formatMessage(intl, 'declaration_in_delinquency_or_default'), 'inDelinquencyOrDefault')}
       {this.renderQuestion(IntlFormatter.formatMessage(intl, 'declaration_lawsuit'), 'partyToLawsuit')}
       {this.renderQuestion(IntlFormatter.formatMessage(intl, 'declaration_conveyed_title_to_property_foreclosure'), 'conveyedTitleToPropertyForeclosure')}
       {this.renderQuestion(IntlFormatter.formatMessage(intl, 'declaration_pre_foreclosure_short_sale'), 'preForeclosureOrShortSale')}
       {this.renderQuestion(IntlFormatter.formatMessage(intl, 'declaration_foreclosure'), 'foreclosure')}

       <div className={classes.mb2}>
         <RadioGroup name="bankruptcy"
                     label={IntlFormatter.formatMessage(intl, 'declaration_bankruptcy')}
                     itemValueProp="value"
                     value={declarations.bankruptcy ? declarations.bankruptcy.value : null}
                     onChange={event => this.onDeclarationChange(event, 'bankruptcy')}
                     items={[
                       { label: IntlFormatter.formatMessage(intl, 'yes'), value: '1' },
                       { label: IntlFormatter.formatMessage(intl, 'no'), value: '0' }
                     ]}
                     validators={['required']}
                     errorMessages={[
                       IntlFormatter.formatMessage(intl, 'validation_required')
                     ]}
                     row />

         {(declarations.bankruptcy.value === '1' && declarations.bankruptcy.types) &&
         <div className={classes.mt2}>
           <FormControl component="fieldset">
             <FormLabel component="legend">
               <FormattedMessage id="bankruptcy_types" />
             </FormLabel>
             <FormGroup row>
               {app.data.enums.bankruptcyTypes.map((bankruptcyType, i) => {
                 return (
                   <FormControlLabel key={i}
                                     control={<Checkbox checked={declarations.bankruptcy.types!.includes(bankruptcyType as FixMeLater)}
                                                        onChange={this.onBankruptcyTypeChange}
                                                        value={bankruptcyType} />}
                                     label={IntlFormatter.formatMessage(intl, `bankruptcy_type_${bankruptcyType}`)}/>
                 )
               })}
             </FormGroup>
           </FormControl>
         </div>
         }
       </div>
     </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app
  };
};


export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(DeclarationsFormFields)));
