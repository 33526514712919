import React, {Component, RefObject, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {
  AutoComplete,
  DeleteDialog,
  PageTitle,
  SearchBar,
  UserAvatar,
  ErrorList,
  NoResultsCard,
  PaginationHeader,
  Dialog,
  ClipboardText,
  Badge,
  SortField,
  AddButton,
  Pagination
} from '../../../components';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {
  Button,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Hidden,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemSecondaryAction,
  ButtonGroup,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  Grow,
  TableSortLabel,
  Fab,
  ListItemAvatar,
  FormControlLabel,
  Checkbox,
  WithStyles,
} from "@material-ui/core";
import {SearchUtil, ReduxUtil, ErrorUtil, AclUtil, IntegrationUtil} from "../../../utils";
import _ from "lodash";
import {Link, Redirect, RouteComponentProps} from "react-router-dom";
import pageStyles from '../../../theme/jss/layouts/pageStyles';
import Api from "../../../lib/Api";
import clsx from "clsx";
import qs from "query-string";
import {
  ActionResponse,
  DialogState,
  LabelValuePairType,
  ReduxLoanOfficers,
  ReduxLoanSettings,
  ReduxUser,
  ActionProps,
  SearchCriteriaWithPagingType,
  SnackbarState,
  SortDirectionType,
  ErrorState,
  ReduxIntegrations
} from "../../../types";
import {Branch, LoanOfficer, LoanOfficerSearchRequest} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";
import {LoanOfficerProfileCompleteIcon, RestoreArchivedLoanOfficer} from "../components";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel
  user: ReduxUser,
  loanOfficers: ReduxLoanOfficers,
  settings: ReduxLoanSettings,
  integrations: ReduxIntegrations
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  pageLoaded: boolean,
  loading: boolean,
  hasInitialResults?: boolean,
  selectedActionMenu: Element|null,
  deleteDialogOpen: boolean,
  deleteLoading: boolean,
  deleteErrors: ErrorState[],
  selectedLoanOfficer: LoanOfficer|null,
  searchCriteria: {branches:Branch[]} & SearchCriteriaWithPagingType,
  snackbar?: SnackbarState|null,
  errors: ErrorState[],
  addButtonGroupOpen: boolean,
  redirectTo?: string,
  restoreDialog: DialogState
}

class LoanOfficersPage extends Component<Props, State> {
  loanOfficerSearch:ActionResponse|null;
  addButtonGroupRef:RefObject<HTMLDivElement>;
  sortOptions:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      deleteDialogOpen: false,
      deleteLoading: false,
      deleteErrors: [],
      selectedLoanOfficer: null,
      selectedActionMenu: null,
      searchCriteria: {
        searchText: '',
        branches: [],
        order: {
          column: 'lastName',
          direction: 'asc'
        },
        limit: 10,
        skip: 0,
        totalCount: 0,
        page: 1,
        deleted: false
      },
      errors: [],
      addButtonGroupOpen: false,
      restoreDialog: {
        open: false,
        loading: false,
        errors: []
      }
    };

    this.addButtonGroupRef = React.createRef();

    this.sortOptions = [
      { label: IntlFormatter.formatMessage(props.intl, 'name'), value: 'lastName' },
      { label: IntlFormatter.formatMessage(props.intl, 'email'), value: 'email' },
      { label: IntlFormatter.formatMessage(props.intl, 'nmls_id'), value: 'nmlsId' },
    ];
  }

  componentDidMount = async () => {
    if(AclUtil.hasRole(this.props.user.data, 'ROLE_LOAN_OFFICER') && this.props.user.data.roles.length === 1 && this.props.user.data.additionalProps && this.props.user.data.additionalProps.loanOfficer) {
      this.setState({ redirectTo: `/admin/loan-officers/detail/${this.props.user.data.additionalProps.loanOfficer._id}` });
    } else {
      try {
        await this.addSearchCriteriaFromParams();
        const results = await this.searchLoanOfficers();
        this.setState({ pageLoaded: true, hasInitialResults: results.data.length > 0 }, () => {
          this.loanOfficerSearch = null;
        });
      } catch(err) {
        this.onError(err);
      }
    }
  };

  componentWillUnmount = () => {
    if (this.loanOfficerSearch) {
      this.loanOfficerSearch.cancel();
    }
  };

  addSearchCriteriaFromParams = ():Promise<void> => {
    return new Promise((resolve) => {
      const params = qs.parse(this.props.location.search); // @todo add other search criteria to url params
      if(params.deleted === 'true') {
        const searchCriteria = _.cloneDeep(this.state.searchCriteria);
        searchCriteria.deleted = true;
        this.setState({ searchCriteria }, () => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  };

  searchTextChanged = (value:string) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.searchText = value;
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.filterLoanOfficers);
  };

  searchTextRemove = () => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.searchText = '';
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.filterLoanOfficers);
  };

  setBranches = (branches:Branch[]) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.branches = _.cloneDeep(branches);
    searchCriteria.skip = 0;
    searchCriteria.page = 1;

    this.setState({ searchCriteria }, this.filterLoanOfficers);
  };

  searchBranches = async (searchText:string) => {
    const result = await Api.searchBranches({ searchText });
    return result.data;
  };

  getFilterCount = () => {
    let count = 0;
    const { branches, deleted } = this.state.searchCriteria;
    count += branches.length;
    if(deleted) {
      count += 1;
    }
    return count;
  };

  filterLoanOfficers = async () => {
    try {
      await this.searchLoanOfficers();
    } catch (e) {
      this.onError(e);
    }
  };

  searchLoanOfficers = ():Promise<AxiosResponse<LoanOfficer[]>> => {
    return new Promise((resolve, reject) => {
      if (this.loanOfficerSearch) {
        this.loanOfficerSearch.cancel('Another request was made');
      }

      const {searchCriteria} = this.state;
      this.setState({loading: true}, () => {
        const criteria:LoanOfficerSearchRequest = {
          searchText: searchCriteria.searchText,
        };

        if (searchCriteria.branches.length > 0) {
          criteria.branch = _.map(searchCriteria.branches, '_id');
        }

        const params = SearchUtil.getParamsFromSearchCriteria(searchCriteria);
        if(params && !AclUtil.isOwner(this.props.user.data)) {
          params.deleted = false;
        }

        this.loanOfficerSearch = this.props.routeProps.searchLoanOfficers(criteria, params);
        this.loanOfficerSearch.send().then(result => {
          const searchCriteria = _.cloneDeep(this.state.searchCriteria);
          searchCriteria.totalCount = parseInt(result.headers['total-count']);
          this.setState({loading: false, searchCriteria});
          this.loanOfficerSearch = null;
          resolve(result);
        }).catch(err => {
          this.loanOfficerSearch = null;
          reject(err);
        });
      });
    });
  };

  onError = (err:any) => {
    this.setState({loading: false, errors: ErrorUtil.formatErrors(err)});
  };

  showDeleteDialog = () => {
    this.setState({
      deleteDialogOpen: true
    });
  };

  hideDeleteDialog = () => {
    this.setState({
      deleteDialogOpen: false,
      selectedLoanOfficer: null
    });
  };

  deleteLoanOfficer = async () => {
    try {
      const loanOfficer = this.state.selectedLoanOfficer;
      this.setState({ deleteLoading: true });
      if(loanOfficer) {
        await this.props.routeProps.deleteLoanOfficer(loanOfficer._id).send();
        this.props.mixpanel.track("Loan officer archived");
      }
      this.setState({
        deleteDialogOpen: false,
        selectedLoanOfficer: null,
        deleteLoading: false,
        snackbar: {
          open: true,
          variant: 'success',
          message: IntlFormatter.formatMessage(this.props.intl, 'loan_officer_archived')
        }
      }, () => {
        this.setState({ snackbar: null });
      });

      await this.filterLoanOfficers();
    } catch (e) {
      this.setState({ deleteErrors: ErrorUtil.formatErrors(e), deleteLoading: false })
    }
  };

  onActionMenuClick = (event:MouseEvent, loanOfficer:LoanOfficer) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ selectedActionMenu: event.currentTarget, selectedLoanOfficer: loanOfficer });
  };

  onActionMenuClose = () => {
    this.setState({ selectedActionMenu: null });
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'loan_officers');
  };

  renderTitleBar = () => {
    const { intl, user, integrations } = this.props;
    const { addButtonGroupOpen } = this.state;

    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageTitle title={this.getPageTitle()} icon="people_outline" />
        </Grid>
        <Grid item>
          <div>
            {AclUtil.isOwner(user.data) &&
            <div>
              {IntegrationUtil.hasIntegration(integrations.data, 'los') &&
              <div>
                <Hidden mdUp>
                  <Tooltip title={IntlFormatter.formatMessage(intl, 'add_loan_officer')}>
                    <Fab color="primary"
                         component={Link} size="small"
                         to="/admin/loan-officers/add">
                      <Icon>add</Icon>
                    </Fab>
                  </Tooltip>
                </Hidden>
                <Hidden smDown>
                  <ButtonGroup ref={this.addButtonGroupRef} variant="contained" color="primary">
                    <AddButton to="/admin/loan-officers/add">
                      <FormattedMessage id="add_loan_officer" />
                    </AddButton>
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => { this.setState({ addButtonGroupOpen: true })}}>
                      <Icon>arrow_drop_down</Icon>
                    </Button>
                  </ButtonGroup>
                  <Popper open={addButtonGroupOpen}
                          anchorEl={this.addButtonGroupRef.current}
                          role={undefined}
                          transition
                          disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={() => { this.setState({ addButtonGroupOpen: false })}}>
                            <MenuList id="split-button-menu">
                              <MenuItem component={Link} to="/admin/loan-officers/add">
                                <ListItemIcon>
                                  <Icon>add</Icon>
                                </ListItemIcon>
                                <FormattedMessage id="add_loan_officer" />
                              </MenuItem>
                              <MenuItem component={Link} to="/admin/loan-officers/import">
                                <ListItemIcon>
                                  <Icon>import_export</Icon>
                                </ListItemIcon>
                                <FormattedMessage id="import_loan_officers" />
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Hidden>
              </div>
              }
              {!IntegrationUtil.hasIntegration(integrations.data, 'los') &&
              <div>
                <Hidden mdUp>
                  <Tooltip title={IntlFormatter.formatMessage(intl, 'add_loan_officer')}>
                    <Fab color="primary"
                         component={Link} size="small"
                         to="/admin/loan-officers/add">
                      <Icon>add</Icon>
                    </Fab>
                  </Tooltip>
                </Hidden>
                <Hidden smDown>
                  <AddButton variant="contained"
                             color="primary"
                             to="/admin/loan-officers/add">
                    <FormattedMessage id="add_loan_officer" />
                  </AddButton>
                </Hidden>
              </div>
              }
            </div>
            }
          </div>
        </Grid>
      </Grid>
    );
  };

  onPageChange = (event:MouseEvent, value:number) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.skip = (value - 1) * searchCriteria.limit;
    searchCriteria.page = value;
    this.setState({ searchCriteria }, this.filterLoanOfficers);
  };

  onTableHeadingSortColumnChange = (column:string) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.order.column = column;
    searchCriteria.order.direction = searchCriteria.order.direction === 'asc' ? 'desc' : 'asc';
    this.setState({ searchCriteria }, this.filterLoanOfficers);
  };

  onSortFieldOptionChange = (data:LabelValuePairType) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.order.column = data ? data.value : null;
    this.setState({ searchCriteria }, this.filterLoanOfficers);
  };

  onSortFieldDirectionChange = (direction:SortDirectionType) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.order.direction = direction;
    this.setState({ searchCriteria }, this.filterLoanOfficers);
  };

  onToggleInactiveLoanOfficers = () => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.deleted = !searchCriteria.deleted;
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.filterLoanOfficers);
  };

  onRestoreLoanOfficerClick = (loanOfficer:LoanOfficer) => {
    const restoreDialog = _.cloneDeep(this.state.restoreDialog);
    restoreDialog.open = true;
    this.setState({ selectedLoanOfficer: _.cloneDeep(loanOfficer), restoreDialog });
  };

  onRestoreLoanOfficerConfirm = async () => {
    const restoreDialog = _.cloneDeep(this.state.restoreDialog);
    try {
      restoreDialog.loading = false;
      restoreDialog.open = false;
      this.setState({ loading: false, selectedLoanOfficer: null, restoreDialog }, this.filterLoanOfficers);
    } catch(e) {
      restoreDialog.errors = ErrorUtil.formatErrors(e);
      restoreDialog.loading = false;
      this.setState({ loading: false, selectedLoanOfficer: null, restoreDialog });
    }
  };

  onRestoreLoanOfficerCancel = () => {
    const restoreDialog = _.cloneDeep(this.state.restoreDialog);
    restoreDialog.open = false;
    restoreDialog.loading = false;
    restoreDialog.errors = [];
    this.setState({ selectedLoanOfficer: null, restoreDialog });
  };

  render() {

    const { intl, loanOfficers, classes, settings, user, integrations } = this.props;
    const { pageLoaded, loading, searchCriteria, deleteDialogOpen, deleteLoading, deleteErrors, selectedLoanOfficer, snackbar, errors, selectedActionMenu, redirectTo, hasInitialResults, restoreDialog } = this.state;
    const pages = Math.ceil(searchCriteria.totalCount / searchCriteria.limit);

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   pageLoaded={pageLoaded}
                   loading={loading}
                   titleBar={this.renderTitleBar()}
                   snackbar={snackbar}
                   breadcrumbs={[
                    {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                    {title: this.getPageTitle() }
                  ]}>

        <DeleteDialog open={deleteDialogOpen}
                      title={IntlFormatter.formatMessage(intl, 'archive_loan_officer')}
                      item={selectedLoanOfficer ? `${selectedLoanOfficer.firstName} ${selectedLoanOfficer.lastName}` : ''}
                      loading={deleteLoading}
                      errors={deleteErrors}
                      confirmationMessage={IntlFormatter.formatMessage(intl, 'archive_confirmation', { value: selectedLoanOfficer ? `${selectedLoanOfficer.firstName} ${selectedLoanOfficer.lastName}` : '' })}
                      secondConfirmationMessage={IntlFormatter.formatMessage(intl, 'archive_second_confirmation', { value: selectedLoanOfficer ? `${selectedLoanOfficer.firstName} ${selectedLoanOfficer.lastName}` : '' })}
                      deleteButtonLabel={IntlFormatter.formatMessage(intl, 'archive')}
                      onCancel={this.hideDeleteDialog}
                      onSubmit={this.deleteLoanOfficer} />

        <Dialog open={restoreDialog.open}
                icon={<Icon>person_add</Icon>}
                title={IntlFormatter.formatMessage(intl, 'restore_loan_officer')}
                color="successAlt"
                onClose={this.onRestoreLoanOfficerCancel}
                fullWidth={true}
                variant="confirm"
                maxWidth="sm">
          <div>
            <ErrorList className={classes.m2}
                       errors={restoreDialog.errors} />
            {selectedLoanOfficer &&
              <RestoreArchivedLoanOfficer loanOfficer={selectedLoanOfficer}
                                          mixpanel={this.props.mixpanel}
                                          onCancel={this.onRestoreLoanOfficerCancel}
                                          onSubmit={this.onRestoreLoanOfficerConfirm} />

            }
          </div>
        </Dialog>

        {(selectedLoanOfficer && !restoreDialog.open) &&
          <Menu anchorEl={selectedActionMenu}
                open={Boolean(selectedActionMenu)}
                onClose={this.onActionMenuClose}>
            <MenuItem component={Link} to={`/admin/loan-officers/detail/${selectedLoanOfficer._id}`}>
              <ListItemIcon>
                <Icon>remove_red_eye</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'view')} />
            </MenuItem>
            <MenuItem component={Link} to={`/admin/loan-officers/edit/${selectedLoanOfficer._id}`}>
              <ListItemIcon>
                <Icon>mode_edit</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
            </MenuItem>
            {AclUtil.isOwner(user.data) &&
              <MenuItem onClick={this.showDeleteDialog}
                        disabled={settings.data!.defaultLoanOfficer!._id === selectedLoanOfficer._id}>
                <ListItemIcon>
                  <Icon>cancel</Icon>
                </ListItemIcon>
                <ListItemText primary={IntlFormatter.formatMessage(intl, 'archive')}/>
              </MenuItem>
            }

          </Menu>
        }
        <div>

          <ErrorList errors={errors}
                     className={classes.m2}
                     onClose={() => { this.setState({ errors: [] }); } } />

          <div className={classes.pageHeaderContainer}>
            {hasInitialResults &&
              <div className={classes.rowCenterWrap}>
                <div className={classes.mr2}>
                  <SearchBar onSearchTextChanged={this.searchTextChanged}
                             onSearchTextRemove={this.searchTextRemove}
                             filterDrawerTitle={IntlFormatter.formatMessage(intl, 'filter_loan_officers')}
                             filterCount={this.getFilterCount()}>

                    {AclUtil.isOwner(user.data) &&
                      <div className={classes.mb2}>
                        <div className={classes.mb2}>
                          <AutoComplete value={searchCriteria.branches}
                                        getOptionLabel={(item:Branch) => item.name ? item.name : ''}
                                        getOptionSelected={(option:Branch, value:Branch) => {
                                          return option._id === value._id;
                                        }}
                                        onChange={this.setBranches}
                                        onTextChange={this.searchBranches}
                                        limitTags={2}
                                        multiple={true}
                                        placeholder={IntlFormatter.formatMessage(intl, 'branch')}
                                        openOnFocus={true}
                                        debounce={500} />
                        </div>
                        <FormControlLabel label={IntlFormatter.formatMessage(intl, 'archived_loan_officers')} control={
                          <Checkbox checked={searchCriteria.deleted}
                                    onChange={this.onToggleInactiveLoanOfficers}
                                    color="primary" />
                        }
                        />

                      </div>
                    }

                    <div className={classes.mb3}>
                      <Divider />
                    </div>

                    <SortField label={IntlFormatter.formatMessage(intl, 'sort_by')}
                               placeholder={IntlFormatter.formatMessage(intl, 'none')}
                               value={searchCriteria.order.column ? this.sortOptions.find(o => o.value === searchCriteria.order.column) : ''}
                               fullWidth={true}
                               options={this.sortOptions}
                               getOptionLabel={(item:LabelValuePairType) => item.label}
                               direction={searchCriteria.order.direction}
                               onChange={this.onSortFieldOptionChange}
                               onDirectionChange={this.onSortFieldDirectionChange}/>

                  </SearchBar>
                </div>
              </div>
            }

          </div>
          {ReduxUtil.hasData(loanOfficers) && loanOfficers.data.length > 0 &&
            <div>
              <div className={clsx(classes.ph2, classes.pv1)}>
                <PaginationHeader totalResults={searchCriteria.totalCount}
                                  currentPage={searchCriteria.page}
                                  totalPages={pages} />
              </div>
              <Hidden mdUp implementation="css">
                <Divider />
                <List>
                  {loanOfficers.data.map((loanOfficer, i) => {
                    return (
                      <ListItem key={i}>
                        <ListItemAvatar>
                          <UserAvatar user={loanOfficer.user} />
                        </ListItemAvatar>
                        <ListItemText primary={`${loanOfficer.firstName} ${loanOfficer.lastName}`}
                                      secondary={`${IntlFormatter.formatMessage(intl, 'nmls_id')} ${loanOfficer.nmlsId}`} />
                        <ListItemSecondaryAction>
                          <div className={classes.rowCenterEnd}>
                            <LoanOfficerProfileCompleteIcon loanOfficer={loanOfficer}
                                                            integrations={integrations.data} />
                            {!loanOfficer.deleted &&
                            <IconButton onClick={event => this.onActionMenuClick(event, loanOfficer)}>
                              <Icon>more_vert</Icon>
                            </IconButton>
                            }
                            {(loanOfficer.deleted && AclUtil.isOwner(user.data)) &&
                            <Button variant="outlined"  onClick={() => this.onRestoreLoanOfficerClick(loanOfficer)}>
                              <FormattedMessage id="restore" />
                            </Button>
                            }
                          </div>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  })}
                </List>
              </Hidden>
              <Hidden smDown implementation="css">
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel active={searchCriteria.order.column === 'lastName'}
                                          direction={searchCriteria.order.direction}
                                          onClick={() => this.onTableHeadingSortColumnChange('lastName')}>
                            <FormattedMessage id="name"/>
                          </TableSortLabel>
                        </TableCell>
                        <TableCell><FormattedMessage id="branch"/></TableCell>
                        <TableCell>
                          <TableSortLabel active={searchCriteria.order.column === 'email'}
                                          direction={searchCriteria.order.direction}
                                          onClick={() => this.onTableHeadingSortColumnChange('email')}>
                            <FormattedMessage id="email"/>
                          </TableSortLabel>
                        </TableCell>
                        <TableCell><FormattedMessage id="title"/></TableCell>
                        <TableCell>
                          <TableSortLabel active={searchCriteria.order.column === 'nmlsId'}
                                          direction={searchCriteria.order.direction}
                                          onClick={() => this.onTableHeadingSortColumnChange('nmlsId')}>
                            <FormattedMessage id="nmls_id"/>
                          </TableSortLabel>
                        </TableCell>
                        <TableCell/>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loanOfficers.data.map(loanOfficer => {
                        return (
                          <TableRow key={loanOfficer._id}>
                            <TableCell component="th" scope="row">
                              <div className={classes.rowCenter}>
                                <UserAvatar user={loanOfficer.user} className={classes.mr1} />
                                <Link to={`/admin/loan-officers/detail/${loanOfficer._id}`}>
                                  <b>{loanOfficer.firstName} {loanOfficer.lastName}</b>
                                </Link>
                                {settings.data!.defaultLoanOfficer!._id === loanOfficer._id &&
                                <Tooltip title={IntlFormatter.formatMessage(intl, 'default_loan_officer')}>
                                  <IconButton size="small" className={classes.ml1}>
                                    <Icon fontSize="small">info</Icon>
                                  </IconButton>
                                </Tooltip>
                                }
                              </div>
                            </TableCell>
                            <TableCell>{loanOfficer.branch.name}</TableCell>
                            <TableCell>
                              <ClipboardText value={loanOfficer.email}>
                                {loanOfficer.email}
                              </ClipboardText>
                            </TableCell>
                            <TableCell>{loanOfficer.title}</TableCell>
                            <TableCell>
                              <Badge color="infoAlt">
                                {loanOfficer.nmlsId}
                              </Badge>
                            </TableCell>

                            <TableCell align="right">
                              <div className={classes.rowCenterEnd}>
                                <LoanOfficerProfileCompleteIcon loanOfficer={loanOfficer}
                                                                integrations={integrations.data} />

                                {!loanOfficer.deleted &&
                                  <IconButton onClick={event => this.onActionMenuClick(event, loanOfficer)}>
                                    <Icon>more_vert</Icon>
                                  </IconButton>
                                }
                                {(loanOfficer.deleted && AclUtil.isOwner(user.data)) &&
                                  <Button variant="outlined"  onClick={() => this.onRestoreLoanOfficerClick(loanOfficer)}>
                                    <FormattedMessage id="restore" />
                                  </Button>
                                }
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </div>
              </Hidden>

              {pages > 1 &&
                <Pagination count={pages}
                            color="primary"
                            onChange={this.onPageChange} />
              }
            </div>
          }

          {(!loading && ReduxUtil.hasData(loanOfficers) && loanOfficers.data.length === 0) &&
          <div className={classes.p2}>
            <NoResultsCard message={IntlFormatter.formatMessage(intl, 'no_loan_officers_found')}>
              <div>
                {(!hasInitialResults && AclUtil.isOwner(user.data)) &&
                <div className={classes.mt1}>
                  <Button color="primary" variant="contained" component={Link} to="/admin/loan-officers/add">
                    <FormattedMessage id="add_loan_officer" />
                  </Button>
                </div>
                }
              </div>
            </NoResultsCard>
          </div>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    loanOfficers: state.loanOfficers,
    settings: state.loanSettings,
    integrations: state.integrations
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoanOfficersPage)));
