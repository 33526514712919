import {defaultRadius, hexToRgb} from '../index';
import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const styles = (theme:Theme) => createStyles({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    borderRadius: defaultRadius,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FDD6DA',
    color: '#841E30',
    '& .MuiTypography-root, .MuiIconButton-root ': {
      color: '#841E30'
    }
  },
  icon: {
    color: theme.palette.danger.main,
    marginRight: theme.spacing(1),
    borderRadius: defaultRadius,
    height: '30px',
    width: '30px',
    backgroundColor: "rgba(" + hexToRgb(theme.palette.white) + ", .45)",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginLeft: theme.spacing(2),
    borderColor: '#841E30',
    color: '#841E30'
  }
});

const useStyles = makeStyles(styles);

export default styles;
export {useStyles}