import React, {ChangeEvent} from 'react';
import Form, {ValidatorForm} from '../../../forms/Form';
import {
  FormControlLabel,
  Grid,
  Checkbox,
  withStyles, WithStyles
} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import IntlFormatter from '../../../intl';
import {BaseForm, AddressFormFields} from "../../../forms";
import {
  ErrorList,
  SubmitButton,
  MoneyInput,
  PhoneNumberInput,
  TextField,
  DatePicker,
} from '../../../components';
import _ from 'lodash';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import moment from "moment";
import {DateUtil, ErrorUtil} from "../../../utils";
import {ActionProps, ErrorState, FixMeLater} from "../../../types";
import {
  Borrower,
  BorrowerPreviousEmployment, BorrowerPreviousEmploymentUpdateRequest, LoanApplication,
  LoanApplicationRequest, LoanApplicationUpdateRequest,
  Location
} from "@jerseydev/orca-loans";
import {Moment} from "moment-timezone";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {AxiosResponse} from "axios";

type Props = {
  loanApplication: LoanApplication,
  borrower: Borrower,
  employment?: BorrowerPreviousEmployment|null,
  actions?: React.ReactNodeArray,
  onSubmit: (data:AxiosResponse<LoanApplication>) => void,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  name: string,
  title: string,
  selfEmployed: boolean,
  phoneNumber: string,
  address: {
    street1: string,
    city: string,
    province: string,
    postalCode: string
  },
  startDate: string|null,
  endDate: string|null,
  grossIncome: string
}

type State = {
  loading: boolean,
  form: Form,
  manualAddressEntry: boolean,
  errors: ErrorState[]
}

class PreviousEmploymentForm extends BaseForm<Props, State> {
  beforeDateValidation:Moment = moment();
  afterDateValidation:Moment = moment().subtract(75, 'years');

  constructor(props:Props) {
    super(props);
    
    let form:Form = {
      name: '',
      title: '',
      selfEmployed: false,
      phoneNumber: '',
      address: {
        street1: '',
        city: '',
        province: '',
        postalCode: ''
      },
      startDate: null,
      endDate: null,
      grossIncome: ''
    };

    if(props.employment) {
      const {name, title, selfEmployed, phoneNumber, address, startDate, endDate, grossIncome} = props.employment;
      form = {
        name: name ? name : '',
        title: title ? title : '',
        selfEmployed: typeof selfEmployed === 'boolean' ? selfEmployed : false,
        phoneNumber: phoneNumber ? phoneNumber : '',
        address: {
          street1: address && address.street1 ? address.street1 : '',
          city: address && address.city ? address.city : '',
          province: address && address.province ? address.province : '',
          postalCode: address && address.postalCode ? address.postalCode : ''
        },
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
        grossIncome: typeof grossIncome === 'number' ? grossIncome.toString() : ''
      };
    }

    this.state = {
      loading: false,
      form,
      manualAddressEntry: false,
      errors: []
    };
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule('isBeforeEndDate', (value) => {
      if(value && this.state.form.endDate) {
        const momentDate = moment(value);
        return momentDate.isBefore(moment(this.state.form.endDate));
      }

      return true;
    });
  }

  componentWillUnmount = () => {
    ValidatorForm.removeValidationRule('isBeforeEndDate');
  };

  onAddressChange = (address:Location) => {
    const form = _.cloneDeep(this.state.form);
    form.address = {
      street1: address.street1 ? address.street1 : '',
      city: address.city ? address.city : '',
      province: address.province ? address.province : '',
      postalCode: address.postalCode ? address.postalCode : ''
    };
    this.setState({ form });
  };

  onSubmit = async () => {
    try {
      const {loanApplication, borrower, employment} = this.props;
      this.setState({loading:true, errors: []});

      const {name, title, selfEmployed, phoneNumber, address, startDate, endDate, grossIncome} = this.state.form;
      let employmentRequest:BorrowerPreviousEmploymentUpdateRequest = {
        _id: employment ? employment._id : undefined,
        name,
        title: title !== '' ? title : null,
        selfEmployed,
        phoneNumber,
        address: {
          street1: address.street1 !== '' ? address.street1 : null,
          city: address.city !== '' ? address.city : null,
          province: address.province !== '' ? address.province : null,
          postalCode: address.postalCode !== '' ? address.postalCode : null
        },
        startDate,
        endDate,
        grossIncome: parseInt(grossIncome)
      };

      const requestData:LoanApplicationUpdateRequest = {
        borrowers: [{
          _id: borrower._id,
          previousEmployment: [employmentRequest]
        }]
      };

      const result = await this.props.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      this.setState({loading:false}, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({loading:false, errors:ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, actions, classes } = this.props;
    const { loading, form, errors } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <TextField name="name"
                         label={IntlFormatter.formatMessage(intl, 'company_name')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.name')}
                         value={form.name}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField name="title"
                         label={IntlFormatter.formatMessage(intl, 'job_title')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.title')}
                         value={form.title}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
          </Grid>
        </div>
        <div className={classes.mb2}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} md={3}>
              <PhoneNumberInput name="phoneNumber"
                                label={IntlFormatter.formatMessage(intl, 'phone_number')}
                                value={form.phoneNumber}
                                fullWidth={true}
                                onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.phoneNumber')}
                                validators={['required']}
                                errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker id="startDate"
                          label={IntlFormatter.formatMessage(intl, 'start_date')}
                          value={form.startDate}
                          onChange={(date:FixMeLater) => this.onDateChange(date, 'form.startDate')}
                          validators={[
                            'required',
                            `isBeforeDate:${this.beforeDateValidation.toISOString()}`,
                            `isAfterDate:${this.afterDateValidation.toISOString()}`,
                            'isBeforeEndDate'
                          ]}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_before_today'),
                            IntlFormatter.formatMessage(intl, 'validation_after_date', {date: DateUtil.formatDate(this.afterDateValidation)}),
                            IntlFormatter.formatMessage(intl, 'validation_before_end_date'),
                          ]}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker id="endDate"
                          label={IntlFormatter.formatMessage(intl, 'end_date')}
                          value={form.endDate}
                          onChange={(date:FixMeLater) => this.onDateChange(date, 'form.endDate')}
                          validators={[
                            'required',
                            `isBeforeDate:${this.beforeDateValidation.toISOString()}`,
                            `isAfterDate:${this.afterDateValidation.toISOString()}`
                          ]}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_before_today'),
                            IntlFormatter.formatMessage(intl, 'validation_after_date', {date: DateUtil.formatDate(this.afterDateValidation)})
                          ]}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel label={IntlFormatter.formatMessage(intl, 'self_employed')} control={
                <Checkbox checked={form.selfEmployed}
                          onChange={() => this.onCheckboxChanged('form.selfEmployed')}
                          color="primary" />
              }
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7} md={6}>
              <AddressFormFields address={form.address}
                                 required={true}
                                 onChange={this.onAddressChange} />
            </Grid>
            <Grid item xs={12} sm={5} md={3}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_income')}
                          value={form.grossIncome}
                          fullWidth={true}
                          decimalScale={0}
                          validators={['required', 'minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', {value: 0})
                          ]}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.grossIncome')}/>
            </Grid>
          </Grid>
        </div>

        <div className={classes.mt2}>
          <Grid container alignItems="center" justifyContent="flex-end" >
            {actions && actions.length > 0 &&
            <Grid item>
              <div className={classes.mr1}>
                {actions.map((action,i) => {
                  return <span key={i}>{action}</span>
                })}
              </div>
            </Grid>
            }
            <Grid item>
              <SubmitButton loading={loading}>
                <FormattedMessage id="submit" />
              </SubmitButton>
            </Grid>
          </Grid>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(PreviousEmploymentForm)));
