import palette from "../palette";
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: palette.icon
  },
  popper: {
    zIndex: 1000
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

export default styles;
