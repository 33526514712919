import React, {Component} from 'react';
import {Typography, Grid, Icon} from '@material-ui/core';

type DefaultProps = {
  variant?: 'subtitle1'|'subtitle2'
}

type Props = {
  title: string,
  icon?: string,
  action?: React.ReactNode,
  className?: string
} & Partial<DefaultProps>

class SubTitle extends Component<Props> {
  static defaultProps:DefaultProps = {
    variant: 'subtitle1'
  };

  render() {
    const {title, icon, className, action, variant, ...rest} = this.props;
    return (
      <div className={className}>
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item>
            {icon &&
              <Grid item>
                <Icon color="inherit" className="page-title-icon">{icon}</Icon>
              </Grid>
            }
            <Typography variant={variant} color="inherit" {...rest}>{title}</Typography>
          </Grid>
          {action &&
            <Grid item>
              {action}
            </Grid>
          }
        </Grid>

      </div>
    );
  }
}

export default SubTitle;
