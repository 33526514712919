import React, {Component} from 'react';
import {
  withStyles, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Button, List, Typography, WithStyles,
} from '@material-ui/core';
import {AclUtil} from "../utils";
import pageStyles from '../theme/jss/components/userListStyles';
import {FormattedMessage} from "react-intl";
import {LoanOfficerAvatar} from "./index";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {LoanOfficer} from "@jerseydev/orca-loans";
import {ReduxUser} from "../types";
import {ReduxState} from "../data/initialState";

type Props = {
  loanOfficers: LoanOfficer[],
  className?: string,
  loggedInUser: ReduxUser
} & WithStyles<typeof pageStyles>

class LoanOfficerList extends Component<Props> {
  render() {

    const { loanOfficers, classes, className, loggedInUser } = this.props;

    if(loanOfficers.length === 0) {
      return (
        <div className={classes.noResults}>
          <Typography variant="body1">
            <FormattedMessage id="no_loan_officers" />
          </Typography>
        </div>
      );
    }

    return (
      <List className={className}>
        {loanOfficers.map((loanOfficer, i) => {
          return (
            <ListItem key={i} className={classes.listItem}>
              <ListItemIcon>
                <LoanOfficerAvatar loanOfficer={loanOfficer} />
              </ListItemIcon>
              <ListItemText primary={`${loanOfficer.firstName} ${loanOfficer.lastName}`}
                            secondary={loanOfficer.email} />
              {AclUtil.isOwner(loggedInUser.data) &&
              <ListItemSecondaryAction>
                <Button variant="outlined"
                        color="primary"
                        size="small"
                        component={Link}
                        to={`/admin/loan-officers/detail/${loanOfficer._id}`}>
                  <FormattedMessage id="view" />
                </Button>
              </ListItemSecondaryAction>
              }
            </ListItem>
          )
        })}
      </List>
    )
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loggedInUser: state.user,
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(LoanOfficerList));
