import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {Page} from '../../../layouts';
import {SetupWizard} from '../components';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {AppLogo} from "../../../components";
import {Grid, Icon, Button, Typography, withStyles, WithStyles, Hidden, IconButton} from '@material-ui/core';
import {ReduxUtil, UserUtil} from "../../../utils";
import {Redirect, RouteComponentProps} from "react-router-dom";
import config from "../../../config";
import {logout} from "../../../actions/auth";
import styles from "../../../theme/jss/pages/setupStyles";
import {ActionProps, ReduxAccount, ReduxUser} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {Mixpanel} from "mixpanel-browser";
import {MixpanelConsumer} from "react-mixpanel";

type Props = {
  routeProps: ActionProps,
  account: ReduxAccount,
  user: ReduxUser,
  logout: () => void
} & RouteComponentProps
  & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  redirectTo?: string|null
}

class SetupPage extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      redirectTo: null
    }
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    const { location, account } = this.props;

    if(ReduxUtil.hasData(account) && !account.data.active && location.pathname !== '/expired') {
      this.setState({ redirectTo: '/expired' });
    }
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'setup');
  };

  onSetupComplete = () => {
    this.setState({ redirectTo: '/dashboard' });
  };

  logout = (event:MouseEvent) => {
    event.preventDefault();
    this.props.logout();
    this.setState({ redirectTo: '/login' });
  };


  render() {
    const { classes, user } = this.props;
    const {redirectTo} = this.state;

    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo }}  />
      )
    }

    return (
      <Page pageTitle={this.getPageTitle()}
            hasAppBar={false}
            loading={false}
            className={classes.houseBg1}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <header className={classes.header}>
              <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Hidden smUp>
                    <AppLogo color="light" size="sm" />
                  </Hidden>
                  <Hidden xsDown>
                    <AppLogo color="light" size="md" />
                  </Hidden>
                </Grid>
                <Grid item>
                  <Hidden smUp>
                    <IconButton color="primary"
                                size="small"
                                onClick={this.logout}>
                      <Icon>power_settings_new</Icon>
                    </IconButton>
                  </Hidden>
                  <Hidden xsDown>
                    <Button variant="contained"
                            color="primary"
                            startIcon={<Icon>power_settings_new</Icon>}
                            onClick={this.logout}>
                      <FormattedMessage id="sign_out" />
                    </Button>
                  </Hidden>
                </Grid>
              </Grid>
            </header>

            <div className={classes.mb3}>
              <div className={classes.hero}>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12} sm={9}>
                    <Typography variant="h2" gutterBottom>
                      <FormattedMessage id="welcome_to_name"
                                        values={{ name: config.app.name }} />
                    </Typography>
                    <Typography variant="body1">
                      <FormattedMessage id="setup_page_text" />
                    </Typography>
                  </Grid>
                  <Hidden xsDown>
                    <Grid item xs={12} sm={3}>
                      <img src="/assets/img/party-popper.png"
                           alt="Party Popper"
                           className={classes.imgResponsive} />
                    </Grid>
                  </Hidden>
                </Grid>
              </div>
            </div>
          </div>
          <MixpanelConsumer>
            {(mixpanel:Mixpanel) => {
              return (
                <div className={classes.setupContainer}>
                  <div className={classes.setupContent}>
                    {UserUtil.isGranted(user.data, ['ROLE_OWNER']) &&
                      <SetupWizard mixpanel={mixpanel}
                                   onComplete={this.onSetupComplete} />
                    }
                    {!UserUtil.isGranted(user.data, ['ROLE_OWNER']) &&
                      <FormattedMessage id="account_not_configured" />
                    }
                  </div>
                </div>
              )
            }}
          </MixpanelConsumer>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  logout() {
    dispatch(logout())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(SetupPage)));
