import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {
  Typography,
  Grid,
  Icon,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  withStyles,
  Hidden,
  Button, WithStyles
} from "@material-ui/core";
import {
  DeleteDialog,
  ErrorList,
  PageTitle,
  PairedList,
  PhoneNumberList,
  NoResultsCard,
  Section,
  LoanOfficerBorrowerSignupLink,
} from "../../../components";
import {Redirect, Link, RouteComponentProps} from "react-router-dom";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import _ from 'lodash';
import config from "../../../config";
import clsx from "clsx";
import {AclUtil, ErrorUtil} from "../../../utils";
import {KeyValueMixed, LoanOfficer} from "@jerseydev/orca-loans";
import {ReduxLoanSettings, ReduxUser, ActionProps, SnackbarState, ErrorState} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel
  settings: ReduxLoanSettings,
  user: ReduxUser
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps<{id:string}>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  loanOfficer: LoanOfficer|null,
  selectedActionMenu?: Element|null,
  deleteDialogOpen: boolean,
  errors: ErrorState[],
  errorStatusCode?: number,
  redirectTo?: string,
  snackbar?: SnackbarState
}

class LoanOfficerDetailPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      loanOfficer: null,
      deleteDialogOpen: false,
      errors: []
    };
  }

  componentDidMount = () => {
    this.props.routeProps.getLoanOfficer(this.props.match.params.id).send().then(result => {
      this.setState({ pageLoaded: true, loanOfficer: _.cloneDeep(result.data) });
    }).catch(err => {
      this.setState({ pageLoaded: true, errorStatusCode: err.response ? err.response.status : err.status });
    });
  };

  getPageTitle = () => {
    const { loanOfficer } = this.state;
    if(loanOfficer) {
      return `${loanOfficer.firstName} ${loanOfficer.lastName}`;
    }

    return '';
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="library_books" />
    )
  };

  getDetailItems = () => {
    const { intl } = this.props;
    const { loanOfficer } = this.state;

    let items:KeyValueMixed[] = [];
    if(loanOfficer) {
      let stateLicensesText = loanOfficer.stateLicenses.length === 50 ? IntlFormatter.formatMessage(intl, 'all_states') : loanOfficer.stateLicenses.join(', ').toUpperCase();
      items = [
        { key: IntlFormatter.formatMessage(intl, 'email'), value: loanOfficer.email },
        { key: IntlFormatter.formatMessage(intl, 'nmls_id'), value: loanOfficer.nmlsId },
        { key: IntlFormatter.formatMessage(intl, 'state_licenses'), value: stateLicensesText }
      ];

      if(loanOfficer.title) {
        items.unshift({ key: IntlFormatter.formatMessage(intl, 'title'), value: loanOfficer.title });
      }

      loanOfficer.phoneNumbers.forEach(phoneNumber => {
        items.push({ key: IntlFormatter.formatMessage(intl, phoneNumber.type ? phoneNumber.type : 'other'), value: `${phoneNumber.number} ${phoneNumber.ext ? `x${phoneNumber.ext}` : ''}` })
      });
    }

    return items;
  };

  onActionMenuClick = (event:MouseEvent) => {
    this.setState({ selectedActionMenu: event.currentTarget });
  };

  onActionMenuClose = () => {
    this.setState({ selectedActionMenu: null });
  };

  showDeleteDialog = () => {
    this.setState({ selectedActionMenu: null, deleteDialogOpen: true });
  };

  hideDeleteDialog = () => {
    this.setState({ deleteDialogOpen: false });
  };

  onDeleteSubmit = async () => {
    try {
      this.setState({ deleteDialogOpen: false, loading: true });
      if(this.state.loanOfficer) {
        await this.props.routeProps.deleteLoanOfficer(this.state.loanOfficer._id).send();
        this.props.mixpanel.track("Loan officer archived");
      }
      this.setState({
        loading: false,
        redirectTo: '/admin/loan-officers',
        snackbar: {
          open: true,
          variant: 'success',
          message: IntlFormatter.formatMessage(this.props.intl, 'loan_officer_archived')
        },
      });
    } catch(e) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, classes, settings, user } = this.props;
    const { pageLoaded, loanOfficer, selectedActionMenu, deleteDialogOpen, redirectTo, snackbar, errors, errorStatusCode } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo, state: { snackbar } }}  />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   pageLoaded={pageLoaded}
                   titleBar={this.renderTitleBar()}
                   errorStatusCode={errorStatusCode}
                   breadcrumbs={[
                    {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                    {title: IntlFormatter.formatMessage(intl, 'loan_officers'), to: '/admin/loan-officers' },
                    {title: this.getPageTitle() }
                  ]}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />
        {!loanOfficer &&
          <NoResultsCard message={IntlFormatter.formatMessage(intl, 'no_results_found')} />
        }
        {loanOfficer &&
        <div>
          <DeleteDialog open={deleteDialogOpen}
                        title={IntlFormatter.formatMessage(intl, 'archive_loan_officer')}
                        item={`${loanOfficer.firstName} ${loanOfficer.lastName}`}
                        confirmationMessage={IntlFormatter.formatMessage(intl, 'archive_confirmation', { value: `${loanOfficer.firstName} ${loanOfficer.lastName}` })}
                        secondConfirmationMessage={IntlFormatter.formatMessage(intl, 'archive_second_confirmation', { value: `${loanOfficer.firstName} ${loanOfficer.lastName}` })}
                        deleteButtonLabel={IntlFormatter.formatMessage(intl, 'archive')}
                        onCancel={this.hideDeleteDialog}
                        onSubmit={this.onDeleteSubmit} />

          {loanOfficer &&
            <Menu anchorEl={selectedActionMenu}
                  open={Boolean(selectedActionMenu)}
                  onClose={this.onActionMenuClose}>
              <MenuItem component={Link} to={`/admin/loan-officers/edit/${loanOfficer._id}`}>
                <ListItemIcon>
                  <Icon>mode_edit</Icon>
                </ListItemIcon>
                <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
              </MenuItem>
              {AclUtil.isOwner(user.data) &&
                <MenuItem onClick={this.showDeleteDialog} disabled={settings.data.defaultLoanOfficer!._id === loanOfficer._id}>
                  <ListItemIcon>
                    <Icon>cancel</Icon>
                  </ListItemIcon>
                  <ListItemText primary={IntlFormatter.formatMessage(intl, 'archive')} />
                </MenuItem>
              }
            </Menu>
          }

          <div style={{ overflow: 'hidden' }}>
            <div className={classes.ph2}>
              <Grid container alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <IconButton onClick={this.onActionMenuClick}>
                    <Icon>more_vert</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <Divider />
            {loanOfficer.picture &&
            <Hidden smUp>
              <div className={clsx(classes.center, classes.mv2)}>
                <img src={loanOfficer.picture ? loanOfficer.picture.url : config.placeholders.person}
                     alt={loanOfficer.fullName}
                     className={classes.thumbnail} />
              </div>
            </Hidden>
            }

            <div className={classes.p2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8}>
                  <Grid container spacing={2}>
                    <Hidden xsDown>
                      <Grid item sm={3}>
                        <div className={classes.ml2}>
                          <img src={loanOfficer.picture ? loanOfficer.picture.url : config.placeholders.person}
                               alt={loanOfficer.fullName}
                               className={classes.imgResponsive} />
                        </div>
                      </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={9}>
                      {loanOfficer.description &&
                      <div className={clsx(classes.mb2, classes.ph2)}>
                        <Typography variant="body1">
                          {loanOfficer.description}
                        </Typography>
                      </div>
                      }
                      <PairedList items={this.getDetailItems()} />

                      <div className={classes.mt3}>
                        <LoanOfficerBorrowerSignupLink email={loanOfficer.email} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Section title={IntlFormatter.formatMessage(intl, 'branch')}
                           icon={<Icon>business</Icon>}
                           actions={
                             <Button color="primary"
                                     variant="outlined"
                                     size="small"
                                     component={Link}
                                     to={`/admin/branches/detail/${loanOfficer.branch._id}`}>
                               <FormattedMessage id="view" />
                             </Button>
                           }>
                  </Section>
                  <div className={classes.ph2}>
                    <Typography variant="subtitle1">
                      {loanOfficer.branch.name}
                    </Typography>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography variant="body1">{loanOfficer.branch.email}</Typography>
                        </Grid>
                      </Grid>
                      {loanOfficer.branch.phoneNumbers.length > 0 &&
                        <PhoneNumberList phoneNumbers={loanOfficer.branch.phoneNumbers}/>
                      }
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        }
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    settings: state.loanSettings,
    user: state.user
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoanOfficerDetailPage)));
