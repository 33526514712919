import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Icon
} from '@material-ui/core';
import {useStyles} from '../theme/jss/components/noResultsCardStyles';
import clsx from "clsx";

type Props = {
  children?: React.ReactNode,
  icon?: React.ReactNode,
  message: string,
  className?: string
}

const NoResultsCard = (props:Props) => {
  const { children, icon, message, className } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      <div className={classes.iconContainer}>
        {icon ? icon : <Icon>report_problem</Icon>}
      </div>
      <CardContent className={classes.content}>
        <div>
          {message &&
            <div className={classes.messageContainer}>
              <Typography className={classes.message}>
                {message}
              </Typography>
            </div>
          }
          {children &&
            <div>
              {children}
            </div>
          }
        </div>
      </CardContent>
    </Card>
  );
}

export default NoResultsCard;
