import axios, {AxiosResponse} from 'axios';
import qs from "query-string";

class EncompassApi {
  static token = null;
  static baseUrl = 'https://api.elliemae.com';
  static encompassApiUrl = 'https://api.elliemae.com/encompass/v1';

  static getAuthorizationHeader = () => {
    if(!EncompassApi.token) {
      throw new Error('Token is required for request');
    }
    return { 'authorization': `Bearer ${EncompassApi.token}` };
  };

  static getToken = (clientId:string, clientSecret:string, instanceId:string, username:string, password:string):Promise<AxiosResponse> => {
    const data = {
      grant_type: 'password',
      username: `${username}@encompass:${instanceId}`,
      password
    };

    const auth = {
      username: clientId,
      password: clientSecret
    };

    return axios({
      url: 'https://api.elliemae.com/oauth2/v1/token',
      method: 'post',
      data: qs.stringify(data),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      auth
    });
  };

  static getPersonas = (params:any|undefined = undefined):Promise<AxiosResponse> => {
    const headers = EncompassApi.getAuthorizationHeader();

    return axios({
      url: `${EncompassApi.encompassApiUrl}/settings/personas`,
      method: 'get',
      headers,
      params
    });
  };

  static getLoanTemplateFolders = (folder: string):Promise<AxiosResponse> => {
    const headers = EncompassApi.getAuthorizationHeader();

    return axios({
      url: `${EncompassApi.encompassApiUrl}/settings/templates/loanTemplateSet/folders/${folder}`,
      method: 'get',
      headers
    });
  };

  static getLoanTemplateFiles = (path: string):Promise<AxiosResponse> => {
    const headers = EncompassApi.getAuthorizationHeader();

    return axios({
      url: `${EncompassApi.encompassApiUrl}/settings/templates/loanTemplateSet/items`,
      method: 'get',
      headers,
      params: { path }
    });
  };
}

export default EncompassApi