import React from 'react';
import {PersonAvatar} from "./index";
import {LoanOfficerProfile} from "@jerseydev/orca-loans";
import {PersonAvatarProps} from "./PersonAvatar";

type Props = {
  loanOfficer: LoanOfficerProfile
} & Omit<PersonAvatarProps, "person">

const LoanOfficerProfileAvatar = (props:Props) => {
  const { loanOfficer, ...rest } = props;
  const {firstName, lastName, email, avatar, user} = loanOfficer;
  return <PersonAvatar person={{
    firstName,
    lastName,
    email,
    avatar,
    userId: user
  }} {...rest} />
}

export default LoanOfficerProfileAvatar;
