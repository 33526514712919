import {AxiosInstance, AxiosResponse} from "axios";
import jwt from "jsonwebtoken";
import {store} from "../index";
import {refreshToken} from "../actions/auth";
import {DecodedJwtUser} from "../types";

type PendingRequest = (newToken: string) => void

export default class TokenRefresher {
  isRefreshing: boolean;
  instance: AxiosInstance;
  pendingRequests: PendingRequest[];
  handleRefreshedToken: (token: string) => void;

  ignoredRequests = ["/v1/token"];

  constructor(instance: AxiosInstance, handleRefreshedToken: (token: string) => void, ignoredRequests?: string[]) {
    this.instance = instance;
    this.isRefreshing = false;
    this.pendingRequests = [];
    this.handleRefreshedToken = handleRefreshedToken;

    if (ignoredRequests) {
      this.ignoredRequests = ignoredRequests;
    }
  }

  private isIgnoredRequest = (url: string) => !!this.ignoredRequests.find(
    ignoredRequest => url === ignoredRequest
  )

  private shouldAttemptTokenRefresh = (response: AxiosResponse) => (response.status === 401 && response.data.name === 'TokenExpiredError');

  private processPendingRequests = ((newToken: string) => {
    this.pendingRequests.forEach(callBack => callBack(newToken));
    this.pendingRequests = [];
  });

  static verify = async (token:string):Promise<DecodedJwtUser> => {
    return new Promise((resolve: Function, reject: Function) => {
      let publicKey = process.env.REACT_APP_API_JWT_PUBLIC_KEY || "-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCJ68IeRGEPhutpmYD9dCFlYWW0\n9IOVh4eIjTiHzL+EgTh+ccrRvKogSw6MyBNoAZf5r7UseIiLnEn8CQmHr1nCEuIF\nPl88siI9BjKbw5h84a9x8g6sDMFiRv5SJQtP952l9O9f/zwxa9vUCsoDluDFuwQg\ng67gZHhckAOp4dNHGwIDAQAB\n-----END PUBLIC KEY-----";
      if(!publicKey) {
        throw new Error(`${process.env.REACT_APP_API_JWT_PUBLIC_KEY} is undefined`)
      }

      jwt.verify(token, publicKey, { algorithms: ['RS256'] }, (err:any, payload:DecodedJwtUser) => {
        if (err) {
          return reject(err);
        }

        resolve(payload);
      });
    });
  };

  responseErrorInterceptor = (err: any) => {
    const {config: originalConfig, response} = err;
    const {url} = originalConfig;

    if (this.isIgnoredRequest(url)) {
      return Promise.reject(err)
    }

    if (!response) {
      return Promise.reject(err);
    }

    if (!this.shouldAttemptTokenRefresh(response)) {
      return Promise.reject(err);
    }

    if (this.isRefreshing) {
      // We are stalling request with unresolved promise. We store promise resolving function into pendingRequests.
      return new Promise((resolve) => {
        this.pendingRequests.push((newToken: string) => {
          originalConfig._retry = true;
          originalConfig.headers['Authorization'] = newToken
          resolve(this.instance(originalConfig))
        })
      })
    }

    this.isRefreshing = true;

    let refreshTokenRequest
    try {
      refreshTokenRequest = store.dispatch(refreshToken());
    } catch (error) {
      this.isRefreshing = false;
      console.trace(`Failed to dispatch refreshToken(). Error: ${error.toString()}`)

      return Promise.reject(error)
    }

    return refreshTokenRequest
      .send()
      .then((result: any) => {
        this.handleRefreshedToken(result.data.access_token)

        const newToken = `Bearer ${result.data.access_token}`

        this.processPendingRequests(newToken)

        originalConfig._retry = true;
        originalConfig.headers.Authorization = newToken;
        response.config.headers.Authorization = newToken;

        return this.instance(originalConfig);
      })
      .catch((error: Error) => {
        console.trace(`Failed to refresh the token. Error: ${error.toString()}`)
      })
      .finally(() => {
        this.isRefreshing = false
      })
  }
}
