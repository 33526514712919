import React, {Component, MouseEvent} from 'react';
import {
  Avatar,
  Popover,
  IconButton,
  Icon,
  withStyles,
  Badge, WithStyles, AvatarProps
} from '@material-ui/core';
import {UserUtil} from "../utils";
import {injectIntl, WrappedComponentProps} from "react-intl";
import clsx from "clsx";
import {connect} from "react-redux";
import styles from "../theme/jss/components/personAvatarStyles";
import {ReduxState} from "../data/initialState";
import {Person, ReduxUser} from "../types";
import {Mixpanel} from "mixpanel-browser";
import {MixpanelConsumer} from "react-mixpanel";
import {PersonCard} from "./index";

type DefaultProps = {
  popoverEnabled: boolean,
  size: 'small'|'normal'|'large'|'extraLarge',
  variant: 'standard'|'outlined'|'square',
  color: 'default'|'primary'|'secondary'|'info'|'success'|'warn'|'danger'
}

export type PersonAvatarProps = {
  person: Person,
  className?: string
} & Partial<DefaultProps>

type Props = {
  loggedInUser: ReduxUser,
} & PersonAvatarProps
  & WrappedComponentProps
  & WithStyles<typeof styles>
  & Omit<AvatarProps, 'size'|'variant'|'color'>

type State = {
  popoverAnchorEl: Element|null,
  messageDialogOpen: boolean,
  snackbarOpen: boolean,
  popoverOpen: boolean
}

class UserAvatar extends Component<Props, State> {
  static defaultProps:DefaultProps = {
    popoverEnabled: true,
    size: 'normal',
    variant: 'standard',
    color: 'default'
  };

  constructor(props:Props) {
    super(props);

    this.state = {
      popoverAnchorEl: null,
      messageDialogOpen: false,
      snackbarOpen: false,
      popoverOpen: false
    }
  }

  onPopoverOpen = (event:MouseEvent) => {
    if(this.props.popoverEnabled) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();

      this.setState({ popoverAnchorEl: event.currentTarget });
    }
  };

  onPopoverClose = (event:MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ popoverAnchorEl: null });
  };

  renderAvatar = () => {
    // @ts-ignore
    const { intl, person, classes, popoverEnabled, loggedInUser, dispatch, size, variant, color, ...rest } = this.props;
    const { popoverOpen } = this.state;
    return (
      <div className={clsx(classes.root, classes[variant ? variant : 'standard'], color ? classes[color] : undefined)}>
        {person.avatar &&
            <Avatar src={person.avatar}
                    aria-owns={popoverOpen ? 'popover' : undefined}
                    aria-haspopup="true"
                    className={classes[size!]}
                    onClick={this.onPopoverOpen}
                    {...rest} />
        }

        {!person.avatar &&
          <Avatar aria-owns={popoverOpen ? 'popover' : undefined}
                  aria-haspopup="true"
                  className={classes[size!]}
                  onClick={this.onPopoverOpen}
                  {...rest}>{UserUtil.getInitials(person)}</Avatar>
        }
      </div>
    )
  };

  render() {

    const { person, classes, popoverEnabled } = this.props;
    const { popoverAnchorEl } = this.state;
    const popoverOpen = Boolean(popoverAnchorEl);

    return (
      <div>
        <div className={popoverEnabled ? classes.cursorPointer : undefined}>
          {!person.deleted &&
            this.renderAvatar()
          }
          {person.deleted &&
            <Badge overlap="circle"
                   badgeContent={<Icon style={{zIndex: 1000}} className={clsx(classes.avatarBadgeIcon, classes.warning)}>warning</Icon>}>
              {this.renderAvatar()}
            </Badge>
          }
        </div>

        <Popover
          id="popover"
          open={popoverOpen}
          anchorEl={popoverAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={this.onPopoverClose}
          className={clsx(classes.popoverContainer, classes.ml1)}
        >
          <div className={classes.p2} style={{minWidth: 250}}>
            <header className={clsx(classes.rowCenterEnd, classes.popoverHeader)}>
              <IconButton size="small" onClick={this.onPopoverClose}>
                <Icon fontSize="small">close</Icon>
              </IconButton>
            </header>
            <div className={classes.popoverContent}>
              <MixpanelConsumer>
                {(mixpanel:Mixpanel) => {
                  return <PersonCard person={person}
                                     mixpanel={mixpanel}
                                     orientation="vertical"
                                     elevation={0} />
                }}
              </MixpanelConsumer>
            </div>
          </div>
        </Popover>
      </div>
    )
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loggedInUser: state.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(injectIntl(UserAvatar)));
