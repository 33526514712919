import React from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Link, WithStyles
} from '@material-ui/core';
import {FormattedMessage} from 'react-intl';
import {Link as RouterLink} from 'react-router-dom';
import pageStyles from "../theme/jss/layouts/pageStyles";
import {BorrowerPersonalInfoSummary, SubTitle, Well} from "./index";
import {
  LoanApplication2009,
  Borrower2009,
  LoanApplication,
  Borrower
} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication
} & WithStyles<typeof pageStyles>


const LoanBorrowerPersonalSummary = (props:Props) => {

  const {classes, loanApplication} = props;

  if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
    return (
      <Grid container spacing={2}>
        {loanApplication.borrowers.map((borrower:Borrower2009|Borrower, borrowerIndex:number) => {
          return (
            <Grid key={borrowerIndex} item xs={12}
                  // @ts-ignore
                  sm={loanApplication.borrowers.length > 1 ? 12 / loanApplication.borrowers.length : 6}>
              <div className={classes.mb2}>
                <SubTitle title={`${borrower.firstName} ${borrower.lastName}`} />
              </div>
              <BorrowerPersonalInfoSummary loanApplication={loanApplication}
                                           borrower={borrower}/>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return (
    <Well color="warn">
      <Typography variant="body1">
        <FormattedMessage id="no_personal_info_found" />&nbsp;
        <Link component={props => <RouterLink to="/apply/personal" {...props} />}>
          <FormattedMessage id="add_personal_info" />
        </Link>
      </Typography>
    </Well>
  );
}

export default withStyles(pageStyles, { withTheme: true })(LoanBorrowerPersonalSummary);