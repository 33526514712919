import React from 'react';
import {
  Divider,
  Grid,
  Icon,
  Card, withStyles, CardHeader, CardContent, WithStyles,
} from '@material-ui/core';
import {Form} from '../../../forms';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {LoanApplicationPage} from "../layouts";
import {ErrorUtil, LoanRev2Util, ReduxUtil} from "../../../utils";
import BaseLoanApplicationPage from "./BaseLoanApplicationPage";
import _ from 'lodash';
import {DeclarationsFormFields} from '../forms';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {LoanApplicationControls} from "../components";
import {ErrorList, UnsavedChangesPrompt} from "../../../components";
import {ActionProps, ErrorState, ReduxLoanApplication, ReduxLoanSettings} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";
import {
  LoanApplication,
  Borrower,
  BorrowerDeclarations,
  LoanApplicationUpdateRequest,
  BorrowerUpdateRequest
} from "@jerseydev/orca-loans";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  loading: boolean,
  loanApplication: LoanApplication|null,
  redirectTo?: string,
  formPristine: boolean,
  errors: ErrorState[],
  isStepCompleted?: boolean
}

class DeclarationsPage extends BaseLoanApplicationPage<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: !ReduxUtil.hasData(props.loanApplication),
      loanApplication: ReduxUtil.hasData(props.loanApplication) ? _.cloneDeep(props.loanApplication.data) : null,
      formPristine: true,
      errors: []
    };
  }

  static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: Readonly<State>){
    if(nextProps.loanApplication && nextProps.loanApplication.data && prevState.isStepCompleted === undefined){
      return {isStepCompleted: LoanRev2Util.isDeclarationsCompleted(nextProps.loanApplication.data)};
    }
    return null;
  };

  componentDidUpdate = () => {
    if(!this.state.loanApplication && ReduxUtil.hasData(this.props.loanApplication)) {
      this.setState({ loanApplication: _.cloneDeep(this.props.loanApplication.data), loading: false });
    }
  };

  componentWillUnmount = () => {
    const {mixpanel, loanApplication} = this.props;
    if(loanApplication.data && !this.state.isStepCompleted && LoanRev2Util.isDeclarationsCompleted(loanApplication.data)) {
      mixpanel.track("Loan application step completed", {step: 'declarations'});
    }
  };

  onDeclarationsChange = (borrower:Borrower, declarations:BorrowerDeclarations) => {
    const loanApplication = _.cloneDeep(this.state.loanApplication);
    if(loanApplication && loanApplication.borrowers) {
      const borrowerIndex = loanApplication.borrowers.findIndex(b => b._id === borrower._id);
      loanApplication.borrowers[borrowerIndex].declarations = declarations;
    }

    this.setState({ loanApplication, formPristine: false });
  };

  onNextClick = async () => {
    try {
      this.setState({ loading: true });
      const loanApplication = _.cloneDeep(this.state.loanApplication);

      if(loanApplication && loanApplication.borrowers) {
        const borrowersRequestData:BorrowerUpdateRequest[] = [];
        loanApplication.borrowers.forEach(borrower => {
          const {_id, declarations} = borrower;
          borrowersRequestData.push({
            _id,
            declarations
          });
        });

        const requestData:LoanApplicationUpdateRequest = {
          borrowers: borrowersRequestData
        };

        await this.props.routeProps.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      }

      this.setState({ loading: false, formPristine: true, redirectTo: this.nextMenuItem.to });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes, loanApplication } = this.props;
    const { loading, redirectTo, formPristine, errors } = this.state;


    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <LoanApplicationPage menuId="declarations"
                           loading={loading}>
        <UnsavedChangesPrompt when={!formPristine} />

        <div className={classes.content}>
          <ErrorList className={classes.mv2}
                     errors={errors}
                     onClose={() => { this.setState({errors: []})}} />

          <Form onSubmit={this.onNextClick}>
            <div>
              {(ReduxUtil.hasData(loanApplication) && loanApplication.data.borrowers) &&
              <Grid container spacing={4}>
                {loanApplication.data.borrowers.map((borrower, i) => {
                  return (
                    <Grid key={i} item md={6}>
                      <Card className={classes.card}>
                        <CardHeader title={`${borrower.firstName} ${borrower.lastName}`}
                                    subheader={IntlFormatter.formatMessage(intl, borrower.primaryBorrower ? 'borrower' : 'co_borrower')}
                                    avatar={<Icon>{borrower.primaryBorrower ? 'person' : 'person_outline'}</Icon>}/>
                        <Divider />

                        <CardContent>
                          <DeclarationsFormFields loanApplication={loanApplication.data}
                                                  declarations={borrower.declarations}
                                                  onChange={declarations => this.onDeclarationsChange(borrower, declarations)}/>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                })}
              </Grid>
              }
            </div>

            <div className={classes.pt2}>
              <LoanApplicationControls prev={this.previousMenuItem}
                                       next={this.nextMenuItem}
                                       nextButtonProps={{ type: 'submit', loading }} />
            </div>
          </Form>
        </div>

      </LoanApplicationPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    settings: state.loanSettings
  };
};


export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(DeclarationsPage)));
