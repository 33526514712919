import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    color: theme.palette.neutral.dark
  },
  title: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  }
});

export default styles;
