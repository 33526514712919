import {defaultRadius} from "../index";
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: theme.spacing(2),
    display: 'inline-block'
  },
  iconPaper: {
    textAlign: 'center',
    width: 60,
    height: 60,
    borderRadius: defaultRadius,
    lineHeight: '70px'
  }
});

export default styles;
