import {createStyles, Theme} from "@material-ui/core";


const styles = (theme:Theme) => createStyles({
  cardHeader: {
    '& .MuiTypography-body2': {
      fontSize: '15px'
    }
  },
  list: {
    '& .MuiListItemIcon-root': {
      minWidth: 30
    }
  },
  headerAvatar: {
    backgroundColor: theme.palette.success.main
  },
  headerIcon: {
    color: theme.palette.success.contrastText
  }
});

export default styles;
