import React from 'react';
import {
  Icon,
  IconButton as MuiIconButton,
  CircularProgress,
  withStyles, WithStyles, IconButtonProps,
} from '@material-ui/core';
import styles from "../theme/jss/components/iconButtonStyles";
import {FixMeLater} from "../types";

type DefaultProps = {
  loading: boolean
}

type Props = {
  children?: React.ReactNode,
  classes?:string
} & Partial<DefaultProps>
  & WithStyles<typeof styles>
  & IconButtonProps

const IconButton = React.forwardRef((props:Props, ref:FixMeLater) => {
  const { children, classes, loading, ...rest } = props;

  return (
    <div ref={ref} className={classes.root}>
      <MuiIconButton {...rest}>
        {loading &&
          <Icon>timer</Icon>
        }
        {!loading && children}
      </MuiIconButton>
      {loading &&
        <CircularProgress className={classes.progress} />
      }
    </div>
  )
});

const defaultProps:DefaultProps = {
  loading: false
};

IconButton.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(IconButton);