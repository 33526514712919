import React, {ChangeEvent} from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MUISelect,
  MenuItem,
  Icon,
  withStyles, WithStyles
} from '@material-ui/core';
import {ValidatorComponent, ValidatorComponentProps} from 'react-material-ui-form-validator';
import _ from 'lodash';
import {Tooltip} from "./index";
import styles from "../theme/jss/components/selectStyles";
import IntlFormatter from "../intl";
import {injectIntl, WrappedComponentProps} from "react-intl";

type DefaultProps = {
  variant?: 'filled' | 'outlined' | 'standard'
}

export type Props = {
  value?:any,
  onChange?: (value:any) => void,
  className?: string,
  loading?: boolean
} & ValidatorComponentProps
  & Partial<DefaultProps>
  & WithStyles<typeof styles>
  & WrappedComponentProps

type State = {
  isValid:boolean
}

class Select extends ValidatorComponent<Props, State> {
  isRequired = () => {
    let required = false;
    if(this.props.validators && this.props.validators.indexOf('required') > -1) {
      required = true;
    }

    return required;
  };

  renderError = () => {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <FormHelperText>
        {this.getErrorMessage()}
      </FormHelperText>
    );
  };

  renderOptionLabel = (option:any) => {
    let label = typeof option === 'string' ? option : option.label;
    if(this.props.getOptionLabel) {
      label = this.props.getOptionLabel(option);
    }
    return label;
  };

  onChange = (event:ChangeEvent<{value: unknown}>) => {
    if(this.props.onChange) {
      this.props.onChange(_.cloneDeep(event.target.value));
    }
  };

  renderValidatorComponent() {

    const { classes, intl, className, label, validators, value, options, placeholder, variant, size, disabled, loading } = this.props;
    const { isValid } = this.state;

    return (
      <FormControl fullWidth={true}
                   error={!isValid}
                   variant={variant ? variant : 'outlined'}
                   size={size ? size : 'medium'}
                   className={className}>
        {label &&
          <InputLabel disabled={disabled || loading}>
            {this.isRequired() && label ? `${label} *` : label}
          </InputLabel>
        }
        <MUISelect value={value}
                   onChange={this.onChange}
                   displayEmpty={placeholder && !label}
                   disabled={disabled || loading}
                   renderValue={(value:any) => {
                     return loading ? `${IntlFormatter.formatMessage(intl, 'loading')}...` : this.renderOptionLabel(value);
                   }}
                   label={this.isRequired() && label ? `${label} *` : label}>
          {placeholder &&
            <MenuItem value={undefined}>
              <em>{placeholder}</em>
            </MenuItem>
          }
          {options.map((option:any, i:number) => {
            return (
              <MenuItem key={i} value={option} className={classes.menuItem}>
                {this.renderOptionLabel(option)}
                {option.helpText &&
                  <Tooltip title={option.helpText} placement="left">
                    <Icon fontSize="small" className={classes.helpIcon}>info</Icon>
                  </Tooltip>
                }
              </MenuItem>
            )
          })}
        </MUISelect>
        {validators && validators.length > 0 &&
          this.renderError()
        }
      </FormControl>
    );
  }
}
export default withStyles(styles, { withTheme: true })(injectIntl(Select));
