import {defaultSpacing} from "../constants";
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  icon: {
    marginRight: theme.spacing(1)
  },
  titleContainer: {
    marginBottom: theme.spacing(1)
  },
  content: {
    padding: `${theme.spacing(1)}px 0`,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  contentContainerWithAction: {
    width: '90%',
    '& $content': {
      paddingRight: theme.spacing(2)
    }
  }
});

export default styles;
export {defaultSpacing}