import React, {MouseEvent} from 'react';
import {
  Button,
  IconButton,
  Icon,
  withStyles, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import _ from 'lodash';
import {connect} from "react-redux";
import {updateUser} from "../actions/users";
import {updateLoanOfficer} from "../actions/loanOfficers";
import {ErrorUtil, UserUtil} from "../utils";
import pageStyles from "../theme/jss/layouts/pageStyles";
import {ErrorList, Loader, Alert} from "../components";
import {LoanOfficerUpdateRequest, User, UserUpdateRequest} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";
import {ActionResponse, ErrorState} from "../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  user: User,
  onSubmit: (data:AxiosResponse) => void,
  onCancel: () => void,
  updateUser: (id:string, data:UserUpdateRequest, params?:any) => ActionResponse,
  updateLoanOfficer: (id:string, data:LoanOfficerUpdateRequest, params?:any) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  loading:boolean,
  errors: ErrorState[]
}

class EnableDeletedUserForm extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      errors: []
    };

  }

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const {user} = this.props;
      let result;
      // @ts-ignore
      if(UserUtil.hasRole(user, 'ROLE_LOAN_OFFICER') && this.props.user.additionalProps && this.props.user.additionalProps.loanOfficer) {
        // @ts-ignore
        result = await this.props.updateLoanOfficer(this.props.user.additionalProps.loanOfficer._id, { deleted: false }, { deleted: true }).send();
      } else {
        result = await this.props.updateUser(this.props.user._id, { deleted: false }, { deleted: true }).send();
      }
      this.props.mixpanel.track("User restored");
      this.setState({ loading: false });
      this.props.onSubmit(_.cloneDeep(result));
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { classes, user, onCancel } = this.props;
    const { loading, errors } = this.state;

    return (
      <div>
        <Loader visible={loading} />
        <ErrorList errors={errors}
                   className={classes.mb2}
                   onClose={() => {this.setState({errors: []})}} />
        <Alert severity="warning" action={
          <div className={classes.rowCenter}>
            <div className={classes.mr2}>
              <Button variant="outlined" onClick={this.onSubmit}>
                <FormattedMessage id="restore" />
              </Button>
            </div>

            {onCancel &&
              <IconButton onClick={onCancel} size="small">
                <Icon>cancel</Icon>
              </IconButton>
            }
          </div>
        }>
          {UserUtil.hasRole(user, 'ROLE_LOAN_OFFICER') &&
            <FormattedMessage id="loan_officer_has_been_archived_restore" values={{ user: user.email }} />
          }
          {!UserUtil.hasRole(user, 'ROLE_LOAN_OFFICER') &&
          <FormattedMessage id="user_has_been_archived_restore" values={{ user: user.email }} />
          }
        </Alert>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateUser(id:string, data:UserUpdateRequest, params?:any) {
    return dispatch(updateUser(id, data, params));
  },
  updateLoanOfficer(id:string, data:LoanOfficerUpdateRequest, params?:any) {
    return dispatch(updateLoanOfficer(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(EnableDeletedUserForm)));
