import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  root: {
    display: 'inline-block'
  },
  largeIcon: {
    fontSize: '40px',
    '& .MuiIcon-root': {
      fontSize: '40px',
    }
  },
  itemContent: {
    fontWeight: 'bold',
    '& p': {
      display: 'block'
    }
  },
  default: {
    color: theme.palette.neutral.main
  },
  info: {
    color: theme.palette.info.main,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  danger: {
    color: theme.palette.danger.main,
  },
});

export default styles;
