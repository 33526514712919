import React, {MouseEvent} from 'react';
import {connect} from 'react-redux';
import {LoanApplicationPage} from '../layouts';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {PersonalInfoForm} from "../forms";
import BaseLoanApplicationPage from './BaseLoanApplicationPage';
import {ErrorUtil, LoanRev2Util, ReduxUtil} from "../../../utils";
import _ from 'lodash';
import {
  Button,
  Grid,
  Icon,
  Typography,
  Card,
  Link,
  withStyles,
  Divider,
  CardHeader,
  WithStyles
} from '@material-ui/core';
import {Redirect, RouteComponentProps} from "react-router-dom";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {
  DeleteDialog,
  Dialog,
  BorrowerPersonalInfoSummary,
  Fab,
  Well,
  IncompleteLoanAlert
} from "../../../components";
import {LoanApplicationControls} from "../components";
import {
  ActionProps,
  DialogState,
  ReduxLoanApplication,
  ReduxLoanSettings
} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";
import {LoanApplicationRequest, LoanApplicationUpdateRequest} from "@jerseydev/orca-loans";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  loading: boolean,
  selectedBorrowerIndex: number|null,
  borrowerFormVisible: boolean,
  borrowerFormDialogVisible: boolean,
  deleteDialog: DialogState,
  redirectTo?: string,
  isStepCompleted?: boolean
}

class PersonalPage extends BaseLoanApplicationPage<Props, State> {

  defaultLoanApplication:LoanApplicationRequest = {};

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      selectedBorrowerIndex: null,
      borrowerFormVisible: !ReduxUtil.hasData(props.loanApplication),
      borrowerFormDialogVisible: false,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      }
    };

    this.defaultLoanApplication = {
      borrowers: []
    };
  }

  static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: Readonly<State>){
    if(nextProps.loanApplication && nextProps.loanApplication.data && prevState.isStepCompleted === undefined){
      return {isStepCompleted: LoanRev2Util.isPersonalInfoCompleted(nextProps.loanApplication.data)};
    }
    return null;
  };

  componentWillUnmount = () => {
    const {mixpanel, loanApplication} = this.props;
    if(loanApplication.data && loanApplication.data && !this.state.isStepCompleted && LoanRev2Util.isPersonalInfoCompleted(loanApplication.data)) {
      mixpanel.track("Loan application step completed", {step: 'personal'});
    }
  };

  onBorrowerFormSubmit = () => {
    this.setState({
      loading: false,
      selectedBorrowerIndex: null,
      borrowerFormDialogVisible: false
    });
  }

  showAddCoBorrowerDialog = (event:MouseEvent) => {
    event.preventDefault();
    this.setState({ borrowerFormDialogVisible: true });
  };

  onBorrowerFormDialogClose = () => {
    this.setState({ borrowerFormDialogVisible: false, selectedBorrowerIndex: null });
  };

  onBorrowerFormDialogSubmitClick = () => {
    this.setState({loading: true});
  };

  onBorrowerFormDialogSubmit = () => {
    this.setState({ loading: false, borrowerFormDialogVisible: false }, () => {
      this.onBorrowerFormSubmit()
    });
  };

  editBorrower = (index:number) => {
    this.setState({ selectedBorrowerIndex: index, borrowerFormDialogVisible: true });
  };

  onDeleteBorrowerClick = (index:number) => {
    this.setState({
      selectedBorrowerIndex: index,
      deleteDialog: {
        open: true,
        loading: false,
        errors: []
      }
    });
  };
  
  deleteCoBorrower = async () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    try {
      deleteDialog.loading = true;
      this.setState({ deleteDialog });
      const loanApplication = _.cloneDeep(this.props.loanApplication.data);
      if(loanApplication.borrowers && this.state.selectedBorrowerIndex) {
        loanApplication.borrowers.splice(this.state.selectedBorrowerIndex, 1);

        const requestData:LoanApplicationUpdateRequest = {
          borrowers: LoanRev2Util.borrowersToUpdateRequest(loanApplication.borrowers)
        }
        await this.props.routeProps.updateLoanApplication(loanApplication._id, requestData).send();
      }

      this.hideDeleteBorrowerDialog();

    } catch (e) {
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({deleteDialog});
    }
  };

  hideDeleteBorrowerDialog = () => {
    this.setState({
      selectedBorrowerIndex: null,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      }
    });
  };

  onNextClick = () => {
    this.setState({ redirectTo: this.nextMenuItem.to });
  };

  render() {

    const { intl, classes, loanApplication } = this.props;
    const { loading, selectedBorrowerIndex, borrowerFormDialogVisible, deleteDialog, redirectTo } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <LoanApplicationPage menuId="personal">

        <Dialog open={borrowerFormDialogVisible}
                icon={<Icon>person</Icon>}
                title={IntlFormatter.formatMessage(intl, selectedBorrowerIndex ? 'edit_borrower' : 'add_borrower')}
                color="primaryAlt"
                onClose={this.onBorrowerFormDialogClose}
                fullWidth={true}
                maxWidth="md">
          <PersonalInfoForm borrower={ReduxUtil.hasData(loanApplication) && loanApplication.data.borrowers && selectedBorrowerIndex !== null ? loanApplication.data.borrowers[selectedBorrowerIndex] : null}
                            loanApplication={loanApplication.data}
                            actions={[
                                      <Button onClick={this.onBorrowerFormDialogClose}>
                                        <FormattedMessage id="cancel" />
                                      </Button>
                                    ]}
                            onSubmit={this.onBorrowerFormDialogSubmit} />
        </Dialog>

        <DeleteDialog open={deleteDialog.open}
                      errors={deleteDialog.errors}
                      loading={deleteDialog.loading}
                      title={IntlFormatter.formatMessage(intl, 'delete_borrower')}
                      item={ReduxUtil.hasData(loanApplication) && selectedBorrowerIndex && loanApplication.data.borrowers && loanApplication.data.borrowers[selectedBorrowerIndex] ? `${loanApplication.data.borrowers[selectedBorrowerIndex].firstName} ${loanApplication.data.borrowers[selectedBorrowerIndex].lastName}` : ''}
                      onCancel={this.hideDeleteBorrowerDialog}
                      onSubmit={this.deleteCoBorrower} />
        <div className={classes.content}>
          {((ReduxUtil.hasData(loanApplication) && !loanApplication.data._id) || !ReduxUtil.hasData(loanApplication)) &&
            <IncompleteLoanAlert className={classes.mb2} />
          }

          <div>
            {(!ReduxUtil.hasData(loanApplication) || (ReduxUtil.hasData(loanApplication) && loanApplication.data.borrowers && loanApplication.data.borrowers.length === 0)) &&
              <PersonalInfoForm borrower={selectedBorrowerIndex ? loanApplication.data.borrowers![selectedBorrowerIndex] : null}
                                loanApplication={loanApplication.data}
                                onSubmitClick={this.onBorrowerFormDialogSubmitClick}
                                onSubmit={this.onBorrowerFormSubmit}>
                <div className={classes.pt2}>
                  <LoanApplicationControls next={{ ...this.nextMenuItem, labelId: 'next' }}
                                           nextButtonProps={{ type: 'submit', loading }} />
                </div>
              </PersonalInfoForm>
            }
            {(ReduxUtil.hasData(loanApplication) && loanApplication.data.borrowers) &&
              <div>
                {loanApplication.data.borrowers.length > 0 &&
                <Grid container spacing={2}>
                  {loanApplication.data.borrowers.map((borrower, borrowerIndex) => {
                    return (
                      // @ts-ignore
                      <Grid key={borrowerIndex} item xs={12} sm={12} md={loanApplication.data.borrowers.length === 1 ? 6 : Math.floor(12 / loanApplication.data.borrowers.length)}>
                        <Card className={classes.card}>
                          <CardHeader title={IntlFormatter.formatMessage(intl, borrower.primaryBorrower ? 'borrower' : 'co_borrower')}
                                      avatar={<Icon>{borrower.primaryBorrower ? 'person' : 'person_outline'}</Icon>}
                                      action={
                                        <div className={classes.rowCenter}>
                                          <Fab color="primary"
                                               size="small"
                                               flat
                                               rounded
                                               onClick={() => this.editBorrower(borrowerIndex)}>
                                            <Icon>edit</Icon>
                                          </Fab>
                                          {!borrower.primaryBorrower &&
                                            <div className={classes.ml1}>
                                              <Fab color="danger"
                                                   size="small"
                                                   flat
                                                   rounded
                                                   onClick={() => this.onDeleteBorrowerClick(borrowerIndex)}>
                                                <Icon>close</Icon>
                                              </Fab>
                                            </div>
                                          }
                                        </div>
                                      } />
                          <Divider />

                          <BorrowerPersonalInfoSummary loanApplication={loanApplication.data}
                                                       borrower={borrower} />
                        </Card>
                      </Grid>
                    );
                  })}

                  {loanApplication.data.borrowers.length === 1 &&
                    <Grid item xs={12} sm={12} md={6}>
                      <Card className={classes.card}>
                        <CardHeader title={IntlFormatter.formatMessage(intl, 'co_borrower')}
                                    avatar={<Icon>person_outline</Icon>}
                                    action={
                          <Fab color="primary"
                               size="small"
                               flat
                               rounded
                               onClick={this.showAddCoBorrowerDialog}>
                            <Icon>add</Icon>
                          </Fab>
                        }/>
                        <Divider />
                        <div className={classes.p2}>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle1">
                              <Link href="#" onClick={this.showAddCoBorrowerDialog}>
                                <FormattedMessage id="add_co_borrower" />
                              </Link>
                            </Typography>
                          </div>
                          <Well color="info">
                            <Typography variant="body1">
                              <FormattedMessage id="no_co_borrower_text" />
                            </Typography>
                          </Well>
                        </div>
                      </Card>
                    </Grid>
                  }
                </Grid>
                }
              </div>
            }
          </div>
          {(ReduxUtil.hasData(loanApplication) && loanApplication.data.borrowers && loanApplication.data.borrowers.length > 0) &&
            <div className={classes.content}>
              <LoanApplicationControls next={this.nextMenuItem}
                                       onNextClick={this.onNextClick} />
            </div>
          }
        </div>

      </LoanApplicationPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(PersonalPage)));
