import {LoanOfficer} from "@jerseydev/orca-loans";
import {Integrations} from "../types";

export default class LoanOfficerUtil {
  static getProfileIncompleteWarnings = (loanOfficer:LoanOfficer, integrations:Integrations) => {
    // @todo add check for each integration type. Currently only Encompass and OB apply
    const warnings:string[] = [];

    if(integrations.los && (!loanOfficer.los || (loanOfficer.los && (!loanOfficer.los.losId || loanOfficer.los.losId === '')))) {
      warnings.push('los');
    }

    if(integrations.pricing && (!loanOfficer.pricing || (loanOfficer.pricing && !loanOfficer.pricing.originatorId))) {
      warnings.push('pricing');
    }

    return warnings;
  };
}
