import React from 'react';
import {
  Typography,
  Link, Grid, withStyles, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {Badge, SubTitle, Well} from "../index";
import {Link as RouterLink} from "react-router-dom";
import pageStyles from "../../theme/jss/layouts/pageStyles";
import {TextUtil} from "../../utils";
import {
  LoanApplication,
  Borrower,
  BorrowerDeclaration
} from "@jerseydev/orca-loans";
import IntlFormatter from "../../intl";

type Props = {
  loanApplication: LoanApplication
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

const LoanDeclarationsSummaryRev2 = (props:Props) => {

  const {classes, loanApplication, intl} = props;

  if(!loanApplication.borrowers || loanApplication.borrowers.length === 0) {
    return (
      <Well color="warn">
        <Typography variant="body1">
          <FormattedMessage id="no_declarations_found" />
        </Typography>
      </Well>
    );
  }

  const renderDeclaration = (labelId:string, prop?:BorrowerDeclaration|null) => {
    const { classes } = props;

    return (
      <div className={classes.pv1}>
        <Typography variant="subtitle2" gutterBottom>
          <FormattedMessage id={labelId} />
        </Typography>
        <div>
          {prop &&
          <Badge color="infoAlt">
            <FormattedMessage id={prop.value ? 'yes' : 'no'} />
            {prop.description &&
              <span>&nbsp;- {prop.description}</span>
            }
          </Badge>
          }
          {!prop &&
          <Badge color="warnAlt">
            <FormattedMessage id="not_answered" />
          </Badge>
          }
        </div>
      </div>
    )
  };

  const renderBorrowerDeclarations = (borrower:Borrower) => {
    const { intl, classes, loanApplication } = props;

    if(!borrower.declarations) {
      return (
        <div>
          <div className={classes.mb2}>
            <Typography variant="body1">
              <FormattedMessage id="no_declarations_found" />.&nbsp;
              {!loanApplication.completed &&
              <Link component={RouterLink} to="/apply/declarations">
                <FormattedMessage id="add_declarations"/>
              </Link>
              }
            </Typography>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className={classes.pv2}>
          {renderDeclaration('declaration_primary_residence', borrower.declarations.primaryResidence )}

          {(borrower.declarations.primaryResidence && borrower.declarations.primaryResidence.value) &&
            <div>
              <Typography variant="subtitle2">
                <FormattedMessage id="declaration_ownership_in_last_three_years" />
              </Typography>
              <Badge color="infoAlt">
                {TextUtil.getBoolText(borrower.declarations.primaryResidence.interestInLastThreeYears, intl)}
              </Badge>
              {borrower.declarations.primaryResidence.propertyType &&
              <div className={classes.pv1}>
                <Typography variant="subtitle2">
                  <FormattedMessage id="declaration_ownership_property_type" />
                </Typography>
                <Badge color="infoAlt">
                  <FormattedMessage id={`declaration_property_types_${borrower.declarations.primaryResidence.propertyType}`} />
                </Badge>
              </div>
              }

              {borrower.declarations.primaryResidence.titleHeld &&
              <div className={classes.pv1}>
                <Typography variant="subtitle2">
                  <FormattedMessage id="declaration_ownership_title"/>
                </Typography>
                <Badge color="infoAlt">
                  <FormattedMessage id={`declaration_title_types_${borrower.declarations.primaryResidence.titleHeld}`}/>
                </Badge>
              </div>
              }
            </div>
          }

          {renderDeclaration('declaration_relationship_with_seller', borrower.declarations.relationshipWithSeller )}

          <div className={classes.pv1}>
            <Typography variant="subtitle2" gutterBottom>
              <FormattedMessage id="declaration_borrowing_money" />
            </Typography>
            <div>
              {borrower.declarations.borrowingMoney &&
              <div>
                <Badge color="infoAlt">
                  <FormattedMessage id={borrower.declarations.borrowingMoney.value ? 'yes' : 'no'} />
                  {borrower.declarations.borrowingMoney.amount &&
                  <span>
                    &nbsp;- {TextUtil.getMoneyText(borrower.declarations.borrowingMoney.amount, intl)}
                  </span>
                  }
                </Badge>
              </div>
              }
              {!borrower.declarations.borrowingMoney &&
              <Badge color="warnAlt">
                <FormattedMessage id="not_answered" />
              </Badge>
              }
            </div>
          </div>

          {renderDeclaration('declaration_applying_for_another_mortgage', borrower.declarations.applyingForAnotherMortgage )}
          {renderDeclaration('declaration_applying_for_new_credit', borrower.declarations.applyingForNewCredit )}
          {renderDeclaration('declaration_subject_to_lien_priority', borrower.declarations.subjectToLienPriority )}
          {renderDeclaration('declaration_cosigner_on_debt', borrower.declarations.coSignerOnDebt )}
          {renderDeclaration('declaration_outstanding_judgments', borrower.declarations.outstandingJudgments )}
          {renderDeclaration('declaration_in_delinquency_or_default', borrower.declarations.inDelinquencyOrDefault )}
          {renderDeclaration('declaration_lawsuit', borrower.declarations.partyToLawsuit )}
          {renderDeclaration('declaration_conveyed_title_to_property_foreclosure', borrower.declarations.conveyedTitleToPropertyForeclosure )}
          {renderDeclaration('declaration_pre_foreclosure_short_sale', borrower.declarations.preForeclosureOrShortSale )}
          {renderDeclaration('declaration_foreclosure', borrower.declarations.foreclosure )}

          <div className={classes.pv1}>
            <Typography variant="subtitle2" gutterBottom>
              <FormattedMessage id="declaration_bankruptcy" />
            </Typography>
            <div>
              {borrower.declarations.bankruptcy &&
              <div>
                {(!borrower.declarations.bankruptcy.types || (borrower.declarations.bankruptcy.types && borrower.declarations.bankruptcy.types.length === 0)) &&
                  <Badge color="infoAlt">
                    <FormattedMessage id={borrower.declarations.bankruptcy.value ? 'yes' : 'no'} />
                  </Badge>
                }
                {borrower.declarations.bankruptcy.types &&
                  <div className={classes.rowCenter}>
                    {borrower.declarations.bankruptcy.types.map((type, i) => {
                      return (
                        <div key={i} className={classes.mr1}>
                          <Badge color="infoAlt"><FormattedMessage id={`bankruptcy_type_${type}`} /></Badge>
                        </div>
                      )
                    })}

                  </div>
                }
              </div>
              }
              {!borrower.declarations.bankruptcy &&
              <Badge color="warnAlt">
                <FormattedMessage id="not_answered" />
              </Badge>
              }
            </div>
          </div>

        </div>
      </div>
    );
  };

  return (
    <div>
      <Grid container spacing={2}>
        {loanApplication.borrowers.map((borrower, borrowerIndex) => {
          return (
            <Grid key={borrowerIndex}
                  item
                  // @ts-ignore
                  md={loanApplication.borrowers && loanApplication.borrowers.length > 1 ? Math.floor(12 / loanApplication.borrowers.length) : 6}>

              <div className={classes.mb2}>
                <SubTitle title={borrower.fullName || IntlFormatter.formatMessage(intl, 'borrower')} />
              </div>

              {borrower.declarations &&
              <div>
                {renderBorrowerDeclarations(borrower)}
              </div>
              }

              {!borrower.declarations &&
              <div>
                <div className={classes.mb2}>
                  <Well color="warn">
                    <Typography variant="body1">
                      <FormattedMessage id="no_declarations_found" />.&nbsp;
                      {!loanApplication.completed &&
                      <Link component={RouterLink} to="/apply/declarations">
                        <FormattedMessage id="add_declarations"/>
                      </Link>
                      }
                    </Typography>
                  </Well>
                </div>
              </div>
              }
            </Grid>
          )
        })}
      </Grid>
    </div>
  );
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanDeclarationsSummaryRev2));