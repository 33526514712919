import {states} from "../data/data";
import {ConfigUtil, LoanRev1Util, LoanRev2Util} from "./index";
import {
  LoanApplicationAssetReport,
  BorrowerAccountAsset, BorrowerDemographics,
  LoanApplication2009, Borrower2009,
  LoanApplication, Borrower,
  LoanDocumentCategory
} from "@jerseydev/orca-loans";
import {Integrations, LoanExportFormat} from "../types";
import IntlFormatter from "../intl";
import {IntlShape} from "react-intl";
import IntegrationUtil from "./IntegrationUtil";

class LoanUtil {
  static getCompletedPercent = (loanApplication:LoanApplication2009|LoanApplication) => {
    if(loanApplication.__t === 'Rev1') {
      return LoanRev1Util.getCompletedPercent(loanApplication as LoanApplication2009);
    }

    return LoanRev2Util.getCompletedPercent(loanApplication as LoanApplication);
  };

  static getCompletedSteps = (loanApplication:LoanApplication2009|LoanApplication) => {
    if(loanApplication.__t === 'Rev1') {
      return LoanRev1Util.getCompletedSteps(loanApplication as LoanApplication2009);
    }

    return LoanRev2Util.getCompletedSteps(loanApplication as LoanApplication);
  };

  static getBorrowerTotalYearsAtAddresses = (loanApplication:LoanApplication2009|LoanApplication, borrower:Borrower2009|Borrower) => {
    if(loanApplication.__t === 'Rev1') {
      return LoanRev1Util.getBorrowerTotalYearsAtAddresses(borrower as Borrower2009);
    }

    return LoanRev2Util.getBorrowerTotalYearsAtAddresses(borrower as Borrower);
  };

  static getBorrowerTotalYearsEmployed = (loanApplication:LoanApplication2009|LoanApplication, borrower:Borrower2009|Borrower) => {
    if(loanApplication.__t === 'Rev1') {
      return LoanRev1Util.getBorrowerTotalYearsEmployed(borrower as Borrower2009);
    }

    return LoanRev2Util.getBorrowerTotalYearsEmployed(borrower as Borrower);
  };

  static getEditUrl = (loanApplication:LoanApplication2009|LoanApplication) => {
    let editUrl = `/apply/personal?id=${loanApplication._id}`;
    const loanApplicationMenu = ConfigUtil.getLoanApplicationMenu(loanApplication.__t);
    const completedSteps = LoanUtil.getCompletedSteps(loanApplication);

    for(let i=0;i<loanApplicationMenu.length;i++) {
      if(!completedSteps.includes(loanApplicationMenu[i].id) && i > 0) {
        editUrl = `${loanApplicationMenu[i].to}?id=${loanApplication._id}`;
        break;
      }
    }

    return editUrl;
  };

  static isLoanOfficerLicensedInPropertyState = (loanApplication:LoanApplication2009|LoanApplication) => {
    let isLicensed = true;

    if(loanApplication.loanOfficer && loanApplication.property && loanApplication.property.address && loanApplication.property.address.province) {
      const state = states.find(s => s.name.toLowerCase() === loanApplication.property!.address!.province!.toLowerCase());
      if(!state || !loanApplication.loanOfficer.stateLicenses.includes(state.abbreviation.toLowerCase())) {
        isLicensed = false;
      }
    }

    return isLicensed;
  };

  static hasCompletedDocumentCategory = (category:LoanDocumentCategory, loanApplication:LoanApplication2009|LoanApplication, integrations:Integrations):boolean => {
    let hasDocumentCategoryUploaded:boolean = false;
    if(loanApplication.documents && loanApplication.documents.length > 0) {
      if(category.assetHistory && IntegrationUtil.hasIntegration(integrations, 'assets')) {
        // check if any assets have the required history
        const daysRequired = integrations.assets!.reportHistoryDays;
        let accountAssets:BorrowerAccountAsset[] = [];
        if(loanApplication.borrowers) {
          loanApplication.borrowers.forEach((borrower:Borrower2009|Borrower) => {
            if(borrower.accountAssets) {
              accountAssets = [...accountAssets, ...borrower.accountAssets];
            }
          });
        }
        const completedAssets = accountAssets.filter(a => a.daysOfHistory && a.daysOfHistory >= daysRequired);
        hasDocumentCategoryUploaded = completedAssets.length > 0;
        if(!hasDocumentCategoryUploaded) {
          // check if it was manually selected on a file upload
          hasDocumentCategoryUploaded = !!loanApplication.documents.find(d => d.category && d.category._id === category._id);
        }
      } else {
        hasDocumentCategoryUploaded = !!loanApplication.documents.find(d => d.category && d.category._id === category._id);
      }
    }

    return hasDocumentCategoryUploaded;
  };

  static hasAllRequiredDocuments = (loanApplication:LoanApplication2009|LoanApplication, documentCategories:LoanDocumentCategory[], integrations:Integrations) => {
    const loanType = loanApplication.loan && loanApplication.loan.loanType ? loanApplication.loan.loanType : null;
    const loanPurpose = loanApplication.loan && loanApplication.loan.loanPurpose ? loanApplication.loan.loanPurpose : null;
    let hasAllRequiredDocuments = true;
    let requiredCategories = documentCategories.filter(c => c.required);
    if(loanPurpose) {
      requiredCategories = requiredCategories.filter(c => c.loanPurposes.includes(loanPurpose));
    }

    if(loanType) {
      requiredCategories = requiredCategories.filter(c => c.loanTypes.includes(loanType));
    }

    for(let i=0;i<requiredCategories.length;i++) {
      const completed = LoanUtil.hasCompletedDocumentCategory(requiredCategories[i], loanApplication, integrations);
      if(!completed) {
        hasAllRequiredDocuments = false;
        break;
      }
    }

    return hasAllRequiredDocuments;
  };

  static assetReportsCompleted = (currentLoanApplication:LoanApplication2009|LoanApplication, nextLoanApplication:LoanApplication2009|LoanApplication) => {
    if(currentLoanApplication._id !== nextLoanApplication._id) {
      return false;
    }

    const currentAssetReports = LoanUtil.getLoanAssetReports(currentLoanApplication);
    const latestAssetReports = LoanUtil.getLoanAssetReports(nextLoanApplication);

    for(let i=0;i<currentAssetReports.length;i++) {
      const currentReport = currentAssetReports[i];
      const latestReport = latestAssetReports.find(r => r._id === currentReport._id);
      //console.log(currentReport.status, latestReport.status);
      if(latestReport && (currentReport.status !== latestReport.status && (latestReport.status === 'completed' || latestReport.status === 'failed'))) {
        return true;
      }
    }

    return false;
  };

  static getLoanAssetReports = (loanApplication:LoanApplication2009|LoanApplication) => {
    let assetReports:LoanApplicationAssetReport[] = [];
    if(loanApplication.borrowers) {
      loanApplication.borrowers.forEach((borrower:Borrower2009|Borrower) => {
        if(borrower.assetReports) {
          assetReports = [...assetReports, ...borrower.assetReports];
        }
      });
    }

    return assetReports;
  };

  static isAssetsAndLiabilitiesCompleted = (loanApplication:LoanApplication2009|LoanApplication):boolean => {
    let completed:boolean = false;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      if(loanApplication.jointAssetsAndLiabilities) {
        completed = LoanUtil.borrowerHasAssetsOrLiabilities(loanApplication.borrowers[0]);
      } else {
        for(let i=0;i<loanApplication.borrowers.length;i++) {
          const borrower = loanApplication.borrowers[i];
          completed = LoanUtil.borrowerHasAssetsOrLiabilities(borrower);
          if(completed) {
            break;
          }
        }
      }
    }

    return completed;
  };

  static borrowerHasAssetsOrLiabilities = (borrower:Borrower|Borrower2009):boolean => {
    const props:string[] = ["accountAssets", "liabilities", "otherAssets", "otherLiabilities", "assetReports"];
    let hasAssetsOrLiabilities = false;
    for(let i=0;i<props.length;i++) {
      let key:any = props[i];
      const borrowerProp = borrower[key as keyof (Borrower|Borrower2009)];
      if(borrowerProp === null || (Array.isArray(borrowerProp) && borrowerProp.length > 0)) {
        hasAssetsOrLiabilities = true;
        break;
      }
    }
    return hasAssetsOrLiabilities;
  };

  static isDemographicsCompleted = (loanApplication:LoanApplication2009|LoanApplication) => {
    let completed = true;

    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      const requiredProps = ['ethnicity', 'race', 'gender'];
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        const borrower = loanApplication.borrowers[i];
        if(borrower.demographics) {
          for(let j=0;j<requiredProps.length;j++) {
            if(!borrower.demographics[requiredProps[j] as keyof BorrowerDemographics]) {
              completed = false;
              break;
            }
          }
        } else {
          completed = false;
          break;
        }
      }
    } else {
      completed = false;
    }

    return completed;
  };

  static getExportUrl = (id:string, format:LoanExportFormat, accessToken:string) => {
    return `${process.env.REACT_APP_API_HOST}/v1/loan-applications/export/${format}/${id}?access_token=${accessToken}`;
  };

  static getBorrowerFullName = (intl:IntlShape, borrower:Borrower2009|Borrower) => {
    let fullName = '';
    if(borrower.prefix) {
      fullName += `${IntlFormatter.formatMessage(intl, `prefixes_${borrower.prefix}`)} `;
    }

    fullName += `${borrower.firstName} ${borrower.lastName}`;

    if(borrower.suffix) {
      fullName += ` ${IntlFormatter.formatMessage(intl, `suffixes_${borrower.suffix}`)} `;
    }

    return fullName;
  };

  static canEdit = (loanApplication:LoanApplication2009|LoanApplication) => {
    return !(loanApplication.completed && (!loanApplication.los || (loanApplication.los && loanApplication.los.status !== 'failed')));
  };

  static hasPendingAssetReports = (loanApplication:LoanApplication2009|LoanApplication) => {
    let hasQueuedReports = false;
    if(loanApplication.borrowers) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        if(loanApplication.borrowers[i].assetReports) {
          const queuedAssetReports = loanApplication.borrowers![i].assetReports!.filter(r => r.status === 'queued' || r.status === 'refreshing');
          if(queuedAssetReports.length > 0) {
            hasQueuedReports = true;
            break;
          }
        }
      }
    }
    return hasQueuedReports;
  };
}

export default LoanUtil