import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    color: theme.palette.neutral.light
  }
});

export default styles;
