import React, {MouseEvent, ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {Grid, Button, Typography, Icon, withStyles, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, PropagateLoader, Select, SubmitButton, TextField} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {getCreditCards} from "../../../actions/creditCards";
import {ErrorUtil, ReduxUtil} from '../../../utils';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {subscribe, updateDefaultPaymentMethod} from "../../../actions/subscription";
import {getBankAccounts} from "../../../actions/bankAccounts";
import clsx from "clsx";
import {ReduxBankAccounts, ReduxCreditCards, ActionProps, ErrorState} from "../../../types";
import {
  BankAccount,
  CreditCard,
  Plan,
  PlanPrice,
  Subscription,
  SubscriptionRequest
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {AxiosPromise, AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  plan: Plan,
  pricing: PlanPrice,
  mixpanel: Mixpanel,
  onSubmit?: (data:AxiosResponse<Subscription>) => void,
  onCancel?: () => void,
  creditCards: ReduxCreditCards,
  bankAccounts: ReduxBankAccounts,
  subscribe: ActionProps["subscribe"],
  getCreditCards: ActionProps["getCreditCards"],
  getBankAccounts: ActionProps["getBankAccounts"],
  updateDefaultPaymentMethod: ActionProps["updateDefaultPaymentMethod"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  name: string,
  number: string,
  exp_month: string,
  exp_year: string,
  cvc: string,
  address_line1: string,
  address_city: string,
  address_state: string,
  address_zip: string,
  address_country: string
}

type State = {
  formLoaded: boolean,
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  existingPaymentMethod: CreditCard|BankAccount|null,
  addCardFormOpen: boolean,
  paymentMethods: (CreditCard|BankAccount)[]
}

class SubscribeForm extends BaseForm<Props, State> {

  currentYear:number;

  constructor(props:Props) {
    super(props);

    this.state = {
      formLoaded: false,
      loading: false,
      form: {
        name: '',
        number: '',
        exp_month: '',
        exp_year: '',
        cvc: '',
        address_line1: '',
        address_city: '',
        address_state: '',
        address_zip: '',
        address_country: 'USA'

      },
      errors: [],
      existingPaymentMethod: null,
      addCardFormOpen: false,
      paymentMethods: []
    };

    this.currentYear = new Date().getFullYear();
  }

  componentDidMount = async () => {
    try {
      const requests:AxiosPromise[] = [
        this.props.getCreditCards().send(),
        this.props.getBankAccounts().send()
      ];

      const [creditCardResult, bankAccountResult] = await Promise.all(requests);
      const paymentMethods = [...creditCardResult.data, ...bankAccountResult.data];
      const existingPaymentMethod = paymentMethods.find(c => c.default);
      this.setState({ formLoaded: true, addCardFormOpen: creditCardResult.data.length === 0, existingPaymentMethod, paymentMethods });
    } catch (e) {
      this.setState({ formLoaded: true, errors: ErrorUtil.formatErrors(e) });
    }
  };

  setPaymentMethod = (paymentMethod:CreditCard|BankAccount) => {
    this.setState({ existingPaymentMethod: paymentMethod });
  };

  onShowAddCardForm = () => {
    this.setState({ addCardFormOpen: true });
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    try {
      const {plan, pricing} = this.props;

      this.setState({ loading: true, errors: [] });

      let data:SubscriptionRequest = {
        plan: pricing.id
      };

      if(this.state.addCardFormOpen) {
        const {name, number, address_line1, address_city, address_state, address_zip, address_country, cvc, exp_year, exp_month} = this.state.form;
        data.card = {
          name,
          number,
          address_line1,
          address_city,
          address_state,
          address_zip,
          address_country,
          cvc: parseInt(cvc),
          exp_year: parseInt(exp_year),
          exp_month: parseInt(exp_month)
        }
      } else if(this.state.existingPaymentMethod && !this.state.existingPaymentMethod.default) {
        await this.props.updateDefaultPaymentMethod(_.clone(this.state.existingPaymentMethod.id)).send();
      }

      const result = await this.props.subscribe(data).send();
      this.props.mixpanel.track("Subscription Updated", { plan: plan.name, pricing: pricing.nickname });
      this.setState({ loading: false }, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch(e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {
    const { intl, classes, creditCards, onCancel } = this.props;
    const { form, formLoaded, loading, errors, existingPaymentMethod, addCardFormOpen, paymentMethods } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] })}} />

          {!formLoaded &&
            <div className={clsx(classes.center, classes.pv3)}>
              <PropagateLoader />
            </div>
          }

          {ReduxUtil.hasData(creditCards) &&
            <div>
              {(paymentMethods.length > 0 && !addCardFormOpen) &&
                <div>
                  <div className={classes.mb2}>
                    <Select name="paymentMethod"
                            options={paymentMethods}
                            onChange={this.setPaymentMethod}
                            value={existingPaymentMethod ? paymentMethods.find(p => p.id === existingPaymentMethod.id) : null}
                            getOptionLabel={(item:CreditCard|BankAccount) => {
                              let label:string;
                              if("brand" in item) {
                                label = `${item.brand} ****${item.last4}`
                              } else {
                                label = `${item.bank_name} ****${item.last4}`
                              }

                              return label;
                            }}
                            label={IntlFormatter.formatMessage(intl, 'payment_method')}
                            placeholder={IntlFormatter.formatMessage(intl, 'select_existing_payment_method')}/>
                  </div>
                  <div>

                    <div className={clsx(classes.mb2, classes.textCenter)}>
                      <Typography>
                        <FormattedMessage id="or" />
                      </Typography>
                    </div>

                    <div className={classes.textCenter}>
                      <Button variant="contained"
                              color="primary"
                              startIcon={<Icon>credit_card</Icon>}
                              onClick={this.onShowAddCardForm}>
                        <FormattedMessage id="add_new_card" />
                      </Button>
                    </div>
                  </div>

                </div>
              }


              {formLoaded && (creditCards.data.length === 0 || addCardFormOpen) &&
                <div>
                  <div className={classes.mb2}>
                    <TextField name="cardName"
                                   label={IntlFormatter.formatMessage(intl, 'name_on_card')}
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.name')}
                                   value={form.name}
                                   fullWidth={true}
                                   validators={['required']}
                                   errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </div>

                  <div className={classes.mb2}>
                    <TextField name="cardNumber"
                               label={IntlFormatter.formatMessage(intl, 'card_number')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.number')}
                               value={form.number}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </div>

                  <div className={classes.mb2}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <TextField name="expMonth"
                                   label={IntlFormatter.formatMessage(intl, 'exp_month')}
                                   placeholder="MM"
                                   value={form.exp_month}
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.exp_month')}
                                   type="number"
                                   InputLabelProps={{
                                     shrink: true,
                                   }}
                                   margin="normal"
                                   validators={['required', 'minNumber:1', 'maxNumber:12']}
                                   errorMessages={[
                                     IntlFormatter.formatMessage(intl, 'validation_required'),
                                     IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 1 }),
                                     IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 12 })
                                   ]} />
                      </Grid>
                      <Grid item>
                        <TextField name="expYear"
                                   label={IntlFormatter.formatMessage(intl, 'exp_year')}
                                   placeholder="YYYY"
                                   value={form.exp_year}
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.exp_year')}
                                   type="number"
                                   InputLabelProps={{
                                     shrink: true,
                                   }}
                                   margin="normal"
                                   validators={['required', `minNumber:${this.currentYear}`, `maxNumber:${this.currentYear + 10}`]}
                                   errorMessages={[
                                     IntlFormatter.formatMessage(intl, 'validation_required'),
                                     IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: this.currentYear }),
                                     IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: this.currentYear + 10 })
                                   ]}/>
                      </Grid>
                      <Grid item>
                        <TextField name="cvcCode"
                                   label={IntlFormatter.formatMessage(intl, 'cvc')}
                                   value={form.cvc}
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.cvc')}
                                   type="number"
                                   InputLabelProps={{
                                     shrink: true,
                                   }}
                                   margin="normal"
                                   validators={['required']}
                                   errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                      </Grid>
                    </Grid>
                  </div>

                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <TextField name="street"
                                     label={IntlFormatter.formatMessage(intl, 'street_address')}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address_line1')}
                                     value={form.address_line1}
                                     fullWidth={true}
                                     validators={['required']}
                                     errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <TextField name="city"
                                     label={IntlFormatter.formatMessage(intl, 'city')}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address_city')}
                                     value={form.address_city}
                                     fullWidth={true}
                                     validators={['required']}
                                     errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField name="state"
                                     label={IntlFormatter.formatMessage(intl, 'state')}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address_state')}
                                     value={form.address_state}
                                     fullWidth={true}
                                     validators={['required']}
                                     errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField name="postalCode"
                                     label={IntlFormatter.formatMessage(intl, 'postal_code')}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address_zip')}
                                     value={form.address_zip}
                                     fullWidth={true}
                                     validators={['required']}
                                     errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                    </Grid>
                  </Grid>
                </div>
              }
            </div>
          }


          <div className={classes.mt3}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {onCancel &&
              <Grid item>
                <Button onClick={onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading} disabled={!addCardFormOpen && !existingPaymentMethod}>
                  <FormattedMessage id="subscribe" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    creditCards: state.creditCards,
    bankAccounts: state.bankAccounts
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  subscribe(data:SubscriptionRequest) {
    return dispatch(subscribe(data));
  },
  getCreditCards() {
    return dispatch(getCreditCards());
  },
  getBankAccounts() {
    return dispatch(getBankAccounts());
  },
  updateDefaultPaymentMethod(id:string) {
    return dispatch(updateDefaultPaymentMethod(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(SubscribeForm)));
