import {createStyles} from "@material-ui/core";

const styles = () => createStyles({
  root: {
    position: 'relative',
    display: 'inline-block'
  },
  progress: {
    position: 'absolute',
    top: 4,
    left: 4,
    zIndex: 1,
  }
});

export default styles;
