import React from 'react';
import {withStyles, WithStyles} from "@material-ui/core";
import styles from "../theme/jss/components/numberIconStyles";
import clsx from "clsx";

type DefaultProps = {
  color: 'default'|'info'|'primary'|'secondary'|'success'|'warn'|'yield'|'danger'
}

type Props = {
  number: number
} & WithStyles<typeof styles>
  & Partial<DefaultProps>

const NumberIcon = (props:Props) => {
  const {classes, number, color} = props;

  return (
    // @ts-ignore
    <div className={clsx(classes.root, classes[color])}>
      {number.toString()}
    </div>
  )
};

const defaultProps:DefaultProps = {
  color: 'default'
};

NumberIcon.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(NumberIcon);