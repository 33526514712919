import React, {Component} from 'react';
import {AccountPage} from '../../../layouts';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {UserForm} from '../forms';
import {Redirect} from "react-router-dom";
import {WithStyles, withStyles} from "@material-ui/core";
import styles from "../../../theme/jss/layouts/pageStyles";
import {PageTitle} from "../../../components";
import {DialogState, SnackbarState} from "../../../types";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel
} & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  redirectTo?: string,
  snackbar?: SnackbarState,
  licenseDialog: DialogState
}

class UserAddPage extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      licenseDialog: {
        open: false,
        loading: false,
        errors: []
      }
    };
  }

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'add_user');
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="person" />
    )
  };

  onCancel = () => {
    this.setState({ redirectTo: '/admin/users' });
  };

  onSubmit = () => {
    const { intl } = this.props;
    this.setState({
      redirectTo: '/admin/users',
      snackbar: {
        open:true,
        variant: 'success',
        message: IntlFormatter.formatMessage(intl, 'user_added')
      }
    });
  };

  render() {

    const { intl, classes, mixpanel } = this.props;
    const { redirectTo, snackbar } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo, state: { snackbar }}}  />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                    {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                    {title: IntlFormatter.formatMessage(intl, 'users'), to: '/admin/users' },
                    {title: this.getPageTitle() }
                  ]}>
        <div className={classes.content}>
          <UserForm mixpanel={mixpanel}
                    onCancel={this.onCancel}
                    onSubmit={this.onSubmit} />
        </div>
      </AccountPage>
    );
  }
}


export default withStyles(styles, { withTheme: true })(injectIntl(UserAddPage));
