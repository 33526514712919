import React from 'react';
import {
  Fab as MuiFab, FabProps
} from '@material-ui/core';
import {useStyles} from '../theme/jss/components/fabStyles';
import clsx from "clsx";

type DefaultProps = {
  color: 'default'|'info'|'infoAlt'|'primary'|'primaryAlt'|'secondary'|'secondaryAlt'|'success'|'successAlt'|'warn'|'warnAlt'|'danger'|'dangerAlt',
  size: 'small'|'medium'|'large',
  rounded: boolean,
  flat: boolean
}

type Props = {
  children: React.ReactChild
} & Partial<DefaultProps>
  & Omit<FabProps, 'color'>

const Fab = (props:Props) => {
  const classes = useStyles();
  const { color, children, rounded, flat, ...rest } = props;
  let classNames = classes[color!];
  if(rounded) {
    classNames = clsx(classNames, classes.rounded);
  }
  if(flat) {
    classNames = clsx(classNames, classes.flat);
  }
  return (
    <MuiFab {...rest} className={classNames}>
      {children}
    </MuiFab>
  );
}

const defaultProps:DefaultProps = {
  color: 'default',
  rounded: false,
  flat: false,
  size: 'medium'
};

Fab.defaultProps = defaultProps;

export default Fab;
