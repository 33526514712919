import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  withStyles,
  Button, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, Select, SubmitButton, TextField} from "../../../components";
import {connect} from "react-redux";
import {addWebHook, updateWebHook} from "../../../actions/webHooks";
import {ErrorUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {KeyNamePairType, ActionProps, ErrorState} from "../../../types";
import {WebHook, WebHookRequest} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  webHook?: WebHook|null,
  onSubmit: (data:AxiosResponse<WebHook>) => void,
  onCancel?: () => void,
  addWebHook: ActionProps["addWebHook"],
  updateWebHook: ActionProps["updateWebHook"],
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  url: string,
  event: WebHook["event"] | '',
  type: WebHook["type"] | ''
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class WebHookForm extends BaseForm<Props, State> {
  types:KeyNamePairType[] = [];
  events:KeyNamePairType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      url: '',
      event: '',
      type: ''
    };

    if(props.webHook) {
      const {url, event, type} = props.webHook;
      form = {
        url,
        event,
        type
      }
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    const {intl} = props;
    this.types = [
      {key: 'LoanApplication', name: IntlFormatter.formatMessage(intl, 'loan_application')},
      {key: 'User', name: IntlFormatter.formatMessage(intl, 'user')}
    ];

    this.events = [
      {key: 'create', name: IntlFormatter.formatMessage(intl, 'webhook_event_create')},
      {key: 'update', name: IntlFormatter.formatMessage(intl, 'webhook_event_update')},
      {key: 'remove', name: IntlFormatter.formatMessage(intl, 'webhook_event_remove')}
    ];
  }

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const {mixpanel} = this.props;
      const {url, type, event} = this.state.form;

      const data:WebHookRequest = {
        url,
        type: type as WebHook["type"],
        event: event as WebHook["event"]
      }
      let result:AxiosResponse<WebHook>;
      if(this.props.webHook && this.props.webHook._id) {
        result = await this.props.updateWebHook(this.props.webHook._id, data).send();
        mixpanel.track("Webhook updated");
      } else {
        result = await this.props.addWebHook(data).send();
        mixpanel.track("Webhook added");
      }

      this.setState({ loading: false }, () => {
        this.props.onSubmit(result);
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes, webHook } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } }/>
          <div className={classes.mb2}>
            <TextField name="url"
                       label={IntlFormatter.formatMessage(intl, 'url')}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.url')}
                       value={form.url}
                       fullWidth={true}
                       validators={['required']}
                       errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Select name="type"
                      options={this.types}
                      getOptionLabel={(item:KeyNamePairType) => item.name}
                      onChange={(item:KeyNamePairType) => this.onItemChange(item.key, 'form.type')}
                      disabled={!!webHook}
                      value={form.type !== '' ? this.types.find(m => m.key === form.type) : ''}
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required')
                      ]}
                      label={IntlFormatter.formatMessage(intl, 'type')}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select name="event"
                      options={this.events}
                      getOptionLabel={(item:KeyNamePairType) => item.name}
                      onChange={(item:KeyNamePairType) => this.onItemChange(item.key, 'form.event')}
                      value={form.event !== '' ? this.events.find(m => m.key === form.event) : ''}
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required')
                      ]}
                      label={IntlFormatter.formatMessage(intl, 'event')}/>
            </Grid>
          </Grid>
          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addWebHook(data:WebHookRequest) {
    return dispatch(addWebHook(data));
  },
  updateWebHook(id:string, data:WebHookRequest) {
    return dispatch(updateWebHook(id, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(WebHookForm)));
