import React, {ChangeEvent, MouseEvent} from 'react';
import Form, {SelectValidator} from '../../../forms/Form';
import {
  Grid,
  withStyles,
  MenuItem, Button, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, TextField, PasswordField} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {AccountUtil, ErrorUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ReduxApp, ActionProps, ErrorState, ReduxAccount, ReduxIntegrations} from "../../../types";
import {AccountRequest, CreditIntegration, CreditIntegrationRequest} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateAccount} from "../../../actions/account";
import {IntegrationHelpAlert} from "../components";
import clsx from "clsx";
import {Mixpanel} from "mixpanel-browser";
import {updateCreditIntegration} from "../../../actions/integrations";

type Props = {
  mixpanel: Mixpanel,
  settings?: CreditIntegration,
  onSubmit: (data:AxiosResponse<CreditIntegration>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  account: ReduxAccount,
  integrations: ReduxIntegrations,
  updateCreditIntegration: ActionProps["updateCreditIntegration"],
  updateAccount: ActionProps["updateAccount"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  provider: CreditIntegration["provider"]|null,
  data: {
    username: string,
    password: string
  }
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

type FormMap = {
  creditPlus: () => void
}

class CreditSettingsForm extends BaseForm<Props, State> {
  formMap:FormMap;

  constructor(props:Props) {
    super(props);

    let form:Form = {
      provider: props.app.data.enums.creditProviders.length === 1 ? props.app.data.enums.creditProviders[0] as CreditIntegration["provider"] : null,
      data: {
        username: '',
        password: ''
      }
    }

    if(props.settings) {
      form = _.merge(form, props.settings);
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    this.formMap = {
      creditPlus: this.renderCreditPlusFields
    };
  }

  onProviderChange = (event:ChangeEvent<{value:CreditIntegration["provider"]}>) => {
    const form = _.cloneDeep(this.state.form);
    form.provider = event.target.value;
    this.setState({ form });
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const {provider, data} = this.state.form;
      const requestData:CreditIntegrationRequest = {
        provider: provider as CreditIntegration["provider"],
        data
      };

      const result = await this.props.updateCreditIntegration(requestData).send();
      this.props.mixpanel.track(this.props.settings ? 'Credit integration updated' : 'Credit integration created');
      if(!AccountUtil.isSetupRecommendationUsed('integrations', this.props.account.data)) {
        let requestData:AccountRequest = AccountUtil.addSetupRecommendationToRequest('integrations', {}, this.props.account.data);
        await this.props.updateAccount(requestData).send();
      }
      this.setState({ loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  renderCreditPlusFields = () => {
    const { intl } = this.props;
    const { form } = this.state;


    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField name="meridianLinkUsername"
                           label={IntlFormatter.formatMessage(intl, 'username')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.data.username')}
                           value={form.data.username}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <PasswordField name="meridianLinkPassword"
                           label={IntlFormatter.formatMessage(intl, 'password')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.data.password')}
                           value={form.data.password}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
          </Grid>
        </Grid>
      </div>
    )
  };

  render() {

    const { intl, classes, app } = this.props;
    const { form, loading, errors } = this.state;


    return (
      <div>
        {form.provider &&
          <div className={classes.mb2}>
            <IntegrationHelpAlert provider={form.provider} />
          </div>
        }
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } }/>
          {app.data.enums.creditProviders.length > 1 &&
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectValidator
                name="provider"
                label={IntlFormatter.formatMessage(intl, 'provider')}
                value={form.provider}
                fullWidth={true}
                onChange={this.onProviderChange}
                validators={['required']}
                errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}>
                {app.data.enums.creditProviders.map((provider, i) => {
                  return (
                    <MenuItem key={i} value={provider}>
                      <FormattedMessage id={provider} />
                    </MenuItem>
                  )
                })}
              </SelectValidator>
            </Grid>
          </Grid>
          }

          {form.provider && this.formMap[form.provider] &&
          <div>
            {app.data.enums.creditProviders.length === 1 &&
            <div className={clsx(classes.mb2, classes.textCenter)}>
              <img src={`/assets/img/logos/${form.provider}.png`} alt={form.provider} />
            </div>
            }
            {this.formMap[form.provider]()}
          </div>
          }

          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateCreditIntegration(data:CreditIntegrationRequest) {
    return dispatch(updateCreditIntegration(data));
  },
  updateAccount(data:AccountRequest) {
    return dispatch(updateAccount(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(CreditSettingsForm)));
