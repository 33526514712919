import React from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  IconButton,
  withStyles,
  Icon,
  Grid,
  Typography,
  Divider, WithStyles
} from "@material-ui/core";
import styles from "../theme/jss/components/errorListStyles";
import _ from 'lodash';
import {ErrorState} from "../types";

type Props = {
  errors: ErrorState[],
  title?: string,
  onClose?: () => void,
  className?: any
} & WithStyles<typeof styles>
  & WrappedComponentProps

type State = {
  open: boolean
}

class ErrorList extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      open: true
    }
  }

  componentDidUpdate = (prevProps:Props) => {
    if(!this.state.open && !_.isEqual(prevProps.errors, this.props.errors)) {
      this.setState({ open: true });
    }
  };

  onClose = () => {
    this.setState({ open: false }, () => {
      if(this.props.onClose) {
        this.props.onClose();
      }
    });
  };

  render() {

    const { classes, errors, title, ...rest } = this.props;
    const { open } = this.state;

    return (
      <div>
        {errors && errors.length > 0 && open &&
          <div {...rest}>
            <div className={classes.root}>
              <List subheader={
                      <ListSubheader component="div">
                        <Grid container justifyContent="space-between" alignItems="center">
                          <Grid item>
                            <div className={classes.titleContainer}>
                              <Icon color="inherit"
                                    className={classes.titleIcon}
                                    fontSize="small">error_outline</Icon>
                              <Typography color="inherit" className={classes.title}>
                                {title}
                                {!title &&
                                  <FormattedMessage id="oops" />
                                }
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item>
                            <IconButton onClick={this.onClose}>
                              <Icon className={classes.icon}>close</Icon>
                            </IconButton>
                          </Grid>
                        </Grid>
                      </ListSubheader>
                    }>
                <Divider className={classes.headerDivider} />
                {errors.map((error, i) => {
                  return (
                    <ListItem key={i} className={classes.listItem}>
                      <ListItemText className={classes.listItemText} disableTypography primary={error.message} />
                    </ListItem>
                  )
                })}
              </List>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(ErrorList));
