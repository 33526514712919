import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from './data';
import _ from "lodash";
import {SubscriptionUtil} from "../utils";
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {Account, SubscriptionRequest, SubscriptionUpdateRequest} from "@jerseydev/orca-loans";

export function getSubscription():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getSubscription({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const subscription = state.subscription && state.subscription.data ? _.cloneDeep(state.subscription.data) : null;

      dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.FETCHING, subscription));

      apiRequest.then(response => {
        dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.IDLE, null));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function subscribe(data:SubscriptionRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.addSubscription(data, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const subscription = state.subscription && state.subscription.data ? _.cloneDeep(state.subscription.data) : null;

      dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.UPDATING, subscription));

      apiRequest.then(response => {
        dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.IDLE, response.data));
        const account = _.cloneDeep(state.account!.data);
        account.active = !SubscriptionUtil.isExpired(response.data.status);
        account.plan = response.data.plan.key as Account["plan"];
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.IDLE, subscription));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateSubscription(data:SubscriptionUpdateRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const state = getState();
    const subscription = state.subscription && state.subscription ? _.cloneDeep(state.subscription.data) : null;

    const cancelSource = createCancelSource();
    const apiRequest = Api.updateSubscription(data, queryParams);
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.UPDATING, subscription));

      apiRequest.then(response => {
        dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.IDLE, subscription));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function cancelSubscription():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const state = getState();

    const cancelSource = createCancelSource();
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const subscription = state.subscription && state.subscription.data ? _.cloneDeep(state.subscription.data) : null;
      dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.UPDATING, subscription));

      Api.cancelSubscription({cancelToken:cancelSource.token}).then(() => {
        Api.getSubscription({cancelToken:cancelSource.token}).then(response => {
          dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.IDLE, response.data));
          resolve(response);
        }).catch(ex => {
          dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.IDLE, subscription));
          reject(ex);
        });
      }).catch((ex) => {
        dispatch(setData(types.SET_SUBSCRIPTION, ReduxAsyncOperation.IDLE, subscription));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateDefaultPaymentMethod(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateAccount({ defaultPaymentSource: id });
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const account = state.account && state.account.data ? _.cloneDeep(state.account.data) : null;
      dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.UPDATING, account));

      apiRequest.then(response => {
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}
