import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    height: '100%'
  },
  default: {

  },
  primary: {
    '& $avatar': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiIcon-root': {
        color: theme.palette.primary.contrastText,
      }
    }
  },
  secondary: {
    '& $avatar': {
      backgroundColor: theme.palette.secondary.main,
      '& .MuiIcon-root': {
        color: theme.palette.secondary.contrastText,
      }
    }
  },
 info: {
    '& $avatar': {
      backgroundColor: theme.palette.info.main,
      '& .MuiIcon-root': {
        color: theme.palette.info.contrastText,
      }
    }
  },
  success: {
    '& $avatar': {
      backgroundColor: theme.palette.success.main,
      '& .MuiIcon-root': {
        color: theme.palette.success.contrastText,
      }
    }
  },
  warning: {
    '& $avatar': {
      backgroundColor: theme.palette.warning.main,
      '& .MuiIcon-root': {
        color: theme.palette.warning.contrastText,
      }
    }
  },
  danger: {
    '& $avatar': {
      backgroundColor: theme.palette.danger.main,
      '& .MuiIcon-root': {
        color: theme.palette.danger.contrastText,
      }
    }
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  avatar: {
    backgroundColor: theme.palette.neutral.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  white: {
    color: theme.palette.white
  }
});

export default styles;
