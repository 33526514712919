import {emphasize} from '@material-ui/core/styles/colorManipulator';
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.neutral.light : theme.palette.neutral.dark,
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(2),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(4)
  },
});

export default styles;
