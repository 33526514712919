import React, {ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {Grid, WithStyles, withStyles} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import BaseForm from "../../../forms/BaseForm";
import {SubmitButton, MoneyInput, TextField, Select, ErrorList} from '../../../components';
import _ from 'lodash';
import IntlFormatter from "../../../intl";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, ErrorState, ReduxApp} from "../../../types";
import {
  Borrower,
  BorrowerAccountAsset, BorrowerAccountAssetUpdateRequest,
  LoanApplication,
  LoanApplicationRequest,
  LoanApplicationUpdateRequest
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {AxiosResponse} from "axios";
import {ErrorUtil} from "../../../utils";

type Props = {
  loanApplication: LoanApplication,
  borrower: Borrower,
  asset?: BorrowerAccountAsset|null,
  actions?: React.ReactNodeArray,
  onSubmit: (data:AxiosResponse<LoanApplication>) => void
  app: ReduxApp,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  name: string,
  type: string|null,
  financialInstitution: string,
  accountNumber: string,
  value: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

type AccountType = {
  value: string,
  label: string,
  otherAsset: boolean
}

class AccountAssetForm extends BaseForm<Props, State> {
  static defaultProps = {
    actions: []
  };

  accountTypes:AccountType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      name: '',
      type: null,
      financialInstitution: '',
      accountNumber: '',
      value: ''
    };

    if(props.asset) {
      const {name, type, financialInstitution, accountNumber, value} = props.asset;
      form = {
        name: name ? name : '',
        type: type ? type : '',
        financialInstitution: financialInstitution ? financialInstitution : '',
        accountNumber: accountNumber ? accountNumber : '',
        value: value ? value.toString() : ''
      }
    }
    
    this.state = {
      loading: false,
      form,
      errors: []
    };

    props.app.data.enums.accountTypes.forEach(type => {
      this.accountTypes.push({
        value: type,
        label: IntlFormatter.formatMessage(props.intl, `account_types_${type}`),
        otherAsset: false
      })
    });
  }

  setAssetType = (type:AccountType) => {
    const form = _.cloneDeep(this.state.form);
    form.type = type.value;
    this.setState({ form });
  };

  onSubmit = async () => {
    try {
      const {loanApplication, borrower, asset} = this.props;
      this.setState({loading:true, errors: []});
      const {name, type, financialInstitution, accountNumber, value} = this.state.form;

      const assetRequestData:BorrowerAccountAssetUpdateRequest = {
        _id: asset ? asset._id : undefined,
        name,
        type: type as BorrowerAccountAssetUpdateRequest["type"],
        financialInstitution,
        accountNumber,
        value: parseInt(value)
      };

      const requestData:LoanApplicationUpdateRequest = {
        borrowers: [{
          _id: borrower._id,
          accountAssets: [assetRequestData]
        }]
      };

      const result = await this.props.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      this.setState({loading:false}, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, actions, classes } = this.props;
    const { loading, form, errors } = this.state;

    return (

      <div>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <Form onSubmit={this.onSubmit}>
          <div className={classes.mb2}>
            <TextField name="accountName"
                       label={IntlFormatter.formatMessage(intl, 'description')}
                       value={form.name}
                       fullWidth={true}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.name')}
                       helperText={IntlFormatter.formatMessage(intl, 'asset_description_help_text')} />
          </div>
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Select name="type"
                        options={this.accountTypes}
                        onChange={this.setAssetType}
                        value={form.type ? this.accountTypes.find(s => s.value === form.type) : ''}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required')
                        ]}
                        label={IntlFormatter.formatMessage(intl, 'account_type')}/>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <TextField name="financialInstitution"
                           label={IntlFormatter.formatMessage(intl, 'financial_institution')}
                           value={form.financialInstitution}
                           fullWidth={true}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.financialInstitution')}
                           validators={['required']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required')
                           ]}/>
              </Grid>
            </Grid>
          </div>
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <TextField name="accountNumber"
                               label={IntlFormatter.formatMessage(intl, 'account_number')}
                               value={form.accountNumber}
                               fullWidth={true}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.accountNumber')}
                               validators={['required']}
                               errorMessages={[
                                 IntlFormatter.formatMessage(intl, 'validation_required')
                               ]}/>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'value')}
                            value={form.value}
                            fullWidth={true}
                            decimalScale={0}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.value')}/>
              </Grid>
            </Grid>
          </div>

          <Grid container alignItems="center" justifyContent="flex-end">
            {actions && actions.length > 0 &&
            <Grid item>
              <div className={classes.mr1}>
                {actions.map((action, i) => {
                  return <span key={i}>{action}</span>
                })}
              </div>
            </Grid>
            }
            <Grid item>
              <SubmitButton loading={loading}>
                <FormattedMessage id="submit"/>
              </SubmitButton>
            </Grid>
          </Grid>

        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(AccountAssetForm)));
