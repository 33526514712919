import React, {Component} from 'react';
import {
  Icon,
  IconButton,
  Tooltip, WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/sortFieldStyles';
import clsx from "clsx";
import IntlFormatter from "../intl";
import Select, {Props as SelectProps} from "./Select";
import {injectIntl, WrappedComponentProps} from "react-intl";

type SortDirectionType = 'asc'|'desc'

type Props = {
  options: any[],
  value: any,
  direction?: SortDirectionType,
  onDirectionChange: (direction:SortDirectionType) => void
} & WrappedComponentProps
  & WithStyles<typeof styles>
  & SelectProps

class SortField extends Component<Props> {
  onDirectionChange = () => {
    if(this.props.onDirectionChange) {
      this.props.onDirectionChange(this.props.direction === 'asc' ? 'desc' : 'asc');
    }
  };

  render() {
    const { intl, classes, direction, ...rest } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.select}>
          <Select {...rest} />
        </div>

        <Tooltip title={IntlFormatter.formatMessage(intl, direction === 'asc' ? 'ascending' : 'descending')}>
          <IconButton onClick={this.onDirectionChange}>
            <Icon className={clsx(classes.icon, direction === 'desc' ? classes.descending : null)}>sort</Icon>
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(SortField));
