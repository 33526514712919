import ReduxQueue from 'redux-queue';
import * as types from '../actions/types';
import createReducer from '../lib/createReducer';
import {ReduxState} from "../data/initialState";
import {ReduxActionData} from "../types";

export const oauthClients = ReduxQueue(createReducer({}, {
  [types.SET_OAUTH_CLIENTS](state:ReduxState, action:ReduxActionData) {
    let newState = {};
    newState = action.payload;
    return newState;
  }
}));