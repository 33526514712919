import {Socket, io as SocketIOClient} from 'socket.io-client';
import {FixMeLater} from "../types";
import {SecureMessage, SecureMessageThread} from "@jerseydev/orca-loans";
import {store} from "../index";
import {getUnreadSecureMessageCount, updateReduxSecureMessage} from "../actions/secureMessages";
import {updateReduxSecureMessageThread} from "../actions/secureMessageThreads";
import {ReduxState} from "../data/initialState";

class SocketUtil {
  static socket:Socket;

  static init = (host:string = 'https://api.orcaloans.com') => {
    if(!SocketUtil.socket) {
      SocketUtil.socket = SocketIOClient(host, {transports:['websocket']});
      SocketUtil.socket.on('secureMessageThread.save', SocketUtil.onSecureMessageThreadUpdate);
      SocketUtil.socket.on('secureMessage.save', SocketUtil.onSecureMessageUpdate);
      SocketUtil.socket.on('connect_error', (err:any) => {
        setTimeout(() => {
          SocketUtil.socket.connect();
        }, 1000);
      });

      // re-authenticate on re-connect. socket connects initially without auth so
      SocketUtil.socket.on('connect', async () => {
        try {
          const state:ReduxState = store.getState();
          if(state.token && state.token.data && state.token.data.accessToken) {
            await SocketUtil.authenticate(state.token.data.accessToken);
          }
        } catch (e) {
          console.log(e);
        }
      });
    }
    return SocketUtil.socket;
  };

  static authenticate = (accessToken:string):Promise<FixMeLater> => {
    return new Promise((resolve, reject) => {
      if(!SocketUtil.socket) {
        return reject('Socket is not initialized');
      }

      SocketUtil.socket
        .emit('authenticate', {token: accessToken})
        .on('authenticated', (authResponse:FixMeLater) => {
          resolve(authResponse);
        })
        .on('unauthorized', (err:any) => {
          reject(err);
        })
        .on('logout', () => {
          //console.log('logout');
        });
    });
  };

  static logout = () => {
    if(SocketUtil.socket) {
      SocketUtil.socket.emit('logout');
    }
  }

  static close = () => {
    if(SocketUtil.socket) {
      SocketUtil.socket.close();
    }
  };

  static onSecureMessageUpdate = (secureMessage:SecureMessage) => {
    store.dispatch(updateReduxSecureMessage(secureMessage));
    store.dispatch(getUnreadSecureMessageCount());
  };

  static onSecureMessageThreadUpdate = (secureMessageThread:SecureMessageThread) => {
    store.dispatch(updateReduxSecureMessageThread(secureMessageThread));
  };
}

export default SocketUtil;