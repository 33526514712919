import React, {useState} from 'react';
import {Grid, Icon, IconButton, Typography, withStyles, WithStyles} from "@material-ui/core";
import {DeleteDialog, Select, Snackbar} from "../../../components";
import IntlFormatter from "../../../intl";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {AclUtil, DateUtil, ErrorUtil, LoanUtil} from "../../../utils";
import {Form} from "../../../forms";
import {
  LoanApplication, LoanApplication2009,
  LoanApplicationDocument,
  LoanApplicationDocumentUpdateRequest,
  LoanDocumentCategory
} from "@jerseydev/orca-loans";
import styles from "../../../theme/jss/components/loanDocumentFormStyles";
import {connect} from "react-redux";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {
  deleteLoanApplicationDocument,
  updateLoanApplicationDocument
} from "../../../actions/loanApplication";
import {ActionProps, DialogState, ErrorState, Integrations, ReduxUser} from "../../../types";
import {Mixpanel} from "mixpanel-browser";
import _ from 'lodash';

type Props = {
  mixpanel: Mixpanel,
  loanApplication: LoanApplication2009|LoanApplication,
  document: LoanApplicationDocument,
  documentCategories: LoanDocumentCategory[],
  integrations: Integrations,
  disabled: boolean,
  updateLoanApplicationDocument: ActionProps["updateLoanApplicationDocument"],
  deleteLoanApplicationDocument: ActionProps["deleteLoanApplicationDocument"],
  user: ReduxUser
} & WithStyles<typeof styles>
  & WrappedComponentProps

const LoanDocumentListItemForm = (props:Props) => {
  const {intl, classes, mixpanel, loanApplication, document, documentCategories, integrations, disabled, user} = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorState[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<LoanApplicationDocument|null>(null);
  const [deleteDialog, setDeleteDialog] = useState<DialogState>({open:false, loading: false, errors: []});

  const setDocumentCategory = async (document:LoanApplicationDocument, category:LoanDocumentCategory) => {
    try {
      setLoading(true);
      setErrors([]);
      await props.updateLoanApplicationDocument(loanApplication._id, document._id, { category: category ? category._id : null }).send();
      mixpanel.track("Loan document category updated");
      if(LoanUtil.hasAllRequiredDocuments(loanApplication, documentCategories, integrations)) {
        mixpanel.track("Loan document categories completed");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setErrors(ErrorUtil.formatErrors(e));
    }
  };

  const onDownloadDocumentClick = async (loanDocument:LoanApplicationDocument) => {
    mixpanel.track("Loan document downloaded");
    window.open(loanDocument.document.url, '_blank');
  };

  const onDeleteDocumentClick = (document:LoanApplicationDocument) => {
    setSelectedDocument(document);
    setDeleteDialog({open:true, loading: false, errors: []});
  };

  const onDeleteDocumentConfirm = async () => {
    try {
      const dialog = _.clone(deleteDialog);
      if(selectedDocument) {
        dialog.loading = true;
        setDeleteDialog(dialog);
        await props.deleteLoanApplicationDocument(loanApplication._id, selectedDocument._id).send();
        mixpanel.track("Loan document deleted");
      }
      setDeleteDialog({open:false, loading: false, errors:[]});
    } catch (e) {
      setDeleteDialog({open:true, loading: false, errors:ErrorUtil.formatErrors(e)});
    }
  };

  const onDeleteDocumentCancel = () => {
    setSelectedDocument(null);
    setDeleteDialog({open:false, loading: false, errors:[]});
  };

  return (
    <Form onSubmit={() => {}}>
      <Snackbar message={IntlFormatter.formatMessage(intl, 'error_occurred')}
                variant="danger"
                open={errors.length > 0}
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setErrors([])}>
                    <Icon>close</Icon>
                  </IconButton>
                ]}
                onClose={() => setErrors([])} />
      <DeleteDialog open={deleteDialog.open}
                    title={IntlFormatter.formatMessage(intl, 'delete_document')}
                    item={selectedDocument ? selectedDocument.name : ''}
                    loading={deleteDialog.loading}
                    errors={deleteDialog.errors}
                    onCancel={onDeleteDocumentCancel}
                    onSubmit={onDeleteDocumentConfirm} />

      <div className={classes.fileItem}>
        <Grid container alignItems="center" spacing={2}>
          {documentCategories.length > 0 &&
            <Grid item xs={12} sm={4}>
              <Select name="documentCategory"
                      options={documentCategories}
                      getOptionLabel={(item:LoanDocumentCategory) => item.name}
                      onChange={(category:LoanDocumentCategory) => setDocumentCategory(document, category)}
                      value={document.category ? documentCategories.find(c => c._id === document.category!._id) : ''}
                      label={IntlFormatter.formatMessage(intl, 'category')}
                      placeholder={IntlFormatter.formatMessage(intl, 'select_category')}
                      disabled={disabled}
                      loading={loading}/>
            </Grid>
          }
          <Grid item xs={8} sm={5}>
            <Typography variant="body1">
              {document.name}
            </Typography>
            <Typography variant="body2">
              <FormattedMessage id="uploaded_on_date" values={{ date: DateUtil.formatUserDateTime(user.data, document.created, 'MM/DD/YYYY h:mm:ss a') }} />
            </Typography>
            {(document.los && document.los.status !== 'completed' && !AclUtil.hasRole(user.data, 'ROLE_MEMBER')) &&
            <Typography variant="body2">
              <FormattedMessage id="los_status" values={{los: 'Encompass'}}/>: <FormattedMessage id={document.los.status}/>
            </Typography>
            }
          </Grid>
          <Grid item xs={4} sm={3}>
            <div className={classes.rowCenter}>
              <IconButton onClick={() => onDownloadDocumentClick(document)}>
                <Icon>save_alt</Icon>
              </IconButton>
              {(document.type === 'upload' && !disabled) &&
              <div className={classes.ml1}>
                <IconButton onClick={() => onDeleteDocumentClick(document)}>
                  <Icon>cancel</Icon>
                </IconButton>
              </div>
              }
            </div>
          </Grid>
        </Grid>
      </div>
    </Form>
  )
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplicationDocument(loanId:string, documentId:string, data:LoanApplicationDocumentUpdateRequest) {
    return dispatch(updateLoanApplicationDocument(loanId, documentId, data));
  },
  deleteLoanApplicationDocument(loanId:string, documentId:string) {
    return dispatch(deleteLoanApplicationDocument(loanId, documentId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(LoanDocumentListItemForm)));