import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 5,
    borderBottom: `solid 3px ${theme.palette.primary.main}`
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  subtitle: {
    marginTop: theme.spacing(1),
    fontSize: 12,
    lineHeight: '1.2em'
  },
  helperTextIcon: {
    marginLeft: theme.spacing(1),
    fontSize: 14,
    color: theme.palette.neutral.mainLight
  }
});

export default styles;
