import React, {Component} from 'react';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {Grid, Typography, withStyles, WithStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {Page} from '../../../layouts';
import {AppLogo} from '../../../components';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {UrlFooter} from "../../../components";
import clsx from "clsx";
import {ReduxUser} from "../../../types";
import {Mixpanel} from "mixpanel-browser";
import {ReduxState} from "../../../data/initialState";
import {UpdateMobilePhoneForm} from "../forms";

type Props = {
  mixpanel: Mixpanel,
  user: ReduxUser,
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps<{}, any, {from:string}>

type State = {
  loading: boolean,
  redirectTo?: string
}

class UpdateMobilePhonePage extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false
    };

    props.mixpanel.identify(props.user.data._id);
  }

  onSkip = () => {
    const { mixpanel } = this.props;
    mixpanel.track("Mobile Phone", {"updated": false});
    this.redirect();
  };

  onSubmit = () => {
    const { mixpanel } = this.props;
    mixpanel.track("Mobile Phone", {"updated": true});
    this.redirect();
  };

  redirect = () => {
    const {location} = this.props;
    const redirectTo = location.state && location.state.from ? location.state.from : '/dashboard'
    this.setState({ redirectTo });
  };

  render() {
    const { intl, classes } = this.props;
    const { redirectTo, loading } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo}/>
    }

    return (
      <Page pageTitle={IntlFormatter.formatMessage(intl, 'add_mobile_phone')}
            loading={loading}>
        <Grid container>
          <Grid item md={6}>
            <div className={classes.houseBg1}>
              <div className={classes.bgOverlay}>

              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className={classes.p4}>
              <div className={clsx(classes.mb3, classes.center)}>
                <AppLogo color="dark"/>
              </div>
              <div className={clsx(classes.mt3, classes.textCenter)}>
                <Typography variant="h2" color="inherit">
                  <FormattedMessage id="add_mobile_phone" />
                </Typography>
              </div>

              <div className={classes.p3}>
                <Typography variant="body1" color="inherit" gutterBottom>
                  <FormattedMessage id="add_mobile_phone_text" />
                </Typography>
                <UpdateMobilePhoneForm onSkip={this.onSkip}
                                       onSubmit={this.onSubmit} />
              </div>
              <UrlFooter/>
            </div>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(UpdateMobilePhonePage)));
