import {appBarHeight, appBarHeightXs} from "../constants";
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  searchFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  searchFieldIcon: {
    marginRight: '10px'
  },
  searchFieldInput: {
    minWidth: '230px',
    [theme.breakpoints.up("sm")]: {
      minWidth: '250px'
    },
    [theme.breakpoints.up("md")]: {
      minWidth: '350px'
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: '450px'
    }
  },
  filterDrawer: {
    '& .MuiDrawer-paper': {
      top: appBarHeight,
      width: 350,
      [theme.breakpoints.down("xs")]: {
        top: appBarHeightXs,
        width: 300,
      },
    }
  },
  filterHeader: {
    marginBottom: theme.spacing(2),
    borderBottom: `solid 1px ${theme.palette.divider}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  filterContent: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  filterFooter: {
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  }
});

export default styles;
