import React from 'react';
import {NetworkConnectionAlert} from "./index";
import {SocketUtil} from "../utils";

type Props = {
  children: React.ReactNode
}

const ApiSocket = (props:Props) => {
  const {children} = props;
  const [connectionError, setConnectionError] = React.useState<boolean>(false);

  React.useEffect(() => {
    SocketUtil.socket.on('connect', () => {
      setConnectionError(false);
    });

    SocketUtil.socket.on('connect_error', () => {
      setConnectionError(true);
    });
  }, []);

  return (
    <div>
      <NetworkConnectionAlert open={connectionError} />
      {children}
    </div>
  )
}

export default ApiSocket;