import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  content: {
    padding: theme.spacing(2)
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  titleIcon: {
    marginRight: theme.spacing(2)
  },
  confirmContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  confirmHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  },
  confirmIconContainer: {
    marginBottom: theme.spacing(2)
  }
});

export default styles;
