import React from "react";
import clsx from "clsx";
import {Item} from "../../../components";
import {Typography} from "@material-ui/core";
import {useStyles} from "../../../theme/jss/layouts/pageStyles";

type Props = {
  icon: string,
  label: string,
  value: string
}

const PricingSearchSummaryGridItem = (props:Props) => {
  const {icon, label, value} = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.mr2, classes.mb2)}>
      <Item label={label} icon={icon}>
        <Typography variant="body1">
          {value}
        </Typography>
      </Item>
    </div>
  )
};

export default PricingSearchSummaryGridItem;