import React from 'react';
import {
  Typography,
  Link,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {PairedList,  Well} from "../index";
import IntlFormatter from "../../intl";
import {Link as RouterLink} from "react-router-dom";
import {LocationUtil, TextUtil} from "../../utils";
import {LoanApplication2009} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009
} & WrappedComponentProps

const LoanPropertySummary = (props:Props) => {

  const {intl, loanApplication} = props;

  let items = [];

  if(loanApplication.property) {

    items = [
      {key: IntlFormatter.formatMessage(intl, 'address'), value: loanApplication.property.address ? LocationUtil.formatLocation(loanApplication.property.address) : IntlFormatter.formatMessage(intl, 'na') },
      {key: IntlFormatter.formatMessage(intl, loanApplication.loan && loanApplication.loan.loanPurpose === 'refinance' ? 'property_value' : 'sale_price'), value: loanApplication.property.propertyValue ? TextUtil.getMoneyText(loanApplication.property.propertyValue, intl) : IntlFormatter.formatMessage(intl, 'na') },
      {key: IntlFormatter.formatMessage(intl, 'year_built'), value: TextUtil.getValueText(loanApplication.property.yearBuilt, intl) },
      {key: IntlFormatter.formatMessage(intl, 'number_of_units'), value: TextUtil.getValueText(loanApplication.property.units, intl) },
      {key: IntlFormatter.formatMessage(intl, 'occupancy'), value: IntlFormatter.formatMessage(intl, loanApplication.property.occupancy ? `occupancy_types_${loanApplication.property.occupancy}` : 'na') },
    ];
    return <PairedList items={items} />
  }

  return (
    <Well color="warn">
      <Typography variant="body1">
        <FormattedMessage id="no_property_info_found" />.&nbsp;
        {!loanApplication.completed &&
        <Link component={RouterLink} to="/apply/form">
          <FormattedMessage id="add_property_info"/>
        </Link>
        }
      </Typography>
    </Well>
  );
}

export default injectIntl(LoanPropertySummary);