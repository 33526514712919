import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from './data';
import _ from "lodash";
import {AnyAction, Dispatch} from "redux";
import {ReduxState} from "../data/initialState";
import {ReduxAsyncOperation} from "../enums";
import {ActionResponse, DashboardActionResponse, DashboardSummary} from "../types";
import {ThunkAction} from "redux-thunk";
import {AxiosResponse} from "axios";

export function getApp():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getAppData({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const app = state.app && state.app.data ? _.cloneDeep(state.app.data) : {};

      dispatch(setData(types.SET_APP, ReduxAsyncOperation.FETCHING, app));

      apiRequest.then(response => {
        dispatch(setData(types.SET_APP, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_APP, ReduxAsyncOperation.IDLE, app));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getDashboard(startDate:string, prevStartDate:string, interval:"days" | "months" | "years"):ThunkAction<DashboardActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {

    const cancelSource = createCancelSource();
    const requests:any[] = [
      Api.getLoanSummaryReport({ startDate, interval }, {cancelToken:cancelSource.token}),
      Api.getLoanStatusReport({ startDate }, {cancelToken:cancelSource.token}),
      Api.getLoanStatusReport({ startDate: prevStartDate, endDate: startDate }, {cancelToken:cancelSource.token}),
      Api.getLoanVolumeReport({ startDate }, {cancelToken:cancelSource.token}),
      Api.getLoanVolumeReport({ startDate: prevStartDate, endDate: startDate }, {cancelToken:cancelSource.token}),
      Api.getUserSummaryReport({ startDate }, {cancelToken:cancelSource.token}), // get the past values for comparison
      Api.getUserSummaryReport({ startDate: prevStartDate, endDate: startDate }, {cancelToken:cancelSource.token})
    ];

    const request:Promise<DashboardSummary> = new Promise(async (resolve, reject) => {
      const state = getState();

      const dashboard = state.dashboard && state.dashboard.data ? _.cloneDeep(state.dashboard.data) : {};

      dispatch(setData(types.SET_DASHBOARD, ReduxAsyncOperation.FETCHING, dashboard));

      try {
        let results = await Promise.all(requests);
        results = _.map(results, 'data');
        const dashboard:DashboardSummary = {
          loanSummary: results[0],
          loanStatus: results[1],
          loanVolume: results[3],
          user: results[5]
        };

        dashboard.loanStatusTrend = {};
        for(let key in results[2]) {
          if(results[2].hasOwnProperty(key)) {
            dashboard.loanStatusTrend[key] = getPercentageChange(results[2][key], results[1][key]);
            //console.log(results[3][key], results[2][key], dashboard.loanStatusTrend[key]);
          }
        }

        dashboard.userTrend = getPercentageChange(results[6].users, results[5].users);

        if(state.loanSettings!.data.tridFields.includes('loanAmount')) {
          dashboard.loanVolumeTrend = results[4].volume > 0 ? getPercentageChange(results[4].volume, results[3].volume) : null;
        } else {
          dashboard.loanVolumeTrend = getPercentageChange(results[4].loans, results[3].loans);
        }

        dispatch(setData(types.SET_DASHBOARD, ReduxAsyncOperation.IDLE, dashboard));
        resolve(dashboard);
      } catch(err) {
        dispatch(setData(types.SET_DASHBOARD, ReduxAsyncOperation.IDLE, dashboard));
        reject(err);
      }
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function setConfigured(data:boolean) {
  return {
    type: types.SET_CONFIGURED,
    payload: data
  }
}

const getPercentageChange = (a:number, b:number):number => {
  let percent;
  if(b !== 0) {
    if(a !== 0) {
      percent = (b - a) / a * 100;
    } else {
      percent = b * 100;
    }
  } else {
    percent = - a * 100;
  }
  return Math.floor(percent);
};
