import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from './data';
import _ from "lodash";
import {AnyAction, Dispatch} from "redux";
import {ReduxState} from "../data/initialState";
import {ReduxAsyncOperation} from "../enums";
import {ActionResponse, SearchParamsType} from "../types";
import {ThunkAction} from "redux-thunk";
import {AxiosResponse} from "axios";

export function getTickets(data?:SearchParamsType):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getTickets(data as any, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const tickets = state.tickets && state.tickets.data ? _.cloneDeep(state.tickets.data) : [];

      dispatch(setData(types.SET_TICKETS, ReduxAsyncOperation.FETCHING, tickets));

      apiRequest.then(response => {
        dispatch(setData(types.SET_TICKETS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_TICKETS, ReduxAsyncOperation.IDLE, tickets));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}