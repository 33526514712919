import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {
  NotificationRequest,
  NotificationSearchRequest,
  NotificationUpdateRequest
} from "@jerseydev/orca-loans";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";

export function getNotifications(queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getNotifications(queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const notifications = state.notifications && state.notifications.data ? _.cloneDeep(state.notifications.data) : [];
      dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.FETCHING, notifications));

      apiRequest.then(response => {
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, notifications));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getNotification(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getNotification(id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const notifications = state.notifications && state.notifications.data ? _.cloneDeep(state.notifications.data) : [];

      dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.FETCHING, notifications));

      apiRequest.then(response => {
        const index = notifications.findIndex(u => u._id === id);
        if(index === -1) {
          notifications.push(response.data);
        } else {
          notifications[index] = response.data;
        }
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, notifications));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, notifications));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function searchNotifications(criteria:NotificationSearchRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.searchNotifications(criteria, queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const notifications = state.notifications && state.notifications.data ? _.cloneDeep(state.notifications.data) : [];

      dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.FETCHING, notifications));
      apiRequest.then(response => {
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, notifications));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}


export function addNotification(notification:NotificationRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createNotification(notification, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const notifications = state.notifications && state.notifications.data ? _.cloneDeep(state.notifications.data) : [];
      dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.ADDING, notifications));

      apiRequest.then(response => {
        notifications.push(response.data);
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, notifications));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, notifications));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateNotification(id:string, notification:NotificationUpdateRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateNotification(id, notification, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const notifications = state.notifications && state.notifications.data ? _.cloneDeep(state.notifications.data) : [];
      dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.UPDATING, notifications));

      apiRequest.then(response => {
        const notificationIndex = notifications.findIndex(u => u._id === id);
        if(notificationIndex === -1) {
          notifications.push(response.data);
        } else {
          notifications[notificationIndex] = response.data;
        }
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, notifications));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, notifications));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteNotification(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteNotification(id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const notifications = state.notifications && state.notifications.data ? _.cloneDeep(state.notifications.data) : [];
      dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.DELETING, notifications));

      apiRequest.then(response => {
        const notificationIndex = notifications.findIndex(u => u._id === id);
        notifications.splice(notificationIndex, 1);
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, notifications));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_NOTIFICATIONS, ReduxAsyncOperation.IDLE, notifications));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}