import React, {Component, MouseEvent} from 'react';
import {
  WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/wellStyles';
import clsx from "clsx";

type DefaultProps = {
  color: 'default'|'info'|'primary'|'secondary'|'success'|'warn'|'danger'
}

type Props = {
  onClick?: (event:MouseEvent) => void
} & WithStyles<typeof styles>
  & Partial<DefaultProps>

class Well extends Component<Props> {
  static defaultProps:DefaultProps = {
    color: 'default',
  };

  onClick = (event:MouseEvent) => {
    if(this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    const { classes, children, color } = this.props;

    return (
      <div className={clsx(classes.root, classes[color!])}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Well);
