import React, {ChangeEvent, MouseEvent} from 'react';
import Form from './Form';
import {
  Grid,
  Button,
  withStyles, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../intl/index';
import BaseForm from "./BaseForm";
import {ErrorList, SubmitButton, TextField, Select} from "../components";
import _ from 'lodash';
import {ErrorUtil} from "../utils";
import {Ticket, TicketRequest} from "@jerseydev/orca-loans";
import Api from '../lib/Api';
import pageStyles from "../theme/jss/layouts/pageStyles";
import {KeyLabelPairType, ErrorState} from "../types";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  subject?: string,
  description?: string,
  refID?: string,
  refCategory?: string,
  onSubmit: (data:AxiosResponse<Ticket>) => void,
  onCancel?: () => void
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  subject: string,
  description: string,
  priority: KeyLabelPairType|null,
  type: KeyLabelPairType|null
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class HelpForm extends BaseForm<Props, State> {
  priorities:KeyLabelPairType[] = [];
  types:KeyLabelPairType[] = [];

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        subject: props.subject ? _.clone(props.subject) : '',
        description: props.description ? _.clone(props.description) : '',
        priority: null,
        type: null
      },
      errors: [],
    };

    this.types = [
      { key: 'Question', label: IntlFormatter.formatMessage(props.intl, 'question') },
      { key: 'Problem', label: IntlFormatter.formatMessage(props.intl, 'problem') },
      { key: 'Feature Request', label: IntlFormatter.formatMessage(props.intl, 'feature_request') }
    ];

    this.priorities = [
      { key: 'low', label: IntlFormatter.formatMessage(props.intl, 'low') },
      { key: 'medium', label: IntlFormatter.formatMessage(props.intl, 'medium') },
      { key: 'high', label: IntlFormatter.formatMessage(props.intl, 'high') },
      { key: 'urgent', label: IntlFormatter.formatMessage(props.intl, 'urgent') }
    ];
  }

  setType = (type:KeyLabelPairType) => {
    const form = _.cloneDeep(this.state.form);
    form.type = type;
    this.setState({ form });
  };

  setPriority = (priority:KeyLabelPairType) => {
    const form = _.cloneDeep(this.state.form);
    form.priority = priority;
    this.setState({ form });
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const {refID, refCategory} = this.props;
      const {description, type, priority, subject} = this.state.form;
      const data:TicketRequest = {
        subject,
        description,
        priority: priority!.key as TicketRequest["priority"],
        type: type!.key as TicketRequest["type"],
        refID,
        refCategory
      }
      const result = await Api.createTicket(data);
      
      const {mixpanel} = this.props;
      mixpanel.track("Support Ticket Submitted", {type: type!.key, priority: priority!.key});

      const form = _.clone(this.state.form);
      form.subject = '';
      form.description = '';
      form.priority = null;
      form.type = null;
      this.setState({ form, loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } }/>
          <div className={classes.mb2}>
            <TextField name="subject"
                       label={IntlFormatter.formatMessage(intl, 'subject')}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.subject')}
                       value={form.subject}
                       fullWidth={true}
                       validators={['required']}
                       errorMessages={[
                         IntlFormatter.formatMessage(intl, 'validation_required')
                       ]}/>
          </div>

          <div className={classes.mb2}>
            <Select name="type"
                    options={this.types}
                    onChange={this.setType}
                    value={form.type ? this.types.find(p => p.key === form.type!.key) : ''}
                    validators={['required']}
                    errorMessages={[
                      IntlFormatter.formatMessage(intl, 'validation_required')
                    ]}
                    label={IntlFormatter.formatMessage(intl, 'type')}/>
          </div>

          <div className={classes.mb2}>
            <Select name="priority"
                    options={this.priorities}
                    onChange={this.setPriority}
                    value={form.priority ? this.priorities.find(p => p.key === form.priority!.key) : ''}
                    validators={['required']}
                    errorMessages={[
                      IntlFormatter.formatMessage(intl, 'validation_required')
                    ]}
                    label={IntlFormatter.formatMessage(intl, 'priority')}/>
          </div>

          <div className={classes.mb2}>
            <TextField name="message"
                       label={IntlFormatter.formatMessage(intl, 'message')}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.description')}
                       value={form.description}
                       multiline
                       rows={3}
                       fullWidth={true}
                       validators={['required']}
                       errorMessages={[
                         IntlFormatter.formatMessage(intl, 'validation_required')
                       ]} />
          </div>

          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="send" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(HelpForm));
