import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  IconButton,
  Icon,
  Button,
  withStyles, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, TextField} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {addBranches} from "../../../actions/branches";
import {ErrorUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionResponse, ErrorState} from "../../../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {Branch, BranchCreateRequest} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";

type Props = {
  onSubmit: (data:AxiosResponse<Branch[]>) => void,
  onCancel?: () => void,
  addBranches: (data:BranchCreateRequest[]) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type FormItem = {
  name: string,
  email: string,
  nmlsId: string
}

type State = {
  loading: boolean,
  form: FormItem[],
  errors: ErrorState[]
}

class MultipleBranchForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: [{ name: '', email: '', nmlsId: '' }],
      errors: []
    };
  }

  onAddBranch = () => {
    const form = _.cloneDeep(this.state.form);
    form.push({ name: '', email: '', nmlsId: '' });
    this.setState({ form });
  };

  onRemoveBranch = (index:number) => {
    const form = _.cloneDeep(this.state.form);
    form.splice(index, 1);
    this.setState({ form });
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const data = _.cloneDeep(this.state.form);
      const result = await this.props.addBranches(data).send();
      this.setState({ loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } } />
          {form.map((branch, i) => {
            return (
              <Grid key={i} container spacing={2}>
                <Grid item xs={5}>
                  <TextField name="name"
                             label={IntlFormatter.formatMessage(intl, 'name')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form[${i}].name`)}
                             value={form[i].name}
                             fullWidth={true}
                             validators={['required']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                </Grid>
                <Grid item xs={4}>
                  <TextField name="email"
                             label={IntlFormatter.formatMessage(intl, 'email')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form[${i}].email`)}
                             value={form[i].email}
                             fullWidth={true}
                             inputProps={{
                               autoCapitalize: 'none',
                             }}
                             validators={['required', 'isEmail']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
                </Grid>
                <Grid item xs={2}>
                  <TextField name="nmlsId"
                             label={IntlFormatter.formatMessage(intl, 'nmls_id')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form[${i}].nmlsId`)}
                             value={form[i].nmlsId}
                             fullWidth={true}
                             validators={['required']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                </Grid>
                <Grid item xs={1}>
                  {i !== 0 &&
                  <IconButton onClick={() => this.onRemoveBranch(i)}>
                    <Icon>close</Icon>
                  </IconButton>
                  }
                </Grid>
              </Grid>
            )
          })}

          <div className={classes.mt2}>
            <Button variant="contained" color="primary" onClick={this.onAddBranch}>
              <FormattedMessage id="add_another_branch" />
            </Button>
          </div>

          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
                <Grid item>
                  <Button onClick={this.props.onCancel}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addBranches(data:BranchCreateRequest[]) {
    return dispatch(addBranches(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(MultipleBranchForm)));
