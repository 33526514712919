import React, {Component} from 'react';
import {
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import styles from "../../../theme/jss/components/setupWizardStyles";
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {BranchForm, LoanOfficerForm} from '../forms';
import {connect} from "react-redux";
import {getBranches} from "../../../actions/branches";
import {getLoanOfficers} from "../../../actions/loanOfficers";
import {updateLoanSettings} from "../../../actions/settings";
import {ErrorUtil, ReduxUtil} from "../../../utils";
import _ from 'lodash';
import {Loader, NumberedListItem, PropagateLoader} from "../../../components";
import {setConfigured} from "../../../actions/app";
import {
  ActionResponse,
  ReduxBranches,
  ReduxLoanOfficers,
  ErrorState, ActionProps
} from "../../../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../../../data/initialState";
import {AccountRequest, LoanSettingsRequest} from "@jerseydev/orca-loans";
import {AxiosPromise} from "axios";
import IntlFormatter from "../../../intl";
import clsx from "clsx";
import {Mixpanel} from "mixpanel-browser";
import {updateAccount} from "../../../actions/account";

type Props = {
  mixpanel: Mixpanel,
  onComplete: () => void,
  getBranches: () => ActionResponse,
  getLoanOfficers: () => ActionResponse,
  branches: ReduxBranches,
  loanOfficers: ReduxLoanOfficers,
  updateLoanSettings: ActionProps["updateLoanSettings"],
  updateAccount: ActionProps["updateAccount"],
  setConfigured: (configured:boolean) => void
} & WithStyles<typeof styles>
  & WrappedComponentProps

type State = {
  pageLoaded: boolean,
  loading: boolean,
  activeStep: number,
  errors: ErrorState[]
}

class SetupWizard extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      activeStep: 0,
      errors: []
    };
  }

  componentDidMount = async () => {
    this.props.mixpanel.track('Setup wizard started');
    const requests:AxiosPromise[] = [
      this.props.getBranches().send(),
      this.props.getLoanOfficers().send()
    ];

    try {
      const results = await Promise.all(requests);
      const branches = results[0].data;
      const loanOfficers = results[1].data;

      let activeStep = 0;
      if(branches.length === 0 && loanOfficers.length === 0) {
        activeStep = 0;
      } else if(branches.length > 0) {
        activeStep = 1;
      }

      this.setState({ pageLoaded: true, activeStep });
    } catch (e) {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(e)});
    }
  };

  onStepComplete = () => {
    this.props.mixpanel.track(`Setup wizard step ${this.state.activeStep} complete`);
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  onPrevStepClick = () => {
    if(this.state.activeStep > 1) {
      this.setState({ activeStep: this.state.activeStep - 1 });
    }
  };

  onLoanOfficerStepComplete = async () => {
    try {
      this.setState({ loading: true });
      const requestData:LoanSettingsRequest = {
        defaultLoanOfficer: this.props.loanOfficers.data[0]._id
      };
      await this.props.updateLoanSettings(requestData).send();
      await this.props.updateAccount({configured:true}).send();
      this.props.setConfigured(true);
      this.props.mixpanel.track('Setup wizard completed');
      this.setState({ loading: false }, () => {
        if(this.props.onComplete) {
          this.props.onComplete();
        }
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  goToStep = (index:number) => {
    this.setState({ activeStep: index });
  };

  render() {

    const { intl, branches, loanOfficers, classes } = this.props;
    const { activeStep, loading, pageLoaded } = this.state;

    return (
      <div>
        {!pageLoaded &&
          <div className={clsx(classes.center, classes.mv4)}>
            <PropagateLoader loading={!pageLoaded} />
          </div>
        }
        <Loader visible={loading} />
        <div>
          <div className={clsx(classes.textCenter, classes.mb2)}>
            <Typography variant="caption">
              <FormattedMessage id="step_start_of_num" values={{current: (activeStep + 1), total: 2}} />
            </Typography>
          </div>
          {activeStep === 0 &&
            <div>
              <NumberedListItem number={1}
                                primaryText={IntlFormatter.formatMessage(intl, 'setup_wizard_branch_text')}
                                color="primary"
                                className={classes.mb2} />
              {pageLoaded &&
                <div className={classes.stepContent}>
                  <BranchForm branch={ReduxUtil.hasData(branches) && branches.data.length > 0 ? _.cloneDeep(branches.data[0]) : undefined}
                              onSubmit={this.onStepComplete}/>
                </div>
              }
            </div>
          }
          {activeStep === 1 &&
            <div>
              <NumberedListItem number={2}
                                primaryText={IntlFormatter.formatMessage(intl, 'setup_wizard_loan_officer_text')}
                                color="primary"
                                className={classes.mb2} />
              {pageLoaded &&
                <div className={classes.stepContent}>
                  <LoanOfficerForm
                    loanOfficer={ReduxUtil.hasData(loanOfficers) && loanOfficers.data.length > 0 ? _.cloneDeep(loanOfficers.data[0]) : ReduxUtil.hasData(branches) && branches.data.length > 0 ? {branch: _.cloneDeep(branches.data[0])} : undefined}
                    onSubmit={this.onLoanOfficerStepComplete}
                    onCancel={() => this.goToStep(0)}/>
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    settings: state.loanSettings,
    branches: state.branches,
    loanOfficers: state.loanOfficers
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getBranches() {
    return dispatch(getBranches())
  },
  getLoanOfficers() {
    return dispatch(getLoanOfficers())
  },
  updateLoanSettings(data:LoanSettingsRequest) {
    return dispatch(updateLoanSettings(data))
  },
  updateAccount(data:AccountRequest) {
    return dispatch(updateAccount(data))
  },
  setConfigured(configured:boolean) {
    return dispatch(setConfigured(configured))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(SetupWizard)));