import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {
  Grid,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import {
  PageTitle,
  Section,
  ClipboardText,
  TextMask,
  Item
} from "../../../components";
import {Redirect, RouteComponentProps} from 'react-router-dom';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {DateUtil, ErrorUtil} from "../../../utils";
import _ from 'lodash';
import clsx from "clsx";
import {WebHookRequestLogsList} from "../components";
import {ActionProps, ErrorState, ReduxUser, ReduxWebHooks, SnackbarState} from "../../../types";
import {WebHook} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";

type Props = {
  routeProps: ActionProps,
  user: ReduxUser,
  webHooks: ReduxWebHooks
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps<{id:string}>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  snackbar: SnackbarState|null,
  webHook: WebHook|null,
  errors: ErrorState[],
  redirectTo?: string,
  errorStatusCode?: number|null
}

class WebHookDetailPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      webHook: null,
      snackbar: null,
      errors: []
    };
  }

  componentDidMount = async () => {
    try {
      const result = await this.props.routeProps.getWebHook(this.props.match.params.id).send();
      const webHook:WebHook = _.cloneDeep(result.data);
      this.setState({ pageLoaded: true, webHook });
    } catch (e) {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(e) });
    }
  };

  getPageTitle = () => {
    const { webHook } = this.state;
    if(webHook) {
      return webHook.url;
    }

    return '';
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="event" />
    )
  };

  render() {

    const { intl, classes, user } = this.props;
    const { pageLoaded, loading, webHook, redirectTo, snackbar, errorStatusCode } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo, state: { snackbar } }}  />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   loading={loading}
                   pageLoaded={pageLoaded}
                   errorStatusCode={errorStatusCode}
                   breadcrumbs={[
                     { icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     { title: IntlFormatter.formatMessage(intl, 'webhooks'), to: '/admin/settings/webhooks' },
                     { title: this.getPageTitle() }
                   ]}>
        {webHook &&
          <div className={classes.p2}>
            <div className={classes.mb2}>
              <Grid container spacing={2}>
                <Grid item>
                  <div className={clsx(classes.mr2, classes.mb2)}>
                    <Item label={IntlFormatter.formatMessage(intl, 'type')}>
                      <Typography variant="body1">
                        {IntlFormatter.formatMessage(intl, `webhook_type_${webHook.type}`)}
                      </Typography>
                    </Item>
                  </div>
                </Grid>
                <Grid item>
                  <div className={clsx(classes.mr2, classes.mb2)}>
                    <Item label={IntlFormatter.formatMessage(intl, 'event')}>
                      <Typography variant="body1">
                        {IntlFormatter.formatMessage(intl, `webhook_event_${webHook.event}`)}
                      </Typography>
                    </Item>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.mb2}>
                    <Item label={IntlFormatter.formatMessage(intl, 'created')}>
                      <Typography variant="body1">
                        {DateUtil.formatUserDate(user.data, webHook.created)}
                      </Typography>
                    </Item>
                  </div>
                </Grid>
              </Grid>

              <div>
                <Typography variant="caption">
                  <FormattedMessage id="signing_key" />
                </Typography>
                <Typography variant="body1">
                  <ClipboardText value={webHook.signingKey}>
                    <TextMask>{webHook.signingKey}</TextMask>
                  </ClipboardText>
                </Typography>
              </div>
            </div>
            <Section title={IntlFormatter.formatMessage(intl, 'logs')}>
              <WebHookRequestLogsList webHook={webHook} />
            </Section>
          </div>
        }
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    webHooks: state.webHooks,
    user: state.user
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(WebHookDetailPage)));
