import React, {MouseEvent} from 'react';
import {
  Button,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  withStyles,
  MenuItem,
  ListItemIcon,
  Menu,
  Typography, WithStyles
} from '@material-ui/core';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {ErrorUtil, LoanUtil} from "../../../utils";
import _ from 'lodash';
import {OtherLiabilityForm} from '../forms';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {
  DeleteDialog,
  Dialog,
  Fab,
  Section
} from "../../../components";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {
  LoanApplication,
  Borrower,
  BorrowerOtherLiability, LoanApplicationUpdateRequest
} from "@jerseydev/orca-loans";
import {ActionProps, DialogState} from "../../../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";

type Props = {
  loanApplication: LoanApplication,
  borrower: Borrower,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  selectedItem: BorrowerOtherLiability|null,
  formDialogOpen: boolean,
  deleteDialog: DialogState,
  actionMenuEl: Element|null
}

class AccountAssetsList extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      selectedItem: null,
      formDialogOpen: false,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      actionMenuEl: null
    };
  }

  onShowActionMenu = (event:MouseEvent, item:BorrowerOtherLiability) => {
    this.setState({
      actionMenuEl: event.currentTarget,
      selectedItem: item
    });
  };

  onShowFormClick = (event:MouseEvent) => {
    event.preventDefault();
    this.setState({
      formDialogOpen: true,
      actionMenuEl: null
    });
  };

  onHideFormClick = () => {
    this.setState({
      formDialogOpen: false,
      selectedItem: null
    });
  };

  onEditClick = () => {
    this.setState({
      formDialogOpen: true,
      actionMenuEl: null
    });
  };

  onFormSubmit = () => {
    this.setState({
      selectedItem: null,
      formDialogOpen: false
    });
  };

  onDeleteClick = () => {
    this.setState({
      deleteDialog: {
        open: true,
        loading: false,
        errors: []
      },
      actionMenuEl: null
    });
  };

  onDeleteClose = () => {
    this.setState({
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      selectedItem: null
    });
  };

  onCloseActionMenu = () => {
    this.setState({
      actionMenuEl: null,
      selectedItem: null
    });
  };

  onDeleteConfirm = async () => {
    try {
      const {borrower, loanApplication} = this.props;
      const {selectedItem} = this.state;
      if(loanApplication.borrowers && selectedItem) {
        const borrowerIndex = loanApplication.borrowers.findIndex(b => b._id === borrower._id);
        if(loanApplication.borrowers[borrowerIndex]) {
          const deleteDialog = _.clone(this.state.deleteDialog);
          deleteDialog.loading = true;
          this.setState({deleteDialog});

          if(borrower.otherLiabilities) {
            const index = borrower.otherLiabilities.findIndex(a => a._id === selectedItem._id);
            await this.props.updateLoanApplication(loanApplication._id, [`borrowers[${borrowerIndex}].otherLiabilities[${index}]`], {merge:'delete'}).send();
          }
        }
      }
      this.setState({
        deleteDialog: {
          open: false,
          loading: false,
          errors: []
        },
        selectedItem: null
      });
    } catch (e) {
      this.setState({
        deleteDialog: {
          open: true,
          loading: false,
          errors: ErrorUtil.formatErrors(e)
        }
      });
    }
  };

  render() {

    const { intl, classes, borrower, loanApplication } = this.props;
    const { selectedItem, formDialogOpen, deleteDialog, actionMenuEl } = this.state;
    const hasPendingAssetReports = LoanUtil.hasPendingAssetReports(loanApplication);

    return (
      <div>

        <Dialog open={formDialogOpen}
                title={IntlFormatter.formatMessage(intl, selectedItem ? 'edit_liability_for' : 'add_liability_for', { name: `${borrower.firstName} ${borrower.lastName}`}) }
                icon={<Icon>money_off</Icon>}
                color="primaryAlt"
                onClose={this.onHideFormClick}
                fullWidth={true}
                maxWidth="sm">
          <div>
            <OtherLiabilityForm loanApplication={loanApplication}
                                borrower={borrower}
                                otherLiability={selectedItem}
                                onSubmit={this.onFormSubmit}
                                actions={[
                                  <Button onClick={this.onHideFormClick}>
                                    <FormattedMessage id="cancel" />
                                  </Button>
                                ]}/>
          </div>
        </Dialog>

        <DeleteDialog open={deleteDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'delete_liability')}
                      item={selectedItem ? IntlFormatter.formatMessage(intl, selectedItem.type ? `other_liability_type_${selectedItem.type}` : 'liability') : ''}
                      loading={deleteDialog.loading}
                      errors={deleteDialog.errors}
                      onCancel={this.onDeleteClose}
                      onSubmit={this.onDeleteConfirm} />


        <Menu anchorEl={actionMenuEl}
              open={Boolean(actionMenuEl)}
              onClose={this.onCloseActionMenu}>
          {selectedItem &&
          <MenuItem onClick={this.onEditClick}>
            <ListItemIcon>
              <Icon>mode_edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
          </MenuItem>
          }
          <MenuItem onClick={this.onDeleteClick} disabled={hasPendingAssetReports}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')} />
          </MenuItem>
        </Menu>

        <Section title={IntlFormatter.formatMessage(intl, 'other_liabilities')}
                 subtitle={IntlFormatter.formatMessage(intl, 'other_liabilities_description')}
                 actions={
                   <Fab color="primary"
                        size="small"
                        flat
                        rounded
                        onClick={this.onShowFormClick}>
                     <Icon>add</Icon>
                   </Fab>
                 }>

          {borrower.otherLiabilities && borrower.otherLiabilities.length === 0 &&
          <Button variant="outlined"
                  color="primary"
                  onClick={this.onShowFormClick}>
            <FormattedMessage id="add_liability" />
          </Button>
          }

          {borrower.otherLiabilities && borrower.otherLiabilities.length > 0 &&
          <div className={classes.mb2}>
            <List>
              {borrower.otherLiabilities.map((liability, i) => {
                return (
                  <ListItem key={i}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="body1">
                          {liability.type ? IntlFormatter.formatMessage(intl, `other_liability_type_${liability.type}`) : 'liability'}
                        </Typography>
                        <Typography variant="body2">
                          {liability.monthlyPayment ? liability.monthlyPayment.toMoney() : ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div className={classes.rowCenter}>
                          <IconButton onClick={event => this.onShowActionMenu(event, liability)}>
                            <Icon>more_vert</Icon>
                          </IconButton>
                        </div>
                      </Grid>
                    </Grid>
                  </ListItem>
                )
              })}
            </List>
          </div>
          }
        </Section>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationUpdateRequest, queryParams?:any) {
    return dispatch(updateLoanApplication(id, data, queryParams));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(AccountAssetsList)));
