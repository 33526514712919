import {SearchCriteriaWithPagingType, SearchParamsType} from "../types";

class SearchUtil {
  static getParamsFromSearchCriteria = (searchCriteria?:SearchCriteriaWithPagingType|null|undefined):SearchParamsType|undefined => {
    if(searchCriteria) {
      const params:SearchParamsType = {};
      if(searchCriteria.order && searchCriteria.order.column && searchCriteria.order.direction) {
        params.orderBy = searchCriteria.order.column;
        params.orderByDirection = searchCriteria.order.direction;
      }

      if(searchCriteria.limit) {
        params.limit = searchCriteria.limit;
      }

      if(searchCriteria.skip) {
        params.skip = searchCriteria.skip;
      }

      if(searchCriteria.deleted) {
        params.deleted = searchCriteria.deleted;
      }

      return params;
    }

  }
}

export default SearchUtil;