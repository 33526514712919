import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import {unregister} from './registerServiceWorker';
import {BrowserRouter} from 'react-router-dom';
import reducers from './reducers';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/lib/integration/react';
import {IntlProvider} from 'react-intl-redux';
import initialState, {ReduxState} from './data/initialState';
import mixpanel, {Mixpanel} from 'mixpanel-browser';
import {MixpanelProvider, MixpanelConsumer} from 'react-mixpanel';
import {Loader} from "./components";
import * as Sentry from '@sentry/browser';
import {logout} from "./actions/auth";
import {version} from '../package.json';
import {FixMeLater} from "./types";
import {RefreshTokenUtil, SocketUtil} from "./utils";
import {setBearerSecurityWorker} from "./lib/Api";

import {Locales} from "./enums";
import translations from './intl/translations';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, release: version, environment: process.env.NODE_ENV });
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '');
SocketUtil.init(process.env.REACT_APP_API_HOST);

const messages = {
  [Locales.EN]: translations.en.messages,
  [Locales.ES]: translations.es.messages,
}
const currentLocal = Locales.EN;

// eslint-disable-next-line
Number.prototype.toMoney = function(c:number, d:string = '.', t:string = ',', p:string = '$') {
  c = isNaN(c = Math.abs(c)) ? 2 : c;
  let n:FixMeLater = this;
  const s = n < 0 ? "-" : "";
  const i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  // @ts-ignore
  return p + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

function configureStore(initialState:ReduxState) {
  let enhancer:any;
  if(process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancer = compose(
      applyMiddleware(
        thunkMiddleware
      ),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  } else {
    enhancer = compose(
      applyMiddleware(
        thunkMiddleware
      )
    );
  }

  // @ts-ignore
  return createStore(reducers, initialState, enhancer);
}

let store:FixMeLater = configureStore(initialState);
let persistor = persistStore(store);
//persistor.purge();


const logoutDispatch = function() {
  return store.dispatch(logout());
};

const onBeforeLift = async () => {
  const state = store.getState();
  if(state.token.data && state.token.data.accessToken) {
    try {
      await RefreshTokenUtil.verify(state.token.data.accessToken);
      setBearerSecurityWorker(state.token.data.accessToken);
    } catch (e) {
      // clear token
      logoutDispatch();
    }
  }
};

unregister();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={<Loader visible={true} />}
      onBeforeLift={onBeforeLift}
      persistor={persistor}>
      <IntlProvider locale={currentLocal} defaultLocale={Locales.EN} messages={messages[currentLocal]}>
        <BrowserRouter>
          <MixpanelProvider mixpanel={mixpanel}>
            <MixpanelConsumer>
              {(mixpanel: Mixpanel) => (
                // @ts-ignore
                <App mixpanel={mixpanel} />
              )}
            </MixpanelConsumer>
          </MixpanelProvider>
        </BrowserRouter>
      </IntlProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'));
//registerServiceWorker();

export { store, persistor }