import React from "react";
import Rev1 from "./LoanDetailsRev1";
import Rev2 from "./LoanDetailsRev2";
import {LoanApplication2009, LoanApplication} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";
import {MixpanelConsumer} from "react-mixpanel";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  showMenu?: boolean
}

function LoanDetails(props:Props) {

  const { loanApplication, ...rest } = props;

  return (
    <MixpanelConsumer>
      {(mixpanel:Mixpanel) => {
        if(loanApplication.__t === 'Rev1') {
          return <Rev1 loanApplication={loanApplication as LoanApplication2009}
                       mixpanel={mixpanel}
                       {...rest} />
        }

        return <Rev2 loanApplication={loanApplication as LoanApplication}
                     mixpanel={mixpanel}
                     {...rest} />
      }}
    </MixpanelConsumer>
  )
}

export default LoanDetails;