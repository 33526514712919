import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {OAuthClientRequest} from "@jerseydev/orca-loans";

export function getOauthClients(queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getOAuthClients(queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const oauthClients = state.oauthClients && state.oauthClients.data ? _.cloneDeep(state.oauthClients.data) : [];
      dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.FETCHING, oauthClients));

      apiRequest.then(response => {
        dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.IDLE, oauthClients));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}
export function addOauthClient(data:OAuthClientRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createOAuthClient(data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const oauthClients = state.oauthClients && state.oauthClients.data ? _.cloneDeep(state.oauthClients.data) : [];
      dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.ADDING, _.cloneDeep(oauthClients)));

      apiRequest.then(response => {
        oauthClients.push(response.data);
        dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.IDLE, oauthClients));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.IDLE, oauthClients));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateOauthClient(id:string, data:OAuthClientRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    //delete data.account;
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateOAuthClient(id, data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const oauthClients = state.oauthClients && state.oauthClients.data ? _.cloneDeep(state.oauthClients.data) : [];
      dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.UPDATING, _.cloneDeep(oauthClients)));

      apiRequest.then(response => {
        const index = oauthClients.findIndex(l => l._id === id);
        if(index === -1) {
          oauthClients.push(response.data);
        } else {
          oauthClients[index] = response.data;
        }
        dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.IDLE, oauthClients));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.IDLE, oauthClients));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function reissueOauthClientSecret(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.reIssueOAuthClientSecret(id, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const oauthClients = state.oauthClients && state.oauthClients.data ? _.cloneDeep(state.oauthClients.data) : [];
      dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.UPDATING, _.cloneDeep(oauthClients)));

      apiRequest.then(response => {
        const index = oauthClients.findIndex(l => l._id === id);
        if(index === -1) {
          oauthClients.push(response.data);
        } else {
          oauthClients[index] = response.data;
        }
        dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.IDLE, oauthClients));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.IDLE, oauthClients));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteOauthClient(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteOAuthClient(id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const oauthClients = state.oauthClients && state.oauthClients.data ? _.cloneDeep(state.oauthClients.data) : [];
      dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.DELETING, _.cloneDeep(oauthClients)));

      apiRequest.then(response => {
        const index = oauthClients.findIndex(c => c._id === id);
        if(index > -1) {
          oauthClients.splice(index, 1);
        }
        dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.IDLE, oauthClients));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_OAUTH_CLIENTS, ReduxAsyncOperation.IDLE, oauthClients));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}