import {boxShadow, defaultRadius} from "../index";
import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  root: {
    borderRadius: 5,
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    minWidth: 200
  },
  default: {
    '& $selectedTierButton': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.white,
    }
  },
  rose: {
    ...boxShadow,
    backgroundColor: theme.palette.rose.main,
    color: theme.palette.rose.contrastText,
    '& $featureListItem': {
      borderBottom: `solid 1px rgba(255, 255, 255, .3)`,
    },
    '& $selectedTierButton': {
      backgroundColor: theme.palette.white,
      borderColor: theme.palette.white,
      color: theme.palette.rose.main,
    }
  },
  tierButton: {

  },
  planName: {
    fontSize: 13,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  featureList: {
    marginTop: theme.spacing(2)
  },
  featureListItem: {
    textAlign: 'center',
    borderBottom: `solid 1px rgba(0, 0, 0, .1)`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  featureItem: {
    fontSize: 13,
  },
  center: {
    textAlign: 'center'
  },
  details: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  currency: {
    fontSize: 16,
    top: 10,
    position: 'relative',
    verticalAlign: 'top'
  },
  tier: {
    padding: `${theme.spacing(1)}px 0`
  },
  price: {
    fontSize: 50,
    fontWeight: 'bold'
  },
  priceDetails: {
    fontSize: 14,
  },
  priceTierDetails: {
    fontSize: 12,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  currentPlan: {
    display: 'inline-block',
    border: `solid 1px ${theme.palette.white}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: defaultRadius,
    textTransform: 'uppercase'
  },
  selectedTierButton: {

  }
});

export default styles;
