import React from "react";
import Rev1 from "./BorrowerPersonalInfoSummaryRev1";
import Rev2 from "./BorrowerPersonalInfoSummaryRev2";
import {
  LoanApplication2009,
  Borrower2009,
  LoanApplication,
  Borrower
} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  borrower: Borrower2009|Borrower
}

function BorrowerPersonalInfoSummary(props:Props) {

  const { loanApplication, borrower } = props;

  if(loanApplication.__t === 'Rev1') {
    return <Rev1 borrower={borrower as Borrower2009}  />
  }

  return <Rev2 borrower={borrower as Borrower} />
}

export default BorrowerPersonalInfoSummary;