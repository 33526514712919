import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  withStyles,
  Button, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, TextField} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {addOauthClient, updateOauthClient} from "../../../actions/oauthClients";
import {ErrorUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, ErrorState} from "../../../types";
import {OAuthClient, OAuthClientRequest} from "@jerseydev/orca-loans";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  oauthClient?: OAuthClient|null,
  onSubmit: (data:AxiosResponse<OAuthClient>) => void,
  onCancel?: () => void,
  addOauthClient: ActionProps["addOauthClient"],
  updateOauthClient: ActionProps["updateOauthClient"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  name: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class OauthClientForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        name: props.oauthClient ? _.clone(props.oauthClient.name) : ''
      },
      errors: []
    };
  }

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      const {mixpanel} = this.props;
      this.setState({ loading: true, errors: [] });
      const {name} = this.state.form;
      let result:AxiosResponse<OAuthClient>;
      if(this.props.oauthClient && this.props.oauthClient._id) {
        result = await this.props.updateOauthClient(this.props.oauthClient._id, {name}).send();
        mixpanel.track("OAuth client updated");
      } else {
        result = await this.props.addOauthClient({name}).send();
        mixpanel.track("OAuth client added");
      }
      this.setState({ loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } }/>
          <TextField name="name"
                     label={IntlFormatter.formatMessage(intl, 'name')}
                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.name')}
                     value={form.name}
                     fullWidth={true}
                     validators={['required']}
                     errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addOauthClient(data:OAuthClientRequest) {
    return dispatch(addOauthClient(data));
  },
  updateOauthClient(id:string, data:OAuthClientRequest) {
    return dispatch(updateOauthClient(id, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(OauthClientForm)));
