import React from 'react';
import {useIntl} from "react-intl";
import IntlFormatter from "../intl";
import {StatusCodeMap} from "../types";
import {useStyles} from "../theme/jss/components/accessError";
import {Icon, Typography} from "@material-ui/core";
import clsx from "clsx";

type Props = {
  className?: string,
  statusCode: number
}

const AccessError = (props:Props) => {
  const {className, statusCode} = props;
  const intl = useIntl();
  const classes = useStyles();
  const messageMap:StatusCodeMap = {
    401: IntlFormatter.formatMessage(intl, 'unauthorized_message'),
    403: IntlFormatter.formatMessage(intl, 'forbidden_message'),
    404: IntlFormatter.formatMessage(intl, 'not_found_message'),
    409: IntlFormatter.formatMessage(intl, 'conflict_message'),
    422: IntlFormatter.formatMessage(intl, 'not_found_message'),
  };
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.iconContainer}>
        <Icon>report_problem</Icon>
      </div>
      <Typography variant="body1" className={classes.message}>
        {messageMap[statusCode] ? messageMap[statusCode] : IntlFormatter.formatMessage(intl, 'unauthorized_message')}
      </Typography>
    </div>
  )
};

export default AccessError;
