import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  withStyles,
  Icon,
  IconButton,
  ListItemText,
  Grid,
  MenuItem,
  ListItemIcon,
  Menu,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemSecondaryAction,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  WithStyles,
} from "@material-ui/core";
import {
  DeleteDialog,
  Dialog,
  ErrorList,
  PageTitle,
  Snackbar,
  Fab,
  PaginationHeader,
  NoResultsCard,
  AddButton,
  Pagination
} from "../../../components";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {DateUtil, ErrorUtil, ReduxUtil, SearchUtil} from "../../../utils";
import {WebHookForm} from "../forms";
import _ from 'lodash';
import clsx from "clsx";
import {Link} from 'react-router-dom';
import {
  ActionProps,
  DialogState,
  ErrorState,
  ReduxUser,
  ReduxWebHooks,
  SearchCriteriaWithPagingType,
  SnackbarState
} from "../../../types";
import {WebHook} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  user: ReduxUser,
  webHooks: ReduxWebHooks
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  snackbar: SnackbarState|null,
  webHookFormOpen: boolean,
  selectedWebHook: WebHook|null,
  hasInitialResults?: boolean,
  searchCriteria: SearchCriteriaWithPagingType,
  deleteDialog: DialogState,
  actionMenuEl: Element|null,
  errors: ErrorState[],
}

class WebHooksPage extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      snackbar: null,
      webHookFormOpen: false,
      selectedWebHook: null,
      searchCriteria: {
        searchText: '',
        order: {
          column: 'created',
          direction: 'desc'
        },
        limit: 100,
        skip: 0,
        totalCount: 0,
        page: 1
      },
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      actionMenuEl: null,
      errors: []
    };
  }

  componentDidMount = async () => {
    await this.getWebHooks();
  };

  getWebHooks = async () => {
    try {
      this.setState({loading: true});
      const searchCriteria = _.cloneDeep(this.state.searchCriteria);
      const params = SearchUtil.getParamsFromSearchCriteria(searchCriteria);
      const result = await this.props.routeProps.getWebHooks(params).send();
      searchCriteria.totalCount = parseInt(result.headers['total-count']);
      this.setState({ loading: false, pageLoaded: true, hasInitialResults: result.data.length > 0, searchCriteria });
    } catch (e) {
      this.setState({ loading: false, pageLoaded: true, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onPageChange = (event:MouseEvent, value:number) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.skip = (value - 1) * searchCriteria.limit;
    searchCriteria.page = value;
    this.setState({ searchCriteria }, this.getWebHooks);
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'webhooks');
  };

  renderTitleBar = () => {
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={9}>
          <PageTitle title={this.getPageTitle()}
                     subtitle={IntlFormatter.formatMessage(this.props.intl, 'webhooks_text')}
                     dangerouslySetInnerHTML={true}
                     icon="event" />
        </Grid>
        <Grid item>
          <Hidden mdUp>
            <Tooltip title={IntlFormatter.formatMessage(this.props.intl, 'add_webhook')}>
              <Fab color="primary"
                   size="small"
                   onClick={this.onAddWebHookClick}>
                <Icon>add</Icon>
              </Fab>
            </Tooltip>
          </Hidden>
          <Hidden smDown>
            <AddButton variant="contained"
                       color="primary"
                       onClick={this.onAddWebHookClick}>
              <FormattedMessage id="add_webhook" />
            </AddButton>
          </Hidden>
        </Grid>
      </Grid>
    )
  };

  hideSnackbar = () => {
    this.setState({ snackbar: null });
  };

  onAddWebHookClick = () => {
    this.setState({ webHookFormOpen: true });
  };

  onEditWebHookClick = () => {
    this.setState({ actionMenuEl: null, webHookFormOpen: true });
  };
  
  onActionMenuClick = (event:MouseEvent, webHook:WebHook) => {
    this.setState({ actionMenuEl: event.currentTarget, selectedWebHook: _.cloneDeep(webHook) });
  };

  onActionMenuClose = () => {
    this.setState({ actionMenuEl: null, selectedWebHook: null });
  };

  onWebHookSubmit = () => {
    const snackbar:SnackbarState = {
      open: true,
      variant: 'success',
      message: IntlFormatter.formatMessage(this.props.intl, 'webhook_saved')
    };

    this.setState({ webHookFormOpen: false, snackbar, selectedWebHook: null });
  };

  onWebHookCancel = () => {
    this.setState({ webHookFormOpen: false, selectedWebHook: null });
  };

  onWebHookDeleteClick = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = true;
    this.setState({ actionMenuEl: null, deleteDialog });
  };

  onWebHookDeleteConfirm = async () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    try {
      deleteDialog.loading = true;
      this.setState({ deleteDialog });
      if(this.state.selectedWebHook) {
        await this.props.routeProps.deleteWebHook(this.state.selectedWebHook._id).send();
        this.props.mixpanel.track("Webhook deleted");
      }
      const snackbar:SnackbarState = {
        open: true,
        variant: 'success',
        message: IntlFormatter.formatMessage(this.props.intl, 'webhook_deleted')
      };
      deleteDialog.open = false;
      deleteDialog.loading = false;
      this.setState({ deleteDialog, snackbar, selectedWebHook: null });
    } catch (e) {
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({ deleteDialog });
    }

  };

  onWebHookDeleteCancel = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = false;
    deleteDialog.loading = false;
    deleteDialog.errors = [];
    this.setState({ deleteDialog, selectedWebHook: null });
  };


  render() {

    const { intl, classes, webHooks, user, mixpanel } = this.props;
    const { pageLoaded, loading, snackbar, errors, webHookFormOpen, actionMenuEl, deleteDialog, selectedWebHook, searchCriteria, hasInitialResults } = this.state;
    const pages = Math.ceil(searchCriteria.totalCount / searchCriteria.limit);

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: this.getPageTitle() }
                   ]}
                   pageLoaded={pageLoaded}
                   loading={loading}>
        {snackbar &&
        <Snackbar open={!!snackbar}
                  variant={snackbar.variant}
                  onClose={this.hideSnackbar}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.hideSnackbar}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        }

        <Dialog open={webHookFormOpen}
                icon={<Icon>public</Icon>}
                title={IntlFormatter.formatMessage(intl, selectedWebHook ? 'edit_webhook' : 'add_webhook')}
                color="primaryAlt"
                fullWidth={true}
                maxWidth="sm"
                onClose={this.onWebHookCancel}>
          <WebHookForm webHook={selectedWebHook}
                       mixpanel={mixpanel}
                       onSubmit={this.onWebHookSubmit}
                       onCancel={this.onWebHookCancel} />
        </Dialog>

        {selectedWebHook &&
          <Menu anchorEl={actionMenuEl}
                open={Boolean(actionMenuEl)}
                onClose={this.onActionMenuClose}>
            <MenuItem component={Link} to={`/admin/settings/webhooks/${selectedWebHook._id}`}>
              <ListItemIcon>
                <Icon>remove_red_eye</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'view')} />
            </MenuItem>
            <MenuItem onClick={this.onEditWebHookClick}>
              <ListItemIcon>
                <Icon>mode_edit</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')}/>
            </MenuItem>
            <MenuItem onClick={this.onWebHookDeleteClick}>
              <ListItemIcon>
                <Icon>cancel</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')}/>
            </MenuItem>
          </Menu>
        }

        <DeleteDialog open={deleteDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'delete_webhook')}
                      item={selectedWebHook ? selectedWebHook.url : ''}
                      loading={deleteDialog.loading}
                      onCancel={this.onWebHookDeleteCancel}
                      onSubmit={this.onWebHookDeleteConfirm} />

        <ErrorList errors={errors}
                   className={classes.p2}
                   onClose={() => { this.setState({ errors: [] }); } } />
        <div>
          {ReduxUtil.hasData(webHooks) && webHooks.data.length > 0 &&
          <div>
            <div className={clsx(classes.ph2, classes.pv1)}>
              <PaginationHeader totalResults={searchCriteria.totalCount}
                                currentPage={searchCriteria.page}
                                totalPages={pages} />
            </div>
            <Hidden mdUp implementation="css">
              <Divider />
              <List>
                {webHooks.data.map((webHook, i) => {
                  return (
                    <ListItem key={i}>
                      <ListItemText primary={`${webHook.url}`}
                                    secondary={`${webHook.type} ${webHook.event}`} />
                      <ListItemSecondaryAction>
                        <IconButton onClick={event => this.onActionMenuClick(event, webHook)}>
                          <Icon>more_vert</Icon>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </List>
            </Hidden>
            <Hidden smDown implementation="css">
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="url"/>
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="type"/>
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="event"/>
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="created"/>
                      </TableCell>
                      <TableCell/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {webHooks.data.map(webHook => {
                      return (
                        <TableRow key={webHook._id}>
                          <TableCell component="th" scope="row">
                            <Link to={`/admin/settings/webhooks/${webHook._id}`}>
                              <b>{webHook.url}</b>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id={`webhook_type_${webHook.type}`} />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id={`webhook_event_${webHook.event}`} />
                          </TableCell>
                          <TableCell>
                            {DateUtil.formatUserDate(user.data, webHook.created)}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={event => this.onActionMenuClick(event, webHook)}>
                              <Icon>more_vert</Icon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            </Hidden>

            {pages > 1 &&
              <Pagination count={pages}
                          color="primary"
                          onChange={this.onPageChange} />
            }
          </div>
          }

          {(!loading && ReduxUtil.hasData(webHooks) && webHooks.data.length === 0) &&
          <div className={classes.p2}>
            <NoResultsCard message={IntlFormatter.formatMessage(intl, 'no_webhooks_found')}>
              <div>
                {(!hasInitialResults) &&
                <div className={classes.mt1}>
                  <AddButton color="primary"
                          variant="contained"
                          onClick={this.onAddWebHookClick}>
                    <FormattedMessage id="add_webhook" />
                  </AddButton>
                </div>
                }
              </div>
            </NoResultsCard>
          </div>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    webHooks: state.webHooks
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(WebHooksPage)));
