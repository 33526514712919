import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from "lodash";
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {setConfigured} from "./app";

export function getDesign():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getDesign({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const design = state.design && state.design.data ? _.cloneDeep(state.design.data) : null;
      dispatch(setData(types.SET_DESIGN, ReduxAsyncOperation.FETCHING, design));
      apiRequest.then(response => {
        dispatch(setData(types.SET_DESIGN, ReduxAsyncOperation.IDLE, response.data));
        dispatch(setConfigured(response.data.configured));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_DESIGN, ReduxAsyncOperation.IDLE, design));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}
