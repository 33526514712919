import React from "react";
import {Button, ButtonProps, Icon} from "@material-ui/core";
import {Link} from 'react-router-dom';

type Props = {to?:string} & Omit<ButtonProps, 'startIcon'|'component'>

const AddButton = (props:Props) => {
  const {to} = props;
  // @ts-ignore
  return <Button component={to ? Link : undefined}
                 to={to}
                 startIcon={<Icon>add_circle</Icon>}
                 {...props} />
};

export default AddButton;