import React, {ChangeEvent, Component} from 'react';

import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {
  IconButton,
  Icon,
  withStyles,
  InputAdornment,
  Button,
  Drawer,
  Typography,
  Grid, Badge, Hidden, WithStyles
} from '@material-ui/core';
import IntlFormatter from "../intl";
import styles from "../theme/jss/components/searchBarStyles";
import _ from 'lodash';
import {ValidatorForm} from 'react-material-ui-form-validator';
import TextField from "./TextField";

type DefaultProps = {
  sortColumn: string,
  searchInputDelay: number
}

type Props = {
  onSearchTextChanged: (value:string) => void,
  onSearchTextRemove: () => void,
  fullWidth?: boolean,
  filterDrawerTitle?: string,
  filterCount?: number
} & Partial<DefaultProps>
  & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  searchText: string,
  childrenSmVisible: boolean,
  filterDrawerOpen: boolean
}

class SearchBar extends Component<Props, State> {
  static defaultProps:DefaultProps = {
    sortColumn: 'select',
    searchInputDelay: 200
  };

  searchTimeout:NodeJS.Timeout;

  constructor(props:Props) {
    super(props);
    this.state = {
      searchText: '',
      childrenSmVisible: false,
      filterDrawerOpen: false
    };
  }

  componentWillUnmount = () => {
    clearTimeout(this.searchTimeout);
  };

  onSearchTextChanged = (event:ChangeEvent<{value:string}>) => {
    const searchText = _.clone(event.currentTarget.value);
    this.setState({ searchText });
    this.searchTimeout = setTimeout(() => {
      if(this.props.onSearchTextChanged && this.state.searchText === searchText) {
        this.props.onSearchTextChanged(searchText);
      }
    }, this.props.searchInputDelay);
  };

  onSearchTextRemove = () => {
    this.setState({ searchText: '' });
    if(this.props.onSearchTextRemove) {
      this.props.onSearchTextRemove();
    }
  };

  onToggleFilterDrawer = () => {
    this.setState({ filterDrawerOpen: !this.state.filterDrawerOpen });
  };

  render() {

    const { intl, classes, children, fullWidth, filterDrawerTitle, filterCount } = this.props;
    const { searchText, filterDrawerOpen } = this.state;

    return (
      <div>
        <ValidatorForm onSubmit={() => {}}>
          <div className={classes.container}>
            <div className={classes.searchFieldContainer}>
              <TextField
                name="searchField"
                placeholder={IntlFormatter.formatMessage(intl, 'search')}
                className={classes.searchFieldInput}
                value={searchText}
                fullWidth={fullWidth}
                onChange={this.onSearchTextChanged}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    searchText !== '' ?
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={this.onSearchTextRemove}>
                          <Icon fontSize="small">close</Icon>
                        </IconButton>
                      </InputAdornment>
                      : null
                  )
                }}
              />
            </div>

            {children &&
              <div>
                <Hidden smUp>
                  <Badge badgeContent={filterCount} color="primary">
                    <IconButton onClick={this.onToggleFilterDrawer}>
                      <Icon>filter_list</Icon>
                    </IconButton>
                  </Badge>
                </Hidden>
                <Hidden xsDown>
                  <Badge badgeContent={filterCount} color="primary">
                    <Button startIcon={<Icon>filter_list</Icon>}
                            onClick={this.onToggleFilterDrawer}>
                      <FormattedMessage id="filter" />
                    </Button>
                  </Badge>
                </Hidden>
              </div>
            }
          </div>
          <Drawer anchor="right"
                  open={filterDrawerOpen}
                  className={classes.filterDrawer}
                  variant="temporary"
                  ModalProps={{
                    BackdropProps: {
                      invisible: true
                    }
                  }}
                  elevation={1}
                  onClose={this.onToggleFilterDrawer}>
            <header className={classes.filterHeader}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <IconButton onClick={this.onToggleFilterDrawer}>
                    <Icon fontSize="small">arrow_forward_ios</Icon>
                  </IconButton>
                </Grid>
                <Grid>
                  <Typography variant="h5">
                    {!filterDrawerTitle &&
                      <FormattedMessage id="filter" />
                    }
                    {filterDrawerTitle}
                  </Typography>
                </Grid>
              </Grid>
            </header>
            <div className={classes.filterContent}>
              {children}
            </div>
            <footer className={classes.filterFooter}>
              <Button variant="contained" color="primary" fullWidth={true} onClick={this.onToggleFilterDrawer}>
                <FormattedMessage id="done" />
              </Button>
            </footer>
          </Drawer>
        </ValidatorForm>

      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(SearchBar));
