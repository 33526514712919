import React from 'react';
import {
  withStyles,
  Typography,
  Link, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import pageStyles from "../../theme/jss/layouts/pageStyles";
import {PairedList, Well} from "../index";
import IntlFormatter from "../../intl";
import Item from "../Item";
import {Link as RouterLink} from "react-router-dom";
import {TextUtil} from "../../utils";
import {LoanApplication} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

const LoanInfoSummary = (props:Props) => {

  const {intl, classes, loanApplication} = props;

  let items = [];
  if(loanApplication.loan) {
    items = [
      {key: IntlFormatter.formatMessage(intl, 'loan_purpose'), value: IntlFormatter.formatMessage(intl, loanApplication.loan.loanPurpose ? loanApplication.loan.loanPurpose : 'na') },
      {key: IntlFormatter.formatMessage(intl, 'loan_type'), value: IntlFormatter.formatMessage(intl, loanApplication.loan.loanType ? `loan_types_${loanApplication.loan.loanType}`: 'na') },
      {key: IntlFormatter.formatMessage(intl, 'loan_term'), value: loanApplication.loan.term ? IntlFormatter.formatMessage(intl, 'num_years', { years: loanApplication.loan.term / 12}) : IntlFormatter.formatMessage(intl, 'na') },
      {key: IntlFormatter.formatMessage(intl, 'loan_amount'), value: TextUtil.getMoneyText(loanApplication.loan.loanAmount, intl) }
    ];

    if(loanApplication.loan.downPayment) {
      items.push( {key: IntlFormatter.formatMessage(intl, 'down_payment'), value: TextUtil.getMoneyText(loanApplication.loan.downPayment, intl) });
    }

    if(loanApplication.loan.loanPurpose === 'refinance' && loanApplication.loan.refinance) {
      const refinanceItems = [
        {key: IntlFormatter.formatMessage(intl, 'refinance_purpose'), value: IntlFormatter.formatMessage(intl, loanApplication.loan.refinance.purpose ? `refinance_purpose_${loanApplication.loan.refinance.purpose}`: 'na') },
        {key: IntlFormatter.formatMessage(intl, 'year_acquired'), value: TextUtil.getValueText(loanApplication.loan.refinance.yearAcquired, intl) },
        {key: IntlFormatter.formatMessage(intl, 'original_cost'), value: TextUtil.getMoneyText(loanApplication.loan.refinance.originalCost, intl) },
        {key: IntlFormatter.formatMessage(intl, 'existing_liens'), value: TextUtil.getMoneyText(loanApplication.loan.refinance.existingLiens, intl) },
      ];

      if(loanApplication.loan.refinance.valueOfLot) {
        refinanceItems.push({key: IntlFormatter.formatMessage(intl, 'value_of_land'), value: TextUtil.getMoneyText(loanApplication.loan.refinance.valueOfLot, intl) });
      }

      if(loanApplication.loan.refinance.costOfImprovements) {
        refinanceItems.push({key: IntlFormatter.formatMessage(intl, 'value_of_home'), value:  TextUtil.getMoneyText(loanApplication.loan.refinance.costOfImprovements, intl) });
      }

      items = [...items, ...refinanceItems];
    }

    if(loanApplication.realtor) {
      return (
        <div style={{ width: '100%'}}>
          <div className={classes.mb2}>
            <PairedList items={items} />
          </div>
          <Item label={IntlFormatter.formatMessage(intl, 'realtor')} icon="person">
            <Typography variant="body2">
              {loanApplication.realtor.firstName} {loanApplication.realtor.lastName}
            </Typography>
            {loanApplication.realtor.company &&
            <Typography variant="body2">
              {loanApplication.realtor.company}
            </Typography>
            }
            {loanApplication.realtor.email &&
            <Typography variant="body2">
              {loanApplication.realtor.email}
            </Typography>
            }
            {loanApplication.realtor.phoneNumber &&
            <Typography variant="body2">
              {loanApplication.realtor.phoneNumber}
            </Typography>
            }
          </Item>
        </div>
      );
    }

    return <PairedList items={items} />;
  }

  return (
    <Well color="warn">
      <Typography variant="body1">
        <FormattedMessage id="no_loan_info_found" />.&nbsp;
        {!loanApplication.completed &&
        <Link component={RouterLink} to="/apply/loan">
          <FormattedMessage id="add_loan_info"/>
        </Link>
        }
      </Typography>
    </Well>
  );
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanInfoSummary));