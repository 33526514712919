import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    '& .MuiTypography-body1': {
      fontSize: '16px',
      lineHeight: '1.2em'
    }
  },
  content: {
    marginLeft: theme.spacing(2)
  },
  children: {
    margin: `${theme.spacing(2)}px 0`
  }
});

export default styles;
