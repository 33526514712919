import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  dropzone: {
    marginTop: theme.spacing(2),
  },
  imgPreview: {
    maxWidth: 150,
    maxHeight: 150,
    objectFit: 'cover',
  },
  label: {
    color: theme.palette.neutral.main,
    fontWeight: 'bold'
  }
});

export default styles;
