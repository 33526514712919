import React, {Component} from 'react';
import {
  WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/textCalloutBoxStyles';
import clsx from "clsx";

type DefaultProps = {
  color: 'default' |'info' |'infoAlt' |'primary' |'primaryAlt' |'secondary' |'secondaryAlt' |'success' |'successAlt' |'warn' |'warnAlt' |'danger' |'dangerAlt'
}

type Props = {
  className?: string
} & Partial<DefaultProps>
  & WithStyles<typeof styles>

class TextCalloutBox extends Component<Props> {
  static defaultProps:DefaultProps = {
    color: 'default',
  };

  render() {
    const { classes, color, children, className } = this.props;

    return (
      <div className={clsx(classes.root, classes[color!], className)}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TextCalloutBox);
