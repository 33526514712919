import {WithStyles, withStyles} from "@material-ui/core";
import React from "react";
import {PairedList, TextMask} from "../index";
import {DateUtil, TextUtil} from "../../utils";
import IntlFormatter from "../../intl";
import styles from "../../theme/jss/layouts/pageStyles";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Borrower2009} from "@jerseydev/orca-loans";

type Props = {
  borrower: Borrower2009
} & WithStyles<typeof styles>
  & WrappedComponentProps

function BorrowerPersonalInfoSummaryRev1(props:Props) {
  const { intl, borrower } = props;

  const getBorrowerFullName = () => {
    let fullName = '';
    if(borrower.prefix) {
      fullName += `${IntlFormatter.formatMessage(props.intl, `prefixes_${borrower.prefix}`)} `;
    }

    fullName += borrower.firstName;

    if(borrower.middleName) {
      fullName += ` ${borrower.middleName}`;
    }

    fullName += ` ${borrower.lastName}`;

    if(borrower.suffix) {
      fullName += ` ${IntlFormatter.formatMessage(props.intl, `suffixes_${borrower.suffix}`)} `;
    }

    return fullName;
  };

  const getBorrowerDisplayListItems = () => {

    const borrowerName = getBorrowerFullName();

    const items = [
      {key: IntlFormatter.formatMessage(intl, 'name'), value: borrowerName},
      {key: IntlFormatter.formatMessage(intl, 'email'), value: TextUtil.render(borrower.email, IntlFormatter.formatMessage(intl, 'na'))},
      {key: IntlFormatter.formatMessage(intl, 'ssn'), value: borrower.ssn ? <TextMask>{TextUtil.formatSSN(borrower.ssn, false)}</TextMask> : IntlFormatter.formatMessage(intl, 'na')},
      {key: IntlFormatter.formatMessage(intl, 'birth_date'), value: borrower.birthDate ? DateUtil.formatUtcDate(borrower.birthDate) : IntlFormatter.formatMessage(intl, 'na')},
      {key: IntlFormatter.formatMessage(intl, 'phone_number'), value: borrower.phoneNumbers && borrower.phoneNumbers.length > 0 ? borrower.phoneNumbers[0].number : IntlFormatter.formatMessage(intl, 'na') },
      {key: IntlFormatter.formatMessage(intl, 'years_of_school'), value: TextUtil.render(borrower.yearsOfSchool, IntlFormatter.formatMessage(intl, 'na')) },
      {key: IntlFormatter.formatMessage(intl, 'marital_status'), value: IntlFormatter.formatMessage(intl, borrower.maritalStatus ? `marital_statuses_${borrower.maritalStatus}` : 'na')},
      {key: IntlFormatter.formatMessage(intl, 'military_veteran'), value: TextUtil.isNullOrUndefined(borrower.militaryVeteran) ? IntlFormatter.formatMessage(intl, 'na') : IntlFormatter.formatMessage(intl, borrower.militaryVeteran ? 'yes' : 'no' ) },
      {key: IntlFormatter.formatMessage(intl, 'dependents'), value: IntlFormatter.formatMessage(intl, borrower.dependentAges && borrower.dependentAges.length === 0 ? 'no' : 'yes') },
    ];

    if(borrower.dependentAges && borrower.dependentAges.length > 0) {
      items.push({key: IntlFormatter.formatMessage(intl, 'dependent_ages'), value: borrower.dependentAges.join(', ')});
    }

    return items;
  };

  return (
    <PairedList items={getBorrowerDisplayListItems()} />
  );
}

export default withStyles(styles)(injectIntl(BorrowerPersonalInfoSummaryRev1));