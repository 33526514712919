import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, Redirect, RouteComponentProps} from 'react-router-dom';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {ReduxUtil, LoanUtil, ErrorUtil, SettingsUtil} from "../../../utils";
import {
  Button,
  Grid,
  withStyles, Divider, Icon, Hidden, Tooltip, Fab, WithStyles,
} from "@material-ui/core";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {
  ColorButton,
  PageTitle,
  Well,
  LoanSummary,
  LoanStartedWelcome,
  AddButton
} from "../../../components";
import qs from "query-string";
import {ReduxState} from "../../../data/initialState";
import {
  ActionProps,
  ErrorState, ReduxAccount,
  ReduxLoanApplications,
  ReduxLoanSettings,
  ReduxUser
} from "../../../types";
import {LoanApplication2009, LoanApplication} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  account: ReduxAccount,
  user: ReduxUser,
  loanApplications: ReduxLoanApplications,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  pageLoaded: boolean,
  loading: boolean,
  loanCompletedMessage: boolean,
  errors: ErrorState[],
  redirectTo?: string
}

class AccountDashboardPage extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      loanCompletedMessage: false,
      errors: []
    };
  }

  componentDidMount = () => {
    const params = qs.parse(this.props.location.search);
    if(params.action && params.action === 'loanCompleted') {
      this.setState({ loanCompletedMessage: true });
    }
    this.props.routeProps.getLoanApplications({ orderBy: 'created', orderByDirection: 'desc'}).send().then(() => {
      this.setState({ pageLoaded: true });
    }).catch(err => {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(err) });
    });
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'dashboard');
  };

  renderTitleBar = () => {
    const {intl, loanApplications} = this.props;

    return (
      <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item>
          <PageTitle title={this.getPageTitle()} icon="dashboard" />
        </Grid>
        {ReduxUtil.hasData(loanApplications) && loanApplications.data.length > 0 &&
        <Grid item>
          <Hidden mdUp>
            <Tooltip title={IntlFormatter.formatMessage(intl, 'start_a_new_loan')}>
              <Fab color="primary"
                   size="small"
                   component={Link}
                   to="/apply">
                <Icon>add</Icon>
              </Fab>
            </Tooltip>
          </Hidden>
          <Hidden smDown>
            <AddButton variant="contained"
                       color="primary"
                       to="/apply">
              <FormattedMessage id="start_a_new_loan"/>
            </AddButton>
          </Hidden>
        </Grid>
        }
      </Grid>
    )
  };

  onGetStartedClick = async () => {
    this.props.mixpanel.track("Loan application started");
    this.setState({redirectTo: '/apply/personal'});
  };

  render() {
    const { intl, loanApplications, user, classes, account, settings, mixpanel } = this.props;
    const { pageLoaded, loading, loanCompletedMessage, redirectTo } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   pageLoaded={pageLoaded}
                   loading={loading}>
        <div>
          {pageLoaded &&
            <div className={classes.content}>
              {loanCompletedMessage &&
                <div className={classes.mb3}>
                  <Well color="success">
                    {settings.data.loanCompletedMessage
                      ? SettingsUtil.formatLoanMessage(settings.data.loanCompletedMessage, {...user.data, company: account.data.name})
                      : IntlFormatter.formatMessage(intl, 'loan_completed_message')}
                  </Well>
                </div>
              }

              {ReduxUtil.hasData(loanApplications) && loanApplications.data.length > 0 &&
                <div>
                  {loanApplications.data.map((loanApplication:LoanApplication2009|LoanApplication, i:number) => {
                    return (
                      <div key={loanApplication._id} className={classes.mb4}>
                        <LoanSummary loanApplication={loanApplication}
                                     actions={
                                      <div className={classes.center}>
                                        {(!loanApplication.completed && loanApplication.__t !== 'Rev1') &&
                                          <ColorButton to={LoanUtil.getEditUrl(loanApplication)}
                                                       size="large"
                                                       color="success"
                                                       startIcon={<Icon>alarm</Icon>}>
                                            <FormattedMessage id="complete_application" />
                                          </ColorButton>
                                        }
                                        {(loanApplication.completed || loanApplication.__t === 'Rev1') &&
                                          <Button color="primary"
                                                  variant="contained"
                                                  component={Link}
                                                  startIcon={<Icon>article</Icon>}
                                                  size="large"
                                                  to={`/loan-applications/detail/${loanApplication._id}`}>
                                            <FormattedMessage id="view_loan" />
                                          </Button>
                                        }
                                      </div>
                                    } />
                        {(i < loanApplications.data.length - 1) &&
                          <div className={classes.mv6}>
                            <Divider />
                          </div>
                        }
                      </div>
                    )
                  })}
                </div>
              }

              {(!loading && loanApplications.data.length === 0) &&
                <LoanStartedWelcome loanSettings={settings.data}
                                    user={user.data}
                                    account={account.data}
                                    mixpanel={mixpanel} />
              }

            </div>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    account: state.account,
    user: state.user,
    loanApplications: state.loanApplications,
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(AccountDashboardPage)));
