import React, {ChangeEvent} from 'react';
import {
  Button,
  Divider,
  Grid,
  Icon,
  Card,
  withStyles,
  CardHeader,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox, WithStyles
} from '@material-ui/core';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {LoanApplicationControls, AssetsIntegration} from "../components";
import {LoanApplicationPage} from "../layouts";
import {ErrorUtil, IntegrationUtil, LoanRev2Util, LoanUtil, ReduxUtil, SocketUtil} from "../../../utils";
import BaseLoanApplicationPage from "./BaseLoanApplicationPage";
import _ from 'lodash';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {
  RadioGroup,
  ErrorList,
  BorrowerAssetReportSection,
  Alert
} from "../../../components";
import {Form} from '../../../forms';
import clsx from "clsx";
import {AccountAssetsList, OtherAssetsList, LiabilitiesList, OtherLiabilitiesList} from "../components";
import {ReduxState} from "../../../data/initialState";
import {ActionProps, ErrorState, ReduxIntegrations, ReduxLoanApplication, ReduxLoanSettings} from "../../../types";
import {Mixpanel} from "mixpanel-browser";
import {
  LoanApplication,
  Borrower,
  PlaidReportRequest,
  LoanApplicationUpdateRequest
} from "@jerseydev/orca-loans";
import {AxiosPromise} from "axios";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings,
  integrations: ReduxIntegrations
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  loading: boolean,
  redirectTo?: string,
  actionMenuEl: Element|null,
  refreshAvailable: boolean,
  errors: ErrorState[],
  selectedBorrower: Borrower|null,
  isStepCompleted?: boolean
}

class AssetsLiabilitiesPage extends BaseLoanApplicationPage<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      actionMenuEl: null,
      refreshAvailable: false,
      errors: [],
      selectedBorrower: null
    };
  }

  static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: Readonly<State>){
    if(nextProps.loanApplication && nextProps.loanApplication.data && prevState.isStepCompleted === undefined){
      return {isStepCompleted: LoanUtil.isAssetsAndLiabilitiesCompleted(nextProps.loanApplication.data)};
    }
    return null;
  };

  componentDidMount = () => {
    SocketUtil.socket.on('loanApplication.save', this.onLoanUpdated);
  };

  componentWillUnmount = () => {
    const {mixpanel, loanApplication} = this.props;
    if(loanApplication.data && !this.state.isStepCompleted && LoanUtil.isAssetsAndLiabilitiesCompleted(loanApplication.data)) {
      mixpanel.track("Loan application step completed", {step: 'assets_liabilities'});
    }

    SocketUtil.socket.off('loanApplication.save', this.onLoanUpdated);
  };

  onLoanUpdated = async (data:LoanApplication) => {
    this.setState({ loading: true });
    await this.props.routeProps.getLoanApplication(data._id).send();
    this.setState({ loading: false });
  };

  onConnectToAccountClick = (borrower:Borrower) => {
    this.setState({ selectedBorrower: borrower });
  };

  onConnectToAccountSuccess = async (data:PlaidReportRequest) => {
    this.setState({ loading: true });
    try {
      const { loanApplication } = this.props;
      const { selectedBorrower } = this.state;
      if(selectedBorrower) {
        await this.props.routeProps.addLoanApplicationAssetReport(loanApplication.data._id, selectedBorrower._id, data).send();
      }

      this.setState({ loading: false, selectedBorrower: null });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onConnectToAccountClose = () => {
    this.setState({ loading: false, selectedBorrower: null });
  };

  setJointAssetsAndLiabilities = async (event:ChangeEvent<HTMLInputElement>) => {
    try {
      this.setState({ loading: true });
      const loanApplication = _.cloneDeep(this.props.loanApplication.data);
      loanApplication.jointAssetsAndLiabilities = event.target.value === '1';
      const requestData:LoanApplicationUpdateRequest = {
        jointAssetsAndLiabilities: loanApplication.jointAssetsAndLiabilities
      };
      if(loanApplication.jointAssetsAndLiabilities && loanApplication.borrowers && loanApplication.borrowers.length > 1) {
        // move assets and liabilities before updating for co-borrower
        const borrowerIndex = loanApplication.borrowers.findIndex(b => b.primaryBorrower);
        const coBorrowerIndex = loanApplication.borrowers.findIndex(b => !b.primaryBorrower);
        if(coBorrowerIndex > -1) {
          if(loanApplication.borrowers[coBorrowerIndex].accountAssets && loanApplication.borrowers![coBorrowerIndex].accountAssets!.length > 0) {
            loanApplication.borrowers[borrowerIndex].accountAssets = [...loanApplication.borrowers![borrowerIndex].accountAssets!, ...loanApplication.borrowers![coBorrowerIndex].accountAssets!];
            loanApplication.borrowers[coBorrowerIndex].accountAssets = [];
          }

          if(loanApplication.borrowers[coBorrowerIndex].liabilities && loanApplication.borrowers![coBorrowerIndex].liabilities!.length > 0) {
            loanApplication.borrowers[borrowerIndex].liabilities = [...loanApplication.borrowers![borrowerIndex].liabilities!, ...loanApplication.borrowers![coBorrowerIndex].liabilities!];
            loanApplication.borrowers[coBorrowerIndex].liabilities = [];
          }
        }

        requestData.borrowers = LoanRev2Util.borrowersToUpdateRequest(loanApplication.borrowers);
      }
      await this.props.routeProps.updateLoanApplication(loanApplication._id, requestData).send();
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onNextClick = () => {
    this.setState({ redirectTo: this.nextMenuItem.to });
  };

  getBorrowers = ():Borrower[] => {
    const { loanApplication } = this.props;
    let borrowers:Borrower[] = loanApplication.data.borrowers ? loanApplication.data.borrowers : [];

    if(borrowers.length > 1 && loanApplication.data.jointAssetsAndLiabilities) {
      borrowers = [borrowers[0]];
    }

    return borrowers;
  };

  onDeclineAssetsLiabilitiesChange = async (event:ChangeEvent<HTMLInputElement>, borrower:Borrower) => {
    try {
      const checked = event.target.checked;
      this.setState({ loading: true });
      const {loanApplication} = this.props;
      const borrowerRequestData = LoanRev2Util.borrowerToUpdateRequest(borrower);
      borrowerRequestData.accountAssets = checked ? null : [];
      borrowerRequestData.otherAssets = checked ? null : [];
      borrowerRequestData.liabilities = checked ? null : [];
      borrowerRequestData.otherLiabilities = checked ? null : [];
      const requestData:LoanApplicationUpdateRequest = {
        borrowers: [borrowerRequestData]
      };

      const requests:AxiosPromise[] = [
        this.props.routeProps.updateLoanApplication(loanApplication.data._id, requestData, {merge: 'append'}).send(),
      ];

      if(borrower.assetReports && borrower.assetReports.length > 0) {
        borrower.assetReports.forEach(assetReport => {
          requests.push(
            this.props.routeProps.deleteLoanApplicationAssetReport(loanApplication.data._id, borrower._id, assetReport._id).send()
          )
        })
      }

      await Promise.all(requests);

      this.setState({ loading: false });
    } catch (e) {
      this.setState({
        loading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  onRefreshLoan = async () => {
    try {
      this.setState({ loading: true });
      await this.props.routeProps.getLoanApplication(this.props.loanApplication.data._id).send();
      this.setState({ loading: false, refreshAvailable: false });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onAssetReportUpdateAvailable = () => {
    this.setState({ refreshAvailable: true });
  };

  render() {

    const { intl, classes, loanApplication, integrations } = this.props;
    const { loading, redirectTo, errors, refreshAvailable } = this.state;

    const hasAssetIntegration = IntegrationUtil.hasIntegration(integrations.data, 'assets');

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <LoanApplicationPage menuId="assets_liabilities"
                           loading={loading}>

        <div className={classes.content}>
          <ErrorList errors={errors} className={classes.mv2} />

          {refreshAvailable &&
            <div className={classes.mb2}>
              <Alert severity="info" action={
                <Button variant="outlined" color="inherit" onClick={this.onRefreshLoan}>
                  <FormattedMessage id="refresh" />
                </Button>
              }>
                <FormattedMessage id="account_report_available_text" />
              </Alert>
            </div>
          }

          {(ReduxUtil.hasData(loanApplication) && loanApplication.data.borrowers && loanApplication.data.borrowers.length > 1) &&
            <div className={classes.mb2}>
              <Form onSubmit={() => {}}>
                <RadioGroup name="jointAssetsAndLiabilities"
                            itemValueProp="value"
                            label={IntlFormatter.formatMessage(intl, 'assets_completed')}
                            value={loanApplication.data.jointAssetsAndLiabilities !== null ? loanApplication.data.jointAssetsAndLiabilities ? '1' : '0' : loanApplication.data.jointAssetsAndLiabilities}
                            onChange={this.setJointAssetsAndLiabilities}
                            items={[
                              { label: IntlFormatter.formatMessage(intl, 'jointly'), value: '1' },
                              { label: IntlFormatter.formatMessage(intl, 'individually'), value: '0' }
                            ]}
                            row />
              </Form>
            </div>
          }

          {ReduxUtil.hasData(loanApplication) &&
          <Grid container spacing={4}>
            {this.getBorrowers().map((borrower, i) => {
              return (
                <Grid key={i} item xs={12} sm={12} md={6}>
                  <Card className={classes.card}>
                    <CardHeader title={`${loanApplication.data.jointAssetsAndLiabilities && loanApplication.data.borrowers!.length > 1 ? `${borrower.firstName} ${borrower.lastName}/${loanApplication.data.borrowers![1].firstName} ${loanApplication.data.borrowers![1].lastName}` : `${borrower.firstName} ${borrower.lastName}` }`}
                                subheader={IntlFormatter.formatMessage(intl, borrower.primaryBorrower ? 'borrower' : 'co_borrower')}
                                avatar={<Icon>{borrower.primaryBorrower ? 'person' : 'person_outline'}</Icon>}/>
                    <Divider />

                    {(borrower.accountAssets || borrower.liabilities) &&
                      <CardContent>
                        {borrower.accountAssets &&
                          <div className={classes.mb2}>
                            <div>

                              <div className={classes.mb2}>
                                {hasAssetIntegration && (borrower.assetReports && borrower.assetReports.length === 0) &&
                                  <div className={clsx(classes.pv2, classes.textCenter)}>
                                    <AssetsIntegration borrower={borrower}
                                                       onClick={() => this.onConnectToAccountClick(borrower)}
                                                       onSuccess={this.onConnectToAccountSuccess}
                                                       onExit={this.onConnectToAccountClose} />
                                  </div>
                                }
                                {(borrower.assetReports && borrower.assetReports.length > 0) &&
                                  <BorrowerAssetReportSection loanApplication={loanApplication.data}
                                                              borrower={borrower}>
                                    {hasAssetIntegration &&
                                      <div className={clsx(classes.pt1, classes.mb2, classes.textCenter)}>
                                        <AssetsIntegration borrower={borrower}
                                                           onClick={() => this.onConnectToAccountClick(borrower)}
                                                           onSuccess={this.onConnectToAccountSuccess}
                                                           onExit={this.onConnectToAccountClose} />
                                      </div>
                                    }
                                  </BorrowerAssetReportSection>
                                }
                              </div>

                              {hasAssetIntegration &&
                                <div className={clsx(classes.mv2, classes.textCenter)}>
                                  <Typography variant="body1">
                                    <FormattedMessage id="or_add_manually_below" />
                                  </Typography>
                                </div>
                              }
                            </div>

                            <div className={classes.mb2}>
                              <AccountAssetsList loanApplication={loanApplication.data}
                                                 borrower={borrower} />
                            </div>

                            <div className={classes.mb2}>
                              <OtherAssetsList loanApplication={loanApplication.data}
                                               borrower={borrower} />
                            </div>
                          </div>
                        }

                        {borrower.liabilities &&
                          <div className={classes.mb2}>
                            <LiabilitiesList loanApplication={loanApplication.data}
                                             borrower={borrower} />
                          </div>
                        }

                        {borrower.otherLiabilities &&
                          <div className={classes.mb2}>
                            <OtherLiabilitiesList loanApplication={loanApplication.data}
                                                  borrower={borrower} />
                          </div>
                        }
                      </CardContent>
                    }

                    <Divider />
                    <div className={classes.p2}>
                      <FormControlLabel label={IntlFormatter.formatMessage(intl, 'no_assets_liabilities')} control={
                        <Checkbox checked={!borrower.accountAssets && !borrower.liabilities}
                                  onChange={(event) => this.onDeclineAssetsLiabilitiesChange(event, borrower)}
                                  color="primary" />
                      }
                      />
                    </div>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
          }
        </div>
        <div className={classes.content}>
          <LoanApplicationControls prev={this.previousMenuItem}
                                   next={this.nextMenuItem}
                                   onNextClick={this.onNextClick} />
        </div>

      </LoanApplicationPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    settings: state.loanSettings,
    integrations: state.integrations
  };
};


export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(AssetsLiabilitiesPage)));
