import React, {Component} from 'react';
import {AccountPage} from '../../../layouts';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {BranchForm} from '../forms';
import {Redirect} from "react-router-dom";
import {WithStyles, withStyles} from "@material-ui/core";
import styles from "../../../theme/jss/layouts/pageStyles";
import {PageTitle} from "../../../components";
import {SnackbarState} from "../../../types";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel
} & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  loading: boolean,
  redirectTo?: string,
  snackbar?: SnackbarState
}

class BranchAddPage extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'add_branch');
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="person" />
    )
  };

  onCancel = () => {
    this.setState({ redirectTo: '/admin/branches' });
  };

  onSubmit = () => {
    this.setState({
      redirectTo: '/admin/branches',
      snackbar: {
        open: true,
        variant: 'success',
        message: IntlFormatter.formatMessage(this.props.intl, 'branch_added')
      }
    });
  };

  render() {

    const { intl, classes, mixpanel } = this.props;
    const { loading, redirectTo, snackbar } = this.state;


    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo, state: { snackbar } }}  />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   loading={loading}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                    {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                    {title: IntlFormatter.formatMessage(intl, 'branches'), to: '/admin/branches' },
                    {title: IntlFormatter.formatMessage(intl, 'add_branch') }
                  ]}>
        <div className={classes.content}>
          <BranchForm mixpanel={mixpanel}
                      onSubmit={this.onSubmit}
                      onCancel={this.onCancel} />
        </div>
      </AccountPage>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(BranchAddPage));
