import {ReduxActionData} from "../types";
import {ReduxState} from "../data/initialState";

export default function createReducer(initialState:Partial<ReduxState>|null|boolean, handlers:any) {
  return function reducer(state = initialState, action:ReduxActionData) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  }
}