import React, {Component} from 'react';
import {Icon, Snackbar as MUISnackbar, SnackbarContent, SnackbarProps, SnackbarOrigin, WithStyles, withStyles} from '@material-ui/core';
import styles from "../theme/jss/components/snackbarStyles";

type DefaultProps = {
  variant: 'default'|'danger'|'info'|'success'|'warning',
  autoHideDuration: number,
  anchorOrigin: SnackbarOrigin
}

type Props = {
  message: string
} & SnackbarProps
  & Partial<DefaultProps>
  & WithStyles<typeof styles>

class Snackbar extends Component<Props> {
  static defaultProps:DefaultProps = {
    variant: 'default',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right'
    },
    autoHideDuration: 5000
  };

  styleMap = {
    default: {
      className: 'default',
      icon: null
    },
    info: {
      icon: 'info'
    },
    danger: {
      icon: 'error'
    },
    success: {
      icon: 'check_circle'
    },
    warning: {
      icon: 'warning'
    }
  };

  render() {
    const { classes, variant, message, action, ...rest } = this.props;

    return (
      <div>
        <MUISnackbar {...rest}>
          <SnackbarContent action={action} className={classes[variant!]} message={
            <div className={classes.content}>
              {this.styleMap[variant!].icon &&
                <Icon color="inherit" className={classes.icon}>{this.styleMap[variant!].icon}</Icon>
              }
              {message}
            </div>
          } />
        </MUISnackbar>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Snackbar);
