import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  started: {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  closed: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  processing: {
    color: theme.palette.yield.contrastText,
    backgroundColor: theme.palette.yield.main,
    '&:hover': {
      backgroundColor: theme.palette.yield.dark,
    },
  },
  abandoned: {
    color: theme.palette.danger.contrastText,
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
    },
  },
  lost: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  }
});

export default styles;
