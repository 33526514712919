import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  IconButton,
  Icon,
  Button,
  Typography,
  Link,
  InputLabel,
  withStyles,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Select as RNSelect,
  FormControlLabel,
  Switch,
  WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {
  AutoComplete,
  ErrorList,
  PhoneNumberInput,
  SubmitButton,
  Dropzone,
  TextField,
  Select,
  InlineCard,
  NoLicensesAlert,
  Section,
  Fab,
  FormSection,
  LoanOfficerBorrowerSignupLink,
  DeleteDialog,
  Alert,
  UnsavedChangesPrompt
} from "../../../components";
import moment from "moment-timezone";
import _ from 'lodash';
import {connect} from "react-redux";
import {
  addLoanOfficer,
  updateLoanOfficer,
  addLoanOfficerPicture, removeLoanOfficerPicture
} from "../../../actions/loanOfficers";
import {AclUtil, ErrorUtil, IntegrationUtil, ObjectUtil, PhoneNumberUtil, ReduxUtil, UserUtil} from "../../../utils";
import Api from "../../../lib/Api";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {getRoles} from "../../../actions/roles";
import {EnableDeletedUserForm} from "../../../forms";
import {
  DialogState, FileWithPreview,
  LabelValuePairType,
  PhoneNumberState,
  ReduxApp,
  ReduxRoles,
  ReduxUser, ActionProps,
  ErrorState, ReduxIntegrations
} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {
  Branch,
  LoanOfficer,
  LoanOfficerRequest,
  LoanOfficerUpdateRequest, LOSLoanOfficer, ManagedFile,
  PictureRequest,
  Role,
  User
} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";
import {LoanOfficerLosImportAlert} from "../components";

type Props = {
  mixpanel: Mixpanel,
  loanOfficer?: LoanOfficer,
  onSubmit: (data:AxiosResponse<LoanOfficer>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  integrations: ReduxIntegrations,
  user: ReduxUser,
  roles: ReduxRoles,
  addLoanOfficer: ActionProps["addLoanOfficer"],
  updateLoanOfficer: ActionProps["updateLoanOfficer"],
  getRoles: ActionProps["getRoles"],
  addLoanOfficerPicture: ActionProps["addLoanOfficerPicture"],
  removeLoanOfficerPicture: ActionProps["removeLoanOfficerPicture"],
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  firstName: string,
  lastName: string,
  email: string,
  description: string,
  title: string,
  nmlsId: string,
  branch?: Branch|null,
  phoneNumbers: PhoneNumberState[],
  stateLicenses: string[],
  timezone: string,
  roles: Role[],
  picture?: FileWithPreview|ManagedFile|null,
  pricing?: {
    businessChannelId: string,
    originatorId: string
  },
  los?: {
    losId: string
  },
  notifications: {
    loanAssigned: boolean,
    loanCompleted: boolean,
    loanAbandoned: boolean,
    loanDocumentAdded: boolean
  },
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  existingUser: User|null,
  pictureDeleteDialog: DialogState,
  formPristine: boolean
}

class LoanOfficerForm extends BaseForm<Props, State> {
  availableTimezones:string[] = [];
  phoneNumberTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      firstName: '',
      lastName: '',
      email: '',
      description: '',
      title: '',
      nmlsId: '',
      branch: null,
      phoneNumbers: [],
      stateLicenses: [],
      timezone: moment.tz.guess(),
      roles: [],
      notifications: {
        loanAssigned: true,
        loanCompleted: true,
        loanAbandoned: true,
        loanDocumentAdded: true
      }
    };

    if(props.loanOfficer) {
      const {firstName, lastName, email, description, title, nmlsId, branch, phoneNumbers, stateLicenses, picture, user, los, pricing, notifications} = props.loanOfficer;
      form = {
        firstName,
        lastName,
        email,
        description: description ? description : '',
        title: title ? title : '',
        nmlsId,
        branch,
        phoneNumbers,
        stateLicenses,
        picture,
        los,
        pricing,
        timezone: user.timezone,
        roles:  _.cloneDeep(props.loanOfficer.user.roles),
        notifications
      };
      if(!form.notifications) {
        form.notifications = {
          loanAssigned: true,
          loanCompleted: true,
          loanAbandoned: true,
          loanDocumentAdded: true
        };
      }
    }

    if(!form.title) {
      form.title = '';
    }

    if(!form.description) {
      form.description = '';
    }
    //form = _.merge(form, this.testData);

    this.state = {
      loading: false,
      form,
      errors: [],
      existingUser: null,
      pictureDeleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      formPristine: true
    };

    if(IntegrationUtil.hasIntegration(props.integrations.data, 'pricing') && !this.state.form.pricing) {
      if(props.integrations.data.pricing!.provider === 'optimalBlue') {
        this.state.form.pricing = {
          businessChannelId: '',
          originatorId: ''
        };
      }
    }

    if(IntegrationUtil.hasIntegration(props.integrations.data, 'los') && !this.state.form.los) {
      if(props.integrations.data.los!.provider === 'encompass') {
        this.state.form.los = {
          losId: ''
        };
      }
    }

    this.availableTimezones = UserUtil.getAmericanTimezones();

    props.app.data.enums.phoneNumberTypes.forEach(type => {
      this.phoneNumberTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `phone_number_types_${type}`) })
    });

    if(AclUtil.hasRole(props.user.data, 'ROLE_BRANCH_MANAGER')) {
      this.state.form.branch = _.cloneDeep(props.user.data.additionalProps!.branch);
    }
  }

  componentDidMount = async () => {
    try {
      if(!ReduxUtil.hasData(this.props.roles)) {
        await this.props.getRoles().send();
      }
      this.setState({ loading: false });
    } catch (e) {
      this.setState({
        errors: ErrorUtil.formatErrors(e),
        loading: false
      });
    }
  }

  componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>) => {
    if(this.state.formPristine && !ObjectUtil.isEqual(prevState.form, this.state.form)) {
      this.setState({formPristine:false});
    }
  }

  componentWillUnmount = () => {
    if(this.state.form.picture && "preview" in this.state.form.picture) {
      window.URL.revokeObjectURL(this.state.form.picture.preview);
    }
  };

  addPhoneNumber = (event:MouseEvent) => {
    event.preventDefault();
    const form = _.cloneDeep(this.state.form);
    form.phoneNumbers.push({ type: null, number: '' });
    this.setState({ form });
  };

  removePhoneNumber = (index:number) => {
    const form = _.cloneDeep(this.state.form);
    form.phoneNumbers.splice(index, 1);
    this.setState({ form });
  };

  setPhoneNumberType = (index:number, type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.phoneNumbers[index].type = type.value;

    this.setState({ form });
  };

  setBranch = (branch:Branch) => {
    const form = _.cloneDeep(this.state.form);
    form.branch = branch;
    this.setState({ form });
  };

  removeBranch = () => {
    const form = _.cloneDeep(this.state.form);
    form.branch = null;
    this.setState({ form });
  };

  searchBranches = async (searchText:string) => {
    const result = await Api.searchBranches({ searchText });
    return result.data;
  };

  onAddPicture = (file:FileWithPreview) => {
    const form = _.cloneDeep(this.state.form);
    form.picture = file;
    this.setState({ form });
  };

  onRemovePicture = () => {
    const pictureDeleteDialog = _.clone(this.state.pictureDeleteDialog);
    pictureDeleteDialog.open = true;
    this.setState({ pictureDeleteDialog });
  };

  onStateLicenseChange = (event:ChangeEvent<{value:string[]}>) => {
    const form = _.cloneDeep(this.state.form);
    form.stateLicenses = _.clone(event.target.value);
    this.setState({ form });
  };

  renderStateLicenses = (selected:string[]) => {
    let abbreviations:string[] = [];

    selected.forEach(s => {
      abbreviations.push(s.toUpperCase());
    });

    return abbreviations.join(',');
  };

  onRoleToggle = (role:Role) => {
    const form = _.cloneDeep(this.state.form);
    const index = form.roles.findIndex(r => r._id === role._id);
    if(index === -1) {
      form.roles.push(_.cloneDeep(role));
    } else {
      form.roles.splice(index, 1);
    }

    this.setState({ form });
  }

  onLoanOfficerSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    this.setState({ loading: true, errors: [] });
    const form = _.cloneDeep(this.state.form);
    let formValid = false;
    if(this.props.loanOfficer) {
      formValid = true;
    } else {
      try {
        const emailResult = await Api.getUserByEmail(form.email, { deleted: 'all' });
        this.setState({ loading: false, existingUser: _.cloneDeep(emailResult.data) });
      } catch (e) {
        if(e.response && e.response.status === 404) {
          formValid = true;
        } else {
          this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
        }
      }
    }

    if(formValid) {
      try {
        const {
          firstName, lastName, email, timezone, roles, description,
          stateLicenses, phoneNumbers, branch, nmlsId, title, picture,
          los, pricing, notifications
        } = this.state.form;
        let loanOfficerResult:AxiosResponse<LoanOfficer>;
        if(this.props.loanOfficer && this.props.loanOfficer._id) {
          let requestData:LoanOfficerUpdateRequest = {
            firstName,
            lastName,
            email,
            timezone,
            roles: _.map(roles, '_id'),
            description,
            stateLicenses,
            phoneNumbers: PhoneNumberUtil.stateToUpdateRequest(phoneNumbers),
            branch: branch!._id,
            nmlsId,
            title,
            los,
            pricing,
            notifications
          };
          loanOfficerResult = await this.props.updateLoanOfficer(this.props.loanOfficer._id, requestData).send();
          this.props.mixpanel.track("Loan officer updated");
        } else {
          let requestData:LoanOfficerRequest = {
            firstName,
            lastName,
            email,
            timezone,
            roles: _.map(roles, '_id'),
            description,
            stateLicenses,
            phoneNumbers: PhoneNumberUtil.stateToCreateRequest(phoneNumbers),
            branch: branch!._id,
            nmlsId,
            title,
            los,
            pricing,
            notifications
          };
          loanOfficerResult = await this.props.addLoanOfficer(requestData).send();
          this.props.mixpanel.track("Loan officer added");
        }

        if(picture instanceof File) {
          const pictureResult = await this.props.addLoanOfficerPicture(loanOfficerResult.data._id, {picture, width: 800, resizeMode: "max"}).send();
          loanOfficerResult.data.picture = pictureResult.data;
        }

        this.setState({ loading: false }, () => {
          this.props.onSubmit(_.cloneDeep(loanOfficerResult));
        });
      } catch (e) {
        this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
      }
    }
  };

  renderPricingFields = () => {
    const { intl, classes, integrations } = this.props;
    const { form } = this.state;

    let fields = null;
    // @todo setup better way to add pricing abstraction
    if(IntegrationUtil.hasIntegration(integrations.data, 'pricing') && integrations.data.pricing!.provider === 'optimalBlue') {
      fields = (
        <FormSection title={IntlFormatter.formatMessage(intl, integrations.data.pricing!.provider)}
                     className={classes.mv2}>
          <div className={classes.mt1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField name="optimalBlueOriginatorId"
                           label={IntlFormatter.formatMessage(intl, 'originator_id')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.pricing.originatorId')}
                           value={form.pricing!.originatorId}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />

              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField name="optimalBlueBusinessChannelId"
                           label={IntlFormatter.formatMessage(intl, 'business_channel_id')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.pricing.businessChannelId')}
                           value={form.pricing!.businessChannelId}
                           fullWidth={true} />
              </Grid>
            </Grid>
          </div>
        </FormSection>
      );
    }

    return fields;
  };

  importLosLoanOfficerData = (losLoanOfficer:LOSLoanOfficer) => {
    const {id, firstName, lastName, email, description, title, nmlsId, stateLicenses, phoneNumbers} = losLoanOfficer;
    const form = _.cloneDeep(this.state.form);
    form.firstName = firstName || '';
    form.lastName = lastName || '';
    form.description = description || '';
    form.title = title || '';
    form.nmlsId = nmlsId || '';
    form.stateLicenses = stateLicenses || [];
    form.phoneNumbers = phoneNumbers || [];
    form.email = email;
    form.los = {
      losId: id
    };
    this.setState({form});
  };

  renderLosFields = () => {
    const { intl, classes, integrations } = this.props;
    const { form } = this.state;

    let fields = null;
    if(integrations.data && integrations.data.los) {
      fields = (
        <FormSection title={IntlFormatter.formatMessage(intl, integrations.data.los!.provider)}
                     className={classes.mv2}>
          <div className={classes.mt1}>
            <TextField name="losId"
                       label={IntlFormatter.formatMessage(intl, `${integrations.data.los!.provider}_id`)}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.los.losId')}
                       value={form.los!.losId}
                       fullWidth={true}
                       validators={['required']}
                       errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
          </div>
        </FormSection>
      );
    }

    return fields;
  };

  onDeletedLoanOfficerFormSubmit = (data:AxiosResponse<LoanOfficer>) => {
    this.props.onSubmit(_.cloneDeep(data));
  };

  onDeletedLoanOfficerFormCancel = () => {
    this.setState({ existingUser: null });
  };

  onLoanOfficerExistsAlertClose = () => {
    this.setState({ existingUser: null });
  };

  onDeletePictureCancel = () => {
    const pictureDeleteDialog = {
      open: false,
      loading: false,
      errors: []
    };
    this.setState({pictureDeleteDialog});
  };

  onDeletePictureConfirm = async () => {
    let pictureDeleteDialog = _.clone(this.state.pictureDeleteDialog);
    pictureDeleteDialog.loading = true;
    this.setState({pictureDeleteDialog});

    try {
      if(this.props.loanOfficer) {
        await this.props.removeLoanOfficerPicture(this.props.loanOfficer._id);
      }
      pictureDeleteDialog = {
        open: false,
        loading: false,
        errors: []
      };
      let form = _.cloneDeep(this.state.form);
      form.picture = null;
      this.setState({pictureDeleteDialog, form});
    } catch (e) {
      pictureDeleteDialog.loading = false;
      pictureDeleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({pictureDeleteDialog});
    }
  };

  render() {

    const { intl, classes, app, loanOfficer, roles, user, mixpanel } = this.props;
    const { form, loading, errors, existingUser, pictureDeleteDialog, formPristine } = this.state;

    return (
      <div>
        <UnsavedChangesPrompt when={!formPristine} />

        <DeleteDialog open={pictureDeleteDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'delete_picture')}
                      item={IntlFormatter.formatMessage(intl, 'names_picture', {name: form.firstName })}
                      confirmationMessage={loanOfficer && loanOfficer.user._id === user.data._id ? IntlFormatter.formatMessage(intl, 'are_you_sure_delete_your_picture') : IntlFormatter.formatMessage(intl, 'are_you_sure_delete_name_picture', {name: form.firstName })}
                      loading={pictureDeleteDialog.loading}
                      errors={pictureDeleteDialog.errors}
                      onCancel={this.onDeletePictureCancel}
                      onSubmit={this.onDeletePictureConfirm} />

        {!loanOfficer &&
          <NoLicensesAlert className={classes.mb2} />
        }
        {existingUser &&
        <div className={classes.mb2}>
          {UserUtil.hasRole(existingUser, 'ROLE_LOAN_OFFICER') &&
            <div>
                {existingUser.deleted &&
                  <EnableDeletedUserForm user={existingUser}
                                         mixpanel={mixpanel}
                                         onSubmit={this.onDeletedLoanOfficerFormSubmit}
                                         onCancel={this.onDeletedLoanOfficerFormCancel} />
                }
                {!existingUser.deleted &&
                <Alert severity="danger" action={
                  <IconButton onClick={this.onLoanOfficerExistsAlertClose} size="small">
                    <Icon>cancel</Icon>
                  </IconButton>
                }>
                  <FormattedMessage id="user_exists_select_another_email" values={{ user: existingUser.email }} />
                </Alert>
                }
            </div>
          }
          {!UserUtil.hasRole(existingUser, 'ROLE_LOAN_OFFICER') &&
            <Alert severity="warning">
              <FormattedMessage id="user_not_loan_officer" values={{ user: existingUser.email }} />
            </Alert>
          }
        </div>
        }

        {(form.los && !form.los.losId) &&
          <LoanOfficerLosImportAlert email={form.email}
                                     onSubmit={this.importLosLoanOfficerData} />
        }

        <Form onSubmit={this.onLoanOfficerSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } }/>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name="firstName"
                               label={IntlFormatter.formatMessage(intl, 'first_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.firstName')}
                               value={form.firstName}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name="lastName"
                               label={IntlFormatter.formatMessage(intl, 'last_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.lastName')}
                               value={form.lastName}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name="email"
                               label={IntlFormatter.formatMessage(intl, 'email')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.email')}
                               value={form.email}
                               fullWidth={true}
                               disabled={!!(loanOfficer && loanOfficer._id)}
                               inputProps={{
                                 autoCapitalize: 'none',
                               }}
                               validators={['required', 'isEmail']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name="title"
                               label={IntlFormatter.formatMessage(intl, 'title')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.title')}
                               value={form.title}
                               fullWidth={true} />

                  </Grid>
                </Grid>
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextField name="nmlsId"
                               label={IntlFormatter.formatMessage(intl, 'nmls_id')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.nmlsId')}
                               value={form.nmlsId}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AutoComplete value={form.branch}
                                  getOptionLabel={(item:Branch) => {
                                    return item.name;
                                  }}
                                  onChange={this.setBranch}
                                  onTextChange={this.searchBranches}
                                  label={IntlFormatter.formatMessage(intl, 'branch')}
                                  getOptionSelected={(option:Branch, value:Branch) => option._id === value._id}
                                  openOnFocus={true}
                                  debounce={500}
                                  validators={['required']}
                                  errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}
                                  placeholder={IntlFormatter.formatMessage(intl, 'search')}/>

                  </Grid>
                </Grid>
              </div>
              <div className={classes.mv2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="state-licenses-label">{IntlFormatter.formatMessage(intl, 'state_licenses')}</InputLabel>
                  <RNSelect
                    labelId="state-licenses-label"
                    id="state-licenses-select"
                    multiple
                    value={form.stateLicenses}
                    renderValue={this.renderStateLicenses}
                    onChange={this.onStateLicenseChange}
                    label={IntlFormatter.formatMessage(intl, 'state_licenses')}
                  >
                    {app.data.enums.states.map(state => (
                      <MenuItem key={state} value={state}>
                        <Checkbox checked={form.stateLicenses.indexOf(state) > -1} />
                        <ListItemText primary={IntlFormatter.formatMessage(intl, `state_${state}`)} />
                      </MenuItem>
                    ))}
                  </RNSelect>
                </FormControl>
              </div>


              <div className={classes.mb2}>
                <TextField name="description"
                           label={IntlFormatter.formatMessage(intl, 'about')}
                           value={form.description}
                           multiline={true}
                           rows={3}
                           fullWidth={true}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.description')} />
              </div>

              {this.renderLosFields()}
              {this.renderPricingFields()}

              <FormSection title={IntlFormatter.formatMessage(intl, 'notifications')}>
                <div>
                  <FormControlLabel label={IntlFormatter.formatMessage(intl, 'notification_loan_assigned')}
                                    control={
                                      <Switch checked={form.notifications.loanAssigned}
                                              onChange={() => this.onCheckboxChanged('form.notifications.loanAssigned')} />
                                    } />
                </div>
                <div>
                  <FormControlLabel label={IntlFormatter.formatMessage(intl, 'notification_loan_completed')}
                                    control={
                                      <Switch checked={form.notifications.loanCompleted}
                                              onChange={() => this.onCheckboxChanged('form.notifications.loanCompleted')} />
                                    } />
                </div>
                <div>
                  <FormControlLabel label={IntlFormatter.formatMessage(intl, 'notification_loan_abandoned')}
                                    control={
                                      <Switch checked={form.notifications.loanAbandoned}
                                              onChange={() => this.onCheckboxChanged('form.notifications.loanAbandoned')} />
                                    } />
                </div>
                <div>
                  <FormControlLabel label={IntlFormatter.formatMessage(intl, 'notification_loan_document_added')}
                                    control={
                                      <Switch checked={form.notifications.loanDocumentAdded}
                                              onChange={() => this.onCheckboxChanged('form.notifications.loanDocumentAdded')} />
                                    } />
                </div>
              </FormSection>

              {loanOfficer &&
                <div className={classes.mt3}>
                  <LoanOfficerBorrowerSignupLink email={loanOfficer.email} />
                </div>
              }
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <aside className={classes.rightSidebar}>
                {UserUtil.hasAnyRole(user.data, ['ROLE_SUPER_ADMIN', 'ROLE_OWNER']) &&
                  <Section title={IntlFormatter.formatMessage(intl, 'additional_roles')} className={classes.mb4}>
                    {ReduxUtil.hasData(roles) && roles.data.length > 0 &&
                    <div>
                      {roles.data.filter(r => r.key !== 'ROLE_LOAN_OFFICER' && r.key !== 'ROLE_MEMBER').map((role, i) => {
                        if(role.key === 'ROLE_BRANCH_MANAGER' && form.branch && form.branch.manager && (loanOfficer && loanOfficer.user && form.branch.manager._id !== loanOfficer.user._id)) { // cant assign if branch already has a manager
                          return null;
                        }
                        return (
                          <div key={i} className={classes.mb1}>
                            <FormControlLabel label={role.name}
                                              control={
                                                <Checkbox checked={!!form.roles.find(r => r._id === role._id)}
                                                          onChange={() => this.onRoleToggle(role)} />
                                              }/>
                          </div>
                        )
                      })}
                    </div>
                    }
                  </Section>
                }
                <Section title={IntlFormatter.formatMessage(intl, 'phone_numbers')} className={classes.mb4} actions={
                  <Fab color="primary"
                       size="small"
                       onClick={this.addPhoneNumber}
                       flat
                       rounded>
                    <Icon>add</Icon>
                  </Fab>
                }>
                  <div>
                    {form.phoneNumbers.length === 0 &&
                      <Typography variant="body1">
                        <Link href="#" onClick={this.addPhoneNumber}><FormattedMessage id="add_phone_number" /></Link>
                      </Typography>
                    }

                    {form.phoneNumbers.map((phoneNumber, i) => {
                      return (
                        <div key={i} className={classes.mb2}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Select name={`phoneNumberType${i}`}
                                      options={this.phoneNumberTypes}
                                      onChange={(data:LabelValuePairType) => this.setPhoneNumberType(i, data)}
                                      value={phoneNumber.type ? this.phoneNumberTypes.find(t => t.value === phoneNumber.type) : ''}
                                      label={IntlFormatter.formatMessage(intl, 'type')}
                                      validators={['required']}
                                      errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                            </Grid>
                            <Grid item xs={4}>
                              <PhoneNumberInput name={`phoneNumber${i}`}
                                                label={IntlFormatter.formatMessage(intl, 'number')}
                                                value={form.phoneNumbers[i].number}
                                                fullWidth={true}
                                                onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.phoneNumbers[${i}].number`)}
                                                validators={['required']}
                                                errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField name={`phoneNumbers[${i}].ext`}
                                         label={IntlFormatter.formatMessage(intl, 'ext')}
                                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.phoneNumbers[${i}].ext`)}
                                         value={form.phoneNumbers[i].ext}
                                         fullWidth={true} />
                            </Grid>
                            <Grid item>
                              <IconButton onClick={() => this.removePhoneNumber(i)} size="small">
                                <Icon>cancel</Icon>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    })}
                  </div>
                </Section>
                <div>
                  {(form.picture && "url" in form.picture) &&
                  <InlineCard title={IntlFormatter.formatMessage(intl, 'photo')}
                              action={
                                <IconButton onClick={this.onRemovePicture} size="small">
                                  <Icon>cancel</Icon>
                                </IconButton>
                              }>
                    <img src={form.picture.url} alt={`${form.firstName} ${form.lastName}`} className={classes.thumbnail} />
                  </InlineCard>
                  }
                  {(!form.picture || (form.picture && !("_id" in form.picture))) &&
                  <div className={classes.mv2}>
                    <Dropzone label={IntlFormatter.formatMessage(intl, 'photo')}
                              accept="image/jpeg, image/png"
                              imageDimensions={{ width: 150, height: 150 }}
                              onAdd={this.onAddPicture}
                              onRemove={this.onRemovePicture} />
                  </div>
                  }
                </div>
              </aside>
            </Grid>
          </Grid>
          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}
                              disabled={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    settings: state.loanSettings,
    integrations: state.integrations,
    user: state.user,
    roles: state.roles
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addLoanOfficer(data:LoanOfficerRequest) {
    return dispatch(addLoanOfficer(data));
  },
  updateLoanOfficer(id:string, data:LoanOfficerUpdateRequest) {
    return dispatch(updateLoanOfficer(id, data));
  },
  getRoles() {
    return dispatch(getRoles());
  },
  addLoanOfficerPicture(id:string, picture:PictureRequest) {
    return dispatch(addLoanOfficerPicture(id, picture));
  },
  removeLoanOfficerPicture(id:string) {
    return dispatch(removeLoanOfficerPicture(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoanOfficerForm)));
