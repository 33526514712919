import React, {Component} from 'react';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  Grid, Typography, WithStyles,
  withStyles,
} from "@material-ui/core";
import styles from "../../../theme/jss/pages/calculatorStyles";
import {Card, PageTitle} from "../../../components";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar, faMoneyBillAlt, faClipboard} from '@fortawesome/free-regular-svg-icons'
import {faCalendarPlus, faCopy, faMoneyCheck} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel
} & WrappedComponentProps
  & WithStyles<typeof styles>

class CalculatorsPage extends Component<Props> {
  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'mortgage_calculators');
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="calculate" />
    )
  };

  render() {

    const { intl, classes } = this.props;


    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}>

        <div className={clsx(classes.content, classes.root)}>
          <div className={classes.mb2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Card title={IntlFormatter.formatMessage(intl, 'monthly_payments')}
                      link={{ to: '/calculators/monthly-payment', label: IntlFormatter.formatMessage(intl, 'calculate') }}
                      icon={<FontAwesomeIcon icon={faCalendar} />}>
                  <Typography variant="body1">
                    <FormattedMessage id="monthly_payment_calculator_text" />
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Card title={IntlFormatter.formatMessage(intl, 'what_can_i_afford')}
                      link={{ to: '/calculators/affordability', label: IntlFormatter.formatMessage(intl, 'calculate') }}
                      icon={<FontAwesomeIcon icon={faMoneyBillAlt} />}>
                  <Typography variant="body1">
                    <FormattedMessage id="what_can_i_afford_calculator_text" />
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </div>

          <div className={classes.mb2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Card title={IntlFormatter.formatMessage(intl, 'refinance')}
                      link={{ to: '/calculators/refinance', label: IntlFormatter.formatMessage(intl, 'calculate') }}
                      icon={<FontAwesomeIcon icon={faClipboard} />}>
                  <Typography variant="body1">
                    <FormattedMessage id="refinance_calculator_text" />
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Card title={IntlFormatter.formatMessage(intl, 'income_to_qualify')}
                      link={{ to: '/calculators/income-to-qualify', label: IntlFormatter.formatMessage(intl, 'calculate') }}
                      icon={<FontAwesomeIcon icon={faMoneyCheck} />}>
                  <Typography variant="body1">
                    <FormattedMessage id="income_to_qualify_calculator_text" />
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Card title={IntlFormatter.formatMessage(intl, 'loan_comparison')}
                    link={{ to: '/calculators/loan-comparison', label: IntlFormatter.formatMessage(intl, 'calculate') }}
                    icon={<FontAwesomeIcon icon={faCopy} />}>
                <Typography variant="body1">
                  <FormattedMessage id="loan_comparison_calculator_text" />
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Card title={IntlFormatter.formatMessage(intl, 'pay_more')}
                    link={{ to: '/calculators/pay-more', label: IntlFormatter.formatMessage(intl, 'calculate') }}
                    icon={<FontAwesomeIcon icon={faCalendarPlus} />}>
                <Typography variant="body1">
                  <FormattedMessage id="pay_more_calculator_text" />
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </div>
      </AccountPage>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(CalculatorsPage));
