import _ from 'lodash';
import {Permission, User} from "@jerseydev/orca-loans";

export default class AclUtil {

  static isOwner = (user:User) => {
    return !!user.roles.find(r => r.key === 'ROLE_OWNER' || r.key === 'ROLE_SUPER_ADMIN');
  };

  static isGranted = (user:User, permissions:string[]|string) => {
    const isSuperAdmin = user.roles.find(r => r.key === 'ROLE_SUPER_ADMIN');
    if(isSuperAdmin) {
      return true;
    }

    let rolePermissions:Permission[] = [];

    user.roles.forEach(role => {
      if(role.permissions.length > 0) {
        rolePermissions = [...rolePermissions, ...role.permissions];
      }
    });

    const userPermissionKeys = _.map(rolePermissions, 'key');

    let isGranted = false;
    if(Array.isArray(permissions)) {
      for(let i=0;i<permissions.length;i++) {
        if(userPermissionKeys.includes(permissions[i])) {
          isGranted = true;
          break;
        }
      }
    } else {
      isGranted = userPermissionKeys.includes(permissions);
    }

    return isGranted;
  };

  static hasRole = (user:User, roleKey:string) => {
    const roleKeys = _.map(user.roles, 'key');
    return roleKeys.includes(roleKey);
  };

  static hasAnyRole = (user:User, roleKeys:string[]) => {
    let hasRole = false;
    const userRoleKeys = _.map(user.roles, 'key');

    for(let i=0;i<roleKeys.length;i++) {
      if(userRoleKeys.includes(roleKeys[i])) {
        hasRole = true;
        break;
      }
    }

    return hasRole;
  };
}
