import React, {Component} from 'react';
import {Typography, Link, Breadcrumbs as MUIBreadcrumbs, withStyles, Icon, WithStyles} from '@material-ui/core';
import {Link as RouterLink} from "react-router-dom";
import styles from '../theme/jss/components/breadcrumbStyles';
import {Breadcrumb} from "../types";

type Props = {
  breadcrumbs: Breadcrumb[]
} & WithStyles<typeof styles>

class Breadcrumbs extends Component<Props> {
  render() {
    const { classes, breadcrumbs } = this.props;

    return (
      <div className={classes.root}>
        <MUIBreadcrumbs color="inherit" aria-label="breadcrumb">
          {breadcrumbs.map((breadcrumb, i) => {
            if(breadcrumb.to) {
              return (
                <Link color="inherit" key={i} to={breadcrumb.to} component={RouterLink}>
                  <div className={classes.item}>
                    {breadcrumb.icon &&
                      <Icon color={breadcrumb.color ? breadcrumb.color : 'inherit'} className={classes.icon}>{breadcrumb.icon}</Icon>
                    }
                    {breadcrumb.title &&
                      <Typography key={i} color={breadcrumb.color ? breadcrumb.color : 'inherit'} className={classes.label}>{breadcrumb.title}</Typography>
                    }
                  </div>
                </Link>
              )
            } else {
              return (
                <div key={i} className={classes.item}>
                  {breadcrumb.icon &&
                    <Icon color="inherit" className={classes.icon}>{breadcrumb.icon}</Icon>
                  }
                  {breadcrumb.title &&
                    <Typography color="inherit" className={classes.label}>{breadcrumb.title}</Typography>
                  }
                </div>
              )
            }
          })}
        </MUIBreadcrumbs>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Breadcrumbs);
