import React, {Component} from 'react';
import {Typography, Grid, CardContent, CardActions, Card, withStyles, Avatar, WithStyles} from '@material-ui/core';
import styles from "../theme/jss/components/tileStyles";
import {Link as RouterLink} from 'react-router-dom';

type DefaultProps = {
  color: 'default'|'info'|'primary'|'secondary'|'success'|'warning'|'danger'
}

type Props = {
  title: string,
  icon: React.ReactNode,
  content?: any,
  primaryText?: string,
  actions?: React.ReactNode,
  to?: string
} & Partial<DefaultProps>
  & WithStyles<typeof styles>


class Tile extends Component<Props> {
  static defaultProps:DefaultProps = {
    color: 'default'
  };

  render() {
    const { classes, title, icon, primaryText, content, actions, color, to } = this.props;

    return (
      <Card className={classes.root + ' ' + classes[color!]}>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className={classes.title}
                          color={color === Tile.defaultProps.color ? 'textSecondary' : 'inherit'}
                          gutterBottom
                          variant="body2"
                          // @ts-ignore
                          component={to ? (props => <RouterLink {...props} to={to} />) : undefined}>
                {title}
              </Typography>
              {primaryText &&
                <Typography variant="h3" color="inherit">{primaryText}</Typography>
              }
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                {icon}
              </Avatar>
            </Grid>
          </Grid>
          <div className={classes.content}>
            {content}
          </div>

        </CardContent>
        {actions &&
          <CardActions>
            {actions}
          </CardActions>

        }
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Tile);
