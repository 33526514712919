import {defaultRadius, hexToRgb} from "../index";
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    borderRadius: defaultRadius,
    fontSize: 16,
    padding: theme.spacing(2),
    color: theme.palette.neutral.main
  },
  default: {
    backgroundColor: theme.palette.neutral.main,
    color: theme.palette.neutral.contrastText,
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText
  },
  infoAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.info.main) + ", .08)",
    color: theme.palette.info.dark,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  primaryAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ", .08)",
    color: theme.palette.primary.dark,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  secondaryAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.secondary.main) + ", .08)",
    color: theme.palette.secondary.dark,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  successAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.success.main) + ", .08)",
    color: theme.palette.success.dark,
  },
  warn: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  warnAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.warning.main) + ", .08)",
    color: theme.palette.warning.dark,
  },
  danger: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrastText,
  },
  dangerAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.danger.main) + ", .08)",
    color: theme.palette.danger.dark,
  },
});

export default styles;
