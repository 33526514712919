import React, {ChangeEvent, MouseEvent} from 'react';
import Form, {ValidatorForm} from './Form';
import {Grid, Button, withStyles, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../intl';
import BaseForm from "./BaseForm";
import {ErrorList, Select, SubmitButton, TextField, CreditCardNumberInput} from "../components";
import {connect} from "react-redux";
import {addCreditCard, updateCreditCard} from "../actions/creditCards";
import {ErrorUtil} from '../utils';
import pageStyles from "../theme/jss/layouts/pageStyles";
import {CreditCard, CreditCardRequest, CreditCardUpdateRequest} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";
import {ActionResponse, ErrorState, LabelNumberType} from "../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  creditCard?: CreditCard|null,
  onCancel?: () => void,
  onSubmit?: (data:AxiosResponse<CreditCard>) => void,
  addCreditCard: (data:CreditCardRequest) => ActionResponse,
  updateCreditCard: (id:string, data:CreditCardUpdateRequest) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  name: string,
  number?: string,
  exp_month: string,
  exp_year: string,
  cvc?: string,
  address_line1: string,
  address_city: string,
  address_state: string,
  address_zip: string,
  address_country: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}


class CreditCardForm extends BaseForm<Props, State> {
  currentYear:number;
  currentMonth:number;

  months:LabelNumberType[] = [
    { value: 1, label: '01' },
    { value: 2, label: '02' },
    { value: 3, label: '03' },
    { value: 4, label: '04' },
    { value: 5, label: '05' },
    { value: 6, label: '06' },
    { value: 7, label: '07' },
    { value: 8, label: '08' },
    { value: 9, label: '09' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' }
  ];
  years:LabelNumberType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      name: '',
      number: '',
      exp_month: '',
      exp_year: '',
      cvc: '',
      address_line1: '',
      address_city: '',
      address_state: '',
      address_zip: '',
      address_country: 'USA'
    };

    if(props.creditCard) {
      const {name, exp_month, exp_year, address_line1, address_city, address_state, address_zip, address_country} = props.creditCard;
      form = {
        name,
        exp_month: exp_month.toString(),
        exp_year: exp_year.toString(),
        address_line1: address_line1 ? address_line1 : '',
        address_city: address_city ? address_city : '',
        address_state: address_state ? address_state : '',
        address_zip,
        address_country
      }
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    const d = new Date();
    this.currentYear = d.getFullYear();
    this.currentMonth = d.getMonth() + 1;

    for(let i=0;i<10;i++) {
      const year = this.currentYear + i;
      this.years.push({ value: year, label: year.toString() });
    }
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule('isValidExpirationMonth', (month) => {
      const { form } = this.state;
      if(form.exp_year === this.currentYear.toString() && month.value !== '') {
        return month.value >= this.currentMonth;
      }
      return true;
    });
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const {creditCard} = this.props;
      const {name, number, cvc, exp_month, exp_year, address_line1, address_city, address_state, address_zip, address_country} = this.state.form;
      let result;
      if(creditCard && creditCard.id) {
        const request:CreditCardUpdateRequest = {
          name,
          exp_month: parseInt(exp_month),
          exp_year: parseInt(exp_year),
          address_line1,
          address_city,
          address_state,
          address_zip,
          address_country
        }
        result = await this.props.updateCreditCard(creditCard.id, request).send();
        this.props.mixpanel.track('Credit card updated');
      } else {
        const request:CreditCardRequest = {
          name,
          number: number || '',
          cvc: cvc ? parseInt(cvc) : 0,
          exp_month: parseInt(exp_month),
          exp_year: parseInt(exp_year),
          address_line1,
          address_city,
          address_state,
          address_zip,
          address_country
        }
        result = await this.props.addCreditCard(request).send();
        this.props.mixpanel.track('Credit card added');
      }

      this.setState({ loading: false });
      if(this.props.onSubmit) {
        this.props.onSubmit(result);
      }
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes, onCancel, creditCard } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] })}} />

          <div className={classes.mb2}>
            <TextField name="cardName"
                       label={IntlFormatter.formatMessage(intl, 'name_on_card')}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.name')}
                       value={form.name}
                       fullWidth={true}
                       validators={['required']}
                       errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
          </div>
          {!creditCard &&
            <div className={classes.mb2}>
              <CreditCardNumberInput name="cardNumber"
                                     label={IntlFormatter.formatMessage(intl, 'card_number')}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.number')}
                                     value={form.number}
                                     fullWidth={true}
                                     validators={['required']}
                                     errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </div>
          }
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Select name="month"
                        options={this.months}
                        onChange={(item:LabelNumberType) => this.onItemChange(item ? item.value : null, 'form.exp_month')}
                        value={form.exp_month !== '' ? this.months.find(m => m.value === parseInt(form.exp_month)) : ''}
                        validators={['required', 'isValidExpirationMonth']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required'),
                          IntlFormatter.formatMessage(intl, 'validation_expiration_date_must_be_future')
                        ]}
                        label={IntlFormatter.formatMessage(intl, 'exp_month')}
                        placeholder="MM"/>
              </Grid>
              <Grid item xs={4}>
                <Select name="year"
                        options={this.years}
                        onChange={(item:LabelNumberType) => this.onItemChange(item ? item.value : null, 'form.exp_year')}
                        value={form.exp_year !== '' ? this.years.find(y => y.value === parseInt(form.exp_year)) : ''}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required')
                        ]}
                        label={IntlFormatter.formatMessage(intl, 'exp_year')}
                        placeholder="YYYY"/>
              </Grid>
              {!creditCard &&
                <Grid item xs={4}>
                  <TextField name="cvcCode"
                             label={IntlFormatter.formatMessage(intl, 'cvc')}
                             value={form.cvc}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.cvc')}
                             type="number"
                             validators={['required', 'minStringLength:3', 'maxStringLength:4']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required'),
                               IntlFormatter.formatMessage(intl, 'validation_minlength', {length: 3}),
                               IntlFormatter.formatMessage(intl, 'validation_maxlength', {length: 4})
                             ]} />
                </Grid>
              }
            </Grid>
          </div>
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField name="street"
                           label={IntlFormatter.formatMessage(intl, 'street_address')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address_line1')}
                           value={form.address_line1}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
              </Grid>
            </Grid>
          </div>
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <TextField name="city"
                           label={IntlFormatter.formatMessage(intl, 'city')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address_city')}
                           value={form.address_city}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField name="state"
                           label={IntlFormatter.formatMessage(intl, 'state')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address_state')}
                           value={form.address_state}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField name="postalCode"
                           label={IntlFormatter.formatMessage(intl, 'postal_code')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.address_zip')}
                           value={form.address_zip}
                           fullWidth={true}
                           validators={['required', 'minStringLength:5', 'maxStringLength:5']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required'),
                             IntlFormatter.formatMessage(intl, 'validation_minlength', {length: 5}),
                             IntlFormatter.formatMessage(intl, 'validation_maxlength', {length: 5})
                           ]} />
              </Grid>
            </Grid>
          </div>

          <div className={classes.mv2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {onCancel &&
                <Grid item>
                  <Button onClick={onCancel}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addCreditCard(data:CreditCardRequest) {
    return dispatch(addCreditCard(data));
  },
  updateCreditCard(id:string, data:CreditCardUpdateRequest) {
    return dispatch(updateCreditCard(id, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(CreditCardForm)));
