import React from 'react';
import {Icon, IconButton, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {LoanApplicationDocument} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  documents: LoanApplicationDocument[],
  mixpanel: Mixpanel
}

const LoanDocumentList = (props:Props) => {
  const {documents, mixpanel} = props;

  const onDownloadDocumentClick = (loanDocument:LoanApplicationDocument) => {
    mixpanel.track("Loan document downloaded");
    window.open(loanDocument.document.url, '_blank');
  };

  if(documents.length === 0) {
    return null
  }

  return (
    <List>
      {documents.map((document, i) => {
        return (
          <ListItem key={i}>
            <ListItemIcon>
              <IconButton onClick={() => onDownloadDocumentClick(document)}>
                <Icon>save_alt</Icon>
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={document.name}
                          secondary={document.category ? document.category.name : null}/>
          </ListItem>
        )
      })}
    </List>
  );
}

export default LoanDocumentList;