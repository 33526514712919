import React, {ChangeEvent, Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {AccountPage} from '../../../layouts';
import {
  Grid,
  withStyles,
  Tabs,
  Tab,
  Icon, WithStyles
} from '@material-ui/core';
import {ReduxUtil, ErrorUtil, LocationUtil, IntegrationUtil} from "../../../utils";
import {ErrorList, LoanDetails, PageTitle, TabPanel, LoanDocumentChecklist} from "../../../components";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {LoanDocumentsForm} from "../forms";
import qs from "query-string";
import Credit from "../../loanApplications/components/Credit";
import {ReduxState} from "../../../data/initialState";
import {ActionProps, ErrorState, ReduxIntegrations, ReduxLoanApplication} from "../../../types";
import {RouteComponentProps} from "react-router-dom";
import {Mixpanel} from "mixpanel-browser";
import {LoanApplicationDocumentRequest, SecureMessage} from "@jerseydev/orca-loans";
import {PricingScenarioList} from "../../loanApplications/components";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  integrations: ReduxIntegrations
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps<{id: string}>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  documents: LoanApplicationDocumentRequest[],
  selectedTab: number,
  secureMessageDialogOpen: boolean,
  secureMessage: SecureMessage|null,
  secureMessageReplyDialogOpen: boolean,
  errors: ErrorState[]
}

type TabMap = {
  loan: number,
  documents: number,
  pricing: number,
  credit: number
}

class DetailPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      documents: [],
      selectedTab: 0,
      secureMessageDialogOpen: false,
      secureMessage: null,
      secureMessageReplyDialogOpen: false,
      errors: []
    };
  }

  tabMap:TabMap = {
    loan: 0,
    documents: 1,
    pricing: 2,
    credit: 3
  };

  componentDidMount = async () => {
    if(this.props.location.search) {
      const params = qs.parse(this.props.location.search);
      if(params.tab && this.tabMap[params.tab as keyof TabMap]) {
        this.setState({ selectedTab: this.tabMap[params.tab as keyof TabMap] });
      }
    }

    try {
      await this.props.routeProps.getLoanApplication(this.props.match.params.id).send();
      this.setState({ pageLoaded: true });
    } catch (e) {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(e)});
    }
  };

  getPageTitle = () => {
    const { intl, loanApplication } = this.props;
    if(loanApplication && loanApplication.data) {
      if(loanApplication.data.property && loanApplication.data.property.address) {
        return IntlFormatter.formatMessage(intl, 'loan_for_address', { address: LocationUtil.formatLocation(loanApplication.data.property.address) });
      } else {
        return IntlFormatter.formatMessage(intl, 'loan_application');
      }
    }

    return '';
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="person" />
    )
  };

  selectTab = (e:ChangeEvent, value:number) => {
    this.setState({ selectedTab: value });
  };

  render() {
    const { intl, loanApplication, classes, integrations, mixpanel } = this.props;
    const { pageLoaded, loading, selectedTab, errors } = this.state;

    const pricing = loanApplication.data && loanApplication.data.pricing ? loanApplication.data.pricing.filter(p => p.sharedWithBorrower) : [];

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   pageLoaded={pageLoaded}
                   loading={loading}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: this.getPageTitle() }
                   ]}>

        <ErrorList errors={errors}
                   className={classes.m2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        {ReduxUtil.hasData(loanApplication) && loanApplication.data &&
          <div>
            <Tabs value={selectedTab} onChange={this.selectTab}>
              <Tab label={IntlFormatter.formatMessage(intl, 'loan')} icon={<Icon>list_alt</Icon>} />
              <Tab label={IntlFormatter.formatMessage(intl, 'documents')} icon={<Icon>folder_open</Icon>} />
              {pricing.length > 0 &&
                <Tab label={IntlFormatter.formatMessage(intl, 'pricing')} icon={<Icon>request_quote</Icon>}/>
              }
            </Tabs>

            <TabPanel value={selectedTab} index={0}>
              <LoanDetails loanApplication={loanApplication.data} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <LoanDocumentsForm loanApplication={loanApplication.data}
                                     mixpanel={mixpanel} />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <LoanDocumentChecklist loanApplication={loanApplication.data} />
                </Grid>


              </Grid>
            </TabPanel>
            {pricing && pricing.length > 0 &&
              <TabPanel value={selectedTab} index={2}>
                <PricingScenarioList loanApplication={loanApplication.data} />
              </TabPanel>
            }

            {IntegrationUtil.hasIntegration(integrations.data, 'credit') &&
            <TabPanel value={selectedTab} index={pricing.length > 0 ? 3 : 2}>
              <Credit loanApplication={loanApplication.data} />
            </TabPanel>
            }

          </div>
        }
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    integrations: state.integrations
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(DetailPage)));
