import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  Chip,
  Divider,
  Grid,
  Icon,
  Typography,
  withStyles, Hidden, Tooltip, Fab, IconButton, WithStyles
} from "@material-ui/core";
import {AvatarGroup} from '@material-ui/lab';
import {
  ErrorList,
  NoResultsCard,
  PageTitle,
  SearchBar,
  Dialog,
  UserAvatar,
  Snackbar,
  PaginationHeader,
  Pagination,
  AddButton
} from "../../../components";
import {AclUtil, DateUtil, ErrorUtil, ReduxUtil, SearchUtil, TextUtil} from "../../../utils";
import {SecureMessageThreadForm} from "../../../forms";
import styles from "../../../theme/jss/components/secureMessagesStyles";
import {Redirect} from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import {
  ActionResponse,
  ReduxUser,
  ActionProps,
  SearchCriteriaWithPagingType,
  SnackbarState,
  ErrorState, ReduxSecureMessageThreads
} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {AxiosResponse} from "axios";
import {SecureMessage, SecureMessageThread} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  onMessageItemClick: (data:SecureMessage) => void,
  user: ReduxUser,
  secureMessageThreads: ReduxSecureMessageThreads
} & WithStyles<typeof styles>
  & WrappedComponentProps

type State = {
  pageLoaded: boolean,
  loading: boolean,
  messageDialogOpen: boolean,
  errors: ErrorState[],
  attachments: null,
  searchCriteria: SearchCriteriaWithPagingType,
  hasInitialResults: boolean,
  redirectTo?: string,
  snackbar: SnackbarState,
  secureMessage?: SecureMessage|null
}

class MessagesPage extends Component<Props, State> {

  messageSearch:ActionResponse|null;

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      messageDialogOpen: false,
      errors: [],
      attachments: null,
      searchCriteria: {
        order: {
          column: 'created',
          direction: 'desc'
        },
        searchText: '',
        limit: 10,
        skip: 0,
        totalCount: 0,
        page: 1
      },
      hasInitialResults: false,
      snackbar: {
        open: false,
        message: ''
      }
    };
  }

  componentDidMount = async () => {
    try {
      const result = await this.searchThreads();
      this.setState({ pageLoaded: true, hasInitialResults: result.data.length > 0 });
    } catch(e) {
      this.setState({ pageLoaded: true, hasInitialResults: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'messages');
  };

  renderTitleBar = () => {
    const { intl } = this.props;

    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <PageTitle title={this.getPageTitle()} icon={
            <Icon color="primary">message</Icon>
          } />
        </Grid>
        <Grid item>
          <Hidden mdUp>
            <Tooltip title={IntlFormatter.formatMessage(intl, 'compose_message')}>
              <Fab color="primary"
                   size="small"
                   onClick={this.onShowMessageDialog}>
                <Icon>add</Icon>
              </Fab>
            </Tooltip>
          </Hidden>
          <Hidden smDown>
            <AddButton variant="contained"
                       color="primary"
                       onClick={this.onShowMessageDialog}>
              <FormattedMessage id="compose_message"/>
            </AddButton>
          </Hidden>
        </Grid>
      </Grid>
    )
  };

  onSearchTextChange = (value:string) => {

    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.searchText = value;
    searchCriteria.skip = 0;
    searchCriteria.page = 1;

    this.setState({ searchCriteria }, this.filterMessages);
  };

  onSearchTextRemove = () => {

    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.searchText = '';
    searchCriteria.skip = 0;
    searchCriteria.page = 1;

    this.setState({ searchCriteria }, this.filterMessages);
  };

  filterMessages = async () => {
    try {
      await this.searchThreads();
    } catch (e) {
      this.setState({ errors: ErrorUtil.formatErrors(e) });
    }
  };

  searchThreads = async ():Promise<AxiosResponse<SecureMessage[]>> => {
    this.setState({loading: true});
    const {searchCriteria} = this.state;

    if (this.messageSearch) {
      this.messageSearch.cancel('Another request was made');
    }

    const params = SearchUtil.getParamsFromSearchCriteria(searchCriteria);
    this.messageSearch = this.props.routeProps.searchSecureMessageThreads({searchText: searchCriteria.searchText}, params);
    try {
      const result = await this.messageSearch!.send();
      const searchCriteria = _.cloneDeep(this.state.searchCriteria);
      searchCriteria.totalCount = parseInt(result.headers['total-count']);
      this.setState({loading: false, searchCriteria});
      this.messageSearch = null;
      return result;
    } catch (e) {
      this.messageSearch = null;
      this.setState({loading: false});
      throw e;
    }
  };

  onShowMessageDialog = () => {
    this.setState({ messageDialogOpen: true });
  };

  onHideMessageDialog = () => {
    this.setState({ messageDialogOpen: false, secureMessage: null });
  };

  onMessageSubmit = () => {
    this.setState({
      messageDialogOpen: false,
      hasInitialResults: true,
      snackbar: {
        open: true,
        message: IntlFormatter.formatMessage(this.props.intl, 'message_sent')
      }
    });
  };

  onPageChange = (event:MouseEvent, value:number) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.skip = (value - 1) * searchCriteria.limit;
    searchCriteria.page = value;
    this.setState({ searchCriteria }, this.filterMessages);
  };

  onSecureMessageClick = (secureMessageThread:SecureMessageThread) => {
    this.setState({ redirectTo: `/messages/${secureMessageThread._id}` });
  };

  onSnackbarClose = () => {
    this.setState({ snackbar: { open: false, message: '' } });
  };

  render() {
    const { intl, classes, secureMessageThreads, user, mixpanel } = this.props;
    const { messageDialogOpen, errors, pageLoaded, loading, searchCriteria, hasInitialResults, redirectTo, snackbar } = this.state;
    let pages = Math.ceil(searchCriteria.totalCount / searchCriteria.limit);
    if(pages === 0) {
      pages = 1;
    }

    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo }}  />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   pageLoaded={pageLoaded}
                   loading={loading}>

        <ErrorList errors={errors}
                   className={classes.m2}
                   onClose={() => { this.setState({ errors: [] }); } }/>

        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.onSnackbarClose}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />
        <Dialog open={messageDialogOpen}
                icon={<Icon>message</Icon>}
                title={IntlFormatter.formatMessage(intl, 'send_message')}
                color="primaryAlt"
                onClose={this.onHideMessageDialog}
                fullWidth={true}
                maxWidth="sm">
          <SecureMessageThreadForm mixpanel={mixpanel}
                                   onCancel={this.onHideMessageDialog}
                                   onSubmit={this.onMessageSubmit}/>
        </Dialog>


        {ReduxUtil.hasData(secureMessageThreads) &&
          <div>
            <div>
              {hasInitialResults &&
                <div className={classes.pageHeaderContainer}>
                  <SearchBar onSearchTextChanged={this.onSearchTextChange}
                             onSearchTextRemove={this.onSearchTextRemove}/>
                </div>

              }

              <div>
                <ErrorList errors={errors}
                           className={classes.m2}
                           onClose={() => {
                             this.setState({errors: []});
                           }}/>

                <div>
                  {secureMessageThreads.data.length > 0 &&
                  <div className={classes.pv2}>
                    <div className={clsx(classes.ph2, classes.pb1)}>
                      <PaginationHeader totalResults={searchCriteria.totalCount}
                                        currentPage={searchCriteria.page}
                                        totalPages={pages} />
                    </div>
                    <Divider />
                    {secureMessageThreads.data.map((secureMessageThread, i) => {
                      return (
                        <div key={i} className={classes.messageRow} onClick={() => this.onSecureMessageClick(secureMessageThread)}>
                          <div className={clsx(classes.ph2, classes.pv1)}>
                            <Grid container justifyContent="space-between">
                              <Grid item xs={12} sm={8}>
                                <Grid container>
                                  <Grid item>
                                    <AvatarGroup>
                                      {secureMessageThread.subscribers.map((user, i) => {
                                        return <UserAvatar key={i} user={user} popoverEnabled={false}/>
                                      })}
                                    </AvatarGroup>
                                  </Grid>
                                  <Grid item>
                                    <div className={classes.pl2}>
                                      <Typography variant="h5">{secureMessageThread.subject}</Typography>
                                      {secureMessageThread.summary &&
                                        <Typography variant="body2">
                                          {TextUtil.truncate(secureMessageThread.summary, 100)}
                                        </Typography>
                                      }
                                      <Typography variant="caption">
                                        {DateUtil.formatUserDate(user.data, secureMessageThread.modified)}
                                      </Typography>

                                      <Hidden smUp>
                                        <div className={clsx(classes.rowCenter, classes.mt1)}>
                                          {secureMessageThread.unread > 0 &&
                                          <div className={clsx(classes.messageBadges, classes.mr2)}>
                                            <Chip size="small"
                                                  color="secondary"
                                                  label={IntlFormatter.formatMessage(intl, 'num_unread', {num: secureMessageThread.unread })}/>
                                          </div>
                                          }
                                          {secureMessageThread.attachments && secureMessageThread.attachments.length > 0 &&
                                          <div className={classes.rowCenter}>
                                            <div className={classes.mr1}>
                                              <Icon>attachment</Icon>
                                            </div>
                                            <Typography variant="caption">
                                              <FormattedMessage id={secureMessageThread.attachments.length === 1 ? 'num_attachment' : 'num_attachments'} values={{ num: secureMessageThread.attachments.length }} />
                                            </Typography>
                                          </div>
                                          }
                                        </div>
                                      </Hidden>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Hidden xsDown>
                                  <div className={classes.rowCenterEnd}>
                                    {secureMessageThread.attachments && secureMessageThread.attachments.length > 0 &&
                                    <div className={classes.rowCenter}>
                                      <div className={classes.mr1}>
                                        <Icon>attachment</Icon>
                                      </div>
                                      <Typography variant="caption">
                                        <FormattedMessage id={secureMessageThread.attachments.length === 1 ? 'num_attachment' : 'num_attachments'} values={{ num: secureMessageThread.attachments.length }} />
                                      </Typography>
                                    </div>
                                    }
                                    {secureMessageThread.unread > 0 &&
                                    <div className={clsx(classes.messageBadges, classes.ml2)}>
                                      <Chip size="small"
                                            color="secondary"
                                            label={IntlFormatter.formatMessage(intl, 'num_unread', {num: secureMessageThread.unread })}/>
                                    </div>
                                    }
                                  </div>
                                </Hidden>
                              </Grid>
                            </Grid>
                          </div>
                          {i < secureMessageThreads.data.length - 1 &&
                          <Divider/>
                          }
                        </div>
                      )
                    })}
                  </div>
                  }
                </div>

                {pages > 1 &&
                  <Pagination count={pages}
                              color="primary"
                              onChange={this.onPageChange}/>
                }
              </div>
            </div>

            {(!loading && ReduxUtil.hasData(secureMessageThreads) && secureMessageThreads.data.length === 0) &&
            <div className={classes.p2}>
              <NoResultsCard message={IntlFormatter.formatMessage(intl, searchCriteria.searchText === '' ?  AclUtil.hasRole(user.data, 'ROLE_MEMBER') ? 'messages_page_no_messages_borrower_text' : 'messages_page_no_messages_text' : 'no_messages_found')}>
                <div>
                  {!hasInitialResults &&
                    <div className={classes.mt1}>
                      <AddButton color="primary"
                                 variant="contained"
                                 onClick={this.onShowMessageDialog}>
                        <FormattedMessage id="compose_message"/>
                      </AddButton>
                    </div>
                  }
                </div>
              </NoResultsCard>
            </div>
            }
          </div>
        }
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    secureMessageThreads: state.secureMessageThreads
  };
};

export default connect(mapStateToProps)(withStyles(styles, {withTheme:true})(injectIntl(MessagesPage)));

