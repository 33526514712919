import {PhoneNumberState} from "../types";
import {PhoneNumberCreateRequest, PhoneNumberUpdateRequest} from "@jerseydev/orca-loans";

export default class PhoneNumberUtil {
  static stateToCreateRequest = (phoneNumberStates:PhoneNumberState[]):PhoneNumberCreateRequest[] => {
    const phoneNumbers:PhoneNumberCreateRequest[] = [];
    phoneNumberStates.forEach(phoneNumberState => {
      phoneNumbers.push({
        type: phoneNumberState.type as  PhoneNumberCreateRequest["type"],
        number: phoneNumberState.number,
        ext: phoneNumberState.ext
      })
    });
    return phoneNumbers;
  };

  static stateToUpdateRequest = (phoneNumberStates:PhoneNumberState[]):PhoneNumberUpdateRequest[] => {
    const phoneNumbers:PhoneNumberUpdateRequest[] = [];
    phoneNumberStates.forEach(phoneNumberState => {
      phoneNumbers.push({
        type: phoneNumberState.type,
        number: phoneNumberState.number,
        ext: phoneNumberState.ext
      })
    });
    return phoneNumbers;
  };

  static formatPhoneNumber = (phoneNumber:string) => {
    return phoneNumber.replace(/\D/g,'')
  };
}
