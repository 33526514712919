import config from "../config";
import {AclUtil} from "./index";
import {Account, User} from "@jerseydev/orca-loans";

export default class RouteUtil {

  static isGranted = (user:User, route:string) => {

    let isGranted = false;

    for(let i=0;i<config.security.length;i++) {
      if(route.match(config.security[i].path)) {
        if((user && config.security[i].roles.includes('AUTHENTICATED_USER')) || (config.security[i].roles && AclUtil.hasAnyRole(user, config.security[i].roles))) {
          isGranted = true;
        }
        break;
      }
    }

    return isGranted;
  };

  static hasPlan = (account:Account, route:string) => {
    let hasPlan = false;
    for(let i=0;i<config.security.length;i++) {
      if(route.match(config.security[i].path)) {
        if(!config.security[i].plans || (config.security[i].plans && config.security![i].plans!.includes(account.plan))) {
          hasPlan = true;
        }
        break;
      }
    }

    return hasPlan;
  };
}
