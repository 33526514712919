import React, {Component} from 'react';
import {
  Grid,
  Icon,
  withStyles,
  IconButton,
  Typography,
  LinearProgress,
  WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import styles from "../../../theme/jss/components/loanDocumentFormStyles";
import {Dropzone, ErrorList, LoanDocumentList} from "../../../components";
import {connect} from "react-redux";
import {addLoanApplicationDocument} from "../../../actions/loanApplication";
import {ErrorUtil, ReduxUtil} from "../../../utils";
import {
  ActionProps,
  ErrorState,
  FileWithPreview,
  ReduxIntegrations,
  ReduxLoanDocumentCategories,
  ReduxToken
} from "../../../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../../../data/initialState";
import {
  LoanApplicationDocument,
  LoanApplicationDocumentRequest,
  LoanApplication2009,
  LoanApplication,
  LoanDocumentCategory
} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";
import {getLoanDocumentCategories} from "../../../actions/loanDocumentCategories";
import {LoanDocumentListItemForm} from "./index";
import _ from "lodash";

type Props = {
  mixpanel: Mixpanel,
  loanApplication: LoanApplication2009|LoanApplication,
  loanDocumentCategories: ReduxLoanDocumentCategories,
  integrations: ReduxIntegrations,
  token: ReduxToken,
  addLoanApplicationDocument: ActionProps["addLoanApplicationDocument"],
  getLoanDocumentCategories: ActionProps["getLoanDocumentCategories"]
} & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  loading: boolean,
  errors: ErrorState[],
  files: FileWithPreview[],
  deleteDialogOpen: boolean,
  deleteLoading: boolean,
  selectedDocument: LoanApplicationDocument|null
}

type LOSStatusIconMap = {
  queued: React.ReactNode,
  completed: React.ReactNode,
  failed: React.ReactNode
}

class LoanDocumentsForm extends Component<Props, State> {
  losStatusIconMap:LOSStatusIconMap;

  constructor(props:Props) {
    super(props);

    this.state = {
      files: [],
      errors: [],
      loading: true,
      deleteDialogOpen: false,
      deleteLoading: false,
      selectedDocument: null
    };

    this.losStatusIconMap = {
      queued: <Icon className={props.classes.warning}>scheduled</Icon>,
      completed: <Icon className={props.classes.success}>check_circle</Icon>,
      failed: <Icon className={props.classes.danger}>cancel</Icon>
    }
  }

  componentDidMount = async () => {
    try {
      if(!ReduxUtil.hasData(this.props.loanDocumentCategories) && ReduxUtil.isIdle(this.props.loanDocumentCategories)) {
        await this.props.getLoanDocumentCategories().send();
      }
      this.setState({loading:false});
    } catch (e) {
      this.setState({loading:false, errors:ErrorUtil.formatErrors(e)});
    }
  }

  componentWillUnmount = () => {
    if(this.state.files.length > 0) {
      this.state.files.forEach(file => {
        window.URL.revokeObjectURL(file.preview);
      });
    }
  };

  onFileDrop = async (files:FileWithPreview[]) => {
    this.setState({ errors: [], loading: true });

    for(let file of files) {
      try {
        await this.props.addLoanApplicationDocument(this.props.loanApplication._id, {name: file.name, document: file}).send();
        this.props.mixpanel.track("Loan document uploaded");
      } catch(e) {
        this.setState({ loading: false, deleteLoading: false, errors: ErrorUtil.formatErrors(e) });
      }
    }

    this.setState({ loading: false });
  };

  getDocumentCategories = ():LoanDocumentCategory[] => {
    let documentCategories:LoanDocumentCategory[] = [];

    const { loanApplication, loanDocumentCategories } = this.props;

    if(ReduxUtil.hasData(loanDocumentCategories) && loanApplication.loan && loanApplication.loan.loanType && loanApplication.loan.loanPurpose) {
      documentCategories = loanDocumentCategories.data.filter(c => loanApplication.loan && loanApplication.loan.loanType && loanApplication.loan.loanPurpose && c.loanTypes.includes(loanApplication.loan.loanType) && c.loanPurposes.includes(loanApplication.loan.loanPurpose));
    }

    return documentCategories;
  };

  hasAssetsOrLiabilities = () => {
    const { loanApplication } = this.props;

    let hasAssetsOrLiabilities = false;
    if(loanApplication.borrowers) {
      for(let i=0;i<loanApplication.borrowers.length;i++) {
        const borrower = loanApplication.borrowers[i];
        if((borrower.accountAssets && borrower.accountAssets.length > 0) || (borrower.liabilities && borrower.liabilities.length > 0)) {
          hasAssetsOrLiabilities = true;
          break;
        }
      }
    }

    return hasAssetsOrLiabilities;
  };


  onDownloadReportClick = () => {
    const {accessToken} = this.props.token.data;
    const url = `${process.env.REACT_APP_API_HOST}/v1/loan-applications/asset-reports/${this.props.loanApplication._id}?access_token=${accessToken}`;
    window.location.assign(url);
  };

  render() {
    const { classes, loanApplication, integrations, mixpanel } = this.props;
    const {errors, loading} = this.state;
    const documentCategories = this.getDocumentCategories();
    const hasAssetsOrLiabilities = this.hasAssetsOrLiabilities();
    const canEdit = loanApplication.status !== 'closed' && loanApplication.__t !== 'Rev1';
    return (
      <div>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        {loanApplication.status === 'closed' &&
          <div>
            <Typography variant="subtitle1">
              <FormattedMessage id="documents" />
            </Typography>

            {(!loanApplication.documents || (loanApplication.documents && loanApplication.documents.length === 0)) &&
              <Typography variant="body1">
                <FormattedMessage id="no_documents" />
              </Typography>
            }
            {loanApplication.documents &&
              <LoanDocumentList documents={loanApplication.documents}
                                mixpanel={mixpanel} />
            }
          </div>
        }

        <div>
          {canEdit &&
            <div>
              <Dropzone multiple={true}
                        preview={false}
                        onAdd={this.onFileDrop}/>

              {loading &&
              <div className={classes.mv2}>
                <LinearProgress />
              </div>
              }
            </div>
          }

          {(hasAssetsOrLiabilities || (loanApplication.documents && loanApplication.documents.length > 0)) &&
          <div className={classes.filesContainer}>
            {hasAssetsOrLiabilities &&
              <div className={classes.fileItem}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={8} sm={9}>
                    <div className={classes.pl2}>
                      <Typography variant="body1">
                        <FormattedMessage id="asset_report" />
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <IconButton onClick={this.onDownloadReportClick}>
                      <Icon>save_alt</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            }

            {loanApplication.documents && _.orderBy(loanApplication.documents, 'created', 'desc').map((document, i) => {
              return (
                <LoanDocumentListItemForm key={i}
                                          mixpanel={mixpanel}
                                          loanApplication={loanApplication}
                                          documentCategories={documentCategories}
                                          document={document}
                                          integrations={integrations.data}
                                          disabled={!canEdit}  />
              )
            })}
          </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    integrations: state.integrations,
    loanDocumentCategories: state.loanDocumentCategories,
    token: state.token
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addLoanApplicationDocument(id:string, data:LoanApplicationDocumentRequest) {
    return dispatch(addLoanApplicationDocument(id, data));
  },
  getLoanDocumentCategories() {
    return dispatch(getLoanDocumentCategories());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(LoanDocumentsForm)));