import React, {ChangeEvent, MouseEvent} from 'react';
import {
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Card, withStyles, CardHeader, CardContent, MenuItem, ListItemIcon, Menu, FormControlLabel, Checkbox, WithStyles
} from '@material-ui/core';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {LoanApplicationPage} from "../layouts";
import {ErrorUtil, LoanRev2Util, LocationUtil, ReduxUtil} from "../../../utils";
import BaseLoanApplicationPage from "./BaseLoanApplicationPage";
import _ from 'lodash';
import {RealEstateForm} from '../forms';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {LoanApplicationControls} from "../components";
import {DeleteDialog, Dialog, ErrorList, Fab} from "../../../components";
import {ActionProps, DialogState, ErrorState, ReduxLoanApplication, ReduxLoanSettings} from "../../../types";
import {Mixpanel} from "mixpanel-browser";
import {Borrower, BorrowerRealEstateAsset, LoanApplicationUpdateRequest} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  loading: boolean,
  selectedBorrower: Borrower | null,
  selectedRealEstate: BorrowerRealEstateAsset | null,
  realEstateFormDialogOpen: boolean,
  deleteDialog: DialogState,
  redirectTo?: string,
  actionMenuEl: Element|null,
  errors: ErrorState[],
  isStepCompleted?: boolean
}

class RealEstatePage extends BaseLoanApplicationPage<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      selectedBorrower: null,
      selectedRealEstate: null,
      realEstateFormDialogOpen: false,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      actionMenuEl: null,
      errors: []
    };
  }

  static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: Readonly<State>){
    if(nextProps.loanApplication && nextProps.loanApplication.data && prevState.isStepCompleted === undefined){
      return {isStepCompleted: LoanRev2Util.isRealEstateCompleted(nextProps.loanApplication.data)};
    }
    return null;
  };

  componentWillUnmount = () => {
    const {mixpanel, loanApplication} = this.props;
    if(loanApplication.data && !this.state.isStepCompleted && LoanRev2Util.isRealEstateCompleted(loanApplication.data)) {
      mixpanel.track("Loan application step completed", {step: 'real_estate'});
    }
  };

  showFormDialog = (event:MouseEvent, borrower?:Borrower) => {
    event.preventDefault();
    if(borrower) {
      this.setState({ actionMenuEl: null, realEstateFormDialogOpen: true, selectedBorrower: borrower });
    } else {
      this.setState({ actionMenuEl: null, realEstateFormDialogOpen: true });
    }
  };

  hideFormDialog = () => {
    this.setState({ realEstateFormDialogOpen: false, selectedBorrower: null, selectedRealEstate: null });
  };

  onDeleteRealEstateClick = () => {
    this.setState({
      actionMenuEl: null,
      deleteDialog: {
        open: true,
        loading: false,
        errors: []
      }
    });
  };

  hideDeleteDialog = () => {
    this.setState({
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      selectedBorrower: null,
      selectedRealEstate: null
    });
  };

  deleteRealEstate = async () => {
    try {
      const {loanApplication} = this.props;
      const {selectedBorrower, selectedRealEstate} = this.state;

      const deleteDialog = _.clone(this.state.deleteDialog);
      if(loanApplication.data.borrowers && selectedBorrower && selectedRealEstate) {
        deleteDialog.loading = true;
        this.setState({deleteDialog});

        if(selectedBorrower.realEstateAssets) {
          const borrowerIndex = loanApplication.data.borrowers.findIndex(b => b._id === selectedBorrower._id);
          const index = selectedBorrower.realEstateAssets.findIndex(e => e._id === selectedRealEstate._id);
          await this.props.routeProps.updateLoanApplication(loanApplication.data._id, [`borrowers[${borrowerIndex}].realEstateAssets[${index}]`], {merge:'delete'}).send();
        }
      }
      this.setState({
        selectedBorrower: null,
        selectedRealEstate: null,
        deleteDialog: {
          open: false,
          loading: false,
          errors: []
        }
      });
    } catch (e) {
      const deleteDialog = _.clone(this.state.deleteDialog);
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({deleteDialog});
      this.setState({ deleteDialog, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onRealEstateFormSubmit = () => {
    this.setState({
      selectedBorrower: null,
      selectedRealEstate: null,
      realEstateFormDialogOpen: false
    });
  };

  onNextClick = () => {
    this.setState({ redirectTo: this.nextMenuItem.to });
  };

  onShowActionMenu = (event:MouseEvent, borrower:Borrower, realEstate:BorrowerRealEstateAsset) => {
    this.setState({ actionMenuEl: event.currentTarget, selectedBorrower: borrower, selectedRealEstate: realEstate });
  };

  onCloseActionMenu = () => {
    this.setState({ actionMenuEl: null, selectedBorrower: null, selectedRealEstate: null });
  };

  onNoRealEstateChange = async (event:ChangeEvent<HTMLInputElement>, borrower:Borrower) => {
    try {
      const checked = event.target.checked;
      this.setState({ loading: true });
      const loanApplication = _.cloneDeep(this.props.loanApplication.data);
      const borrowerRequestData = LoanRev2Util.borrowerToUpdateRequest(borrower);
      borrowerRequestData.realEstateAssets = checked ? null : [];
      const requestData: LoanApplicationUpdateRequest = {
        borrowers: [borrowerRequestData]
      };
      await this.props.routeProps.updateLoanApplication(loanApplication._id, requestData, {merge: 'append'}).send();
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes, loanApplication } = this.props;
    const { loading, errors, selectedBorrower, redirectTo, selectedRealEstate, realEstateFormDialogOpen, deleteDialog, actionMenuEl } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <LoanApplicationPage menuId="real_estate"
                           loading={loading}>

        <Dialog open={realEstateFormDialogOpen}
                icon={<Icon>business</Icon>}
                title={IntlFormatter.formatMessage(intl, selectedRealEstate ? 'edit_real_estate_for' : 'add_real_estate_for', {name: selectedBorrower ? `${selectedBorrower.firstName} ${selectedBorrower.lastName}` : ''})}
                color="primaryAlt"
                onClose={this.hideFormDialog}
                fullWidth={true}
                maxWidth="sm">
          {selectedBorrower &&
            <RealEstateForm loanApplication={loanApplication.data}
                            borrower={selectedBorrower}
                            realEstate={selectedRealEstate}
                            onSubmit={this.onRealEstateFormSubmit}
                            actions={[
                             <Button onClick={this.hideFormDialog}>
                               <FormattedMessage id="cancel" />
                             </Button>
                            ]}/>
          }
        </Dialog>

        <DeleteDialog open={deleteDialog.open}
                      loading={deleteDialog.loading}
                      errors={deleteDialog.errors}
                      title={IntlFormatter.formatMessage(intl, 'delete_real_estate')}
                      item={selectedRealEstate && selectedRealEstate.address ? selectedRealEstate.address.street1 : '' }
                      onCancel={this.hideDeleteDialog}
                      onSubmit={this.deleteRealEstate} />

        <Menu anchorEl={actionMenuEl}
              open={Boolean(actionMenuEl)}
              onClose={this.onCloseActionMenu}>
          <MenuItem onClick={this.showFormDialog}>

            <ListItemIcon>
              <Icon>mode_edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
          </MenuItem>
          <MenuItem onClick={() => this.onDeleteRealEstateClick()}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')} />
          </MenuItem>
        </Menu>
        <div>
          <ErrorList errors={errors}
                     className={classes.p2}
                     onClose={() => { this.setState({ errors: [] }); } }/>
          <div className={classes.content}>
            {(ReduxUtil.hasData(loanApplication) && loanApplication.data.borrowers) &&
              <div>
                <Grid container spacing={4}>
                  {loanApplication.data.borrowers.map((borrower, i) => {
                    return (
                      // @ts-ignore
                      <Grid key={i} item xs={12} sm={12} md={loanApplication.data.borrowers.length > 1 ? Math.floor(12 / loanApplication.data.borrowers.length) : 6}>
                        <Card className={classes.card}>
                          <CardHeader title={`${borrower.firstName} ${borrower.lastName}`}
                                      subheader={IntlFormatter.formatMessage(intl, borrower.primaryBorrower ? 'borrower' : 'co_borrower')}
                                      avatar={<Icon>{borrower.primaryBorrower ? 'person' : 'person_outline'}</Icon>}
                                      action={
                                        borrower.realEstateAssets ?
                                          <Fab color="primary"
                                               size="small"
                                               flat
                                               rounded onClick={event => this.showFormDialog(event, borrower)}>
                                            <Icon>add</Icon>
                                          </Fab>
                                        : null
                                      }/>
                          <Divider />

                          {Array.isArray(borrower.realEstateAssets) &&
                            <CardContent>
                              {borrower.realEstateAssets.length === 0 &&
                              <div>
                                <Button variant="outlined"
                                        color="primary"
                                        onClick={event => this.showFormDialog(event, borrower)}>
                                  <FormattedMessage id="add_real_estate" />
                                </Button>
                              </div>
                              }

                              {borrower.realEstateAssets.length > 0 &&
                              <div className={classes.mb2}>
                                <List>
                                  {borrower.realEstateAssets.map((realEstate, i) => {
                                    return (
                                      <ListItem key={i}>
                                        <ListItemText primary={realEstate.address ? LocationUtil.formatLocation(realEstate.address) : IntlFormatter.formatMessage(intl, 'na')}
                                                      secondary={realEstate.marketValue ? realEstate.marketValue.toMoney() : ''} />
                                        <ListItemSecondaryAction>
                                          <IconButton onClick={event => this.onShowActionMenu(event, borrower, realEstate)}>
                                            <Icon>more_vert</Icon>
                                          </IconButton>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    )
                                  })}
                                </List>
                              </div>
                              }

                            </CardContent>
                          }

                          {borrower.realEstateAssets &&
                            <Divider />
                          }

                          <div className={classes.p2}>
                            <FormControlLabel label={IntlFormatter.formatMessage(intl, 'no_real_estate')} control={
                              <Checkbox checked={!borrower.realEstateAssets}
                                        onChange={(event) => this.onNoRealEstateChange(event, borrower)}
                                        color="primary" />
                            }
                            />
                          </div>
                        </Card>
                      </Grid>
                    )
                  })}
                </Grid>
              </div>
            }
          </div>
          <div className={classes.content}>
            <LoanApplicationControls prev={this.previousMenuItem}
                                     next={this.nextMenuItem}
                                     onNextClick={this.onNextClick} />
          </div>
        </div>

      </LoanApplicationPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(RealEstatePage)));
