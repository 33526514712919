import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  filters: {
    zIndex: 1000
  },
  badgeTextContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    margin: theme.spacing(2)
  },
  badgeText: {
    padding: theme.spacing(0, 2)
  },
  tileContentItem: {
    marginBottom: theme.spacing(2)
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.primary.dark
  },
  differenceIconSuccess: {
    color: theme.palette.success.main
  },
  differenceIconDanger: {
    color: theme.palette.danger.dark
  },
  differenceValue: {
    color: theme.palette.primary.dark,
    marginRight: theme.spacing(1)
  },
  differenceValueSuccess: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1)
  },
  differenceValueDanger: {
    color: theme.palette.danger.dark,
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tileAvatar: {
    height: 56,
    width: 56
  },
  tileAvatarPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  tileAvatarSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  tileAvatarInfo: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText
  },
  tileAvatarSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  tileAvatarDanger: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrastText
  },
  tileAvatarWhite: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main
  }
});

export default styles;
