import React, {Component} from 'react';
import {
  WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/circularIconStyles';
import clsx from "clsx";

type DefaultProps = {
  color: 'default'|'info'|'infoAlt'|'primary'|'primaryAlt'|'primaryAlt2'|'secondary'|'secondaryAlt'|'secondaryAlt2'|'success'|'successAlt'|'successAlt2'|'warn'|'warnAlt'|'warnAlt2'|'danger'|'dangerAlt'|'dangerAlt2',
  size: 'small'|'standard'|'large',
  variant: 'circle'|'rounded'
}

type Props = {
  icon: React.ReactNode,
} & WithStyles<typeof styles>
  & Partial<DefaultProps>

class CircularIcon extends Component<Props> {
  static defaultProps:DefaultProps = {
    color: 'default',
    size: 'standard',
    variant: 'circle'
  };

  render() {
    const { classes, color, icon, size, variant } = this.props;

    return (
      <div className={clsx(classes.root, classes[color!], classes[size!], classes[variant!] )}>
        {icon}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CircularIcon);
