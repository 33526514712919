import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  breadcrumbsContainer: {
    paddingLeft: theme.spacing(2)
  },
  loader: {
    padding: theme.spacing(2)
  },
  link: {
    cursor: 'pointer'
  },
  errorList: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

export default styles;
