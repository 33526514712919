import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    padding: theme.spacing(1),
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.extraLight
    }
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  default: {
    border: `solid 1px ${theme.palette.neutral.main}`
  },
  info: {
    backgroundColor: theme.palette.info.main,
    border: `solid 1px ${theme.palette.info.main}`
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.primary.main}`
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    border: `solid 1px ${theme.palette.secondary.main}`
  },
  success: {
    backgroundColor: theme.palette.success.main,
    border: `solid 1px ${theme.palette.success.main}`
  },
  danger: {
    backgroundColor: theme.palette.danger.main,
    border: `solid 1px ${theme.palette.danger.main}`
  },
});

export default styles;
