import React, {Component} from 'react';
import IntlFormatter from "../intl";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Badge} from "./index";
import {BadgeProps} from "@material-ui/core";

type Props = {
  status: 'started'|'processing'|'abandoned'|'closed'|'lost'
} & WrappedComponentProps
  & Omit<BadgeProps, 'color'>

type ChipMap = {
  started: string,
  processing: string,
  abandoned: string,
  closed: string,
  lost: string
}

class LoanStatusBadge extends Component<Props> {
  chipClasses:ChipMap = {
    started: 'info',
    processing: 'yield',
    abandoned: 'danger',
    closed: 'success',
    lost: 'warn'
  };

  render() {
    const { intl, status, ...rest } = this.props;

    return (
      // @ts-ignore
      <Badge color={this.chipClasses[status as keyof ChipMap]} {...rest}>
        {IntlFormatter.formatMessage(intl, status)}
      </Badge>
    );
  }
}

export default injectIntl(LoanStatusBadge);
