import React, {useState} from "react";
import {
  Button,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import IntlFormatter from "../intl";
import {Alert, Dialog, Snackbar} from "../components";
import styles from "../theme/jss/layouts/pageStyles";
import {LoanApplication} from "@jerseydev/orca-loans";
import {ReduxState} from "../data/initialState";
import {connect} from "react-redux";
import {ActionProps, ReduxIntegrations, ReduxUser, SnackbarState} from "../types";
import {DateUtil, IntegrationUtil} from "../utils";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {getLoanApplication, sendLoanApplicationToLos} from "../actions/loanApplication";

type Props = {
  loanApplication: LoanApplication,
  integrations: ReduxIntegrations,
  user: ReduxUser,
  className?: string,
  sendLoanApplicationToLos: ActionProps["sendLoanApplicationToLos"],
  getLoanApplication: ActionProps["getLoanApplication"]
} & WithStyles<typeof styles>
  & WrappedComponentProps

const LoanNotSentToLosAlert = (props:Props) => {
  const {classes, intl, loanApplication, integrations, user, className} = props;
  const [logsDialogOpen, setLogsDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarState>({open: false, message: ''});

  const hasLoanFailedToSendToLOS = () => {
    let failed = false;
    const {integrations, loanApplication} = props;
    if(IntegrationUtil.hasIntegration(integrations.data, 'los') && loanApplication.completed && loanApplication.status !== 'closed' && (!loanApplication.los || (loanApplication.los && loanApplication.los.status === 'failed'))) {
      failed = true;
    }
    return failed;
  };

  const onSendToLosClick = async () => {
    const { intl, loanApplication } = props;
    try {
      setLoading(true);
      await props.sendLoanApplicationToLos(loanApplication._id).send();
      setLoading(false);
      setSnackbar({
        open: true,
        message: IntlFormatter.formatMessage(intl, 'loan_has_been_sent'),
        variant: 'success'
      });
    } catch (e) {
      let message = IntlFormatter.formatMessage(intl, 'loan_failed_to_send');
      if(e.response && e.response.status === 409) {
        message = IntlFormatter.formatMessage(intl, 'loan_is_locked');
      }

      // fetch the loan again to get error logs
      await props.getLoanApplication(loanApplication._id).send();
      setLoading(false);
      setSnackbar({ open: true, message, variant: 'danger' })
    }
  };

  const onSnackbarClose = () => {
    setSnackbar({open:false, message: ''})
  };

  if(!hasLoanFailedToSendToLOS()) {
    return null;
  }

  return (
    <div className={className}>
      <Snackbar open={snackbar.open}
                variant={snackbar.variant || 'success'}
                onClose={onSnackbarClose}
                message={snackbar.message}
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={onSnackbarClose}>
                    <Icon>close</Icon>
                  </IconButton>
                ]} />
      <Dialog open={logsDialogOpen}
              title={IntlFormatter.formatMessage(intl, 'logs')}
              icon={<Icon>list_alt</Icon>}
              color="primaryAlt"
              onClose={() => setLogsDialogOpen(false)}
              fullWidth={true}
              maxWidth="sm">
        {loanApplication.logs &&
        <List>
          {loanApplication.logs.map((log, i) => {
            return (
              <ListItem key={i}>
                <ListItemIcon>
                  <Icon color={log.level === 'error' ? 'error' : undefined}>warning</Icon>
                </ListItemIcon>
                <ListItemText primary={log.message}
                              secondary={DateUtil.formatUserDateTime(user.data, log.created)} />
              </ListItem>
            )
          })}
        </List>
        }
      </Dialog>
      <Alert severity="warning" action={
        <div className={classes.rowCenterEnd}>
          {loanApplication.logs && loanApplication.logs.filter(l => l.level === 'error').length > 0 &&
          <div className={classes.mr2}>
            <Button color="inherit" size="small" variant="outlined" onClick={() => setLogsDialogOpen(true)}>
              <FormattedMessage id="view_logs"/>
            </Button>
          </div>
          }
          <Button color="inherit"
                  size="small"
                  variant="outlined"
                  onClick={onSendToLosClick}
                  disabled={loading}>
            <FormattedMessage id={loading ? 'sending' : 'send'} />
          </Button>
        </div>
      }>
        <FormattedMessage id="loan_is_not_sent_to_los"
                          values={{name: IntlFormatter.formatMessage(intl, integrations.data.los!.provider)}}/>
      </Alert>
    </div>
  )
};

const mapStateToProps = (state:ReduxState) => {
  return {
    integrations: state.integrations,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getLoanApplication(id:string) {
    return dispatch(getLoanApplication(id));
  },
  sendLoanApplicationToLos(id:string) {
    return dispatch(sendLoanApplicationToLos(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(LoanNotSentToLosAlert)));