import palette from '../palette';
import {createStyles} from "@material-ui/core";

const styles = () => createStyles({
  card: {
    overflow: 'visible'
  },
  container: {
    alignItems: 'center',
    backgroundColor: palette.white,
  },
  searchFieldContainer: {
    alignItems: 'center',
    padding: '10px 20px'
  },
  searchFieldIcon: {
    marginRight: '10px'
  },
  searchFieldInput: {
    width: '100%'
  }
});

export default styles;
