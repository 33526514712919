import React, {ChangeEvent, MouseEvent, Component} from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  withStyles,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Tooltip,
  ListItemIcon,
  Hidden,
  Button,
  Paper, WithStyles,
} from '@material-ui/core';
import styles from '../../theme/jss/components/loanDetailStyles';
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {
  Snackbar,
  ErrorList,
  Loader,
  LoanStatusBadge,
  LoanStatusButton,
  LoanOfficerCard,
  LoanOfficerReference,
  AutoComplete,
  LoanMilestoneButton,
  CircularIcon,
  LoanAssetsLiabilitiesSummary,
  LoanBorrowerPersonalSummary,
  LoanAddressHistorySummary,
  LoanPropertySummary,
  LoanEmploymentSummary,
  LoanRealEstateSummary,
  LoanDeclarationsSummary,
  LoanDemographicsSummary,
  LoanInfoSummary,
  LoanIncomeSummary,
  Alert,
  LoanHelpAlert, LoanNotSentToLosAlert
} from "../index";
import IntlFormatter from "../../intl";
import {
  AclUtil,
  DateUtil,
  ErrorUtil,
  LoanUtil,
  ObjectUtil,
  TextUtil,
  UserUtil
} from "../../utils";
import config from "../../config";
import {connect} from "react-redux";
import {Link as RouterLink} from 'react-router-dom';
import Api from "../../lib/Api";
import clsx from "clsx";
import _ from 'lodash';
import {updateLoanApplication, sendLoanApplicationToLos, getLoanApplication} from "../../actions/loanApplication";
import Item from "../Item";
import {ValidatorForm} from 'react-material-ui-form-validator';
import LoanGiftsSummary from "../LoanGiftsSummary";
import {LoanApplication, LoanApplicationRequest, LoanOfficer} from "@jerseydev/orca-loans";
import {
  LoanExportFormat,
  ReduxLoanOfficers,
  ReduxIntegrations,
  ReduxUser,
  SnackbarState,
  ErrorState,
  ReduxToken,
  ActionProps
} from "../../types";
import {ReduxState} from "../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {Mixpanel} from "mixpanel-browser";
import IntegrationUtil from "../../utils/IntegrationUtil";

type DefaultProps = {
  showMenu: boolean
}

type Props = {
  loanApplication: LoanApplication,
  mixpanel: Mixpanel,
  user: ReduxUser,
  integrations: ReduxIntegrations,
  loanOfficers: ReduxLoanOfficers,
  token: ReduxToken,
  sendLoanApplicationToLos: ActionProps["sendLoanApplicationToLos"],
  getLoanApplication: ActionProps["getLoanApplication"],
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WithStyles<typeof styles>
  & WrappedComponentProps
  & Partial<DefaultProps>

type State = {
  activePanels: string[],
  exportActionMenu: Element|null,
  completedSteps: string[],
  messageDialogOpen: boolean,
  snackbar: SnackbarState,
  errors: ErrorState[],
  loading: boolean,
  logsDialogOpen: boolean,
  loanOfficerFormOpen: boolean,
  assetUpdateAvailable: boolean,
  helpDialogOpen: boolean
}

class LoanDetailsRev2 extends Component<Props, State> {
  static defaultProps:DefaultProps = {
    showMenu: true
  }

  loanStatusChipClasses = null;

  constructor(props:Props) {
    super(props);

    const panels = [
      'personal',
      'address',
      'loan_info',
      'property',
      'employment',
      'income',
      'assets_liabilities',
      'real_estate',
      'declarations',
      'demographics',
      'gifts'
    ];

    this.state = {
      activePanels: _.cloneDeep(panels),
      exportActionMenu: null,
      completedSteps: LoanUtil.getCompletedSteps(props.loanApplication),
      messageDialogOpen: false,
      snackbar: {
        open: false,
        message: ''
      },
      errors: [],
      loading: false,
      logsDialogOpen: false,
      loanOfficerFormOpen: false,
      assetUpdateAvailable: false,
      helpDialogOpen: false
    };
  }

  componentDidUpdate = (prevProps:Props) => {
    if(!ObjectUtil.isEqual(prevProps.loanApplication, this.props.loanApplication)) {
      this.setState({ completedSteps: LoanUtil.getCompletedSteps(this.props.loanApplication) });
    }
  };

  onPanelChange = (panel:string, event:ChangeEvent<unknown>, expanded:boolean) => {
    const activePanels = _.cloneDeep(this.state.activePanels);
    const panelIndex = activePanels.indexOf(panel);
    if(expanded) {
      if(panelIndex === -1) {
        activePanels.push(panel);
      }
    } else {
      activePanels.splice(panelIndex, 1);
    }

    this.setState({ activePanels });
  };

  onExportActionMenuClick = (event:MouseEvent) => {
    this.setState({ exportActionMenu: event.currentTarget });
  };

  onExportActionMenuClose = () => {
    this.setState({ exportActionMenu: null });
  };

  onExportClick = (event:MouseEvent, format:LoanExportFormat) => {
    const {accessToken} = this.props.token.data;
    const url = LoanUtil.getExportUrl(this.props.loanApplication._id, format, accessToken);
    window.location.assign(url);
    this.setState({ exportActionMenu: null });
  };

  renderAccordionSummary = (title:string, linkTo:string, complete:boolean) => {
    const { intl, classes, loanApplication } = this.props;
    return (
      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item>
            <div className={classes.rowCenter}>
              <div className={classes.mr1}>
                <Tooltip title={IntlFormatter.formatMessage(intl, complete ? 'complete' : 'incomplete')}>
                  <CircularIcon color={complete ? 'successAlt' : 'warnAlt'}
                                icon={<Icon>{complete ? 'check' : 'warning'}</Icon>}
                                variant="rounded" />
                </Tooltip>
              </div>
              <div>
                <Typography variant="subtitle1" color="inherit">
                  {title}
                </Typography>
              </div>
            </div>
          </Grid>
          {LoanUtil.canEdit(loanApplication) &&
          <Grid item>
            <div className={classes.mr2}>
              <Button variant="outlined" component={RouterLink} to={linkTo} color="primary" size="small">
                <FormattedMessage id="edit" />
              </Button>
            </div>
          </Grid>
          }
        </Grid>
      </AccordionSummary>
    )
  };

  onSendToLosClick = () => {
    const { intl, loanApplication } = this.props;
    this.setState({ loading: true });
    this.props.sendLoanApplicationToLos(loanApplication._id).send().then(() => {
      this.setState({
        loading: false,
        snackbar: {
          open: true,
          message: IntlFormatter.formatMessage(intl, 'loan_has_been_sent'),
          variant: 'success'
        }
      });
    }).catch(err => {
      let message = IntlFormatter.formatMessage(intl, 'loan_failed_to_send');
      if(err.response && err.response.status === 409) {
        message = IntlFormatter.formatMessage(intl, 'loan_is_locked');
      }

      // fetch the loan again to get error logs
      const state:any = { loading: false, snackbar: { open: true, message, variant: 'danger' } };
      this.props.getLoanApplication(this.props.loanApplication._id).send().then(() => {
        this.setState(state);
      }).catch(() => {
        this.setState(state);
      });
    });
  };

  renderTopBar = () => {
    const { intl, classes, loanApplication, user} = this.props;

    return (
      <Paper>
        <div className={clsx(classes.pv1, classes.ph2)}>
          <div>

            {loanApplication.creditAuth &&
            <div className={classes.mb2}>
              <Item icon={loanApplication.creditAuth === 'declined' ? 'error' : 'check_circle'}
                    label={IntlFormatter.formatMessage(intl, 'credit')}
                    color={loanApplication.creditAuth === 'declined' ? 'danger' : 'success'}>
                <FormattedMessage id={loanApplication.creditAuth} />
              </Item>
            </div>
            }

            {!AclUtil.hasRole(user.data,'ROLE_MEMBER') && loanApplication.los && loanApplication.los.loanNumber &&
            <div className={classes.mb2}>
              <Item icon="fingerprint"
                    label={IntlFormatter.formatMessage(intl, 'loan_number')}>
                {loanApplication.los.loanNumber}
              </Item>
            </div>
            }

            {loanApplication.milestones && loanApplication.milestones.length > 0 &&
            <div className={classes.mb2}>
              <LoanMilestoneButton loanApplication={loanApplication} />
            </div>
            }

            <div className={classes.mb2}>
              <Item icon="today"
                    label={IntlFormatter.formatMessage(intl, 'started')}>
                {DateUtil.formatDate(loanApplication.created)}
              </Item>
            </div>
            {loanApplication.completed &&
            <div className={classes.mb2}>
              <Item icon="event"
                    label={IntlFormatter.formatMessage(intl, 'completed')}>
                {DateUtil.formatDate(loanApplication.completed)}
              </Item>
            </div>
            }
            {(!AclUtil.hasRole(user.data,'ROLE_MEMBER') && loanApplication.source !== config.app.key) &&
            <div className={classes.mb2}>
              <Item icon="my_location"
                    label={IntlFormatter.formatMessage(intl, 'source')}>
                {loanApplication.source ? loanApplication.source : IntlFormatter.formatMessage(intl, 'unknown')}
              </Item>
            </div>
            }
          </div>
        </div>
      </Paper>
    )
  };

  onNeedHelpClick = () => {
    this.setState({helpDialogOpen:true});
  };

  onNeedHelpClose = () => {
    this.setState({helpDialogOpen:false});
  };

  renderStatusAndExportMenu = () => {
    const { classes, user, loanApplication, showMenu } = this.props;
    const { exportActionMenu } = this.state;

    return (
      <div>

        <div className={UserUtil.isBorrower(user.data) ? classes.rowCenterEnd : classes.helpStatusBar}>
          {!UserUtil.isBorrower(user.data) &&
            <LoanHelpAlert loanApplication={loanApplication} />
          }
          <div className={classes.loanStatusContainer}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                {AclUtil.hasRole(user.data,'ROLE_MEMBER') &&
                <LoanStatusBadge status={loanApplication.status} />
                }
                {!AclUtil.hasRole(user.data, 'ROLE_MEMBER') &&
                <LoanStatusButton loanApplication={loanApplication}/>
                }
              </Grid>

              {(!AclUtil.hasRole(user.data,'ROLE_MEMBER') && LoanUtil.canEdit(loanApplication) && showMenu) &&
              <Grid item>
                <IconButton onClick={this.onExportActionMenuClick}>
                  <Icon>more_vert</Icon>
                </IconButton>
                <Menu anchorEl={exportActionMenu}
                      open={Boolean(exportActionMenu)}
                      onClose={this.onExportActionMenuClose}>
                  {LoanUtil.canEdit(loanApplication) &&
                  <MenuItem component={RouterLink} to={LoanUtil.getEditUrl(loanApplication)}>
                    <ListItemIcon>
                      <Icon>mode_edit</Icon>
                    </ListItemIcon>
                    <ListItemText>
                      <FormattedMessage id="edit" />
                    </ListItemText>
                  </MenuItem>
                  }
                  {/*<MenuItem onClick={event => this.onExportClick(event, 'pdf')}>
                    <ListItemIcon>
                      <Icon>picture_as_pdf</Icon>
                    </ListItemIcon>
                    <ListItemText primary={IntlFormatter.formatMessage(intl, 'export_to_pdf')} />
                  </MenuItem>
                  {!AclUtil.hasRole(user.data, 'ROLE_MEMBER') &&
                    <MenuItem onClick={event => this.onExportClick(event, 'mismo')}>
                      <ListItemIcon>
                        <Icon>insert_drive_file</Icon>
                      </ListItemIcon>
                      <ListItemText primary={IntlFormatter.formatMessage(intl, 'export_to_mismo')}/>
                    </MenuItem>
                  }*/}
                </Menu>
              </Grid>
              }
            </Grid>
          </div>
        </div>
      </div>
    );

  };

  onSnackbarClose = () => {
    this.setState({ snackbar: { open: false, message: '' } });
  };

  hasLoanFailedToSendToLOS = () => {
    let failed = false;
    const {integrations, loanApplication} = this.props;
    if(IntegrationUtil.hasIntegration(integrations.data, 'los') && loanApplication.completed && loanApplication.status !== 'closed' && (!loanApplication.los || (loanApplication.los && loanApplication.los.status === 'failed'))) {
      failed = true;
    }
    return failed;
  };

  onLogsDialogClick = () => {
    this.setState({ logsDialogOpen: true });
  };

  onLogsDialogClose = () => {
    this.setState({ logsDialogOpen: false });
  };

  searchLoanOfficers = async (searchText:string) => {
    const result = await Api.searchLoanOfficers({ searchText });
    return result.data;
  };

  onChangeLoanOfficerClick = () => {
    this.setState({ loanOfficerFormOpen: true });
  };

  onChangeLoanOfficerClose = () => {
    this.setState({ loanOfficerFormOpen: false });
  };

  onLoanOfficerChange = (loanOfficer:LoanOfficer) => {
    if(this.props.loanApplication.loanOfficer && this.props.loanApplication.loanOfficer._id === loanOfficer._id) {
      this.setState({ loanOfficerFormOpen: false });
    } else {
      this.setState({ loading: true, loanOfficerFormOpen: false });
      this.props.updateLoanApplication(this.props.loanApplication._id, { loanOfficer: loanOfficer._id }).send().then(() => {
        this.setState({ loading: false });
      }).catch(err => {
        this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
      });
    }
  };

  render() {
    const { intl, classes, loanApplication, user, mixpanel } = this.props;
    const { snackbar, errors, loading, loanOfficerFormOpen, activePanels, completedSteps } = this.state;

    return (
      <div>
        <Loader visible={loading} />

        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.onSnackbarClose}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        <Hidden mdUp>
          <div className={classes.mb2}>
            {this.renderStatusAndExportMenu()}
          </div>
        </Hidden>

        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => this.setState({ errors: [] })} />

        {!AclUtil.hasRole(user.data, 'ROLE_MEMBER') &&
          <LoanNotSentToLosAlert loanApplication={loanApplication}
                                 className={classes.mb2} />
        }

        <Hidden smDown>
          <div className={classes.mb2}>
            {this.renderStatusAndExportMenu()}
          </div>
        </Hidden>

        <div>
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                {this.renderTopBar()}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {loanApplication.loanOfficer &&
                <div className={classes.loanOfficerContainer}>
                  <LoanOfficerCard loanOfficer={loanApplication.loanOfficer}
                                   mixpanel={mixpanel}
                                   variant="condensed">

                    {!LoanUtil.isLoanOfficerLicensedInPropertyState(loanApplication) &&
                    <div className={classes.mt2}>
                      <Alert severity="danger">
                        <FormattedMessage id="loan_officer_not_licensed_warning" />
                      </Alert>
                    </div>
                    }

                    {((UserUtil.isOwner(user.data) || UserUtil.isBranchManager(user.data)) && !loanOfficerFormOpen) &&
                    <div className={clsx(classes.center, classes.mt2)}>
                      <div className={classes.rowCenter}>
                        <Button variant="outlined"
                                onClick={this.onChangeLoanOfficerClick}
                                disabled={!!(loanApplication.los && loanApplication.los.losId)}>
                          <FormattedMessage id="change_loan_officer"/>
                        </Button>
                        {(loanApplication.los && loanApplication.los.losId) &&
                        <div className={classes.ml1}>
                          <Tooltip title={IntlFormatter.formatMessage(intl, 'cant_change_loan_officer_after_sent_to_los', { los: TextUtil.ucWords(loanApplication.los.provider) })}>
                            <Icon fontSize="small">info</Icon>
                          </Tooltip>
                        </div>
                        }
                      </div>
                    </div>
                    }
                    {loanOfficerFormOpen &&
                    <div className={classes.mt2}>
                      <ValidatorForm onSubmit={() => {}}>
                        <div className={classes.rowCenter}>
                          <AutoComplete getOptionLabel={(item:LoanOfficer) => {return `${item.firstName} ${item.lastName}`; }}
                                        onChange={this.onLoanOfficerChange}
                                        onTextChange={this.searchLoanOfficers}
                                        renderOption={(option:LoanOfficer) => (
                                          <React.Fragment>
                                            <LoanOfficerReference loanOfficer={option}
                                                                  subtitle={`${IntlFormatter.formatMessage(intl, 'nmls_id')}: ${option.nmlsId}`}
                                                                  popoverEnabled={false} />
                                          </React.Fragment>
                                        )}
                                        getOptionSelected={(option:LoanOfficer, value:LoanOfficer) => {
                                          return option._id === value._id;
                                        }}
                                        label={IntlFormatter.formatMessage(intl, 'loan_officer')}
                                        placeholder={IntlFormatter.formatMessage(intl, 'search_loan_officers')}
                                        openOnFocus={true}
                                        debounce={500}/>
                          <IconButton onClick={this.onChangeLoanOfficerClose}>
                            <Icon>cancel</Icon>
                          </IconButton>
                        </div>
                      </ValidatorForm>
                    </div>
                    }
                  </LoanOfficerCard>
                </div>
                }

              </Grid>
            </Grid>
          </div>

          <Accordion key="personal"
                     expanded={activePanels.includes('personal')}
                     onChange={(event, expanded) => this.onPanelChange('personal', event, expanded)}>
            {this.renderAccordionSummary(IntlFormatter.formatMessage(intl, 'personal'),'/apply/personal', completedSteps.includes('personal'))}
            <AccordionDetails>
              <LoanBorrowerPersonalSummary loanApplication={loanApplication} />
            </AccordionDetails>
          </Accordion>

          <Accordion key="address"
                     expanded={activePanels.includes('address')}
                     onChange={(event, expanded) => this.onPanelChange('address', event, expanded)}>
            {this.renderAccordionSummary(IntlFormatter.formatMessage(intl, 'address'),'/apply/address-history', completedSteps.includes('address'))}
            <AccordionDetails>
              <LoanAddressHistorySummary loanApplication={loanApplication} />
            </AccordionDetails>
          </Accordion>

          <Accordion key="loan_info"
                     expanded={activePanels.includes('loan_info')}
                     onChange={(event, expanded) => this.onPanelChange('loan_info', event, expanded)}>
            {this.renderAccordionSummary(IntlFormatter.formatMessage(intl, 'loan_info'),'/apply/loan', completedSteps.includes('loan_info'))}
            <AccordionDetails>
              <LoanInfoSummary loanApplication={loanApplication} />
            </AccordionDetails>
          </Accordion>

          <Accordion key="property"
                     expanded={activePanels.includes('property')}
                     onChange={(event, expanded) => this.onPanelChange('property', event, expanded)}>
            {this.renderAccordionSummary(IntlFormatter.formatMessage(intl, 'property'),'/apply/property', completedSteps.includes('property'))}
            <AccordionDetails>
              <LoanPropertySummary loanApplication={loanApplication} />
            </AccordionDetails>
          </Accordion>

          <Accordion key="employment"
                     expanded={activePanels.includes('employment')}
                     onChange={(event, expanded) => this.onPanelChange('employment', event, expanded)}>
            {this.renderAccordionSummary(IntlFormatter.formatMessage(intl, 'employment'),'/apply/employment', completedSteps.includes('employment'))}
            <AccordionDetails>
              <LoanEmploymentSummary loanApplication={loanApplication} />
            </AccordionDetails>
          </Accordion>

          <Accordion key="income"
                     expanded={activePanels.includes('income')}
                     onChange={(event, expanded) => this.onPanelChange('income', event, expanded)}>
            {this.renderAccordionSummary(IntlFormatter.formatMessage(intl, 'other_income'),'/apply/income', completedSteps.includes('income'))}
            <AccordionDetails>
              <LoanIncomeSummary loanApplication={loanApplication} />
            </AccordionDetails>
          </Accordion>

          <Accordion key="assets_liabilities"
                     expanded={activePanels.includes('assets_liabilities')}
                     onChange={(event, expanded) => this.onPanelChange('assets_liabilities', event, expanded)}>
            {this.renderAccordionSummary(IntlFormatter.formatMessage(intl, 'assets_liabilities'),'/apply/assets-and-liabilities', completedSteps.includes('assets_liabilities'))}
            <AccordionDetails>
              <LoanAssetsLiabilitiesSummary loanApplication={loanApplication} />
            </AccordionDetails>
          </Accordion>

          <Accordion key="real_estate"
                     expanded={activePanels.includes('real_estate')}
                     onChange={(event, expanded) => this.onPanelChange('real_estate', event, expanded)}>
            {this.renderAccordionSummary(IntlFormatter.formatMessage(intl, 'real_estate'),'/apply/real-estate', completedSteps.includes('real_estate'))}
            <AccordionDetails>
              <LoanRealEstateSummary loanApplication={loanApplication} />
            </AccordionDetails>
          </Accordion>

          <Accordion key="declarations"
                     expanded={activePanels.includes('declarations')}
                     onChange={(event, expanded) => this.onPanelChange('declarations', event, expanded)}>
            {this.renderAccordionSummary(IntlFormatter.formatMessage(intl, 'declarations'),'/apply/declarations', completedSteps.includes('declarations'))}
            <AccordionDetails>
              <LoanDeclarationsSummary loanApplication={loanApplication} />
            </AccordionDetails>
          </Accordion>

          <Accordion key="demographics"
                     expanded={activePanels.includes('demographics')}
                     onChange={(event, expanded) => this.onPanelChange('demographics', event, expanded)}>
            {this.renderAccordionSummary(IntlFormatter.formatMessage(intl, 'demographics'),'/apply/demographics', completedSteps.includes('demographics'))}
            <AccordionDetails>
              <LoanDemographicsSummary loanApplication={loanApplication} />
            </AccordionDetails>
          </Accordion>

          <Accordion key="gifts"
                     expanded={activePanels.includes('gifts')}
                     onChange={(event, expanded) => this.onPanelChange('gifts', event, expanded)}>
            {this.renderAccordionSummary(IntlFormatter.formatMessage(intl, 'gifts'),'/apply/gifts', completedSteps.includes('gifts'))}
            <AccordionDetails>
              <LoanGiftsSummary loanApplication={loanApplication} />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    user: state.user,
    loanOfficers: state.loanOfficers,
    integrations: state.integrations,
    token: state.token
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getLoanApplication(id:string) {
    return dispatch(getLoanApplication(id));
  },
  sendLoanApplicationToLos(id:string) {
    return dispatch(sendLoanApplicationToLos(id));
  },
  updateLoanApplication(id:string, data:LoanApplicationRequest) {
    return dispatch(updateLoanApplication(id, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(LoanDetailsRev2)));
