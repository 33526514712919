import React, {MouseEvent} from 'react';
import {
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Button,
  withStyles, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import BaseForm from "../../../forms/BaseForm";
import {connect} from "react-redux";
import {deleteLoanDocumentCategory, getLoanDocumentCategories} from "../../../actions/loanDocumentCategories";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {DeleteDialog, Dialog, Section, Snackbar, Fab, PropagateLoader} from "../../../components";
import IntlFormatter from '../../../intl/index';
import _ from "lodash";
import {ErrorUtil, ReduxUtil} from "../../../utils";
import {DocumentCategoryForm} from '../forms';
import {DialogState, ActionProps, SnackbarState, ReduxLoanDocumentCategories} from "../../../types";
import {
  LoanDocumentCategory
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  loanDocumentCategories: ReduxLoanDocumentCategories,
  getLoanDocumentCategories: ActionProps["getLoanDocumentCategories"],
  deleteLoanDocumentCategory: ActionProps["deleteLoanDocumentCategory"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  loading: boolean,
  selectedItem: LoanDocumentCategory|null,
  addEditDialogOpen: boolean,
  deleteDialog: DialogState,
  snackbar: SnackbarState,
  actionMenuEl: Element|null
}

class DocumentCategoryList extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: true,
      selectedItem: null,
      addEditDialogOpen: false,
      actionMenuEl: null,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      snackbar: {
        open: false,
        message: ''
      }
    }
  }

  componentDidMount = async () => {
    if(!ReduxUtil.hasData(this.props.loanDocumentCategories) && ReduxUtil.isIdle(this.props.loanDocumentCategories)) {
      await this.props.getLoanDocumentCategories().send();
    }

    this.setState({loading:false});
  };

  renderLoanTypes = (documentCategory:LoanDocumentCategory) => {
    const loanTypes:string[] = [];
    documentCategory.loanTypes.forEach(loanType => {
      loanTypes.push(IntlFormatter.formatMessage(this.props.intl, `loan_type_${loanType}`));
    });
    return loanTypes.join(', ');
  };

  onActionMenuClick = (event:MouseEvent, documentCategory:LoanDocumentCategory) => {
    this.setState({ actionMenuEl: event.currentTarget, selectedItem: _.cloneDeep(documentCategory) });
  };

  onActionMenuClose = () => {
    this.setState({ actionMenuEl: null, selectedItem: null });
  };

  onAddClick = () => {
    this.setState({ addEditDialogOpen: true });
  };

  onEditClick = () => {
    this.setState({ actionMenuEl: null, addEditDialogOpen: true });
  };

  onCancel = () => {
    this.setState({ addEditDialogOpen: false, selectedItem: null });
  };

  onSubmit = () => {
    this.setState({
      addEditDialogOpen: false,
      selectedItem: null,
      snackbar: {
        open: true,
        message: IntlFormatter.formatMessage(this.props.intl, 'document_category_saved')
      }
    });
  };

  onDeleteClick = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = true;
    this.setState({ actionMenuEl: null, deleteDialog });
  };

  onDeleteConfirm = async () => {
    const deleteDialog = _.clone(this.state.deleteDialog);

    try {
      deleteDialog.loading = true;
      deleteDialog.errors = [];
      this.setState({ deleteDialog });

      const { selectedItem } = this.state;
      if(selectedItem) {
        await this.props.deleteLoanDocumentCategory(selectedItem._id).send();
        this.props.mixpanel.track("Document category deleted");
      }

      deleteDialog.open = false;
      deleteDialog.loading = false;
      this.setState({
        deleteDialog,
        selectedItem: null,
        snackbar: {
          open: true,
          message: IntlFormatter.formatMessage(this.props.intl, 'document_category_deleted')
        }
      });
    } catch (e) {
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({ deleteDialog });
    }
  };

  onDeleteCancel = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = false;
    deleteDialog.loading = false;
    deleteDialog.errors = [];

    this.setState({ deleteDialog, selectedItem: null });
  };

  onSnackbarClose = () => {
    this.setState({
      snackbar: {
        open: false,
        message:''
      }
    });
  };

  render() {

    const { intl, mixpanel, loanDocumentCategories } = this.props;
    const { addEditDialogOpen, deleteDialog, selectedItem, snackbar, actionMenuEl, loading } = this.state;

    if(loading) {
      return <PropagateLoader loading={true} />
    }

    return (
      <div>
        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.onSnackbarClose}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        <DeleteDialog open={deleteDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'delete_category')}
                      item={selectedItem ? selectedItem.name : ''}
                      loading={deleteDialog.loading}
                      errors={deleteDialog.errors}
                      onCancel={this.onDeleteCancel}
                      onSubmit={this.onDeleteConfirm} />

        <Dialog open={addEditDialogOpen}
                title={IntlFormatter.formatMessage(intl, selectedItem ? 'edit_document_category' : 'add_document_category')}
                icon={<Icon>label</Icon>}
                color="infoAlt"
                fullWidth={true}
                maxWidth="sm"
                onClose={this.onCancel}>
            <DocumentCategoryForm documentCategory={selectedItem}
                                  mixpanel={mixpanel}
                                  onCancel={this.onCancel}
                                  onSubmit={this.onSubmit} />
        </Dialog>

        <Menu anchorEl={actionMenuEl}
              open={Boolean(actionMenuEl)}
              onClose={this.onActionMenuClose}>
          <MenuItem onClick={this.onEditClick}>
            <ListItemIcon>
              <Icon>mode_edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')}/>
          </MenuItem>
          <MenuItem onClick={this.onDeleteClick}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')}/>
          </MenuItem>
        </Menu>

        <Section title={IntlFormatter.formatMessage(intl, 'document_categories')}
                 helperText={IntlFormatter.formatMessage(intl, 'document_categories_help')}
                 actions={
          <Fab color="primary"
               size="small"
               onClick={this.onAddClick}
               flat
               rounded>
            <Icon>add</Icon>
          </Fab>
        }>
          {loanDocumentCategories.data.length === 0 &&
          <div>
            <Button variant="outlined" color="primary" onClick={this.onAddClick}>
              <FormattedMessage id="add_category" />
            </Button>
          </div>
          }
          <List>
            {loanDocumentCategories.data.map((documentCategory, i ) => {
              const loanTypesText = this.renderLoanTypes(documentCategory);
              return (
                <ListItem key={i}>
                  <ListItemText primary={`${documentCategory.name}${documentCategory.required ? ' *' : ''}`}
                                secondary={loanTypesText} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={(event) => this.onActionMenuClick(event, documentCategory)}>
                      <Icon>more_vert</Icon>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanDocumentCategories: state.loanDocumentCategories
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getLoanDocumentCategories() {
    return dispatch(getLoanDocumentCategories());
  },
  deleteLoanDocumentCategory(id:string) {
    return dispatch(deleteLoanDocumentCategory(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(DocumentCategoryList)));
