import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {
  SecureMessage,
  SecureMessageRequest,
  SecureMessageSearchRequest,
} from "@jerseydev/orca-loans";

export function getSecureMessages(threadId:string, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getSecureMessages(threadId, queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessages = state.secureMessages && state.secureMessages.data ? _.cloneDeep(state.secureMessages.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.FETCHING, secureMessages));

      apiRequest.then(response => {
        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getUnreadSecureMessages(queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const state = getState();
    const cancelSource = createCancelSource();
    const apiRequest = Api.searchSecureMessages({ notReadBy: [state.user!.data._id] }, queryParams, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const secureMessages = state.secureMessages && state.secureMessages.data ? _.cloneDeep(state.secureMessages.data) : [];
      dispatch(setData(types.SET_UNREAD_SECURE_MESSAGES, ReduxAsyncOperation.FETCHING, secureMessages));

      apiRequest.then(response => {
        dispatch(setData(types.SET_UNREAD_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_UNREAD_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getUnreadSecureMessageCount():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const state = getState();
    const cancelSource = createCancelSource();
    const apiRequest = Api.searchSecureMessages({ notReadBy: [state.user!.data._id] }, undefined, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      let unreadSecureMessageCount = state.unreadSecureMessageCount && state.unreadSecureMessageCount.data ? state.unreadSecureMessageCount.data : 0;
      dispatch(setData(types.SET_UNREAD_SECURE_MESSAGE_COUNT, ReduxAsyncOperation.UPDATING, unreadSecureMessageCount));

      apiRequest.then(response => {
        unreadSecureMessageCount = parseInt(response.headers['total-count']);
        dispatch(setData(types.SET_UNREAD_SECURE_MESSAGE_COUNT, ReduxAsyncOperation.IDLE, unreadSecureMessageCount));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_UNREAD_SECURE_MESSAGE_COUNT, ReduxAsyncOperation.IDLE, unreadSecureMessageCount));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getSecureMessage(threadId:string, id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getSecureMessage(threadId, id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessages = state.secureMessages && state.secureMessages.data ? _.cloneDeep(state.secureMessages.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.FETCHING, _.cloneDeep(secureMessages)));

      apiRequest.then(response => {
        secureMessages.push(response.data);
        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function searchSecureMessages(criteria:SecureMessageSearchRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.searchSecureMessages(criteria, queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessages = state.secureMessages && state.secureMessages.data ? _.cloneDeep(state.secureMessages.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.FETCHING, secureMessages));

      apiRequest.then(response => {
        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addSecureMessage(threadId:string, secureMessage:SecureMessageRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createSecureMessage(threadId, secureMessage, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessages = state.secureMessages && state.secureMessages.data ? _.cloneDeep(state.secureMessages.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.ADDING, _.cloneDeep(secureMessages)));

      apiRequest.then(response => {
        // updated through socket event
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateSecureMessage(threadId:string, id:string, secureMessage:SecureMessageRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateSecureMessage(threadId, id, secureMessage, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessages = state.secureMessages && state.secureMessages.data ? _.cloneDeep(state.secureMessages.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.UPDATING, _.cloneDeep(secureMessages)));

      apiRequest.then(response => {
        const index = secureMessages.findIndex(s => s._id === id);
        secureMessages[index] = response.data;

        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function readSecureMessage(threadId:string, id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.readSecureMessage(threadId, id, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const secureMessages = state.secureMessages && state.secureMessages.data ? _.cloneDeep(state.secureMessages.data) : [];
      dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.UPDATING, _.cloneDeep(secureMessages)));

      apiRequest.then(response => {
        const index = secureMessages.findIndex(s => s._id === id);
        secureMessages[index] = response.data;

        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateReduxSecureMessage(secureMessage:SecureMessage):ThunkAction<void, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const state = getState();
    const secureMessages = state.secureMessages && state.secureMessages.data ? _.cloneDeep(state.secureMessages.data) : [];
    if(secureMessages[0] && secureMessages[0].thread._id === secureMessage.thread._id) {
      const index = secureMessages.findIndex(d => d._id === secureMessage._id);
      if(index === -1) {
        secureMessages.unshift(secureMessage);
      } else {
        secureMessages[index] = secureMessage;
      }
      dispatch(setData(types.SET_SECURE_MESSAGES, ReduxAsyncOperation.IDLE, secureMessages));
    }
  };
}
