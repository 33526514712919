import React, {useState} from "react";
import {Button, Grid, Icon, Typography} from "@material-ui/core";
import {SettingsUtil} from "../utils";
import {FormattedMessage} from "react-intl";
import {LoanChecklist, LoanOfficerCard} from "./index";
import {useStyles} from "../theme/jss/layouts/pageStyles";
import {Redirect, useLocation} from "react-router-dom";
import {Account, LoanSettings, User} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

export type LoanStartedWelcomeProps = {
  loanSettings: LoanSettings,
  user: User,
  account: Account,
  mixpanel: Mixpanel,
};

const LoanStartedWelcome = (props:LoanStartedWelcomeProps) => {
  const classes = useStyles();
  const location = useLocation();

  const [redirectTo, setRedirectTo] = useState<string|null>(null);
  const {loanSettings, user, account, mixpanel} = props;

  const onGetStartedClick = () => {
    mixpanel.track("Loan application started");
    setRedirectTo(`/apply/personal${location.search}`);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }


  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={8}>
        <div className={classes.rowCenter}>
          <div className={classes.mr2}>
            <img src="/assets/img/house-and-keys.png" alt="House and Keys" />
          </div>
          <div>
            <div className={classes.mb2}>
              <Typography variant="h4">
                {loanSettings.loanStartedTitle ?
                  SettingsUtil.formatLoanMessage(loanSettings.loanStartedTitle, {...user, company: account.name})
                  : (
                    <FormattedMessage id="loan_application_start_page_title"
                                      values={{company: account.name}}/>
                  )
                }
              </Typography>
            </div>
            <div>
              {loanSettings.loanStartedMessage ? SettingsUtil.formatLoanMessage(loanSettings.loanStartedMessage, {...user, company: account.name}) : (
                <Typography variant="body1">
                  <FormattedMessage id="loan_application_start_page_text"/>
                </Typography>
              )}
              <div className={classes.mt2}>
                <Button variant="contained"
                        color="primary"
                        size="large"
                        endIcon={<Icon>forward</Icon>}
                        onClick={onGetStartedClick}>
                  <FormattedMessage id="lets_go" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <LoanChecklist />
        {user.additionalProps && user.additionalProps.defaultLoanOfficer &&
        <div className={classes.mt2}>
          <LoanOfficerCard loanOfficer={user.additionalProps.defaultLoanOfficer}
                           mixpanel={mixpanel} />
        </div>
        }
      </Grid>
    </Grid>
  )
};

export default LoanStartedWelcome;