import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {Grid, Button, withStyles, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, TextField, PasswordField} from "../../../components/index";
import {Link as RouterLink, RouteComponentProps, withRouter} from 'react-router-dom';
import Api from "../../../lib/Api";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ErrorUtil} from "../../../utils";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import _ from 'lodash';
import queryString from "query-string";
import * as Sentry from "@sentry/browser";
import {ErrorState} from "../../../types";
import {ResetPasswordRequest} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  onSubmit?: (data:{email:string, password:string}) => void,
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps
  & RouteComponentProps

type Form = {
  email: string,
  code: string|null,
  password: string,
  passwordConfirm: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  showEmailField: boolean,
  showCodeField: boolean
}

class ForgotPasswordForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        email: '',
        code: '',
        password: '',
        passwordConfirm: ''
      },
      errors: [],
      showEmailField: false,
      showCodeField: false,
    };
  }

  componentDidMount = () => {

    const queryParams = queryString.parse(this.props.location.search);
    const form = _.clone(this.state.form);
    const state:any = {form};

    if(queryParams.email) {
      form.email = decodeURIComponent(queryParams.email as string);
    } else {
      state.showEmailField = true;
    }

    if(queryParams.code) {
      form.code = decodeURIComponent(queryParams.code as string);
    } else {
      state.showCodeField = true;
    }

    this.setState(state);
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    try {
      this.setState({ loading: true, errors: [] });
      const {email, password, code} = this.state.form;
      const requestData:ResetPasswordRequest = {
        email,
        password,
        code: code!
      };
      await Api.resetPassword(requestData);
      const {mixpanel} = this.props;
      mixpanel.alias(email);
      mixpanel.track("Reset password", { email });
      this.setState({ loading: false }, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit({email, password});
        }
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onVerify = async(token:string) => {
    try {
      await Api.verifyRecaptchaToken({ token });
    } catch (e) {
      Sentry.withScope(() => {
        Sentry.captureException(e);
      });
    }
  };

  render() {

    const { intl, classes } = this.props;
    const { showEmailField, showCodeField, form, loading, errors } = this.state;

    return (
      <div>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
          <Form onSubmit={this.onSubmit}>

            <ErrorList errors={errors}
                       className={classes.mv2}
                       onClose={() => { this.setState({ errors: [] }); } } />
            {showEmailField &&
              <div className={classes.mb2}>
                <TextField name="email"
                           label={IntlFormatter.formatMessage(intl, 'email')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.email')}
                           value={form.email}
                           fullWidth={true}
                           inputProps={{
                             autoCapitalize: 'none',
                           }}
                           validators={['required', 'isEmail']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
              </div>
            }
            {showCodeField &&
              <div className={classes.mb2}>
                <TextField name="password"
                           label={IntlFormatter.formatMessage(intl, 'code')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.code')}
                           value={form.code}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required')
                           ]} />
              </div>
            }
            <div className={classes.mb2}>
              <PasswordField name="password"
                             label={IntlFormatter.formatMessage(intl, 'password')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.password')}
                             value={form.password}
                             fullWidth={true}
                             validators={['required', 'isValidPassword']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required'),
                               IntlFormatter.formatMessage(intl, 'validation_password_invalid'),
                             ]} />
            </div>
            <div className={classes.mb2}>
              <PasswordField name="passwordConfirm"
                             label={IntlFormatter.formatMessage(intl, 'confirm_password')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.passwordConfirm')}
                             value={form.passwordConfirm}
                             fullWidth={true}
                             validators={['required', `isPasswordMatch:${form.password}`]}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required'),
                               IntlFormatter.formatMessage(intl, 'validation_password_dont_match')
                             ]} />
            </div>
            <div>
              <GoogleReCaptcha onVerify={this.onVerify} />
            </div>
            <div className={classes.mt2}>
              <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <Button component={RouterLink} to="/login">
                    <FormattedMessage id="cancel" />
                  </Button>
                </Grid>
                <Grid item>
                  <SubmitButton loading={loading}>
                    <FormattedMessage id="submit" />
                  </SubmitButton>
                </Grid>
              </Grid>
            </div>
          </Form>
        </GoogleReCaptchaProvider>
      </div>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(withRouter(injectIntl(ForgotPasswordForm)));
