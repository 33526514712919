import * as types from '../actions/types';
import createReducer from '../lib/createReducer';
import {ReduxState} from "../data/initialState";
import {ReduxActionData} from "../types";
import _ from "lodash";

export const token = createReducer({}, {
  [types.SET_TOKENS](state:ReduxState, action:ReduxActionData) {
    let newState = {};
    newState = action.payload;
    return newState;
  }
});

export const user = createReducer({}, {
  [types.SET_USER](state:ReduxState, action:ReduxActionData) {
    let newState = {};
    newState = action.payload;
    return newState;
  }
});

export const authenticated = createReducer(false, {
  [types.SET_AUTHENTICATED](state:ReduxState, action:ReduxActionData) {
    let newState = {};
    newState = action.payload;
    return newState;
  }
});

export const inactivityLogout = createReducer(false, {
  [types.SET_INACTIVITY_LOGOUT](state:ReduxState, action:ReduxActionData) {
    let newState = {};
    newState = action.payload;
    return newState;
  }
});

export const dontPromptForMobileNumber = createReducer(null, {
  [types.ADD_DONT_PROMPT_MOBILE_PHONE](state:ReduxState, action:ReduxActionData) {
    let newState = state.dontPromptForMobileNumber ? _.clone(state.dontPromptForMobileNumber) : [];
    if(!newState.includes(action.payload)) {
      newState.push(action.payload);
    }
    return newState;
  },
  [types.REMOVE_DONT_PROMPT_MOBILE_PHONE](state:ReduxState, action:ReduxActionData) {
    let newState = state.dontPromptForMobileNumber ? _.clone(state.dontPromptForMobileNumber) : [];
    const index = newState.findIndex(p => p === action.payload);
    if(index > -1) {
      newState.splice(index, 1);
    }
    return newState;
  }
});
