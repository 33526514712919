import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  summary: {
    marginBottom: theme.spacing(3)
  },
  resultSet: {
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0
    }
  },
  disclaimer: {
    marginTop: theme.spacing(3)
  }
});

export default styles;
