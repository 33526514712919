import translations from "../intl/translations";
import {
  ReduxAccount,
  ReduxApp,
  ReduxBankAccounts,
  ReduxBranches,
  ReduxCreditCards,
  ReduxDashboard,
  ReduxDesign,
  ReduxLoanApplication,
  ReduxLoanApplications,
  ReduxLoanOfficer,
  ReduxLoanOfficers,
  ReduxNotifications,
  ReduxOAuthClients,
  ReduxPlans,
  ReduxRoles,
  ReduxSecureMessages,
  ReduxLoanSettings,
  ReduxMessageSettings,
  ReduxSubscription,
  ReduxToken,
  ReduxUser,
  ReduxUsers,
  ReduxWebHooks,
  ReduxIntegrations,
  ReduxLoanDocumentCategories,
  ReduxHiddenMilestones,
  ReduxInvoices,
  ReduxTickets,
  ReduxUnreadSecureMessageCount,
  ReduxSecureMessageThreads, ReduxWebHookLogs
} from "../types";

export interface ReduxState {
  intl: {
    defaultLocale: string,
    locale: string,
    messages: any
  },
  dashboard?: ReduxDashboard,
  account?: ReduxAccount,
  app?: ReduxApp,
  authenticated?: boolean,
  bankAccounts?: ReduxBankAccounts,
  branches?: ReduxBranches,
  creditCards?: ReduxCreditCards,
  design?: ReduxDesign,
  loanApplication?: ReduxLoanApplication,
  loanApplications?: ReduxLoanApplications,
  loanOfficers?: ReduxLoanOfficers,
  defaultLoanOfficer?: ReduxLoanOfficer,
  notifications?: ReduxNotifications,
  oauthClients?: ReduxOAuthClients,
  plans?: ReduxPlans,
  roles?: ReduxRoles,
  secureMessageThreads?: ReduxSecureMessageThreads,
  secureMessages?: ReduxSecureMessages,
  unreadSecureMessages?: ReduxSecureMessages,
  unreadSecureMessageCount?: ReduxUnreadSecureMessageCount,
  messageSettings?: ReduxMessageSettings,
  loanSettings?: ReduxLoanSettings,
  integrations?: ReduxIntegrations,
  subscription?: ReduxSubscription,
  users?: ReduxUsers,
  user?: ReduxUser,
  token?: ReduxToken,
  webHooks?: ReduxWebHooks,
  webHookLogs?: ReduxWebHookLogs,
  configured?: boolean,
  inactivityLogout?: boolean,
  loanDocumentCategories?: ReduxLoanDocumentCategories,
  hiddenMilestones?: ReduxHiddenMilestones,
  invoices?: ReduxInvoices,
  tickets?: ReduxTickets,
  dontPromptForMobileNumber: string[]|null
}

const initialState:ReduxState = {
  intl: {
    defaultLocale: 'en',
    locale: 'en',
    messages: translations.en.messages,
  },
  authenticated: false,
  configured: false,
  dontPromptForMobileNumber: []
};

export default initialState