import React, {Component} from 'react';
import IntlFormatter from "../intl";
import {ClipboardTextInput} from "./index";
import {injectIntl, WrappedComponentProps} from "react-intl";

type Props = {
  email: string
} & WrappedComponentProps

class LoanOfficerBorrowerSignupLink extends Component<Props> {
  render() {
    const { intl, email } = this.props;

    return (
      <ClipboardTextInput label={IntlFormatter.formatMessage(intl, 'borrower_sign_up_link')}
                          value={`https://${window.location.hostname}/register?loanOfficer=${encodeURIComponent(email)}`}
                          fullWidth={true}
                          helperText={IntlFormatter.formatMessage(intl, 'borrower_sign_up_link_help_text')} />
    );
  }
}

export default injectIntl(LoanOfficerBorrowerSignupLink);
