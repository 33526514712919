import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {Grid, Button, Link, withStyles, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, RadioGroup, SubmitButton, TextField} from "../../../components/index";
import {Link as RouterLink} from 'react-router-dom';
import Api from "../../../lib/Api";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ErrorUtil} from "../../../utils";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import * as Sentry from "@sentry/browser";
import {ErrorState} from "../../../types";
import {Mixpanel} from "mixpanel-browser";
import {ForgotPasswordRequest} from "@jerseydev/orca-loans";

export type ForgotPasswordSubmitData = {
  email:string,
  transport:ForgotPasswordRequest["transport"]
}

type Props = {
  mixpanel: Mixpanel,
  onSubmit?: (data:ForgotPasswordSubmitData) => void,
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

type State = {
  loading: boolean,
  email: string,
  transport: ForgotPasswordRequest["transport"],
  errors: ErrorState[],
  showTransports: boolean
}

class ForgotPasswordForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      email: '',
      transport: 'email',
      errors: [],
      showTransports: false
    };
  }

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    try {
      this.setState({ loading: true, errors: [] });
      const { email, showTransports } = this.state;
      if(showTransports) {
        await this.sendForgotPassword();
      } else {
        const optionsResult = await Api.forgotPasswordOptions(email);
        if(optionsResult.data.length > 1) {
          this.setState({showTransports: true, loading: false});
        } else {
          await this.sendForgotPassword();
        }
      }
    } catch (e) {
      this.setState({
        loading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  sendForgotPassword = async () => {
    const { email, transport } = this.state;
    await Api.forgotPassword({email, transport});
    const {mixpanel} = this.props;
    mixpanel.alias(email);
    mixpanel.track("Forgot password", { email });
    this.setState({ loading: false }, () => {
      if(this.props.onSubmit) {
        this.props.onSubmit({ email, transport });
      }
    });
  };

  onEmailChanged = () => {
    if(this.state.errors.length > 0) {
      this.setState({ errors: [] });
    }
  };

  onVerify = async(token:string) => {
    try {
      await Api.verifyRecaptchaToken({ token });
    } catch (e) {
      Sentry.withScope(() => {
        Sentry.captureException(e);
      });
    }
  };

  onTransportChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({transport: event.target.value as State["transport"]});
  }

  render() {

    const { intl, classes } = this.props;
    const { email, transport, loading, errors, showTransports } = this.state;

    return (
      <div>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
          <Form onSubmit={this.onSubmit}>
            <ErrorList errors={errors}
                     className={classes.mv2}
                       onClose={() => { this.setState({ errors: [] }); } } />
            <div>
              <TextField name="email"
                         label={IntlFormatter.formatMessage(intl, 'email')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'email', this.onEmailChanged)}
                         value={email}
                         fullWidth={true}
                         inputProps={{
                           autoCapitalize: 'none',
                         }}
                         validators={['required', 'isEmail']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
            </div>
            {showTransports &&
              <div className={classes.mt2}>
                <RadioGroup name="transport"
                            itemValueProp="value"
                            value={transport}
                            label={IntlFormatter.formatMessage(intl, 'how_receive_notification')}
                            onChange={this.onTransportChange}
                            items={[
                              {label: IntlFormatter.formatMessage(intl, 'email'), value: 'email'},
                              {label: IntlFormatter.formatMessage(intl, 'text_message'), value: 'sms'}
                            ]}
                            validators={['required']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required')
                            ]} row />
              </div>
            }
            <div>
              <GoogleReCaptcha onVerify={this.onVerify} />
            </div>
            <div className={classes.mt2}>
              <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <Link component={RouterLink} to="/login">
                    <Button>
                      <FormattedMessage id="cancel" />
                    </Button>
                  </Link>
                </Grid>
                <Grid item>
                  <SubmitButton loading={loading}>
                    <FormattedMessage id="submit" />
                  </SubmitButton>
                </Grid>
              </Grid>
            </div>
          </Form>
        </GoogleReCaptchaProvider>
      </div>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(ForgotPasswordForm));
