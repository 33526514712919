import palette from '../jss/palette';
import typography from '../jss/typography';

export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
    '& a': {
      color: 'inherit'
    }
  }
};
