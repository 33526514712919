import React, {MouseEvent} from 'react';
import {
  Button,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  withStyles,
  MenuItem,
  ListItemIcon,
  Menu,
  Typography,
  Tooltip, WithStyles
} from '@material-ui/core';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {ErrorUtil, LoanUtil} from "../../../utils";
import _ from 'lodash';
import {LiabilityForm} from '../forms';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {
  DeleteDialog,
  Dialog,
  Fab,
  Section
} from "../../../components";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {
  BorrowerLiability,
  LoanApplication,
  Borrower, LoanApplicationUpdateRequest
} from "@jerseydev/orca-loans";
import {ActionProps, DialogState} from "../../../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";

type Props = {
  loanApplication: LoanApplication,
  borrower: Borrower,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  selectedItem: BorrowerLiability|null,
  formDialogOpen: boolean,
  deleteDialog: DialogState,
  actionMenuEl: Element|null
}

class LiabilitiesList extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      selectedItem: null,
      formDialogOpen: false,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      actionMenuEl: null
    };
  }

  onShowActionMenu = (event:MouseEvent, item:BorrowerLiability) => {
    this.setState({
      actionMenuEl: event.currentTarget,
      selectedItem: item
    });
  };

  onShowFormClick = (event:MouseEvent) => {
    event.preventDefault();
    this.setState({
      formDialogOpen: true,
      actionMenuEl: null
    });
  };

  onHideFormClick = () => {
    this.setState({
      formDialogOpen: false,
      selectedItem: null
    });
  };

  onEditClick = () => {
    this.setState({
      formDialogOpen: true,
      actionMenuEl: null
    });
  };

  onFormSubmit = () => {
    this.setState({
      formDialogOpen: false,
      selectedItem: null,
    });
  };

  onDeleteClick = () => {
    this.setState({
      deleteDialog: {
        open: true,
        loading: false,
        errors: []
      },
      actionMenuEl: null
    });
  };

  onDeleteClose = () => {
    this.setState({
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      selectedItem: null
    });
  };

  onCloseActionMenu = () => {
    this.setState({
      actionMenuEl: null,
      selectedItem: null
    });
  };

  onDeleteConfirm = async () => {
    try {
      const {borrower, loanApplication} = this.props;
      const {selectedItem} = this.state;
      if(loanApplication.borrowers && selectedItem) {
        const borrowerIndex = loanApplication.borrowers.findIndex(b => b._id === borrower._id);
        if(loanApplication.borrowers[borrowerIndex]) {
          const deleteDialog = _.clone(this.state.deleteDialog);
          deleteDialog.loading = true;
          this.setState({deleteDialog});

          if(borrower.liabilities) {
            const index = borrower.liabilities.findIndex(a => a._id === selectedItem._id);
            await this.props.updateLoanApplication(loanApplication._id, [`borrowers[${borrowerIndex}].liabilities[${index}]`], {merge:'delete'}).send();
          }
        }
      }
      this.setState({
        deleteDialog: {
          open: false,
          loading: false,
          errors: []
        },
        selectedItem: null
      });
    } catch (e) {
      this.setState({
        deleteDialog: {
          open: true,
          loading: false,
          errors: ErrorUtil.formatErrors(e)
        }
      });
    }
  };


  render() {

    const { intl, classes, loanApplication, borrower } = this.props;
    const { selectedItem, formDialogOpen, deleteDialog, actionMenuEl } = this.state;
    const hasPendingAssetReports = LoanUtil.hasPendingAssetReports(loanApplication);

    return (
      <div>
        <Dialog open={formDialogOpen}
                title={IntlFormatter.formatMessage(intl, selectedItem ? 'edit_liability_for' : 'add_liability_for', { name: `${borrower.firstName} ${borrower.lastName}`}) }
                icon={<Icon>money_off</Icon>}
                color="primaryAlt"
                onClose={this.onHideFormClick}
                fullWidth={true}
                maxWidth="sm">
          <div>
            <LiabilityForm loanApplication={loanApplication}
                           borrower={borrower}
                           liability={selectedItem}
                           onSubmit={this.onFormSubmit}
                           actions={[
                             <Button onClick={this.onHideFormClick}>
                                <FormattedMessage id="cancel" />
                             </Button>
                           ]}/>
          </div>
        </Dialog>

        <DeleteDialog open={deleteDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'delete_liability')}
                      item={selectedItem ? selectedItem.name || selectedItem.financialInstitution : ''}
                      loading={deleteDialog.loading}
                      errors={deleteDialog.errors}
                      onCancel={this.onDeleteClose}
                      onSubmit={this.onDeleteConfirm} />


        <Menu anchorEl={actionMenuEl}
              open={Boolean(actionMenuEl)}
              onClose={this.onCloseActionMenu}>
          {selectedItem && !selectedItem.verified &&
          <MenuItem onClick={this.onEditClick}>
            <ListItemIcon>
              <Icon>mode_edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
          </MenuItem>
          }
          <MenuItem onClick={this.onDeleteClick} disabled={hasPendingAssetReports}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')} />
          </MenuItem>
        </Menu>

        <Section title={IntlFormatter.formatMessage(intl, 'liabilities')}
                 subtitle={IntlFormatter.formatMessage(intl, 'liabilities_description')}
                 actions={
                   <Fab color="primary"
                        size="small"
                        flat
                        rounded
                        onClick={this.onShowFormClick}>
                     <Icon>add</Icon>
                   </Fab>
                 }>

          {borrower.liabilities && borrower.liabilities.length === 0 &&
          <Button variant="outlined"
                  color="primary"
                  onClick={this.onShowFormClick}>
            <FormattedMessage id="add_liability" />
          </Button>
          }

          {borrower.liabilities && borrower.liabilities.length > 0 &&
          <div className={classes.mb2}>
            <List>
              {borrower.liabilities.map((asset, i) => {
                let label;
                if(asset.name) {
                  label = `${asset.name} (${asset.financialInstitution})`;
                } else {
                  label = `${asset.financialInstitution} ${asset.type ? IntlFormatter.formatMessage(intl, `liability_type_${asset.type}`) : asset.accountNumber}`;
                }
                return (
                  <ListItem key={i}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="body1">
                          {label}
                        </Typography>
                        <Typography variant="body2">
                          {asset.balance ? asset.balance.toMoney() : ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div className={classes.rowCenter}>
                          {asset.verified &&
                          <div className={classes.mr1}>
                            <Tooltip title={IntlFormatter.formatMessage(intl, 'verified_liability')}>
                              <Icon className={classes.success}>verified_user</Icon>
                            </Tooltip>
                          </div>
                          }
                          <IconButton onClick={event => this.onShowActionMenu(event, asset)}>
                            <Icon>more_vert</Icon>
                          </IconButton>
                        </div>
                      </Grid>
                    </Grid>
                  </ListItem>
                )
              })}
            </List>
          </div>
          }
        </Section>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationUpdateRequest, queryParams?:any) {
    return dispatch(updateLoanApplication(id, data, queryParams));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LiabilitiesList)));
