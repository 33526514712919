import React, {Component, RefObject} from 'react';
import {
  Icon,
  IconButton,
  Grow,
  Paper,
  ClickAwayListener,
  Popper
} from '@material-ui/core';
import {connect} from "react-redux";
import {ErrorList, LoanMilestoneList} from "./index";
import IntlFormatter from "../intl";
import Item from "./Item";
import {injectIntl, WrappedComponentProps} from "react-intl";
import _ from 'lodash';
import {LoanApplicationMilestone, LoanApplication2009, LoanApplication} from "@jerseydev/orca-loans";
import {ReduxState} from "../data/initialState";
import {ActionProps, ErrorState, ReduxHiddenMilestones} from "../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {getHiddenMilestones} from "../actions/hiddenMilestones";
import {ErrorUtil, ReduxUtil} from "../utils";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  hiddenMilestones: ReduxHiddenMilestones,
  getHiddenMilestones: ActionProps["getHiddenMilestones"]
} & WrappedComponentProps

type State = {
  loading: boolean,
  popperOpen: boolean,
  errors: ErrorState[]
}

class LoanMilestoneButton extends Component<Props, State> {
  buttonRef:RefObject<HTMLButtonElement>;

  constructor(props:Props) {
    super(props);
    this.state = {
      loading: true,
      popperOpen: false,
      errors: []
    };

    this.buttonRef = React.createRef();
  }

  componentDidMount = async () => {
    try {
      if(!ReduxUtil.hasData(this.props.hiddenMilestones) && !ReduxUtil.isFetching(this.props.hiddenMilestones)) {
        await this.props.getHiddenMilestones().send();
      }

      this.setState({loading:false});
    } catch (e) {
      this.setState({loading:false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  isMilestoneVisible = (milestone:LoanApplicationMilestone) => {
    const { loanApplication, hiddenMilestones } = this.props;
    let milestoneVisible = true;

    if(loanApplication.loan && loanApplication.loan.loanPurpose && loanApplication.loan.loanType && ReduxUtil.hasData(hiddenMilestones)) {
      milestoneVisible = !hiddenMilestones.data.find(m => m.name === milestone.name && m.loanTypes.includes(loanApplication.loan!.loanType!) && m.loanPurposes.includes(loanApplication.loan!.loanPurpose!));
    }

    return milestoneVisible;
  };

  onTogglePopper = () => {
    this.setState({ popperOpen: !this.state.popperOpen });
  };

  onClosePopper = () => {
    this.setState({ popperOpen: false });
  };

  getCurrentMilestone = () => {
    const { loanApplication } = this.props;
    let currentMilestone = loanApplication.milestones ? loanApplication.milestones.find(m => m.current) : null;
    if(currentMilestone) {
      if(!this.isMilestoneVisible(currentMilestone)) {
        currentMilestone = null;
        const currentIndex = loanApplication.milestones!.findIndex(m => m.current);
        if(currentIndex > 0) {
          // check if there is another completed milestone thats visible
          let completedMilestones = _.orderBy(loanApplication.milestones!.filter(m => m.completed), 'weight', 'desc');
          for(let i=0;i<completedMilestones.length;i++) {
            if(this.isMilestoneVisible(completedMilestones[i])) {
              currentMilestone = completedMilestones[i];
              break;
            }
          }
        }
      }
    }

    return currentMilestone;
  };

  render() {
    const { intl, loanApplication } = this.props;
    const { popperOpen, loading, errors } = this.state;
    const currentMilestone = this.getCurrentMilestone();

    if(!currentMilestone || loading) {
      return null;
    }

    if(!loading && errors.length > 0) {
      return <ErrorList errors={errors} />
    }

    return (
      <div>
        <Item label={IntlFormatter.formatMessage(intl, 'milestone')} icon={
          <IconButton ref={this.buttonRef}
                      size="small"
                      onClick={this.onTogglePopper}>
            <Icon>data_usage</Icon>
          </IconButton>
        }>
          {currentMilestone.name}
        </Item>
        <Popper open={popperOpen}
                anchorEl={this.buttonRef.current}
                role={undefined}
                transition
                placement="left"
                style={{zIndex: 1000}}
                disablePortal>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.onClosePopper}>
                  <LoanMilestoneList loanApplication={loanApplication} />
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    hiddenMilestones: state.hiddenMilestones
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getHiddenMilestones() {
    return dispatch(getHiddenMilestones());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoanMilestoneButton));
