import React from "react";
import IntlFormatter from "../../../intl";
import {useIntl} from "react-intl";
import {Form as PricingFormProps} from '../forms/PricingForm';
import PricingSearchSummaryGridItem from "./PricingSearchSummaryGridItem";
import {Grid} from "@material-ui/core";

type Props = {
  searchCriteria: PricingFormProps
}

const PricingSearchSummary = (props:Props) => {
  const {searchCriteria} = props;
  const intl = useIntl();
  let loanTypesText = IntlFormatter.formatMessage(intl, `loan_types_${searchCriteria.loanType}`);

  let loanTermsText:string;
  if(searchCriteria.loanTerms.length === 0) {
    loanTermsText = IntlFormatter.formatMessage(intl, 'none');
  } else {
    const loanTerms:string[] = [];
    searchCriteria.loanTerms.forEach(loanTerm => {
      loanTerms.push(IntlFormatter.formatMessage(intl, 'num_yr', { num: loanTerm / 12 }));
    });
    loanTermsText = loanTerms.join(', ');
  }

  let amortizationTypesText:string;
  if(searchCriteria.amortizationTypes.length === 0) {
    amortizationTypesText = IntlFormatter.formatMessage(intl, 'none');
  } else {
    const amortizationTypes:string[] = [];
    searchCriteria.amortizationTypes.forEach(amortizationType => {
      amortizationTypes.push(IntlFormatter.formatMessage(intl, `amortization_types_${amortizationType}`));
    });
    amortizationTypesText = amortizationTypes.join(', ');
  }

  if(!searchCriteria.armFixedTerms) {
    searchCriteria.armFixedTerms = [];
  }
  let armFixedTermsText:string;
  if(searchCriteria.armFixedTerms.length === 0) {
    armFixedTermsText = IntlFormatter.formatMessage(intl, 'none');
  } else {
    const armFixedTerms:string[] = [];
    searchCriteria.armFixedTerms.forEach(armFixedTerm => {
      armFixedTerms.push(IntlFormatter.formatMessage(intl, 'num_mo', { num: armFixedTerm }));
    });
    armFixedTermsText = armFixedTerms.join(', ');
  }

  return (
    <Grid container spacing={2}>
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'appraisal_value')}
                                    value={parseInt(searchCriteria.appraisalValue).toMoney()}
                                    icon="attach_money" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'sale_price')}
                                    value={parseInt(searchCriteria.salePrice).toMoney()}
                                    icon="attach_money" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'loan_amount')}
                                    value={parseInt(searchCriteria.loanAmount).toMoney()}
                                    icon="attach_money" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'desired_lock_period')}
                                    value={`${searchCriteria.desiredLockPeriod} ${IntlFormatter.formatMessage(intl, 'days')}`}
                                    icon="calendar_today" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'representative_fico')}
                                    value={searchCriteria.representativeFICO}
                                    icon="score" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'dti_ratio')}
                                    value={searchCriteria.dtiRatio}
                                    icon="poll" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'months_of_reserves')}
                                    value={searchCriteria.monthsOfReserves}
                                    icon="date_range" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'county')}
                                    value={searchCriteria.county}
                                    icon="location_on" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'loan_type')}
                                    value={loanTypesText}
                                    icon="description" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'loan_term')}
                                    value={loanTermsText}
                                    icon="schedule" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'amortization_type')}
                                    value={amortizationTypesText}
                                    icon="dynamic_feed" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'arm_fixed_term')}
                                    value={armFixedTermsText}
                                    icon="table_chart" />
      <PricingSearchSummaryGridItem label={IntlFormatter.formatMessage(intl, 'first_time_home_buyer')}
                                    value={IntlFormatter.formatMessage(intl, searchCriteria.firstTimeHomeBuyer ? 'yes' : 'no')}
                                    icon="how_to_reg" />
    </Grid>
  )
};

export default PricingSearchSummary;