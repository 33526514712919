import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  Grid, WithStyles,
  withStyles,
} from "@material-ui/core";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ErrorList, PageTitle, Plan, NavPills, Alert} from "../../../components";
import {ErrorUtil, ReduxUtil} from "../../../utils";
import {Redirect} from "react-router-dom";
import {Plan as PlanObj} from "@jerseydev/orca-loans";
import {ReduxPlans, ReduxSubscription, ActionProps, ErrorState} from "../../../types";
import {ReduxState} from "../../../data/initialState";

type Props = {
  routeProps: ActionProps,
  subscription: ReduxSubscription,
  plans: ReduxPlans
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  selectedPlan: PlanObj|null,
  subscribeDialogOpen: boolean,
  selectedInterval: 'month'|'year',
  errors: ErrorState[],
  redirectTo?: string|null
}

class PlansPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      selectedPlan: null,
      subscribeDialogOpen: false,
      selectedInterval: 'month',
      errors: [],
      redirectTo: null
    };
  }

  componentDidMount = async () => {
    try {
      await this.props.routeProps.getPlans().send();
      await this.props.routeProps.getSubscription().send();

      this.setState({ pageLoaded: true });
    } catch(err) {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(err) });
    }
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'plans');
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="card_membership" />
    )
  };

  onPriceIntervalClick = () => {
    this.setState({ selectedInterval: this.state.selectedInterval === 'month' ? 'year' : 'month' });
  };

  render() {

    const { intl, classes, subscription, plans } = this.props;
    const { pageLoaded, loading, errors,  redirectTo, selectedInterval } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo}/>
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   loading={loading}
                   pageLoaded={pageLoaded}>
        <div className={classes.content}>
          <ErrorList errors={errors} onClose={() => { this.setState({ errors: [] })}}/>
          {ReduxUtil.isIdle(subscription) && !subscription.data &&
            <div className={classes.mb2}>
              <Alert severity="warning">
                <FormattedMessage id="your_subscription_is_not_active" />
              </Alert>
            </div>
          }

          <div className={classes.mb2}>
            <NavPills
              alignCenter
              color="rose"
              tabs={[
                { tabButton: IntlFormatter.formatMessage(intl, 'month')},
                { tabButton: IntlFormatter.formatMessage(intl, 'year')}
              ]}
              onChange={this.onPriceIntervalClick}
            />
          </div>

          {(!loading && ReduxUtil.hasData(plans) && ReduxUtil.hasData(subscription)) &&
            <Grid container>
              {plans.data.map((plan, i) => {
                return (
                  // @ts-ignore
                  <Grid key={i} item xs={12} sm={12} md={Math.floor(12 / plans.data.length)}>
                    <Plan plan={plan}
                          color={subscription.data.plan.product.id === plan.id ? 'rose' : 'default'}
                          subscription={subscription.data}
                          interval={selectedInterval} />
                  </Grid>
                )
              })}
            </Grid>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    subscription: state.subscription,
    plans: state.plans
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(PlansPage)));
