import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  text: {
    marginRight: theme.spacing(1)
  }
});

export default styles;
