import React from "react";
import {
  ValidatorForm,
  ValidatorFormProps,
  ValidatorComponentProps,
  TextFieldValidatorComponentProps,
  ValidatorComponent,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator';

const Form = (props:ValidatorFormProps) => {
  const {debounceTime, ...rest} = props;
  return (
    <ValidatorForm debounceTime={debounceTime ? debounceTime : 1500} {...rest} />
  )
};

export default Form;
export {ValidatorForm, ValidatorComponent, TextValidator, SelectValidator}
export type {ValidatorFormProps, ValidatorComponentProps, TextFieldValidatorComponentProps}