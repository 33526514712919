import React, {Component} from 'react';
import {Typography, Paper, withStyles, WithStyles} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {Page} from '../../../layouts';
import {AppLogo, Alert} from "../../../components";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import clsx from "clsx";

type Props = WrappedComponentProps
  & WithStyles<typeof pageStyles>

class AppNotFoundPage extends Component<Props> {
  render() {
    const { intl, classes } = this.props;
    return (
      <Page pageTitle={IntlFormatter.formatMessage(intl, 'not_found')}
            loading={false}>
        <div className={classes.houseBg1}>
          <div className={classes.bgOverlay}>
            <div className={classes.center}>
              <div className={classes.mt5}>
                <Paper elevation={2}>
                  <div className={clsx(classes.p3, classes.center)}>
                    <AppLogo color="dark" />
                  </div>
                  <div className={classes.p3}>
                    <Alert severity="warning">
                      <Typography variant="body1">
                        <FormattedMessage id="app_not_found_message"
                                          values={{ domain: window.location.hostname }} />
                      </Typography>
                    </Alert>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(AppNotFoundPage));

