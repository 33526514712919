import React, { Component, MouseEvent } from 'react';
import {
  WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/dotStyles';
import clsx from "clsx";

type DefaultProps = {
  color: 'default'|'info'|'primary'|'secondary'|'success'|'danger'
}

type Props = {
  onClick?: (event:MouseEvent) => void
} & Partial<DefaultProps>
  & WithStyles<typeof styles>

class Dot extends Component<Props> {
  static defaultProps:DefaultProps = {
    color: 'default',
  };

  onClick = (event:MouseEvent) => {
    if(this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    const { classes,  color } = this.props;

    return (
      <div className={classes.root} onClick={this.onClick}>
        <div className={clsx(classes.dot, classes[color!])} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Dot);
