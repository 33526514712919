import {
  defaultFont,
  drawerWidth, hexToRgb,
  transition,
  menuIconOpacity,
  menuIconMinWidth,
  menuTransition,
} from "../index";
import palette from "../palette";
import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "./pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  mainPanel: {
    overflow: "auto",
    position: "relative",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    flexGrow: 1
  },
  mainPanelWithDrawer: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    float: "right",
    ...transition,
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.dark) + ", .1)",
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    }
  },
  pageContainerWithAppBar: {
    paddingTop: '65px',
    [theme.breakpoints.down("xs")]: {
      paddingTop: '45px',
    }
  },
  titleBar: {
    backgroundColor: '#FCFDFE',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    color: theme.palette.neutral.mainDark,
    position: 'relative',
    zIndex: 2
  },
  pageTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down("xs")]: {
      '& h1': {
        fontSize: 20,
        lineHeight: '24px'
      }
    },
  },
  breadcrumbs: {
    marginBottom: theme.spacing(3),
    color: theme.palette.neutral.main,
    [theme.breakpoints.down("sm")]: {
      display: 'none',
      '& p, a' : {
        fontSize: 11,
        lineHeight: '14px'
      }
    },
  },
  info: {
    color: theme.palette.info.main
  },
  success: {
    color: theme.palette.success.light
  },
  warning: {
    color: theme.palette.warning.main
  },
  danger: {
    color: theme.palette.danger.main
  },
  dashboardButtonContainer: {
    paddingTop: theme.spacing(2)
  },
  mt2: {
    marginTop: theme.spacing(2)
  },
  p2: {
    padding: theme.spacing(2)
  },
  dashboardHeader: {
    borderBottom: `solid 1px ${theme.palette.neutral.main}`,
  },
  dashboardLinkList: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
    listStyle: "none",
    position: "unset",
    color: theme.palette.white,
  },
  dashboardLinkListItem: {
    transition: menuTransition,
    "&:hover": {
      backgroundColor: palette.menu.backgroundAlt,
      '& $dashboardLinkItemIcon': {
        color: "rgba(" + hexToRgb(palette.menu.contrastTextAlt!) + ", 1)",
      },
      '& $dashboardLinkItemText': {
        color: palette.menu.contrastTextAlt,
      }
    }
  },
  dashboardLinkItemIconContainer: {
    minWidth: menuIconMinWidth
  },
  dashboardLinkItemIcon: {
    width: 24,
    height: 30,
    fontSize: "22px",
    lineHeight: "30px",
    float: "left",
    marginLeft: 8,
    marginRight: 10,
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb(palette.menu.contrastTextAlt!) + ", " + menuIconOpacity + ")",
    transition: menuTransition
  },
  dashboardLinkItemText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "16px",
    fontWeight: 'bold',
    color: palette.menu.contrastText
  },
  errorContainer: {
    margin: theme.spacing(2)
  },
  loanOfficerContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  loanOfficerHeader: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.white) + ", .1)",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    textTransform: 'uppercase'
  },
  loanOfficerContent: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  textCenter: {
    textAlign: 'center'
  },
  progressBar: {
    marginTop: Math.round(theme.spacing(1) / 2),
    [theme.breakpoints.up("md")]: {
      marginLeft: 78
    }
  },
  pageLoader: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  menuWarningIcon: {
    color: "rgba(" + hexToRgb(theme.palette.yield.main) + ", .65)"
  }
});

export default styles;
