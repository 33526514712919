import React, {Component} from 'react';
import {
  WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/badgeStyles';
import clsx from "clsx";

type DefaultProps = {
  color: 'default'|'info'|'infoAlt'|'primary'|'primaryAlt'|'secondary'|'secondaryAlt'|'success'|'successAlt'|'warn'|'warnAlt'|'yield'|'danger'|'dangerAlt'
}

type Props = DefaultProps & WithStyles<typeof styles>

class Badge extends Component<Props> {
  static defaultProps:DefaultProps = {
    color: 'default',
  };

  render() {
    const { classes, color, children } = this.props;

    return (
      <span className={clsx(classes.root, classes[color])}>
        {children}
      </span>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Badge);
