import {Account, AccountRequest} from "@jerseydev/orca-loans";
import {SetupRecommendation} from "../types";
import _ from "lodash";

export default class AccountUtil {
  static addSetupRecommendationToRequest = (recommendationKey:SetupRecommendation, accountRequest:AccountRequest, account:Account, used:boolean = true): AccountRequest => {
    if(account.setupRecommendations) {
      const recommendationIndex = account.setupRecommendations.findIndex(r => r.key === recommendationKey && r.used !== used);
      if(recommendationIndex > -1) {
        const setupRecommendations = _.cloneDeep(account.setupRecommendations);
        setupRecommendations[recommendationIndex].used = used;
        accountRequest.setupRecommendations = setupRecommendations;
      }
    }
    return accountRequest;
  };

  static isSetupRecommendationUsed = (recommendationKey:SetupRecommendation, account:Account):boolean => {
    if(account.setupRecommendations) {
      const recommendation = account.setupRecommendations.find(r => r.key === recommendationKey);
      return recommendation ? recommendation.used : false;
    }

    return false;
  }
}
