import {Typography, Grid, withStyles, Link, WithStyles} from "@material-ui/core";
import React from "react";
import IntlFormatter from "../intl";
import styles from "../theme/jss/layouts/pageStyles";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {TextUtil} from "../utils";
import {SubTitle, Well} from "./index";
import {Link as RouterLink} from "react-router-dom";
import {
  LoanApplication,
  Borrower
} from "@jerseydev/orca-loans";
import pageStyles from "../theme/jss/layouts/pageStyles";

type Props = {
  loanApplication: LoanApplication,
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

function LoanGiftsSummary(props:Props) {
  const { intl, loanApplication, classes } = props;

  if(!loanApplication.borrowers || loanApplication.borrowers.length === 0) {
    return (
      <Well color="warn">
        <Typography variant="body1">
          <FormattedMessage id="no_gifts_found" />
        </Typography>
      </Well>
    );
  }

  return (
    <Grid container spacing={2}>
      {loanApplication.borrowers.map((borrower:Borrower, borrowerIndex:number) => {
        const smMdSize = loanApplication.borrowers && loanApplication.borrowers.length > 1 ? Math.floor(12 / loanApplication.borrowers.length) : 6;
        return (
          // @ts-ignore
          <Grid key={borrowerIndex} item
                xs={12}
                sm={smMdSize}
                md={smMdSize}>

            <div className={classes.mb2}>
              <SubTitle title={borrower.fullName || IntlFormatter.formatMessage(intl, 'borrower')} />
            </div>

            {(!borrower.gifts || (borrower.gifts && borrower.gifts.length === 0)) &&
            <div>
              <div className={classes.mb2}>
                <Well color="warn">
                  <Typography variant="body1">
                    <FormattedMessage id="no_gifts_found" />. &nbsp;
                    {!loanApplication.completed &&
                    <Link component={RouterLink} to="/apply/gifts">
                      <FormattedMessage id="add_gift"/>
                    </Link>
                    }
                  </Typography>
                </Well>
              </div>
            </div>
            }

            {borrower.gifts &&
            <div>
              {borrower.gifts.map((gift, i) => {
                return (
                  <div key={i} className={classes.mb2}>
                    <Typography variant="subtitle1" gutterBottom>
                      {IntlFormatter.formatMessage(intl, gift.source ? `gift_source_${gift.source}` : 'gift')}
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography variant="caption">
                          <FormattedMessage id="type"/>
                        </Typography>
                        <Typography variant="body1">
                          {gift.assetType &&
                          <FormattedMessage id={`gift_asset_type_${gift.assetType}`} />
                          }
                          {!gift.assetType &&
                          <FormattedMessage id="na" />
                          }
                        </Typography>
                      </Grid>
                      {gift.value &&
                        <Grid item>
                          <Typography variant="caption">
                            <FormattedMessage id="value"/>
                          </Typography>
                          <Typography variant="body1">
                            {TextUtil.getMoneyText(gift.value, intl)}
                          </Typography>
                        </Grid>
                      }
                      {gift.deposited &&
                        <Grid item>
                          <Typography variant="caption">
                            <FormattedMessage id="deposited"/>
                          </Typography>
                          <Typography variant="body1">
                            {TextUtil.getBoolText(gift.deposited, intl)}
                          </Typography>
                        </Grid>
                      }
                    </Grid>
                  </div>
                )
              })}
            </div>
            }
          </Grid>
        )
      })}
    </Grid>
  );
}

export default withStyles(styles)(injectIntl(LoanGiftsSummary));