import {persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {intlReducer} from 'react-intl-redux';
import * as appReducer from './app';
import * as accountReducer from './account';
import * as authReducer from './auth';
import * as usersReducer from './users';
import * as loanApplicationReducer from './loanApplication';
import * as loanApplicationsReducer from './loanApplications';
import * as loanOfficersReducer from './loanOfficers';
import * as branchesReducer from './branches';
import * as rolesReducer from './roles';
import * as settingsReducer from './settings';
import * as secureMessageThreadsReducer from './secureMessageThreads';
import * as secureMessagesReducer from './secureMessages';
import * as creditCardsReducer from './creditCards';
import * as bankAccountsReducer from './bankAccounts';
import * as plansReducer from './plans';
import * as subscriptionReducer from './subscription';
import * as notificationsReducer from './notifications';
import * as oauthClientsReducer from './oauthClients';
import * as webHooksReducer from './webHooks';
import * as designReducer from './design';
import * as integrationsReducer from './integrations';
import * as loanDocumentCategoriesReducer from './loanDocumentCategories';
import * as hiddenMilestonesReducer from './hiddenMilestones';
import * as invoicesReducer from './invoices';
import * as ticketsReducer from './tickets';
import {ReduxState} from "../data/initialState";
import {FixMeLater, ReduxActionData} from "../types";

//import Queue from 'redux-queue';

const reduxPersistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'dashboard',
    'loanApplication',
    'loanApplications',
    'users',
    'secureMessages',
    'unreadSecureMessages',
    'creditCards',
    'bankAccounts',
    'intl',
    'inactivityLogout',
    'branches',
    'loanOfficers',
    'notifications',
    'oauthClients'
  ]
};

/*if(process.env.NODE_ENV === 'development') {
  reduxPersistConfig.blacklist.push('intl')
}*/

const reducers = persistCombineReducers(reduxPersistConfig, {
  ...appReducer,
  ...accountReducer,
  ...authReducer,
  ...usersReducer,
  ...rolesReducer,
  ...loanApplicationReducer,
  ...loanApplicationsReducer,
  ...loanOfficersReducer,
  ...branchesReducer,
  ...settingsReducer,
  ...secureMessageThreadsReducer,
  ...secureMessagesReducer,
  ...creditCardsReducer,
  ...bankAccountsReducer,
  ...plansReducer,
  ...subscriptionReducer,
  ...notificationsReducer,
  ...oauthClientsReducer,
  ...webHooksReducer,
  ...designReducer,
  ...integrationsReducer,
  ...loanDocumentCategoriesReducer,
  ...hiddenMilestonesReducer,
  ...invoicesReducer,
  ...ticketsReducer,
  ...{intl: intlReducer}
});

const rootReducer = (state:ReduxState, action:ReduxActionData) => {

  if(action.type === 'LOGOUT') {
    //console.log(action);
    //storage.removeItem('persist:user');
    //storage.removeItem('persist:token');
    state = {
      ...action.payload,
      _persist: {
        version: -1,
        rehydrated: true
      }
    };
  }

  return reducers(state as FixMeLater, action)
};

export default rootReducer;