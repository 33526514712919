import React, {ChangeEvent, MouseEvent} from 'react';
import Form from './Form';
import {
  Grid,
  Button,
  withStyles, IconButton, Icon, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../intl';
import BaseForm from "../forms/BaseForm";
import {ErrorList, SubmitButton, TextField, Alert} from "../components";
import moment from "moment-timezone";
import _ from 'lodash';
import {connect} from "react-redux";
import {inviteApplicant} from "../actions/users";
import {AclUtil, ErrorUtil, UserUtil} from "../utils";
import pageStyles from "../theme/jss/layouts/pageStyles";
import {EnableDeletedUserForm} from "./index";
import Api from "../lib/Api";
import {Mixpanel} from 'mixpanel-browser';
import {ActionResponse, ReduxUser, ErrorState} from "../types";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {InviteBorrowerRequest, User} from "@jerseydev/orca-loans";

type Props = {
  mixpanel: Mixpanel,
  onSubmit: (data:User) => void,
  onCancel: () => void,
  user: ReduxUser,
  inviteApplicant: (data:InviteBorrowerRequest) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  firstName: string,
  lastName: string,
  email: string,
  additionalProps?: {
    defaultLoanOfficer: string
  }
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  existingUser?: User
}

class InviteApplicantForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        firstName: '',
        lastName: '',
        email: ''
      },
      errors: []
    };

  }

  onUserFormSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    const { user } = this.props;
    this.setState({ loading: true, errors: [] });

    const {firstName, lastName, email} = this.state.form;
    const data:InviteBorrowerRequest = {
      firstName,
      lastName,
      email,
      timezone: moment.tz.guess()
    };

    if(UserUtil.isLoanOfficer(user.data)) {
      data.additionalProps = { defaultLoanOfficer: user.data.additionalProps!.loanOfficer!._id };
    }

    let formValid = false;
    try {
      const emailResult = await Api.getUserByEmail(data.email, { deleted: 'all' });
      this.setState({ loading: false, existingUser: _.cloneDeep(emailResult.data) });
    } catch (e) {
      if(e.response && e.response.status === 404) {
        formValid = true;
      } else {
        this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
      }
    }

    if(formValid) {
      try {
        let result = await this.props.inviteApplicant(data).send();
        this.props.mixpanel.track("Borrower Invite", { email: this.state.form.email, initial: true });
        this.setState({ loading: false }, () => {
          this.props.onSubmit(_.cloneDeep(result.data));
        });
      } catch (e) {
        this.setState({
          loading: false,
          errors: ErrorUtil.formatErrors(e)
        });
      }
    }
  };

  onDeletedUserFormSubmit = () => {
    if(this.state.existingUser) {
      this.props.onSubmit(_.cloneDeep(this.state.existingUser));
    }
  };

  onDeletedUserFormCancel = () => {
    this.setState({ existingUser: undefined });
  };

  onUserExistsAlertClose = () => {
    this.setState({ existingUser: undefined });
  };

  onEmailTextChange = (event:ChangeEvent<{value:string}>) => {
    const form = _.cloneDeep(this.state.form);
    form.email = event.target.value;
    this.setState({ form, existingUser: undefined });
  };

  render() {

    const { intl, classes, user, mixpanel } = this.props;
    const { form, loading, errors, existingUser } = this.state;

    return (
      <div>
        {existingUser &&
        <div className={classes.mb2}>
          {existingUser.deleted &&
            <div>
              {(AclUtil.isOwner(user.data) && AclUtil.hasRole(existingUser, 'ROLE_MEMBER')) &&
                <EnableDeletedUserForm user={existingUser}
                                       mixpanel={mixpanel}
                                       onSubmit={this.onDeletedUserFormSubmit}
                                       onCancel={this.onDeletedUserFormCancel} />
              }

              {!AclUtil.hasRole(existingUser, 'ROLE_MEMBER') &&
                <Alert severity="danger" action={
                  <IconButton onClick={this.onUserExistsAlertClose} size="small">
                    <Icon>cancel</Icon>
                  </IconButton>
                }>
                  <FormattedMessage id="user_has_been_archived_please_enable" values={{ user: existingUser.email }} />
                </Alert>
              }
            </div>
          }
          {!existingUser.deleted &&
          <Alert severity="danger" action={
            <IconButton onClick={this.onUserExistsAlertClose} size="small">
              <Icon>cancel</Icon>
            </IconButton>
          }>
            <FormattedMessage id="user_exists_select_another_email" values={{ user: existingUser.email }} />
          </Alert>
          }
        </div>
        }

        <Form onSubmit={this.onUserFormSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); }}/>

          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField name="firstName"
                           label={IntlFormatter.formatMessage(intl, 'first_name')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.firstName')}
                           value={form.firstName}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField name="lastName"
                           label={IntlFormatter.formatMessage(intl, 'last_name')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.lastName')}
                           value={form.lastName}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField name="email"
                         label={IntlFormatter.formatMessage(intl, 'email')}
                         onChange={this.onEmailTextChange}
                         value={form.email}
                         fullWidth={true}
                         validators={['required', 'isEmail']}
                         errorMessages={[
                           IntlFormatter.formatMessage(intl, 'validation_required'),
                           IntlFormatter.formatMessage(intl, 'validation_email_invalid')
                         ]} />
            </Grid>
          </Grid>

          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    users: state.users,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  inviteApplicant(data:InviteBorrowerRequest) {
    return dispatch(inviteApplicant(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(InviteApplicantForm)));
