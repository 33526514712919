import {WithStyles, withStyles} from "@material-ui/core";
import React from "react";
import {PairedList} from "../index";
import IntlFormatter from "../../intl";
import styles from "../../theme/jss/layouts/pageStyles";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {
  KeyValueMixed,
  Borrower2009MonthlyIncome,
  Borrower2009OtherIncome
} from "@jerseydev/orca-loans";

type Props = {
  monthlyIncome?: Borrower2009MonthlyIncome|null,
  otherIncome?: Borrower2009OtherIncome[]|null
} & WithStyles<typeof styles>
  & WrappedComponentProps

function BorrowerMonthlyIncomeSummaryRev1(props:Props) {
  const { intl, monthlyIncome, otherIncome } = props;

  const getListItems = () => {
    let items:KeyValueMixed[] = [];

    if(monthlyIncome) {
      items = [
        {key: IntlFormatter.formatMessage(intl, 'base_income'), value: monthlyIncome.base ? monthlyIncome.base.toMoney() : 'none'},
        {key: IntlFormatter.formatMessage(intl, 'overtime'), value: monthlyIncome.overtime ? monthlyIncome.overtime.toMoney() : 'none'},
        {key: IntlFormatter.formatMessage(intl, 'bonus'), value: monthlyIncome.bonus ? monthlyIncome.bonus.toMoney() : 'none'},
        {key: IntlFormatter.formatMessage(intl, 'commission'), value: monthlyIncome.commission ? monthlyIncome.commission.toMoney() : 'none'},
        {key: IntlFormatter.formatMessage(intl, 'dividends_interest'), value: monthlyIncome.dividendsInterest ? monthlyIncome.dividendsInterest.toMoney() : 'none'},
        {key: IntlFormatter.formatMessage(intl, 'net_rental'), value: monthlyIncome.netRental ? monthlyIncome.netRental.toMoney() : 'none'},
      ];
    }

    if(otherIncome) {
      otherIncome.forEach(o => {
        items.push(
          {key: o.source, value: o.income ? o.income.toMoney() : IntlFormatter.formatMessage(intl,'na') }
        )
      });
    }

    return items;
  };

  return (
    <PairedList items={getListItems()} />
  );
}

export default withStyles(styles)(injectIntl(BorrowerMonthlyIncomeSummaryRev1));