import React from 'react';
import {injectIntl, WrappedComponentProps} from "react-intl";
import {
  List,
  ListItem,
  ListItemText,
  withStyles,
  Icon,
  ListItemIcon,
  Card,
  WithStyles,
  CardHeader,
  CardContent,
  Divider,
  Avatar
} from "@material-ui/core";
import styles from "../theme/jss/components/loanChecklistStyles";
import IntlFormatter from "../intl";

type Props = WrappedComponentProps & WithStyles<typeof styles>

class LoanChecklist extends React.Component<Props> {
  render() {
    const { intl, classes } = this.props;
    return (
      <Card>
        <CardHeader title={IntlFormatter.formatMessage(intl, 'before_you_begin')}
                    avatar={<Avatar className={classes.headerAvatar}><Icon className={classes.headerIcon}>checklist</Icon></Avatar>}
                    className={classes.cardHeader} />
        <Divider />
        <CardContent>
          <List className={classes.list} dense={true}>
            <ListItem>
              <ListItemIcon>
                <Icon fontSize="small">markunread_mailbox</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'two_years_of_residence_history')} />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Icon fontSize="small">mailbox</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'mailing_address')} />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Icon fontSize="small">work</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'two_years_of_employment')} />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Icon fontSize="small">account_balance_wallet</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'bank_accounts_credit_cards_loans')} />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Icon fontSize="small">house</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'info_property_applying_for')} />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Icon fontSize="small">villa</Icon>
              </ListItemIcon>
              <ListItemText primary={IntlFormatter.formatMessage(intl, 'info_real_estate_you_own')} />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(LoanChecklist));
