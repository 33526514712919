import React, {MouseEvent} from 'react';
import {
  Dialog as MUIDialog,
  Typography,
  Grid,
  withStyles,
  IconButton,
  Icon,
  DialogProps,
  WithStyles
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import {CircularIcon} from "./index";
import styles from "../theme/jss/components/dialogStyles";

type DefaultProps = {
  variant?: 'standard'|'confirm',
  color?: 'default'|'info'|'infoAlt'|'primary'|'primaryAlt'|'secondary'|'secondaryAlt'|'success'|'successAlt'|'warn'|'warnAlt'|'danger'|'dangerAlt'
}

type Props = {
  title?: string,
  subtitle?: string,
  icon?: React.ReactNode,
  onClose?: (event:MouseEvent) => void
} & DialogProps
  & WithStyles<typeof styles>
  & Partial<DefaultProps>

function Dialog(props:Props) {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const { classes, children, variant, title, subtitle, icon, color, ...rest } = props;

  return (
    <MUIDialog {...rest} transitionDuration={{exit:0}} fullScreen={fullScreen}>
      <div className={variant === 'confirm' ? classes.confirmContent : classes.content}>
        {(variant === 'standard' && (title || icon)) &&
          <header className={classes.header}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <div className={classes.title}>
                  {icon &&
                    <div className={classes.titleIcon}>
                      <CircularIcon color={color} icon={icon} />
                    </div>
                  }
                  <div>
                    {title &&
                      <Typography variant="h5" gutterBottom>
                        {title}
                      </Typography>
                    }
                    {subtitle &&
                      <Typography variant="body1">
                        {subtitle}
                      </Typography>
                    }
                  </div>
                </div>
              </Grid>
              {props.onClose &&
              <Grid item>
                <IconButton onClick={props.onClose}>
                  <Icon>close</Icon>
                </IconButton>
              </Grid>
              }
            </Grid>
          </header>
        }

        {(variant === 'confirm' && (title || icon)) &&
          <header className={classes.confirmHeader}>
            {icon &&
              <div className={classes.confirmIconContainer}>
                <CircularIcon icon={icon} color={color} />
              </div>
            }
            {title &&
              <Typography variant="h4" gutterBottom>
                {title}
              </Typography>
            }
            {subtitle &&
              <Typography variant="body1">
                {subtitle}
              </Typography>
            }
          </header>
        }
        {children}
      </div>
    </MUIDialog>
  );
}

const defaultProps:DefaultProps = {
  variant: 'standard',
  color: 'default'
};

Dialog.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(Dialog);