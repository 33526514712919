import {createStyles} from "@material-ui/core";

const styles = () => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default styles;
