import {colors} from "@material-ui/core";
import {createStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

// dont use theme because it needs to render before the mui theme is loaded
const styles = () => createStyles({
  root: {
    background: 'rgba(0,0,0,0)',
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 10000,
    top: 0
  },
  container: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    backgroundColor: colors.grey[900],
    padding: `8px 16px`,
    color: colors.grey[100],
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginLeft: '8px',
    color: colors.grey[100],
  }
});

export default makeStyles(styles);

