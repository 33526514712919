import React from "react";
import MuiPagination, {PaginationProps} from '@material-ui/lab/Pagination';
import useStyles from "../theme/jss/components/paginationStyles";

const Pagination = (props:PaginationProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MuiPagination {...props} />
    </div>
  )
};

export default Pagination;