import React from 'react';
import {Typography} from '@material-ui/core';
import {FormattedMessage, useIntl} from "react-intl";
import {PersonCard} from "./index";
import {useStyles} from "../theme/jss/components/loanOfficerCardStyles";
import {LoanOfficerProfile} from "@jerseydev/orca-loans";
import {PersonCardProps} from "./PersonCard";
import IntlFormatter from "../intl";

type Props = {
  loanOfficer: LoanOfficerProfile,
} & Omit<PersonCardProps, 'person'>

const LoanOfficerCard = (props:Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { loanOfficer, children, mixpanel, variant, ...rest } = props;

  return (
    <PersonCard person={{
                  firstName: loanOfficer.firstName,
                  lastName: loanOfficer.lastName,
                  email: loanOfficer.email,
                  userId: loanOfficer.user,
                  avatar: loanOfficer.avatar,
                  deleted: loanOfficer.deleted
                }}
                mixpanel={mixpanel}
                title={IntlFormatter.formatMessage(intl, 'loan_officer')}
                variant={variant} {...rest}>

      {loanOfficer.title &&
        <Typography variant="subtitle1" color="inherit" gutterBottom>
          {loanOfficer.title}
        </Typography>
      }

      <div className={classes.mb2}>
        <Typography variant="body1" color="inherit" gutterBottom>
          <FormattedMessage id="nmls" /> #{loanOfficer.nmlsId}
        </Typography>
      </div>

      {(loanOfficer.description && variant === 'normal') &&
        <div className={classes.description}>
          <Typography variant="body1" color="inherit" className={classes.descriptionText}>
            {loanOfficer.description}
          </Typography>
        </div>
      }

      {children}
    </PersonCard>
  );
}

export default LoanOfficerCard;