import React, {ChangeEvent} from 'react';
import {SelectValidator} from 'react-material-ui-form-validator';
import {
  Grid,
  withStyles,
  MenuItem,
  WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ReduxApp, ActionProps, ErrorState, ReduxAccount} from "../../../types";
import {AxiosResponse} from "axios";
import {AccountRequest, LosIntegration} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateAccount} from "../../../actions/account";
import {IntegrationHelpAlert} from "../components";
import clsx from "clsx";
import mixpanel, {Mixpanel} from "mixpanel-browser";
import {EncompassSettingsForm} from "./index";
import {AccountUtil, ErrorUtil} from "../../../utils";

type Props = {
  mixpanel: Mixpanel,
  settings?: LosIntegration|null,
  onSubmit: (data:AxiosResponse<LosIntegration>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  account: ReduxAccount,
  updateAccount: ActionProps["updateAccount"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  provider: LosIntegration["provider"]|null
}

type State = {
  form: Form,
  errors: ErrorState[]
}

class LosSettingsForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    let form:Form = {
      provider: props.app.data.enums.losProviders.length === 1 ? props.app.data.enums.losProviders[0] as LosIntegration["provider"] : null
    }

    if(props.settings) {
      const {provider} = props.settings;
      form = {
        provider
      }
    }

    this.state = {
      form,
      errors: []
    };
  }

  onProviderChange = (event:ChangeEvent<{value:LosIntegration["provider"]}>) => {
    const form = _.cloneDeep(this.state.form);
    form.provider = event.target.value;
    this.setState({ form });
  };

  onSubmit = async (data:AxiosResponse<LosIntegration>) => {
    try {
      this.setState({errors: []})
      this.props.mixpanel.track(this.props.settings ? 'LOS integration updated' : 'LOS integration created');
      if(!AccountUtil.isSetupRecommendationUsed('integrations', this.props.account.data)) {
        let requestData:AccountRequest = AccountUtil.addSetupRecommendationToRequest('integrations', {}, this.props.account.data);
        await this.props.updateAccount(requestData).send();
      }
      this.props.onSubmit(_.cloneDeep(data));
    } catch (e) {
      this.setState({ errors: ErrorUtil.formatErrors(e) });
    }
  };


  render() {

    const { intl, classes, app, settings } = this.props;
    const { form, errors } = this.state;


    return (
      <div>
        {form.provider &&
        <div className={classes.mb2}>
          <IntegrationHelpAlert provider={form.provider} />
        </div>
        }
        <ErrorList errors={errors}
                     className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } }/>
        {app.data.enums.losProviders.length > 1 &&
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectValidator
                name="provider"
                label={IntlFormatter.formatMessage(intl, 'provider')}
                value={form.provider}
                fullWidth={true}
                onChange={this.onProviderChange}
                validators={['required']}
                errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}>
                {app.data.enums.losProviders.map((provider, i) => {
                  return (
                    <MenuItem key={i} value={provider}>
                      <FormattedMessage id={provider} />
                    </MenuItem>
                  )
                })}
              </SelectValidator>
            </Grid>
          </Grid>
        }

        {form.provider &&
          <div>
            {app.data.enums.losProviders.length === 1 &&
              <div className={clsx(classes.mb2, classes.textCenter)}>
                <img src={`/assets/img/logos/${form.provider}.png`} alt={form.provider} />
              </div>
            }

            {form.provider === 'encompass' &&
              <EncompassSettingsForm mixpanel={mixpanel}
                                     settings={settings}
                                     onSubmit={this.onSubmit} />
            }
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateAccount(data:AccountRequest) {
    return dispatch(updateAccount(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LosSettingsForm)));
