import React, {ChangeEvent, Component} from 'react';
import {CircularProgress, Icon, IconButton, Slider, Grid, withStyles, Typography, WithStyles} from '@material-ui/core';
import {DeleteDialog, ErrorList, UserAvatar} from "./index";
import ReactAvatarEditor from 'react-avatar-editor';
import IntlFormatter from "../intl";
import {injectIntl, FormattedMessage, WrappedComponentProps} from "react-intl";
import {updateProfileAvatar, deleteProfileAvatar} from "../actions/auth";
import {connect} from "react-redux";
import {ErrorUtil} from "../utils";
import styles from "../theme/jss/components/avatarEditorStyles";
import Fab from "./Fab";
import {PictureRequest, User} from "@jerseydev/orca-loans";
import {ActionProps, ErrorState, FixMeLater} from "../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import Dropzone from 'react-dropzone';
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  user: User,
  updateProfileAvatar: ActionProps["updateProfileAvatar"],
  deleteProfileAvatar: ActionProps["deleteProfileAvatar"]
} & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  loading: boolean,
  avatar?: File|null,
  scale: number,
  rotation: number,
  deleteDialogOpen: boolean,
  deleteLoading: boolean,
  errors: ErrorState[],
  dropzoneActive: boolean,
  dropzoneRejected: boolean
}

class AvatarEditor extends Component<Props, State> {
  avatarFile:HTMLInputElement|null;
  editor:ReactAvatarEditor|null;
  acceptedMimeTypes:string[] = ["image/jpeg", "image/png", "image/gif", "image/webp", "image/heic", "image/heif"];

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      avatar: null,
      scale: 1,
      rotation: 0,
      deleteDialogOpen: false,
      deleteLoading: false,
      errors: [],
      dropzoneActive: false,
      dropzoneRejected: false
    };
  }

  setEditorRef = (instance: ReactAvatarEditor | null) => {
    this.editor = instance;
  };

  onEditClick = () => {
    this.setState({ errors: [] });
    this.avatarFile!.click();
  };

  onAvatarSelected = (event:ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if(event.target.files && event.target.files.length > 0) {
      const avatar = event.target.files[0];
      //console.log(avatar);
      //avatar.url = URL.createObjectURL(avatar);
      if(avatar.type.includes('image')) {
        this.setState({ avatar });
      } else {
        // wrong file type
        this.setState({ errors: [{ message: IntlFormatter.formatMessage(this.props.intl, 'wrong_file_type')}] });
      }
    }
  };

  onScaleChange = (event:ChangeEvent<unknown>, value:number) => {
    this.setState( { scale: value });
  };

  onRotationRightChange = () => {
    this.setState( { rotation: this.state.rotation + 45 });
  };

  onRotationLeftChange = () => {
    this.setState( { rotation: this.state.rotation - 45 });
  };

  onShowDeleteDialog = () => {
    this.setState({ deleteDialogOpen: true });
  };

  onHideDeleteDialog = () => {
    this.setState({ deleteDialogOpen: false });
  };

  onDeleteDialogConfirm = () => {
    this.setState({ deleteLoading: true });
    this.props.deleteProfileAvatar().send().then(() => {
      this.props.mixpanel.track("Avatar deleted");
      this.setState({ deleteLoading: false, avatar: null, deleteDialogOpen: false });
    }).catch(err => {
      this.setState({ deleteLoading: false, avatar: null, deleteDialogOpen: false, errors: ErrorUtil.formatErrors(err) });
    });
  };

  onAvatarDrop = (dropped:File[]) => {
    this.setState({ avatar: dropped[0], dropzoneActive: false });
  };

  onAvatarDropRejected = (dropped:File[]) => {
    this.setState({ dropzoneActive: false, dropzoneRejected: true, avatar: null });
  };

  onDragEnter = () => {
    this.setState( { dropzoneActive: true, dropzoneRejected: false });
  };

  onDragLeave = () => {
    this.setState( { dropzoneActive: false });
  };

  onCancel = () => {
    this.setState({ avatar: null });
  };

  onSave = () => {
    //console.log(this.state.avatar);

    this.setState({ loading: true }, () => {
      const img = this.editor!.getImageScaledToCanvas();

      // @ts-ignore
      const mimeType = this.editor && this.editor.props && this.editor.props.image ? this.editor.props.image.type : 'image/png';
      img.toBlob((blob:Blob|null) => {
        if(blob){
          const file = new File([blob], this.state.avatar!.name, {'type':mimeType});
          this.props.updateProfileAvatar({picture:file, width: 100, resizeMode: "max"}).send().then(() => {
            this.props.mixpanel.track("Avatar updated");
            this.setState({ avatar: null, loading: false });
          }).catch(() => {
            this.setState({ avatar: null, loading: false, errors: [{ message: IntlFormatter.formatMessage(this.props.intl, 'error_uploading_avatar')}] })
          });
        }
      }, (this.state.avatar! as FixMeLater).mimeType);
    });
  };

  renderRejectedText = () => {
    if(this.state.dropzoneRejected) {
      const {classes} = this.props;

      return (
        <div className={classes.textCenter}>
          <Typography variant="caption" className={classes.danger}>
            <FormattedMessage id="invalid_file_type" />
          </Typography>
        </div>
      )
    }
    return null;
  };

  render() {

    const { classes, user, intl } = this.props;
    const { avatar, loading, scale, rotation, errors, deleteDialogOpen, deleteLoading, dropzoneActive, dropzoneRejected } = this.state;

    return (
      <div>
        <DeleteDialog open={deleteDialogOpen}
                      title={IntlFormatter.formatMessage(intl, 'delete_avatar')}
                      loading={deleteLoading}
                      item={IntlFormatter.formatMessage(intl, 'your_avatar')}
                      onCancel={this.onHideDeleteDialog}
                      onSubmit={this.onDeleteDialogConfirm} />
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <ErrorList errors={errors}
                       onClose={() => { this.setState({ errors: [] }); }}
                       className={classes.mb2} />
            {avatar &&
              <div>
                {loading &&
                  <div style={{ width: 100, height: 100 }} className={classes.centerAll}>
                    <CircularProgress />
                  </div>
                }
                <Grid container spacing={2}>
                  <Grid item>
                    <div style={{ opacity: loading ? 0 : 1 }}>
                      <Dropzone
                        onDrop={this.onAvatarDrop}
                        onDropRejected={this.onAvatarDropRejected}
                        accept={this.acceptedMimeTypes}
                        noClick
                        noKeyboard
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <ReactAvatarEditor ref={this.setEditorRef}
                                               image={avatar}
                                               width={100}
                                               height={100}
                                               border={1}
                                               borderRadius={10}
                                               color={[0, 0, 0, 0.6]} // RGBA
                                               scale={scale}
                                               rotate={rotation}/>
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </Dropzone>
                      {this.renderRejectedText()}
                    </div>
                  </Grid>
                  {!loading &&
                  <Grid item>
                    <div className={classes.rowCenter}>
                      <div className={classes.slider}>
                        <Typography variant="caption">
                          <FormattedMessage id="scale"/>
                        </Typography>

                        <Slider value={scale}
                                step={.1}
                                min={1}
                                max={5}
                                onChange={this.onScaleChange}/>
                      </div>

                      <div>
                        <IconButton size="small" onClick={this.onRotationLeftChange}>
                          <Icon>rotate_left</Icon>
                        </IconButton>
                        <IconButton size="small" onClick={this.onRotationRightChange}>
                          <Icon>rotate_right</Icon>
                        </IconButton>
                      </div>
                    </div>

                    <div>
                      {!loading &&
                      <div className={classes.mt1}>

                        <Grid item className={classes.rowCenter}>
                          <div className={classes.mr2}>
                            <Fab color="dangerAlt"
                                 size="small"
                                 flat
                                 rounded
                                 onClick={this.onCancel}>
                              <Icon>cancel</Icon>
                            </Fab>
                          </div>
                          <Fab color="primaryAlt"
                               size="small"
                               flat
                               rounded
                               onClick={this.onSave}>
                            <Icon>check</Icon>
                          </Fab>
                        </Grid>
                      </div>
                      }
                    </div>
                  </Grid>
                  }
                </Grid>
              </div>
            }
            {!avatar &&
              <div>
                <Dropzone
                  onDrop={this.onAvatarDrop}
                  onDragEnter={this.onDragEnter}
                  onDragLeave={this.onDragLeave}
                  onDropRejected={this.onAvatarDropRejected}
                  accept={this.acceptedMimeTypes}
                  noKeyboard>
                  {({ getRootProps }) => (
                    <div {...getRootProps()}>
                      <div className={dropzoneActive ? classes.avatarEditContainerActive : dropzoneRejected ? classes.avatarEditContainerRejected : classes.avatarEditContainer}
                           onClick={this.onEditClick}>
                        <UserAvatar user={user}
                                    variant="square"
                                    size="extraLarge"
                                    popoverEnabled={false} />
                        <Icon className={classes.avatarEditIcon}>photo_camera</Icon>
                        <input type="file"
                               id="avatar-file"
                               ref={ref => this.avatarFile = ref}
                               style={{display: "none"}}
                               onChange={this.onAvatarSelected} />
                      </div>

                    </div>
                  )}
                </Dropzone>
                {this.renderRejectedText()}
              </div>
            }
          </Grid>
          {user.avatar && !avatar &&
            <Grid item>
              <Fab color="dangerAlt"
                   size="small"
                   flat
                   rounded
                   onClick={this.onShowDeleteDialog}>
                <Icon>cancel</Icon>
              </Fab>
            </Grid>
          }
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateProfileAvatar(data:PictureRequest) {
    return dispatch(updateProfileAvatar(data))
  },
  deleteProfileAvatar() {
    return dispatch(deleteProfileAvatar())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(AvatarEditor)));
