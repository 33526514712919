import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  loanOfficerContainer: {
    marginBottom: theme.spacing(2)
  },
  footer: {
    marginTop: theme.spacing(2),
    textAlign: 'right'
  },
  chipInfo: {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main
  },
  chipSuccess: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main
  },
  chipWarning: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main
  },
  chipDanger: {
    color: theme.palette.danger.contrastText,
    backgroundColor: theme.palette.danger.main
  },
  accordionHeaderEditIcon: {
    fontSize: 20
  },
  loanStatusContainer: {
    position: 'relative',
    zIndex: 10
  },
  helpStatusBar: {
    '& > div > .MuiButton-root:first-child': {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up("sm")]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    }
  }
});

export default styles;
