import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  root: {
    display: 'inline-block'
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  normal: {

  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  extraLarge: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  standard: {

  },
  outlined: {
    border: `solid 2px ${theme.palette.neutral.main}`,
    padding: 2,
    borderRadius: '50%'
  },
  square: {
    '& .MuiAvatar-root': {
      borderRadius: 10
    }
  },
  default: {

  },
  primary: {
    borderColor: theme.palette.primary.main,
  },
  secondary: {
    borderColor: theme.palette.secondary.main,
  },
  info: {
    borderColor: theme.palette.info.main,
  },
  success: {
    borderColor: theme.palette.success.main,
  },
  warn: {
    borderColor: theme.palette.warning.main,
  },
  danger: {
    borderColor: theme.palette.danger.main,
  }
});

export default styles;
