import React from 'react';
import {List, ListItem, ListItemIcon, ListItemText, Icon, Tooltip, ListSubheader} from '@material-ui/core';
import IntlFormatter from "../intl";
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {PhoneNumber} from "@jerseydev/orca-loans";

type Props = {
  phoneNumbers: PhoneNumber[],
  title?: string|null
} & WrappedComponentProps

type IconMap = {
  home: React.ReactNode,
  work: React.ReactNode,
  cell: React.ReactNode,
  fax:  React.ReactNode,
  other: React.ReactNode
}

class PhoneNumberList extends React.Component<Props> {
  phoneNumberIcons:IconMap;

  constructor(props:Props) {
    super(props);

    this.phoneNumberIcons = {
      home: <Tooltip title={IntlFormatter.formatMessage(props.intl, 'home')}><Icon>home</Icon></Tooltip>,
      work: <Tooltip title={IntlFormatter.formatMessage(props.intl, 'work')}><Icon>business</Icon></Tooltip>,
      cell: <Tooltip title={IntlFormatter.formatMessage(props.intl, 'cell')}><Icon>phone_iphone</Icon></Tooltip>,
      fax:  <Tooltip title={IntlFormatter.formatMessage(props.intl, 'fax')}><Icon>print</Icon></Tooltip>,
      other: <Tooltip title={IntlFormatter.formatMessage(props.intl, 'other')}><Icon>phone</Icon></Tooltip>,
    };

  }

  render() {

    const { title, phoneNumbers } = this.props;

    if(phoneNumbers.length === 0) {
      return null;
    }

    return (
      <List subheader={title ?
        <ListSubheader>
          {title}
        </ListSubheader>
        : undefined
      }>
        {phoneNumbers.map((phoneNumber,i) => {
          return (
            <ListItem key={i}>
              <ListItemIcon>
                {this.phoneNumberIcons[phoneNumber.type as keyof IconMap]}
              </ListItemIcon>
              <ListItemText primary={`${phoneNumber.number} ${phoneNumber.ext ? `x${phoneNumber.ext}` : ''}`} />
            </ListItem>
          )
        })}
      </List>
    );
  }
}

export default injectIntl(PhoneNumberList);
