import React from 'react';
import {
  Typography,
  Link, Grid, withStyles, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {PairedList, SubTitle, Well} from "../index";
import IntlFormatter from "../../intl";
import {Link as RouterLink} from "react-router-dom";
import {LocationUtil, TextUtil} from "../../utils";
import pageStyles from "../../theme/jss/layouts/pageStyles";
import Item from "../Item";
import {LoanApplication} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

const LoanRealEstateSummaryRev2 = (props:Props) => {
  const {intl, classes, loanApplication} = props;

  if(!loanApplication.borrowers || loanApplication.borrowers.length === 0) {
    return (
      <Well color="warn">
        <Typography variant="body1">
          <FormattedMessage id="no_real_estate_found" />
        </Typography>
      </Well>
    );
  }

  return (
    <Grid container spacing={2}>
      {loanApplication.borrowers.map((borrower, borrowerIndex) => {
        return (
          <Grid key={borrowerIndex} item
                // @ts-ignore
                md={loanApplication.borrowers && loanApplication.borrowers.length > 1 ? Math.floor(12 / loanApplication.borrowers.length) : 6}>
            
            <div className={classes.mb2}>
              <SubTitle title={borrower.fullName || IntlFormatter.formatMessage(intl, 'borrower')} />
            </div>

            {(!borrower.realEstateAssets || (borrower.realEstateAssets && borrower.realEstateAssets.length === 0)) &&
            <Well color="warn">
              <Typography variant="body1">
                <FormattedMessage id="no_real_estate_found" />.&nbsp;
                {!loanApplication.completed &&
                <Link component={RouterLink} to="/apply/real-estate">
                  <FormattedMessage id="add_real_estate"/>
                </Link>
                }
              </Typography>
            </Well>
            }
            {borrower.realEstateAssets &&
            <div>
              {borrower.realEstateAssets.map((asset, i) => {
                return (
                  <div key={i} className={classes.pv2}>
                    {asset.address &&
                      <div className={classes.pl2}>
                        <Typography variant="subtitle2">{LocationUtil.formatLocation(asset.address)}</Typography>
                      </div>
                    }
                    <PairedList items={[
                      {key: IntlFormatter.formatMessage(intl, 'occupancy'), value: IntlFormatter.formatMessage(intl, asset.occupancy ? `occupancy_types_${asset.occupancy}` : 'na')},
                      {key: IntlFormatter.formatMessage(intl, 'status'), value: IntlFormatter.formatMessage(intl, asset.status ? `real_estate_status_${asset.status}` : 'na')},
                      {key: IntlFormatter.formatMessage(intl, 'market_value'), value: TextUtil.getMoneyText(asset.marketValue, intl)},
                      {key: IntlFormatter.formatMessage(intl, 'monthly_rental_income'), value: TextUtil.getMoneyText(asset.monthlyRentalIncome, intl)},
                      {key: IntlFormatter.formatMessage(intl, 'insurance_maintenance_taxes_misc'), value: TextUtil.getMoneyText(asset.monthlyInsTaxDues, intl)}
                    ]}/>

                    {(asset.mortgages && asset.mortgages.length > 0) &&
                      <div className={classes.pl2}>
                        {asset.mortgages.map((mortgage, i) => {
                          return (
                            <div key={i}>
                              <Typography variant="subtitle2" gutterBottom>
                                {mortgage.creditor}
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item>
                                  <Item label={IntlFormatter.formatMessage(intl, 'loan_type')}>
                                    <Typography>
                                      {TextUtil.getValueText(IntlFormatter.formatMessage(intl, `loan_type_${mortgage.loanType}`), intl)}
                                    </Typography>
                                  </Item>
                                </Grid>
                                <Grid item>
                                  <Item label={IntlFormatter.formatMessage(intl, 'account_number')}>
                                    <Typography>
                                      {TextUtil.getValueText(mortgage.accountNumber, intl)}
                                    </Typography>
                                  </Item>
                                </Grid>
                                <Grid item>
                                  <Item label={IntlFormatter.formatMessage(intl, 'monthly_mortgage_payment')}>
                                    <Typography>
                                      {TextUtil.getValueText(mortgage.monthlyMortgagePayment, intl)}
                                    </Typography>
                                  </Item>
                                </Grid>
                                <Grid item>
                                  <Item label={IntlFormatter.formatMessage(intl, 'balance')}>
                                    <Typography>
                                      {TextUtil.getValueText(mortgage.balance, intl)}
                                    </Typography>
                                  </Item>
                                </Grid>
                                <Grid item>
                                  <Item label={IntlFormatter.formatMessage(intl, 'balance_paid_before_closing')}>
                                    <Typography>
                                      {TextUtil.getBoolText(mortgage.balancePaidBeforeClosing, intl)}
                                    </Typography>
                                  </Item>
                                </Grid>
                                <Grid item>
                                  <Item label={IntlFormatter.formatMessage(intl, 'credit_limit')}>
                                    <Typography>
                                      {TextUtil.getMoneyText(mortgage.creditLimit, intl)}
                                    </Typography>
                                  </Item>
                                </Grid>
                              </Grid>
                            </div>
                          )
                        })}
                      </div>
                    }
                  </div>
                )
              })}
            </div>
            }
          </Grid>
        )
      })}
    </Grid>
  );
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanRealEstateSummaryRev2));