import React, {ChangeEvent} from 'react';
import {
  Grid,
  List,
  ListItem,
  withStyles,
  ListSubheader, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import BaseForm from "../../../forms/BaseForm";
import {ColorPicker, DropzoneField, ErrorList, HelperTooltip, SubmitButton, TextField} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {addLogo, removeLogo, addLightLogo, removeLightLogo, addFavicon, removeFavicon} from "../../../actions/settings";
import {AccountUtil, ErrorUtil, ReduxUtil} from "../../../utils";
import styles from "../../../theme/jss/components/generalSettingsFormStyles";
import palette from "../../../theme/jss/palette";
import {getDesign} from "../../../actions/design";
import IntlFormatter from "../../../intl";
import {Form} from "../../../forms";
import {getAccount, updateAccount} from "../../../actions/account";
import clsx from "clsx";
import {FileWithPreview, ReduxAccount, ActionProps, ErrorState} from "../../../types";
import {AxiosResponse} from "axios";
import {
  AccountRequest,
  ImageRequest,
  ManagedFile, Theme,
  ThemeColor,
  ThemeColorSetting
} from "@jerseydev/orca-loans";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  onSubmit: (data:AxiosResponse<AccountRequest>) => void,
  onCancel?: () => void,
  account: ReduxAccount,
  getDesign: ActionProps["getDesign"],
  addLogo: ActionProps["addLogo"],
  removeLogo: ActionProps["removeLogo"],
  addLightLogo: ActionProps["addLightLogo"],
  removeLightLogo: ActionProps["removeLightLogo"],
  addFavicon: ActionProps["addFavicon"],
  removeFavicon: ActionProps["removeFavicon"],
  getAccount: ActionProps["getAccount"],
  updateAccount: ActionProps["updateAccount"]
} & WrappedComponentProps
  & WithStyles<typeof styles>

type Form = {
  logo: FileWithPreview|ManagedFile|null,
  lightLogo: FileWithPreview|ManagedFile|null,
  favicon: FileWithPreview|ManagedFile|null,
  account: {
    name: string,
    websiteUrl: string,
    privacyPolicyUrl: string,
    termsOfUseUrl: string,
    supportUrl: string,
    theme: Theme
  }
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  logoLoading: boolean,
  lightLogoLoading: boolean,
  faviconLoading: boolean,
  colorPickerLoading: boolean,
  colorPicker: {
    visible: boolean,
    type: string|null,
    variant: string|null,
    loading: boolean
  }
}

class GeneralSettingsForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    let form:Form = {
      logo: null,
      lightLogo: null,
      favicon: null,
      account: {
        name: '',
        websiteUrl: '',
        privacyPolicyUrl: '',
        termsOfUseUrl: '',
        supportUrl: '',
        theme: {
          colors: {
            primary: null,
            secondary: null
          }
        }
      }
    };

    if(props.account) {
      const {name, theme, websiteUrl, privacyPolicyUrl, termsOfUseUrl, supportUrl, logo, lightLogo, favicon} = props.account.data;
      form = {
        logo: logo ? logo as ManagedFile : null,
        lightLogo: lightLogo ? lightLogo as ManagedFile : null,
        favicon: favicon ? favicon as ManagedFile : null,
        account: {
          name,
          websiteUrl: websiteUrl ? websiteUrl : '',
          privacyPolicyUrl: privacyPolicyUrl ? privacyPolicyUrl : '',
          termsOfUseUrl: termsOfUseUrl ? termsOfUseUrl : '',
          supportUrl: supportUrl ? supportUrl : '',
          theme: _.clone(theme)
        }
      };
    }

    this.state = {
      loading: false,
      form,
      logoLoading: false,
      lightLogoLoading: false,
      faviconLoading: false,
      colorPickerLoading: false,
      colorPicker: {
        visible: false,
        variant: null,
        type: null,
        loading: false
      },
      errors: []
    };
  }

  componentDidMount = async () => {
    if(!ReduxUtil.hasData(this.props.account)) {
      try {
        const accountResult = await this.props.getAccount().send();
        const form = _.cloneDeep(this.state.form);
        form.account = _.merge(form.account, _.cloneDeep(accountResult.data));
        this.setState({ form });
      } catch(e) {
        this.setState({ errors: ErrorUtil.formatErrors(e) });
      }
    }

    this.setState({ loading: false });
  };

  componentWillUnmount = () => {
    const {logo, lightLogo, favicon} = this.state.form;
    if(logo && "preview" in logo) {
      window.URL.revokeObjectURL(logo.preview);
    }
    if(lightLogo && "preview" in lightLogo) {
      window.URL.revokeObjectURL(lightLogo.preview);
    }
    if(favicon && "preview" in favicon) {
      window.URL.revokeObjectURL(favicon.preview);
    }
  };

  onAddLogo = async (image:FileWithPreview) => {
    try {
      this.setState({ logoLoading: true });
      const result = await this.props.addLogo({image, width: 800, resizeMode: "max"}).send();
      this.props.mixpanel.track('Logo updated');
      const account = this.props.account.data;
      if(!AccountUtil.isSetupRecommendationUsed('logo', account)) {
        let requestData:AccountRequest = AccountUtil.addSetupRecommendationToRequest('logo', {}, this.props.account.data);
        await this.props.updateAccount(requestData).send();
      }

      const form = _.cloneDeep(this.state.form);
      form.logo = result.data;
      this.setState({ logoLoading: false, form });
      await this.refreshDesign();
    } catch (e) {
      this.setState({
        logoLoading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  onRemoveLogo = async () => {
    try {
      this.setState({ logoLoading: true });
      await this.props.removeLogo().send();
      this.props.mixpanel.track('Logo removed');
      const form = _.cloneDeep(this.state.form);
      form.logo = null;
      this.setState({ logoLoading: false, form });
      await this.refreshDesign();
    } catch (e) {
      this.setState({
        logoLoading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  onAddLightLogo = async (image:FileWithPreview) => {
    try {
      this.setState({ lightLogoLoading: true });
      const result = await this.props.addLightLogo({image, width: 800, resizeMode: "max"}).send();
      this.props.mixpanel.track('Light logo updated');
      const form = _.cloneDeep(this.state.form);
      form.lightLogo = result.data;
      this.setState({ lightLogoLoading: false, form });
      await this.refreshDesign();
    } catch (e) {
      this.setState({
        lightLogoLoading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  onRemoveLightLogo = async () => {
    try {
      this.setState({ lightLogoLoading: true });
      await this.props.removeLightLogo().send();
      this.props.mixpanel.track('Light logo removed');
      const form = _.cloneDeep(this.state.form);
      form.lightLogo = null;
      this.setState({ lightLogoLoading: false, form });
      await this.refreshDesign();
    } catch (e) {
      this.setState({
        lightLogoLoading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  onAddFavicon = async (image:FileWithPreview) => {
    try {
      this.setState({ faviconLoading: true });
      const result = await this.props.addFavicon({image, width: 200, resizeMode: "max"}).send();
      this.props.mixpanel.track('Favicon updated');
      const form = _.cloneDeep(this.state.form);
      form.favicon = result.data;
      this.setState({ faviconLoading: false, form });
      await this.refreshDesign();
    } catch (e) {
      this.setState({
        errors: ErrorUtil.formatErrors(e),
        faviconLoading: false
      });
    }
  };

  onRemoveFavicon = async () => {
    try {
      this.setState({ faviconLoading: true });
      await this.props.removeFavicon().send();
      this.props.mixpanel.track('Favicon removed');
      const form = _.cloneDeep(this.state.form);
      form.favicon = null;
      this.setState({ faviconLoading: false, form });
      await this.refreshDesign();
    } catch (e) {
      this.setState({
        faviconLoading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  onThemeColorClick = (type:string, variant:string) => {
    const colorPicker:State["colorPicker"] = {
      type,
      variant,
      loading: false,
      visible:true
    };

    this.setState({ colorPicker });
  };

  onThemeColorClose = () => {
    const colorPicker:State["colorPicker"] = {
      type: null,
      variant: null,
      loading: false,
      visible: false
    };

    this.setState({ colorPicker });
  };

  onThemeColorSubmit = async (color:string) => {
    try {
      const colorPicker = _.clone(this.state.colorPicker);

      if(colorPicker.type) {
        colorPicker.loading = true;
        this.setState({ colorPicker });

        const theme = _.cloneDeep(this.props.account.data.theme);

        if(!theme.colors[colorPicker.type as keyof ThemeColorSetting]) {
          // @ts-ignore
          theme.colors[colorPicker.type as keyof ThemeColorSetting] = {
            [colorPicker.variant as keyof ThemeColor]: color
          };
        } else {
          // @ts-ignore
          theme.colors[colorPicker.type][colorPicker.variant] = color;
        }

        const requestData:AccountRequest = {
          theme
        };

        await this.props.updateAccount(requestData).send();
        this.props.mixpanel.track('Theme color updated', {type: colorPicker.type, variant: colorPicker.variant});
        colorPicker.loading = false;

        this.setState({ colorPicker });
        await this.refreshDesign();
      }
    } catch (e) {
      this.setState({ errors: ErrorUtil.formatErrors(e) });
    }
  };

  refreshDesign = async () => {
    try {
      await this.props.getDesign().send();
    } catch(err) {

    }
  }

  renderThemeColorVariant = (type:string, variant:string) => {
    const { intl, account } = this.props;
    const { colorPicker } = this.state;

    // @ts-ignore
    const color = account.data.theme.colors[type as keyof ThemeColorSetting] && account.data.theme.colors[type as keyof ThemeColorSetting]![variant as keyof ThemeColor] ? account.data.theme.colors[type as keyof ThemeColorSetting]![variant as keyof ThemeColor] : palette[type][variant];
    return <ColorPicker title={IntlFormatter.formatMessage(intl, `theme.colors.variant.${variant}`)}
                        color={color}
                        loading={colorPicker.loading}
                        onClick={() => this.onThemeColorClick(type, variant)}
                        onSubmit={this.onThemeColorSubmit} />
  };

  onSubmit = async () => {
    try {
      this.setState({ loading: true, errors: [] });
      const {name} = this.state.form.account;
      const {websiteUrl, privacyPolicyUrl, supportUrl, termsOfUseUrl} = this.state.form.account;

      let accountRequestData:AccountRequest = {
        name,
        websiteUrl: websiteUrl !== '' ? websiteUrl : null,
        privacyPolicyUrl: privacyPolicyUrl !== '' ? privacyPolicyUrl : null,
        supportUrl: supportUrl !== '' ? supportUrl : null,
        termsOfUseUrl: termsOfUseUrl !== '' ? termsOfUseUrl : null
      };

      accountRequestData = AccountUtil.addSetupRecommendationToRequest('generalSettings', accountRequestData, this.props.account.data);
      const result = await this.props.updateAccount(accountRequestData).send();
      this.props.mixpanel.track('General settings updated');

      this.setState({ loading: false });
      this.props.onSubmit(_.cloneDeep(result));
    } catch(err) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
    }
  };

  render() {

    const { classes, intl } = this.props;
    const { errors, form, logoLoading, lightLogoLoading, faviconLoading, loading } = this.state;

    return (
      <div>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } }/>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={5}>
            <Form onSubmit={this.onSubmit}>
              <div className={classes.mb3}>
                <TextField name="name"
                           label={IntlFormatter.formatMessage(intl, 'company_name')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.account.name')}
                           value={form.account.name}
                           fullWidth={true}/>
              </div>
              <div className={classes.mb3}>
                <TextField name="websiteUrl"
                           label={IntlFormatter.formatMessage(intl, 'website_url')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.account.websiteUrl')}
                           value={form.account.websiteUrl}
                           fullWidth={true}
                           helperText={<HelperTooltip text={IntlFormatter.formatMessage(intl, 'whats_this')}
                                                      tooltip={IntlFormatter.formatMessage(intl, 'website_url_settings_help')} />}
                           validators={['isUrl']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_url_invalid'),
                           ]}/>
              </div>
              <div className={classes.mb3}>
                <TextField name="privacyPolicyUrl"
                           label={IntlFormatter.formatMessage(intl, 'privacy_policy_url')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.account.privacyPolicyUrl')}
                           value={form.account.privacyPolicyUrl}
                           fullWidth={true}
                           helperText={<HelperTooltip text={IntlFormatter.formatMessage(intl, 'whats_this')}
                                                     tooltip={IntlFormatter.formatMessage(intl, 'privacy_policy_url_settings_help')} />}
                           validators={['isUrl']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_url_invalid'),
                           ]}/>
              </div>
              <div className={classes.mb3}>
                <TextField name="termsOfUseUrl"
                           label={IntlFormatter.formatMessage(intl, 'terms_of_use_url')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.account.termsOfUseUrl')}
                           value={form.account.termsOfUseUrl}
                           fullWidth={true}
                           helperText={<HelperTooltip text={IntlFormatter.formatMessage(intl, 'whats_this')}
                                                      tooltip={IntlFormatter.formatMessage(intl, 'terms_of_use_url_settings_help')} />}
                           validators={['isUrl']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_url_invalid'),
                           ]}/>
              </div>
              <div className={classes.mb3}>
                <TextField name="supportUrl"
                           label={IntlFormatter.formatMessage(intl, 'support_url')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.account.supportUrl')}
                           value={form.account.supportUrl}
                           fullWidth={true}
                           helperText={<HelperTooltip text={IntlFormatter.formatMessage(intl, 'whats_this')}
                                                      tooltip={IntlFormatter.formatMessage(intl, 'support_url_settings_help')} />}
                           validators={['isUrl']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_url_invalid'),
                           ]}/>
              </div>

              <div className={classes.mt2}>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <SubmitButton loading={loading}>
                      <FormattedMessage id="save" />
                    </SubmitButton>
                  </Grid>
                </Grid>
              </div>
            </Form>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <div className={classes.mb2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <List subheader={
                    <ListSubheader component="div" disableSticky>
                      <FormattedMessage id="primary_colors" />
                      <HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'primary_color_settings_help')} />
                    </ListSubheader>
                  } >
                    <ListItem>{this.renderThemeColorVariant('primary', 'dark')}</ListItem>
                    <ListItem>{this.renderThemeColorVariant('primary', 'main')}</ListItem>
                    <ListItem>{this.renderThemeColorVariant('primary', 'light')}</ListItem>
                    <ListItem>{this.renderThemeColorVariant('primary', 'contrastText')}</ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <List subheader={
                    <ListSubheader component="div" disableSticky>
                      <FormattedMessage id="secondary_colors" />
                      <HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'secondary_color_settings_help')} />
                    </ListSubheader>
                  } >
                    <ListItem>{this.renderThemeColorVariant('secondary', 'dark')}</ListItem>
                    <ListItem>{this.renderThemeColorVariant('secondary', 'main')}</ListItem>
                    <ListItem>{this.renderThemeColorVariant('secondary', 'light')}</ListItem>
                    <ListItem>{this.renderThemeColorVariant('secondary', 'contrastText')}</ListItem>
                  </List>
                </Grid>
              </Grid>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <div className={clsx(classes.mb2, classes.ph2)}>
                  <DropzoneField label={IntlFormatter.formatMessage(intl, 'logo')}
                                 helperText={IntlFormatter.formatMessage(intl, 'logo_settings_help')}
                                 loading={logoLoading}
                                 accept="image/jpeg, image/png"
                                 preview={false}
                                 imageDimensions={{ width: 150, height: 100 }}
                                 value={form.logo}
                                 onAdd={this.onAddLogo}
                                 onRemove={this.onRemoveLogo}
                                 confirmRemoveMessage={IntlFormatter.formatMessage(intl, 'logo')}
                                 confirmRemove={true} />
                </div>
                <div className={classes.ph2}>
                  <DropzoneField label={IntlFormatter.formatMessage(intl, 'favicon')}
                                 helperText={IntlFormatter.formatMessage(intl, 'favicon_settings_help')}
                                 loading={faviconLoading}
                                 accept="image/jpeg, image/png"
                                 preview={false}
                                 imageDimensions={{ width: 75, height: 75 }}
                                 value={form.favicon}
                                 onAdd={this.onAddFavicon}
                                 onRemove={this.onRemoveFavicon}
                                 confirmRemoveMessage={IntlFormatter.formatMessage(intl, 'favicon')}
                                 confirmRemove={true} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className={clsx(classes.mb2, classes.ph2)}>
                  <DropzoneField label={IntlFormatter.formatMessage(intl, 'light_logo')}
                                 helperText={IntlFormatter.formatMessage(intl, 'light_logo_settings_help')}
                                 loading={lightLogoLoading}
                                 accept="image/jpeg, image/png"
                                 preview={false}
                                 imageDimensions={{ width: 150, height: 100 }}
                                 value={form.lightLogo}
                                 previewClass={classes.lightLogoPreview}
                                 onAdd={this.onAddLightLogo}
                                 onRemove={this.onRemoveLightLogo}
                                 confirmRemoveMessage={IntlFormatter.formatMessage(intl, 'light_logo')}
                                 confirmRemove={true} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getDesign() {
    return dispatch(getDesign());
  },
  addLogo(file:ImageRequest) {
    return dispatch(addLogo(file));
  },
  removeLogo() {
    return dispatch(removeLogo());
  },
  addLightLogo(file:ImageRequest) {
    return dispatch(addLightLogo(file));
  },
  removeLightLogo() {
    return dispatch(removeLightLogo());
  },
  addFavicon(file:ImageRequest) {
    return dispatch(addFavicon(file));
  },
  removeFavicon() {
    return dispatch(removeFavicon());
  },
  getAccount() {
    return dispatch(getAccount());
  },
  updateAccount(data:AccountRequest) {
    return dispatch(updateAccount(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(GeneralSettingsForm)));
