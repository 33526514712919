import * as types from './types';
import Api, {
  createCancelSource,
  setBasicSecurityWorker,
  setBearerSecurityWorker
} from '../lib/Api';
import {ReduxState} from "../data/initialState";
import {setData} from './data';
import _ from 'lodash';
import {SocketUtil} from "../utils";
import {AnyAction, Dispatch} from "redux";
import {ReduxAsyncOperation} from "../enums";
import {ActionResponse, FixMeLater, ReduxActionData} from "../types";
import {
  PictureRequest,
  RefreshTokenRequest,
  TokenRequest,
  User,
  UserProfileRequest,
  UserRegistrationRequest
} from "@jerseydev/orca-loans";
import {AxiosPromise, AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";

export function authenticate(username:string, password:string, data?:FixMeLater) {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    return login(username, password, dispatch, getState, data);
  };
}

export function refreshToken():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const state = getState();
    if(!state.token || (state.token && !state.token.data)) {
      throw new Error('Cannot refresh token because the token is missing');
    }
    const token = _.clone(state.token);
    const cancelSource = createCancelSource();
    const requestData:RefreshTokenRequest = {
      grant_type: "refresh_token",
      refresh_token: state.token.data.refreshToken,
      client_id: process.env.REACT_APP_ORCA_LOANS_CLIENT_ID || "",
      client_secret: process.env.REACT_APP_ORCA_LOANS_CLIENT_SECRET || "",
    };
    const apiRequest = Api.getToken(requestData, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      dispatch(setData(types.SET_TOKENS, ReduxAsyncOperation.UPDATING, token!.data));
      apiRequest.then(response => {
        const tokens = {
          accessToken: response.data.access_token,
          refreshToken: state.token!.data.refreshToken
        };

        setBearerSecurityWorker(tokens.accessToken);

        dispatch(setData(types.SET_TOKENS, ReduxAsyncOperation.IDLE, tokens));

        SocketUtil.authenticate(response.data.access_token).then(() => {
          resolve(response);
        }).catch(err => {
          reject(err);
        });
      }).catch(ex => {
        dispatch(setData(types.SET_TOKENS, ReduxAsyncOperation.IDLE, token.data));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function logout(inactivityLogout = false):ThunkAction<void, ReduxState, any, AnyAction> {
  SocketUtil.logout();
  setBasicSecurityWorker();

  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const state = getState();

    const {app, configured, design, intl, dontPromptForMobileNumber} = state;
    const data:ReduxState = {
      app,
      configured,
      authenticated: false,
      design,
      intl,
      dontPromptForMobileNumber
    };
    
    if(inactivityLogout) {
      data.inactivityLogout = true;
    }
    dispatch(setLogout(data));
  };
}

export function setInactivityLogout(inactivityLogout:boolean):FixMeLater {
  return (dispatch:Dispatch) => {
    dispatch({
      type: types.SET_INACTIVITY_LOGOUT,
      payload: inactivityLogout
    });
  };
}

export function register(data:UserRegistrationRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.registerBorrower(data, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      dispatch(setData(types.SET_USER, ReduxAsyncOperation.ADDING));

      apiRequest.then((result) => {
        login(data.email, data.password, dispatch, getState).send().then(() => {
          resolve(result);
        }).catch(err => reject(err));
      }).catch((ex) => {
        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, {}));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getProfile():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getProfile({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const user = state.user ? _.cloneDeep(state.user.data) : null;
      dispatch(setData(types.SET_USER, ReduxAsyncOperation.FETCHING, user));
      apiRequest.then(response => {
        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, user));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateProfile(data:UserProfileRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateProfile(data, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const user = state.user ? _.cloneDeep(state.user.data) : null;
      dispatch(setData(types.SET_USER, ReduxAsyncOperation.UPDATING, user));
      apiRequest.then(response => {
        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, user));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateProfileAvatar(picture:PictureRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateProfileAvatar(picture, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const user = state.user ? _.cloneDeep(state.user.data) : null;
      dispatch(setData(types.SET_USER, ReduxAsyncOperation.UPDATING, user));
      apiRequest.then(response => {
        if(user) {
          user.avatar = response.data;
        }
        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, user));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, user));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteProfileAvatar():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteProfileAvatar({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const user = state.user ? _.cloneDeep(state.user.data) : null;
      dispatch(setData(types.SET_USER, ReduxAsyncOperation.UPDATING, user));
      apiRequest.then(response => {
        if(user) {
          user.avatar = null;
        }
        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, user));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, user));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function changePassword(oldPassword:string, newPassword:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.changePassword({oldPassword, newPassword}, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const user = state.user ? _.cloneDeep(state.user.data) : null;
      dispatch(setData(types.SET_USER, ReduxAsyncOperation.UPDATING, user));
      apiRequest.then(response => {
        if(user) {
          user.forcePasswordReset = false;
        }
        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, user));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, user));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function setAuthenticated(data:boolean):ReduxActionData {
  return {
    type: types.SET_AUTHENTICATED,
    payload: data
  }
}

export function setProfile(data?:User):ThunkAction<void, ReduxState, any, AnyAction> {
  return (dispatch) => {
    dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, data));
  }
}

export function setLogout(data:ReduxState):ReduxActionData {
  return {
    type: types.LOGOUT,
    payload: data
  }
}

const setAuthRequests = (dispatch:Dispatch, op:ReduxAsyncOperation, value?:any) => {
  dispatch(setData(types.SET_TOKENS, op, value));
  dispatch(setData(types.SET_USER, op, value));
  dispatch(setData(types.SET_ACCOUNT, op, value));
  dispatch(setData(types.SET_LOAN_SETTINGS, op, value));
  dispatch(setData(types.SET_MESSAGE_SETTINGS, op, value));
  dispatch(setData(types.SET_INTEGRATIONS, op, value));
};

const login = (username:string, password:string, dispatch:Dispatch, getState:()=>ReduxState, data?:any) => {
  const cancelSource = createCancelSource();
  let requests:AxiosPromise[] = [];

  const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
    setAuthRequests(dispatch, ReduxAsyncOperation.FETCHING);
    dispatch(setInactivityLogout(false));

    const tokenRequestData:TokenRequest = {
      username,
      password,
      grant_type: "password",
      client_id: process.env.REACT_APP_ORCA_LOANS_CLIENT_ID || "",
      client_secret: process.env.REACT_APP_ORCA_LOANS_CLIENT_SECRET || ""
    }

    // @ts-ignore
    tokenRequestData.data = data;

    Api.getToken(tokenRequestData).then((result) => {
      // get the token
      const tokens = { accessToken: result.data.access_token, refreshToken: result.data.refresh_token };
      setBearerSecurityWorker(result.data.access_token);

      const state = getState();
      requests = [
        Api.getProfile({cancelToken:cancelSource.token}),
        Api.getAccount({cancelToken:cancelSource.token}),
        Api.getLoanSettings({cancelToken:cancelSource.token}),
        Api.getMessageSettings({cancelToken:cancelSource.token}),
        Api.getIntegrations({cancelToken:cancelSource.token})
      ];

      // get profile and settings
      requests.push(SocketUtil.authenticate(result.data.access_token));

      Promise.all(requests).then(results => {
        const user = results[0].data;
        const account = results[1].data;
        const loanSettings = results[2].data;
        const messageSettings = results[3].data;
        const integrations = results[4].data;

        if(user.account !== state.design!.data.account) {
          setAuthRequests(dispatch, ReduxAsyncOperation.IDLE, null);
          return reject(new Error('User account does not match the requested account'));
        }

        dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, user));
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        dispatch(setData(types.SET_TOKENS, ReduxAsyncOperation.IDLE, tokens));
        dispatch(setData(types.SET_LOAN_SETTINGS, ReduxAsyncOperation.IDLE, loanSettings));
        dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.IDLE, messageSettings));
        dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
        dispatch(setAuthenticated(true));

        resolve(results[0]);
      }).catch(err => {
        setAuthRequests(dispatch, ReduxAsyncOperation.IDLE, null);
        reject(err);
      });
    }).catch(error => {
      setAuthRequests(dispatch, ReduxAsyncOperation.IDLE, null);
      reject(error);
    });
  });

  return {
    send: () => request,
    cancel: cancelSource.cancel
  };
};

export function authenticateOneTimeLogin(otlToken:string) {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      setAuthRequests(dispatch, ReduxAsyncOperation.FETCHING);

      Api.authorizeOneTimeLoginToken({token: otlToken}, {cancelToken:cancelSource.token}).then(result => {
        const {accessToken, refreshToken} = result.data;
        const tokens = { accessToken, refreshToken };
        setBearerSecurityWorker(accessToken);

        const state = getState();

        let requests:AxiosPromise[] = [
          Api.getProfile({cancelToken:cancelSource.token}),
          Api.getAccount({cancelToken:cancelSource.token}),
          Api.getLoanSettings({cancelToken:cancelSource.token}),
          Api.getMessageSettings({cancelToken:cancelSource.token}),
          Api.getIntegrations({cancelToken:cancelSource.token})
        ];

        // get profile and settings
        requests.push(SocketUtil.authenticate(accessToken));

        Promise.all(requests).then(results => {
          const user = results[0].data;
          const account = results[1].data;
          const loanSettings = results[2].data;
          const messageSettings = results[3].data;
          const integrations = results[4].data;

          if(user.account !== state.design!.data.account) {
            setAuthRequests(dispatch, ReduxAsyncOperation.IDLE, null);
            return reject(new Error('User account does not match the requested account'));
          }

          dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, user));
          dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
          dispatch(setData(types.SET_TOKENS, ReduxAsyncOperation.IDLE, tokens));
          dispatch(setData(types.SET_LOAN_SETTINGS, ReduxAsyncOperation.IDLE, loanSettings));
          dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.IDLE, messageSettings));
          dispatch(setData(types.SET_INTEGRATIONS, ReduxAsyncOperation.IDLE, integrations));
          dispatch(setAuthenticated(true));

          resolve(user);
        }).catch(err => {
          setAuthRequests(dispatch, ReduxAsyncOperation.IDLE, null);
          reject(err);
        });
      }).catch((ex) => {
        reject(ex);
      });
    });

    return {
      send: () => request,
      cancel: cancelSource.cancel
    };
  };
}

export function addDontPromptMobilePhone(userId:string):ReduxActionData {
  return {
    type: types.ADD_DONT_PROMPT_MOBILE_PHONE,
    payload: userId
  }
}

export function removeDontPromptMobilePhone(userId:string):ReduxActionData {
  return {
    type: types.REMOVE_DONT_PROMPT_MOBILE_PHONE,
    payload: userId
  }
}