import React from 'react';
import {injectIntl, WrappedComponentProps} from "react-intl";
import {withStyles, Typography, WithStyles} from "@material-ui/core";
import styles from "../../../theme/jss/components/calculatorResultsStyles";
import {Section} from "../../../components";
import {Disclaimer} from "./index";
import IntlFormatter from "../../../intl";
import CalculatorResultSet, {CalculatorResultSetProps} from './CalculatorResultSet';

export type CalculatorResultsProps = {
  results: CalculatorResultSetProps[],
  summary?: string
}

type Props = CalculatorResultsProps
  & WrappedComponentProps
  & WithStyles<typeof styles>

class CalculatorResults extends React.Component<Props> {
  render() {

    const { intl, classes, summary, results } = this.props;

    return (
      <Section title={IntlFormatter.formatMessage(intl, 'your_results')}>
        {summary &&
          <div className={classes.summary}>
            <Typography variant="body1" color="inherit" dangerouslySetInnerHTML={{ __html: summary}} />
          </div>
        }

        {results.map((result, i) => {
          return (
            <div key={i} className={classes.resultSet}>
              <CalculatorResultSet title={result.title}
                                   amortization={result.amortization}
                                   chart={result.chart}
                                   items={result.items} />
            </div>
          )
        })}

        <Disclaimer className={classes.disclaimer} />
      </Section>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(CalculatorResults));
