import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiIcon-root': {
      marginRight: theme.spacing(1)
    }
  }
});

export default styles;
