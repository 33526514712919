import {hexToRgb} from "../index";
import {createStyles, Theme} from "@material-ui/core";

const navPillsStyle = (theme:Theme) => createStyles({
  root: {
    marginTop: "20px",
    paddingLeft: "0",
    marginBottom: "0",
    overflow: "visible !important"
  },
  flexContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap"
    }
  },
  displayNone: {
    display: "none !important"
  },
  fixed: {
    overflow: "visible !important"
  },
  horizontalDisplay: {
    display: "block"
  },
  pills: {
    float: "left",
    position: "relative",
    display: "block",
    borderRadius: "30px",
    minWidth: "100px",
    textAlign: "center",
    transition: "all .3s",
    padding: "10px 15px",
    color: theme.palette.neutral.main,
    height: "auto",
    opacity: "1",
    maxWidth: "100%",
    margin: "0 5px",
    minHeight: "unset",
    lineHeight: "24px",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: 500,
  },
  pillsWithIcons: {
    borderRadius: "4px"
  },
  tabIcon: {
    width: "30px",
    height: "30px",
    display: "block",
    margin: "15px 0 !important"
  },
  horizontalPills: {
    width: "100%",
    // @ts-ignore
    float: "none !important",
    "& + button": {
      margin: "10px 0"
    }
  },
  contentWrapper: {
    marginTop: "20px",
    "& .react-swipeable-view-container > div > div": {
      paddingLeft: "15px",
      paddingRight: "15px"
    }
  },
  primary: {
    "&,&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(theme.palette.black) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(theme.palette.primary.main) +
        ", 0.4)"
    }
  },
  info: {
    "&,&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.info.main,
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(theme.palette.black) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(theme.palette.success.main) +
        ", 0.4)"
    }
  },
  success: {
    "&,&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.success.main,
      boxShadow:
        "0 2px 2px 0 rgba(" +
        hexToRgb(theme.palette.success.main) +
        ", 0.14), 0 3px 1px -2px rgba(" +
        hexToRgb(theme.palette.success.main) +
        ", 0.2), 0 1px 5px 0 rgba(" +
        hexToRgb(theme.palette.success.main) +
        ", 0.12)"
    }
  },
  warning: {
    "&,&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.warning.main,
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(theme.palette.black) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(theme.palette.warning.main) +
        ", 0.4)"
    }
  },
  danger: {
    "&,&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.danger.main,
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(theme.palette.black) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(theme.palette.warning.main) +
        ", 0.4)"
    }
  },
  rose: {
    "&,&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.rose.main,
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(theme.palette.black) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(theme.palette.rose.main) +
        ", 0.4)"
    }
  },
  white: {
    "&,&:hover": {
      color: theme.palette.rose.main,
      backgroundColor: theme.palette.white,
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(theme.palette.black) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(theme.palette.rose.main) +
        ", 0.4)"
    }
  },
  alignCenter: {
    alignItems: "center",
    justifyContent: "center"
  },
  tabLabelContainer: {
    padding: "unset !important"
  },
  tabContent: {}
});

export default navPillsStyle;
