import React, {Component} from 'react';
import {Redirect, Link as RouterLink, RouteComponentProps} from 'react-router-dom';
import {Grid, Typography, Link, withStyles, WithStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {LoginForm} from '../forms';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {Page} from '../../../layouts';
import {AppLogo, Alert} from '../../../components';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {UrlFooter} from "../../../components";
import clsx from "clsx";
import {ReduxUtil, UserUtil} from "../../../utils";
import {ReduxAccount, ReduxUser} from "../../../types";
import {Mixpanel} from "mixpanel-browser";
import {ReduxState} from "../../../data/initialState";
import {AxiosResponse} from "axios";
import {TokenResponse} from "@jerseydev/orca-loans";

type Props = {
  mixpanel: Mixpanel,
  account: ReduxAccount,
  user: ReduxUser,
  inactivityLogout: boolean|undefined,
  dontPromptForMobileNumber: string[]|null
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  loading: boolean,
  redirectTo?: string,
  password?: string
}

class LoginPage extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount = () => {
    const { user } = this.props;
    if(ReduxUtil.hasData(user)) {
      this.setState({ redirectTo: '/dashboard' });
    }
  };

  onSubmit = (response:AxiosResponse<TokenResponse & {email:string, password:string}>) => {
    const { user, account, location, mixpanel, dontPromptForMobileNumber } = this.props;
    mixpanel.identify(user.data._id);
    const mixpanelProfile:any = {
      "$first_name": user.data.firstName,
      "$last_name": user.data.lastName,
      "$name": user.data.fullName,
      "$email": user.data.email,
      '$timezone': user.data.timezone,
      '$created': new Date(user.data.created).toISOString(),
      'Plan': account.data.plan,
      'Account Name': account.data.name,
      'Account ID': account.data._id,
      'Borrower': UserUtil.isBorrower(user.data),
    };

    if(user.data.avatar) {
      mixpanelProfile.$avatar = user.data.avatar.url;
    }

    mixpanel.people.set(mixpanelProfile);
    mixpanel.track("Login");

    if(user.data.forcePasswordReset) {
      this.setState({redirectTo: '/change-password', password: response.data.password});
    } else if (!user.data.mobilePhoneNumber && ((dontPromptForMobileNumber && !dontPromptForMobileNumber.includes(user.data._id)) || !dontPromptForMobileNumber) && account.data.configured) {
      this.setState({redirectTo: '/account/mobile-phone'});
    } else {
      const { from }:any = location.state || { from: '/dashboard' };
      this.setState({ redirectTo: from });
    }
  };

  render() {
    const {  intl, classes, inactivityLogout } = this.props;
    const { redirectTo, loading, password } = this.state;

    if (redirectTo) {
      return <Redirect to={{pathname: redirectTo, state: {oldPassword: password} }}/>
    }

    return (
      <Page pageTitle={IntlFormatter.formatMessage(intl, 'login')}
            loading={loading}>
        <Grid container>
          <Grid item md={6}>
            <div className={classes.houseBg1}>
              <div className={classes.bgOverlay}>

              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className={classes.p4}>
              <div className={clsx(classes.mb3, classes.center)}>
                <AppLogo color="dark"/>
              </div>
              <div className={clsx(classes.mt3, classes.textCenter)}>
                <Typography variant="h2" color="inherit">
                  <FormattedMessage id="login" />
                </Typography>
              </div>

              <div className={classes.p3}>
                {inactivityLogout &&
                  <div className={classes.mb2}>
                    <Alert severity="warning">
                      <FormattedMessage id="logged_out_for_inactivity" />
                    </Alert>
                  </div>
                }

                <LoginForm onSubmit={this.onSubmit} />
              </div>
              <div className={clsx(classes.mt3, classes.textCenter)}>
                <Typography variant="body1">
                  <FormattedMessage id="dont_have_an_account" />{' '}
                  <Link component={RouterLink} to="/register">
                    <FormattedMessage id="sign_up" />
                  </Link>
                </Typography>
              </div>
              <UrlFooter/>
            </div>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    account: state.account,
    user: state.user,
    inactivityLogout: state.inactivityLogout,
    dontPromptForMobileNumber: state.dontPromptForMobileNumber
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoginPage)));
