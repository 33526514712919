import React, {RefObject, MouseEvent, ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {Grid, Button, withStyles, Icon, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {
  ErrorList, HelperTooltip,
  MoneyInput, PercentageTextField,
  Select,
  SubmitButton,
} from "../../../components/index";
import {CalculatorUtil, ErrorUtil} from '../../../utils';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import Api from '../../../lib/Api';
import {ChartComponentProps, Doughnut} from "react-chartjs-2";
import CalculatorResults, {CalculatorResultsProps} from "../components/CalculatorResults";
import _ from 'lodash';
import {ErrorState, FixMeLater, LabelNumberType} from "../../../types";
import themePalette from "../../../theme/jss/palette";
import {MonthlyPaymentCalculatorRequest, MonthlyPaymentCalculatorResponse} from "@jerseydev/orca-loans";
import {CalculatorResultSetProps} from "../components/CalculatorResultSet";
import {Mixpanel} from "mixpanel-browser";

const palette:FixMeLater = themePalette;

type Props = {
  mixpanel: Mixpanel,
  onSubmit?: (data:MonthlyPaymentCalculatorResponse) => void,
  onCancel?: () => void
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  loanAmount: string,
  interestRate: string,
  term: string,
  homeValue: string,
  annualTaxes: string,
  annualInsurance: string,
  annualPMI: string
}

type State = {
  loading: boolean,
  form: Form,
  result?: CalculatorResultsProps|null,
  errors: ErrorState[],
}

class MonthlyPaymentForm extends BaseForm<Props, State> {
  terms:LabelNumberType[] = [];
  resultsRef:RefObject<HTMLDivElement>;

  chartColors:string[] = [
    palette.info.main,
    palette.danger.main,
    palette.warning.main,
    palette.success.main,
  ];

  chartOptions = CalculatorUtil.getPieChartOptions();

  constructor(props:Props) {
    super(props);

    this.resultsRef = React.createRef();

    const form:State["form"] = {
      loanAmount: '',
      interestRate: '',
      term: '',
      homeValue: '',
      annualTaxes: '',
      annualInsurance: '',
      annualPMI: ''
    };

    /*const form:State["form"] = {
      loanAmount: '250000',
      interestRate: '5.875',
      term: '30',
      homeValue: '300000',
      annualTaxes: '3000',
      annualInsurance:' 1500',
      annualPMI: '.5'
    };*/

    this.state = {
      loading: false,
      form,
      result: null,
      errors: [],
    };

    this.terms = CalculatorUtil.getSelectLoanTerms(props.intl);
  }

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    try {
      this.setState({ loading: true, result: null, errors: [] });

      const {loanAmount, interestRate, term, homeValue, annualTaxes, annualInsurance, annualPMI} = this.state.form;

      const requestData:MonthlyPaymentCalculatorRequest = {
        loanAmount: parseInt(loanAmount),
        interestRate: parseFloat(interestRate),
        term: parseInt(term),
        homeValue: parseInt(homeValue),
        annualTaxes: parseInt(annualTaxes),
        annualInsurance: parseInt(annualInsurance),
        annualPMI: parseFloat(annualPMI)
      };

      const result = await Api.calculateMonthlyPayment(requestData);
      const chartData = _.cloneDeep(this.getChartData(result.data));
      this.setState({
        loading: false,
        result: {
          summary: result.data.description,
          results: [{
            amortization: result.data.amortization,
            items: this.getResultItems(result.data),
            chart: <Doughnut data={chartData} options={this.chartOptions} redraw />
          }]
        }
      });
      this.props.mixpanel.track("Mortgage Calculator", { name: "Monthly Payment", action: "submitted" });
      if(this.props.onSubmit) {
        this.props.onSubmit(result.data);
      }
    } catch(e) {
      this.onError(e);
    }
  };

  onError = (err:any) => {
    this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
  };

  getChartData = (resultData:MonthlyPaymentCalculatorResponse):ChartComponentProps["data"] => {
    const { intl } = this.props;

    return {
      datasets: [{
        data: [
          resultData.monthlyPrincipalAndInterest.toFixed(2),
          resultData.monthlyTaxes.toFixed(2),
          resultData.monthlyInsurance.toFixed(2),
          resultData.monthlyPMI.toFixed(2),
        ],
        backgroundColor: this.chartColors
      }],
      labels: [
        IntlFormatter.formatMessage(intl, 'monthly_principal_interest'),
        IntlFormatter.formatMessage(intl, 'monthly_taxes'),
        IntlFormatter.formatMessage(intl, 'monthly_insurance'),
        IntlFormatter.formatMessage(intl, 'monthly_pmi'),
      ]
    };
  };

  getResultItems = (result:MonthlyPaymentCalculatorResponse) => {
    const {intl} = this.props;

    const items:CalculatorResultSetProps["items"] = [
      { color: this.chartColors[0], label: IntlFormatter.formatMessage(intl, 'monthly_principal_interest'), value: result.monthlyPrincipalAndInterest.toMoney() },
      { color: this.chartColors[1], label: IntlFormatter.formatMessage(intl, 'monthly_taxes'), value: result.monthlyTaxes.toMoney() },
      { color: this.chartColors[2], label: IntlFormatter.formatMessage(intl, 'monthly_insurance'), value: result.monthlyInsurance.toMoney() },
    ];

    if(result.monthlyPMI > 0) {
      items.push(
        { color: this.chartColors[3], label: IntlFormatter.formatMessage(intl, 'monthly_pmi_months', {months: result.monthsWithPMI}), value: result.monthlyPMI.toMoney() },
      )
    }

    items.push(
      { label: IntlFormatter.formatMessage(intl, 'monthly_payment'), value: result.monthlyPayment.toMoney(), align: 'right', size: 'large' },
    );

    return items;
  };

  render() {

    const { intl, classes, onCancel } = this.props;
    const { form, loading, errors, result } = this.state;


    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] })}} />
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'loan_amount')}
                            value={form.loanAmount}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loanAmount')}
                            validators={['required', 'minNumber:1']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 1 })
                            ]} />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Select name="term"
                        options={this.terms}
                        placeholder={IntlFormatter.formatMessage(intl, 'select_term')}
                        onChange={(item:LabelNumberType) => this.onItemChange(item ? item.value : '', 'form.term')}
                        value={form.term !== '' ? this.terms.find(m => m.value === parseInt(form.term)) : ''}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required')
                        ]}
                        label={IntlFormatter.formatMessage(intl, 'term')} />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <PercentageTextField name="interestRate"
                                     label={IntlFormatter.formatMessage(intl, 'interest_rate')}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.interestRate')} value={form.interestRate} fullWidth={true}
                                     validators={['required', 'minFloat:0', 'maxFloat:30']}
                                     errorMessages={[
                                       IntlFormatter.formatMessage(intl, 'validation_required'),
                                       IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                       IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 30 })
                                     ]} />
              </Grid>
            </Grid>
          </div>

          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'home_value')}
                            value={form.homeValue}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.homeValue')}
                            validators={['required', 'minNumber:1']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 1 })
                            ]} />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'annual_taxes')}
                            value={form.annualTaxes}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.annualTaxes')}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]} />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'annual_insurance')}
                            value={form.annualInsurance}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.annualInsurance')}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]} />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <PercentageTextField name="pmi"
                                     label={IntlFormatter.formatMessage(intl, 'annual_pmi')}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.annualPMI')}
                                     value={form.annualPMI}
                                     fullWidth={true}
                                     helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'pmi_help')}
                                                                text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                                     validators={['required', 'minFloat:0', 'maxFloat:100']}
                                     errorMessages={[
                                       IntlFormatter.formatMessage(intl, 'validation_required'),
                                       IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                       IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                     ]} />
              </Grid>
            </Grid>
          </div>

          <div className={classes.mt3}>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              {onCancel &&
              <Grid item>
                <Button onClick={onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}
                              size="large"
                              startIcon={<Icon>calculate</Icon>}>
                  <FormattedMessage id={result ? 'recalculate' : 'calculate'} />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>

        <div ref={this.resultsRef}>
          {result &&
            <div className={classes.mt3}>
              <CalculatorResults summary={result.summary}
                                 results={result.results} />

            </div>
          }
        </div>
      </div>
    );
  }
}



export default withStyles(pageStyles, { withTheme: true })(injectIntl(MonthlyPaymentForm));
