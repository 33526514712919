import React, {useEffect, useState} from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {
  withStyles,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Icon,
  ListItemText,
  WithStyles,
} from "@material-ui/core";
import styles from "../theme/jss/layouts/pageStyles";
import {connect} from "react-redux";
import IntlFormatter from "../intl";
import {Alert, ErrorList} from './index';
import {ErrorUtil, LoanUtil, ReduxUtil} from "../utils";
import {
  LoanApplication2009,
  LoanApplication,
  LoanDocumentCategory,
} from "@jerseydev/orca-loans";
import pageStyles from "../theme/jss/layouts/pageStyles";
import {ActionProps, ErrorState, ReduxIntegrations, ReduxLoanDocumentCategories} from "../types";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {getLoanDocumentCategories} from "../actions/loanDocumentCategories";
import {getIntegrations} from "../actions/integrations";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  integrations: ReduxIntegrations,
  loanDocumentCategories: ReduxLoanDocumentCategories,
  getLoanDocumentCategories: ActionProps["getLoanDocumentCategories"]
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

const LoanDocumentChecklist = (props:Props) => {
  const {classes, intl, loanApplication, loanDocumentCategories, integrations, getLoanDocumentCategories} = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<ErrorState[]>([]);
  const [documentCategories, setDocumentCategories] = useState<LoanDocumentCategory[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let allCategories:LoanDocumentCategory[] = [];
        if(ReduxUtil.hasData(loanDocumentCategories)) {
          allCategories = loanDocumentCategories.data;
        } else {
          const result = await getLoanDocumentCategories().send();
          allCategories = result.data;
        }

        let categories:LoanDocumentCategory[] = [];
        if(loanApplication.loan && loanApplication.loan.loanType && loanApplication.loan.loanPurpose) {
          categories = allCategories.filter(c => c.loanTypes.includes(loanApplication.loan!.loanType!) && c.loanPurposes.includes(loanApplication.loan!.loanPurpose!));
        }

        setDocumentCategories(categories);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setErrors(ErrorUtil.formatErrors(e));
      }
    }

    fetchData();
  }, [loanApplication, loanDocumentCategories, getLoanDocumentCategories]);


  if((documentCategories.length === 0) || loading) {
    return null;
  }

  if(!loading && errors.length > 0) {
    return <ErrorList errors={errors} />
  }

  return (
    <div>
      {documentCategories.length > 0 &&
      <div>
        <Typography variant="subtitle1">
          <FormattedMessage id="document_checklist" />
        </Typography>
        {LoanUtil.hasAllRequiredDocuments(loanApplication, documentCategories, integrations.data) &&
        <div className={classes.mv2}>
          <Alert severity="success">
            <FormattedMessage id="all_documents_uploaded" />
          </Alert>
        </div>
        }
        <List>
          {documentCategories.map((category, i) => {
            const hasDocumentCategory = LoanUtil.hasCompletedDocumentCategory(category, loanApplication, integrations.data);
            return (
              <ListItem key={i}>
                <ListItemIcon>
                  <Icon className={hasDocumentCategory ? classes.success : classes.warning}>{hasDocumentCategory ? 'check_circle' : 'schedule'}</Icon>
                </ListItemIcon>
                <ListItemText primary={category.name}
                              secondary={category.required ? '*' + IntlFormatter.formatMessage(intl, 'required') : null}/>
              </ListItem>
            )
          })}
        </List>
      </div>
      }
    </div>
  )
};

const mapStateToProps = (state:ReduxState) => {
  return {
    integrations: state.integrations,
    loanDocumentCategories: state.loanDocumentCategories,
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getLoanDocumentCategories() {
    return dispatch(getLoanDocumentCategories());
  },
  getIntegrations() {
    return dispatch(getIntegrations());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(LoanDocumentChecklist)));
