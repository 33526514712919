import React from 'react';
import {InputAdornment} from '@material-ui/core';
import TextField, {Props} from "./TextField";

class PercentageTextField extends React.Component<Props> {
  render() {
    const { InputProps, type, ...rest } = this.props;
    // @ts-ignore
    return <TextField {...rest} type="number" InputProps={{
                 ...InputProps,
                 endAdornment: <InputAdornment position="end">%</InputAdornment>
               }} />
  }
}

export default PercentageTextField;
