import React, {ChangeEvent, MouseEvent} from 'react';
import Form from './Form';
import {Grid, Button, Typography, withStyles, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../intl';
import BaseForm from "./BaseForm";
import {ErrorList, SubmitButton, TextField} from "../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {addBankAccount, updateBankAccount} from "../actions/bankAccounts";
import {ErrorUtil} from '../utils';
import pageStyles from "../theme/jss/layouts/pageStyles";
import {BankAccount, BankAccountRequest, BankAccountUpdateRequest} from "@jerseydev/orca-loans";
import {ActionResponse, ErrorState} from "../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {Mixpanel} from "mixpanel-browser";
import {AxiosResponse} from "axios";

type Props = {
  mixpanel: Mixpanel,
  bankAccount?: BankAccount,
  onCancel?: () => void,
  onSubmit?: (bankAccount:AxiosResponse<BankAccount>) => void,
  addBankAccount: (data:BankAccountRequest) => ActionResponse,
  updateBankAccount: (id:string, data:BankAccountUpdateRequest) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  loading: boolean,
  form: BankAccountRequest|BankAccountUpdateRequest,
  errors: ErrorState[]
}

class BankAccountForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: props.bankAccount ? _.cloneDeep(props.bankAccount) : {
        account_holder_name: '',
        account_number: '', // 000123456789
        routing_number: '', // 110000000
        account_holder_type: 'company'
      },
      errors: []
    };
  }

  onSubmit = async (event:MouseEvent) => {
    try {
      event.preventDefault();
      this.setState({ loading: true, errors: [] });
      const form = _.cloneDeep(this.state.form);
      let result:AxiosResponse<BankAccount>;
      if(this.props.bankAccount && this.props.bankAccount.id) {
        result = await this.props.updateBankAccount(this.props.bankAccount.id, form as BankAccountUpdateRequest).send();
        this.props.mixpanel.track('Bank account updated');
      } else {
        result = await this.props.addBankAccount(form as BankAccountRequest).send();
        this.props.mixpanel.track('Bank account added');
      }
      this.setState({ loading: false });
      if(this.props.onSubmit) {
        this.props.onSubmit(result);
      }
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes, onCancel, bankAccount } = this.props;
    const { form, loading, errors } = this.state;


    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] })}} />

          <div className={classes.mb2}>
            <TextField name="accountHolderName"
                       label={IntlFormatter.formatMessage(intl, 'account_holder_name')}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.account_holder_name')}
                       value={form.account_holder_name}
                       fullWidth={true}
                       validators={['required']}
                       errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
          </div>
          {bankAccount &&
            <div>
              <div className={classes.mb2}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="routing_number" />: {bankAccount.routing_number}
                </Typography>
              </div>

              <Typography variant="subtitle1">
                <FormattedMessage id="account_number" />: ****{bankAccount.last4}
              </Typography>
            </div>
          }
          {("routing_number" in form && "account_number" in form) &&
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <TextField name="routingNumber"
                           label={IntlFormatter.formatMessage(intl, 'routing_number')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.routing_number')}
                           value={form.routing_number}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <TextField name="accountNumber"
                           label={IntlFormatter.formatMessage(intl, 'account_number')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.account_number')}
                           value={form.account_number}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
              </Grid>
            </Grid>
          }

          <div className={classes.mv2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {onCancel &&
                <Grid item>
                  <Button onClick={onCancel}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {

  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addBankAccount(data:BankAccountRequest) {
    return dispatch(addBankAccount(data));
  },
  updateBankAccount(id:string, data:BankAccountUpdateRequest) {
    return dispatch(updateBankAccount(id, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(BankAccountForm)));
