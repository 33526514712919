import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {
  Grid,
  ListItemText,
  Typography,
  withStyles,
  IconButton,
  Icon,
  Menu,
  MenuItem,
  ListItemIcon,
  Hidden,
  Divider, WithStyles
} from "@material-ui/core";
import {
  PageTitle,
  PairedList,
  DeleteDialog,
  Section,
  UserList,
  LoanOfficerList
} from "../../../components";
import {Link, Redirect, RouteComponentProps} from 'react-router-dom';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {AclUtil, ErrorUtil, LocationUtil} from "../../../utils";
import _ from 'lodash';
import config from "../../../config";
import clsx from "clsx";
import styles from "../../../theme/jss/layouts/pageStyles";
import {DialogState, ReduxLoanSettings, ReduxUser, ActionProps, SnackbarState, ErrorState} from "../../../types";
import {Branch, KeyValueMixed, LoanOfficer} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  settings: ReduxLoanSettings,
  user: ReduxUser
} & WrappedComponentProps
  & WithStyles<typeof styles>
  & RouteComponentProps<{id:string}>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  branch: Branch|null,
  loanOfficers: LoanOfficer[]
  selectedActionMenu?: Element|null,
  deleteDialog: DialogState,
  errorStatusCode?: number|null,
  errors: ErrorState[],
  redirectTo?: string,
  snackbar?: SnackbarState
}

class BranchDetailPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      branch: null,
      loanOfficers: [],
      selectedActionMenu: null,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      errorStatusCode: null,
      errors: []
    };
  }

  componentDidMount = async () => {
    try {
      const branchResult = await this.props.routeProps.getBranch(this.props.match.params.id).send();
      const branch = _.cloneDeep(branchResult.data);
      const loanOfficerResult = await this.props.routeProps.searchLoanOfficers({ branch: this.props.match.params.id }).send();
      const loanOfficers = _.cloneDeep(loanOfficerResult.data);
      this.setState({ pageLoaded: true, loanOfficers, branch });
    } catch (e) {
      this.setState({
        pageLoaded: true,
        errorStatusCode: e.response ? e.response.status : e.status,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  getPageTitle = () => {
    const { branch } = this.state;
    if(branch) {
      return branch.name;
    }

    return '';
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="person" />
    )
  };

  getDetailItems = () => {
    const { intl } = this.props;
    const { branch } = this.state;

    let items:KeyValueMixed[] = [];
    if(branch) {
      items = [
        { key: IntlFormatter.formatMessage(intl, 'email'), value: branch.email },
        { key: IntlFormatter.formatMessage(intl, 'nmls_id'), value: branch.nmlsId }
      ];

      if(branch.address) {
        items.push({ key: IntlFormatter.formatMessage(intl, 'address'), value: LocationUtil.formatLocation(branch.address) })
      }

      branch.phoneNumbers.forEach(phoneNumber => {
        items.push({ key: IntlFormatter.formatMessage(intl, phoneNumber.type ? phoneNumber.type : 'other'), value: `${phoneNumber.number} ${phoneNumber.ext ? `x${phoneNumber.ext}` : ''}` })
      });
    }

    return items;
  };

  onActionMenuClick = (event:MouseEvent) => {
    this.setState({ selectedActionMenu: event.currentTarget });
  };

  onActionMenuClose = () => {
    this.setState({ selectedActionMenu: null });
  };

  showDeleteDialog = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = true;
    this.setState({ selectedActionMenu: null, deleteDialog });
  };

  hideDeleteDialog = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = false;
    this.setState({ deleteDialog });
  };

  onDeleteSubmit = async () => {
    try {
      let deleteDialog = _.clone(this.state.deleteDialog);
      deleteDialog.loading = true;
      this.setState({ deleteDialog });
      if(this.state.branch) {
        await this.props.routeProps.deleteBranch(this.state.branch._id).send();
        this.props.mixpanel.track("Branch archived");
      }
      this.setState({
        redirectTo: '/admin/branches',
        snackbar: {
          open: true,
          variant: 'success',
          message: IntlFormatter.formatMessage(this.props.intl, 'branch_deleted')
        },
      });
    } catch (e) {
      let deleteDialog = _.clone(this.state.deleteDialog);
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({ deleteDialog });
    }
  };

  render() {

    const { intl, classes, settings, user } = this.props;
    const { pageLoaded, loading, branch, loanOfficers, selectedActionMenu, deleteDialog, redirectTo, snackbar, errorStatusCode } = this.state;


    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo, state: { snackbar } }}  />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   loading={loading}
                   pageLoaded={pageLoaded}
                   errorStatusCode={errorStatusCode}
                   breadcrumbs={[
                     { icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     { title: IntlFormatter.formatMessage(intl, 'branches'), to: '/admin/branches' },
                     { title: this.getPageTitle() }
                   ]}>
        {branch &&
          <div>
            <DeleteDialog open={deleteDialog.open}
                          title={IntlFormatter.formatMessage(intl, 'delete_branch')}
                          item={branch.name}
                          errors={deleteDialog.errors}
                          loading={deleteDialog.loading}
                          onCancel={this.hideDeleteDialog}
                          onSubmit={this.onDeleteSubmit} />
              <div>
                <Menu anchorEl={selectedActionMenu}
                      open={Boolean(selectedActionMenu)}
                      onClose={this.onActionMenuClose}>
                  <MenuItem component={Link} to={`/admin/branches/edit/${branch._id}`}>
                    <ListItemIcon>
                      <Icon>mode_edit</Icon>
                    </ListItemIcon>
                    <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
                  </MenuItem>
                  {AclUtil.isOwner(user.data) &&
                    <MenuItem onClick={this.showDeleteDialog}
                              disabled={settings.data.defaultBranch!._id === branch._id}>
                      <ListItemIcon>
                        <Icon>cancel</Icon>
                      </ListItemIcon>
                      <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')}/>
                    </MenuItem>
                  }
                </Menu>
              </div>
            <div style={{ overflow: 'hidden' }}>
              <div className={classes.ph2}>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <IconButton onClick={event => this.onActionMenuClick(event)}>
                      <Icon>more_vert</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
              <Divider />

              <div className={classes.p2}>

                {branch.picture &&
                  <Hidden smUp>
                    <div className={clsx(classes.center, classes.mv2)}>
                      <img src={branch.picture ? branch.picture.url : config.placeholders.building}
                           alt={branch.name}
                           className={classes.thumbnail} />
                    </div>
                  </Hidden>
                }

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={8}>
                    <Grid container spacing={2}>
                      <Hidden xsDown>
                        <Grid item sm={3}>
                          <div className={classes.ml2}>
                            <img src={branch.picture? branch.picture.url : config.placeholders.building}
                                 alt={branch.name}
                                 className={classes.imgResponsive} />
                          </div>
                        </Grid>
                      </Hidden>
                      <Grid item xs={12} sm={9}>
                        {branch.description &&
                          <div className={clsx(classes.mb2, classes.ph2)}>
                            <Typography variant="body1">
                              {branch.description}
                            </Typography>
                          </div>
                        }

                        <PairedList items={this.getDetailItems()} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    {branch.manager &&
                      <div className={classes.mb3}>
                        <Section title={IntlFormatter.formatMessage(intl, 'branch_manager')}>
                          <UserList users={[branch.manager]} />
                        </Section>
                      </div>
                    }

                    <Section title={IntlFormatter.formatMessage(intl, 'loan_officers')}>
                      <LoanOfficerList loanOfficers={loanOfficers} />
                    </Section>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        }
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    settings: state.loanSettings,
    user: state.user
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(BranchDetailPage)));
