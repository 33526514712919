import React, {MouseEvent, ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  IconButton,
  Icon,
  Button,
  Typography,
  Link,
  InputLabel,
  withStyles,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Select as RNSelect,
  FormControlLabel,
  WithStyles,
  Switch
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {
  ErrorList,
  PhoneNumberInput,
  SubmitButton,
  Dropzone,
  TextField,
  Select,
  InlineCard,
  AvatarEditor,
  Section,
  Fab,
  FormSection,
  LoanOfficerBorrowerSignupLink, DeleteDialog
} from "../../../components";
import moment from "moment-timezone";
import _ from 'lodash';
import {connect} from "react-redux";
import {
  updateLoanOfficer,
  getLoanOfficer, addLoanOfficerPicture, removeLoanOfficerPicture
} from "../../../actions/loanOfficers";
import {ErrorUtil, IntegrationUtil, PhoneNumberUtil, UserUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {getProfile} from "../../../actions/auth";
import clsx from "clsx";
import {
  ActionResponse,
  DialogState,
  FileWithPreview,
  LabelValuePairType,
  ReduxApp,
  ReduxUser,
  ErrorState, ReduxIntegrations
} from "../../../types";
import {
  LoanOfficer, LoanOfficerUpdateRequest,
  ManagedFile,
  PhoneNumber,
  PictureRequest
} from "@jerseydev/orca-loans";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../../../data/initialState";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  submitLabel?: string,
  onSubmit: (data:AxiosResponse<LoanOfficer>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  integrations: ReduxIntegrations,
  user: ReduxUser,
  getProfile: () => ActionResponse,
  getLoanOfficer: (id:string) => ActionResponse<LoanOfficer>,
  updateLoanOfficer: (id:string, data:LoanOfficerUpdateRequest, params:any) => ActionResponse,
  addLoanOfficerPicture: (id:string, picture:PictureRequest) => ActionResponse,
  removeLoanOfficerPicture: (id:string) => ActionResponse
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

type Form = {
  firstName: string,
  lastName: string,
  description: string,
  email: string,
  title: string,
  nmlsId: string,
  branch: null,
  phoneNumbers: PhoneNumber[],
  mobilePhoneNumber: string,
  stateLicenses: string[],
  timezone: string,
  notifications: {
    loanAssigned: boolean,
    loanCompleted: boolean,
    loanAbandoned: boolean,
    loanDocumentAdded: boolean
  },
  data: any,
  los?: any,
  pricing?: any,
  picture?: FileWithPreview|ManagedFile|null
}

type State = {
  pageLoaded: boolean,
  loading: boolean,
  form: Form,
  pictureDeleteDialog: DialogState,
  errors: ErrorState[]
}

class LoanOfficerAccountForm extends BaseForm<Props, State> {
  availableTimezones:string[] = [];
  phoneNumberTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      firstName: '',
      lastName: '',
      description: '',
      email: '',
      title: '',
      nmlsId: '',
      branch: null,
      phoneNumbers: [],
      mobilePhoneNumber: '',
      stateLicenses: [],
      timezone: moment.tz.guess(),
      notifications: {
        loanAssigned: true,
        loanCompleted: true,
        loanAbandoned: true,
        loanDocumentAdded: true
      },
      data: {}
    };

    this.state = {
      pageLoaded: false,
      loading: false,
      form,
      pictureDeleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      errors: []
    };

    if(props.integrations.data) {
      if(IntegrationUtil.hasIntegration(props.integrations.data, 'pricing') && !this.state.form.pricing) {
        if(props.integrations.data.pricing!.provider === 'optimalBlue') {
          this.state.form.pricing = {
            businessChannelId: '',
            originatorId: ''
          };
        }
      }

      if(IntegrationUtil.hasIntegration(props.integrations.data, 'los') && !this.state.form.los) {
        if(props.integrations.data.los!.provider === 'encompass') {
          this.state.form.los = {
            losId: ''
          };
        }
      }
    }

    this.availableTimezones = UserUtil.getAmericanTimezones();

    props.app.data.enums.phoneNumberTypes.forEach(type => {
      this.phoneNumberTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `phone_number_types_${type}`) })
    });
  }

  componentDidMount = async () => {
    try {
      const result = await this.props.getLoanOfficer(this.props.user.data.additionalProps!.loanOfficer!._id).send();
      let form = _.cloneDeep(this.state.form);
      const {firstName, lastName, description, email, title, nmlsId, stateLicenses, notifications, los, pricing, user} = result.data;
      form.firstName = firstName;
      form.lastName = lastName;
      form.description = description ? description : '';
      form.email = email;
      form.title = title ? title : '';
      form.nmlsId = nmlsId;
      form.stateLicenses = stateLicenses;
      form.notifications = notifications;
      form.los = los ? los : {};
      form.pricing = pricing ? pricing : {};
      form.mobilePhoneNumber = user.mobilePhoneNumber ? user.mobilePhoneNumber : ''

      if(!form.title) {
        form.title = '';
      }

      if(!form.notifications) {
        form.notifications = {
          loanAssigned: true,
          loanCompleted: true,
          loanAbandoned: true,
          loanDocumentAdded: true
        };
      }

      this.setState({ pageLoaded: true, form, errors: [] });
    } catch(e) {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(e) });
    }
  };

  componentWillUnmount = () => {
    if(this.state.form.picture && "preview" in this.state.form.picture) {
      window.URL.revokeObjectURL((this.state.form.picture as FileWithPreview).preview);
    }
  };

  addPhoneNumber = (event:MouseEvent) => {
    event.preventDefault();
    const form:any = _.cloneDeep(this.state.form);
    form.phoneNumbers.push({ type: null, number: '' });
    this.setState({ form });
  };

  removePhoneNumber = (index:number) => {
    const form = _.cloneDeep(this.state.form);
    form.phoneNumbers.splice(index, 1);
    this.setState({ form });
  };

  setPhoneNumberType = (index:number, type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.phoneNumbers[index].type = type.value;
    this.setState({ form });
  };

  onAddPicture = (file:FileWithPreview) => {
    const form = _.cloneDeep(this.state.form);
    form.picture = file;

    this.setState({ form });
  };

  onRemovePicture = () => {
    const pictureDeleteDialog = _.clone(this.state.pictureDeleteDialog);
    pictureDeleteDialog.open = true;
    this.setState({ pictureDeleteDialog });
  };

  onStateLicenseChange = (event:ChangeEvent<{value:string[]}>) => {
    const form = _.cloneDeep(this.state.form);
    form.stateLicenses = _.clone(event.target.value);
    this.setState({ form });
  };

  renderStateLicenses = (selected:string[]) => {
    let abbreviations:string[] = [];

    selected.forEach(s => {
      abbreviations.push(s.toUpperCase());
    });

    return abbreviations.join(',');
  };

  onLoanOfficerSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    this.setState({ loading: true, errors: [] });
    
    const {firstName, lastName, description, title, nmlsId, stateLicenses, notifications, picture, los, pricing, mobilePhoneNumber} = this.state.form;
    const requestData:LoanOfficerUpdateRequest = {
      firstName,
      lastName,
      description: description ? description : null,
      title: title ? title : null,
      nmlsId,
      stateLicenses,
      notifications,
      los,
      pricing,
      mobilePhoneNumber: mobilePhoneNumber !== '' ? PhoneNumberUtil.formatPhoneNumber(mobilePhoneNumber) : null
    }

    try {
      const result = await this.props.updateLoanOfficer(this.props.user.data.additionalProps!.loanOfficer!._id, requestData, {merge: 'replace'}).send();

      if(picture instanceof File) {
        const pictureResult = await this.props.addLoanOfficerPicture(this.props.user.data.additionalProps!.loanOfficer!._id, {picture, width: 800, resizeMode: "max"}).send();
        result.data.picture = pictureResult.data;
        const form = _.cloneDeep(this.state.form);
        form.picture = pictureResult.data;
        this.setState({ form });
      }


      await this.props.getProfile().send(); // update profile
      this.setState({ loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  renderPricingFields = () => {
    const { intl, classes, integrations } = this.props;
    const { form } = this.state;

    let fields = null;
    // @todo setup better way to add pricing abstraction
    if(IntegrationUtil.hasIntegration(integrations.data, 'pricing') && integrations.data.pricing!.provider === 'optimalBlue') {
      fields = (
        <FormSection title={IntlFormatter.formatMessage(intl, integrations.data.pricing!.provider)}
                     className={classes.mv2}>
          <div className={classes.mt1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField name="optimalBlueOriginatorId"
                           label={IntlFormatter.formatMessage(intl, 'originator_id')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.pricing.originatorId')}
                           value={form.pricing.originatorId}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />

              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField name="optimalBlueBusinessChannelId"
                           label={IntlFormatter.formatMessage(intl, 'business_channel_id')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.pricing.businessChannelId')}
                           value={form.pricing.businessChannelId}
                           fullWidth={true} />
              </Grid>
            </Grid>
          </div>
        </FormSection>
      );
    }

    return fields;
  };

  renderLosFields = () => {
    const { intl, classes, integrations } = this.props;
    const { form } = this.state;

    let fields = null;
    if(IntegrationUtil.hasIntegration(integrations.data, 'los')) {
      fields = (
        <FormSection title={IntlFormatter.formatMessage(intl, integrations.data.los!.provider)}
                     className={classes.mv2}>
          <div className={classes.mt1}>
            <TextField name="losId"
                       label={IntlFormatter.formatMessage(intl, 'username')}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.los.losId')}
                       value={form.los.losId}
                       fullWidth={true}
                       validators={['required']}
                       errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}
            />
          </div>
        </FormSection>
      );
    }

    return fields
  };

  onDeletePictureCancel = () => {
    const pictureDeleteDialog = {
      open: false,
      loading: false,
      errors: []
    };
    this.setState({pictureDeleteDialog});
  };

  onDeletePictureConfirm = async () => {
    let pictureDeleteDialog = _.clone(this.state.pictureDeleteDialog);
    pictureDeleteDialog.loading = true;
    this.setState({pictureDeleteDialog});

    try {
      // @ts-ignore
      await this.props.removeLoanOfficerPicture(this.props.user.data.additionalProps.loanOfficer._id);
      pictureDeleteDialog = {
        open: false,
        loading: false,
        errors: []
      };
      let form = _.cloneDeep(this.state.form);
      form.picture = null;
      this.setState({pictureDeleteDialog, form});
    } catch (e) {
      pictureDeleteDialog.loading = false;
      pictureDeleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({pictureDeleteDialog});
    }
  };

  render() {

    const { intl, classes, app, user, mixpanel } = this.props;
    const { form, loading, errors, pictureDeleteDialog } = this.state;

    return (
      <div>
        <DeleteDialog open={pictureDeleteDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'delete_picture')}
                      item={IntlFormatter.formatMessage(intl, 'names_picture', {name: form.firstName })}
                      confirmationMessage={IntlFormatter.formatMessage(intl, 'are_you_sure_delete_your_picture')}
                      loading={pictureDeleteDialog.loading}
                      errors={pictureDeleteDialog.errors}
                      onCancel={this.onDeletePictureCancel}
                      onSubmit={this.onDeletePictureConfirm} />

        <Form onSubmit={this.onLoanOfficerSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } }/>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField name="firstName"
                               label={IntlFormatter.formatMessage(intl, 'first_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.firstName')}
                               value={form.firstName}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField name="lastName"
                               label={IntlFormatter.formatMessage(intl, 'last_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.lastName')}
                               value={form.lastName}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField name="email"
                               label={IntlFormatter.formatMessage(intl, 'email')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.email')}
                               value={form.email}
                               fullWidth={true}
                               disabled={true}
                               validators={['required', 'isEmail']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField name="title"
                               label={IntlFormatter.formatMessage(intl, 'title')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.title')}
                               value={form.title}
                               fullWidth={true} />

                  </Grid>
                </Grid>
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField name="nmlsId"
                               label={IntlFormatter.formatMessage(intl, 'nmls_id')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.nmlsId')}
                               value={form.nmlsId}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <PhoneNumberInput name="mobilePhone"
                                      label={IntlFormatter.formatMessage(intl, 'mobile_phone')}
                                      value={form.mobilePhoneNumber}
                                      fullWidth={true}
                                      onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.mobilePhoneNumber')} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.mb2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="state-licenses-label">{IntlFormatter.formatMessage(intl, 'state_licenses')}</InputLabel>
                  <RNSelect
                    labelId="state-licenses-label"
                    id="state-licenses-select"
                    multiple
                    value={form.stateLicenses}
                    renderValue={this.renderStateLicenses}
                    onChange={this.onStateLicenseChange}
                    label={IntlFormatter.formatMessage(intl, 'state_licenses')}
                  >
                    {app.data.enums.states.map(state => (
                      <MenuItem key={state} value={state}>
                        <Checkbox checked={form.stateLicenses.indexOf(state) > -1} />
                        <ListItemText primary={IntlFormatter.formatMessage(intl, `state_${state}`)} />
                      </MenuItem>
                    ))}
                  </RNSelect>
                </FormControl>
              </div>
              <div className={classes.mb2}>
                <TextField name="description"
                           label={IntlFormatter.formatMessage(intl, 'about_me')}
                           value={form.description}
                           multiline={true}
                           rows={3}
                           fullWidth={true}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.description')} />
              </div>

              {this.renderLosFields()}
              {this.renderPricingFields()}

              <FormSection title={IntlFormatter.formatMessage(intl, 'notifications')}>
                <div>
                  <FormControlLabel label={IntlFormatter.formatMessage(intl, 'notification_loan_assigned')}
                                    control={
                                      <Switch checked={form.notifications.loanAssigned}
                                              onChange={() => this.onCheckboxChanged('form.notifications.loanAssigned')} />
                                    } />
                </div>
                <div>
                  <FormControlLabel label={IntlFormatter.formatMessage(intl, 'notification_loan_completed')}
                                    control={
                                      <Switch checked={form.notifications.loanCompleted}
                                              onChange={() => this.onCheckboxChanged('form.notifications.loanCompleted')} />
                                    } />
                </div>
                <div>
                  <FormControlLabel label={IntlFormatter.formatMessage(intl, 'notification_loan_abandoned')}
                                    control={
                                      <Switch checked={form.notifications.loanAbandoned}
                                              onChange={() => this.onCheckboxChanged('form.notifications.loanAbandoned')} />
                                    } />
                </div>
                <div>
                  <FormControlLabel label={IntlFormatter.formatMessage(intl, 'notification_loan_document_added')}
                                    control={
                                      <Switch checked={form.notifications.loanDocumentAdded}
                                              onChange={() => this.onCheckboxChanged('form.notifications.loanDocumentAdded')} />
                                    } />
                </div>
              </FormSection>

              <div className={clsx(classes.mt3, classes.mb2)}>
                <LoanOfficerBorrowerSignupLink email={user.data.email} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <aside className={classes.rightSidebar}>
                <div className={classes.mb4}>
                  <Section title={IntlFormatter.formatMessage(intl, 'phone_numbers')} actions={
                    <Fab color="primary" size="small" onClick={this.addPhoneNumber} flat rounded>
                      <Icon>add</Icon>
                    </Fab>
                  }>
                    <div>
                      {form.phoneNumbers.length === 0 &&
                        <Typography variant="body1">
                          <Link href="#" onClick={this.addPhoneNumber}><FormattedMessage id="add_phone_number" /></Link>
                        </Typography>
                      }

                      {form.phoneNumbers.map((phoneNumber, i) => {
                        return (
                          <div key={i} className={classes.mb2}>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Select name={`phoneNumber_${i}`}
                                        options={this.phoneNumberTypes}
                                        onChange={(data:LabelValuePairType) => this.setPhoneNumberType(i, data)}
                                        value={phoneNumber.type ? this.phoneNumberTypes.find(t => t.value === phoneNumber.type) : ''}
                                        label={IntlFormatter.formatMessage(intl, 'type')}
                                        validators={['required']}
                                        errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                              </Grid>
                              <Grid item xs={4}>
                                <PhoneNumberInput name={`phoneNumber${i}`}
                                                  label={IntlFormatter.formatMessage(intl, 'number')}
                                                  value={form.phoneNumbers[i].number}
                                                  fullWidth={true}
                                                  onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.phoneNumbers[${i}].number`)}
                                                  validators={['required']}
                                                  errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                              </Grid>
                              <Grid item xs={2}>
                                <TextField name={`phoneNumbers[${i}].ext`}
                                           label={IntlFormatter.formatMessage(intl, 'ext')}
                                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.phoneNumbers[${i}].ext`)}
                                           value={form.phoneNumbers[i].ext}
                                           fullWidth={true} />
                              </Grid>
                              <Grid item>
                                <IconButton onClick={() => this.removePhoneNumber(i)} size="small">
                                  <Icon>cancel</Icon>
                                </IconButton>
                              </Grid>
                            </Grid>
                          </div>
                        )
                      })}
                    </div>
                  </Section>
                </div>
                <div>
                  {form.picture && "_id" in form.picture &&
                  <InlineCard title={IntlFormatter.formatMessage(intl, 'photo')}
                              action={
                                <IconButton onClick={this.onRemovePicture} size="small">
                                  <Icon>cancel</Icon>
                                </IconButton>
                              }>
                    <img src={(form.picture as ManagedFile).url} alt={(form.picture as ManagedFile).name} className={classes.thumbnail} />
                  </InlineCard>
                  }
                  {(!form.picture || (form.picture && !("_id" in form.picture))) &&
                  <div className={classes.mv2}>
                    <Typography variant="h4">
                      <FormattedMessage id="photo" />
                    </Typography>

                    <Dropzone accept="image/jpeg, image/png"
                              imageDimensions={{ width: 150, height: 150 }}
                              onAdd={this.onAddPicture}
                              onRemove={this.onRemovePicture} />
                  </div>
                  }
                </div>

                <div className={classes.mt2}>
                  <InlineCard title={IntlFormatter.formatMessage(intl, 'avatar')}>
                    <AvatarEditor user={user.data}
                                  mixpanel={mixpanel} />
                  </InlineCard>
                </div>
              </aside>
            </Grid>
          </Grid>
          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    integrations: state.integrations,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getProfile() {
    return dispatch(getProfile());
  },
  getLoanOfficer(id:string) {
    return dispatch(getLoanOfficer(id));
  },
  updateLoanOfficer(id:string, data:LoanOfficerUpdateRequest, params:any) {
    return dispatch(updateLoanOfficer(id, data, params));
  },
  addLoanOfficerPicture(id:string, picture:PictureRequest) {
    return dispatch(addLoanOfficerPicture(id, picture));
  },
  removeLoanOfficerPicture(id:string) {
    return dispatch(removeLoanOfficerPicture(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoanOfficerAccountForm)));
