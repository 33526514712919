import {
  defaultBoxShadow,
  defaultRadius,
  drawerWidth,
  transition,
} from "../index";
import {appBarHeight, appBarHeightXs} from '../constants';
import palette from "../palette";
import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const pageStyles = (theme:Theme) => createStyles({
  wrapper: {
    position: "relative",
    top: "0",
    bottom: "0",
    height: "100vh",
    backgroundColor: palette.white
  },
  hasSupportWidget: {
    paddingBottom: '50px'
  },
  imgResponsive: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  mainPanel: {
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
    ...transition,
    [theme.breakpoints.up("sm")]: {
      maxHeight: "100vh",
    },
    width: "100%",
    /*overflowScrolling: "touch",*/
    flexGrow: 1
  },
  mainPanelWithDrawer: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    float: "right",
    ...transition,
  },
  content: {
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: 90, // recaptcha logo is going over form elements
    }
  },
  container: {
    display: 'flex'
  },
  pageContainerWithAppBar: {
    '& $mainPanel': {
      top: appBarHeight
    },
    [theme.breakpoints.down("xs")]: {
      '& $mainPanel': {
        top: appBarHeightXs
      }
    }
  },
  pageTitleContainer: {
    backgroundColor: palette.neutral!.shades![2],
    marginBottom: "30px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: palette.white
  },
  rowCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  rowCenterWrap: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  rowWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },
  rowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  rowSpaceBetweenCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rowCenterEnd: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  rowBottom: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexEnd: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  centerAll: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrap: {
    flexWrap: 'wrap'
  },
  m1: {
    margin: theme.spacing(1)
  },
  m2: {
    margin: theme.spacing(2)
  },
  m3: {
    margin: theme.spacing(3)
  },
  m4: {
    margin: theme.spacing(4)
  },
  m5: {
    margin: theme.spacing(5)
  },
  m6: {
    margin: theme.spacing(6)
  },
  mt1: {
    marginTop: theme.spacing(1)
  },
  mt2: {
    marginTop: theme.spacing(2)
  },
  mt3: {
    marginTop: theme.spacing(3)
  },
  mt4: {
    marginTop: theme.spacing(4)
  },
  mt5: {
    marginTop: theme.spacing(5)
  },
  mt6: {
    marginTop: theme.spacing(6)
  },
  mb1: {
    marginBottom: theme.spacing(1)
  },
  mb2: {
    marginBottom: theme.spacing(2)
  },
  mb3: {
    marginBottom: theme.spacing(3)
  },
  mb4: {
    marginBottom: theme.spacing(4)
  },
  mb5: {
    marginBottom: theme.spacing(5)
  },
  mb6: {
    marginBottom: theme.spacing(6)
  },
  ml1: {
    marginLeft: theme.spacing(1)
  },
  ml2: {
    marginLeft: theme.spacing(2)
  },
  ml3: {
    marginLeft: theme.spacing(3)
  },
  ml4: {
    marginLeft: theme.spacing(4)
  },
  ml5: {
    marginLeft: theme.spacing(5)
  },
  ml6: {
    marginLeft: theme.spacing(6)
  },
  mr1: {
    marginRight: theme.spacing(1)
  },
  mr2: {
    marginRight: theme.spacing(2)
  },
  mr3: {
    marginRight: theme.spacing(3)
  },
  mr4: {
    marginRight: theme.spacing(4)
  },
  mr5: {
    marginRight: theme.spacing(5)
  },
  mr6: {
    marginRight: theme.spacing(6)
  },
  mv1: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  mv2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  mv3: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  mv4: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  mv5: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  mv6: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  },
  mh1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  mh2: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  mh3: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  mh4: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  mh5: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5)
  },
  mh6: {
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6)
  },
  p1: {
    padding: theme.spacing(1)
  },
  p2: {
    padding: theme.spacing(2)
  },
  p3: {
    padding: theme.spacing(3)
  },
  p4: {
    padding: theme.spacing(4)
  },
  p5: {
    padding: theme.spacing(5)
  },
  p6: {
    padding: theme.spacing(6)
  },
  pt1: {
    paddingTop: theme.spacing(1)
  },
  pt2: {
    paddingTop: theme.spacing(2)
  },
  pt3: {
    paddingTop: theme.spacing(3)
  },
  pt4: {
    paddingTop: theme.spacing(4)
  },
  pt5: {
    paddingTop: theme.spacing(5)
  },
  pt6: {
    paddingTop: theme.spacing(6)
  },
  pb1: {
    paddingBottom: theme.spacing(1)
  },
  pb2: {
    paddingBottom: theme.spacing(2)
  },
  pb3: {
    paddingBottom: theme.spacing(3)
  },
  pb4: {
    paddingBottom: theme.spacing(4)
  },
  pb5: {
    paddingBottom: theme.spacing(5)
  },
  pb6: {
    paddingBottom: theme.spacing(6)
  },
  pl1: {
    paddingLeft: theme.spacing(1)
  },
  pl2: {
    paddingLeft: theme.spacing(2)
  },
  pl3: {
    paddingLeft: theme.spacing(3)
  },
  pl4: {
    paddingLeft: theme.spacing(4)
  },
  pl5: {
    paddingLeft: theme.spacing(5)
  },
  pl6: {
    paddingLeft: theme.spacing(6)
  },
  pr1: {
    paddingRight: theme.spacing(1)
  },
  pr2: {
    paddingRight: theme.spacing(2)
  },
  pr3: {
    paddingRight: theme.spacing(3)
  },
  pr4: {
    paddingRight: theme.spacing(4)
  },
  pr5: {
    paddingRight: theme.spacing(5)
  },
  pr6: {
    paddingRight: theme.spacing(6)
  },
  pv1: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  pv2: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  pv3: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  pv4: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  pv5: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  pv6: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  ph1: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  ph2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  ph3: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  ph4: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  ph5: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  ph6: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  },
  thumbnail: {
    maxWidth: 150,
    maxHeight: 150,
    objectFit: 'cover',
    borderRadius: defaultRadius,
    ...defaultBoxShadow,
  },
  leftSidebar: {
    [theme.breakpoints.up("md")]: {
      borderRight: `solid 1px ${theme.palette.neutral.light}`,
      paddingRight: theme.spacing(2),
      height: '100%'
    }
  },
  rightSidebar: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(2),
    }
  },
  sectionHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  chips: {
    flexDirection: 'row',
    alignContent: 'flex-center',
    flexWrap: 'wrap'
  },
  pageHeaderContainer: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    position: 'relative',
    [theme.breakpoints.down("md")]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    }
  },
  primary: {
    color: theme.palette.primary.main
  },
  secondary: {
    color: theme.palette.secondary.main
  },
  info: {
    color: theme.palette.info.main
  },
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.main
  },
  danger: {
    color: theme.palette.danger.main
  },
  yield: {
    color: theme.palette.yield.main
  },
  primaryBg: {
    backgroundColor: theme.palette.primary.main
  },
  secondaryBg: {
    backgroundColor: theme.palette.secondary.main
  },
  infoBg: {
    backgroundColor: theme.palette.info.main
  },
  successBg: {
    backgroundColor: theme.palette.success.main
  },
  warningBg: {
    backgroundColor: theme.palette.warning.main
  },
  dangerBg: {
    backgroundColor: theme.palette.danger.main
  },
  yieldBg: {
    backgroundColor: theme.palette.yield.main
  },
  primaryLightBg: {
    backgroundColor: theme.palette.primary.light
  },
  secondaryLightBg: {
    backgroundColor: theme.palette.secondary.light
  },
  infoLightBg: {
    backgroundColor: theme.palette.info.light
  },
  successLightBg: {
    backgroundColor: theme.palette.success.light
  },
  warningLightBg: {
    backgroundColor: theme.palette.warning.light
  },
  dangerLightBg: {
    backgroundColor: theme.palette.danger.light
  },
  yieldLightBg: {
    backgroundColor: theme.palette.yield.light
  },
  primaryDarkBg: {
    backgroundColor: theme.palette.primary.dark
  },
  secondaryDarkBg: {
    backgroundColor: theme.palette.secondary.dark
  },
  infoDarkBg: {
    backgroundColor: theme.palette.info.dark
  },
  successDarkBg: {
    backgroundColor: theme.palette.success.dark
  },
  warningDarkBg: {
    backgroundColor: theme.palette.warning.dark
  },
  dangerDarkBg: {
    backgroundColor: theme.palette.danger.dark
  },
  yieldDarkBg: {
    backgroundColor: theme.palette.yield.dark
  },
  chipInfo: {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main
  },
  chipSuccess: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main
  },
  chipWarning: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main
  },
  chipDanger: {
    color: theme.palette.danger.contrastText,
    backgroundColor: theme.palette.danger.main
  },
  textLeft: {
    textAlign: 'left'
  },
  textRight: {
    textAlign: 'right'
  },
  textCenter: {
    textAlign: 'center'
  },
  selectInlineMargin: {
    marginTop: 8
  },
  buttonInlineMargin: {
    marginTop: 10
  },
  card: {
    overflow: 'visible'
  },
  paperBoxContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3)
    }
  },
  paperBoxLogoContainer: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4)
    },
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4),
  },
  popoverContainer: {
    padding: `${theme.spacing(2)}px`
  },
  popoverHeader: {

  },
  popoverContent: {
    padding: `${theme.spacing(1)}px 0`,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  avatarBadgeIcon: {
    fontSize: '14px'
  },
  button: {
    cursor: 'pointer',
    '& :hover': {
      backgroundColor: theme.palette.neutral.extraLight
    }
  },
  houseBg1: {
    backgroundImage: 'url(/assets/img/house-bg1.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height:'100vh',
    backgroundColor: 'linear-gradient(rgba(255, 0, 0, 0.45),rgba(255, 0, 0, 0.45))',
  },
  bgOverlay: {
    backgroundColor: 'rgba(0,0,0,.35)',
    width: '100%',
    height:'100vh',
  },
  wp25: {
    width: '25%'
  },
  wp50: {
    width: '50%'
  },
  wp75: {
    width: '75%'
  },
  inlineBlock: {
    display: 'inline-block'
  }
});

export default pageStyles;
const useStyles = makeStyles(pageStyles);
export {useStyles}
