import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  Button, IconButton, Icon, withStyles, Typography, WithStyles
} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import IntlFormatter from '../../../intl';
import BaseForm from "../../../forms/BaseForm";
import {
  ErrorList,
  SubmitButton,
  PhoneNumberInput,
  SSNInput,
  Select,
  RadioGroup,
  TextField,
  DatePicker, CheckboxGroup
} from '../../../components';
import _ from 'lodash';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {AclUtil, DateUtil, ErrorUtil, ObjectUtil, PhoneNumberUtil} from "../../../utils";
import moment, {Moment} from "moment-timezone";
import {
  ActionProps,
  ErrorState,
  LabelValuePairType,
  PhoneNumberState,
  ReduxApp,
  ReduxUser
} from "../../../types";
import {
  LoanApplication,
  Borrower,
  BorrowerRequest, BorrowerUpdateRequest,
  LoanApplicationRequest,
  LoanApplicationUpdateRequest
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {addLoanApplication, updateLoanApplication} from "../../../actions/loanApplication";
import {AxiosResponse} from "axios";
import queryString from "query-string";
import Api from "../../../lib/Api";
import {RouteComponentProps, withRouter} from "react-router-dom";

type Props = {
  loanApplication?: LoanApplication,
  borrower?: Borrower|null,
  actions?: React.ReactNodeArray,
  onSubmit: (data:AxiosResponse<LoanApplication>) => void,
  onSubmitClick?: (event:MouseEvent) => void,
  app: ReduxApp,
  user: ReduxUser,
  addLoanApplication: ActionProps["addLoanApplication"],
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type FormAltName = {
  _id?: string,
  firstName: string,
  lastName: string,
  middleName: string,
  suffix: string|null,
}

type Form = {
  prefix: string|null,
  firstName: string,
  lastName: string,
  middleName: string,
  suffix: string|null,
  email: string,
  birthDate: string|null,
  ssn: string,
  maritalStatus: string,
  hasDependents: string,
  numberOfDependents: number|null,
  dependentAges: string[],
  phoneNumbers: PhoneNumberState[],
  militaryService: {
    served: string,
    types: ("current" | "retiredDischargedSeparated" | "nonActivatedNationalGuard" | "survivingSpouse")[],
    endDate: string|null
  },
  citizenship: "us" | "permanentResidentAlien" | "nonPermanentResidentAlien" | "",
  alternateNames: FormAltName[],
  primaryBorrower: boolean,
  hasAlternateNames: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class PersonalInfoForm extends BaseForm<Props, State> {
  prefixes:LabelValuePairType[] = [];
  suffixes:LabelValuePairType[] = [];
  maritalStatuses:LabelValuePairType[] = [];
  militaryServiceTypes:LabelValuePairType[] = [];
  citizenshipTypes:LabelValuePairType[] = [];
  beforeDateValidation:Moment = moment().subtract(10, 'years');
  afterDateValidation:Moment = moment().subtract(100, 'years');
  yesterdayValidation:Moment = moment().subtract(1, 'day');

  constructor(props:Props) {
    super(props);

    const {loanApplication} = props;

    let primaryBorrower = !(loanApplication && loanApplication.borrowers && loanApplication.borrowers.find(b => b.primaryBorrower));

    let form:Form = {
      prefix: null,
      firstName: '',
      lastName: '',
      middleName: '',
      suffix: null,
      email: '',
      birthDate: null,
      ssn: '',
      maritalStatus: '',
      hasDependents: '',
      numberOfDependents: null,
      dependentAges: [],
      phoneNumbers: [{
        type: 'home',
        number: ''
      }],
      militaryService: {
        served: '',
        types: [],
        endDate: null
      },
      citizenship: '',
      hasAlternateNames: '',
      alternateNames: [],
      primaryBorrower
    };

    if(props.borrower) {
      const {
        prefix, firstName, middleName, lastName, suffix, email, birthDate, ssn, maritalStatus, numberOfDependents,
        dependentAges, phoneNumbers, militaryService, citizenship, alternateNames, primaryBorrower
      } = props.borrower;

      form = {
        prefix: prefix ? prefix : null,
        firstName: firstName ? firstName : '',
        middleName: middleName ? middleName : '',
        lastName: lastName ? lastName : '',
        suffix: suffix ? suffix : null,
        email: email ? email : '',
        birthDate: null,
        ssn: ssn ? ssn : '',
        maritalStatus: maritalStatus ? maritalStatus : '',
        hasDependents: !ObjectUtil.isNullOrUndefined(numberOfDependents) ? numberOfDependents! > 0 ? '1' : '0' : '0',
        numberOfDependents: typeof numberOfDependents === 'number' ? numberOfDependents : null,
        dependentAges: [],
        phoneNumbers: [{
          type: 'home',
          number: ''
        }],
        militaryService: {
          served: militaryService ? typeof militaryService.served === 'boolean' && militaryService.served ? '1' : '0' : '',
          types: militaryService && militaryService.types ? militaryService.types : [],
          endDate: militaryService && militaryService.endDate ? militaryService.endDate : null
        },
        citizenship: citizenship ? citizenship : '',
        hasAlternateNames: alternateNames ? alternateNames.length > 0 ? '1' : '0' : '',
        alternateNames: [],
        primaryBorrower: primaryBorrower ? primaryBorrower : false
      };

      if(birthDate) {
        const birthDateString = moment(birthDate).utc().format('YYYY-MM-DD');
        form.birthDate = moment(birthDateString).tz(moment.tz.guess()).format('YYYY-MM-DD');
      }

      if(dependentAges) {
        dependentAges.forEach(age => {
          form.dependentAges.push(age.toString());
        });
      }

      if(phoneNumbers && phoneNumbers.length > 0) {
        form.phoneNumbers = [];
        phoneNumbers.forEach(phoneNumber => {
          form.phoneNumbers.push({
            _id: phoneNumber._id,
            type: phoneNumber.type ? phoneNumber.type : null,
            number: phoneNumber.number
          });
        });
      }

      if(alternateNames) {
        alternateNames.forEach(altName => {
          const {_id, firstName, middleName, lastName, suffix} = altName;
          form.alternateNames.push({
            _id,
            firstName: firstName ? firstName : '',
            middleName: middleName ? middleName : '',
            lastName: lastName ? lastName : '',
            suffix: suffix ? suffix : ''
          });
        })
      }
    }

    if(props.loanApplication) {
      const primaryBorrower = props.loanApplication.borrowers && props.loanApplication.borrowers.find(b => b.primaryBorrower);
      if(primaryBorrower && (!form || (props.borrower && primaryBorrower._id !== props.borrower._id))) {
        form.primaryBorrower = false;
      }
    }

    const hasBorrower = props.loanApplication && props.loanApplication.borrowers && props.loanApplication.borrowers.length > 0;
    if(AclUtil.hasRole(props.user.data, 'ROLE_MEMBER') && !hasBorrower && form.firstName === '' && form.lastName === '' && form.email === '') {
      // copy use profile info
      form.firstName = props.user.data.firstName;
      form.lastName = props.user.data.lastName;
      form.email = props.user.data.email;
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    props.app.data.enums.prefixes.forEach(type => {
      this.prefixes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `prefixes_${type}`) })
    });

    props.app.data.enums.suffixes.forEach(type => {
      this.suffixes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `suffixes_${type}`) })
    });

    props.app.data.enums.maritalStatus.forEach(type => {
      this.maritalStatuses.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `marital_statuses_${type}`) })
    });

    props.app.data.enums.militaryServiceTypes.forEach(type => {
      this.militaryServiceTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `military_service_${type}`) })
    });

    props.app.data.enums.citizenshipTypes.forEach(type => {
      this.citizenshipTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `citizenship_type_${type}`) })
    });
  }

  setPrefix = (prefix:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.prefix = prefix ? prefix.value : null;
    this.setState({ form });
  };

  setSuffix = (suffix:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.suffix = suffix ? suffix.value : null;
    this.setState({ form });
  };

  setCitizenship = (citizenship:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.citizenship = citizenship ? citizenship.value : null;
    this.setState({ form });
  };

  setMaritalStatus = (maritalStatus:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.maritalStatus = maritalStatus ? maritalStatus.value : null;
    this.setState({ form });
  };

  setHasDependents = (event:ChangeEvent<HTMLInputElement>) => {
    const form = _.cloneDeep(this.state.form);
    form.hasDependents = event.target.value;
    if(form.hasDependents === '1') {
      form.dependentAges.push('');
    } else {
      form.dependentAges = [];
    }

    this.setState({ form });
  };

  addDependentAge = () => {
    const form = _.cloneDeep(this.state.form);
    form.dependentAges.push('');
    this.setState({ form });
  };

  removeDependentAge = (ageIndex:number) => {
    const form = _.cloneDeep(this.state.form);
    form.dependentAges.splice(ageIndex, 1);
    if(form.dependentAges.length === 0) {
      form.hasDependents = '0';
    }

    this.setState({ form });
  };

  setMilitaryService = (event:ChangeEvent<HTMLInputElement>) => {
    const form = _.cloneDeep(this.state.form);
    if(!form.militaryService) {
      form.militaryService = {
        served: event.target.value,
        types: [],
        endDate: ''
      };
    } else {
      form.militaryService.served = event.target.value;
    }
    this.setState({ form });
  };

  onMilitaryServiceChange = (event:ChangeEvent<HTMLInputElement>) => {
    const form = _.cloneDeep(this.state.form);
    const value = event.target.value as Form["militaryService"]["types"][0];
    if(form.militaryService.types.includes(value)) {
      const index = form.militaryService.types.indexOf(value);
      form.militaryService.types.splice(index, 1);
    } else {
      form.militaryService.types.push(value);
    }

    this.setState({form});
  };

  setHasAlternateNames = (event:ChangeEvent<HTMLInputElement>) => {
    const form = _.cloneDeep(this.state.form);
    form.hasAlternateNames = event.target.value;
    if(form.hasAlternateNames === '0') {
      form.alternateNames = [];
    } else if(form.alternateNames.length === 0) {
      form.alternateNames.push({
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: ''
      });
    }
    this.setState({ form });
  };

  addAlternateName = () => {
    const form = _.cloneDeep(this.state.form);
    form.alternateNames.push({
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: ''
    });
    this.setState({ form });
  };

  removeAlternateName = (ageIndex:number) => {
    const form = _.cloneDeep(this.state.form);
    form.alternateNames.splice(ageIndex, 1);
    if(form.alternateNames.length === 0) {
      form.hasAlternateNames = '0';
    }
    this.setState({ form });
  };

  setAlternateNameSuffix = (index:number, suffix:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.alternateNames[index].suffix = suffix ? suffix.value : null;
    this.setState({ form });
  };

  onSubmit = async (event:MouseEvent) => {
    this.setState({ loading: true, errors: [] });
    if(this.props.onSubmitClick) {
      this.props.onSubmitClick(event);
    }
    try {
      const {loanApplication, borrower} = this.props;
      const {
        prefix, firstName, middleName, lastName, suffix, email, birthDate, ssn, maritalStatus, numberOfDependents,
        dependentAges, phoneNumbers, militaryService, citizenship, alternateNames, primaryBorrower
      } = this.state.form;

      const requestDataBase:BorrowerRequest = {
        prefix: prefix ? prefix as BorrowerRequest["prefix"] : null,
        firstName,
        middleName: middleName !== '' ? middleName : null,
        lastName,
        suffix: suffix ? suffix as BorrowerRequest["suffix"] : null,
        email,
        birthDate,
        ssn: ssn.replace(/-/g, '') ,
        maritalStatus: maritalStatus as BorrowerRequest["maritalStatus"],
        numberOfDependents: typeof numberOfDependents === 'number' ? numberOfDependents : 0,
        dependentAges: ObjectUtil.stringArrayToNumbers(dependentAges),
        citizenship: citizenship as BorrowerRequest["citizenship"],
        militaryService: {
          served: militaryService.served === '1',
          types: militaryService.types ? militaryService.types : null,
          endDate: militaryService.endDate ? militaryService.endDate : null
        },
        alternateNames: [],
        primaryBorrower
      };
      const requestAltNames:BorrowerRequest["alternateNames"]|BorrowerUpdateRequest["alternateNames"] = [];
      alternateNames.forEach(altName => {
        const {_id, firstName, middleName, lastName, suffix} = altName;
        requestAltNames.push({
          _id,
          firstName,
          middleName: middleName !== '' ? middleName : null,
          lastName,
          suffix: suffix !== '' ? suffix : null
        });
      });

      requestDataBase.alternateNames = requestAltNames;

      let result:AxiosResponse<LoanApplication>;
      if(loanApplication) {
        const requestData:LoanApplicationUpdateRequest = {
          borrowers: [{
            _id: borrower ? borrower._id : undefined,
            ...requestDataBase,
            phoneNumbers: PhoneNumberUtil.stateToUpdateRequest(phoneNumbers)
          }]
        };
        result = await this.props.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      } else {
        const requestData:LoanApplicationRequest = {
          borrowers: [{
            ...requestDataBase,
            phoneNumbers: PhoneNumberUtil.stateToCreateRequest(phoneNumbers)
          }]
        };

        const queryParams = queryString.parse(this.props.location.search);
        if(queryParams.loanOfficer || queryParams.source) {
          if(queryParams.loanOfficer) {
            const result = await Api.searchLoanOfficers({ searchText: queryParams.loanOfficer as string, exactMatch: true });
            if(result.data.length > 0) {
              requestData.loanOfficer = result.data[0]._id;
            }

            if(queryParams.source) {
              requestData.source = queryParams.source as string;
            }
          }
        }

        result = await this.props.addLoanApplication(requestData).send();
      }

      this.setState({ loading: false }, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {
    const { intl, actions, classes, children } = this.props;
    const { loading, form, errors } = this.state;


    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <div className={classes.mb2}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={2}>
              <Select name="prefix"
                      options={this.prefixes}
                      onChange={this.setPrefix}
                      value={form.prefix ? this.prefixes.find(t => t.value === form.prefix) : ''}
                      label={IntlFormatter.formatMessage(intl, 'prefix')}
                      placeholder={IntlFormatter.formatMessage(intl, 'select')}/>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField name="firstName"
                         label={IntlFormatter.formatMessage(intl, 'first_name')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.firstName')}
                         value={form.firstName}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField name="middleName"
                         label={IntlFormatter.formatMessage(intl, 'middle_name')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.middleName')}
                         value={form.middleName}
                         fullWidth={true} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField name="lastName"
                         label={IntlFormatter.formatMessage(intl, 'last_name')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.lastName')}
                         value={form.lastName}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Select name="suffix"
                      options={this.suffixes}
                      onChange={this.setSuffix}
                      value={form.suffix ? this.suffixes.find(t => t.value === form.suffix) : ''}
                      label={IntlFormatter.formatMessage(intl, 'suffix')}
                      placeholder={IntlFormatter.formatMessage(intl, 'select')} />
            </Grid>
          </Grid>
        </div>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField name="email"
                         label={IntlFormatter.formatMessage(intl, 'email')}
                         value={form.email}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.email')}
                         validators={['required', 'isEmail']}
                         fullWidth={true}
                         inputProps={{
                           autoCapitalize: 'none',
                         }}
                         errorMessages={[
                           IntlFormatter.formatMessage(intl, 'validation_required'),
                           IntlFormatter.formatMessage(intl, 'validation_email_invalid')
                         ]} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SSNInput name="ssn"
                        label={IntlFormatter.formatMessage(intl, 'ssn')}
                        value={form.ssn}
                        fullWidth={true}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required')
                        ]}
                        onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.ssn')} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <DatePicker label={IntlFormatter.formatMessage(intl, 'birth_date')}
                          value={form.birthDate}
                          onChange={(date:string) => this.onDateChange(date, 'form.birthDate')}
                          fullWidth={true}
                          validators={[
                            'required',
                            `isBeforeDate:${this.beforeDateValidation.toISOString()}`,
                            `isAfterDate:${this.afterDateValidation.toISOString()}`
                          ]}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_before_date', { date: DateUtil.formatDate(this.beforeDateValidation) }),
                            IntlFormatter.formatMessage(intl, 'validation_after_date', { date: DateUtil.formatDate(this.afterDateValidation) })
                          ]} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <PhoneNumberInput name="phoneNumber"
                                label={IntlFormatter.formatMessage(intl, 'phone_number')}
                                value={form.phoneNumbers[0].number}
                                fullWidth={true}
                                validators={['required']}
                                errorMessages={[
                                  IntlFormatter.formatMessage(intl, 'validation_required')
                                ]}
                                onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.phoneNumbers[0].number')} />
            </Grid>
          </Grid>
        </div>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Select name="marital-status"
                      options={this.maritalStatuses}
                      onChange={this.setMaritalStatus}
                      value={form.maritalStatus ? this.maritalStatuses.find(s => s.value === form.maritalStatus) : ''}
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required')
                      ]}
                      label={IntlFormatter.formatMessage(intl, 'marital_status')}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Select name="citizenship"
                      options={this.citizenshipTypes}
                      onChange={this.setCitizenship}
                      value={form.citizenship ? this.citizenshipTypes.find(t => t.value === form.citizenship) : ''}
                      label={IntlFormatter.formatMessage(intl, 'citizenship')}
                      placeholder={IntlFormatter.formatMessage(intl, 'select')}
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required'),
                      ]} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <RadioGroup name="haveDependents"
                          label={IntlFormatter.formatMessage(intl, 'do_you_have_dependents')}
                          itemValueProp="value"
                          value={form.hasDependents}
                          onChange={this.setHasDependents}
                          items={[
                            { label: IntlFormatter.formatMessage(intl, 'yes'), value: '1' },
                            { label: IntlFormatter.formatMessage(intl, 'no'), value: '0' }
                          ]}
                          validators={['required']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required')
                          ]}
                          row />
              {form.dependentAges.length > 0 &&
              <div className={classes.mt1}>
                {form.dependentAges.map((age, ageIndex) => {
                  return (
                    <Grid container key={ageIndex} spacing={1} alignItems="center">
                      <Grid item xs={6}>
                        <TextField name={`dependentAge_${ageIndex}`}
                                   label={IntlFormatter.formatMessage(intl, 'age')}
                                   value={form.dependentAges[ageIndex]}
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.dependentAges[${ageIndex}`)}
                                   type="number"
                                   margin="dense"
                                   validators={['required', 'minNumber:0', 'maxNumber:24']}
                                   errorMessages={[
                                     IntlFormatter.formatMessage(intl, 'validation_required'),
                                     IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                     IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 24 })
                                   ]} />
                      </Grid>
                      <Grid item xs={6}>
                        <IconButton onClick={() => this.removeDependentAge(ageIndex)} size="small">
                          <Icon fontSize="small">close</Icon>
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                })}
                <div className={classes.mt2}>
                  <Button variant="contained" color="primary" onClick={this.addDependentAge}>
                    <FormattedMessage id="add"/>
                  </Button>
                </div>
              </div>
              }
            </Grid>
          </Grid>
        </div>

        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <RadioGroup name="militaryVeteran"
                        label={IntlFormatter.formatMessage(intl, 'are_you_a_veteran_or_active_us_military')}
                        itemValueProp="value"
                        value={form.militaryService.served}
                        onChange={this.setMilitaryService}
                        items={[
                          { label: IntlFormatter.formatMessage(intl, 'yes'), value: '1' },
                          { label: IntlFormatter.formatMessage(intl, 'no'), value: '0' }
                        ]}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required')
                        ]}
                        row />

            {(form.militaryService && form.militaryService.served === '1') &&
            <div className={classes.mt2}>
              <Typography variant="body2">
                <FormattedMessage id="check_all_that_apply" />
              </Typography>
              <CheckboxGroup name="militaryServiceServed"
                             items={this.militaryServiceTypes}
                             value={form.militaryService.types}
                             onChange={this.onMilitaryServiceChange}
                             validators={['oneOrMore']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_at_least_one_value_is_required')
                             ]} />

              {form.militaryService.types.includes('current') &&
              <div className={classes.mt2}>
                <DatePicker label={IntlFormatter.formatMessage(intl, 'estimated_end_of_service')}
                            value={form.militaryService.endDate}
                            onChange={(date:string) => this.onDateChange(date, 'form.militaryService.endDate')}
                            fullWidth={true}
                            validators={[
                              'required',
                              `isAfterDate:${this.yesterdayValidation.toISOString()}`
                            ]}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_after_date', { date: DateUtil.formatDate(this.yesterdayValidation) })
                            ]} />
              </div>
              }
            </div>
            }
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <RadioGroup name="alternateNames"
                        label={IntlFormatter.formatMessage(intl, 'alternate_names_description')}
                        itemValueProp="value"
                        value={form.hasAlternateNames}
                        onChange={this.setHasAlternateNames}
                        items={[
                          { label: IntlFormatter.formatMessage(intl, 'yes'), value: '1' },
                          { label: IntlFormatter.formatMessage(intl, 'no'), value: '0' }
                        ]}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required')
                        ]}
                        row />

            {form.alternateNames.length > 0 &&
            <div className={classes.mt1}>
              {form.alternateNames.map((altName, i) => {
                return (
                  <div key={i} className={classes.mb2}>
                    <Grid container key={i} spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField name={`alternateNames_firstName_${i}`}
                                   label={IntlFormatter.formatMessage(intl, 'first_name')}
                                   value={altName.firstName}
                                   fullWidth={true}
                                   margin="dense"
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.alternateNames[${i}].firstName`)}
                                   validators={['required']}
                                   errorMessages={[
                                     IntlFormatter.formatMessage(intl, 'validation_required')
                                   ]} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField name={`alternateNames_middleName_${i}`}
                                   label={IntlFormatter.formatMessage(intl, 'middle_name')}
                                   value={altName.middleName}
                                   fullWidth={true}
                                   margin="dense"
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.alternateNames[${i}].middleName`)}/>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField name={`alternateNames_lastName_${i}`}
                                   label={IntlFormatter.formatMessage(intl, 'last_name')}
                                   value={altName.lastName}
                                   fullWidth={true}
                                   margin="dense"
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.alternateNames[${i}].lastName`)}
                                   validators={['required']}
                                   errorMessages={[
                                     IntlFormatter.formatMessage(intl, 'validation_required')
                                   ]} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Select name={`alternateNames_suffix_${i}`}
                                options={this.suffixes}
                                onChange={(value:LabelValuePairType) => this.setAlternateNameSuffix(i, value)}
                                value={altName.suffix ? this.suffixes.find(t => t.value === altName.suffix) : ''}
                                fullWidth={true}
                                size="small"
                                label={IntlFormatter.formatMessage(intl, 'suffix')}
                                placeholder={IntlFormatter.formatMessage(intl, 'select')}
                                className={classes.selectInlineMargin} />
                      </Grid>
                      <Grid item>
                        <IconButton size="small" onClick={() => this.removeAlternateName(i)}
                                    className={classes.buttonInlineMargin}>
                          <Icon>close</Icon>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                )
              })}

              <div className={classes.mt2}>
                <Button variant="contained" color="primary" onClick={this.addAlternateName}>
                  <FormattedMessage id="add_another_name"/>
                </Button>
              </div>
            </div>
            }
          </Grid>
        </Grid>


        <div className={classes.mt2}>
          {children}

          {!children &&
            <Grid container alignItems="center" justifyContent="flex-end">
              {actions && actions.length > 0 &&
              <Grid item>
                <div className={classes.mr1}>
                  {actions.map((action, i) => {
                    return <span key={i}>{action}</span>
                  })}
                </div>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save"/>
                </SubmitButton>
              </Grid>
            </Grid>
          }
        </div>

      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addLoanApplication(data:LoanApplicationRequest) {
    return dispatch(addLoanApplication(data));
  },
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(PersonalInfoForm))));
