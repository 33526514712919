import {Icon, Typography, WithStyles, withStyles} from "@material-ui/core";
import React from "react";
import styles from "../theme/jss/components/itemStyles";
import clsx from "clsx";

type DefaultProps = {
  color: 'default'|'info'|'primary'|'secondary'|'success'|'danger'
}

type Props = {
  label: string,
  icon?: React.ReactNode|string,
  classes?: string,
  children?: React.ReactNode
} & Partial<DefaultProps>
  & WithStyles<typeof styles>

const Item = (props:Props) => {
  const { classes, label, children, icon, color } = props;

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {icon &&
          <div className={classes.mr1}>
            {typeof icon === 'string' &&
              <Icon className={clsx(classes.largeIcon, classes[color!])}>{icon}</Icon>
            }
            {typeof icon !== 'string' &&
              <div className={clsx(classes.largeIcon, classes[color!])}>
                {icon}
              </div>
            }
          </div>
        }
        <div>
          <Typography variant="caption">
            {label}
          </Typography>
          {children &&
            <div className={classes.itemContent}>
              {children}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

const defaultProps:DefaultProps = {
  color: 'default'
}

Item.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(Item);