import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";
import {makeStyles} from "@material-ui/styles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  description: {
    marginTop: theme.spacing(2),
    maxHeight: 150,
    overflowY: 'scroll'
  },
  descriptionText: {
    color: theme.palette.neutral.light,
    fontSize: 16
  }
});

export default styles;
const useStyles = makeStyles(styles);
export {useStyles}
