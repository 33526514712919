import React from 'react';
import {Badge} from "./index";
import {FormattedMessage} from 'react-intl';
import {Ticket} from "@jerseydev/orca-loans";

type Props = {
  status?: Ticket["status"]
}

type StatusMap = Record<Ticket["status"], any>

const TicketStatusBadge = (props:Props) => {
  const {status} = props;
  const statusMap:StatusMap = {
    open: 'danger',
    closed: 'success',
    pending: 'warn',
    resolved: 'info'
  };

  if(!status) {
    return null;
  }

  return (
    <Badge color={statusMap[status]}>
      <FormattedMessage id={status} />
    </Badge>
  )
};

export default TicketStatusBadge;