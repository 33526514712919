import {ReduxAsyncOperation} from "../enums";
import {ReduxActionData} from "../types";

export function setData(type:string, status:ReduxAsyncOperation, data?:any):ReduxActionData {
  return {
    type: type,
    payload: {
      status,
      data,
    }
  }
}