import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {FormattedMessage} from "react-intl";
import useStyles from "../theme/jss/components/paginationHeaderStyles";

type Props = {
  totalResults: number,
  currentPage: number,
  totalPages: number
}

function PaginationHeader(props:Props) {
  const classes = useStyles();
  const { totalResults, currentPage, totalPages } = props;
  if(totalPages === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <Typography variant="body1" color="inherit">
            {totalResults === 1 &&
            <FormattedMessage id="num_result" values={{ num: totalResults }} />
            }
            {totalResults > 1 &&
            <FormattedMessage id="num_results" values={{ num: totalResults }} />
            }
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="inherit">
            <FormattedMessage id="page_of" values={{ currentPage, totalPages }} />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default PaginationHeader;