import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {
  withStyles,
  Icon,
  IconButton,
  Grid, WithStyles,
} from "@material-ui/core";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {
  ErrorList,
  PageTitle,
  Snackbar,
  CreditCardList,
  BankAccountList,
  SubscriptionCard,
} from "../../../components";
import {ErrorUtil, ReduxUtil} from "../../../utils";
import {ReduxState} from "../../../data/initialState";
import {Plan} from "@jerseydev/orca-loans";
import {ReduxAccount, ReduxSubscription, ActionProps, SnackbarState, ErrorState} from "../../../types";
import {AxiosPromise} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  account: ReduxAccount,
  subscription: ReduxSubscription
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  selectedPlan: Plan|null,
  addButtonGroupOpen: boolean,
  errors: ErrorState[],
  snackbar: SnackbarState
}

class SubscriptionPage extends Component<Props, State> {

  activeStatuses:string[] = ['active', 'trialing', 'past_due', 'unpaid'];

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      selectedPlan: null,
      addButtonGroupOpen: false,
      errors: [],
      snackbar: {
        open: false,
        message: ''
      }
    };
  }

  componentDidMount = async () => {
    try {
      const requests:AxiosPromise[] = [
        this.props.routeProps.getAccount().send(),
        this.props.routeProps.getSubscription().send()
      ];
      await Promise.all(requests).then(() => {
        this.setState({ pageLoaded: true });
      }).catch(err => {
        this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(err) });
      })
    } catch(err) {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(err) });
    }
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'subscription');
  };

  renderTitleBar = () => {
    return <PageTitle title={this.getPageTitle()} icon="payments" />
  };

  onNumberOfLicensesSubmit = () => {
    this.setState({
      snackbar: {
        open: true,
        message: IntlFormatter.formatMessage(this.props.intl, 'licenses_updated'),
        variant: 'success'
      }
    });
  };

  onSnackbarClose = () => {
    this.setState({
      snackbar: {
        open: false,
        message:''
      }
    });
  };

  render() {
    const { classes, account, subscription, mixpanel } = this.props;
    const { errors, pageLoaded, loading, snackbar } = this.state;

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   pageLoaded={pageLoaded}
                   loading={loading}>

        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.onSnackbarClose}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        <div className={classes.content}>

          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } } />

          {ReduxUtil.hasData(account) &&
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  {ReduxUtil.hasData(subscription) &&
                    <div className={classes.mb2}>
                      <SubscriptionCard mixpanel={mixpanel}
                                        onNumberOfLicensesSubmit={this.onNumberOfLicensesSubmit} />
                    </div>
                  }
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <div className={classes.mb2}>
                    <CreditCardList mixpanel={mixpanel} />
                  </div>
                  <BankAccountList mixpanel={mixpanel} />
                </Grid>
              </Grid>
            </div>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    account: state.account,
    subscription: state.subscription
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(SubscriptionPage)));

