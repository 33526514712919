import React, {Component, RefObject} from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Icon,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper, WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/loanStatusButtonStyles';
import IntlFormatter from "../intl";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Loader, Snackbar} from "./index";
import {updateLoanApplication} from "../actions/loanApplication";
import {connect} from "react-redux";
import {ActionProps, LoanStatusType, ReduxApp, SnackbarState} from "../types";
import {
  LoanApplication2009,
  LoanApplication,
  LoanApplicationUpdateRequest
} from "@jerseydev/orca-loans";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  app: ReduxApp,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  open: boolean,
  snackbar: SnackbarState,
  loading: boolean
}

class LoanStatusButton extends Component<Props, State> {
  buttonRef:RefObject<HTMLDivElement>;

  constructor(props:Props) {
    super(props);

    this.state = {
      open: false,
      snackbar: {
        open: false,
        message: ''
      },
      loading: false
    };

    this.buttonRef = React.createRef();
  }

  onLoanStatusClick = (status:LoanStatusType) => {
    const {intl} = this.props;
    this.setState({ loading: true });
    this.props.updateLoanApplication(this.props.loanApplication._id, { status })
      .send()
      .then(() => {
        this.setState({
          loading: false,
          open: false,
          snackbar: {
            open: true,
            message: IntlFormatter.formatMessage(intl, 'loan_status_updated'),
            variant: 'success'
          }
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          open: false,
          snackbar: {
            open: true,
            message: IntlFormatter.formatMessage(intl, 'loan_status_failed_to_update'),
            variant: 'danger'
          }
        });
      });
  };

  onSnackbarClose = () => {
    this.setState({ snackbar: { open: false, message: '' } });
  };

  render() {
    const { intl, app, loanApplication, classes } = this.props;
    const { open, loading, snackbar } = this.state;


    return (
      <div>
        <Loader visible={loading} />

        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.onSnackbarClose}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.onSnackbarClose}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        <ButtonGroup ref={this.buttonRef} variant="contained" className={classes[loanApplication.status]}>
          <Button className={classes[loanApplication.status]}>
            <FormattedMessage id={loanApplication.status} />
          </Button>
          <Button
            className={classes[loanApplication.status]}
            size="small"
            onClick={() => { this.setState({ open: true })}}>
            <Icon>arrow_drop_down</Icon>
          </Button>
        </ButtonGroup>
        <Popper open={open}
                anchorEl={this.buttonRef.current}
                role={undefined}
                transition
                disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom'}}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => { this.setState({ open: false })}}>
                  <MenuList id="split-button-menu">

                    {app.data.enums.loanStatuses.map((status:string, i:number) => {
                      if(status === loanApplication.status || (loanApplication.completed && status === 'started')) {
                        return null;
                      }
                      return (
                        <MenuItem key={i} onClick={() => this.onLoanStatusClick(status as LoanStatusType)}>
                          <ListItemText primary={IntlFormatter.formatMessage(intl, status)} />
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationUpdateRequest) {
    return dispatch(updateLoanApplication(id, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(LoanStatusButton)));
