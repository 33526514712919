import React, {ChangeEvent, Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {AccountPage} from '../../../layouts';
import {
  Tabs,
  Tab,
  Grid,
  Icon,
  withStyles, WithStyles
} from '@material-ui/core';
import {ReduxUtil, LocationUtil, IntegrationUtil} from "../../../utils";
import {LoanDetails, PageTitle, TabPanel, LoanDocumentChecklist} from "../../../components";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {LoanDocumentsForm} from "../../loanApplication/forms";
import {Pricing} from "../components";
import qs from "query-string";
import Credit from "../components/Credit";
import {ActionProps, ReduxIntegrations, ReduxLoanApplication} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {RouteComponentProps} from "react-router-dom";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  integrations: ReduxIntegrations
} & RouteComponentProps<{id:string}>
  & WithStyles<typeof pageStyles>
  & WrappedComponentProps

type State = {
  pageLoaded: boolean,
  loading: boolean,
  selectedTab: number,
  errorStatusCode?: number
}

type TabMap = {
  loan: number,
  documents: number,
  pricing: number,
  credit: number
}

class LoanApplicationDetailPage extends Component<Props, State> {
  tabMap:TabMap = {
    loan: 0,
    documents: 1,
    pricing: 2,
    credit: 3
  };

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      selectedTab: 0
    };
  }

  componentDidMount = async () => {
    try {
      if(this.props.location.search) {
        const params = qs.parse(this.props.location.search);
        if(params.tab && this.tabMap[params.tab as keyof TabMap]) {
          this.setState({ selectedTab: this.tabMap[params.tab as keyof TabMap] });
        }
      }

      await this.props.routeProps.getLoanApplication(this.props.match.params.id).send();
      this.setState({ pageLoaded: true });
    } catch (e) {
      this.setState({ pageLoaded: true, errorStatusCode: e.response ? e.response.status : e.status });
    }
  };

  onTabChange = (event:ChangeEvent<{}>, value:number) => {
    this.setState({ selectedTab: value });
  };

  getPageTitle = () => {
    const { intl, loanApplication } = this.props;

    if(ReduxUtil.hasData(loanApplication) && loanApplication.data.property && loanApplication.data.property.address && loanApplication.data.property.address.province) {
      return IntlFormatter.formatMessage(intl, 'loan_for_address', { address: LocationUtil.formatLocation(loanApplication.data.property.address) });
    }

    return IntlFormatter.formatMessage(intl, 'loan_application');
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="library_books" />
    )
  };

  getTabs = () => {
    const {intl, loanApplication, integrations } = this.props;

    const items = [
      <Tab key="detail"
           label={IntlFormatter.formatMessage(intl, 'detail')}
           icon={<Icon>list_alt</Icon>} />
    ];

    items.push(
      <Tab key="documents"
           label={IntlFormatter.formatMessage(intl, 'documents')}
           icon={<Icon>folder_open</Icon>} />
    );

    if(ReduxUtil.hasData(loanApplication) && loanApplication.data.completed) {
      if(IntegrationUtil.hasIntegration(integrations.data, 'pricing')) {
        items.push(
          <Tab key="pricing"
               label={IntlFormatter.formatMessage(intl, 'pricing')}
               icon={<Icon>request_quote</Icon>} />
        );
      }

      if(IntegrationUtil.hasIntegration(integrations.data, 'credit')) {
        items.push(
          <Tab key="credit"
               label={IntlFormatter.formatMessage(intl, 'credit')}
               icon={<Icon>payments</Icon>} />
        );
      }
    }

    return items;
  };

  render() {
    const { intl, loanApplication, integrations, mixpanel } = this.props;
    const { loading, selectedTab, errorStatusCode, pageLoaded } = this.state;

    const tabs = this.getTabs();

    return (
      <AccountPage pageTitle={IntlFormatter.formatMessage(intl, 'loan_application')}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                    {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                    {to: '/admin/loan-applications', title: IntlFormatter.formatMessage(intl, 'loan_applications') },
                    {title: this.getPageTitle() }
                   ]}
                   errorStatusCode={errorStatusCode}
                   pageLoaded={pageLoaded}
                   loading={loading}>

        <div>
          {ReduxUtil.hasData(loanApplication) &&
            <div>
              {tabs.length > 1 &&
                <Tabs value={selectedTab} onChange={this.onTabChange}>
                  {tabs}
                </Tabs>
              }

              <TabPanel value={selectedTab} index={0}>
                <LoanDetails loanApplication={loanApplication.data} />
              </TabPanel>

              <TabPanel value={selectedTab} index={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={7}>
                    <LoanDocumentsForm loanApplication={loanApplication.data}
                                       mixpanel={mixpanel} />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <LoanDocumentChecklist loanApplication={loanApplication.data} />
                  </Grid>
                </Grid>
              </TabPanel>

              {loanApplication.data.completed && IntegrationUtil.hasIntegration(integrations.data, 'pricing') &&
                <TabPanel value={selectedTab} index={2}>
                  <Pricing loanApplication={loanApplication.data}/>
                </TabPanel>
              }
              {loanApplication.data.completed && IntegrationUtil.hasIntegration(integrations.data, 'credit') &&
              <TabPanel value={selectedTab} index={IntegrationUtil.hasIntegration(integrations.data, 'credit') ? 3 : 2}>
                <Credit loanApplication={loanApplication.data} />
              </TabPanel>
              }
            </div>
          }

        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    integrations: state.integrations
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoanApplicationDetailPage)));
