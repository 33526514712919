import React, {useEffect, useState} from 'react';
import {LoanOfficer} from "@jerseydev/orca-loans";
import {FormattedMessage} from "react-intl";
import {useStyles} from "../theme/jss/components/loanOfficerProfileIncompleteAlertStyles";
import {Icon, Button} from "@material-ui/core";
import {LoanOfficerUtil, ReduxUtil} from "../utils";
import clsx from "clsx";
import {Link} from 'react-router-dom';
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {getIntegrations} from "../actions/integrations";
import {useDispatch, useSelector} from "react-redux";

type Props = {
  loanOfficer: LoanOfficer,
  className?: string
}

const LoanOfficerProfileIncompleteAlert = (props:Props) => {
  const {className, loanOfficer} = props;
  const classes = useStyles();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const integrations = useSelector((state:ReduxState) => state.integrations);
  const [loading, setLoading] = useState<boolean>(true);
  const warnings = integrations && integrations.data ? LoanOfficerUtil.getProfileIncompleteWarnings(loanOfficer, integrations.data) : [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(!ReduxUtil.hasData(integrations)) {
          await dispatch(getIntegrations()).send();
        }

        setLoading(false);
      } catch (e) {
        setLoading(false); // ignore error
      }
    }

    fetchData();
  }, [dispatch, integrations]);


  if(!loading && warnings.length > 0) {
    return (
      <div className={clsx(classes.root, className)}>
        <Icon className={classes.icon}>warning</Icon>
        <FormattedMessage id="profile_incomplete" />
        <Button size="small"
                variant="outlined"
                className={classes.button}
                component={Link}
                to="/account">
          <FormattedMessage id="fix" />
        </Button>
      </div>
    )
  }

  return null;
};

export default LoanOfficerProfileIncompleteAlert;