import React from 'react';
import {Typography, WithStyles, withStyles} from "@material-ui/core";
import {NumberIcon} from "./index";
import styles from "../theme/jss/components/numberedListItemStyles";
import clsx from "clsx";

type DefaultProps = {
  color: 'default'|'info'|'primary'|'secondary'|'success'|'warn'|'yield'|'danger'
}

type Props = {
  number: number,
  primaryText: string,
  secondaryText?: string,
  className?: string,
  children?: React.ReactNode
} & Partial<DefaultProps>
  & WithStyles<typeof styles>

const NumberedListItem = (props:Props) => {
  const {classes, number, color, primaryText, secondaryText, className, children} = props;

  return (
    <div className={clsx(classes.root, className)}>
      <div>
        <NumberIcon number={number}
                    color={color} />
      </div>
      <div className={classes.content}>
        <Typography variant="body1" gutterBottom={!!secondaryText}>
          {primaryText}
        </Typography>
        {secondaryText &&
          <Typography variant="caption">
            {primaryText}
          </Typography>
        }

        {children &&
          <div className={classes.children}>
            {children}
          </div>
        }
      </div>
    </div>
  )
};

const defaultProps:DefaultProps = {
  color: 'default'
};

NumberedListItem.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(NumberedListItem);