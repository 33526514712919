import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  withStyles,
  Button, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {DropzoneField, ErrorList, HelperTooltip, SubmitButton, TextField} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {addEmailLogo, removeEmailLogo, updateMessageSettings} from "../../../actions/settings";
import {AccountUtil, AclUtil, ErrorUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {FileWithPreview, ReduxSubscription, ReduxUser, ActionProps, ErrorState, ReduxAccount} from "../../../types";
import {AxiosResponse} from "axios";
import {
  AccountRequest,
  MessageSettings,
  ImageRequest,
  ManagedFile, MessageSettingsRequest
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateAccount} from "../../../actions/account";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  settings?: MessageSettings,
  onSubmit: (data:AxiosResponse<MessageSettings>) => void,
  onCancel?: () => void,
  user: ReduxUser,
  subscription: ReduxSubscription,
  account: ReduxAccount,
  updateMessageSettings: ActionProps["updateMessageSettings"],
  addEmailLogo: ActionProps["addEmailLogo"],
  removeEmailLogo: ActionProps["removeEmailLogo"],
  updateAccount: ActionProps["updateAccount"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  fromName: string,
  fromEmail: string,
  emailDomain: string,
  emailLogo: FileWithPreview|ManagedFile|null,
  websiteUrl: string,
  companyName: string,
  companyAddress: string,
  helpUrl: string,
  copyright: string,
  supportEmail: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  emailLogo: {
    loading: boolean,
    errors: ErrorState[]
  }
}

class EmailSettingsForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    let form:Form = {
      fromName: '',
      fromEmail: '',
      emailDomain: '',
      emailLogo: null,
      websiteUrl: '',
      companyName: '',
      companyAddress: '',
      helpUrl: '',
      copyright: '',
      supportEmail: ''
    }

    if(props.settings) {
      const {fromName, fromEmail, emailDomain, companyName, companyAddress, copyright, helpUrl, supportEmail, websiteUrl, emailLogo} = props.settings;
      form = {
        fromName: fromName ? fromName : '',
        fromEmail: fromEmail ? fromEmail : '',
        emailDomain: emailDomain ? emailDomain : '',
        companyName: companyName ? companyName : '',
        companyAddress: companyAddress ? companyAddress : '',
        copyright: copyright ? copyright : '',
        helpUrl: helpUrl ? helpUrl : '',
        supportEmail: supportEmail ? supportEmail : '',
        websiteUrl: websiteUrl ? websiteUrl : '',
        emailLogo: emailLogo ? emailLogo as ManagedFile : null
      };
    }

    this.state = {
      loading: false,
      emailLogo: {
        loading: false,
        errors: []
      },
      form,
      errors: []
    };
  }

  onAddLogo = async (image:FileWithPreview) => {
    const emailLogo = _.clone(this.state.emailLogo);
    emailLogo.loading = true;
    this.setState({ emailLogo });

    try {
      const result = await this.props.addEmailLogo({image}).send();
      this.props.mixpanel.track('Email logo updated');
      const form = _.cloneDeep(this.state.form);
      form.emailLogo = result.data;
      emailLogo.loading = false;
      this.setState({ form, emailLogo });
    } catch (e) {
      emailLogo.loading = false;
      emailLogo.errors = ErrorUtil.formatErrors(e);
      this.setState({ emailLogo });
    }
  };

  onRemoveLogo = async () => {
    const emailLogo = _.clone(this.state.emailLogo);
    emailLogo.loading = true;
    this.setState({ emailLogo });

    try {
      await this.props.removeEmailLogo().send();
      this.props.mixpanel.track('Email logo removed');
      const form = _.cloneDeep(this.state.form);
      form.emailLogo = null;
      emailLogo.loading = false;
      this.setState({ form, emailLogo });
    } catch (e) {
      emailLogo.loading = false;
      emailLogo.errors = ErrorUtil.formatErrors(e);
      this.setState({ emailLogo });
    }
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const {fromName, fromEmail, emailDomain, companyName, companyAddress, copyright, helpUrl, supportEmail, websiteUrl} = this.state.form;
      const requestData:MessageSettingsRequest = {
        fromName: fromName !== '' ? fromName : null,
        fromEmail: fromEmail !== '' ? fromEmail : null,
        emailDomain: emailDomain !== '' ? emailDomain : null,
        companyName: companyName !== '' ? companyName : null,
        companyAddress: companyAddress !== '' ? companyAddress : null,
        copyright: copyright !== '' ? copyright : null,
        helpUrl: helpUrl !== '' ? helpUrl : null,
        supportEmail: supportEmail !== '' ? supportEmail : null,
        websiteUrl: websiteUrl !== '' ? websiteUrl : null
      };

      const result = await this.props.updateMessageSettings(requestData).send();
      this.props.mixpanel.track('Email settings updated');
      const account = this.props.account.data;
      if(!AccountUtil.isSetupRecommendationUsed('emailSettings', account)) {
        let requestData:AccountRequest = AccountUtil.addSetupRecommendationToRequest('emailSettings', {}, this.props.account.data);
        await this.props.updateAccount(requestData).send();
      }

      this.setState({ loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes, user } = this.props;
    const { form, loading, errors, emailLogo } = this.state;


    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } }/>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            {AclUtil.hasRole(user.data, 'ROLE_SUPER_ADMIN') &&
              <div className={classes.mb2}>
                <TextField name="domain"
                           label={IntlFormatter.formatMessage(intl, 'domain')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.emailDomain')}
                           value={form.emailDomain}
                           fullWidth={true}/>
              </div>
            }

            <div className={classes.mb2}>

              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField name="companyName"
                               label={IntlFormatter.formatMessage(intl, 'company_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.companyName')}
                               value={form.companyName}
                               fullWidth={true}
                               helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'email_settings_company_name_help')}
                                                          text={IntlFormatter.formatMessage(intl, 'whats_this')} />}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField name="fromName"
                               label={IntlFormatter.formatMessage(intl, 'from_email_name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.fromName')}
                               value={form.fromName}
                               fullWidth={true}
                               helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'email_settings_from_name_help')}
                                                          text={IntlFormatter.formatMessage(intl, 'whats_this')} />} />
                  </Grid>
                </Grid>
              </div>
              {/*{ReduxUtil.hasData(subscription) && SubscriptionUtil.isEnterprise(subscription.data) &&
                <div className={classes.mb2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField name="host"
                                 label={IntlFormatter.formatMessage(intl, 'host')}
                                 onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.host')}
                                 value={form.host}
                                 fullWidth={true} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField name="fromEmail"
                                 label={IntlFormatter.formatMessage(intl, 'from_email')}
                                 onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.fromEmail')}
                                 value={form.fromEmail}
                                 fullWidth={true}
                                 helperText={IntlFormatter.formatMessage(intl, 'email_settings_from_email_help')}
                                 validators={['isEmail']}
                                 errorMessages={[IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
                    </Grid>
                  </Grid>
                </div>
              }*/}
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField name="supportEmail"
                               label={IntlFormatter.formatMessage(intl, 'support_email')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.supportEmail')}
                               value={form.supportEmail}
                               fullWidth={true}
                               helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'email_settings_support_email_help')}
                                                          text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                               validators={['isEmail']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_email_invalid')]}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField name="websiteUrl"
                               label={IntlFormatter.formatMessage(intl, 'website_url')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.websiteUrl')}
                               value={form.websiteUrl}
                               fullWidth={true}
                               helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'email_settings_website_url_help')}
                                                          text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                               validators={['isUrl']}
                               errorMessages={[
                                 IntlFormatter.formatMessage(intl, 'validation_url_invalid'),
                               ]}/>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField name="helpUrl"
                               label={IntlFormatter.formatMessage(intl, 'help_url')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.helpUrl')}
                               value={form.helpUrl}
                               fullWidth={true}
                               helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'email_settings_help_url_help')}
                                                          text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                               validators={['isUrl']}
                               errorMessages={[
                                 IntlFormatter.formatMessage(intl, 'validation_url_invalid'),
                               ]}/>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <TextField name="copyright"
                               label={IntlFormatter.formatMessage(intl, 'copyright')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.copyright')}
                               value={form.copyright}
                               fullWidth={true} helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'email_settings_copyright_help')}
                                                                           text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                    />
                  </Grid>
                </Grid>
              </div>

              <div className={classes.mb2}>
                <TextField name="companyAddress"
                           label={IntlFormatter.formatMessage(intl, 'company_address')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.companyAddress')}
                           value={form.companyAddress}
                           helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'email_settings_address_help')}
                                                      text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                           multiline={true}
                           rows={4}
                           fullWidth={true}/>
              </div>
            </div>

            <div className={classes.mt2}>
              <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                {this.props.onCancel &&
                <Grid item>
                  <Button onClick={this.props.onCancel}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Grid>
                }
                <Grid item>
                  <SubmitButton loading={loading}>
                    <FormattedMessage id="save" />
                  </SubmitButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <DropzoneField label={IntlFormatter.formatMessage(intl, 'email_logo')}
                           helperText={IntlFormatter.formatMessage(intl, 'email_settings_logo_help')}
                           loading={emailLogo.loading}
                           accept="image/jpeg, image/png"
                           imageDimensions={{ width: 150, height: 100 }}
                           multiple={false}
                           preview={false}
                           value={form.emailLogo}
                           onAdd={this.onAddLogo}
                           onRemove={this.onRemoveLogo}
                           confirmRemoveMessage={IntlFormatter.formatMessage(intl, 'email_logo')}
                           confirmRemove={true}/>

          </Grid>
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    subscription: state.subscription,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateMessageSettings(data:MessageSettingsRequest) {
    return dispatch(updateMessageSettings(data));
  },
  addEmailLogo(image:ImageRequest) {
    return dispatch(addEmailLogo(image));
  },
  removeEmailLogo() {
    return dispatch(removeEmailLogo());
  },
  updateAccount(data:AccountRequest) {
    return dispatch(updateAccount(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(EmailSettingsForm)));
