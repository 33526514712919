class ObjectUtil {
  static isEqual = (obj1:Object, obj2:Object) => {
    const obj1String = JSON.stringify(obj1);
    const obj2String = JSON.stringify(obj2);
    return obj1String === obj2String;
  };

  static stringArrayToNumbers = (arr:string[]):number[] => {
    const newArray:number[] = [];
    arr.forEach(item => {
      newArray.push(parseInt(item));
    });
    return newArray;
  };

  static numberArrayToStrings = (arr:number[]):string[] => {
    const newArray:string[] = [];
    arr.forEach(item => {
      newArray.push(item.toString());
    });
    return newArray;
  };

  static isNullOrUndefined = (val:any) => {
    return val === null || val === undefined;
  };
}

export default ObjectUtil;