import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    position: 'fixed',
    zIndex: 10000,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,.05)'
  },
  container: {
    marginTop: 13,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  message: {
    marginRight: theme.spacing(1)
  }
});

export default styles;
