import {
  defaultFont,
  menuIconOpacity,
  menuIconMinWidth,
  menuTransition,
  hexToRgb
} from "../index";
import {drawerWidth, appBarHeight} from '../constants';
import palette from '../palette';
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: 1,
    boxShadow:
      "0 10px 30px -12px rgba(" +
      hexToRgb(palette.black) +
      ", 0.42), 0 4px 25px 0px rgba(" +
      hexToRgb(palette.black) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(palette.black) +
      ", 0.2)",
    width: drawerWidth,
    height: "100%",
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%",
      overflow: "auto",
    }
  },
  drawerItemsContainer: {
    width: drawerWidth,
    backgroundColor: theme.palette.menu.background,
    color: theme.palette.menu.contrastText,
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0"
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100vh'
  },
  menuItems: {
    overflowY: 'scroll',
    flexGrow: 1,
    height: '100vh',
    '-ms-overflow-style': 'none'
  },
  toolbar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minHeight: appBarHeight - theme.spacing(2),
    marginBottom: 1
  },
  logoContainer: {
    color: palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: appBarHeight - theme.spacing(2),
    '& img' : {
      maxHeight: appBarHeight - theme.spacing(2) - 5
    },
  },
  divider: {
    marginTop: 8,
    backgroundColor: "rgba(" + hexToRgb(palette.menu.contrastTextAlt!) + ", .25)",
    height: 1
  },
  list: {
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
  },
  navLink: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: theme.palette.menu.contrastTextAlt
    },
    '& .MuiListItemIcon-root': {
      minWidth: menuIconMinWidth
    }
  },
  itemSecondaryContainer: {
    paddingRight: theme.spacing(1)
  },
  itemLink: {
    width: "auto",
    transition: menuTransition,
    position: "relative",
    display: "block",
    padding: "10px 25px",
    backgroundColor: palette.menu.background,
    ...defaultFont,
    "&:hover": {
      backgroundColor: palette.menu.backgroundAlt,
      '& $itemIcon': {
        color: "rgba(" + hexToRgb(palette.menu.contrastTextAlt!) + ", 1)",
      },
      '& $itemText': {
        color: palette.menu.contrastTextAlt,
      },
      '& $nestedItemExpandIcon': {
        color: "rgba(" + hexToRgb(palette.menu.contrastTextAlt!) + ", 1)",
      }
    },
    '& .MuiListItemIcon-root': {
      minWidth: menuIconMinWidth
    }
  },
  itemLinkDisabled: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  itemLinkActive: {
    marginLeft: 0,
    backgroundColor: palette.menu.backgroundAlt,
    '& $itemText': {
      color: palette.menu.contrastTextAlt,
    },
    '& $itemIcon': {
      color: "rgba(" + hexToRgb(palette.menu.contrastTextAlt!) + ", 1)",
    },
    '& $nestedItemExpandIcon': {
      color: palette.menu.contrastTextAlt,
    }
  },
  itemIconContainer: {
    float: 'left'
  },
  itemIcon: {
    width: "22px",
    height: "30px",
    fontSize: "22px",
    lineHeight: "30px",
    float: "left",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb(palette.menu.contrastTextAlt!) + ", " + menuIconOpacity + ")",
    transition: menuTransition
  },
  itemText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "16px",
    color: palette.menu.contrastText,
    fontWeight: 'bold',
  },
  nestedItem: {
    padding: "10px 25px",
    '& .MuiListItemIcon-root': {
      minWidth: menuIconMinWidth
    },
    "&:hover": {
      backgroundColor: palette.menu.backgroundAlt,
      '& $nestedItemText': {
        color: palette.menu.contrastTextAlt,
      },
    }
  },
  nestedItemActive: {
    backgroundColor: palette.menu.backgroundAlt,
    '& $nestedItemText': {
      color: palette.menu.contrastTextAlt,
    }
  },
  nestedItemText: {
    margin: "0",
    lineHeight: "20px",
    fontSize: "15px",
    color: palette.menu.contrastText,
  },
  nestedItemIcon: {
    marginLeft: 7,
    fontSize: "8px",
    color: "rgba(" + hexToRgb(palette.menu.contrastTextAlt!) + ", .1)",
  },
  nestedItemExpandIcon: {
    color: palette.menu.contrastText
  }
});

export default styles;
