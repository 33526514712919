import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {
  AddButton,
  ClipboardText,
  DeleteDialog,
  ErrorList,
  NoResultsCard,
  PageTitle,
  Pagination,
  PaginationHeader,
  SearchBar,
  UserAvatar
} from '../../../components';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {
  Button,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Hidden,
  Divider,
  Tooltip,
  ListItem,
  ListItemSecondaryAction,
  List,
  TableSortLabel,
  Fab, WithStyles
} from "@material-ui/core";
import {SearchUtil, ReduxUtil, ErrorUtil, AclUtil} from "../../../utils";
import _ from "lodash";
import {Link, Redirect} from "react-router-dom";
import pageStyles from '../../../theme/jss/layouts/pageStyles';
import clsx from "clsx";
import {
  ActionResponse,
  DialogState,
  ReduxBranches,
  ReduxLoanSettings,
  ReduxUser,
  ActionProps,
  SearchCriteriaWithPagingType,
  SnackbarState,
  ErrorState
} from "../../../types";
import {Branch, BranchSearchRequest} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  user: ReduxUser,
  branches: ReduxBranches,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  hasInitialResults?: boolean,
  selectedActionMenu?: Element|null,
  deleteDialog: DialogState,
  selectedBranch: Branch|null,
  searchCriteria: SearchCriteriaWithPagingType,
  redirectTo?: string,
  snackbar?: SnackbarState,
  errors: ErrorState[]
}

class BranchPage extends Component<Props, State> {

  branchSearch:ActionResponse|null;

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      selectedBranch: null,
      searchCriteria: {
        searchText: '',
        order: {
          column: 'name',
          direction: 'asc'
        },
        limit: 10,
        skip: 0,
        totalCount: 0,
        page: 1
      },
      errors: []
    };
  }

  componentDidMount = async () => {
    if(AclUtil.hasRole(this.props.user.data, 'ROLE_BRANCH_MANAGER') && this.props.user.data.roles.length === 1 && this.props.user.data.additionalProps && this.props.user.data.additionalProps.branch) {
      this.setState({ redirectTo: `/admin/branches/detail/${this.props.user.data.additionalProps.branch._id}` });
    } else {
      try {
        const result = await this.searchBranches();
        this.setState({ pageLoaded: true, hasInitialResults: result.data.length > 0 }, () => {
          this.branchSearch = null;
        });
      } catch(err) {
        this.onError(err);
      }
    }
  };

  componentWillUnmount = () => {
    if (this.branchSearch) {
      this.branchSearch.cancel();
    }
  };

  searchTextChanged = (value:string) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.searchText = value;
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.filterBranches);
  };

  searchTextRemove = () => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.searchText = '';
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.filterBranches);
  };

  filterBranches = async () => {
    try {
      await this.searchBranches();
    } catch (e) {
      this.onError(e);
    }
  };

  searchBranches = ():Promise<AxiosResponse<Branch[]>> => {
    return new Promise((resolve, reject) => {
      const {searchCriteria} = this.state;

      this.setState({loading: true}, () => {
        const criteria:BranchSearchRequest = {
          searchText: searchCriteria.searchText
        };

        const params = SearchUtil.getParamsFromSearchCriteria(searchCriteria);

        if (this.branchSearch) {
          this.branchSearch.cancel('Another request was made');
        }

        this.branchSearch = this.props.routeProps.searchBranches(criteria, params);
        this.branchSearch.send().then(result => {
          const searchCriteria = _.cloneDeep(this.state.searchCriteria);
          searchCriteria.totalCount = parseInt(result.headers['total-count']);
          this.setState({loading: false, searchCriteria});
          this.branchSearch = null;
          resolve(result);
        }).catch(err => {
          this.branchSearch = null;
          reject(err);
        });
      });
    });
  };

  onError = (err:any) => {
    this.setState({loading: false, errors: ErrorUtil.formatErrors(err)});
  };

  showDeleteDialog = () => {

    const deleteDialog = _.cloneDeep(this.state.deleteDialog);
    deleteDialog.open = true;

    this.setState({
      deleteDialog,
      selectedActionMenu: null
    });
  };

  hideDeleteDialog = () => {
    const deleteDialog = _.cloneDeep(this.state.deleteDialog);
    deleteDialog.open = false;
    deleteDialog.errors = [];

    this.setState({
      deleteDialog,
      selectedBranch: null
    });
  };

  deleteBranch = async (branch:Branch) => {
    const deleteDialog = _.cloneDeep(this.state.deleteDialog);
    try {
      deleteDialog.loading = true;
      this.setState({ deleteDialog });
      await this.props.routeProps.deleteBranch(branch._id).send();
      this.props.mixpanel.track("Branch archived");
      deleteDialog.loading = false;
      deleteDialog.open = false;

      this.setState({
        deleteDialog,
        selectedBranch: null,
        snackbar: {
          open: true,
          variant: 'success',
          message: IntlFormatter.formatMessage(this.props.intl, 'branch_deleted')
        }
      }, () => {
        this.setState({ snackbar: undefined });
      });

      await this.filterBranches();
    } catch (e) {
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({ deleteDialog });
    }

  };

  onActionMenuClick = (event:MouseEvent, branch:Branch) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ selectedActionMenu: event.currentTarget, selectedBranch: branch });
  };

  onActionMenuClose = () => {
    this.setState({ selectedActionMenu: null, selectedBranch: null });
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'branches');
  };

  renderTitleBar = () => {
    const {user} = this.props;
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <PageTitle title={this.getPageTitle()} icon="business" />
        </Grid>
        {AclUtil.isOwner(user.data) &&
          <Grid item>
            <Hidden mdUp>
              <Tooltip title={IntlFormatter.formatMessage(this.props.intl, 'add_branch')}>
                <Fab color="primary"
                     component={Link} size="small"
                     to="/admin/branches/add">
                  <Icon>add</Icon>
                </Fab>
              </Tooltip>
            </Hidden>
            <Hidden smDown>
              <AddButton variant="contained"
                         color="primary"
                         to="/admin/branches/add">
                <FormattedMessage id="add_branch" />
              </AddButton>
            </Hidden>
          </Grid>
        }
      </Grid>
    )

  };

  onPageChange = (event:MouseEvent, value:number) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.skip = (value - 1) * searchCriteria.limit;
    searchCriteria.page = value;
    this.setState({ searchCriteria }, this.filterBranches);
  };

  onSortColumnChange = (event:MouseEvent, column:string) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.order!.column = column;
    searchCriteria.order!.direction = searchCriteria.order!.direction === 'asc' ? 'desc' : 'asc';
    this.setState({ searchCriteria }, this.filterBranches);
  };

  render() {

    const { intl, branches, classes, settings, user } = this.props;
    const { pageLoaded, loading, searchCriteria, deleteDialog, selectedBranch, snackbar, errors, selectedActionMenu, redirectTo, hasInitialResults } = this.state;
    const pages = Math.ceil(searchCriteria.totalCount / searchCriteria.limit);

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   loading={loading}
                   pageLoaded={pageLoaded}
                   titleBar={this.renderTitleBar()}
                   snackbar={snackbar}
                   breadcrumbs={[
                    {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                    {title: IntlFormatter.formatMessage(intl, 'branches') }
                    ]}>

        {selectedBranch &&
          <div>
            <DeleteDialog open={deleteDialog.open}
                          loading={deleteDialog.loading}
                          errors={deleteDialog.errors}
                          title={IntlFormatter.formatMessage(intl, 'delete_branch')}
                          item={selectedBranch ? selectedBranch.name : ''}
                          onCancel={this.hideDeleteDialog}
                          onSubmit={() => this.deleteBranch(selectedBranch)} />

            <Menu anchorEl={selectedActionMenu}
                  open={Boolean(selectedActionMenu)}
                  onClose={this.onActionMenuClose}>
              <MenuItem component={Link} to={`/admin/branches/detail/${selectedBranch._id}`}>
                <ListItemIcon>
                  <Icon>remove_red_eye</Icon>
                </ListItemIcon>
                <ListItemText primary={IntlFormatter.formatMessage(intl, 'view')} />
              </MenuItem>
              <MenuItem component={Link} to={`/admin/branches/edit/${selectedBranch._id}`}>
                <ListItemIcon>
                  <Icon>mode_edit</Icon>
                </ListItemIcon>
                <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
              </MenuItem>
              {AclUtil.isOwner(user.data) &&
                <MenuItem onClick={this.showDeleteDialog}
                          disabled={settings.data.defaultBranch!._id === selectedBranch._id}>
                  <ListItemIcon>
                    <Icon>cancel</Icon>
                  </ListItemIcon>
                  <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')}/>
                </MenuItem>
              }
            </Menu>
          </div>
        }

        <div>
          <ErrorList errors={errors}
                     className={classes.m2}
                     onClose={() => { this.setState({ errors: [] }); } } />

          {hasInitialResults &&
            <div>
              <div className={classes.pageHeaderContainer}>
                  <SearchBar onSearchTextChanged={this.searchTextChanged}
                             onSearchTextRemove={this.searchTextRemove} />
              </div>
              {ReduxUtil.hasData(branches) && branches.data.length > 0 &&
                <div>
                  <div className={clsx(classes.ph2, classes.pv1)}>
                    <PaginationHeader totalResults={searchCriteria.totalCount}
                                      currentPage={searchCriteria.page}
                                      totalPages={pages} />
                  </div>
                  <Hidden mdUp implementation="css">
                    <Divider />
                    <List>
                      {branches.data.map((branch, i) => {
                        return (
                          <ListItem key={i}>
                            <ListItemText primary={branch.name}
                                          secondary={`${IntlFormatter.formatMessage(intl, 'nmls_id')}: ${branch.nmlsId}`} />
                            <ListItemSecondaryAction>
                              <IconButton onClick={event => this.onActionMenuClick(event, branch)}>
                                <Icon>more_vert</Icon>
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      })}
                    </List>
                  </Hidden>
                  <Hidden smDown implementation="css">
                    <div>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <TableSortLabel active={searchCriteria.order!.column === 'name'}
                                              direction={searchCriteria.order!.direction}
                                              onClick={event => this.onSortColumnChange(event, 'name')}>
                                <FormattedMessage id="name"/>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell>
                              <FormattedMessage id="branch_manager"/>
                            </TableCell>
                            <TableCell>
                              <TableSortLabel active={searchCriteria.order!.column === 'email'}
                                              direction={searchCriteria.order!.direction}
                                              onClick={event => this.onSortColumnChange(event, 'email')}>
                                <FormattedMessage id="email"/>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell>
                              <TableSortLabel active={searchCriteria.order!.column === 'nmlsId'}
                                              direction={searchCriteria.order!.direction}
                                              onClick={event => this.onSortColumnChange(event, 'nmlsId')}>
                                <FormattedMessage id="nmls_id"/>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell/>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {branches.data.map(branch => {
                            return (
                              <TableRow key={branch._id}>
                                <TableCell component="th" scope="row">
                                  <Link to={`/admin/branches/detail/${branch._id}`}>
                                    <strong>{branch.name}</strong>
                                  </Link>

                                  {settings.data.defaultBranch!._id === branch._id &&
                                  <Tooltip title={IntlFormatter.formatMessage(intl, 'default_branch')}>
                                    <IconButton size="small" className={classes.ml1}>
                                      <Icon fontSize="small">info</Icon>
                                    </IconButton>
                                  </Tooltip>
                                  }
                                </TableCell>
                                <TableCell>
                                  {branch.manager &&
                                    <div className={classes.rowCenter}>
                                      <UserAvatar user={branch.manager} className={classes.mr1} />
                                      <Typography>
                                        {branch.manager.firstName} {branch.manager.lastName}
                                      </Typography>
                                    </div>
                                  }
                                </TableCell>
                                <TableCell>
                                  <ClipboardText value={branch.email}>
                                    {branch.email}
                                  </ClipboardText>
                                </TableCell>
                                <TableCell>{branch.nmlsId}</TableCell>
                                <TableCell align="right">
                                  <IconButton onClick={event => this.onActionMenuClick(event, branch)}>
                                    <Icon>more_vert</Icon>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  </Hidden>

                  {pages > 1 &&
                    <Pagination count={pages}
                                color="primary"
                                onChange={this.onPageChange} />
                  }
                </div>
              }
            </div>
          }

          {(!loading && ReduxUtil.hasData(branches) && branches.data.length === 0) &&
          <div className={classes.p2}>
            <NoResultsCard message={IntlFormatter.formatMessage(intl, 'no_branches_found')}>
              <div>
                {(!hasInitialResults && AclUtil.isOwner(user.data)) &&
                <div className={classes.mt1}>
                  <Button color="primary" variant="contained" component={Link} to="/admin/branches/add">
                    <FormattedMessage id="add_branch"/>
                  </Button>
                </div>
                }
              </div>
            </NoResultsCard>
          </div>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    branches: state.branches,
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(BranchPage)));
