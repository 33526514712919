import React, {Component, RefObject, MouseEvent} from 'react';
import {Redirect} from 'react-router-dom';
import {
  Grid,
  Typography,
  Icon,
  withStyles, ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, WithStyles
} from '@material-ui/core';
import {connect} from 'react-redux';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {AccountPage} from '../../../layouts';
import styles from "../../../theme/jss/pages/adminDashboardStyles";
import {LoanTrendChart, LoanStatusChart} from "../components";
import {Tile} from "../../../components";
import {ErrorUtil, UserUtil} from "../../../utils";
import moment from 'moment';
import themePalette from "../../../theme/jss/palette";
import _ from 'lodash';
import {ReduxState} from "../../../data/initialState";
import {ActionProps, ErrorState, FixMeLater, ReduxDashboard, ReduxLoanSettings, ReduxUser} from "../../../types";
import {LoanStatusChartProps} from '../components/LoanStatusChart';
import {LoanTrendChartProps} from '../components/LoanTrendChart';
import {LoanStatusReport, LoanSummaryReportItem} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

const palette:FixMeLater = themePalette;

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  user: ReduxUser,
  dashboard: ReduxDashboard,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  redirectTo?: string,
  pageLoaded: boolean,
  loading: boolean,
  errors: ErrorState[],
  intervalPopperOpen: boolean,
  selectedIntervalIndex: number
}

type Options = {
  label: string,
  amount: number,
  unit: 'days'|'months'|'years'
}

type BorderColorMap = {
  started: string,
  abandoned: string,
  processing: string,
  closed: string,
  lost: string
}

class AdminDashboardPage extends Component<Props, State> {

  options:Options[] = [];
  anchorEl:RefObject<HTMLDivElement>;
  borderColorMap:BorderColorMap = {
    started: palette.info!.main,
    abandoned: palette.danger.main,
    processing: palette.yield.main,
    closed: palette.success!.main,
    lost: palette.warning!.main
  };

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      errors: [],
      intervalPopperOpen: false,
      selectedIntervalIndex: 0
    };

    this.anchorEl = React.createRef();

    this.options = [
      { label: IntlFormatter.formatMessage(props.intl, 'last_30_days'), amount: 30, unit: 'days' },
      { label: IntlFormatter.formatMessage(props.intl, 'last_60_days'), amount: 60, unit: 'days' },
      { label: IntlFormatter.formatMessage(props.intl, 'last_90_days'), amount: 90, unit: 'days' },
      { label: IntlFormatter.formatMessage(props.intl, 'last_6_months'), amount: 6, unit: 'months' },
      { label: IntlFormatter.formatMessage(props.intl, 'last_year'), amount: 1, unit: 'years' },
    ];
  }

  componentDidMount = async () => {
    await this.fetchData();
    this.setState({pageLoaded: true});
  };

  fetchData = async () => {
    this.setState({ loading: true });
    try {
      const {selectedIntervalIndex} = this.state;

      const startDate = moment().subtract(this.options[selectedIntervalIndex].amount, this.options[selectedIntervalIndex].unit).toISOString();
      const prevStartDate = moment().subtract(this.options[selectedIntervalIndex].amount * 2, this.options[selectedIntervalIndex].unit).toISOString();

      await this.props.routeProps.getDashboard(startDate, prevStartDate, this.options[selectedIntervalIndex].unit).send();

      this.setState({loading: false});
    } catch(err) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(err)});
    }
  };

  getLoanStatusData = ():LoanStatusChartProps["data"] => {
    // loan statuses are started, abandoned, processing, closed
    const backgroundColors:string[] = [
      palette.info.main,
      palette.danger.main,
      palette.yield.main,
      palette.success.main,
      palette.warning.main,
    ];

    const loanStatus = _.cloneDeep(this.props.dashboard.data.loanStatus);

    // remove the 0 values for the chart
    let totalLoans:number = 0;
    for(let key in loanStatus) {
      if(loanStatus.hasOwnProperty(key)) {
        totalLoans += loanStatus[key as keyof LoanStatusReport];
      }
    }

    const percentageData:LoanStatusChartProps["data"]["percentageData"] =  [];
    const labels:string[] = [];

    Object.keys(loanStatus).forEach((key:string) => {
      const label = IntlFormatter.formatMessage(this.props.intl, key);
      labels.push(label);
      let value = 0;
      if(totalLoans > 0) {
        value = Math.round((loanStatus[key as keyof LoanStatusReport] / totalLoans) * 100);
      }
      percentageData.push({
        title: label,
        value,
        color: this.borderColorMap[key as keyof BorderColorMap]
      })
    });

    const chartData = {
      datasets: [
        {
          data: Object.values(loanStatus),
          backgroundColor: backgroundColors,
          borderWidth: 8,
          borderColor: palette.white,
          hoverBorderColor: palette.white
        }
      ],
      labels
    };

    return { chartData, percentageData };
  };

  onIntervalClick = () => {
    this.setState({ intervalPopperOpen: true });
  };

  onCloseIntervalPopover = () => {
    this.setState({ intervalPopperOpen: false });
  };

  onIntervalItemClick = (event:MouseEvent, index:number) => {
    this.setState({ intervalPopperOpen: false, selectedIntervalIndex: index }, this.fetchData);
    const option = this.options[index];
    if(option) {
      this.props.mixpanel.track('Dashboard report updated', {interval: option.label});
    }
  };

  getLoanTrendData = ():LoanTrendChartProps["data"] => {
    const interval = this.options[this.state.selectedIntervalIndex];
    const loanSummary = _.cloneDeep(this.props.dashboard.data.loanSummary);
    //loanSummary.reverse();
    //console.log(loanSummary);
    const labels:string[] = [];
    const datasets:FixMeLater[] = [];

    loanSummary.forEach((s:LoanSummaryReportItem) => {
      let label = `${s.year}`;
      if(interval.unit === 'days') {
        label = `${s.month}/${s.day}/${s.year}`;
      } else if(interval.unit === 'months') {
        label = `${s.month}/${s.year}`;
      }

      if(labels.indexOf(label) === -1) {
        labels.push(label);
      }

      if(!datasets.find(x => x.key === s.status)) {
        datasets.push({
          key: s.status,
          label: IntlFormatter.formatMessage(this.props.intl, s.status),
          borderColor: this.borderColorMap[s.status],
          data: []
        })
      }
    });

    datasets.forEach((dataset, i) => {
      labels.forEach(label => {
        let data:FixMeLater;
        let count = 0;
        if(interval.unit === 'days') {
          const dateArray = label.split('/');
          const year = parseInt(dateArray[2]);
          const month = parseInt(dateArray[0]);
          const day = parseInt(dateArray[1]);
          data = loanSummary.find(s => s.status === dataset.key && s.year === year && s.month === month && s.day === day);
        } else if(interval.unit === 'months') {
          const dateArray = label.split('/');
          const year = parseInt(dateArray[1]);
          const month = parseInt(dateArray[0]);
          data = loanSummary.find(s => s.status === dataset.key && s.year === year && s.month === month);
        } else {
          data = loanSummary.find(s => s.status === dataset.key && s.year === parseInt(label));
        }

        if(data) {
          count = data.count;
        }

        datasets[i].data.push(count);
      });
    });

    const data:LoanTrendChartProps["data"] = {
      fill: false,
      labels,
      datasets
    };

    // add the interval back even if its 0 so chart looks ok with one data point.
    const firstDate = moment().subtract(interval.amount, interval.unit);
    const formattedFirstDate = firstDate.format(interval.unit === 'years' ? 'YYYY' : 'M/D/YYYY');
    if(!data.labels.includes(formattedFirstDate)) {
      data.labels.unshift(formattedFirstDate);
      for(let i=0;i<data.datasets.length;i++) {
        data.datasets[i].data.unshift(0);
      }
    }

    return data;
  };

  renderTrendData = (value:number) => {
    const { classes } = this.props;
    const { selectedIntervalIndex } = this.state;

    const styleData:any = {
      icon: null,
      iconClass: null,
      labelClass: null
    };

    if(value === 0) {
      styleData.icon = 'remove';
      styleData.iconClass = classes.differenceIcon;
      styleData.labelClass = classes.differenceValue;
    } else if(value > 0) {
      styleData.icon = 'arrow_upward';
      styleData.iconClass = classes.differenceIconSuccess;
      styleData.labelClass = classes.differenceValueSuccess;
    } else {
      styleData.icon = 'arrow_downward';
      styleData.iconClass = classes.differenceIconDanger;
      styleData.labelClass = classes.differenceValueDanger;
    }

    return (
      <div className={classes.difference}>
        <Icon className={styleData.iconClass}>{styleData.icon}</Icon>
        <Typography className={styleData.labelClass} variant="body2">
          {Math.abs(value)}%
        </Typography>
        <Typography
          color="inherit"
          variant="caption">
            {this.options[selectedIntervalIndex].label}
        </Typography>
      </div>
    )
  };

  getTitle = () => {
    const { intl, user, classes } = this.props;
    let title = IntlFormatter.formatMessage(intl, 'company');
    let icon = 'store';
    if(UserUtil.hasRole(user.data, 'ROLE_BRANCH_MANAGER') && user.data.additionalProps!.branch) {
      title = user.data.additionalProps!.branch.name;
      icon = 'business';
    } else if(UserUtil.hasRole(user.data, 'ROLE_LOAN_OFFICER') && user.data.additionalProps!.loanOfficer) {
      title = user.data.additionalProps!.loanOfficer.fullName;
      icon = 'people_outline';
    }

    return (
      <div className={classes.rowCenter}>
        <div className={classes.mr1}>
          <Icon>{icon}</Icon>
        </div>
        <Typography variant="subtitle1">
          {title}
        </Typography>
      </div>
    );
  }

  render() {

    const { intl, classes, dashboard, settings } = this.props;
    const { pageLoaded, loading, redirectTo, selectedIntervalIndex, intervalPopperOpen } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo}/>
      )
    }


    return (
      <AccountPage pageTitle={IntlFormatter.formatMessage(intl, 'dashboard')}
                   paper={false}
                   pageLoaded={pageLoaded}
                   loading={loading}>
        {pageLoaded &&
          <div>
            <Paper className={classes.mb3}>
              <div className={classes.p2}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>
                    {this.getTitle()}
                  </Grid>
                  <Grid item>
                    <ButtonGroup ref={this.anchorEl}>
                      <Button onClick={this.onIntervalClick}>{this.options[selectedIntervalIndex].label}</Button>
                      <Button
                        size="small"
                        onClick={this.onIntervalClick}
                      >
                        <Icon>arrow_drop_down</Icon>
                      </Button>
                    </ButtonGroup>
                    <Popper open={intervalPopperOpen}
                            anchorEl={this.anchorEl.current}
                            role={undefined}
                            transition
                            disablePortal
                            className={classes.filters}
                            style={{zIndex: 1000}}>
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={this.onCloseIntervalPopover}>
                                <MenuList id="split-button-menu">
                                  {this.options.map((option, index) => {
                                    if(index !== selectedIntervalIndex) {
                                      return (
                                        <MenuItem
                                          key={index}
                                          disabled={index === selectedIntervalIndex}
                                          selected={index === selectedIntervalIndex}
                                          onClick={(event:MouseEvent) => this.onIntervalItemClick(event, index)}>
                                          {option.label}
                                        </MenuItem>
                                      )
                                    }

                                    return null;
                                  })}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <div className={classes.mb2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Tile title={IntlFormatter.formatMessage(intl, 'new_loans')}
                        icon={<Icon>library_books</Icon>}
                        to="/admin/loan-applications?status=started"
                        primaryText={dashboard.data.loanStatus.started.toString()}
                        color="info"
                        content={this.renderTrendData(dashboard.data.loanStatusTrend.started)} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Tile title={IntlFormatter.formatMessage(intl, 'abandoned_loans')}
                        icon={<Icon>library_books</Icon>}
                        to="/admin/loan-applications?status=abandoned"
                        primaryText={dashboard.data.loanStatus.abandoned.toString()}
                        color="danger"
                        content={this.renderTrendData(dashboard.data.loanStatusTrend.abandoned)} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Tile title={IntlFormatter.formatMessage(intl, 'closed_loans')}
                        icon={<Icon>library_books</Icon>}
                        to="/admin/loan-applications?status=closed"
                        primaryText={dashboard.data.loanStatus.closed.toString()}
                        color="success"
                        content={this.renderTrendData(dashboard.data.loanStatusTrend.closed)} />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Tile title={IntlFormatter.formatMessage(intl, 'loan_volume')}
                        icon={<Icon color="inherit">attach_money</Icon>}
                        primaryText={settings.data.tridFields.includes('loanAmount') ? dashboard.data.loanVolume.volume.toMoney(0) : dashboard.data.loanVolume.loans.toString()}
                        color="primary"
                        content={
                            dashboard.data.loanVolumeTrend ?
                              this.renderTrendData(dashboard.data.loanVolumeTrend)
                            : null
                        } />
                </Grid>
              </Grid>
            </div>
            <div className={classes.mb2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8}>
                  <LoanTrendChart data={this.getLoanTrendData()} />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <LoanStatusChart data={this.getLoanStatusData()} />
                </Grid>
              </Grid>
            </div>
          </div>
        }
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    dashboard: state.dashboard,
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(injectIntl(AdminDashboardPage)));
