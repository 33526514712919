import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import styles from "../theme/jss/components/linearProgressStyles";
import {PropagateLoader as RSPropagateLoader} from 'react-spinners';
import {LoaderSizeProps} from 'react-spinners/interfaces';
import palette from "../theme/jss/palette";

class PropagateLoader extends Component<LoaderSizeProps> {
  render() {
    return (
      <RSPropagateLoader
        // @ts-ignore
        color={palette.primary.main} {...this.props} />
    );
  }
}

export default withStyles(styles, { withTheme: true })(PropagateLoader);
