import {Box} from "@material-ui/core";
import React from "react";

type Props = {
  value: number,
  index: number,
  children: any
}

export default function TabPanel(props:Props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel"
         hidden={value !== index}
         id={`tabpanel-${index}`}
         aria-labelledby={`tab-${index}`}
         {...other}>
      <Box p={3}>{children}</Box>
    </div>
  );
}