import React, {ReactNode, SyntheticEvent} from 'react';
import {TextField, CircularProgress} from '@material-ui/core';
import MUIAutocomplete, {AutocompleteRenderOptionState} from '@material-ui/lab/Autocomplete';
import {withStyles} from "@material-ui/core/styles";
import styles from "../theme/jss/components/autoCompleteStyles";
import {ValidatorComponent, ValidatorComponentProps} from "react-material-ui-form-validator";
import {FormHelperText} from "@material-ui/core";
import {injectIntl, WrappedComponentProps} from "react-intl";
import IntlFormatter from "../intl";
import {WithStyles} from "@material-ui/styles";
import _ from "lodash";

type DefaultProps = {
  fullWidth: boolean,
  disableClearable: boolean,
  disabled: boolean,
  variant: 'outlined'|'filled'|'standard'
}

type Props = {
  label?: string,
  placeholder?: string,
  value: any,
  getOptionLabel?: (option:any) => string,
  getOptionDisabled?: (option:any) => boolean
  onChange?: (value:any) => void,
  renderOption?: (option: any, state: AutocompleteRenderOptionState) => ReactNode,
  disableClearable?: boolean,
  disabled?: boolean,
  fullWidth?: boolean,
  limitTags?: number,
  onTextChange?: (value:string) => any[],
  onOpen?: () => void,
  getOptionSelected: (option: any, value: any) => boolean,
  openOnFocus: boolean,
  debounce?: number
} & ValidatorComponentProps
  & WrappedComponentProps
  & WithStyles<typeof styles>
  & Partial<DefaultProps>

type State = {
  open: boolean,
  loading: boolean,
  options: any[],
  isValid: boolean
}

class AutoComplete extends ValidatorComponent<Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      options: [],
      isValid: this.state.isValid
    };
  }

  onOpen = async () => {
    this.setState({ open: true });
    await this.onTextChange('');
  };

  onClose = () => {
    this.setState({ open: false });
  };

  onChange = (event:SyntheticEvent, value:any, reason:string) => {
    if(this.props.onChange) {
      this.props.onChange(value);
    }
  };

  onTextChange = async (value:string) => {
    let options:any[] = [];
    if(this.props.onTextChange) {
      options = this.props.onTextChange(value);
      if(options instanceof Promise) {
        this.setState({ loading: true });
        options = await this.props.onTextChange(value);
      }
    }

    this.setState({ options, loading: false });
  };

  isRequired = () => {
    let required = false;
    if(this.props.validators && this.props.validators.indexOf('required') > -1) {
      required = true;
    }

    return required;
  };

  renderError = () => {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <FormHelperText error={true}>
        {this.getErrorMessage()}
      </FormHelperText>
    );
  };

  renderValidatorComponent() {
    const { intl, classes, label, placeholder, value, getOptionLabel, getOptionDisabled, renderOption, multiple, disableClearable, fullWidth, disabled, getOptionSelected, variant, limitTags, helperText, openOnFocus, debounce } = this.props;
    const { open, loading, options, isValid } = this.state;

    return (
      <div className={classes.root}>
        <MUIAutocomplete
          multiple={multiple}
          open={open}
          onOpen={this.onOpen}
          onClose={this.onClose}
          onChange={this.onChange}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          filterSelectedOptions={true}
          options={options}
          loading={loading}
          getOptionSelected={getOptionSelected}
          getOptionDisabled={getOptionDisabled}
          value={value}
          disableClearable={disableClearable}
          disabled={disabled}
          fullWidth={fullWidth}
          noOptionsText={IntlFormatter.formatMessage(intl, 'no_results_found')}
          limitTags={limitTags}
          onInputChange={_.debounce((event:SyntheticEvent, value:string) => this.onTextChange(value), debounce ? debounce : 0)}
          openOnFocus={openOnFocus}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={variant ? variant : 'outlined'}
              label={this.isRequired() ? `${label} *` : label}
              placeholder={placeholder}
              fullWidth={fullWidth ? fullWidth : true}
              error={!isValid}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        {this.renderError()}
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(AutoComplete));