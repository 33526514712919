import React, {ChangeEvent, MouseEvent} from 'react';
import Form from './Form';
import {
  Grid,
  Button,
  withStyles, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../intl';
import BaseForm from "./BaseForm";
import {ErrorList, SubmitButton, TextField} from "../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {ErrorUtil} from "../utils";
import {addSecureMessage} from '../actions/secureMessages'
import pageStyles from "../theme/jss/layouts/pageStyles";
import {
  SecureMessageThread,
  SecureMessage,
  SecureMessageRequest
} from "@jerseydev/orca-loans";
import {ActionResponse, ReduxUser, ErrorState} from "../types";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  secureMessageThread: SecureMessageThread,
  secureMessage?: Partial<SecureMessage>,
  onSubmit?: (data:AxiosResponse<SecureMessage>) => void,
  onCancel?: () => void,
  onMessageFocus?: () => void,
  user: ReduxUser,
  addSecureMessage: (threadId:string, data:SecureMessageRequest) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  message: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class SecureMessageForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    let form:Form = {
      message: ''
    };

    if(props.secureMessage) {
      form = {
        message: props.secureMessage.message ? props.secureMessage.message : ''
      }
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };
  }

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const {message} = this.state.form;
      const {secureMessageThread} = this.props;
      let messageResult = await this.props.addSecureMessage(secureMessageThread._id, {message}).send();
      this.props.mixpanel.track("Secure message added");
      const form = _.cloneDeep(this.state.form);
      form.message = '';
      this.setState({ loading: false, form }, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(_.cloneDeep(messageResult));
        }
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes, onMessageFocus } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); }} />
          <div>
            <TextField name="message"
                       label={IntlFormatter.formatMessage(intl, 'message')}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.message')}
                       value={form.message}
                       fullWidth={true}
                       multiline={true}
                       rows={3}
                       validators={['required']}
                       onFocus={onMessageFocus}
                       errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
          </div>
          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="send" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addSecureMessage(threadId:string, data:SecureMessageRequest) {
    return dispatch(addSecureMessage(threadId, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(SecureMessageForm)));
