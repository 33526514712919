import {colors} from '@material-ui/core';
import {PaletteOptions} from "@material-ui/core/styles/createPalette";

const white = '#FFFFFF';
const black = '#000000';

const palette:PaletteOptions = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#0F5776',
    main: '#18749c',
    light: '#1b9cd9'
  },
  secondary: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[500],
    light: colors.green[300]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[100]
  },
  neutral: {
    contrastText: white,
    dark: colors.grey[900],
    mainLight: colors.grey[500],
    main: colors.grey[600],
    mainDark: colors.grey[700],
    light: colors.grey[400],
    extraLight: colors.grey[100],
    extraExtraLight: colors.grey[50],
    shades: ['#3C4252', '#303030', '#303643', '#EFEFEF', '#EEEEEE']
  },
  danger: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[500],
    light: colors.red[400],
    lightest: colors.red[200]
  },
  yield: {
    contrastText: white,
    dark: colors.yellow[900],
    main: colors.yellow[600],
    light: colors.yellow[400],
    lightest: colors.yellow[200]
  },
  rose: {
    contrastText: white,
    dark: "#d81b60",
    main: "#e91e63",
    light: "#ec407a",
    lightest: "#f8bbd0"
  },
  menu: {
    background: '#253053',
    backgroundAlt: '#212B4B',
    contrastText: '#b8bdc4',
    contrastTextAlt: '#FEFEFE',
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};

export default palette