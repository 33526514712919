import {defaultRadius} from '../index';
import {createStyles, Theme} from "@material-ui/core";

const textColor = '#611A15';
const styles = (theme:Theme) => createStyles({
  root: {
    background: '#FDECEA',
    color: theme.palette.danger.contrastText,
    borderRadius: defaultRadius
  },
  icon: {
    color: theme.palette.danger.main
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: textColor,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  titleIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.danger.main
  },
  title: {
    color: textColor,
    fontSize: '13px',
    fontWeight: 'bold'
  },
  listItem: {
    paddingLeft: theme.spacing(6),
    color: textColor
  },
  listItemText: {
    fontSize: '13px'
  },
  headerDivider: {
    backgroundColor: '#fcf3f2'
  }
});

export default styles;
