import React from 'react';
import {
  Typography,
  Link, Grid, withStyles, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {SubTitle, Well, YearsMonthsSummary} from "../index";
import IntlFormatter from "../../intl";
import {Link as RouterLink} from "react-router-dom";
import {DateUtil, LoanUtil, LocationUtil, TextUtil} from "../../utils";
import Item from "../Item";
import pageStyles from "../../theme/jss/layouts/pageStyles";
import clsx from "clsx";
import {LoanApplication} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

const LoanEmploymentSummaryRev2 = (props:Props) => {

  const {intl, classes, loanApplication} = props;

  if(!loanApplication.borrowers || loanApplication.borrowers.length === 0) {
    return (
      <Well color="warn">
        <Typography variant="body1">
          <FormattedMessage id="no_employment_found" />
        </Typography>
      </Well>
    );
  }

  let form = null;
  if(loanApplication.borrowers.length > 0) {
    form = (
      <Grid container spacing={2}>
        {loanApplication.borrowers.map((borrower, borrowerIndex) => {
          const smMdSize = loanApplication.borrowers && loanApplication.borrowers.length > 1 ? Math.floor(12 / loanApplication.borrowers.length) : 6;
          const totalYearsEmployed = LoanUtil.getBorrowerTotalYearsEmployed(loanApplication, borrower);
          return (
            // @ts-ignore
            <Grid key={borrowerIndex} item
                  xs={12}
                  sm={smMdSize}
                  md={smMdSize}>

              <div className={classes.mb2}>
                <SubTitle title={borrower.fullName || IntlFormatter.formatMessage(intl, 'borrower')} />
              </div>

              {totalYearsEmployed < 2 &&
              <div className={classes.mb2}>
                <Well color="warn">
                  <Typography variant="caption">
                    * <FormattedMessage id="two_years_employment_required" />
                  </Typography>
                </Well>
              </div>
              }

              <div className={classes.mb1}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="current_employment" />
                </Typography>
              </div>

              <div className={classes.mb2}>
                {(!borrower.currentEmployment || (borrower.currentEmployment && borrower.currentEmployment.length === 0)) &&
                <div>
                  <Typography variant="body1">
                    <FormattedMessage id="no_employment_info_found" />. &nbsp;
                    {!loanApplication.completed &&
                    <Link component={RouterLink} to="/apply/form">
                      <FormattedMessage id="add_employment"/>
                    </Link>
                    }
                  </Typography>
                </div>
                }

                {borrower.currentEmployment && borrower.currentEmployment.map((form, i) => {
                  return (
                    <div key={i} className={classes.pv1}>
                      <Typography variant="subtitle2" gutterBottom>
                        {form.name || IntlFormatter.formatMessage(intl, 'na')}
                      </Typography>
                      <div>
                        {form.address &&
                          <Typography variant="body1" gutterBottom>{LocationUtil.formatLocation(form.address)}</Typography>
                        }
                        {form.phoneNumber &&
                          <div className={classes.mb1}>
                            <Typography variant="caption">
                              <FormattedMessage id="phone_number" />
                            </Typography>
                            <Typography variant="body1">{form.phoneNumber}</Typography>
                          </div>
                        }
                        {form.title &&
                          <div className={classes.mb1}>
                            <Typography variant="caption">
                              <FormattedMessage id="title" />
                            </Typography>
                            <Typography variant="body1">{form.title}</Typography>
                          </div>
                        }
                        {form.selfEmployed &&
                          <Typography variant="body1"><FormattedMessage id="self_employed" /></Typography>
                        }

                        {form.startDate &&
                          <div className={classes.mb1}>
                            <Typography variant="caption">
                              <FormattedMessage id="start_date" />
                            </Typography>
                            <Typography variant="body1">
                              {DateUtil.formatUtcDate(form.startDate)}
                            </Typography>
                          </div>
                        }

                        {form.timeInLineOfWork &&
                          <div className={classes.mb1}>
                            <Typography variant="caption">
                              <FormattedMessage id="time_in_line_of_work" />
                            </Typography>
                            <YearsMonthsSummary years={form.timeInLineOfWork.years}
                                                months={form.timeInLineOfWork.months}
                                                variant="body1" />
                          </div>
                        }

                        {form.employedByPartyInTransaction &&
                        <div className={classes.mb1}>
                          <Typography variant="body1">
                            <FormattedMessage id="employed_by_party_in_transaction" />
                          </Typography>
                        </div>
                        }

                        {form.grossIncome &&
                          <div className={classes.mb1}>
                            <Grid container spacing={2}>
                              <Grid item>
                                <Item label={IntlFormatter.formatMessage(intl, 'base_income')}>
                                  <Typography variant="body1">
                                    {TextUtil.getMoneyText(form.grossIncome.base, intl)}
                                  </Typography>
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item label={IntlFormatter.formatMessage(intl, 'overtime')}>
                                  <Typography variant="body1">
                                    {TextUtil.getMoneyText(form.grossIncome.overtime, intl)}
                                  </Typography>
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item label={IntlFormatter.formatMessage(intl, 'bonus')}>
                                  <Typography variant="body1">
                                    {TextUtil.getMoneyText(form.grossIncome.bonus, intl)}
                                  </Typography>
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item label={IntlFormatter.formatMessage(intl, 'commission')}>
                                  <Typography variant="body1">
                                    {TextUtil.getMoneyText(form.grossIncome.commission, intl)}
                                  </Typography>
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item label={IntlFormatter.formatMessage(intl, 'military_entitlements')}>
                                  <Typography variant="body1">
                                    {TextUtil.getMoneyText(form.grossIncome.militaryEntitlements, intl)}
                                  </Typography>
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item label={IntlFormatter.formatMessage(intl, 'other')}>
                                  <Typography variant="body1">
                                    {TextUtil.getMoneyText(form.grossIncome.other, intl)}
                                  </Typography>
                                </Item>
                              </Grid>
                            </Grid>
                          </div>
                        }

                        {(form.ownership && form.ownership.owner) &&
                          <div>
                            {!TextUtil.isNullOrUndefined(form.ownership.ownershipOver25Percent) &&
                              <div className={classes.mb1}>
                                <Typography variant="body1">
                                  <FormattedMessage id={form.ownership.ownershipOver25Percent ? 'ownership_over_25_percent' : 'ownership_less_25_percent'} />
                                </Typography>
                              </div>
                            }
                            {form.ownership.monthlyIncome &&
                              <div className={clsx(classes.mb1, classes.ml2)}>
                                <Typography variant="caption">
                                  <FormattedMessage id="gross_monthly_income" />
                                </Typography>
                                <Typography variant="body1">
                                  {form.ownership.monthlyIncome.toMoney()}
                                </Typography>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className={classes.mb1}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="previous_employment" />
                </Typography>
              </div>

              {(!borrower.previousEmployment || (borrower.previousEmployment && borrower.previousEmployment.length === 0)) &&
              <Typography variant="body1">
                <FormattedMessage id="no_employment_info_found" />. &nbsp;
                {!loanApplication.completed &&
                <Link component={RouterLink} to="/apply/form">
                  <FormattedMessage id="add_employment"/>
                </Link>
                }
              </Typography>
              }

              {borrower.previousEmployment && borrower.previousEmployment.map((form, i) => {
                return (
                  <div key={i} className={classes.pv1}>
                    <Typography variant="subtitle2" gutterBottom>
                      {form.name || IntlFormatter.formatMessage(intl, 'na')}
                    </Typography>
                    <Typography variant="body1">{form.address ? LocationUtil.formatLocation(form.address) : IntlFormatter.formatMessage(intl, 'na')}</Typography>
                    {form.phoneNumber &&
                    <div>
                      <Typography variant="caption">
                        <FormattedMessage id="phone_number" />
                      </Typography>
                      <Typography variant="body1">{form.phoneNumber}</Typography>
                    </div>
                    }
                    {form.title &&
                    <div>
                      <Typography variant="caption">
                        <FormattedMessage id="title" />
                      </Typography>
                      <Typography variant="body1">{form.title}</Typography>
                    </div>
                    }

                    {form.selfEmployed &&
                    <Typography variant="body1"><FormattedMessage id="self_employed" /></Typography>
                    }

                    <div>
                      <Typography variant="caption">
                        <FormattedMessage id="monthly_income" />
                      </Typography>
                      <Typography variant="body1">
                        {TextUtil.getMoneyText(form.grossIncome, intl)}
                      </Typography>
                    </div>

                    {form.startDate && form.endDate &&
                      <div className={classes.mt1}>
                        <Typography variant="body1"><FormattedMessage id="employed_from_start_to_end" values={{ startDate: DateUtil.formatDate(form.startDate), endDate: DateUtil.formatDate(form.endDate) }} /></Typography>
                      </div>
                    }
                  </div>
                )
              })}
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return form;
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanEmploymentSummaryRev2));