import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {WebHookRequest, WebHookRequestLog} from "@jerseydev/orca-loans";

export function getWebHooks(queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getWebHooks(queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const webHooks = state.webHooks && state.webHooks.data ? _.cloneDeep(state.webHooks.data) : [];

      dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.FETCHING, webHooks));

      apiRequest.then(response => {
        dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.IDLE, webHooks));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getWebHook(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getWebHook(id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const webHooks = state.webHooks && state.webHooks.data ? _.cloneDeep(state.webHooks.data) : [];
      dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.FETCHING, _.cloneDeep(webHooks)));

      apiRequest.then(response => {
        const index = webHooks.findIndex(l => l._id === id);
        const webHook = _.cloneDeep(response.data);
        if(index === -1) {
          webHooks.push(webHook);
        } else {
          webHooks[index] = webHook;
        }
        dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.IDLE, webHooks));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.IDLE, webHooks));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getWebHookLogs(id:string, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getWebHookLogs(id, queryParams, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const webHookLogs = state.webHookLogs && state.webHookLogs.data ? _.cloneDeep(state.webHookLogs.data) : [];
      dispatch(setData(types.SET_WEBHOOK_LOGS, ReduxAsyncOperation.UPDATING, _.cloneDeep(webHookLogs)));

      apiRequest.then(response => {
        dispatch(setData(types.SET_WEBHOOK_LOGS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_WEBHOOK_LOGS, ReduxAsyncOperation.IDLE, webHookLogs));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function setWebHookLogs(data:WebHookRequestLog[]):ThunkAction<void, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch) => {
    dispatch(setData(types.SET_WEBHOOK_LOGS, ReduxAsyncOperation.IDLE, data));
  }
}

export function addWebHook(data:WebHookRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createWebHook(data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const webHooks = state.webHooks && state.webHooks.data ? _.cloneDeep(state.webHooks.data) : [];
      dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.ADDING, _.cloneDeep(webHooks)));

      apiRequest.then(response => {
        webHooks.push(response.data);
        dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.IDLE, webHooks));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.IDLE, webHooks));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateWebHook(id:string, data:WebHookRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    //delete data.account;
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateWebHook(id, data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const webHooks = state.webHooks && state.webHooks.data ? _.cloneDeep(state.webHooks.data) : [];
      dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.UPDATING, _.cloneDeep(webHooks)));

      apiRequest.then(response => {
        const index = webHooks.findIndex(l => l._id === id);
        if(index === -1) {
          webHooks.push(response.data);
        } else {
          webHooks[index] = response.data;
        }
        dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.IDLE, webHooks));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.IDLE, webHooks));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteWebHook(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteWebHook(id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const webHooks = state.webHooks && state.webHooks.data ? _.cloneDeep(state.webHooks.data) : [];
      dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.DELETING, _.cloneDeep(webHooks)));

      apiRequest.then(response => {
        const index = webHooks.findIndex(c => c._id === id);
        if(index > -1) {
          webHooks.splice(index, 1);
        }
        dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.IDLE, webHooks));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_WEBHOOKS, ReduxAsyncOperation.IDLE, webHooks));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}