import React from 'react';
import {FormattedMessage} from "react-intl";
import {Typography, withStyles} from "@material-ui/core";
import styles from "../../../theme/jss/components/calculatorResultsStyles";

type Props = {
  className?: string
}

class Disclaimer extends React.Component<Props> {
  render() {
    return (
      <div {...this.props}>
        <Typography variant="caption">
          <FormattedMessage id="calculator_disclaimer" />
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Disclaimer);
