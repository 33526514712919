import {defaultFont, drawerWidth} from "../index";
import palette from "../palette";
import {green, grey} from '@material-ui/core/colors';
import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  root: {
    display: 'flex',
    '& .MuiToolbar-regular': {
      [theme.breakpoints.up("md")]: {
        minHeight: 75,
      }
    }
  },
  appBar: {
    backgroundColor: palette.white,
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    color: palette.neutral.dark
  },
  userMenu: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: `inset 1px ${palette.neutral.light}`,
    paddingLeft: theme.spacing(3),
    cursor: 'pointer',
    color: palette.neutral.dark
  },
  userMenuAvatar: {
    ...defaultFont,
    marginRight: '10px',
    borderRadius: 5,
    border: `solid 1px ${palette.neutral.light}`
  },
  userMenuText: {
    ...defaultFont,
    fontWeight: 'bold'
  },
  userMenuSecondaryText: {
    ...defaultFont,
    color: theme.palette.neutral.mainLight
  },
  userMenuArrow: {
    marginLeft: '10px',
    fontSize: '18px',
    fontWeight: 'bold',
    color: palette.neutral.mainLight
  },
  userMenuTextContainer: {
    marginRight: theme.spacing(2)
  },
  userPopper: {
    marginTop: 5
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  itemsContainer: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  itemContainer: {
    marginRight: theme.spacing(2)
  },
  notificationsHeader: {
    padding: theme.spacing(2),
    width: 300
  },
  notificationsFooter: {
    padding: theme.spacing(1),
    backgroundColor: grey[100],
    textAlign: 'center'
  },
  notificationIcon: {
    color: theme.palette.black,
    textAlign: 'center'
  },
  notificationMessageAvatar: {
    backgroundColor: green[500]
  },
  notificationIconInfo: {
    color: theme.palette.info.main,
  },
  notificationIconWarn: {
    color: theme.palette.warning.main,
  },
  notificationIconError: {
    color: theme.palette.danger.main,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
});

export default styles;
