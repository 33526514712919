import React, {Component} from 'react';
import {AccountPage} from '../../../layouts';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {PageTitle} from "../../../components";
import {UserForm} from "../forms";
import {WithStyles, withStyles} from '@material-ui/core';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {Redirect, RouteComponentProps} from "react-router-dom";
import _ from 'lodash';
import styles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, SnackbarState} from "../../../types";
import {User} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
} & WrappedComponentProps
  & WithStyles<typeof styles>
  & RouteComponentProps<{id:string}>

type State = {
  pageLoaded: boolean,
  user: User|null,
  errorStatusCode?: number,
  redirectTo?: string,
  snackbar?: SnackbarState
}

class UserEditPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      user: null
    };
  }

  componentDidMount = async () => {
    try {
      const result = await this.props.routeProps.getUser(this.props.match.params.id).send();
      this.setState({ pageLoaded: true, user: _.cloneDeep(result.data) });
    } catch (e) {
      this.setState({ pageLoaded: true, errorStatusCode: e.response ? e.response.status : e.status });
    }
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'edit_user');
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="person" />
    )
  };

  onCancel = () => {
    this.setState({ redirectTo: '/admin/users' });
  };

  onSubmit = () => {
    const { intl } = this.props;
    this.setState({
      redirectTo: '/admin/users',
      snackbar: {
        open: true,
        variant: 'success',
        message: IntlFormatter.formatMessage(intl, 'user_updated')
      }
    });
  };

  render() {

    const { intl, classes, mixpanel } = this.props;
    const { pageLoaded, redirectTo, snackbar, user, errorStatusCode } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo, state: { snackbar }}}  />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: IntlFormatter.formatMessage(intl, 'users'), to: '/admin/users' },
                     {title: this.getPageTitle() }
                   ]}
                   errorStatusCode={errorStatusCode}
                   pageLoaded={pageLoaded}>
        <div className={classes.content}>
          {user &&
            <UserForm user={user}
                      mixpanel={mixpanel}
                      onCancel={this.onCancel}
                      onSubmit={this.onSubmit} />
          }
        </div>
      </AccountPage>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(UserEditPage));
