import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const styles = (theme:Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  message: {
    fontSize: '16px',
    color: theme.palette.neutral.main
  },
  icon: {
    fontSize: 30,
    color: theme.palette.neutral.main,
    marginRight: theme.spacing(1)
  }
});

const useStyles = makeStyles(styles);
export default styles;
export {useStyles}
