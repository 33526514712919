import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {AxiosResponse} from "axios";
import {
  LoanOfficer,
  LoanOfficerLosImportRequest,
  LoanOfficerRequest,
  LoanOfficerSearchRequest,
  LoanOfficerUpdateRequest,
  PictureRequest
} from "@jerseydev/orca-loans";

export function getLoanOfficers(queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getLoanOfficers(queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanOfficers = state.loanOfficers && state.loanOfficers.data ? _.cloneDeep(state.loanOfficers.data) : [];
      dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.FETCHING, loanOfficers));

      apiRequest.then(response => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getLoanOfficer(id:string, queryParams?:any):ThunkAction<ActionResponse<LoanOfficer>, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getLoanOfficer(id, queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanOfficers = state.loanOfficers && state.loanOfficers.data ? _.cloneDeep(state.loanOfficers.data) : [];
      dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.FETCHING, _.cloneDeep(loanOfficers)));

      apiRequest.then(response => {
        const index = loanOfficers.findIndex(l => l._id === id);
        if(index === -1) {
          loanOfficers.push(response.data);
        } else {
          loanOfficers[index] = response.data;
        }
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function searchLoanOfficers(criteria:LoanOfficerSearchRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.searchLoanOfficers(criteria, queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanOfficers = state.loanOfficers && state.loanOfficers.data ? _.cloneDeep(state.loanOfficers.data) : [];
      dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.FETCHING, loanOfficers));

      apiRequest.then(response => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addLoanOfficer(loanOfficer:LoanOfficerRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createLoanOfficer(loanOfficer, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanOfficers = state.loanOfficers && state.loanOfficers.data ? _.cloneDeep(state.loanOfficers.data) : [];
      dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.ADDING, _.cloneDeep(loanOfficers)));

      apiRequest.then(response => {
        loanOfficers.push(response.data);
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addLoanOfficers(data:LoanOfficerRequest[]):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createLoanOfficers(data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      let loanOfficers = state.loanOfficers && state.loanOfficers.data ? _.cloneDeep(state.loanOfficers.data) : [];
      dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.ADDING, _.cloneDeep(loanOfficers)));

      apiRequest.then(response => {
        loanOfficers = [...loanOfficers, ...response.data];
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function importLoanOfficerFromLOS(data:LoanOfficerLosImportRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.importLoanOfficersFromLos(data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanOfficers = state.loanOfficers && state.loanOfficers.data ? _.cloneDeep(state.loanOfficers.data) : [];
      dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.ADDING, _.cloneDeep(loanOfficers)));

      apiRequest.then(response => {
        loanOfficers.push(response.data as LoanOfficer);
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateLoanOfficer(id:string, loanOfficer:LoanOfficerUpdateRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateLoanOfficer(id, loanOfficer, queryParams, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanOfficers = state.loanOfficers && state.loanOfficers.data ? _.cloneDeep(state.loanOfficers.data) : [];
      dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanOfficers)));

      apiRequest.then(response => {
        const index = loanOfficers.findIndex(l => l._id === id);
        if(index === -1) {
          loanOfficers.push(response.data);
        } else {
          loanOfficers[index] = response.data;
        }
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteLoanOfficer(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteLoanOfficer(id, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const loanOfficers = state.loanOfficers && state.loanOfficers.data ? _.cloneDeep(state.loanOfficers.data) : [];
      dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.DELETING, _.cloneDeep(loanOfficers)));

      apiRequest.then(response => {
        const index = loanOfficers.findIndex(c => c._id === id);
        if(index > -1) {
          loanOfficers.splice(index, 1);
        }
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addLoanOfficerPicture(id:string, picture:PictureRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.addLoanOfficerPicture(id, picture, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanOfficers = state.loanOfficers && state.loanOfficers.data ? _.cloneDeep(state.loanOfficers.data) : [];
      dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanOfficers)));

      apiRequest.then(response => {
        const index = loanOfficers.findIndex(l => l._id === id);
        if(index > -1) {
          loanOfficers[index].picture = response.data;
        }
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function removeLoanOfficerPicture(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteLoanOfficerPicture(id, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanOfficers = state.loanOfficers && state.loanOfficers.data ? _.cloneDeep(state.loanOfficers.data) : [];

      dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanOfficers)));

      apiRequest.then((response) => {
        const index = loanOfficers.findIndex(l => l._id === id);
        if(index > -1) {
          loanOfficers[index].picture = null;
        }
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_OFFICERS, ReduxAsyncOperation.IDLE, loanOfficers));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getDefaultLoanOfficer(email:string, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getLoanOfficerByEmail(email, queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const loanOfficer = state.defaultLoanOfficer && state.defaultLoanOfficer.data ? _.cloneDeep(state.defaultLoanOfficer.data) : null;

      dispatch(setData(types.SET_DEFAULT_LOAN_OFFICER, ReduxAsyncOperation.FETCHING, loanOfficer));

      apiRequest.then(response => {
        dispatch(setData(types.SET_DEFAULT_LOAN_OFFICER, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_DEFAULT_LOAN_OFFICER, ReduxAsyncOperation.IDLE, null));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function setDefaultLoanOfficer(email:string|null):ThunkAction<void, ReduxState, any, AnyAction> {
  return (dispatch) => {
    dispatch({
      type: types.SET_DEFAULT_LOAN_OFFICER,
      payload: email
    });
  }
}