import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  experian: {
    backgroundColor: '#0D5DAB',
  },
  transunion: {
    backgroundColor: '#4D917B',
  },
  equifaxexperian: {
    backgroundColor: '#961E32',
  },
  scoreText: {
    fontSize: 18,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      textAlign: 'left',
    }
  },
  creditProvider: {
    textAlign: 'center'
  },
  creditResultContainer: {
    marginBottom: theme.spacing(2)
  }
});

export default styles;
