import React from 'react';
import {
  Grid,
  Typography,
  Link, withStyles, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {PairedList,  Well} from "../index";
import IntlFormatter from "../../intl";
import {Link as RouterLink} from "react-router-dom";
import {LocationUtil, TextUtil} from "../../utils";
import clsx from "clsx";
import styles from "../../theme/jss/layouts/pageStyles";
import {LoanApplication} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication
} & WithStyles<typeof styles>
  & WrappedComponentProps

const LoanPropertySummary = (props:Props) => {

  const {intl, classes, loanApplication} = props;

  let items = [];

  if(loanApplication.property) {
    items = [
      {key: IntlFormatter.formatMessage(intl, 'address'), value: loanApplication.property.address ? LocationUtil.formatLocation(loanApplication.property.address) : IntlFormatter.formatMessage(intl, 'na') },
      {key: IntlFormatter.formatMessage(intl, loanApplication.loan && loanApplication.loan.loanPurpose === 'refinance' ? 'property_value' : 'sale_price'), value: loanApplication.property.propertyValue ? TextUtil.getMoneyText(loanApplication.property.propertyValue, intl) : IntlFormatter.formatMessage(intl, 'na') },
      {key: IntlFormatter.formatMessage(intl, 'occupancy'), value: IntlFormatter.formatMessage(intl, loanApplication.property.occupancy ? `occupancy_types_${loanApplication.property.occupancy}` : 'na') },
      {key: IntlFormatter.formatMessage(intl, 'number_of_units'), value: TextUtil.getValueText(loanApplication.property.numberOfUnits, intl) },
      {key: IntlFormatter.formatMessage(intl, 'fha_secondary_residence'), value: TextUtil.getBoolText(loanApplication.property.fhaSecondaryResidence, intl) },
      {key: IntlFormatter.formatMessage(intl, 'mixed_use_property'), value: TextUtil.getBoolText(loanApplication.property.mixedUseProperty, intl) },
      {key: IntlFormatter.formatMessage(intl, 'manufactured_home'), value: TextUtil.getBoolText(loanApplication.property.manufacturedHome, intl) },
      {key: IntlFormatter.formatMessage(intl, 'monthly_rental_income'), value: loanApplication.property.monthlyRentalIncome ? TextUtil.getMoneyText(loanApplication.property.monthlyRentalIncome, intl) : IntlFormatter.formatMessage(intl, 'na') }
    ];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <PairedList items={items} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" gutterBottom>
            <FormattedMessage id="mortgages" />
          </Typography>
          <div>
            {(!loanApplication.property.mortgages || (loanApplication.property.mortgages && loanApplication.property.mortgages.length === 0)) &&
              <Typography variant="body1">
                <FormattedMessage id="none" />
              </Typography>
            }
            {(loanApplication.property.mortgages && loanApplication.property.mortgages.length > 0) &&
              <div>
                {loanApplication.property.mortgages.map((mortgage, i) => {
                  return (
                    <div key={i} className={clsx(classes.ph2, classes.pv1)}>
                      <Typography variant="h5">
                        {mortgage.creditor}
                      </Typography>
                      <Grid container spacing={2} justifyContent="space-between">

                        {mortgage.lienType &&
                        <Grid item>
                          <Typography variant="caption">
                            {IntlFormatter.formatMessage(intl, 'lien_type')}
                          </Typography>
                          <Typography variant="body1">
                            {IntlFormatter.formatMessage(intl, `lien_type_${mortgage.lienType}`)}
                          </Typography>
                        </Grid>
                        }

                        {mortgage.monthlyPayment &&
                        <Grid item>
                          <Typography variant="caption">
                            {IntlFormatter.formatMessage(intl, 'monthly_payment')}
                          </Typography>
                          <Typography variant="body1">
                            {mortgage.monthlyPayment.toMoney()}
                          </Typography>
                        </Grid>
                        }

                        {mortgage.loanAmount &&
                          <Grid item>
                            <Typography variant="caption">
                              {IntlFormatter.formatMessage(intl, 'loan_amount')}
                            </Typography>
                            <Typography variant="body1">
                              {mortgage.loanAmount.toMoney()}
                            </Typography>
                          </Grid>
                        }

                        {mortgage.creditLimit &&
                          <Grid item>
                            <Typography variant="caption">
                              {IntlFormatter.formatMessage(intl, 'credit_limit')}
                            </Typography>
                            <Typography variant="body1">
                              {mortgage.creditLimit.toMoney()}
                            </Typography>
                          </Grid>
                        }
                      </Grid>
                    </div>
                  )
                })}
              </div>
            }
          </div>
        </Grid>
      </Grid>
      )
  }

  return (
    <Well color="warn">
      <Typography variant="body1">
        <FormattedMessage id="no_property_info_found" />.&nbsp;
        {!loanApplication.completed &&
        <Link component={RouterLink} to="/apply/form">
          <FormattedMessage id="add_property_info"/>
        </Link>
        }
      </Typography>
    </Well>
  );
}

export default withStyles(styles)(injectIntl(LoanPropertySummary));