import axios from "axios";
import {Api as OrcaApi} from "@jerseydev/orca-loans";
import {BasicAuthCredentials} from "../types";
import TokenRefresher from "../utils/RefreshTokenUtil";

const securityWorker = (data:any) => {
  return { headers: data };
}

export const apiClient = new OrcaApi({
  baseURL: process.env.REACT_APP_API_HOST || 'https://api.orcaloans.com',
  securityWorker
});

export const setBearerSecurityWorker = (accessToken:string) => {
  const data = {
    Authorization: `Bearer ${accessToken}`
  };

  apiClient.setSecurityData(data);
};

export const setBasicSecurityWorker = () => {
  const auth:BasicAuthCredentials = {
    username: process.env.REACT_APP_ORCA_LOANS_CLIENT_ID || '',
    password: process.env.REACT_APP_ORCA_LOANS_CLIENT_SECRET || ''
  };
  let b = new Buffer(`${auth.username}:${auth.password}`);
  let base64data = b.toString('base64');
  apiClient.setSecurityData({ Authorization: `Basic ${base64data}` });
};

const tokenRefresher = new TokenRefresher(apiClient.instance, setBearerSecurityWorker);
apiClient.instance.interceptors.response.use(
  (res)  => res,
  tokenRefresher.responseErrorInterceptor
)

setBasicSecurityWorker();

export const createCancelSource = () => {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
}

export const transformRequestToFormData = (data:object) => {
  const formData = new FormData();
  for(let key in data) {
    if(data.hasOwnProperty(key)) {
      const value:any = data[key as keyof object];
      if(value) {
        formData.append(key, value);
      }
    }
  }
  return formData;
}

export default apiClient.v1;