import _ from 'lodash';
import IntlFormatter from "../intl";
import {IntlShape} from 'react-intl';
import {Borrower2009Address, BorrowerAddress} from "@jerseydev/orca-loans";

class TextUtil {
  static camelcaseToText = (text:string) => {
    return text.replace(/([A-Z])/g, ' $1').replace(/^./, function(text){ return text.toUpperCase(); })
  };

  static formatSSN = (ssn:string, mask:boolean = false) => {
    ssn = ssn.replace(/\D/g,'');
    let replacePat = mask ? '****$3' : '$1-$2-$3';
    return ssn.replace(/(\d{3})(\d{2})(\d{4})/, replacePat);
  };

  static maskText = (text:string, showLast:number = 4) => {
    return `****${text.substr(text.length - showLast, showLast)}`;
  };

  static truncate = (text:string, length:number) => {
    let truncatedText = _.clone(text);

    if(truncatedText.length > length) {
      truncatedText = truncatedText.slice(0, length);
      const textArray = truncatedText.split(' ');
      textArray.splice(textArray.length - 1, 1);
      truncatedText = textArray.join(' ');
    }

    return truncatedText;
  };

  static ucWords = (str:string) => {
    return (str + '').replace(/^(.)|\s+(.)/g, function ($1) {
        return $1.toUpperCase()
    });
  };

  static escapeRegEx = (string:string) => {
    return string.replace(/[*+?^${}()|[\]\\]/g, '');
  };

  static render = (text:string|null|undefined|number, defaultText:string) => {
    if(!text) {
      return defaultText;
    }
    if(typeof text === 'number') {
      return text.toString();
    }
    return text;
  };

  static isNullOrUndefined = (value:any) => {
    if(value === null || value === undefined) {
      return true;
    }

    return false;
  };

  static getAddressTypeText = (address:Borrower2009Address|BorrowerAddress, intl:IntlShape) => {

    let intlKey:string;
    if((address.current === null || address.current === undefined) && address.isMailingAddress) {
      intlKey = 'mailing_address';
    } else {
      if(address.current) {
        intlKey = address.isMailingAddress ? 'current_address_mailing_address' : 'current_address';
      } else {
        intlKey = address.isMailingAddress ? 'previous_address_mailing_address' : 'previous_address';
      }
    }

    return IntlFormatter.formatMessage(intl, intlKey);
  };

  static getValueText = (value:any, intl:IntlShape) => {
    return value !== null && value !== undefined ? value : IntlFormatter.formatMessage(intl, 'na');
  };

  static getBoolText = (value:boolean|undefined|null, intl:IntlShape) => {
    return value !== null && value !== undefined ? value ? IntlFormatter.formatMessage(intl, 'yes') : IntlFormatter.formatMessage(intl, 'no') : IntlFormatter.formatMessage(intl, 'na');
  };

  static getMoneyText = (value:number|undefined|null, intl:IntlShape) => {
    return value !== null && value !== undefined ? value.toMoney() : IntlFormatter.formatMessage(intl, 'na');
  };

  static isEmail = (email:string) => {
    return /\S+@\S+\.\S+/.test(email);
  };
}

export default TextUtil;