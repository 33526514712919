import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {
  InviteBorrowerRequest,
  UserRequest,
  UserSearchRequest,
  UserUpdateRequest
} from "@jerseydev/orca-loans";

export function getUsers(queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getUsers(queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const users = state.users && state.users.data ? _.cloneDeep(state.users.data) : [];

      dispatch(setData(types.SET_USERS, ReduxAsyncOperation.FETCHING, users));

      apiRequest.then(response => {
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getUser(id:string, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getUser(id, queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const users = state.users && state.users.data ? _.cloneDeep(state.users.data) : [];
      dispatch(setData(types.SET_USERS, ReduxAsyncOperation.FETCHING, users));

      apiRequest.then(response => {
        const index = users.findIndex(u => u._id === id);
        if(index === -1) {
          users.push(response.data);
        } else {
          users[index] = response.data;
        }
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function searchUsers(criteria:UserSearchRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.searchUsers(criteria, queryParams);
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const users = state.users && state.users.data ? _.cloneDeep(state.users.data) : [];

      dispatch(setData(types.SET_USERS, ReduxAsyncOperation.FETCHING, users));
      apiRequest.then(response => {
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}


export function addUser(user:UserRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createUser(user, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const users = state.users && state.users.data ? _.cloneDeep(state.users.data) : [];
      dispatch(setData(types.SET_USERS, ReduxAsyncOperation.ADDING, users));

      apiRequest.then(response => {
        users.push(response.data);
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateUser(id:string, user:UserUpdateRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {

    const cancelSource = createCancelSource();
    const apiRequest = Api.updateUser(id, user, queryParams, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const users = state.users && state.users.data ? _.cloneDeep(state.users.data) : [];
      dispatch(setData(types.SET_USERS, ReduxAsyncOperation.UPDATING, users));

      apiRequest.then(response => {
        const userIndex = users.findIndex(u => u._id === id);
        if(userIndex === -1) {
          users.push(response.data);
        } else {
          users[userIndex] = response.data;
        }
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));

        if(id === state.user!.data._id) {
          dispatch(setData(types.SET_USER, ReduxAsyncOperation.IDLE, response.data));
        }

        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteUser(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteUser(id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const users = state.users && state.users.data ? _.cloneDeep(state.users.data) : [];
      dispatch(setData(types.SET_USERS, ReduxAsyncOperation.DELETING, users));

      apiRequest.then(response => {
        const userIndex = users.findIndex(u => u._id === id);
        users.splice(userIndex, 1);
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function inviteApplicant(user:InviteBorrowerRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.inviteBorrower(user, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const users = state.users && state.users.data ? _.cloneDeep(state.users.data) : [];
      dispatch(setData(types.SET_USERS, ReduxAsyncOperation.ADDING, users));

      apiRequest.then(response => {
        users.push(response.data);
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_USERS, ReduxAsyncOperation.IDLE, users));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}