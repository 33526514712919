import React, {Component} from 'react';
import {Link, Redirect, RouteComponentProps} from 'react-router-dom';
import {Grid, Typography, Button, withStyles, WithStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../intl/index";
import {Page} from '../layouts';
import {AppLogo, ErrorList, UrlFooter} from "../components";
import pageStyles from "../theme/jss/layouts/pageStyles";
import clsx from "clsx";
import {ReduxUtil} from "../utils";
import {LoginForm} from "./account/forms";
import queryString from "query-string";
import {ReduxState} from "../data/initialState";
import {ActionProps, ErrorState, ReduxUser} from "../types";
import {Mixpanel} from "mixpanel-browser";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {authenticateOneTimeLogin} from "../actions/auth";

type Props = {
  mixpanel: Mixpanel,
  user: ReduxUser,
  authenticateOneTimeLogin: ActionProps["authenticateOneTimeLogin"]
} & RouteComponentProps
  & WithStyles<typeof pageStyles>
  & WrappedComponentProps

type State = {
  redirectTo?: string,
  loading: boolean,
  errors: ErrorState[]
}

class HomePage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      errors: []
    };
  }

  componentDidMount = async () => {
    const queryParams = queryString.parse(this.props.location.search);
    if(queryParams.otl) {
      try {
        await this.props.authenticateOneTimeLogin(queryParams.otl as string).send();
      } catch (e) {
        this.setState({
          errors: [
            { message: IntlFormatter.formatMessage(this.props.intl, 'invalid_token') }
          ]
        })
      }
    }

    const { user } = this.props;
    if(ReduxUtil.hasData(user)) {
      this.setState({ redirectTo: '/dashboard' });
    }

    this.props.mixpanel.track("Home Page");
  };

  onRegisterClick = () => {
    this.setState({ redirectTo: '/register' });
  };

  onLoginClick = () => {
    this.setState({ redirectTo: 'login' });
  };

  onLoginSubmit = () => {
    const { user, location, mixpanel } = this.props;
    mixpanel.identify(user.data._id);
    mixpanel.people.set({ "$name": user.data.fullName, "$email": user.data.email });
    mixpanel.track("Login");

    if(user.data.forcePasswordReset) {
      this.setState({ redirectTo: '/change-password' });
    } else {
      const { from }:any = location.state || { from: { pathname: '/dashboard' } };
      this.setState({ redirectTo: from });
    }
  };

  render() {

    const { intl, classes } = this.props;
    const { loading, redirectTo, errors } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo}/>
      )
    }

    return (
      <Page pageTitle={IntlFormatter.formatMessage(intl, 'welcome')}
            loading={loading}>
        <Grid container>
          <Grid item md={6}>
            <div className={classes.houseBg1}>
              <div className={classes.bgOverlay}>

              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className={classes.p4}>
              <div className={clsx(classes.mb3, classes.center)}>
                <AppLogo color="dark"/>
              </div>
              <div className={clsx(classes.mt3, classes.textCenter)}>
                <Typography variant="h2" color="inherit">
                  <FormattedMessage id="welcome" />
                </Typography>
              </div>

              <ErrorList errors={errors}
                         className={classes.p3}
                         onClose={() => this.setState({errors:[]})} />

              <div className={classes.p3}>
                <div className={classes.mb2}>
                  <LoginForm onSubmit={this.onLoginSubmit} />
                </div>
                <div className={clsx(classes.m3, classes.textCenter)}>
                  - <FormattedMessage id="or" /> -
                </div>
                <Grid container justifyContent="center">
                  <Grid item xs={12} sm={9} md={6}>
                    <Button variant="contained"
                            color="primary"
                            fullWidth={true}
                            component={Link}
                            to="/register">
                      <FormattedMessage id="sign_up"/>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
            <UrlFooter/>
          </Grid>
        </Grid>

      </Page>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  authenticateOneTimeLogin(token:string) {
    return dispatch(authenticateOneTimeLogin(token));
  }
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(HomePage)));
