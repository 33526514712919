import {defaultRadius} from '../index';
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    '& .MuiCardHeader-root': {
      paddingBottom: 0,
    }
  },
  previewContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  colorPreview: {
    width: 30,
    height: 30,
    borderRadius: defaultRadius,
    cursor: 'pointer',
    border: `solid 2px ${theme.palette.neutral.light}`,
    marginRight: theme.spacing(2)
  },
  colorPreviewSelected: {
    borderColor: theme.palette.black
  },
  popper: {
    marginLeft: 10,
    marginTop: -20,
    zIndex: 1000
  },
  actions: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
    '& .MuiButton-root': {
      display: 'inline-block'
    }
  }
});

export default styles;
