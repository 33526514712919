import React, {Component} from 'react';
import {InputAdornment, Icon, IconButton, Tooltip, TextFieldProps} from '@material-ui/core';
import {TextField} from './index';
import {injectIntl, WrappedComponentProps} from "react-intl";
import IntlFormatter from "../intl";

type DefaultProps = {
  variant: 'filled'|'outlined'|'standard'
}

type Props = {
  value:string,
} & Partial<DefaultProps>
  & WrappedComponentProps
  & TextFieldProps

class ClipboardTextInput extends Component<Props> {
  static defaultProps:DefaultProps = {
    variant: 'outlined'
  };

  textField:TextField|null;

  onCopyToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.value = this.props.value;
    textArea.setAttribute('class', 'clipboard-text');

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
  };

  render() {
    const {intl, ...rest} = this.props;

    return (
      <div>
        <TextField
                  // @ts-ignore
                  ref={textField => this.textField = textField}
                   {...rest}
                   InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={IntlFormatter.formatMessage(intl, 'copy_to_clipboard')}>
                          <IconButton onClick={this.onCopyToClipboard} data-clipboard-text={this.props.value}>
                            <Icon>assignment</Icon>
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }} />
      </div>
    );
  }
}

export default injectIntl(ClipboardTextInput);
