import {createStyles, Theme} from "@material-ui/core";
import pageStyles from "../layouts/pageStyles";

const styles = (theme:Theme) => createStyles({
  ...pageStyles(theme),
  messageBadges: {
    display: 'flex',
    alignItems: 'center',
  },
  messageBadge: {
    marginRight: theme.spacing(1)
  },
  composeContainer: {
    marginBottom: theme.spacing(2)
  },
  leftSidebar: {
    borderRight: `solid 1px ${theme.palette.neutral.shades![4]}`,
    paddingRight: theme.spacing(2)
  },
  searchFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    border: `solid 1px ${theme.palette.neutral.shades![4]}`,
    borderRadius: 4,
    padding: theme.spacing(1) / 2
  },
  messageRow: {
    cursor: 'pointer',
    transition: 'background-color 250ms linear',
    '&:hover': {
      backgroundColor: theme.palette.neutral.extraExtraLight
    }
  }
});

export default styles;
