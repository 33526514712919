import React from "react";
import IntlFormatter from "../intl";
import {Prompt, PromptProps} from "react-router-dom";
import {injectIntl, WrappedComponentProps} from "react-intl";

export type UnsavedChangesPromptProps = {
  message?: string
} & WrappedComponentProps
  & Omit<PromptProps,'message'>

const UnsavedChangesPrompt = (props:UnsavedChangesPromptProps) => {
  const {intl, message, ...rest} = props;
  return (
    <Prompt {...rest}
            message={message ? message : IntlFormatter.formatMessage(intl, 'unsaved_changes_message')} />
  )
};

export default injectIntl(UnsavedChangesPrompt)