import React from 'react';
import {
  Tooltip as MuiTooltip,
  TooltipProps,
  Zoom
} from '@material-ui/core';

type DefaultProps = {
  arrow: boolean,
  interactive: boolean
}

type Props = Partial<DefaultProps> & TooltipProps

const Tooltip = (props:Props) => {
  const { classes, children, ...rest } = props;
  return (
    <MuiTooltip TransitionComponent={Zoom}
                {...rest}>

      <div>{children}</div>
    </MuiTooltip>
  );
}

const defaultProps:DefaultProps = {
  arrow: true,
  interactive: true
}

Tooltip.defaultProps = defaultProps;

export default Tooltip;
