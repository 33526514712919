import React from 'react';
import {
  Button,
  Grid,
  Icon,
  Typography,
  withStyles,
  Hidden, WithStyles
} from '@material-ui/core';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {LoanApplicationPage} from "../layouts";
import {Link, Redirect, RouteComponentProps} from "react-router-dom";
import BaseLoanApplicationPage from "./BaseLoanApplicationPage";
import {AclUtil, LoanUtil, ReduxUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ColorButton, ErrorList, LoanDetails, Well} from "../../../components";
import config from "../../../config";
import {CreditAuthForm} from '../forms';
import {ReduxState} from "../../../data/initialState";
import {
  ActionProps,
  ErrorState,
  ReduxLoanApplication,
  ReduxLoanSettings,
  ReduxUser
} from "../../../types";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings,
  user: ReduxUser
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  loading: boolean,
  redirectTo?: string,
  errors: ErrorState[]
}

class ReviewPage extends BaseLoanApplicationPage<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      errors: []
    };
  }

  renderControls = () => {
    const { classes, loanApplication, settings } = this.props;
    const { loading } = this.state;

    const completedSteps = LoanUtil.getCompletedSteps(loanApplication.data);

    return (
      <div className={classes.p2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Hidden smUp>
              <Button component={Link}
                      to={this.previousMenuItem.to}
                      startIcon={<Icon>arrow_back</Icon>}>
                <FormattedMessage id="previous" />
              </Button>
            </Hidden>
            <Hidden xsDown>
              <Button variant="contained"
                      component={Link}
                      to={this.previousMenuItem.to}
                      startIcon={<Icon>arrow_back</Icon>}>
                <FormattedMessage id={this.previousMenuItem.labelId} />
              </Button>
            </Hidden>
          </Grid>
          <Grid item>
            {ReduxUtil.hasData(loanApplication) &&
              <div>
                <ColorButton type="submit"
                             color="success"
                             startIcon={<Icon>check_circle</Icon>}
                             disabled={!(completedSteps.length <= config.menus.loanApplicationMenu[settings.data.loanRevision].length) || loading}>
                  <FormattedMessage id="complete"/>
                </ColorButton>
              </div>
            }
          </Grid>
        </Grid>
      </div>
    )
  };

  onSubmitClick = () => {
    this.setState({loading: true});
  };

  onSubmit = () => {
    this.props.mixpanel.track("Loan application completed");
    const redirectTo = AclUtil.hasRole(this.props.user.data,'ROLE_MEMBER') ? '/dashboard?action=loanCompleted' : '/admin/loan-applications';
    this.setState({ redirectTo, loading: false });
    this.props.routeProps.clearLoanApplication();
  };

  render() {

    const { intl, classes, loanApplication } = this.props;
    const { loading, redirectTo, errors } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <LoanApplicationPage menuId="review"
                           loading={loading}>
        <div className={classes.content}>
          {ReduxUtil.hasData(loanApplication) &&
            <div>
              <LoanDetails loanApplication={loanApplication.data}
                           showMenu={false} />

              <div className={classes.mv2}>
                <Well color="primary">
                  <div className={classes.mb2}>
                    <Typography variant="body1"
                                dangerouslySetInnerHTML={{__html: IntlFormatter.formatMessage(intl, 'loan_application_acknowledgement_rev2')}} />
                  </div>
                </Well>
              </div>

              <ErrorList errors={errors} className={classes.mb2} />
              <CreditAuthForm loanApplication={loanApplication.data}
                              onSubmitClick={this.onSubmitClick}
                              onSubmit={this.onSubmit}>


                {this.renderControls()}
              </CreditAuthForm>
            </div>
          }

        </div>

      </LoanApplicationPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    user: state.user,
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(ReviewPage)));
