import {defaultRadius, hexToRgb} from "../index";
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    display: 'inline-block',
    borderRadius: defaultRadius,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 11,
    fontWeight: 'bold',
    padding: '2px 6px'
  },
  default: {
    backgroundColor: theme.palette.neutral.main,
    color: theme.palette.neutral.contrastText,
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText
  },
  infoAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.info.main) + ", .15)",
    color: theme.palette.info.main,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  primaryAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ", .15)",
    color: theme.palette.primary.main,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  secondaryAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.secondary.main) + ", .15)",
    color: theme.palette.secondary.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  successAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.success.main) + ", .15)",
    color: theme.palette.success.main,
  },
  warn: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  warnAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.warning.main) + ", .15)",
    color: theme.palette.warning.main,
  },
  danger: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrastText,
  },
  dangerAlt: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.danger.main) + ", .15)",
    color: theme.palette.danger.main,
  },
  yield: {
    color: theme.palette.yield.contrastText,
    backgroundColor: theme.palette.yield.main
  }
});

export default styles;
