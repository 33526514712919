import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    display: 'inline-flex',
    '&:hover': {
      '& $button': {
        opacity: 1
      }
    }
  },
  content: {
    marginRight: theme.spacing(1)
  },
  button: {
    opacity: 0,
    transition: '0.3s'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  popper: {
    zIndex: 100
  }
});

export default styles;
