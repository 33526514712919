import React, {Component} from 'react';
import {Switch, Route, Redirect, withRouter, RouteComponentProps} from 'react-router-dom';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ActionCreators} from "./actions";
import HomePage from './views/HomePage';
import {
  StartPage, LoanInfoPage, AddressPage, AssetsLiabilitiesPage, DeclarationsPage, IncomePage, GiftPage,
  PersonalPage, PropertyPage, RealEstatePage, ReviewPage, DemographicsPage, EmploymentPage, DetailPage
} from './views/loanApplication/pages';
import {DashboardPage} from './views/dashboard/pages';
import {
  RegisterPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  ChangePasswordPage,
  ProfilePage,
  LoginPage,
  SubscriptionPage,
  ExpiredSubscriptionPage,
  InvoicesPage,
  UpdateMobilePhonePage
} from './views/account/pages';
import {
  MessagesPage,
  MessageDetailPage
} from './views/messages/pages';
import {CalculatorsPage, MonthlyPaymentCalculatorPage, AffordabilityCalculatorPage, RefinanceCalculatorPage, IncomeToQualifyCalculatorPage, LoanComparisonCalculatorPage, PayMoreCalculatorPage} from './views/calculators/pages';
import {AppNotFoundPage, NotFoundPage, ErrorPage} from './views/errors/pages';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {ValidatorForm} from 'react-material-ui-form-validator';
import AdminLoanApplicationDetail from './views/loanApplications/pages/LoanApplicationDetailPage';
import {
  LoanApplicationsPage,
  LoanOfficersPage,
  LoanOfficerAddPage,
  LoanOfficerEditPage,
  LoanOfficerDetailPage,
  ImportLoanOfficersPage
} from './views/loanOfficers/pages';
import {BranchesPage, BranchAddPage, BranchEditPage, BranchDetailPage} from './views/branches/pages';
import {UsersPage, UserAddPage, UserEditPage, UserDetailPage} from './views/users/pages';
import {GeneralSettingsPage, LoanSettingsPage, EmailSettingsPage, IntegrationSettingsPage, ApiPage, WebHooksPage, WebHookDetailPage} from './views/settings/pages';
import {HelpPage} from './views/help/pages';
import {Loader} from './components';
import moment from 'moment';
import {SetupPage} from "./views/configuration/pages";
import IdleTimer from 'react-idle-timer';
import theme from "./theme";
import {MuiThemeProvider} from "@material-ui/core/styles";
import _ from 'lodash';
import {MixpanelConsumer} from 'react-mixpanel';
import {PlansPage} from "./views/plans/pages";
import PasswordValidator from 'password-validator';
import {ReduxState} from "./data/initialState";
import {ActionProps, ActionResponse, FixMeLater, ReduxApp, ReduxDesign} from "./types";
import {Location, ThemeColorSetting} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";
import {AxiosPromise} from "axios";

ValidatorForm.addValidationRule('isUrl', (value:string) => {
  if(value) {
    const exp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    const regex = new RegExp(exp);
    return !!value.match(regex);
  }
  return true;
});

ValidatorForm.addValidationRule('isBeforeDate', (value:string, date:string) => {
  const momentDate = moment(value);
  return momentDate.isBefore(moment(date));
});

ValidatorForm.addValidationRule('isAfterDate', (value:string, date:string) => {
  const momentDate = moment(value);
  return momentDate.isAfter(moment(date));
});

ValidatorForm.addValidationRule('isLessThan', (fieldValue:string|number, comparisonValue:string|number) => {
  let valid = true;
  const fieldValueNumber = typeof fieldValue === 'string' ? parseFloat(fieldValue) : fieldValue;
  const comparisonValueNumber = typeof comparisonValue === 'string' ? parseFloat(comparisonValue) : comparisonValue;
  if(!isNaN(fieldValueNumber) && !isNaN(comparisonValueNumber)) {
    valid = fieldValueNumber < comparisonValueNumber;
  }
  return valid;
});

ValidatorForm.addValidationRule('isGreaterThan', (fieldValue:string|number, comparisonValue:string|number) => {
  let valid = true;
  const fieldValueNumber = typeof fieldValue === 'string' ? parseFloat(fieldValue) : fieldValue;
  const comparisonValueNumber = typeof comparisonValue === 'string' ? parseFloat(comparisonValue) : comparisonValue;
  if(!isNaN(fieldValueNumber) && !isNaN(comparisonValueNumber)) {
    valid = fieldValueNumber > comparisonValueNumber;
  }
  return valid;
});

ValidatorForm.addValidationRule('isValidPassword', (value:string) => {
  const schema = new PasswordValidator();
  schema
    .is().min(6)
    .is().max(100)
    .has().uppercase()
    .has().lowercase()
    .has().symbols()
    .has().digits()
    .has().not().spaces()

  return !!schema.validate(value);
});

ValidatorForm.addValidationRule('isCompleteAddress', (value:Location) => {
  let valid = false;
  if(value && value.street1 && value.city && value.province && value.postalCode) {
    valid = true;
  }
  return valid;
});

ValidatorForm.addValidationRule('hasGoogleAddressCity', (value:any) => {
  return !!(!value || (value && value.city));
});

ValidatorForm.addValidationRule('hasGoogleAddressState', (value:any) => {
  return !!(!value || (value && value.province));
});

ValidatorForm.addValidationRule('isPasswordMatch', (password1:string, password2:string) => {
  return password1 === password2;
});

ValidatorForm.addValidationRule('oneOrMore', (value:string) => {
  return Array.isArray(value) && value.length > 0;
});

const PrivateRoute = ({ render: Component, ...rest }:PrivateProps) => (
  <Route {...rest} render={props => {
    let nextRoute;
    if(rest.routeProps.authenticated) {
      if(rest.routeProps.configured) {
        nextRoute = <Component {...props}/>;
      } else {
        // @ts-ignore
        nextRoute = <SetupPage {...props} />
      }
    } else {
      nextRoute = <Redirect to={{
        pathname: '/login',
        state: {from: `${props.location.pathname}${props.location.search}`}
      }}/>
    }

    return nextRoute;
  }}/>
);

type Props = {
  app: ReduxApp,
  design: ReduxDesign,
  authenticated: boolean,
  configured: boolean,
  getApp: ActionProps["getApp"],
  getDesign: ActionProps["getDesign"],
  clearLoanApplication: ActionProps["clearLoanApplication"],
  logout: ActionProps["logout"]
} & WrappedComponentProps
  & RouteComponentProps

type PrivateProps = {
  render: Function,
  routeProps: PrivateRouteProps,
  exact: boolean,
  path: string
}

type PrivateRouteProps = {
  configured: boolean,
  authenticated: boolean,
}

type State = {
  loading: boolean,
  error: any,
  redirectTo?: string
}

class App extends Component<Props, State> {
  idleTimer:FixMeLater;
  designAction?:ActionResponse;
  appAction?:ActionResponse;

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: true,
      error: null
    };
  }

  componentDidMount = async () => {
    try {
      this.appAction = this.props.getApp();
      this.designAction = this.props.getDesign();
      const requests:AxiosPromise[] = [
        this.appAction.send(),
        this.designAction.send()
      ];

      await Promise.all(requests);
      this.designAction = undefined;
      this.appAction = undefined;
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ error: e, loading: false });
    }
  };

  componentDidUpdate = (prevProps:Props) => {
    if(this.props.location !== prevProps.location) { // check if the route has changed
      if(prevProps.location.pathname.includes('/apply') && !this.props.location.pathname.includes('/apply')) {
        this.props.clearLoanApplication();
      }
    }
  };

  componentWillUnmount = () => {
    if(this.designAction) {
      this.designAction.cancel();
    }
  }

  onIdle = () => {
    if(this.props.authenticated) {
      //console.log('user inactivity timeout', this.idleTimer.getLastActiveTime());
      this.props.logout(true);
    }
  };

  getTheme = () => {
    const palette:FixMeLater = {};
    if(this.props.design && this.props.design.data && this.props.design.data.theme) {
      const colors = _.cloneDeep(this.props.design.data.theme.colors);

      for(let key in colors) {
        if(colors.hasOwnProperty(key) && colors[key as keyof ThemeColorSetting]) {
          palette[key] = _.clone(colors[key as keyof ThemeColorSetting]);
        }
      }
    }

    return theme.createTheme({ palette });
  };

  render() {
    const { authenticated, configured } = this.props;
    const { loading, error, redirectTo } = this.state;

    const privateRouteProps = {
      authenticated,
      configured
    };

    if (redirectTo) {
      return (
        <Redirect to={redirectTo}/>
      )
    }

    if(loading) {
      return <Loader visible={loading} />
    }

    if(error) {
      return (
        <MuiThemeProvider theme={this.getTheme()}>
          {(error.status === 404 || (error.response && error.response.status)) &&
            <AppNotFoundPage />
          }
          {error.status !== 404 &&
            <ErrorPage />
          }
        </MuiThemeProvider>
      )
    }

    return (
      <MuiThemeProvider theme={this.getTheme()}>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          onIdle={this.onIdle}
          debounce={250}
          timeout={1000 * 60 * 15} />
        <MixpanelConsumer>
          {(mixpanel:Mixpanel) => {
            return (
              <main>
                <Switch>
                  <Route exact path='/' render={props =>
                    <HomePage {...props} mixpanel={mixpanel}/>
                  }/>
                  {/* Auth routes */}
                  <Route path='/register' render={props =>
                    <RegisterPage {...props} mixpanel={mixpanel}/>
                  }/>
                  <Route path='/login' render={props =>
                    <LoginPage {...props} mixpanel={mixpanel}/>
                  }/>
                  <Route path='/forgot-password' render={props =>
                    <ForgotPasswordPage {...props} mixpanel={mixpanel}/>
                  }/>
                  <Route path='/reset-password' render={props =>
                    <ResetPasswordPage {...props} mixpanel={mixpanel}/>
                  }/>


                  {/* Account routes */}
                  <PrivateRoute exact path='/account/mobile-phone' routeProps={privateRouteProps} render={(props:any) =>
                    <UpdateMobilePhonePage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/help' routeProps={privateRouteProps} render={(props:any) =>
                    <HelpPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/dashboard' routeProps={privateRouteProps} render={(props:any) =>
                    <DashboardPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/change-password' routeProps={privateRouteProps} render={(props:any) =>
                    <ChangePasswordPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/account' routeProps={privateRouteProps} render={(props:any) =>
                    <ProfilePage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/messages' routeProps={privateRouteProps} render={(props:any) =>
                    <MessagesPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/messages/:id' routeProps={privateRouteProps} render={(props:any) =>
                    <MessageDetailPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/subscription' routeProps={privateRouteProps} render={(props:any) =>
                    <SubscriptionPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/plans' routeProps={privateRouteProps} render={(props:any) =>
                    <PlansPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  {/* Loan application routes */}
                  <PrivateRoute exact path='/apply' routeProps={privateRouteProps} render={(props:any) =>
                    <StartPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/apply/personal' routeProps={privateRouteProps} render={(props:any) =>
                    <PersonalPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/apply/address-history' routeProps={privateRouteProps} render={(props:any) =>
                    <AddressPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/apply/loan' routeProps={privateRouteProps} render={(props:any) =>
                    <LoanInfoPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/apply/property' routeProps={privateRouteProps} render={(props:any) =>
                    <PropertyPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/apply/employment' routeProps={privateRouteProps} render={(props:any) =>
                    <EmploymentPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/apply/income' routeProps={privateRouteProps} render={(props:any) =>
                    <IncomePage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/apply/assets-and-liabilities' routeProps={privateRouteProps} render={(props:any) =>
                    <AssetsLiabilitiesPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/apply/real-estate' routeProps={privateRouteProps} render={(props:any) =>
                    <RealEstatePage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/apply/declarations' routeProps={privateRouteProps} render={(props:any) =>
                    <DeclarationsPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/apply/demographics' routeProps={privateRouteProps} render={(props:any) =>
                    <DemographicsPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/apply/gifts' routeProps={privateRouteProps} render={(props:any) =>
                    <GiftPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/apply/review' routeProps={privateRouteProps} render={(props:any) =>
                    <ReviewPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/loan-applications/detail/:id' routeProps={privateRouteProps} render={(props:any) =>
                    <DetailPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  {/* Admin routes */}
                  <PrivateRoute exact path='/admin/loan-applications' routeProps={privateRouteProps} render={(props:any) =>
                    <LoanApplicationsPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/loan-applications/detail/:id' routeProps={privateRouteProps} render={(props:any) =>
                    <AdminLoanApplicationDetail {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/loan-officers' routeProps={privateRouteProps} render={(props:any) =>
                    <LoanOfficersPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/loan-officers/add' routeProps={privateRouteProps} render={(props:any) =>
                    <LoanOfficerAddPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/loan-officers/edit/:id' routeProps={privateRouteProps} render={(props:any) =>
                    <LoanOfficerEditPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/loan-officers/detail/:id' routeProps={privateRouteProps} render={(props:any) =>
                    <LoanOfficerDetailPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/loan-officers/import' routeProps={privateRouteProps} render={(props:any) =>
                    <ImportLoanOfficersPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/branches' routeProps={privateRouteProps} render={(props:any) =>
                    <BranchesPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/branches/add' routeProps={privateRouteProps} render={(props:any) =>
                    <BranchAddPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/branches/edit/:id' routeProps={privateRouteProps} render={(props:any) =>
                    <BranchEditPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/branches/detail/:id' routeProps={privateRouteProps} render={(props:any) =>
                    <BranchDetailPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  {/* User routes*/}
                  <PrivateRoute exact path='/admin/users' routeProps={privateRouteProps} render={(props:any) =>
                    <UsersPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/users' routeProps={privateRouteProps} render={(props:any) =>
                    <UsersPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/users/add' routeProps={privateRouteProps} render={(props:any) =>
                    <UserAddPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/users/edit/:id' routeProps={privateRouteProps} render={(props:any) =>
                    <UserEditPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>
                  <PrivateRoute exact path='/admin/users/detail/:id' routeProps={privateRouteProps} render={(props:any) =>
                    <UserDetailPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/admin/settings' routeProps={privateRouteProps} render={(props:any) =>
                    <GeneralSettingsPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/admin/settings/loan' routeProps={privateRouteProps} render={(props:any) =>
                    <LoanSettingsPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/admin/settings/email' routeProps={privateRouteProps} render={(props:any) =>
                    <EmailSettingsPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/admin/settings/integrations' routeProps={privateRouteProps} render={(props:any) =>
                    <IntegrationSettingsPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/admin/settings/api' routeProps={privateRouteProps} render={(props:any) =>
                    <ApiPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/admin/settings/webhooks' routeProps={privateRouteProps} render={(props:any) =>
                    <WebHooksPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/admin/settings/webhooks/:id' routeProps={privateRouteProps} render={(props:any) =>
                    <WebHookDetailPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/expired' routeProps={privateRouteProps} render={(props:any) =>
                    <ExpiredSubscriptionPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/invoices' routeProps={privateRouteProps} render={(props:any) =>
                    <InvoicesPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  {/* Mortgage calculator pages */}
                  <PrivateRoute exact path='/calculators' routeProps={privateRouteProps} render={(props:any) =>
                    <CalculatorsPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/calculators/monthly-payment' routeProps={privateRouteProps} render={(props:any) =>
                    <MonthlyPaymentCalculatorPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/calculators/affordability' routeProps={privateRouteProps} render={(props:any) =>
                    <AffordabilityCalculatorPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/calculators/refinance' routeProps={privateRouteProps} render={(props:any) =>
                    <RefinanceCalculatorPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/calculators/income-to-qualify' routeProps={privateRouteProps} render={(props:any) =>
                    <IncomeToQualifyCalculatorPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/calculators/loan-comparison' routeProps={privateRouteProps} render={(props:any) =>
                    <LoanComparisonCalculatorPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  <PrivateRoute exact path='/calculators/pay-more' routeProps={privateRouteProps} render={(props:any) =>
                    <PayMoreCalculatorPage {...props} mixpanel={mixpanel} routeProps={this.props}/>
                  }/>

                  {/* Error pages */}
                  <Route render={props =>
                    <NotFoundPage {...props} mixpanel={mixpanel} />
                  }/>
                </Switch>
              </main>

            )
          }}
        </MixpanelConsumer>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state:ReduxState) {
  return {
    app: state.app,
    authenticated: state.authenticated,
    configured: state.configured,
    design: state.design
  }
}

function mapDispatchToProps(dispatch:Dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(App)));
