import React, {Component} from 'react';
import {
  Card as MuiCard, Divider,
  Typography, Box,
  withStyles, Icon, WithStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/cardStyles';
import {Link as RouterLink} from "react-router-dom";
import clsx from "clsx";

type Props = {
  title: string,
  icon?: React.ReactNode,
  action?: React.ReactNode,
  link: {
    to: string,
    label: string
  }
} & WithStyles<typeof styles>

class Card extends Component<Props> {
  render() {
    const { classes, title, icon, children, action, link } = this.props;

    return (
      <MuiCard>
        <div className={clsx(classes.container, 'OlCard-content')}>
          {icon &&
            <div className={classes.icon}>
              {icon}
            </div>
          }
          <div>
            <div className={classes.title}>
              <Typography variant="h5">
                {title}
              </Typography>
            </div>
            <div className={classes.content}>
              {children}
            </div>
          </div>
        </div>
        {(action || link) &&
          <div>
            <Divider />
            <div className={classes.footer}>
              {action &&
                <div className={classes.action}>{action}</div>
              }

              {link &&
                <Box component={props => <RouterLink {...props} to={link.to} />} className={classes.link}>
                  <span>{link.label}</span>
                  <Icon>chevron_right</Icon>
                </Box>
              }
            </div>
          </div>
        }
      </MuiCard>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Card);
