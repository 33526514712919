import React, {Component} from 'react';
import {connect} from 'react-redux';
import Page from './Page';
import {Paper, WithStyles, withStyles} from '@material-ui/core';
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import styles from '../theme/jss/layouts/accountPageStyles';
import {Breadcrumbs, DrawerMenu, AccessError, OrcaFooter, PageLoader} from "../components";
import config from "../config";
import {RouteUtil} from "../utils";
import _ from 'lodash';
import {getUnreadSecureMessageCount} from "../actions/secureMessages";
import {
  ActionProps,
  Breadcrumb,
  ReduxAccount,
  ReduxApp,
  ReduxUnreadSecureMessageCount,
  ReduxUser,
  SnackbarState
} from "../types";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";

type DefaultProps = {
  pageLoaded: boolean,
  loading: boolean,
  hasAppBar: boolean,
  paper: boolean
}

type Props = {
  className?: string,
  pageTitle?: string,
  snackbar?: SnackbarState|null,
  classes?: string,
  titleBar?: any,
  errorStatusCode?: number|null,
  breadcrumbs?: Breadcrumb[],
  app: ReduxApp,
  user: ReduxUser,
  account: ReduxAccount,
  unreadSecureMessageCount: ReduxUnreadSecureMessageCount,
  getUnreadSecureMessageCount: ActionProps["getUnreadSecureMessageCount"],
  configured: boolean
} & Partial<DefaultProps>
  & RouteComponentProps<{}, any, {snackbar:SnackbarState}>
  & WithStyles<typeof styles>

type State = {
  loading: boolean,
  redirectTo: string|null
}

class AccountPage extends Component<Props, State> {
  static defaultProps:DefaultProps = {
    paper: true,
    pageLoaded: true,
    loading: false,
    hasAppBar: true
  };

  constructor(props:Props) {
    super(props);
    this.state = {
      loading: true,
      redirectTo: null
    };
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    const { user, location, account } = this.props;

    if(!RouteUtil.isGranted(user.data, location.pathname)) {
      this.setState({ redirectTo: '/dashboard' });
    }

    if(!account.data.active && location.pathname !== '/expired') {
      this.setState({ redirectTo: '/expired' });
    }

    if(!this.props.unreadSecureMessageCount || (this.props.unreadSecureMessageCount && Object.keys(this.props.unreadSecureMessageCount).length === 0)) {
      await this.props.getUnreadSecureMessageCount().send();
    }

    this.setState({ loading: false });
  };

  renderTitleBar = () => {
    const {titleBar, classes} = this.props;
    if(!titleBar) {
      return null;
    }

    return (
      <div className={classes.pageTitle}>
        {titleBar}
      </div>
    )
  };

  getAccountMenuItems = () => {
    let menuItems = config.menus.accountMenu;
    if(this.props.unreadSecureMessageCount && this.props.unreadSecureMessageCount.data > 0) {
      menuItems = _.cloneDeep(config.menus.accountMenu);
      const menuIndex = menuItems.findIndex(i => i.id === 'messages');
      menuItems[menuIndex].badge = this.props.unreadSecureMessageCount.data;
    }
    return menuItems;
  };

  render() {
    const { classes, children, breadcrumbs, pageTitle, titleBar, account, errorStatusCode, paper, pageLoaded, hasAppBar, configured, user, ...rest } = this.props;
    const { redirectTo } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo }}  />
      )
    }

    return (
      <Page pageTitle={pageTitle}
            drawerMenu={configured}
            hasAppBar={hasAppBar}
            pageLoaded={pageLoaded}
            {...rest}>
        {configured &&
          <DrawerMenu menuItems={this.getAccountMenuItems()} />
        }

        {(titleBar || breadcrumbs) &&
          <div className={classes.titleBar}>
            {breadcrumbs && breadcrumbs.length > 0 &&
            <div className={classes.breadcrumbs}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
            }

            {this.renderTitleBar()}
          </div>
        }

        <div className={classes.content}>

          {errorStatusCode &&
            <Paper>
              <div className={classes.p6}>
                <AccessError statusCode={errorStatusCode} />
              </div>
            </Paper>
          }
          {!errorStatusCode &&
            <div>
              {paper &&
                <Paper>
                  <PageLoader visible={!pageLoaded} className={classes.pageLoader} />
                  {pageLoaded && children}
                </Paper>
              }
              {!paper &&
                <div>
                  <PageLoader visible={!pageLoaded} className={classes.pageLoader} />
                  {pageLoaded && children}
                </div>
              }
            </div>
          }

        </div>
        <OrcaFooter />
      </Page>
    )
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    account: state.account,
    user: state.user,
    unreadSecureMessageCount: state.unreadSecureMessageCount,
    configured: state.configured
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getUnreadSecureMessageCount() {
    return dispatch(getUnreadSecureMessageCount());
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(AccountPage)));

