import React, {Component} from 'react';
import {
  CardContent,
  Card,
  withStyles,
  CardHeader,
  Divider,
  Typography,
  WithStyles
} from '@material-ui/core';
import IntlFormatter from "../../../intl";
import {Line, LinearComponentProps} from "react-chartjs-2";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import clsx from "clsx";
import styles from "../../../theme/jss/components/loanTrendChart";
import {FixMeLater} from "../../../types";
import themePalette from "../../../theme/jss/palette";

const palette:FixMeLater = themePalette;

export type LoanTrendChartProps = {
  data: LinearComponentProps["data"]
}

type Props = LoanTrendChartProps
  & WrappedComponentProps
  & WithStyles<typeof styles>

const options:LinearComponentProps["options"] = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};

class LoanTrendChart extends Component<Props> {
  render() {
    const { intl, classes, data } = this.props;

    return (
      <Card>
        <CardHeader title={IntlFormatter.formatMessage(intl, 'loans')}/>
        <Divider />
        <CardContent>
          <div className={clsx(classes.chartContainer, data.datasets.length === 0 ? classes.center : null)}>
            {data.datasets.length === 0 &&
              <Typography variant="subtitle2">
                <FormattedMessage id="no_data_to_display_chart" />
              </Typography>
            }
            {data.datasets.length > 0 &&
              <Line data={data} options={options}/>
            }
          </div>
        </CardContent>
        <Divider />
        {/*<CardActions className={classes.actions}>
          <Button
            color="primary"
            size="small"
            variant="text">
            Overview <Icon>arrow_right</Icon>
          </Button>
        </CardActions>*/}
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(LoanTrendChart));
