import * as types from '../actions/types';
import createReducer from '../lib/createReducer';
import {ReduxState} from "../data/initialState";
import {ReduxActionData} from "../types";

export const app = createReducer({}, {
  [types.SET_APP](state:ReduxState, action:ReduxActionData) {
    let newState = {};
    newState = action.payload;
    return newState;
  }
});

export const dashboard = createReducer({}, {
  [types.SET_DASHBOARD](state:ReduxState, action:ReduxActionData) {
    let newState = {};
    newState = action.payload;
    return newState;
  }
});

export const configured = createReducer(false, {
  [types.SET_CONFIGURED](state:ReduxState, action:ReduxActionData) {
    let newState = {};
    newState = action.payload;
    return newState;
  }
});