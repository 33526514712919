import React from 'react';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {LoanInfoForm} from "../forms";
import {LoanApplicationPage} from "../layouts";
import BaseLoanApplicationPage from "./BaseLoanApplicationPage";
import {LoanRev2Util, ReduxUtil} from "../../../utils";
import {WithStyles, withStyles} from "@material-ui/core";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {LoanApplicationControls} from "../components";
import {ActionProps, ReduxLoanApplication, ReduxLoanSettings} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";
import {UnsavedChangesPrompt} from "../../../components";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings
} & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  loading: boolean,
  redirectTo?: string,
  formPristine: boolean,
  isStepCompleted?: boolean
}

class LoanInfoPage extends BaseLoanApplicationPage<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      formPristine: true
    };
  }

  static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: Readonly<State>){
    if(nextProps.loanApplication && nextProps.loanApplication.data && prevState.isStepCompleted === undefined){
      return {isStepCompleted: LoanRev2Util.isLoanInfoCompleted(nextProps.loanApplication.data)};
    }
    return null;
  };

  componentWillUnmount = () => {
    const {mixpanel, loanApplication} = this.props;
    if(loanApplication.data && !this.state.isStepCompleted && LoanRev2Util.isLoanInfoCompleted(loanApplication.data)) {
      mixpanel.track("Loan application step completed", {step: 'loan_info'});
    }
  };

  onChange = () => {
    if(this.state.formPristine) {
      this.setState({ formPristine: false });
    }
  };

  onSubmitClick = () => {
    this.setState({loading:true});
  };

  onSubmit = () => {
    this.setState({ loading:false, formPristine: true, redirectTo: this.nextMenuItem.to });
  };

  render() {

    const { classes, loanApplication } = this.props;
    const { redirectTo, formPristine, loading } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <LoanApplicationPage menuId="loan_info">
        <UnsavedChangesPrompt when={!formPristine} />

        <div className={classes.content}>
          {ReduxUtil.hasData(loanApplication) &&
            <LoanInfoForm loanApplication={loanApplication.data}
                          onSubmitClick={this.onSubmitClick}
                          onSubmit={this.onSubmit}
                          onChange={this.onChange}>
              <div className={classes.pt2}>
                <LoanApplicationControls prev={this.previousMenuItem}
                                         next={{...this.nextMenuItem }}
                                         nextButtonProps={{ type: 'submit', loading }} />
              </div>
            </LoanInfoForm>
          }
        </div>
      </LoanApplicationPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(LoanInfoPage));
