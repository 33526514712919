import {WithStyles, withStyles} from "@material-ui/core";
import React from "react";
import {PairedList, TextMask} from "../index";
import {DateUtil, TextUtil} from "../../utils";
import IntlFormatter from "../../intl";
import styles from "../../theme/jss/layouts/pageStyles";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Borrower} from "@jerseydev/orca-loans";

type Props = {
  borrower: Borrower
} & WithStyles<typeof styles>
  & WrappedComponentProps

function BorrowerPersonalInfoSummary(props:Props) {
  const { intl, borrower } = props;

  const getBorrowerFullName = () => {
    let fullName = '';
    if(borrower.prefix) {
      fullName += `${IntlFormatter.formatMessage(props.intl, `prefixes_${borrower.prefix}`)} `;
    }

    fullName += borrower.firstName;

    if(borrower.middleName) {
      fullName += ` ${borrower.middleName}`;
    }

    fullName += ` ${borrower.lastName}`;

    if(borrower.suffix) {
      fullName += ` ${IntlFormatter.formatMessage(props.intl, `suffixes_${borrower.suffix}`)} `;
    }

    return fullName;
  };

  const getBorrowerDisplayListItems = () => {

    const borrowerName = getBorrowerFullName();

    const items = [
      {key: IntlFormatter.formatMessage(intl, 'name'), value: borrowerName},
      {key: IntlFormatter.formatMessage(intl, 'email'), value: TextUtil.render(borrower.email, IntlFormatter.formatMessage(intl, 'na'))},
      {key: IntlFormatter.formatMessage(intl, 'ssn'), value: borrower.ssn ? <TextMask>{TextUtil.formatSSN(borrower.ssn, false)}</TextMask> : IntlFormatter.formatMessage(intl, 'na')},
      {key: IntlFormatter.formatMessage(intl, 'birth_date'), value: borrower.birthDate ? DateUtil.formatUtcDate(borrower.birthDate) : IntlFormatter.formatMessage(intl, 'na')},
      {key: IntlFormatter.formatMessage(intl, 'phone_number'), value: borrower.phoneNumbers && borrower.phoneNumbers.length > 0 ? borrower.phoneNumbers[0].number : IntlFormatter.formatMessage(intl, 'na') },
      {key: IntlFormatter.formatMessage(intl, 'marital_status'), value: IntlFormatter.formatMessage(intl, borrower.maritalStatus ? `marital_statuses_${borrower.maritalStatus}` : 'na')},
      {key: IntlFormatter.formatMessage(intl, 'citizenship'), value: IntlFormatter.formatMessage(intl, borrower.citizenship ? `citizenship_type_${borrower.citizenship}` : 'na') },
      {key: IntlFormatter.formatMessage(intl, 'dependents'), value: IntlFormatter.formatMessage(intl, borrower.dependentAges && borrower.dependentAges.length === 0 ? 'no' : 'yes') },
    ];

    if(borrower.dependentAges && borrower.dependentAges.length > 0) {
      items.push({key: IntlFormatter.formatMessage(intl, 'dependent_ages'), value: borrower.dependentAges.join(', ')});
    }

    if(borrower.militaryService && typeof borrower.militaryService.served === 'boolean') {
      let value = IntlFormatter.formatMessage(intl, borrower.militaryService.served ? 'yes' : 'no');
      if(borrower.militaryService.types && borrower.militaryService.types.length > 0) {
        const values:string[] = [];
        borrower.militaryService.types.forEach(type => {
          values.push(IntlFormatter.formatMessage(intl, `military_service_${type}`));
        });
        value = values.join(', ');
      }
      items.push({key: IntlFormatter.formatMessage(intl, 'military_service'), value});
      if(borrower.militaryService.endDate) {
        items.push({key: IntlFormatter.formatMessage(intl, 'military_service_end_date'), value: DateUtil.formatUtcDate(borrower.militaryService.endDate)});
      }
    } else {
      items.push({key: IntlFormatter.formatMessage(intl, 'military_service'), value: IntlFormatter.formatMessage(intl, 'na')});
    }

    if(borrower.alternateNames && borrower.alternateNames.length > 0) {
      const altNames:string[] = [];
      borrower.alternateNames.forEach(altName => {
        let name = '';
        if(altName.firstName) {
          name += `${altName.firstName} `;
        }
        if(altName.middleName) {
          name += `${altName.middleName} `;
        }
        if(altName.lastName) {
          name += `${altName.lastName} `;
        }
        if(altName.suffix) {
          name += `${IntlFormatter.formatMessage(props.intl, `suffixes_${altName.suffix}`)}`;
        }
        altNames.push(name.trim());
      });

      items.push({key: IntlFormatter.formatMessage(intl, 'alternate_names'), value: altNames.join(', ')});
    }

    return items;
  };

  return (
    <PairedList items={getBorrowerDisplayListItems()} />
  );
}

export default withStyles(styles)(injectIntl(BorrowerPersonalInfoSummary));