import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {Grid, Button, withStyles, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, PasswordField} from "../../../components/index";
import _ from 'lodash';
import {RouteComponentProps, withRouter} from "react-router-dom";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ErrorUtil} from "../../../utils";
import {changePassword} from "../../../actions/auth";
import {connect} from "react-redux";
import {ActionResponse, ErrorState} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  onSubmit?: () => void,
  onCancel?: () => void,
  changePassword: (oldPassword:string, newPassword:string) => ActionResponse
} & WithStyles<typeof pageStyles>
  & RouteComponentProps<{}, any, {oldPassword?:string|null}>
  & WrappedComponentProps

type Form = {
  currentPassword: string,
  password: string,
  passwordConfirm: string
}

type State = {
  loading: boolean,
  form: Form,
  currentPasswordFieldVisible: boolean,
  errors: ErrorState[]
}

class ChangePasswordForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        currentPassword: '',
        password: '',
        passwordConfirm: ''
      },
      currentPasswordFieldVisible: true,
      errors: []
    };
  }

  componentDidMount = () => {
    if(this.props.location.state && this.props.location.state.oldPassword) {
      const form = _.clone(this.state.form);
      form.currentPassword = this.props.location.state.oldPassword;
      this.props.location.state.oldPassword = null;
      this.setState({ form, currentPasswordFieldVisible: false });
    }
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const { currentPassword, password } = _.clone(this.state.form);
      await this.props.changePassword(currentPassword, password).send();
      const {mixpanel} = this.props;
      mixpanel.track("Changed password");
      this.setState({ loading: false }, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit();
        }
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes } = this.props;
    const { form, loading, errors, currentPasswordFieldVisible } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } } />

          {currentPasswordFieldVisible &&
            <div className={classes.mb2}>
              <PasswordField name="currentPassword"
                             label={IntlFormatter.formatMessage(intl, 'current_password')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.currentPassword')}
                             value={form.currentPassword}
                             fullWidth={true}
                             validators={['required']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}
              />
            </div>
          }
          <div className={classes.mb2}>
            <PasswordField name="password"
                           label={IntlFormatter.formatMessage(intl, 'password')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.password')}
                           value={form.password}
                           fullWidth={true}
                           validators={['required', 'isValidPassword']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required'),
                             IntlFormatter.formatMessage(intl, 'validation_password_invalid'),
                           ]}
            />
          </div>
          <div>
            <PasswordField name="passwordConfirm"
                           label={IntlFormatter.formatMessage(intl, 'confirm_password')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.passwordConfirm')}
                           value={form.passwordConfirm}
                           fullWidth={true}
                           validators={['required', `isPasswordMatch:${form.password}`]}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required'),
                             IntlFormatter.formatMessage(intl, 'validation_password_dont_match')
                           ]}
            />
          </div>
          <div className={classes.mt3}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save"/>
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  changePassword(oldPassword:string, newPassword:string) {
    return dispatch(changePassword(oldPassword, newPassword))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(pageStyles, { withTheme: true })(injectIntl(ChangePasswordForm))));
