import React, {RefObject, MouseEvent, ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {Grid, Button, withStyles, Icon, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {
  ErrorList,
  MoneyInput, PercentageTextField,
  Select,
  SubmitButton,
} from "../../../components/index";
import {CalculatorUtil, ErrorUtil} from '../../../utils';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import Api from '../../../lib/Api';
import {ChartComponentProps, Doughnut} from "react-chartjs-2";
import CalculatorResults, {CalculatorResultsProps} from "../components/CalculatorResults";
import {ErrorState, FixMeLater, LabelNumberType} from "../../../types";
import themePalette from "../../../theme/jss/palette";
import {PayMoreCalculatorRequest, PayMoreCalculatorResponse} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

const palette:FixMeLater = themePalette;

type Props = {
  mixpanel: Mixpanel,
  onSubmit?: (data:PayMoreCalculatorResponse) => void,
  onCancel?: () => void
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  loanAmount: string,
  interestRate: string,
  term: string,
  additionalPayment: string
}

type State = {
  loading: boolean,
  form: Form,
  result?: CalculatorResultsProps|null,
  errors: ErrorState[],
}

class PayMoreForm extends BaseForm<Props, State> {
  terms:LabelNumberType[] = [];
  resultsRef:RefObject<HTMLDivElement>;

  chartColors:string[] = [
    palette.warning.main,
    palette.success.main,
  ];

  chartOptions = CalculatorUtil.getPieChartOptions();

  constructor(props:Props) {
    super(props);

    this.resultsRef = React.createRef();

    /*const testFormData = {
      loanAmount: 250000,
      interestRate: 5.875,
      term: 30,
      additionalPayment: 50
    };*/

    this.state = {
      loading: false,
      form: {
        loanAmount: '',
        interestRate: '',
        term: '',
        additionalPayment: ''
      },
      result: null,
      errors: [],
    };

    //this.state.form = testFormData;

    this.terms = CalculatorUtil.getSelectLoanTerms(props.intl)
  }

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    const {intl} = this.props;

    try {
      this.setState({ loading: true, result: null, errors: [] });
      const {loanAmount, interestRate, term, additionalPayment} = this.state.form;
      const requestData:PayMoreCalculatorRequest = {
        loanAmount: parseInt(loanAmount),
        interestRate: parseFloat(interestRate),
        term: parseInt(term),
        additionalPayment: parseInt(additionalPayment)
      };
      const result = await Api.calculatePayMore(requestData);
      this.setState({
        loading: false,
        result: {
          summary: result.data.description,
          results: [
            {
              title: IntlFormatter.formatMessage(intl, 'additional_payment'),
              items: [
                ...this.getResultItems(result.data.additional),
                { label: IntlFormatter.formatMessage(intl, 'time_saved'), value: IntlFormatter.formatMessage(intl, 'num_years_months', { years: result.data.timeSaved.years, months: result.data.timeSaved.months }) },
                { label: IntlFormatter.formatMessage(intl, 'interest_saved'), value: result.data.interestSavings.toMoney() }
              ],
              amortization: result.data.standard.amortization,
              chart: <Doughnut data={this.getChartData(result.data)} options={this.chartOptions} redraw />
            },
            {
              title: IntlFormatter.formatMessage(intl, 'regular_payment'),
              items: this.getResultItems(result.data.standard),
              amortization: result.data.standard.amortization,
              chart: null
            }
          ]
        }
      });

      this.resultsRef.current!.scrollIntoView({behavior:'smooth'});
      this.props.mixpanel.track("Mortgage Calculator", { name: "Pay More", action: "submitted" });
      if(this.props.onSubmit) {
        this.props.onSubmit(result.data);
      }
    } catch(e) {
      this.onError(e);
    }
  };

  getResultItems = (data:PayMoreCalculatorResponse["standard"]|PayMoreCalculatorResponse["additional"]) => {
    const {intl} = this.props;

    let termValue;
    if(data.term.years <= 0) {
      termValue = IntlFormatter.formatMessage(intl, 'num_months', { months: data.term.months });
    } else if(data.term.years > 0 && data.term.months <= 0) {
      termValue = IntlFormatter.formatMessage(intl, 'num_years', { years: data.term.years });
    } else {
      termValue = IntlFormatter.formatMessage(intl, 'num_years_months', { years: data.term.years, months: data.term.months });
    }

    return [
      { label: IntlFormatter.formatMessage(intl, 'monthly_payment'), value: data.monthlyPayment.toMoney() },
      { label: IntlFormatter.formatMessage(intl, 'total_payments'), value: data.totalPayments.toMoney() },
      { label: IntlFormatter.formatMessage(intl, 'term'), value: termValue }
    ];
  };

  getChartData = (resultData:PayMoreCalculatorResponse):ChartComponentProps["data"] => {
    const { intl } = this.props;

    return {
      datasets: [{
        data: [
          resultData.standard.totalPayments.toFixed(2),
          (resultData.standard.totalPayments - resultData.additional.totalPayments).toFixed(2),
        ],
        backgroundColor: this.chartColors
      }],
      labels: [
        IntlFormatter.formatMessage(intl, 'payment'),
        IntlFormatter.formatMessage(intl, 'savings'),
      ]
    };
  };

  onError = (err:any) => {
    this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
  };

  render() {

    const { intl, classes, onCancel } = this.props;
    const { form, loading, errors, result } = this.state;
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] })}} />
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'loan_amount')}
                            value={form.loanAmount}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loanAmount')}
                            validators={['required', 'minNumber:1']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 1 })
                            ]} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Select name="term"
                        options={this.terms}
                        placeholder={IntlFormatter.formatMessage(intl, 'select_term')}
                        onChange={(item:LabelNumberType) => this.onItemChange(item ? item.value : '', 'form.term')}
                        value={form.term !== '' ? this.terms.find(m => m.value === parseInt(form.term)) : ''}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required')
                        ]}
                        label={IntlFormatter.formatMessage(intl, 'term')} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <PercentageTextField name="interestRate"
                                     label={IntlFormatter.formatMessage(intl, 'interest_rate')}
                                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.interestRate')} value={form.interestRate} fullWidth={true}
                                     validators={['required', 'minFloat:0', 'maxFloat:100']}
                                     errorMessages={[
                                       IntlFormatter.formatMessage(intl, 'validation_required'),
                                       IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                       IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                     ]} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'additional_payment')}
                            value={form.additionalPayment}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.additionalPayment')}
                            validators={['required', 'minNumber:1']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 1 })
                            ]} />
              </Grid>
            </Grid>
          </div>

          <div className={classes.mt3}>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              {onCancel &&
              <Grid item>
                <Button onClick={onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}
                              size="large"
                              startIcon={<Icon>calculate</Icon>}>
                  <FormattedMessage id={result ? 'recalculate' : 'calculate'} />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>

        <div ref={this.resultsRef}>
          {result &&
            <div className={classes.mt3}>
              <CalculatorResults summary={result.summary}
                                 results={result.results} />

            </div>
          }
        </div>
      </div>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(PayMoreForm));
