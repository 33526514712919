import React, {ChangeEvent} from 'react';
import _ from 'lodash';

class BaseForm<P, S> extends React.Component<P, S> {

  onTextChange = (event:ChangeEvent<{ value: unknown }>, key:string, callback?:() => void|undefined) => {
    const state:any = _.cloneDeep(this.state);
    if(key.includes('.')) {
      _.set(state, key, event.target.value);
    } else {
      state[key] = event.target.value;
    }

    if(state.errors) {
      state.errors = [];
    }

    this.setState(state, callback);
  };

  onSelectChange = (event:ChangeEvent<{ value: unknown }>, key:string, callback?:() => void|undefined) => {
    const state:any = _.cloneDeep(this.state);
    if(key.includes('.')) {
      _.set(state, key, event.target.value);
    } else {
      state[key] = event.target.value;
    }

    if(state.errors) {
      state.errors = [];
    }

    this.setState(state, callback);
  };

  onRadioChange = (event:ChangeEvent<{ value: unknown }>, key:string, callback?:() => void|undefined) => {
    const state:any = _.cloneDeep(this.state);
    if(key.includes('.')) {
      _.set(state, key, event.target.value);
    } else {
      state[key] = event.target.value;
    }

    if(state.errors) {
      state.errors = [];
    }

    this.setState(state, callback);
  };

  onCheckboxChanged = (key:string, callback?:() => void|undefined) => {
    const state:any = _.cloneDeep(this.state);
    const prevValue = _.get(state, key);
    if(key.includes('.')) {
      _.set(state, key, !prevValue);
    } else {
      state[key] = !state[key];
    }

    if(state.errors) {
      state.errors = [];
    }

    this.setState(state, callback);
  };

  onDateChange = (date:string, key:string, callback?:() => void|undefined) => {
    const state:any = _.cloneDeep(this.state);
    if(key.includes('.')) {
      _.set(state, key, date);
    } else {
      state[key] = date;
    }

    if(state.errors) {
      state.errors = [];
    }

    this.setState(state, callback);
  };

  onItemChange = (item:any, key:string, callback?:() => void|undefined) => {
    const state:any = _.cloneDeep(this.state);
    if(key.includes('.')) {
      _.set(state, key, item);
    } else {
      state[key] = item;
    }

    if(state.errors) {
      state.errors = [];
    }

    this.setState(state, callback);
  };
}

export default BaseForm;
