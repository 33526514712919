import React, {ChangeEvent} from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router-dom';
import {Form} from '../../../forms';
import {Grid, withStyles, WithStyles, Button, Checkbox, FormControlLabel} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, PhoneNumberInput} from "../../../components/index";
import {connect} from "react-redux";
import _ from 'lodash';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionResponse, ErrorState, ReduxUser} from "../../../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {AxiosResponse} from "axios";
import {ReduxState} from "../../../data/initialState";
import {UserProfileRequest} from "@jerseydev/orca-loans";
import {ErrorUtil, PhoneNumberUtil} from "../../../utils";
import {addDontPromptMobilePhone, removeDontPromptMobilePhone, updateProfile} from "../../../actions/auth";

type Props = {
  onSubmit?: (data:AxiosResponse) => void,
  onSkip?: () => void,
  user: ReduxUser,
  updateProfile: (data:UserProfileRequest) => ActionResponse,
  dontPromptForMobileNumber: string[]|null,
  addDontPromptMobilePhone: (userId:string) => void,
  removeDontPromptMobilePhone: (userId:string) => void,
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps
  & RouteComponentProps<{}, any, {from:string}>

type Form = {
  mobilePhoneNumber: string,
  dontShowAgain: boolean
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  redirectTo?: string
}

class UpdateMobilePhoneForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        mobilePhoneNumber: props.user.data.mobilePhoneNumber ? _.clone(props.user.data.mobilePhoneNumber) : '',
        dontShowAgain: !!(props.dontPromptForMobileNumber && props.dontPromptForMobileNumber.includes(props.user.data._id))
      },
      errors: [] 
    };
  }

  onDontShowAgainChange = () => {
    const form = _.clone(this.state.form);
    form.dontShowAgain = !this.state.form.dontShowAgain;
    this.setState({form});
    const {user} = this.props;
    if(form.dontShowAgain) {
      this.props.addDontPromptMobilePhone(user.data._id);
      const {location} = this.props;
      const redirectTo = location.state && location.state.from ? location.state.from : '/dashboard'
      this.setState({ redirectTo });
    } else {
      this.props.removeDontPromptMobilePhone(user.data._id);
    }
  };

  onSubmit = async () => {
    try {
      this.setState({ loading: true, errors: [] });
      const {mobilePhoneNumber} = this.state.form;
      const data:UserProfileRequest = {
        mobilePhoneNumber: PhoneNumberUtil.formatPhoneNumber(mobilePhoneNumber),
      };
      const result = await this.props.updateProfile(data).send();
      this.setState({ loading: false }, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({loading:false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, classes, onSkip } = this.props;
    const { loading, form, errors, redirectTo } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo}/>
    }

    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />
        <div className={classes.mv2}>
          <PhoneNumberInput name="mobilePhoneNumber"
                            label={IntlFormatter.formatMessage(intl, 'mobile_phone')}
                            value={form.mobilePhoneNumber}
                            fullWidth={true}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.mobilePhoneNumber')}
                            validators={['required']}
                            errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
        </div>

        <div className={classes.mt2}>
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item>
              <FormControlLabel label={IntlFormatter.formatMessage(intl, 'dont_show_again')}
                                control={
                                  <Checkbox checked={form.dontShowAgain}
                                            onChange={this.onDontShowAgainChange} />
                                } />
            </Grid>
            <Grid item>
              <div className={classes.row}>
                {onSkip &&
                  <Button onClick={onSkip} className={classes.mr2}>
                    <FormattedMessage id="skip" />
                  </Button>
                }
                <SubmitButton loading={loading}>
                  <FormattedMessage id="submit" />
                </SubmitButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    dontPromptForMobileNumber: state.dontPromptForMobileNumber
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateProfile(data:UserProfileRequest) {
    return dispatch(updateProfile(data))
  },
  addDontPromptMobilePhone(id:string) {
    return dispatch(addDontPromptMobilePhone(id));
  },
  removeDontPromptMobilePhone(id:string) {
    return dispatch(removeDontPromptMobilePhone(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(withRouter(injectIntl(UpdateMobilePhoneForm))));
