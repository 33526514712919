import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  listItem: {
    '& .MuiListItemText-primary': {
      fontSize: 16,
      fontWeight: 'bold'
    },
    '& .MuiListItemText-secondary': {
      fontSize: 14,
      color: theme.palette.neutral.main
    }
  },
  noResults: {

  }
});

export default styles;
