import React, {Component} from 'react';
import config from "../config";
import {WithStyles, withStyles} from '@material-ui/core';
import styles from "../theme/jss/components/appLogoStyles";
import {connect} from "react-redux";
import {ReduxUtil} from "../utils";
import {ReduxAccount, ReduxDesign} from "../types";
import {ReduxState} from "../data/initialState";

type DefaultProps = {
  size: 'sm'|'md'|'lg',
  color: 'dark'|'light'
}

type Props = {
  account: ReduxAccount,
  design: ReduxDesign
} & Partial<DefaultProps>
  & WithStyles<typeof styles>

class AppLogo extends Component<Props> {
  static defaultProps:DefaultProps = {
    size: 'md',
    color: 'dark'
  };

  getImgUrl = () => {
    const { size, color, design } = this.props;
    let url = `https://storage.googleapis.com/orcaloans/assets/img/orca-logo-${size}-${color}.png`;

    if(design.data) {
      if(color === 'light' && design.data.lightLogo && design.data.lightLogo.url) {
        url = design.data.lightLogo.url;
      } else if(design.data.logo && design.data.logo.url) {
        url = design.data.logo.url;
      }
    }

    return url;
  };

  renderLogoImage = () => {
    const { account, design } = this.props;
    const img = <img src={this.getImgUrl()} alt={ReduxUtil.hasData(account) && account.data.name ? account.data.name : config.app.name} />

    if(design.data && design.data.websiteUrl) {
      return (
        <a href={design.data.websiteUrl} target="_blank" rel="noopener noreferrer">
          {img}
        </a>
      )
    }

    return img;
  };

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.logoContainer}>
          {this.renderLogoImage()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    account: state.account,
    design: state.design
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(AppLogo));
