import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';

import {
  Grid,
  IconButton,
  Icon,
  Button,
  Typography,
  withStyles, Link, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {
  ErrorList,
  PhoneNumberInput,
  SubmitButton,
  Dropzone,
  TextField,
  AutoComplete,
  InlineCard,
  Dialog,
  Select,
  Fab,
  Section,
  DeleteDialog,
  UnsavedChangesPrompt
} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {addBranch, updateBranch, addBranchPicture, removeBranchPicture} from "../../../actions/branches";
import {AclUtil, ErrorUtil, ObjectUtil, PhoneNumberUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import Api from "../../../lib/Api";
import {getRoles} from "../../../actions/roles";
import {BranchManagerForm} from "./index";
import {AddressFormFields} from "../../../forms";
import {AxiosResponse} from "axios";
import {
  Branch,
  BranchCreateRequest,
  BranchUpdateRequest,
  PictureRequest,
  User,
  Location, UserLean, Role, ManagedFile
} from "@jerseydev/orca-loans";
import {
  ActionResponse,
  DialogState,
  LabelValuePairType, PhoneNumberState,
  ReduxApp,
  ReduxRoles,
  ReduxUser,
  ErrorState, FileWithPreview
} from "../../../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  branch?: Branch|null,
  submitLabel?: string,
  onSubmit?: (data:AxiosResponse<Branch>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  user: ReduxUser,
  roles: ReduxRoles,
  addBranch: (data:BranchCreateRequest) => ActionResponse,
  updateBranch: (id:string, data:BranchUpdateRequest) => ActionResponse,
  getRoles: () => ActionResponse,
  addBranchPicture: (id:string, picture:PictureRequest) => ActionResponse,
  removeBranchPicture: (id:string) => ActionResponse
} & WithStyles<typeof pageStyles>
  & WrappedComponentProps

type Form = {
  name: string,
  email: string,
  description: string,
  phoneNumbers: PhoneNumberState[],
  address?: Location|null,
  manager?: UserLean|null,
  nmlsId: string,
  picture?: FileWithPreview|ManagedFile|null
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  managerDialogOpen: boolean,
  managerDialogLoading: boolean,
  pictureDeleteDialog: DialogState,
  formPristine: boolean,
  branchManagerRole: Role|null
}

class BranchForm extends BaseForm<Props, State> {
  phoneNumberTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);


    let form:Form = {
      name: '',
      email: '',
      description: '',
      phoneNumbers: [],
      address: null,
      manager: null,
      nmlsId: ''
    };

    if(props.branch) {
      const {name, email, description, phoneNumbers, address, manager, nmlsId, picture} = props.branch;

      form = {
        name,
        email,
        description: description ? description : '',
        phoneNumbers,
        address,
        manager,
        nmlsId,
        picture
      }
    }

    this.state = {
      loading: true,
      form,
      errors: [],
      managerDialogOpen: false,
      managerDialogLoading: false,
      pictureDeleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      formPristine: true,
      branchManagerRole: null
    };

    props.app.data.enums.phoneNumberTypes.forEach(type => {
      this.phoneNumberTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `phone_number_types_${type}`) })
    });
  }

  componentDidMount = async () => {
    let branchManagerRole:Role|null|undefined;
    if(this.props.roles && this.props.roles.data && this.props.roles.data.length > 0) {
      branchManagerRole = this.props.roles.data.find(r => r.key === 'ROLE_BRANCH_MANAGER');
    } else {
      const roleResult = await this.props.getRoles().send();
      branchManagerRole = roleResult.data.find((r:Role) => r.key === 'ROLE_BRANCH_MANAGER');
    }

    if(branchManagerRole) {
      this.setState({ branchManagerRole });
    }

    this.setState({ loading: false });
  };

  componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>) => {
    if(this.state.formPristine && !ObjectUtil.isEqual(prevState.form, this.state.form)) {
      this.setState({formPristine:false});
    }
  }

  addPhoneNumber = (event:MouseEvent) => {
    event.preventDefault();
    const form = _.cloneDeep(this.state.form);
    form.phoneNumbers.push({ type: null, number: '', ext: '' });
    this.setState({ form });
  };

  removePhoneNumber = (index:number) => {
    const form = _.cloneDeep(this.state.form);
    form.phoneNumbers.splice(index, 1);
    this.setState({ form });
  };

  setPhoneNumberType = (index:number, type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.phoneNumbers[index].type = type.value;

    this.setState({ form });
  };

  searchUsers = async (searchText:string) => {
    const {branchManagerRole} = this.state;
    const result = await Api.searchUsers({ searchText, roles: [branchManagerRole!._id] });
    // @ts-ignore
    return result.data.filter((u:User) => !u.additionalProps || (u.additionalProps && (!u.additionalProps.branch || (this.props.branch && u.additionalProps.branch && u.additionalProps.branch._id === this.props.branch._id))));
  };

  onAddManagerClick = () => {
    this.setState({ managerDialogOpen: true });
  };

  onAddManagerCancel = () => {
    this.setState({ managerDialogOpen: false });
  };

  onAddManagerSubmit = (result:AxiosResponse<User>) => {
    const form = _.cloneDeep(this.state.form);
    form.manager = _.cloneDeep(result.data) as UserLean;
    this.setState({ form, managerDialogOpen: false });
  };

  setManager = (user:User) => {
    const form = _.cloneDeep(this.state.form);
    form.manager = _.cloneDeep(user) as UserLean;
    this.setState({ form });
  };

  onAddPicture = (file:File) => {
    const form = _.cloneDeep(this.state.form);
    form.picture = file;

    this.setState({ form });
  };

  onRemovePicture = () => {
    const pictureDeleteDialog = _.clone(this.state.pictureDeleteDialog);
    pictureDeleteDialog.open = true;
    this.setState({ pictureDeleteDialog });
  };

  onAddressChange = (address:Location) => {
    const form = _.cloneDeep(this.state.form);
    form.address = address;
    this.setState({ form });
  };

  onSubmit = async (event:MouseEvent) => {
    try {
      event.preventDefault();
      this.setState({ loading: true, errors: [] });
      let branchResult:AxiosResponse;

      const {name, email, nmlsId, description, phoneNumbers, address, manager, picture} = this.state.form;
      if(this.props.branch && this.props.branch._id) {
        const data:BranchUpdateRequest = {
          name,
          email,
          nmlsId,
          description: description !== '' ? description : null,
          phoneNumbers: PhoneNumberUtil.stateToUpdateRequest(phoneNumbers),
          address,
          manager: manager ? manager._id : null
        }
        branchResult = await this.props.updateBranch(this.props.branch._id, data).send();
        this.props.mixpanel.track("Branch updated");
      } else {
        const data:BranchCreateRequest = {
          name,
          email,
          nmlsId,
          description: description !== '' ? description : null,
          phoneNumbers: PhoneNumberUtil.stateToCreateRequest(phoneNumbers),
          address,
          manager: manager ? manager._id : null
        }
        branchResult = await this.props.addBranch(data).send();
        this.props.mixpanel.track("Branch added");
      }

      if(picture instanceof File) {
        const pictureResult = await this.props.addBranchPicture(branchResult.data._id, {picture, width: 800, resizeMode: "max"}).send();
        branchResult.data.picture = pictureResult.data;
      }

      this.setState({ loading: false }, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(_.cloneDeep(branchResult));
        }
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onDeletePictureCancel = () => {
    const pictureDeleteDialog = {
      open: false,
      loading: false,
      errors: []
    };
    this.setState({pictureDeleteDialog});
  };

  onDeletePictureConfirm = async () => {
    let pictureDeleteDialog = _.clone(this.state.pictureDeleteDialog);
    pictureDeleteDialog.loading = true;
    this.setState({pictureDeleteDialog});

    try {
      if(this.props.branch) {
        await this.props.removeBranchPicture(this.props.branch._id);
      }
      pictureDeleteDialog = {
        open: false,
        loading: false,
        errors: []
      };
      let form = _.cloneDeep(this.state.form);
      form.picture = null;
      this.setState({pictureDeleteDialog, form});
    } catch (e) {
      pictureDeleteDialog.loading = false;
      pictureDeleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({pictureDeleteDialog});
    }
  };

  render() {

    const { intl, classes, branch, submitLabel, user, mixpanel } = this.props;
    const { form, loading, errors, managerDialogOpen, pictureDeleteDialog, formPristine } = this.state;
    const isOwner = AclUtil.isOwner(user.data);


    return (
      <div>
        <UnsavedChangesPrompt when={!formPristine} />
        {branch &&
          <Dialog open={managerDialogOpen}
                  icon={<Icon>person_add</Icon>}
                  title={IntlFormatter.formatMessage(intl, 'add_branch_manager')}
                  color="primaryAlt"
                  onClose={this.onAddManagerCancel}
                  fullWidth={true}
                  maxWidth="sm">
            <BranchManagerForm branch={branch}
                               mixpanel={mixpanel}
                               onSubmit={this.onAddManagerSubmit}
                               onCancel={this.onAddManagerCancel} />
          </Dialog>
        }

        <DeleteDialog open={pictureDeleteDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'delete_picture')}
                      item={IntlFormatter.formatMessage(intl, 'names_picture', {name: form.name })}
                      confirmationMessage={IntlFormatter.formatMessage(intl, 'are_you_sure_delete_name_picture', {name: form.name })}
                      loading={pictureDeleteDialog.loading}
                      errors={pictureDeleteDialog.errors}
                      onCancel={this.onDeletePictureCancel}
                      onSubmit={this.onDeletePictureConfirm} />

        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <Form onSubmit={this.onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name="name"
                               label={IntlFormatter.formatMessage(intl, 'name')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.name')}
                               value={form.name}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name="email"
                               label={IntlFormatter.formatMessage(intl, 'email')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.email')}
                               value={form.email}
                               fullWidth={true}
                               inputProps={{
                                 autoCapitalize: 'none',
                               }}
                               validators={['required', 'isEmail']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name="nmlsId"
                               label={IntlFormatter.formatMessage(intl, 'nmls_id')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.nmlsId')}
                               value={form.nmlsId}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={isOwner && branch ? 10 : 12}>
                        <AutoComplete value={form.manager}
                                      getOptionLabel={(item:User) => {
                                        return `${item.firstName} ${item.lastName}`;
                                      }}
                                      onChange={this.setManager}
                                      onTextChange={this.searchUsers}
                                      fullWidth={true}
                                      label={IntlFormatter.formatMessage(intl, 'branch_manager')}
                                      disabled={!isOwner || loading}
                                      getOptionSelected={(option:User, value:User) => {
                                        return option._id === value._id;
                                      }}
                                      placeholder={IntlFormatter.formatMessage(intl, 'search')}
                                      openOnFocus={true}
                                      debounce={500} />
                      </Grid>
                      {(isOwner && branch) &&
                        <Grid item>
                          <IconButton onClick={this.onAddManagerClick}>
                            <Icon>add_circle</Icon>
                          </IconButton>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField name="description"
                               label={IntlFormatter.formatMessage(intl, 'description')}
                               value={form.description}
                               multiline={true}
                               rows={3}
                               fullWidth={true}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.description')} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.mt2}>
                <AddressFormFields address={form.address}
                                   onChange={this.onAddressChange}
                                   required={true} />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <aside className={classes.rightSidebar}>
                <Section className={classes.mb4} title={IntlFormatter.formatMessage(intl, 'phone_numbers')} actions={
                  <Fab onClick={this.addPhoneNumber} color="primary" size="small" rounded flat>
                    <Icon>add</Icon>
                  </Fab>
                }>
                  <div>
                    {form.phoneNumbers.length === 0 &&
                    <Typography variant="body1">
                      <Link href="#" onClick={this.addPhoneNumber}><FormattedMessage id="add_phone_number" /></Link>
                    </Typography>
                    }

                    {form.phoneNumbers.map((phoneNumber, i) => {
                      return (
                        <div key={i} className={classes.mb2}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Select name={`phoneNumberType[${i}]`}
                                      options={this.phoneNumberTypes}
                                      onChange={(data:LabelValuePairType) => this.setPhoneNumberType(i, data)}
                                      value={phoneNumber.type ? this.phoneNumberTypes.find(t => t.value === phoneNumber.type) : ''}
                                      label={IntlFormatter.formatMessage(intl, 'type')}
                                      validators={['required']}
                                      errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                            </Grid>
                            <Grid item xs={4}>
                              <PhoneNumberInput name={`phoneNumbers${i}`}
                                                label={IntlFormatter.formatMessage(intl, 'number')}
                                                value={form.phoneNumbers[i].number}
                                                fullWidth={true}
                                                onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.phoneNumbers[${i}].number`)}
                                                validators={['required']}
                                                errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField name={`phoneNumbers[${i}].ext`}
                                         label={IntlFormatter.formatMessage(intl, 'ext')}
                                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.phoneNumbers[${i}].ext`)}
                                         value={form.phoneNumbers[i].ext}
                                         fullWidth={true} />
                            </Grid>
                            <Grid item>
                              <IconButton onClick={() => this.removePhoneNumber(i)} size="small">
                                <Icon>cancel</Icon>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    })}
                  </div>
                </Section>
                <div>
                  {form.picture && "url" in form.picture &&
                  <InlineCard title={IntlFormatter.formatMessage(intl, 'photo')}
                              action={
                                <IconButton onClick={this.onRemovePicture} size="small">
                                  <Icon>cancel</Icon>
                                </IconButton>
                              }>
                    <img src={(form.picture as ManagedFile).url} alt={form.name} className={classes.thumbnail} />
                  </InlineCard>
                  }
                  {(!form.picture || (form.picture && !("_id" in form.picture))) &&
                  <div className={classes.mv2}>
                    <Dropzone label={IntlFormatter.formatMessage(intl, 'branch_photo')}
                              accept="image/jpeg, image/png"
                              imageDimensions={{ width: 150, height: 150 }}
                              onAdd={this.onAddPicture}
                              onRemove={this.onRemovePicture} />
                  </div>
                  }
                </div>
              </aside>
            </Grid>
          </Grid>

          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
                <Grid item>
                  <Button onClick={this.props.onCancel}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  {submitLabel}
                  {!submitLabel &&
                    <FormattedMessage id="save" />
                  }
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    user: state.user,
    roles: state.roles
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addBranch(data:BranchCreateRequest) {
    return dispatch(addBranch(data));
  },
  updateBranch(id:string, data:BranchUpdateRequest) {
    return dispatch(updateBranch(id, data));
  },
  getRoles() {
    return dispatch(getRoles());
  },
  addBranchPicture(id:string, picture:PictureRequest) {
    return dispatch(addBranchPicture(id, picture));
  },
  removeBranchPicture(id:string) {
    return dispatch(removeBranchPicture(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(BranchForm)));
