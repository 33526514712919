import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {
  BranchCreateRequest,
  BranchSearchRequest,
  BranchUpdateRequest,
  PictureRequest
} from "@jerseydev/orca-loans";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";

export function getBranches(queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getBranches(queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const branches = state.branches && state.branches.data ? _.cloneDeep(state.branches.data) : [];
      dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.FETCHING, branches));
      apiRequest.then(response => {
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getBranch(id:string, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getBranch(id, queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const branches = state.branches && state.branches.data ? _.cloneDeep(state.branches.data) : [];
      dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.FETCHING, _.cloneDeep(branches)));

      apiRequest.then(response => {
        const index = branches.findIndex(l => l._id === id);
        if(index === -1) {
          branches.push(response.data);
        } else {
          branches[index] = response.data;
        }
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function searchBranches(criteria:BranchSearchRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.searchBranches(criteria, queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const branches = state.branches && state.branches.data ? _.cloneDeep(state.branches.data) : [];
      dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.FETCHING, branches));

      apiRequest.then(response => {
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addBranch(branch:BranchCreateRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createBranch(branch, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const branches = state.branches && state.branches.data ? _.cloneDeep(state.branches.data) : [];
      dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.ADDING, _.cloneDeep(branches)));

      apiRequest.then(response => {
        branches.push(response.data);
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addBranches(branches:BranchCreateRequest[]):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createBranches(branches, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      let branches = state.branches && state.branches.data ? _.cloneDeep(state.branches.data) : [];
      dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.ADDING, _.cloneDeep(branches)));

      apiRequest.then(response => {
        branches = [...branches, ...response.data];
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateBranch(id:string, branch:BranchUpdateRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateBranch(id, branch, queryParams, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const branches = state.branches && state.branches.data ? _.cloneDeep(state.branches.data) : [];
      dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.UPDATING, _.cloneDeep(branches)));

      apiRequest.then(response => {
        const index = branches.findIndex(l => l._id === id);
        if(index === -1) {
          branches.push(response.data);
        } else {
          branches[index] = response.data;
        }
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteBranch(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteBranch(id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const branches = state.branches && state.branches.data ? _.cloneDeep(state.branches.data) : [];
      dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.DELETING, _.cloneDeep(branches)));

      apiRequest.then(response => {
        const index = branches.findIndex(c => c._id === id);
        if(index > -1) {
          branches.splice(index, 1);
        }
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addBranchPicture(id:string, picture:PictureRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.addBranchPicture(id, picture, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const branches = state.branches && state.branches.data ? _.cloneDeep(state.branches.data) : [];

      dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.UPDATING, _.cloneDeep(branches)));

      apiRequest.then(response => {
        const index = branches.findIndex(l => l._id === id);
        if(index > -1) {
          branches[index].picture = response.data;
        }
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function removeBranchPicture(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteBranchPicture(id, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const branches = state.branches && state.branches.data ? _.cloneDeep(state.branches.data) : [];

      dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.UPDATING, _.cloneDeep(branches)));

      apiRequest.then((response) => {
        const index = branches.findIndex(l => l._id === id);
        if(index > -1) {
          branches[index].picture = null;
        }
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_BRANCHES, ReduxAsyncOperation.IDLE, branches));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}