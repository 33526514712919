import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from 'lodash';
import config from "../config";
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {
  LoanApplicationDocumentUpdateRequest,
  LoanApplicationDocumentRequest,
  LoanApplicationPriceUpdateRequest,
  LoanApplicationPriceRequest,
  Borrower2009,
  Borrower,
  LoanApplicationRequest,
  LoanApplicationUpdateRequest,
  PlaidReportRequest, LoanApplication, LoanApplication2009
} from "@jerseydev/orca-loans";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";

export function getLoanApplication(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getLoanApplication(id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : {};
      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.FETCHING, loanApplication));

      apiRequest.then(response => {
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addLoanApplication(data:LoanApplicationRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    //const preparedLoanApplication =  prepareLoanApplication(data);
    data.source = config.app.key;
    const cancelSource = createCancelSource();
    const apiRequest = Api.createLoanApplication(data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : {};

      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.ADDING, loanApplication));

      apiRequest.then(response => {
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateLoanApplication(id:string, data:LoanApplicationUpdateRequest, queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    //const preparedLoanApplication =  prepareLoanApplication(data);
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateLoanApplication(id, data, queryParams ? queryParams : {merge:'replace'}, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : {};

      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, loanApplication));

      apiRequest.then(response => {
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function sendLoanApplicationToLos(id:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.sendLoanApplicationToLos(id, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      const loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : {};

      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, loanApplication));

      apiRequest.then(response => {
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addLoanApplicationDocument(id:string, data:LoanApplicationDocumentRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createLoanDocument(id, data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : null;
      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanApplication)));
      apiRequest.then(response => {
        if(loanApplication) {
          if(!loanApplication.documents) {
            loanApplication.documents = [];
          }
          loanApplication.documents.push(response.data);
        }
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateLoanApplicationDocument(loanId:string, documentId:string, data:LoanApplicationDocumentUpdateRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    //data = prepareDocument(data);
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateLoanDocument(loanId, documentId, data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      let loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : null;

      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanApplication)));

      apiRequest.then(response => {
        if(loanApplication) {
          if(!loanApplication.documents) {
            loanApplication.documents = [];
          }
          const index = loanApplication.documents.findIndex(d => d._id === documentId);
          loanApplication.documents[index] = response.data;
        }
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteLoanApplicationDocument(loanId:string, documentId:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteLoanDocument(loanId, documentId, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : null;
      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanApplication)));

      apiRequest.then(response => {
        if(loanApplication && loanApplication.documents) {
          const index = loanApplication.documents.findIndex(d => d._id === documentId);
          loanApplication.documents.splice(index, 1);
        }
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function setLoanApplication(data:LoanApplication|LoanApplication2009):ThunkAction<void, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch) => {
    dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, data));
  }
}

export function clearLoanApplication():ThunkAction<void, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch) => {
    dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, null));
  }
}

export function addLoanApplicationPricing(loanId:string, data:LoanApplicationPriceRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createLoanApplicationPrice(loanId, data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      let loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : null;

      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanApplication)));

      apiRequest.then(response => {
        if(loanApplication) {
          if(!loanApplication.pricing) {
            loanApplication.pricing = [];
          }
          loanApplication.pricing.unshift(response.data);
        }
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateLoanApplicationPricing(loanId:string, pricingId:string, data:LoanApplicationPriceUpdateRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateLoanApplicationPrice(loanId, pricingId, data, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      let loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : null;
      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanApplication)));

      apiRequest.then(response => {
        if(loanApplication) {
          if (!loanApplication.pricing) {
            loanApplication.pricing = [];
          }

          const pricingIndex = loanApplication.pricing.findIndex(p => p._id === pricingId);
          if(pricingIndex > -1) {
            loanApplication.pricing[pricingIndex] = response.data;
          } else {
            loanApplication.pricing.push(response.data);
          }
        }

        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}


export function deleteLoanApplicationPricing(loanId:string, pricingId:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteLoanApplicationPrice(loanId, pricingId, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      let loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : null;

      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanApplication)));

      apiRequest.then(response => {
        if(loanApplication && loanApplication.pricing) {
          const pricingIndex = loanApplication.pricing.findIndex(p => p._id === pricingId);
          if(pricingIndex > -1) {
            loanApplication.pricing.splice(pricingIndex, 1);
          }
        }

        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addLoanApplicationAssetReport(loanId:string, borrowerId:string, data:PlaidReportRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.createAssetReport(loanId, borrowerId, data, {cancelToken:cancelSource.token});

    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      let loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : null;
      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanApplication)));

      apiRequest.then(response => {
        if(loanApplication &&  loanApplication.borrowers) {
          const borrowerIndex = loanApplication.borrowers.findIndex((b:Borrower2009|Borrower) => b._id === borrowerId);
          if(borrowerIndex > -1) {
            if(!loanApplication.borrowers[borrowerIndex]!.assetReports) {
              loanApplication.borrowers[borrowerIndex]!.assetReports = [];
            }
            loanApplication.borrowers![borrowerIndex].assetReports!.unshift(response.data);
          }
        }
        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateLoanApplicationAssetReport(loanId:string, borrowerId:string, reportId:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateAssetReport(loanId, borrowerId, reportId, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      let loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : null;
      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanApplication)));

      apiRequest.then(response => {
        if(loanApplication && loanApplication.borrowers) {
          const borrowerIndex = loanApplication.borrowers.findIndex((b:Borrower2009|Borrower) => b._id === borrowerId);
          if(borrowerIndex > -1) {
            if(!loanApplication.borrowers[borrowerIndex]!.assetReports) {
              loanApplication.borrowers[borrowerIndex]!.assetReports = [];
            }
            const index = loanApplication.borrowers![borrowerIndex].assetReports!.findIndex(r => r._id === reportId);
            if(index > -1) {
              loanApplication.borrowers![borrowerIndex].assetReports![index] = response.data;
            }
          }
        }

        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function refreshLoanApplicationAssetReport(loanId:string, borrowerId:string, reportId:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.refreshAssetReport(loanId, borrowerId, reportId, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();

      let loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : null;
      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanApplication)));

      apiRequest.then(response => {
        if(loanApplication && loanApplication.borrowers) {
          const borrowerIndex = loanApplication.borrowers.findIndex((b:Borrower2009|Borrower) => b._id === borrowerId);
          if(borrowerIndex > -1) {
            if(!loanApplication.borrowers[borrowerIndex]!.assetReports) {
              loanApplication.borrowers[borrowerIndex]!.assetReports = [];
            }
            const index = loanApplication.borrowers![borrowerIndex].assetReports!.findIndex(r => r._id === reportId);
            if(index > -1) {
              loanApplication.borrowers![borrowerIndex].assetReports![index] = response.data;
            }
          }
        }

        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function deleteLoanApplicationAssetReport(loanId:string, borrowerId:string, reportId:string):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.deleteAssetReport(loanId, borrowerId, reportId, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      let loanApplication = state.loanApplication && state.loanApplication.data ? _.cloneDeep(state.loanApplication.data) : null;
      dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.UPDATING, _.cloneDeep(loanApplication)));

      apiRequest.then(response => {
        if(loanApplication && loanApplication.borrowers) {
          const borrowerIndex = loanApplication.borrowers.findIndex((b: Borrower2009 | Borrower) => b._id === borrowerId);
          if(borrowerIndex > -1 && loanApplication.borrowers![borrowerIndex].assetReports) {
            const index = loanApplication.borrowers![borrowerIndex].assetReports!.findIndex(r => r._id === reportId);
            if (index > -1) {
              loanApplication.borrowers[borrowerIndex].assetReports!.splice(index, 1);
            }
          }
        }

        dispatch(setData(types.SET_LOAN_APPLICATION, ReduxAsyncOperation.IDLE, loanApplication));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_APPLICATIONS, ReduxAsyncOperation.IDLE, loanApplication));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}
