import React, {Component} from 'react';
import {AccountPage} from '../../../layouts';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {PageTitle} from "../../../components";
import {BranchForm} from "../forms";
import {WithStyles, withStyles} from '@material-ui/core';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {Redirect, RouteComponentProps} from "react-router-dom";
import _ from 'lodash';
import styles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, SnackbarState} from "../../../types";
import {Branch} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel
} & WrappedComponentProps
  & WithStyles<typeof styles>
  & RouteComponentProps<{id:string}>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  branch: Branch|null,
  redirectTo?: string,
  snackbar?: SnackbarState,
  errorStatusCode: number|null
}

class BranchEditPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      branch: null,
      errorStatusCode: null
    };
  }

  componentDidMount = () => {
    this.props.routeProps.getBranch(this.props.match.params.id).send().then(result => {
      this.setState({ pageLoaded: true, branch: _.cloneDeep(result.data) });
    }).catch(err => {
      this.setState({ pageLoaded: true, errorStatusCode: err.response ? err.response.status : err.status });
    });
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'edit_branch');
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="person" />
    )
  };

  onCancel = () => {
    this.setState({ redirectTo: '/admin/branches' });
  };

  onSubmit = () => {
    this.setState({
      redirectTo: '/admin/branches',
      snackbar: {
        open: true,
        variant: 'success',
        message: IntlFormatter.formatMessage(this.props.intl, 'branch_updated')
      }
    });
  };

  render() {

    const { intl, classes, mixpanel } = this.props;
    const { pageLoaded, loading, branch, redirectTo, snackbar, errorStatusCode } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo, state: { snackbar } }}  />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: IntlFormatter.formatMessage(intl, 'branches'), to: '/admin/branches' },
                     {title: this.getPageTitle() }
                   ]}
                   errorStatusCode={errorStatusCode}
                   pageLoaded={pageLoaded}
                   loading={loading}>
        <div className={classes.content}>
          <BranchForm branch={branch}
                      mixpanel={mixpanel}
                      onSubmit={this.onSubmit}
                      onCancel={this.onCancel} />
        </div>
      </AccountPage>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(BranchEditPage));
