import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  tooltip: {
    display: 'inline-block'
  },
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3,
    cursor: 'pointer'
  },
  icon: {
    color: theme.palette.neutral.mainLight,
    fontSize: 14,
    marginLeft: 5
  },
});

export default styles;
