import React, {Component} from 'react';
import {Redirect, Link as RouterLink} from 'react-router-dom';
import {Grid, Typography, Link, withStyles, WithStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import Page from '../../../layouts/Page';
import {RegistrationForm} from '../forms';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {AppLogo, UrlFooter} from "../../../components/index";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import clsx from "clsx";
import {ReduxUtil} from "../../../utils";
import {ReduxUser, ErrorState} from "../../../types";
import {Mixpanel} from "mixpanel-browser";
import {ReduxState} from "../../../data/initialState";

type Props = {
  mixpanel: Mixpanel,
  user: ReduxUser
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  redirectTo?: string,
  loading: boolean,
  errors: ErrorState[]
}

class RegisterPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      errors: []
    };
  }

  componentDidMount = () => {
    const { user } = this.props;
    if(ReduxUtil.hasData(user)) {
      this.setState({ redirectTo: '/dashboard' });
    }
  };

  onSubmit = () => {
    this.setState({ redirectTo: '/dashboard' });
  };

  render() {
    const { intl, classes, mixpanel } = this.props;
    const { redirectTo, loading } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <Page pageTitle={IntlFormatter.formatMessage(intl, 'sign_up')}
            loading={loading}>
        <Grid container>
          <Grid item md={6}>
            <div className={classes.houseBg1}>
              <div className={classes.bgOverlay}>

              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className={classes.p4}>
              <div className={clsx(classes.mb3, classes.center)}>
                <AppLogo color="dark"/>
              </div>
              <div className={clsx(classes.mt3, classes.textCenter)}>
                <Typography variant="h2" color="inherit">
                  <FormattedMessage id="sign_up" />
                </Typography>
              </div>

              <div className={classes.p3}>
                <RegistrationForm mixpanel={mixpanel}
                                  onSubmit={this.onSubmit} />
              </div>

              <div className={clsx(classes.mt3, classes.textCenter)}>
                <Typography variant="body1">
                  <FormattedMessage id="already_have_an_account" />{' '}
                  <Link component={RouterLink} to="/login">
                    <FormattedMessage id="sign_in" />
                  </Link>
                </Typography>
              </div>

              <UrlFooter/>
            </div>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(RegisterPage)));
