import theme from './theme';
import {Config} from "./types";

const config:Config = {
  app: {
    name: 'Orca Loans',
    key: 'orcaloans',
    copyright: '©2022 JerseyDev Inc.'
  },
  api: {
    host: process.env.REACT_APP_API_HOST || 'https://api.orcaloans.com'
  },
  google: {
    placesApiKey: process.env.REACT_APP_GOOGLE_PLACES || ''
  },
  placeholders: {
    person: 'https://storage.googleapis.com/orcaloans/assets/img/person-silhouette-150x150.png',
    building: 'https://storage.googleapis.com/orcaloans/assets/img/building-placeholder-150-150.png'
  },
  maxUploadSize: {
    bytes: 25000000,
    label: '25MB'
  },
  theme,
  formDebounceTime: 1500,
  menus: {
    loanApplicationMenu: {
      Rev1: [
        { id: 'personal', to: '/apply/personal', labelId: 'personal', icon: 'people' },
        { id: 'address', to: '/apply/address-history', labelId: 'address_history', icon: 'markunread_mailbox' },
        { id: 'loan_info', to: '/apply/loan', labelId: 'loan_info', icon: 'dashboard' },
        { id: 'property', to: '/apply/property', labelId: 'property', icon: 'store_mall_directory' },
        { id: 'employment', to: '/apply/employment', labelId: 'employment', icon: 'work' },
        { id: 'income', to: '/apply/income', labelId: 'income', icon: 'attach_money' },
        { id: 'housing', to: '/apply/housing', labelId: 'housing', icon: 'home' },
        { id: 'assets_liabilities', to: '/apply/assets-and-liabilities', labelId: 'assets_liabilities', icon: 'account_balance_wallet' },
        { id: 'real_estate', to: '/apply/real-estate', labelId: 'real_estate', icon: 'business' },
        { id: 'declarations', to: '/apply/declarations', labelId: 'declarations', icon: 'format_list_bulleted' },
        { id: 'demographics', to: '/apply/demographics', labelId: 'demographics', icon: 'face' },
        { id: 'review', to: '/apply/review', labelId: 'review', icon: 'check_box' },
      ],
      Rev2: [
        { id: 'personal', to: '/apply/personal', labelId: 'personal', icon: 'people' },
        { id: 'address', to: '/apply/address-history', labelId: 'address_history', icon: 'markunread_mailbox' },
        { id: 'loan_info', to: '/apply/loan', labelId: 'loan_info', icon: 'dashboard' },
        { id: 'property', to: '/apply/property', labelId: 'property', icon: 'store_mall_directory' },
        { id: 'employment', to: '/apply/employment', labelId: 'employment', icon: 'work' },
        { id: 'income', to: '/apply/income', labelId: 'income', icon: 'attach_money' },
        { id: 'assets_liabilities', to: '/apply/assets-and-liabilities', labelId: 'assets_liabilities', icon: 'account_balance_wallet' },
        { id: 'real_estate', to: '/apply/real-estate', labelId: 'real_estate', icon: 'business' },
        { id: 'declarations', to: '/apply/declarations', labelId: 'declarations', icon: 'format_list_bulleted' },
        { id: 'demographics', to: '/apply/demographics', labelId: 'demographics', icon: 'face' },
        { id: 'gifts', to: '/apply/gifts', labelId: 'gifts', icon: 'card_giftcard' },
        { id: 'review', to: '/apply/review', labelId: 'review', icon: 'check_box' },
      ]
    },
    appMenu: [
      { id: 'dashboard',
        to: '/dashboard',
        labelId: 'dashboard',
        icon: 'dashboard'
      },
      {
        id: 'profile',
        to: '/account',
        labelId: 'my_account',
        icon: 'person'
      },
      {
        id: 'subscription',
        to: '/subscription',
        labelId: 'subscription',
        icon: 'payment'
      },
      {
        id: 'invoices',
        to: '/invoices',
        labelId: 'invoices',
        icon: 'payments'
      },
      {
        id: 'help',
        to: '/help',
        labelId: 'help',
        icon: 'change_history'
      }
    ],
    accountMenu: [
      {
        id: 'dashboard',
        to: '/dashboard',
        labelId: 'dashboard',
        icon: 'dashboard'
      },
      {
        id: 'loan_applications',
        to: '/admin/loan-applications',
        labelId: 'loan_applications',
        icon: 'library_books',
        regex: new RegExp('^/admin/loan-applications(.*)')
      },
      {
        id: 'branches',
        to: '/admin/branches',
        labelId: 'branches',
        icon: 'business',
        regex: new RegExp('^/admin/branches(.*)')
      },
      {
        id: 'loan_officers',
        to: '/admin/loan-officers',
        labelId: 'loan_officers',
        icon: 'people_outline',
        regex: new RegExp('^/admin/loan-officers(.*)')
      },
      {
        id: 'users',
        to: '/admin/users',
        labelId: 'users',
        icon: 'people',
        regex: new RegExp('^/admin/users(.*)')
      },
      {
        id: 'messages',
        to: '/messages',
        labelId: 'messages',
        icon: 'message',
        regex: new RegExp('^/messages(.*)'),
      },
      {
        id: 'calculators',
        to: '/calculators',
        labelId: 'calculators',
        icon: 'calculate',
        regex: new RegExp('^/calculators(.*)'),
      },
      {
        id: 'settings',
        to: '/admin/settings',
        labelId: 'settings',
        icon: 'settings',
        regex: new RegExp('^/admin/settings(.*)'),
        children: {
          visible: true,
          items: [
            {id: 'general_settings', to: '/admin/settings', labelId: 'general'},
            {id: 'loan_settings', to: '/admin/settings/loan', labelId: 'loan'},
            {id: 'email_settings', to: '/admin/settings/email', labelId: 'email'},
            {id: 'integration_settings', to: '/admin/settings/integrations', labelId: 'integrations'},
            {id: 'api_settings', to: '/admin/settings/api', labelId: 'api'},
            {id: 'webhook_settings', to: '/admin/settings/webhooks', labelId: 'webhooks'},
          ]
        },
      },
    ]
  },
  security: [
    { path: '^/dashboard$', roles: ['AUTHENTICATED_USER'] },
    { path: '^/messages(.*)', roles: ['AUTHENTICATED_USER'] },
    { path: '^/calculators(.*)', roles: ['AUTHENTICATED_USER'] },
    { path: '^/help$', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER', 'ROLE_BRANCH_MANAGER', 'ROLE_LOAN_OFFICER'] },
    { path: '^/upgrade$', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'] },
    { path: '^/plans$', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'] },
    { path: '^/account$', roles: ['AUTHENTICATED_USER'] },
    { path: '^/mobile-phone$', roles: ['AUTHENTICATED_USER'] },
    { path: '^/account/status$', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'] },
    { path: '^/subscription(.*)', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'] },
    { path: '^/invoices(.*)', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'] },
    { path: '^/admin/loan-applications(.*)', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER', 'ROLE_BRANCH_MANAGER', 'ROLE_LOAN_OFFICER'] },
    { path: '^/admin/users(.*)', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'] },
    { path: '^/admin/settings/api$', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'], plans: ['enterprise'] },
    { path: '^/admin/settings/webhooks$', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'], plans: ['enterprise'] },
    { path: '^/admin/settings/integrations$', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'], plans: ['professional', 'enterprise'] },
    { path: '^/admin/settings(.*)', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'] },
    { path: '^/admin/api(.*)', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'] },
    { path: '^/admin/branches/add$', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER'] },
    { path: '^/admin/branches/edit(.*)', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER', 'ROLE_BRANCH_MANAGER'] },
    { path: '^/admin/branches(.*)', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER', 'ROLE_BRANCH_MANAGER'] },
    { path: '^/admin/loan-officers(.*)', roles: ['ROLE_SUPER_ADMIN', 'ROLE_OWNER', 'ROLE_BRANCH_MANAGER'] },
    { path: '^/loan-applications/(.*)', roles: ['AUTHENTICATED_USER'] },
    { path: '^/apply(.*)', roles: ['AUTHENTICATED_USER'] },
  ]
}

export default config;
export { theme }