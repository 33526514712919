import palette from "./palette";
import {drawerWidth, defaultRadius} from "./constants";

const hexToRgb = (input:string) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase();
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

const defaultBoxShadow = {
  boxShadow:
    "0 0 5px 0 rgba(" +
    hexToRgb(palette.black) +
    ",.14)",
  transition: "all 150ms ease 0s"
};

const boxShadow = {
  boxShadow:
    "0 1px 4px 0 rgba(" +
    hexToRgb(palette.black) +
    ", 0.2)"
};

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 300,
  lineHeight: "1.5em"
};

const title = {
  color: palette.neutral.main,
  textDecoration: "none",
  fontWeight: "300",
  marginTop: "30px",
  marginBottom: "25px",
  minHeight: "32px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  "& small": {
    color: palette.neutral.dark,
    fontWeight: "400",
    lineHeight: "1"
  }
};

const menuTransitionSpeed = '.2s';
const menuIconOpacity = .3;
const menuTransition = `all ${menuTransitionSpeed} ease-in-out`;
const menuIconMinWidth = 35;


export {
  hexToRgb,
  menuTransitionSpeed,
  menuIconOpacity,
  menuTransition,
  menuIconMinWidth,
  transition,
  defaultFont,
  title,
  drawerWidth,
  defaultRadius,
  defaultBoxShadow,
  boxShadow
};
