import React, {Component} from 'react';
import {Typography, Tooltip, Icon, withStyles, WithStyles} from '@material-ui/core';

import styles from '../theme/jss/components/sectionHeadingStyles';

type Props = {
  title?: string,
  subtitle?: string,
  helperText?: string,
  icon?: React.ReactNode,
  actions?: React.ReactNode,
  className?: string
} & WithStyles<typeof styles>

class SectionHeading extends Component<Props> {
  render() {
    const { classes, title, subtitle, helperText, icon, className, actions} = this.props;
    return (
      <div className={className}>
        <div className={classes.root}>
          <div className={classes.titleContainer}>
            {icon &&
              <div className={classes.icon}>
                {icon}
              </div>
            }
            <Typography color="inherit" className={classes.title}>
              {title}
            </Typography>
            {helperText &&
              <Tooltip title={helperText}>
                <Icon className={classes.helperTextIcon}>info</Icon>
              </Tooltip>
            }

          </div>

          {actions &&
            <div>
              {actions}
            </div>
          }
        </div>
        {subtitle &&
          <Typography color="inherit" className={classes.subtitle}>
            {subtitle}
          </Typography>
        }
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SectionHeading);
