import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    width: '100%',
    marginRight: theme.spacing(1)
  },
  text: {
    fontWeight: 'bold',
    color: theme.palette.neutral.mainLight
  }
});

export default styles;
