import React, {Component} from 'react';
import {Tooltip, Icon, InputAdornment, IconButton} from '@material-ui/core';

type DefaultProps = {
  position: 'start'|'end'
}

type Props = {
  title: string,
  position: 'start'|'end'
}

class InputTooltip extends Component<Props> {
  static defaultProps:DefaultProps = {
    position: 'end'
  };

  render() {

    const { title, position } = this.props;

    return (
      <InputAdornment position={position}>
        <Tooltip title={title}>
          <IconButton>
            <Icon>info</Icon>
          </IconButton>
        </Tooltip>
      </InputAdornment>
    );
  }
}

export default InputTooltip;
