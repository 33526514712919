import React, {ChangeEvent} from 'react';
import Form, {TextValidator} from './Form';
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  withStyles,
  TableRow,
  WithStyles, Hidden, Grid,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import BaseForm from "./BaseForm";
import {ErrorList, ColorButton} from "../components";
import {connect} from "react-redux";
import {updateSubscription} from "../actions/subscription";
import pageStyles from "../theme/jss/layouts/pageStyles";
import {ErrorUtil, ReduxUtil, StripeUtil} from "../utils";
import IntlFormatter from "../intl";
import _ from 'lodash';
import moment from 'moment';
import {ActionResponse, ReduxSubscription, ErrorState} from "../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../data/initialState";
import {Subscription, SubscriptionUpdateRequest} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  onSubmit: (data:AxiosResponse<Subscription>) => void,
  updateSubscription: (data:SubscriptionUpdateRequest) => ActionResponse,
  subscription: ReduxSubscription
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  loading: boolean,
  numberOfLicenses: string,
  errors: ErrorState[]
}

class NumberOfLicensesForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      numberOfLicenses: props.subscription ? props.subscription.data.quantity.toString() : '1',
      errors: []
    };
  }

  getLicenseCost = ():string => {
    const { subscription } = this.props;
    const { numberOfLicenses } = this.state;

    let licenseCost:string = '';
    for(let i=0;i<subscription.data.plan.product.tiers.length;i++) {
      if(parseInt(numberOfLicenses) <= subscription.data.plan.product.tiers[i].up_to) {
        licenseCost = StripeUtil.formatAmount(subscription.data.plan.product.tiers[i].unit_amount);
        break;
      }
    }

    if(licenseCost === '0') {
      licenseCost = StripeUtil.formatAmount(subscription.data.plan.product.tiers[subscription.data.plan.product.tiers.length - 1].unit_amount);
    }

    return licenseCost;
  }

  onSubmit = async () => {
    try {
      this.setState({loading:true, errors: []});
      const quantity = parseInt(this.state.numberOfLicenses);
      this.props.mixpanel.track("Licenses Updated", { quantity });
      let result = await this.props.updateSubscription({ quantity }).send();
      this.setState({loading:false});

      if(this.props.onSubmit) {
        this.props.onSubmit(_.cloneDeep(result));
      }
    } catch(err) {
      this.setState({loading:true, errors:ErrorUtil.formatErrors(err)});
    }
  }

  getDiscountAmount = () => {
    let discount:number = 0;
    const {subscription} = this.props;
    const { numberOfLicenses } = this.state;
    const licenseCost = ReduxUtil.hasData(subscription) ? this.getLicenseCost() : '0';
    const subTotal = parseInt(numberOfLicenses) * parseInt(licenseCost);
    if(ReduxUtil.hasData(subscription) && subscription.data.discount) {
      if(subscription.data.discount.percent_off) {
        discount = subTotal * (subscription.data.discount.percent_off / 100);
      } else if(subscription.data.discount.amount_off) {
        discount = subTotal - parseFloat(StripeUtil.formatAmount(subscription.data.discount.amount_off));
      }
    }

    return discount;
  }

  renderLicenseInput = (label?:string) => {
    const {intl, subscription} = this.props;
    const minLicenses = ReduxUtil.hasData(subscription) ? subscription.data.quantity - subscription.data.licensesAvailable : 1;
    const { numberOfLicenses } = this.state;
    return (
      <div style={{width: 55}}>
        <TextValidator name="numberOfLicenses"
                       value={numberOfLicenses}
                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'numberOfLicenses')}
                       type="number"
                       margin="normal"
                       label={label}
                       validators={['required', `minNumber:${minLicenses}`]}
                       errorMessages={[
                         IntlFormatter.formatMessage(intl, 'validation_required'),
                         IntlFormatter.formatMessage(intl, 'validation_minvalue', {value: minLicenses })
                       ]} />
      </div>
    )
  }

  renderUpdateButton = () => {
    const {subscription} = this.props;
    const {loading, numberOfLicenses} = this.state;
    const numberOfLicensesChanged = ReduxUtil.hasData(subscription) && (subscription.data.quantity !== parseInt(numberOfLicenses));
    return (
      <ColorButton type="submit"
                   color="success"
                   disabled={!numberOfLicensesChanged}
                   loading={loading}>
        <FormattedMessage id="update"/>
      </ColorButton>
    )
  };

  render() {

    const { intl, classes, subscription } = this.props;
    const { errors, numberOfLicenses } = this.state;
    const licenseCost = ReduxUtil.hasData(subscription) ? this.getLicenseCost() : '0';
    const subTotal = parseInt(numberOfLicenses) * parseInt(licenseCost);
    const discount = this.getDiscountAmount();
    const hasDiscount = ReduxUtil.hasData(subscription) && subscription.data.discount && moment().unix() <= subscription.data.discount.end;
    const licenseCostText = `${licenseCost}/${IntlFormatter.formatMessage(intl, `per_${subscription.data.plan.interval}_interval`)}`
    return (
      <div>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <Form onSubmit={this.onSubmit}>
          <div className={classes.mt2}>
            <Hidden smUp>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <div>
                    {this.renderLicenseInput(IntlFormatter.formatMessage(intl, 'licenses'))}
                  </div>
                  <div>
                    <Typography variant="caption">
                      <FormattedMessage id="price" />
                    </Typography>
                    <Typography variant="body1">
                      ${licenseCostText}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="caption">
                      <FormattedMessage id="amount" />
                    </Typography>
                    <Typography variant="body1">
                      {subTotal.toMoney()}
                    </Typography>
                  </div>
                  {hasDiscount &&
                  <div>
                    <Typography variant="caption">
                      <b>{subscription.data.discount!.name}</b>
                    </Typography>
                    <Typography variant="body1">
                      <span>-{discount.toMoney()}</span>
                    </Typography>
                  </div>
                  }
                  {hasDiscount &&
                  <div>
                    <Typography variant="body1">
                      <strong><FormattedMessage id="total"/>&nbsp;{(subTotal - discount).toMoney()}</strong>
                    </Typography>
                  </div>
                  }
                </Grid>
                <Grid item>
                  {this.renderUpdateButton()}
                </Grid>
              </Grid>
            </Hidden>
            <Hidden xsDown>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="licenses" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="price" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="amount" />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {this.renderLicenseInput()}
                    </TableCell>
                    <TableCell>${licenseCostText}</TableCell>
                    <TableCell>&nbsp;{subTotal.toMoney()}</TableCell>
                    <TableCell>
                      {this.renderUpdateButton()}
                    </TableCell>
                  </TableRow>
                  {hasDiscount &&
                      <TableRow>
                        <TableCell colSpan={2} align="right">
                          <Typography variant="body1">
                            <b>{subscription.data.discount!.name}</b>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            <span>-{discount.toMoney()}</span>
                          </Typography>
                        </TableCell>
                      </TableRow>
                  }
                  {hasDiscount &&
                    <TableRow>
                      <TableCell colSpan={2} align="right">
                        <strong><FormattedMessage id="total"/></strong>
                      </TableCell>
                      <TableCell>
                        &nbsp;{(subTotal - discount).toMoney()}
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Hidden>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    subscription: state.subscription
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateSubscription(data:SubscriptionUpdateRequest) {
    return dispatch(updateSubscription(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(NumberOfLicensesForm)));
