import React from 'react';
import {FormattedMessage} from "react-intl";
import {Badge} from "./index";
import {Subscription} from "@jerseydev/orca-loans";

type Props = {
  subscription: Subscription
}

const LicensesUsedBadge = (props:Props) => {
  const {subscription} = props;

  return (
    <Badge color="infoAlt">
      <FormattedMessage id="num_licenses_used" values={{ num: `${subscription.quantity - subscription.licensesAvailable}/${subscription.quantity}` }} />
    </Badge>
  )
};

export default LicensesUsedBadge;