import React, {ChangeEvent, MouseEvent} from 'react';
import {
  Button,
  Divider,
  Grid,
  Icon,
  Card,
  withStyles,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  ListItemIcon, Menu, FormControlLabel, Checkbox, WithStyles
} from '@material-ui/core';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {LoanApplicationPage} from "../layouts";
import {ErrorUtil, LoanRev2Util, ReduxUtil} from "../../../utils";
import BaseLoanApplicationPage from "./BaseLoanApplicationPage";
import _ from 'lodash';
import {IncomeForm} from '../forms';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ErrorList, Dialog, Fab, DeleteDialog} from "../../../components";
import {LoanApplicationControls} from "../components";
import {
  ActionProps,
  DialogState,
  ErrorState,
  ReduxLoanApplication,
  ReduxLoanSettings
} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";
import {
  Borrower,
  BorrowerOtherIncome,
  LoanApplicationUpdateRequest
} from "@jerseydev/orca-loans";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  loading: boolean,
  selectedBorrower: Borrower | null,
  selectedIncome: BorrowerOtherIncome | null,
  redirectTo?: string,
  errors: ErrorState[],
  actionMenuEl: Element|null,
  incomeDialog: DialogState,
  deleteDialog: DialogState,
  isStepCompleted?: boolean
}

class IncomePage extends BaseLoanApplicationPage<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      selectedBorrower: null,
      selectedIncome: null,
      errors: [],
      actionMenuEl: null,
      incomeDialog: {
        open: false,
        loading: false,
        errors: []
      },
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      }
    };
  }

  static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: Readonly<State>){
    if(nextProps.loanApplication && nextProps.loanApplication.data && prevState.isStepCompleted === undefined){
      return {isStepCompleted: LoanRev2Util.isIncomeCompleted(nextProps.loanApplication.data)};
    }
    return null;
  };

  componentWillUnmount = () => {
    const {mixpanel, loanApplication} = this.props;
    if(loanApplication.data && !this.state.isStepCompleted && LoanRev2Util.isIncomeCompleted(loanApplication.data)) {
      mixpanel.track("Loan application step completed", {step: 'income'});
    }
  };

  onAddClick = (borrower:Borrower) => {
    this.setState({
      selectedBorrower: _.cloneDeep(borrower),
      incomeDialog: {
        open: true,
        loading: false,
        errors: []
      }
    });
  };

  onEditClick = () => {
    this.setState({
      actionMenuEl: null,
      incomeDialog: {
        open: true,
        loading: false,
        errors: []
      }
    });
  };

  hideFormDialog = () => {
    this.setState({
      selectedBorrower: null,
      selectedIncome: null,
      incomeDialog: {
        open: false,
        loading: false,
        errors: []
      }
    });
  };

  onIncomeFormSubmit = () => {
    this.setState({
      loading: false,
      selectedBorrower: null,
      selectedIncome: null,
      incomeDialog: {
        open: false,
        loading: false,
        errors: []
      }
    });
  };

  onShowActionMenu = (event:MouseEvent, borrower:Borrower, income:BorrowerOtherIncome) => {
    this.setState({
      actionMenuEl: event.currentTarget,
      selectedBorrower: _.cloneDeep(borrower),
      selectedIncome: _.cloneDeep(income)
    });
  };

  onCloseActionMenu = () => {
    this.setState({
      actionMenuEl: null,
      selectedBorrower: null,
      selectedIncome: null,
      incomeDialog: {
        open: false,
        loading: false,
        errors: []
      }
    });
  };

  onDeleteCancel = () => {
    this.setState({
      selectedBorrower: null,
      selectedIncome: null,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      }
    });
  };

  onDeleteClick = () => {
    this.setState({
      actionMenuEl: null,
      deleteDialog: {
        open: true,
        loading: false,
        errors: []
      }
    });
  };

  onDeleteSubmit = async () => {
    try {
      const {loanApplication} = this.props;
      const {selectedBorrower, selectedIncome} = this.state;

      if(loanApplication.data.borrowers && selectedBorrower && selectedIncome) {
        const deleteDialog = _.clone(this.state.deleteDialog);
        deleteDialog.loading = true;
        this.setState({deleteDialog});

        if(selectedBorrower.otherIncome) {
          const borrowerIndex = loanApplication.data.borrowers.findIndex(b => b._id === selectedBorrower._id);
          const index = selectedBorrower.otherIncome.findIndex(e => e._id === selectedIncome._id);
          await this.props.routeProps.updateLoanApplication(loanApplication.data._id, [`borrowers[${borrowerIndex}].otherIncome[${index}]`], {merge:'delete'}).send();
        }
      }

      this.setState({
        selectedBorrower: null,
        selectedIncome: null,
        deleteDialog: {
          open: false,
          loading: false,
          errors: []
        }
      });
    } catch (e) {
      const deleteDialog = _.clone(this.state.deleteDialog);
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({deleteDialog});
    }
  };

  onNoIncomeChange = async (event:ChangeEvent<HTMLInputElement>, borrower:Borrower) => {
    try {
      const checked = event.target.checked;
      this.setState({ loading: true });
      const loanApplication = _.cloneDeep(this.props.loanApplication.data);
      if(loanApplication.borrowers) {
        const borrowerRequestData = LoanRev2Util.borrowerToUpdateRequest(borrower);
        borrowerRequestData.otherIncome = checked ? null : [];
        const requestData: LoanApplicationUpdateRequest = {
          borrowers: [borrowerRequestData]
        };
        await this.props.routeProps.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      }
      this.setState({ loading: false });
    } catch (e) {
      this.setState({
        loading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  onNextClick = () => {
    this.setState({ loading: false, redirectTo: this.nextMenuItem.to });
  };

  render() {

    const { intl, classes, loanApplication } = this.props;
    const { loading, selectedBorrower, selectedIncome, redirectTo, errors, actionMenuEl, incomeDialog, deleteDialog } = this.state;


    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <LoanApplicationPage menuId="income"
                           loading={loading}>

        <Dialog open={incomeDialog.open}
                icon={<Icon>attach_money</Icon>}
                title={IntlFormatter.formatMessage(intl, selectedIncome ? 'edit_monthly_income_for' : 'add_monthly_income_for', {name: selectedBorrower ? `${selectedBorrower.firstName} ${selectedBorrower.lastName}` : ''})}
                color="primaryAlt"
                onClose={this.hideFormDialog}
                fullWidth={true}
                maxWidth="sm"
                fullScreen>
          <ErrorList errors={incomeDialog.errors}
                     className={classes.mb2} />

          {selectedBorrower &&
            <IncomeForm loanApplication={loanApplication.data}
                        borrower={selectedBorrower}
                        otherIncome={selectedIncome}
                        onSubmit={this.onIncomeFormSubmit}
                        actions={[
                          <Button onClick={this.hideFormDialog}>
                            <FormattedMessage id="cancel" />
                          </Button>
                        ]}/>
          }
        </Dialog>

        <DeleteDialog open={deleteDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'delete_income')}
                      item={selectedIncome ? IntlFormatter.formatMessage(intl, `income_type_${selectedIncome.type}`) : ''}
                      loading={deleteDialog.loading}
                      errors={deleteDialog.errors}
                      onCancel={this.onDeleteCancel}
                      onSubmit={this.onDeleteSubmit} />

        <Menu anchorEl={actionMenuEl}
              open={Boolean(actionMenuEl)}
              onClose={this.onCloseActionMenu}>

          <MenuItem onClick={this.onEditClick}>
            <ListItemIcon>
              <Icon>mode_edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
          </MenuItem>
          <MenuItem onClick={this.onDeleteClick}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')} />
          </MenuItem>
        </Menu>

        <div>
          <div className={classes.content}>

            <ErrorList errors={errors}
                       className={classes.mv2}
                       onClose={() => { this.setState({ errors: [] }); } } />

            {(ReduxUtil.hasData(loanApplication) && loanApplication.data.borrowers) &&
            <Grid container spacing={4}>
              {loanApplication.data.borrowers.map((borrower, i) => {
                return (
                  // @ts-ignore
                  <Grid key={i} item xs={12} sm={12} md={loanApplication.data.borrowers.length > 1 ? Math.floor(12 / loanApplication.data.borrowers.length) : 6}>
                    <Card className={classes.card}>
                      <CardHeader title={`${borrower.firstName} ${borrower.lastName}`}
                                  subheader={IntlFormatter.formatMessage(intl, borrower.primaryBorrower ? 'borrower' : 'co_borrower')}
                                  avatar={<Icon>{borrower.primaryBorrower ? 'person' : 'person_outline'}</Icon>}
                                  action={
                                    <Fab color="primary"
                                         size="small"
                                         flat
                                         rounded
                                         onClick={() => this.onAddClick(borrower)}>
                                      <Icon>add</Icon>
                                    </Fab>
                                  } />
                      {borrower.otherIncome &&
                        <div>
                          <Divider/>
                          <CardContent>
                            {borrower.otherIncome.length > 0 &&
                            <List>
                              {borrower.otherIncome.map((income, i) => {
                                return (
                                  <ListItem key={i}>
                                    <ListItemText
                                      primary={IntlFormatter.formatMessage(intl, income.type ? `income_type_${income.type}` : 'na')}
                                      secondary={income.value ? income.value.toMoney() : undefined}/>
                                    <ListItemSecondaryAction>
                                      <IconButton onClick={event => this.onShowActionMenu(event, borrower, income)}>
                                        <Icon>more_vert</Icon>
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              })}
                            </List>
                            }

                            {borrower.otherIncome.length === 0 &&
                            <div className={classes.mt2}>
                              <Button variant="outlined"
                                      color="primary"
                                      onClick={() => this.onAddClick(borrower)}>
                                <FormattedMessage id="add_income"/>
                              </Button>
                            </div>
                            }
                          </CardContent>
                        </div>
                      }

                      <Divider />
                      <div className={classes.p2}>
                        <FormControlLabel label={IntlFormatter.formatMessage(intl, 'no_additional_income')} control={
                          <Checkbox checked={!borrower.otherIncome}
                                    onChange={(event) => this.onNoIncomeChange(event, borrower)}
                                    color="primary" />
                        }
                        />
                      </div>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
            }
          </div>
          <div className={classes.content}>
            <LoanApplicationControls prev={this.previousMenuItem}
                                     next={this.nextMenuItem}
                                     onNextClick={this.onNextClick} />
          </div>
        </div>

      </LoanApplicationPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    settings: state.loanSettings
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(IncomePage)));
