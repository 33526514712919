import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  withStyles,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Icon,
  IconButton, MenuItem,
  ListItemIcon,
  Menu,
  WithStyles,
  Button
} from "@material-ui/core";
import {DeleteDialog, ErrorList, PageTitle, Snackbar, Dialog} from "../../../components";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {LosSettingsForm, PricingSettingsForm, AssetSettingsForm} from "../forms";
import {ErrorUtil, ReduxUtil} from "../../../utils";
import {
  ActionProps,
  ErrorState,
  Integrations,
  IntegrationType,
  ReduxIntegrations,
  SnackbarState
} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";
import {IntegrationUtil} from "../../../utils";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  integrations: ReduxIntegrations
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  selectedTab: number,
  settingsFormVisible: boolean,
  deleteIntegrationDialogOpen: boolean,
  selectedIntegrationType: IntegrationType|null,
  integrationActionMenuEl: Element|null,
  snackbar: SnackbarState,
  errors: ErrorState[]
}

type FormMap = {
  los: React.ReactNode,
  pricing: React.ReactNode,
  assets: React.ReactNode,
  credit?: React.ReactNode
}

class SettingsPage extends Component<Props, State> {

  //integrationTypes = ['los', 'pricing', 'credit', 'assets'];
  integrationTypes = ['los', 'pricing', 'assets'];

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      selectedTab: 0,
      settingsFormVisible: false,
      deleteIntegrationDialogOpen: false,
      selectedIntegrationType: null,
      integrationActionMenuEl: null,
      snackbar: {
        open: false,
        message: ''
      },
      errors: []
    };
  }

  componentDidMount = async () => {
    try {
      if(!ReduxUtil.hasData(this.props.integrations) && ReduxUtil.isIdle(this.props.integrations)) {
        await this.props.routeProps.getIntegrations();
      }
      this.setState({ pageLoaded: true });
    } catch (e) {
      this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(e) });
    }
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'integrations');
  };

  renderTitleBar = () => {
    return <PageTitle title={this.getPageTitle()}
                      subtitle={IntlFormatter.formatMessage(this.props.intl, 'integrations_page_text')}
                      icon="settings_input_component" />
  };

  selectTab = (e:MouseEvent, value:number) => {
    this.setState({ selectedTab: value });
  };

  onShowIntegrationSettings = (type:IntegrationType) => {
    this.setState({ integrationActionMenuEl: null, settingsFormVisible: true, selectedIntegrationType: type });
  };

  onIntegrationSettingsCancel = () => {
    this.setState({ settingsFormVisible: false, selectedIntegrationType: null });
  };

  onIntegrationSettingsSubmit = () => {
    this.setState({
      settingsFormVisible: false,
      selectedIntegrationType: null,
      snackbar: {
        open: true,
        message: IntlFormatter.formatMessage(this.props.intl, 'settings_saved')
      }
    });
  };

  onIntegrationSettingsDeleteCancel = () => {
    this.setState({ selectedIntegrationType: null, deleteIntegrationDialogOpen: false });
  };

  onIntegrationSettingsDeleteClick = (type:IntegrationType) => {
    this.setState({ selectedIntegrationType: type, deleteIntegrationDialogOpen: true, integrationActionMenuEl: null });
  };

  onIntegrationSettingsDelete = async (type:string) => {
    try {
      this.setState({ loading: true });
      switch(type) {
        case 'los':
          await this.props.routeProps.deleteLosIntegration().send();
          break;
        case 'assets':
          await this.props.routeProps.deleteAssetIntegration().send();
          break;
        case 'pricing':
          await this.props.routeProps.deletePricingIntegration().send();
          break;
        case 'credit':
          await this.props.routeProps.deleteCreditIntegration().send();
          break;
      }
      this.setState({
        loading: false,
        selectedIntegrationType: null,
        deleteIntegrationDialogOpen: false,
        snackbar: {
          open: true,
          message: IntlFormatter.formatMessage(this.props.intl, 'settings_deleted')
        }
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onActionMenuShow = (event:MouseEvent, type:IntegrationType) => {
    this.setState({ integrationActionMenuEl: event.currentTarget, selectedIntegrationType: type });
  };

  onActionMenuHide = () => {
    this.setState({ integrationActionMenuEl: null });
  };
  
  renderIntegrationSettingsForm = (type:string):React.ReactNode => {
    const { integrations, mixpanel } = this.props;

    const formMap:FormMap = {
      // @ts-ignore
      los: <LosSettingsForm settings={integrations.data![type as keyof IntegrationSettings]}
                            mixpanel={mixpanel}
                            onCancel={this.onIntegrationSettingsCancel}
                            onSubmit={this.onIntegrationSettingsSubmit} />,
      // @ts-ignore
      pricing: <PricingSettingsForm settings={integrations.data![type as keyof IntegrationSettings]}
                                    mixpanel={mixpanel}
                                    onCancel={this.onIntegrationSettingsCancel}
                                    onSubmit={this.onIntegrationSettingsSubmit} />,
      // @ts-ignore
      assets: <AssetSettingsForm settings={integrations.data![type as keyof IntegrationSettings]}
                                 mixpanel={mixpanel}
                                 onCancel={this.onIntegrationSettingsCancel}
                                 onSubmit={this.onIntegrationSettingsSubmit} />,
    };
    /*const formMap = {
      los: <LosSettingsForm settings={settings.data.integrations[type]}
                            onCancel={() => this.onIntegrationSettingsCancel(type)}
                            onSubmit={() => this.onIntegrationSettingsSubmit(type)} />,
      pricing: <PricingSettingsForm settings={settings.data.integrations[type]} 
                                    onCancel={() => this.onIntegrationSettingsCancel(type)} 
                                    onSubmit={() => this.onIntegrationSettingsSubmit(type)} />,
      credit: <CreditSettingsForm settings={settings.data.integrations[type]} 
                                  onCancel={() => this.onIntegrationSettingsCancel(type)} 
                                  onSubmit={() => this.onIntegrationSettingsSubmit(type)} />,
      assets: <AssetSettingsForm settings={settings.data.integrations[type]} 
                                 onCancel={() => this.onIntegrationSettingsCancel(type)} 
                                 onSubmit={() => this.onIntegrationSettingsSubmit(type)} />,
    };*/
    
    return formMap[type as keyof FormMap];
  };

  hideSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: ''
      }
    });
  };

  render() {

    const { intl, classes, integrations } = this.props;
    const { pageLoaded, loading, deleteIntegrationDialogOpen, selectedIntegrationType, integrationActionMenuEl, settingsFormVisible, snackbar, errors } = this.state;

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: IntlFormatter.formatMessage(intl, 'settings'), to: '/admin/settings' },
                     {title: this.getPageTitle() }
                   ]}
                   pageLoaded={pageLoaded}>
        <Snackbar open={snackbar.open}
                  variant={snackbar.variant || 'success'}
                  onClose={this.hideSnackbar}
                  message={snackbar.message}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.hideSnackbar}>
                      <Icon>close</Icon>
                    </IconButton>
                  ]} />

        {selectedIntegrationType &&
          <DeleteDialog open={deleteIntegrationDialogOpen}
                        title={selectedIntegrationType ? IntlFormatter.formatMessage(intl, `delete_${selectedIntegrationType}_integration`) : ''}
                        item={selectedIntegrationType && integrations.data && integrations.data[selectedIntegrationType as keyof Integrations] ? IntlFormatter.formatMessage(intl, integrations.data![selectedIntegrationType as keyof Integrations]!.provider) : ''}
                        loading={loading}
                        onCancel={this.onIntegrationSettingsDeleteCancel}
                        onSubmit={() => this.onIntegrationSettingsDelete(selectedIntegrationType)} />
        }

        {(ReduxUtil.hasData(integrations) && selectedIntegrationType) &&
          <Dialog open={settingsFormVisible}
                  icon={<Icon>settings_input_component</Icon>}
                  title={IntlFormatter.formatMessage(intl, [selectedIntegrationType] ? `edit_${selectedIntegrationType}_integration` : `add_${selectedIntegrationType}_integration`)}
                  color="primaryAlt"
                  fullWidth={true}
                  maxWidth="sm"
                  onClose={this.onIntegrationSettingsCancel}>
            <div>
              {this.renderIntegrationSettingsForm(selectedIntegrationType)}
            </div>
          </Dialog>
        }


        <div className={classes.content}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } } />
           {ReduxUtil.hasData(integrations) &&
            <div>
              <List>
                {this.integrationTypes.map((type:IntegrationType, i) => {
                  return (
                    <div key={i}>
                      <ListItem>
                        <ListItemText primary={IntlFormatter.formatMessage(intl, `integration_type_${type}`)}
                                      secondary={IntlFormatter.formatMessage(intl, `integration_type_${type}_description`)}/>
                        <ListItemSecondaryAction>
                          <div className={classes.rowCenter}>
                            {!IntegrationUtil.hasIntegration(integrations.data, type) &&
                            <Button color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={() => this.onShowIntegrationSettings(type)}>
                              <FormattedMessage id="add" />
                            </Button>
                            }
                            {IntegrationUtil.hasIntegration(integrations.data, type) &&
                            <IconButton onClick={event => this.onActionMenuShow(event, type)}>
                              <Icon>more_vert</Icon>
                            </IconButton>
                            }

                          </div>
                        </ListItemSecondaryAction>
                      </ListItem>
                      {i < this.integrationTypes.length - 1 &&
                      <Divider/>
                      }
                    </div>
                  )
                })}
              </List>

              {selectedIntegrationType &&
                <Menu anchorEl={integrationActionMenuEl}
                      open={Boolean(integrationActionMenuEl)}
                      onClose={this.onActionMenuHide}>
                  <MenuItem onClick={() => this.onShowIntegrationSettings(selectedIntegrationType)}>
                    <ListItemIcon>
                      <Icon>mode_edit</Icon>
                    </ListItemIcon>
                    <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')}/>
                  </MenuItem>
                  <MenuItem onClick={() => this.onIntegrationSettingsDeleteClick(selectedIntegrationType)}>
                    <ListItemIcon>
                      <Icon>cancel</Icon>
                    </ListItemIcon>
                    <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')}/>
                  </MenuItem>
                </Menu>
              }
            </div>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    integrations: state.integrations
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(SettingsPage)));
