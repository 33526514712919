import React, {ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {Checkbox, FormControlLabel, Grid, WithStyles, withStyles} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import BaseForm from "../../../forms/BaseForm";
import {SubmitButton, MoneyInput, Select} from '../../../components';
import _ from 'lodash';
import IntlFormatter from "../../../intl";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, ErrorState, LabelValuePairType, ReduxApp} from "../../../types";
import {
  Borrower, BorrowerGift, BorrowerGiftUpdateRequest,
  LoanApplication,
  LoanApplicationRequest, LoanApplicationUpdateRequest
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {AxiosResponse} from "axios";
import {ErrorUtil} from "../../../utils";

type Props = {
  loanApplication: LoanApplication,
  borrower: Borrower,
  gift?: BorrowerGift|null,
  actions?: React.ReactNodeArray,
  onSubmit: (data:AxiosResponse<LoanApplication>) => void,
  app: ReduxApp,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  assetType: string,
  source: string,
  deposited: boolean,
  value: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class GiftForm extends BaseForm<Props, State> {
  assetTypes:LabelValuePairType[] = [];
  sources:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      assetType: '',
      source: '',
      deposited: false,
      value: ''
    };

    if(props.gift) {
      const {assetType, source, deposited, value} = props.gift;
      form = {
        assetType: assetType ? assetType : '',
        source: source ? source : '',
        deposited: typeof deposited === 'boolean' ? deposited : false,
        value: value ? value.toString() : ''
      }
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    props.app.data.enums.giftAssetTypes.forEach(type => {
      this.assetTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `gift_asset_type_${type}`) })
    });

    props.app.data.enums.giftSources.forEach(type => {
      this.sources.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `gift_source_${type}`) })
    });
  }

  setAssetType = (type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.assetType = type ? type.value : null;
    this.setState({ form });
  };

  setSource = (source:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.source = source ? source.value : null;
    this.setState({ form });
  };

  onSubmit = async () => {
    try {
      const {loanApplication, borrower, gift} = this.props;
      this.setState({loading:true, errors: []});
      const {assetType, source, deposited, value} = this.state.form;

      const giftRequest:BorrowerGiftUpdateRequest = {
        _id: gift ? gift._id : undefined,
        assetType: assetType as BorrowerGiftUpdateRequest["assetType"],
        source: source as BorrowerGiftUpdateRequest["source"],
        deposited,
        value: parseInt(value)
      };

      const requestData:LoanApplicationUpdateRequest = {
        borrowers: [{
          _id: borrower._id,
         gifts: [giftRequest]
        }]
      };

      const result = await this.props.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      this.setState({loading:false}, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {
    const { intl, actions, classes } = this.props;
    const { loading, form } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <div className={classes.mb2}>
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Select name="source"
                        options={this.sources}
                        onChange={this.setSource}
                        value={form.source ? this.sources.find(t => t.value === form.source) : ''}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required'),
                        ]}
                        label={IntlFormatter.formatMessage(intl, 'source')}
                        placeholder={IntlFormatter.formatMessage(intl, 'select')} />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Select name="type"
                        options={this.assetTypes}
                        onChange={this.setAssetType}
                        value={form.assetType ? this.assetTypes.find(t => t.value === form.assetType) : ''}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required'),
                        ]}
                        label={IntlFormatter.formatMessage(intl, 'type')}
                        placeholder={IntlFormatter.formatMessage(intl, 'select')} />
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'amount')}
                          value={form.value}
                          fullWidth={true}
                          validators={['required', 'minNumber:0']}
                          decimalScale={0}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.value`)} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel label={IntlFormatter.formatMessage(intl, 'deposited')} control={
                <Checkbox checked={form.deposited}
                          onChange={() => this.onCheckboxChanged('form.deposited')}
                          color="primary" />
              }
              />
            </Grid>
          </Grid>
        </div>
        <Grid container alignItems="center" justifyContent="flex-end" >
          {actions && actions.length > 0 &&
          <Grid item>
            <div className={classes.mr1}>
              {actions.map((action,i) => {
                return <span key={i}>{action}</span>
              })}
            </div>
          </Grid>
          }
          <Grid item>
            <SubmitButton loading={loading}>
              <FormattedMessage id="submit" />
            </SubmitButton>
          </Grid>
        </Grid>

      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(GiftForm)));
