import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const styles = (theme:Theme) => createStyles({
  root: {
    minHeight: 150,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    boxShadow: 'none'
  },
  message: {
    fontSize: '18px',
    color: theme.palette.neutral.main
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiIcon-root': {
      fontSize: 50,
      color: theme.palette.neutral.main
    }
  },
  messageContainer: {
    marginBottom: theme.spacing(2)
  }
});

const useStyles = makeStyles(styles);
export default styles;
export {useStyles}
