import React, {ChangeEvent, Component} from 'react';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {
  IconButton,
  Icon,
  Card,
  Input,
  withStyles, InputAdornment, WithStyles
} from '@material-ui/core';
import IntlFormatter from "../intl";
import styles from "../theme/jss/components/searchInputStyles";
import _ from 'lodash';

type DefaultProps = {
  sortColumn: string,
  searchInputDelay: number
}

type Props = {
  onSearchTextChanged?: (value:string) => void,
  onSearchTextRemove?: () => void
} & Partial<DefaultProps>
  & WithStyles<typeof styles>
  & WrappedComponentProps

type State = {
  searchText: string
}

class SearchInput extends Component<Props, State> {
  static defaultProps = {
    sortColumn: 'select',
    searchInputDelay: 200
  };

  searchTimeout:NodeJS.Timeout;

  constructor(props:Props) {
    super(props);
    this.state = {
      searchText: ''
    };
  }

  componentWillUnmount = () => {
    clearTimeout(this.searchTimeout);
  };

  onSearchTextChanged = (event:ChangeEvent<{value:string}>) => {
    const searchText = _.clone(event.currentTarget.value);
    this.setState({ searchText });
    this.searchTimeout = setTimeout(() => {
      if(this.props.onSearchTextChanged && this.state.searchText === searchText) {
        this.props.onSearchTextChanged(searchText);
      }
    }, this.props.searchInputDelay);
  };

  onSearchTextRemove = () => {
    this.setState({ searchText: '' });
    if(this.props.onSearchTextRemove) {
      this.props.onSearchTextRemove();
    }
  };

  render() {

    const { intl, classes } = this.props;
    const { searchText } = this.state;

    return (
      <Card className={classes.card}>
        <div className={classes.container}>
          <div className={classes.searchFieldContainer}>
            <Input placeholder={IntlFormatter.formatMessage(intl, 'search')}
                   className={classes.searchFieldInput}
                   value={searchText}
                   onChange={this.onSearchTextChanged}
                   startAdornment={
                     <InputAdornment position="start">
                       <Icon>search</Icon>
                     </InputAdornment>
                   }
                   endAdornment={
                     searchText !== '' ?
                       <InputAdornment position="end">
                         <IconButton onClick={this.onSearchTextRemove}>
                           <Icon>close</Icon>
                         </IconButton>
                       </InputAdornment>
                       : null
                   }
                   disableUnderline />
          </div>
        </div>
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(SearchInput));
