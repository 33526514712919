import React, {Component} from 'react';
import {
  Button,
} from '@material-ui/core';
import {ErrorUtil, LoanUtil, ReduxUtil, UserUtil} from "../utils";
import {Alert} from "../components";
import {Link as RouterLink} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {searchLoanApplications} from "../actions/loanApplications";
import {LoanApplicationSearchRequest} from "@jerseydev/orca-loans";
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ActionResponse, ReduxLoanApplications, ReduxUser, ErrorState} from "../types";

type Props = {
  user: ReduxUser,
  className?: string,
  loanApplications: ReduxLoanApplications,
  searchLoanApplications: (criteria:LoanApplicationSearchRequest, params:any) => ActionResponse
}

type State = {
  loading: boolean,
  errors: ErrorState[]
}

class IncompleteLoanAlert extends Component<Props, State> {
  reduxRequest?:ActionResponse;

  constructor(props:Props) {
    super(props);
    this.state = {
      loading: true,
      errors: []
    }
  }

  componentDidMount = async () => {
    try {
      this.reduxRequest = this.props.searchLoanApplications({ status: ['started', 'abandoned'] },{ orderBy: 'created', orderByDirection: 'desc'});
      await this.reduxRequest.send();
      this.reduxRequest = undefined;
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  componentWillUnmount = () => {
    if(this.reduxRequest) {
      this.reduxRequest.cancel('Cancelling IncompleteLoanAlert request');
    }
  }

  render() {
    const { user, className, loanApplications } = this.props;
    const { loading } = this.state;

    if(UserUtil.isBorrower(user.data) && !loading && ReduxUtil.hasData(loanApplications) && loanApplications.data.length > 0) {
      return (
        <div className={className}>
          <Alert severity="warning" action={
            <Button variant="outlined" component={RouterLink} to={loanApplications.data.length === 1 ? LoanUtil.getEditUrl(loanApplications.data[0]) : '/dashboard'}>
              <FormattedMessage id={loanApplications.data.length === 1 ? 'complete_loan': 'view_loans' } />
            </Button>
          }>
            <FormattedMessage id={ loanApplications.data.length === 1 ? 'borrower_has_incomplete_loan_message': 'borrower_has_incomplete_loans_message'} />
          </Alert>
        </div>
      )
    }

    return null;
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    loanApplications: state.loanApplications
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  searchLoanApplications(criteria:LoanApplicationSearchRequest, params:any) {
    return dispatch(searchLoanApplications(criteria, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(IncompleteLoanAlert);
