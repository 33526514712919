import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from './data';
import _ from 'lodash';
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";
import {ImageRequest, LoanSettingsRequest, MessageSettingsRequest} from "@jerseydev/orca-loans";

export function getMessageSettings():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getMessageSettings({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const settings = state.messageSettings && state.messageSettings.data ? _.cloneDeep(state.messageSettings.data) : null;
      dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.FETCHING, settings));
      apiRequest.then(response => {
        dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.IDLE, settings));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateMessageSettings(data:MessageSettingsRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateMessageSettings(data, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const settings = state.messageSettings && state.messageSettings.data ? _.cloneDeep(state.messageSettings.data) : null;
      dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.UPDATING, settings));

      apiRequest.then(response => {
        dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.IDLE, settings));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function getLoanSettings():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getLoanSettings({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const settings = state.loanSettings && state.loanSettings.data ? _.cloneDeep(state.loanSettings.data) : null;
      dispatch(setData(types.SET_LOAN_SETTINGS, ReduxAsyncOperation.FETCHING, settings));
      apiRequest.then(response => {
        dispatch(setData(types.SET_LOAN_SETTINGS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_SETTINGS, ReduxAsyncOperation.IDLE, settings));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function updateLoanSettings(data:LoanSettingsRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.updateLoanSettings(data, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const settings = state.loanSettings && state.loanSettings.data ? _.cloneDeep(state.loanSettings.data) : null;
      dispatch(setData(types.SET_LOAN_SETTINGS, ReduxAsyncOperation.UPDATING, settings));

      apiRequest.then(response => {
        dispatch(setData(types.SET_LOAN_SETTINGS, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_LOAN_SETTINGS, ReduxAsyncOperation.IDLE, settings));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addLogo(imageRequest:ImageRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    let apiRequest = Api.addLogo(imageRequest, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const account = state.account && state.account.data ? _.cloneDeep(state.account.data) : null;
      dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.UPDATING, account));
      apiRequest.then(fileResult => {
        if(account) {
          account.logo = fileResult.data;
          dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        }
        resolve(fileResult);
      }).catch(ex => {
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function removeLogo():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    let apiRequest = Api.deleteLogo({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const account = state.account && state.account.data ? _.cloneDeep(state.account.data) : null;
      dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.UPDATING, account));
      apiRequest.then((response) => {
        if(account) {
          account.logo = null;
        }
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        resolve(response);
      }).catch(ex => {
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addLightLogo(image:ImageRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    let apiRequest = Api.addLightLogo(image, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const account = state.account && state.account.data ? _.cloneDeep(state.account.data) : null;
      dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.UPDATING, account));
      apiRequest.then(fileResult => {
        if(account) {
          account.lightLogo = fileResult.data;
        }
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        resolve(fileResult);
      }).catch(ex => {
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function removeLightLogo():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    let apiRequest = Api.deleteLightLogo({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const account = state.account && state.account.data ? _.cloneDeep(state.account.data) : null;
      dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.UPDATING, account));
      apiRequest.then((response) => {
        if(account) {
          account.lightLogo = null;
        }
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        resolve(response);
      }).catch(ex => {
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addFavicon(image:ImageRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    let apiRequest = Api.addFavicon(image, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const account = state.account && state.account.data ? _.cloneDeep(state.account.data) : null;
      dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.UPDATING, account));
      apiRequest.then(fileResult => {
        if(account) {
          account.favicon = fileResult.data;
        }
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        resolve(fileResult);
      }).catch(ex => {
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function removeFavicon():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    let apiRequest = Api.deleteFavicon({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const account = state.account && state.account.data ? _.cloneDeep(state.account.data) : null;
      dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.UPDATING, account));
      apiRequest.then((response) => {
        if(account) {
          account.favicon = null;
        }
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        resolve(response);
      }).catch(ex => {
        dispatch(setData(types.SET_ACCOUNT, ReduxAsyncOperation.IDLE, account));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function addEmailLogo(image:ImageRequest):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    let apiRequest = Api.addEmailLogo(image, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const messageSettings = state.messageSettings && state.messageSettings.data ? _.cloneDeep(state.messageSettings.data) : null;
      dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.UPDATING, messageSettings));
      apiRequest.then(fileResult => {
        if(messageSettings) {
          messageSettings.emailLogo = fileResult.data;
        }
        dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.IDLE, messageSettings));
        resolve(fileResult);
      }).catch(ex => {
        dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.IDLE,messageSettings));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}

export function removeEmailLogo():ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    let apiRequest = Api.deleteEmailLogo({cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const messageSettings = state.messageSettings && state.messageSettings.data ? _.cloneDeep(state.messageSettings.data) : null;
      dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.UPDATING, messageSettings));
      apiRequest.then((response) => {
        if(messageSettings) {
          messageSettings.emailLogo = null;
        }
        dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.IDLE, messageSettings));
        resolve(response);
      }).catch(ex => {
        dispatch(setData(types.SET_MESSAGE_SETTINGS, ReduxAsyncOperation.IDLE, messageSettings));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}