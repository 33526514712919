import {defaultFont} from "../index";
import {createStyles} from "@material-ui/core";

const styles = () => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  logoContainer: {
    marginRight: '10px',
    '& img': {
      maxWidth: '100%'
    }
  },
  textContainer: {
    display: 'flex',
    alignItems: 'baseline'
  },
  primaryText: {
    margin: "0",
    lineHeight: "30px",
    fontSize: "20px",
    fontWeight: "bold"
  },
  secondaryText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "18px",
    fontSize: "12px"
  },
});

export default styles;
