import React from "react";
import Rev1 from "./LoanAssetsLiabilitiesSummaryRev1";
import Rev2 from "./LoanAssetsLiabilitiesSummaryRev2";
import {LoanApplication2009, LoanApplication} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication
}

function LoanAssetsLiabilitiesSummary(props:Props) {

  const { loanApplication } = props;

  if(loanApplication.__t === 'Rev1') {
    return <Rev1 loanApplication={loanApplication as LoanApplication2009} />
  }

  return <Rev2 loanApplication={loanApplication as LoanApplication} />
}

export default LoanAssetsLiabilitiesSummary;