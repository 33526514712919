import React from 'react';
import IntlFormatter from "../intl";
import {Icon, IconButton} from "@material-ui/core";
import {Dialog, Snackbar} from "./index";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {HelpForm} from "../forms";
import {Mixpanel} from "mixpanel-browser";
import {MixpanelConsumer} from "react-mixpanel";
import {AxiosResponse} from "axios";
import {Ticket} from "@jerseydev/orca-loans";

type Props = {
  open: boolean,
  subject?: string,
  description?: string,
  refID?: string,
  refCategory?: string,
  onClose?: () => void,
  onSubmit: (data:AxiosResponse<Ticket>) => void
} & WrappedComponentProps

const HelpFormDialog = (props:Props) => {
  const {intl, open, subject, description, onClose, refID, refCategory} = props;
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);

  const onSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = (data:AxiosResponse<Ticket>) => {
    setSnackbarOpen(true);
    props.onSubmit(data);
  };

  return (
    <Dialog open={open}
            title={IntlFormatter.formatMessage(intl, 'help')}
            icon={<Icon>change_history</Icon>}
            color="primaryAlt"
            onClose={onClose}
            fullWidth={true}
            maxWidth="sm">
      <Snackbar open={snackbarOpen}
                variant="success"
                onClose={onSnackbarClose}
                message={IntlFormatter.formatMessage(intl, 'ticket_created')}
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={onSnackbarClose}>
                    <Icon>close</Icon>
                  </IconButton>
                ]} />
      <MixpanelConsumer>
        {(mixpanel:Mixpanel) => {
          return <HelpForm mixpanel={mixpanel}
                           subject={subject}
                           description={description}
                           refID={refID}
                           refCategory={refCategory}
                           onSubmit={onSubmit}
                           onCancel={onClose} />
        }}
      </MixpanelConsumer>
    </Dialog>
  )
};

export default injectIntl(HelpFormDialog);