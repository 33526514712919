import React, {Component} from 'react';
import {WithStyles, withStyles} from '@material-ui/core';
import styles from "../theme/jss/components/pageLoadingStyles";
import clsx from "clsx";
import {PropagateLoader} from 'react-spinners';
import palette from '../theme/jss/palette';

type Props = {
  visible: boolean,
  className?: string
} & WithStyles<typeof styles>

class PageLoader extends Component<Props> {
  render() {
    const { classes, visible, className } = this.props;

    if(!visible) {
      return null;
    }

    return (
      <div className={clsx(classes.root, className)}>
        <PropagateLoader
          // @ts-ignore
          color={palette.primary.main} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PageLoader);
