import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  withStyles,
  Button, InputLabel, Select, MenuItem, Checkbox, ListItemText, FormControl, FormControlLabel, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, TextField} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {AccountUtil, ErrorUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ReduxApp, ActionProps, ErrorState, ReduxIntegrations, ReduxAccount} from "../../../types";
import {AccountRequest, LoanDocumentCategory, LoanDocumentCategoryUpdateRequest} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {AxiosResponse} from "axios";
import {LoanDocumentCategoryRequest} from "@jerseydev/orca-loans/src/index";
import {Mixpanel} from "mixpanel-browser";
import IntegrationUtil from "../../../utils/IntegrationUtil";
import {addLoanDocumentCategory, updateLoanDocumentCategory} from "../../../actions/loanDocumentCategories";
import {updateAccount} from "../../../actions/account";

type Props = {
  mixpanel: Mixpanel,
  documentCategory?: LoanDocumentCategory|null,
  onSubmit: (data:AxiosResponse<LoanDocumentCategory>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  integrations: ReduxIntegrations,
  account: ReduxAccount,
  addLoanDocumentCategory: ActionProps["addLoanDocumentCategory"],
  updateLoanDocumentCategory: ActionProps["updateLoanDocumentCategory"],
  updateAccount: ActionProps["updateAccount"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  name: string;
  loanTypes: LoanDocumentCategory["loanTypes"];
  loanPurposes: LoanDocumentCategory["loanPurposes"];
  required: boolean;
  assetHistory?: boolean
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class DocumentCategoryForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    let form:Form = {
      name: '',
      required: false,
      loanTypes: [],
      loanPurposes: [],
      assetHistory: false
    }

    if(props.documentCategory) {
      const {name, required, loanTypes, loanPurposes, assetHistory} = props.documentCategory;
      form = {
        name,
        required,
        loanTypes,
        loanPurposes,
        assetHistory
      };
    } else {
      form.loanPurposes = _.cloneDeep(props.app.data.enums.loanPurposes) as LoanDocumentCategory["loanPurposes"];
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };
  }

  onLoanTypesChange = (event:ChangeEvent<{value:LoanDocumentCategory["loanTypes"]}>) => {
    const form = _.cloneDeep(this.state.form);
    form.loanTypes = _.clone(event.target.value);
    this.setState({ form });
  };

  renderLoanTypes = (selected:string[]) => {
    let loanTypes:string[] = [];

    selected.forEach(t => {
      loanTypes.push(IntlFormatter.formatMessage(this.props.intl, `loan_type_${t}`));
    });

    return loanTypes.join(', ');
  };

  onLoanPurposesChange = (event:ChangeEvent<{value:LoanDocumentCategory["loanPurposes"]}>) => {
    const form = _.cloneDeep(this.state.form);
    form.loanPurposes = _.clone(event.target.value);
    this.setState({ form });
  };

  renderLoanPurposes = (selected:string[]) => {
    let loanPurposes:string[] = [];

    selected.forEach(purpose => {
      loanPurposes.push(IntlFormatter.formatMessage(this.props.intl, purpose));
    });

    return loanPurposes.join(', ');
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      const { documentCategory, mixpanel, account } = this.props;
      this.setState({ loading: true, errors: [] });
      const {name, loanTypes, loanPurposes, assetHistory, required} = this.state.form;
      let result:AxiosResponse<LoanDocumentCategory>;
      if(documentCategory && documentCategory._id) {
        const requestData:LoanDocumentCategoryUpdateRequest = {
          name,
          loanTypes,
          loanPurposes,
          assetHistory,
          required
        };
        result = await this.props.updateLoanDocumentCategory(documentCategory._id, requestData).send();
        mixpanel.track("Document category updated");
      } else {
        const requestData:LoanDocumentCategoryRequest = {
          name,
          loanTypes,
          loanPurposes,
          assetHistory,
          required
        };
        result = await this.props.addLoanDocumentCategory(requestData).send();
        mixpanel.track("Document category added");

        if(!AccountUtil.isSetupRecommendationUsed('loanDocumentCategories', account.data)) {
          let requestData:AccountRequest = AccountUtil.addSetupRecommendationToRequest('loanDocumentCategories', {}, account.data);
          await this.props.updateAccount(requestData).send();
        }
      }

      this.setState({ loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes, app, integrations } = this.props;
    const { form, loading, errors} = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } }/>

        <div className={classes.mb2}>
          <TextField name="name"
                     label={IntlFormatter.formatMessage(intl, 'name')}
                     onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.name')}
                     value={form.name}
                     fullWidth={true}
                     validators={['required']}
                     errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}/>
        </div>

        <div className={classes.mb2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="loan-types-label">{IntlFormatter.formatMessage(intl, 'loan_types')} *</InputLabel>
            <Select
              labelId="loan-types-label"
              id="loan-types-select"
              multiple
              value={form.loanTypes}
              renderValue={this.renderLoanTypes}
              onChange={this.onLoanTypesChange}
              label={IntlFormatter.formatMessage(intl, 'loan_types')}
            >
              {app.data.enums.loanTypes.map((loanType:LoanDocumentCategory["loanTypes"][0]) => (
                <MenuItem key={loanType} value={loanType}>
                  <Checkbox checked={form.loanTypes.indexOf(loanType) > -1} />
                  <ListItemText primary={IntlFormatter.formatMessage(intl, `loan_type_${loanType}`)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={classes.mb2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="loan-purposes-label">{IntlFormatter.formatMessage(intl, 'loan_purpose')} *</InputLabel>
            <Select
              labelId="loan-purposes-label"
              id="loan-purposes-select"
              multiple
              value={form.loanPurposes}
              renderValue={this.renderLoanPurposes}
              onChange={this.onLoanPurposesChange}
              label={IntlFormatter.formatMessage(intl, 'loan_purpose')}
            >
              {app.data.enums.loanPurposes.map((loanPurpose:LoanDocumentCategory["loanPurposes"][0]) => (
                <MenuItem key={loanPurpose} value={loanPurpose}>
                  <Checkbox checked={form.loanPurposes.indexOf(loanPurpose) > -1} />
                  <ListItemText primary={IntlFormatter.formatMessage(intl, loanPurpose)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={classes.mb2}>
          <FormControlLabel label={IntlFormatter.formatMessage(intl, 'required')}
                            control={
                              <Checkbox checked={form.required}
                                        onChange={() => this.onCheckboxChanged('form.required')}/>
                            }/>
        </div>

        {IntegrationUtil.hasIntegration(integrations.data, 'assets') &&
          <div className={classes.mb2}>
            <FormControlLabel label={IntlFormatter.formatMessage(intl, 'document_category_asset_history_help_text')}
                              control={
                                <Checkbox checked={form.assetHistory}
                                          onChange={() => this.onCheckboxChanged('form.assetHistory')}/>
                              }/>
          </div>
        }

        <div className={classes.mt2}>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
            {this.props.onCancel &&
            <Grid item>
              <Button onClick={this.props.onCancel}>
                <FormattedMessage id="cancel" />
              </Button>
            </Grid>
            }
            <Grid item>
              <SubmitButton loading={loading}
                            disabled={form.loanTypes.length === 0 || form.loanPurposes.length === 0}>
                <FormattedMessage id="save" />
              </SubmitButton>
            </Grid>
          </Grid>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    integrations: state.integrations,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addLoanDocumentCategory(data:LoanDocumentCategoryRequest) {
    return dispatch(addLoanDocumentCategory(data));
  },
  updateLoanDocumentCategory(id:string, data:LoanDocumentCategoryUpdateRequest) {
    return dispatch(updateLoanDocumentCategory(id, data));
  },
  updateAccount(data:AccountRequest) {
    return dispatch(updateAccount(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(DocumentCategoryForm)));
