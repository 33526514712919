import React from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {
  withStyles,
  Typography, Table, TableHead, TableBody, TableRow, TableCell, Icon, IconButton, Hidden, WithStyles,
} from "@material-ui/core";
import styles from "../../../theme/jss/components/creditStyles";
import IntlFormatter from "../../../intl";
import {
  LoanApplication2009,
  Borrower2009,
  LoanApplication,
  Borrower
} from "@jerseydev/orca-loans";
import {FixMeLater} from "../../../types";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication
} & WrappedComponentProps
  & WithStyles<typeof styles>

class Credit extends React.Component<Props> {
  onCreditReportDownloadClick = (creditReportDocument:FixMeLater) => {
    window.open(creditReportDocument.document.url, '_blank');
  };

  render() {

    const { intl, classes, loanApplication } = this.props;

    return (
      <div>
        {loanApplication.borrowers &&
          <div>
            <Hidden smUp>
              <div>
                {loanApplication.borrowers.map((form:Borrower2009|Borrower, i:number) => {
                  const creditReportDocument = loanApplication.documents ? loanApplication.documents.find(d => d.type === 'creditReport' && d.document._id === form._id) : [];
                  return (
                    <div key={i} className={classes.creditResultContainer}>
                      <div>
                        <Typography variant="subtitle1">{form.firstName} {form.lastName}</Typography>
                      </div>

                      <div className={classes.scoreText}>
                        <FormattedMessage id="experian" />: {form.creditScore ? form.creditScore.experian : IntlFormatter.formatMessage(intl, 'na')}
                      </div>

                      <div className={classes.scoreText}>
                        <FormattedMessage id="transunion" />: {form.creditScore ? form.creditScore.transunion : IntlFormatter.formatMessage(intl, 'na')}
                      </div>

                      <div className={classes.scoreText}>
                        <FormattedMessage id="equifax" />: {form.creditScore ? form.creditScore.equifax : IntlFormatter.formatMessage(intl, 'na')}
                      </div>

                      {creditReportDocument &&
                      <IconButton onClick={() => this.onCreditReportDownloadClick(creditReportDocument)}>
                        <Icon>save_alt</Icon>
                      </IconButton>
                      }
                    </div>
                  )
                })}
              </div>
            </Hidden>
            <Hidden xsDown>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="name" />
                    </TableCell>
                    <TableCell className={classes.creditProvider}>
                      {/*<img src="/assets/img/logo-experian.png" alt="Experian" />*/}
                      <FormattedMessage id="experian" />
                    </TableCell>
                    <TableCell className={classes.creditProvider}>
                      {/*<img src="/assets/img/logo-transunion.gif" alt="TransUnion" />*/}
                      <FormattedMessage id="transunion" />
                    </TableCell>
                    <TableCell className={classes.creditProvider}>
                      {/*<img src="/assets/img/logo-equifax.png" alt="Equifax" />*/}
                      <FormattedMessage id="equifax" />
                    </TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loanApplication.borrowers.map((form:Borrower2009|Borrower, i:number) => {
                    const creditReportDocument = loanApplication.documents ? loanApplication.documents.find(d => d.type === 'creditReport' && d.document._id === form._id) : [];
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <Typography variant="body1">{form.firstName} {form.lastName}</Typography>
                        </TableCell>
                        <TableCell>
                          <div className={classes.scoreText}>

                            {form.creditScore ? form.creditScore.experian : IntlFormatter.formatMessage(intl, 'na')}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.scoreText}>
                            {form.creditScore ? form.creditScore.transunion : IntlFormatter.formatMessage(intl, 'na')}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.scoreText}>
                            {form.creditScore ? form.creditScore.equifax : IntlFormatter.formatMessage(intl, 'na')}
                          </div>
                        </TableCell>
                        <TableCell>
                          {creditReportDocument &&
                            <IconButton onClick={() => this.onCreditReportDownloadClick(creditReportDocument)}>
                              <Icon>save_alt</Icon>
                            </IconButton>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Hidden>
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(Credit));
