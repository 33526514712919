import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {ErrorBoundary, Loader, Snackbar, ApiSocket} from '../components';
import config from '../config';
import {withStyles} from '@material-ui/core/styles';
import {Icon, IconButton} from "@material-ui/core";
import _ from 'lodash';
import {RouteComponentProps, withRouter} from "react-router-dom";
import styles from '../theme/jss/layouts/pageStyles';
import {ReduxUtil} from "../utils";
import queryString from "query-string";
import {setDefaultLoanOfficer} from "../actions/loanOfficers";
import {ActionProps, ReduxDesign, SnackbarState} from "../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../data/initialState";

type DefaultProps = {
  drawerMenu: boolean,
  pageLoaded: boolean,
  loading: boolean,
}

type Props = {
  className?: string,
  pageTitle?: string,
  hasAppBar?: boolean,
  snackbar?: SnackbarState|null,
  classes?: any,
  titleBar?: React.ReactNode,
  design: ReduxDesign,
  setDefaultLoanOfficer: ActionProps["setDefaultLoanOfficer"]
} & Partial<DefaultProps>
  & RouteComponentProps<{}, any, {snackbar:SnackbarState}>

type State = {
  snackbar?: SnackbarState|null
}

class Page extends Component<Props, State> {
  static defaultProps:DefaultProps = {
    drawerMenu: false,
    pageLoaded: true,
    loading: false,
  };

  constructor(props:Props) {
    super(props);
    this.state = {}
  };

  componentDidMount = async () => {
    const { pageTitle, location } = this.props;
    document.body.id = pageTitle && pageTitle !== '' ? `page-${pageTitle.replace(/[^a-z0-9+]+/gi, '-').toLowerCase()}` : '';
    const queryParams = queryString.parse(location.search);
    if(queryParams.loanOfficer) {
      const loanOfficerEmail = decodeURIComponent(queryParams.loanOfficer as string);
      this.props.setDefaultLoanOfficer(loanOfficerEmail);
    }

    if(this.props.location && this.props.location.state && this.props.location.state.snackbar) {
      this.setState({ snackbar: _.clone(this.props.location.state.snackbar) });
    }
  };

  componentDidUpdate = (prevProps:Props) => {
    if(!prevProps.snackbar && this.props.snackbar) {
      this.setState({ snackbar: _.clone(this.props.snackbar)});
    }
  };

  showSnackbar = (snackbar:SnackbarState) => {
    this.setState({ snackbar });
  };

  hideSnackbar = () => {
    this.setState({ snackbar: null });
  };

  render() {
    const { hasAppBar, pageTitle, children, loading, classes, drawerMenu, design, className, pageLoaded } = this.props;
    const { snackbar } = this.state;

    let fullPageTitle = ReduxUtil.hasData(design) ? design.data.name : config.app.name;
    if(pageTitle) {
      fullPageTitle = `${pageTitle} | ${fullPageTitle}`;
    }

    let mainPanelClasses = classes.mainPanel;
    if(drawerMenu) {
      mainPanelClasses = mainPanelClasses + ' ' + classes.mainPanelWithDrawer;
    }

    let containerClasses = classes.wrapper;
    if(hasAppBar) {
      containerClasses = containerClasses + ' ' + classes.pageContainerWithAppBar;
    }

    if(className) {
      containerClasses = containerClasses + ' ' + className;
    }

    let pageId = pageTitle && pageTitle !== '' ? `page-${pageTitle.replace(/[^a-z0-9+]+/gi, '-').toLowerCase()}` : ''

    return (
      <div id={pageId} className={containerClasses}>
        <Helmet>
          <title>{fullPageTitle}</title>
          {ReduxUtil.hasData(design) && design.data.favicon &&
            <link rel="shortcut icon" href={design.data.favicon.url} />
          }

          {(!ReduxUtil.hasData(design) || (ReduxUtil.hasData(design) && !design.data.favicon)) &&
            <link rel="shortcut icon" href="/assets/img/favicon.png" />
          }
          {(!ReduxUtil.hasData(design) || (ReduxUtil.hasData(design) && !design.data.favicon)) &&
            <link rel="apple-touch-icon" sizes="76x76" href="/assets/img/apple-icon.png"/>
          }
        </Helmet>

        <Loader visible={!!(loading && pageLoaded)}/>

        {snackbar &&
          <Snackbar open={true}
                    variant={snackbar.variant}
                    onClose={this.hideSnackbar}
                    message={snackbar.message}
                    action={[
                      <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={this.hideSnackbar}>
                        <Icon>close</Icon>
                      </IconButton>
                    ]} />

        }

        <ErrorBoundary>
          <ApiSocket>
            <main className={mainPanelClasses}>
              {children}
            </main>
          </ApiSocket>
        </ErrorBoundary>
      </div>
    )
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    design: state.design
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  setDefaultLoanOfficer(email:string) {
    return dispatch(setDefaultLoanOfficer(email));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Page)));

