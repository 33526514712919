import React from 'react';
import {Form} from '../../../forms';
import {Grid, WithStyles, withStyles} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import BaseForm from "../../../forms/BaseForm";
import {SubmitButton, RadioGroup, ErrorList} from '../../../components';
import IntlFormatter from "../../../intl";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, ErrorState} from "../../../types";
import {LoanApplication, LoanApplicationRequest, LoanApplicationUpdateRequest} from "@jerseydev/orca-loans";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {connect} from "react-redux";
import {AxiosResponse} from "axios";
import moment from "moment";
import {ErrorUtil} from "../../../utils";

type Props = {
  loanApplication: LoanApplication,
  onSubmitClick?: () => void,
  onSubmit: (data:AxiosResponse<LoanApplication>) => void,
  actions?: React.ReactNodeArray,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  creditAuth: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class CreditAuthForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        creditAuth: (props.loanApplication.creditAuth !== null && props.loanApplication.creditAuth !== undefined) ? props.loanApplication.creditAuth : ''
      },
      errors: []
    };
  }

  onSubmit = async () => {
    if(this.props.onSubmitClick) {
      this.props.onSubmitClick();
    }
    try {
      const {creditAuth} = this.state.form;
      const requestData:LoanApplicationUpdateRequest = {
        completed: moment.utc().toDate().toISOString(), // @todo move completed outside of this form
        creditAuth: creditAuth as LoanApplicationUpdateRequest["creditAuth"]
      };

      this.setState({ loading: true, errors: [] });
      const result = await this.props.updateLoanApplication(this.props.loanApplication._id, requestData).send();
      this.setState({ loading: false }, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, actions, classes, children, loanApplication } = this.props;
    const { loading, form, errors } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } }/>
        <div className={classes.mb2}>
          <RadioGroup name="authorization"
                      label={IntlFormatter.formatMessage(intl, 'credit_authorization')}
                      itemValueProp="value"
                      value={form.creditAuth}
                      onChange={event => this.onRadioChange(event, 'form.creditAuth')}
                      items={ ((loanApplication.borrowers && loanApplication.borrowers.length === 1) || !loanApplication.borrowers) ?
                        [
                          { label: IntlFormatter.formatMessage(intl, 'i_agree'), value: 'individually' },
                          { label: IntlFormatter.formatMessage(intl, 'do_not_run_credit'), value: 'declined' },
                        ] :
                        [
                          { label: IntlFormatter.formatMessage(intl, 'jointly'), value: 'joint' },
                          { label: IntlFormatter.formatMessage(intl, 'individually'), value: 'individually' },
                          { label: IntlFormatter.formatMessage(intl, 'do_not_run_credit'), value: 'declined' },
                        ]
                      }
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required')
                      ]}
                      row />
        </div>

        {children}

        {!children &&
          <Grid container alignItems="center" justifyContent="flex-end">
            {actions && actions.length > 0 &&
            <Grid item>
              <div className={classes.mr1}>
                {actions.map((action,i) => {
                  return <span key={i}>{action}</span>
                })}
              </div>
            </Grid>
            }
            <Grid item>
              <SubmitButton loading={loading}>
                <FormattedMessage id="submit" />
              </SubmitButton>
            </Grid>
          </Grid>
        }
      </Form>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(CreditAuthForm)));
