import React, {Component} from 'react';
import {AccountPage} from '../../../layouts';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {LoanOfficerProfileIncompleteList, PageTitle} from "../../../components";
import {LoanOfficerForm} from "../forms";
import {WithStyles, withStyles} from '@material-ui/core';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {Redirect, RouteComponentProps} from "react-router-dom";
import _ from 'lodash';
import {ActionProps, SnackbarState} from "../../../types";
import {LoanOfficer} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps<{id:string}>

type State = {
  pageLoaded: boolean,
  loading: boolean,
  loanOfficer: LoanOfficer|null,
  redirectTo?: string,
  snackbar?: SnackbarState|null,
  errorStatusCode?: number
}

class LoanOfficerEditPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      loanOfficer: null,
      snackbar: null
    };
  }

  componentDidMount = async () => {
    try {
      const result = await this.props.routeProps.getLoanOfficer(this.props.match.params.id).send();
      this.setState({ pageLoaded: true, loanOfficer: _.cloneDeep(result.data) });
    } catch (e) {
      this.setState({ pageLoaded: true, errorStatusCode: e.response ? e.response.status : e.status });
    }
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'edit_loan_officer');
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="person" />
    )
  };

  onCancel = () => {
    this.setState({ redirectTo: '/admin/loan-officers' });
  };

  onSubmit = () => {
    this.setState({
      redirectTo: '/admin/loan-officers',
      snackbar: {
        open: true,
        variant: 'success',
        message: IntlFormatter.formatMessage(this.props.intl, 'loan_officer_updated')
      }
    });
  };

  render() {

    const { intl, classes, mixpanel } = this.props;
    const { pageLoaded, loading, redirectTo, snackbar, loanOfficer, errorStatusCode } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={{ pathname: redirectTo, state: { snackbar } }}  />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: IntlFormatter.formatMessage(intl, 'loan_officers'), to: '/admin/loan-officers' },
                     {title: this.getPageTitle() }
                   ]}
                   errorStatusCode={errorStatusCode}
                   pageLoaded={pageLoaded}
                   loading={loading}>
        <div className={classes.content}>
          {loanOfficer &&
            <div>
              <LoanOfficerProfileIncompleteList loanOfficer={loanOfficer}
                                                className={classes.mb4} />
              <LoanOfficerForm loanOfficer={loanOfficer}
                               mixpanel={mixpanel}
                               onCancel={this.onCancel}
                               onSubmit={this.onSubmit}/>
            </div>
          }
        </div>
      </AccountPage>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(LoanOfficerEditPage));
