import {defaultRadius} from '../jss';
import palette from "../jss/palette";
import {hexToRgb} from "../jss";
import {PaletteColor} from "@material-ui/core/styles/createPalette";

const primaryMain = (palette.primary as PaletteColor).main;

export default {
  root: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 5,
    borderRadius: defaultRadius,
    '&:hover': {
      backgroundColor: "rgba(" + hexToRgb(primaryMain) + ", .05)",
      color: primaryMain,
      '& .MuiListItemIcon-root, .MuiListItemText-root, .MuiMenuItem-root, .MuiTypography-body1': {
        color: primaryMain
      }
    },
    '&$selected': {
      color: primaryMain
    },
    '&:last-child': {
      marginBottom: 0,
    }
  }
};
