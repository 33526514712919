import React, {Component} from 'react';
import {Typography, Icon, Hidden, Paper, withStyles, WithStyles} from '@material-ui/core';
import styles from "../theme/jss/components/pageTitleStyles";

type Props = {
  title: string,
  icon: string|React.ReactNode,
  subtitle?: string,
  className?: string,
  dangerouslySetInnerHTML?: boolean
} & WithStyles<typeof styles>

class PageTitle extends Component<Props> {

  render() {
    const {classes, title, subtitle, icon, className, dangerouslySetInnerHTML} = this.props;
    return (
      <div className={className}>
        <div className={classes.root}>
          {icon &&
            <Hidden smDown>
              <div className={classes.iconContainer}>
                {typeof icon === 'string' &&
                  <Paper className={classes.iconPaper}>
                    <Icon color="primary">{icon}</Icon>
                  </Paper>
                }
                {typeof icon !== 'string' &&
                  <Paper className={classes.iconPaper}>
                    {icon}
                  </Paper>
                }
              </div>
            </Hidden>
          }

          {dangerouslySetInnerHTML &&
            <div>
              <Typography variant="h3"
                          color="inherit"
                          dangerouslySetInnerHTML={{__html: title}} />
              {subtitle &&
                <Typography variant="subtitle2"
                            color="inherit"
                            dangerouslySetInnerHTML={{__html: subtitle}} />
              }
            </div>
          }

          {!dangerouslySetInnerHTML &&
          <div>
            <Typography variant="h3"
                        color="inherit">
              {title}
            </Typography>
            {subtitle &&
            <Typography variant="subtitle2"
                        color="inherit">
              {subtitle}
            </Typography>
            }
          </div>
          }
        </div>

      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PageTitle);
