import IntlFormatter from "../intl";
import palette from "../theme/jss/palette";
import {IntlShape} from "react-intl";
import {FixMeLater, LabelNumberType} from "../types";

class CalculatorUtil {
  static getSelectLoanTerms = (intl:IntlShape):LabelNumberType[] => {
    return [
      { value: 30, label: IntlFormatter.formatMessage(intl, 'number_year', { number: 30 }) },
      { value: 20, label: IntlFormatter.formatMessage(intl, 'number_year', { number: 20 }) },
      { value: 15, label: IntlFormatter.formatMessage(intl, 'number_year', { number: 15 }) },
      { value: 10, label: IntlFormatter.formatMessage(intl, 'number_year', { number: 10 }) },
      { value: 5, label: IntlFormatter.formatMessage(intl, 'number_year', { number: 5 }) },
    ]
  };

  static getBarChartOptions = () => {
    return {
      legend: {
        display: false
      },
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      layout: { padding: 0 },
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        borderWidth: 1,
        borderColor: palette.divider,
        backgroundColor: palette.white,
        titleFontColor: palette.text!.primary,
        bodyFontColor: palette.text!.secondary,
        footerFontColor: palette.text!.secondary,
        callbacks: {
          label:function(tooltipItem:FixMeLater, data:FixMeLater){
            let label = data.labels[tooltipItem.index] || '';
            return `${label}: ${parseFloat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).toMoney()}`;
          }
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: function(value:number) {
              return value.toMoney();
            }
          }
        }]
      }
    };
  };

  static getPieChartOptions = () => {
    return {
      legend: {
        display: false
      },
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      cutoutPercentage: 60,
      layout: { padding: 0 },
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        borderWidth: 1,
        borderColor: palette.divider,
        backgroundColor: palette.white,
        titleFontColor: palette.text!.primary,
        bodyFontColor: palette.text!.secondary,
        footerFontColor: palette.text!.secondary,
        callbacks: {
          label:function(tooltipItem:FixMeLater, data:FixMeLater){
            let label = data.labels[tooltipItem.index] || '';
            return `${label}: ${parseFloat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).toMoney()}`;
          }
        }
      }
    };
  }
}

export default CalculatorUtil;