import React, {MouseEvent} from 'react';
import {
  Button,
  Grid,
  Divider,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemText,
  Card,
  withStyles,
  ListItemSecondaryAction,
  MenuItem,
  ListItemIcon,
  Menu,
  CardContent,
  CardHeader,
  Typography,
  WithStyles
} from '@material-ui/core';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {LoanApplicationPage} from "../layouts";
import {ErrorUtil, LoanRev2Util, LoanUtil, LocationUtil, ReduxUtil} from "../../../utils";
import BaseLoanApplicationPage from "./BaseLoanApplicationPage";
import {BorrowerAddressForm} from "../forms";
import _ from "lodash";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {DeleteDialog, ErrorList, Dialog, Fab, Alert, YearsMonthsSummary} from "../../../components";
import {LoanApplicationControls} from "../components";
import {ReduxState} from "../../../data/initialState";
import {
  LoanApplication,
  Borrower,
  BorrowerAddress,
  DeletePathsRequest,
} from "@jerseydev/orca-loans";
import {
  ActionProps,
  DialogState,
  ErrorState,
  ReduxLoanApplication,
  ReduxLoanSettings
} from "../../../types";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  loading: boolean,
  borrowerAddressDialogOpen: boolean,
  deleteDialog: DialogState,
  selectedBorrowerIndex: number|null,
  selectedBorrowerAddress: BorrowerAddress|null,
  redirectTo?: string,
  actionMenuEl: Element|null,
  errors: ErrorState[],
  isStepCompleted?: boolean
}

class AddressPage extends BaseLoanApplicationPage<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      borrowerAddressDialogOpen: false,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      selectedBorrowerIndex: null,
      selectedBorrowerAddress: null,
      actionMenuEl: null,
      errors: []
    };
  }

  static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: Readonly<State>){
    if(nextProps.loanApplication && nextProps.loanApplication.data && prevState.isStepCompleted === undefined){
      return {isStepCompleted: LoanRev2Util.isAddressHistoryCompleted(nextProps.loanApplication.data)};
    }
    return null;
  };

  componentWillUnmount = () => {
    const {mixpanel, loanApplication} = this.props;
    if(loanApplication.data && !this.state.isStepCompleted && LoanRev2Util.isAddressHistoryCompleted(loanApplication.data)) {
      mixpanel.track("Loan application step completed", {step: 'address'});
    }
  };

  addBorrowerAddress = (event:MouseEvent, borrowerIndex:number) => {
    event.preventDefault();
    this.setState({ selectedBorrowerIndex: borrowerIndex, borrowerAddressDialogOpen: true });
  };

  onEditBorrowerAddress = () => {
    this.setState({ borrowerAddressDialogOpen: true, actionMenuEl: null });
  };

  onShowDeleteBorrowerAddress = () => {
    this.setState({
      deleteDialog: {
        open: true,
        loading: false,
        errors: []
      },
      actionMenuEl: null
    });
  };

  hideBorrowerAddressDialog = () => {
    this.setState({
      loading: false,
      selectedBorrowerIndex: null,
      selectedBorrowerAddress: null,
      borrowerAddressDialogOpen: false
    });
  };

  onBorrowerAddressSubmit = () => {
    this.hideBorrowerAddressDialog();
  };

  onDeleteAddressCancel = () => {
    this.setState({
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      selectedBorrowerAddress: null,
      selectedBorrowerIndex: null
    })
  };

  onDeleteAddressSubmit = async () => {
    const deleteDialog = _.clone(this.state.deleteDialog);

    try {
      deleteDialog.loading = true;
      this.setState({ deleteDialog });
      const {loanApplication} = this.props;
      const {selectedBorrowerIndex, selectedBorrowerAddress} = this.state;
      if(loanApplication.data.borrowers && selectedBorrowerIndex !== null && loanApplication.data.borrowers[selectedBorrowerIndex] && selectedBorrowerAddress) {
        const addresses = loanApplication.data.borrowers[selectedBorrowerIndex].addresses;
        if(addresses) {
          const index = addresses.findIndex(e => e._id === selectedBorrowerAddress._id);
          const requestData:DeletePathsRequest = [`borrowers[${selectedBorrowerIndex}].addresses[${index}]`];
          await this.props.routeProps.updateLoanApplication(loanApplication.data._id, requestData, {merge:'delete'}).send();
        }
      }

      deleteDialog.loading = false;
      deleteDialog.open = false;
      this.setState({
        deleteDialog,
        selectedBorrowerAddress: null,
        selectedBorrowerIndex: null
      });
    } catch (e) {
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({deleteDialog});
    }
  };

  onNextClick = () => {
    this.setState({ redirectTo: this.nextMenuItem.to });
  };

  onShowActionMenu = (event:MouseEvent, borrowerIndex:number, form:BorrowerAddress) => {
    this.setState({ actionMenuEl: event.currentTarget, selectedBorrowerIndex: borrowerIndex, selectedBorrowerAddress: form });
  };

  onCloseActionMenu = () => {
    this.setState({ actionMenuEl: null, selectedBorrowerIndex: null, selectedBorrowerAddress: null });
  };

  getAddressSecondaryText = (form:BorrowerAddress) => {
    const { intl } = this.props;

    let intlKey:string;
    if((form.current === null || form.current === undefined) && form.isMailingAddress) {
      intlKey = 'mailing_address';
    } else {
      if(form.current) {
        intlKey = form.isMailingAddress ? 'current_address_mailing_address' : 'current_address';
      } else {
        intlKey = form.isMailingAddress ? 'previous_address_mailing_address' : 'previous_address';
      }
    }

    return IntlFormatter.formatMessage(intl, intlKey);
  };

  renderIncompleteWarnings = (loanApplication:LoanApplication, borrower:Borrower) => {
    const warnings:React.ReactNodeArray = [];
    const totalYearsAtAddresses = LoanUtil.getBorrowerTotalYearsAtAddresses(loanApplication, borrower);
    const hasMailingAddress = borrower.addresses ? !!borrower.addresses.find(a => a.isMailingAddress) : false;
    const hasCurrentAddress = borrower.addresses ? !!borrower.addresses.find(a => a.current) : false;

    if(totalYearsAtAddresses < 2) {
      warnings.push(
        <div key={borrower._id}>
          <Typography variant="caption">
            <FormattedMessage id="two_years_residence" />.
          </Typography>
        </div>
      )
    }

    if(!hasMailingAddress) {
      warnings.push(
        <div key={`${borrower._id}-mailing-form`}>
          <Typography variant="caption">
            <FormattedMessage id="mailing_address_is_required" />.
          </Typography>
        </div>
      )
    }

    if(!hasCurrentAddress) {
      warnings.push(
        <div key={`${borrower._id}-current-form`}>
          <Typography variant="caption">
            <FormattedMessage id="current_address_is_required" />.
          </Typography>
        </div>
      )
    }

    if(warnings.length === 0) {
      return null;
    }

    return (
      <Alert severity="warning">
        {warnings}
      </Alert>
    )
  };

  render() {

    const { intl, classes, loanApplication } = this.props;
    const { loading, borrowerAddressDialogOpen, deleteDialog, selectedBorrowerIndex, selectedBorrowerAddress, redirectTo, errors, actionMenuEl } = this.state;


    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <LoanApplicationPage menuId="address"
                           loading={loading}>
        <Dialog open={borrowerAddressDialogOpen}
                title={IntlFormatter.formatMessage(intl, selectedBorrowerAddress ? 'edit_borrower_address' : 'add_borrower_address')}
                icon={<Icon>markunread_mailbox</Icon>}
                color="primaryAlt"
                onClose={this.hideBorrowerAddressDialog}
                fullWidth={true}
                maxWidth="md">
          <div>
            {(ReduxUtil.hasData(loanApplication) && selectedBorrowerIndex !== null) &&
              <BorrowerAddressForm address={selectedBorrowerAddress}
                                   loanApplication={loanApplication.data}
                                   borrower={loanApplication.data.borrowers![selectedBorrowerIndex]}
                                   onSubmit={this.onBorrowerAddressSubmit}
                                   actions={[
                                     <Button onClick={this.hideBorrowerAddressDialog}>
                                       <FormattedMessage id="cancel" />
                                     </Button>
                                   ]} />
            }
          </div>
        </Dialog>
        <DeleteDialog open={deleteDialog.open}
                      loading={deleteDialog.loading}
                      errors={deleteDialog.errors}
                      title={IntlFormatter.formatMessage(intl, 'delete_address')}
                      item={selectedBorrowerAddress ? selectedBorrowerAddress.street1 : ''}
                      onCancel={this.onDeleteAddressCancel}
                      onSubmit={this.onDeleteAddressSubmit} />

        <Menu anchorEl={actionMenuEl}
              open={Boolean(actionMenuEl)}
              onClose={this.onCloseActionMenu}>

          <MenuItem onClick={this.onEditBorrowerAddress}>
            <ListItemIcon>
              <Icon>mode_edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
          </MenuItem>
          <MenuItem onClick={this.onShowDeleteBorrowerAddress}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')} />
          </MenuItem>
        </Menu>

        <div>
          <div className={classes.content}>
            <ErrorList errors={errors}
                       className={classes.mv2}
                       onClose={() => { this.setState({ errors: [] }); } } />

            {(ReduxUtil.hasData(loanApplication) && loanApplication.data!.borrowers) &&
              <div>
                <Grid container spacing={4}>
                  {(loanApplication.data! as LoanApplication).borrowers!.map((borrower, borrowerIndex) => {
                    return (
                      // @ts-ignore
                      <Grid key={borrowerIndex} item xs={12} sm={12} md={loanApplication.data.borrowers ? loanApplication.data.borrowers.length === 1 ? 6 : Math.floor(12 / loanApplication.data.borrowers.length) : 12}>
                        <Card className={classes.card}>
                          <CardHeader title={`${borrower.firstName} ${borrower.lastName}`}
                                      subheader={IntlFormatter.formatMessage(intl, borrower.primaryBorrower ? 'borrower' : 'co_borrower')}
                                      avatar={<Icon>{borrower.primaryBorrower ? 'person' : 'person_outline'}</Icon>}
                                      action={
                                        <Fab color="primary"
                                             size="small"
                                             flat
                                             rounded
                                             onClick={event => this.addBorrowerAddress(event, borrowerIndex)}>
                                          <Icon>add</Icon>
                                        </Fab>
                                      } />
                          <Divider />
                          <CardContent>
                            <div>
                              {this.renderIncompleteWarnings(loanApplication.data, borrower)}

                              <div>
                                {borrower.addresses && borrower.addresses.length > 0 &&
                                  <List>
                                    {borrower.addresses.map((address, i) => {
                                      return (
                                        <ListItem key={i}>
                                          <div>
                                            <Typography variant="body1">
                                              {LocationUtil.formatLocation(address)}
                                            </Typography>
                                            <Typography variant="caption">
                                              {this.getAddressSecondaryText(address)}
                                            </Typography>
                                            {address.timeAtLocation &&
                                              <div>
                                                <YearsMonthsSummary years={address.timeAtLocation.years}
                                                                    months={address.timeAtLocation.months}
                                                                    variant="caption" />
                                              </div>
                                            }
                                          </div>
                                          <ListItemSecondaryAction>
                                            <IconButton onClick={event => this.onShowActionMenu(event, borrowerIndex, address)}>
                                              <Icon>more_vert</Icon>
                                            </IconButton>
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                      )
                                    })}
                                  </List>
                                }

                                {(!borrower.addresses || (borrower.addresses && borrower.addresses.length === 0)) &&
                                  <div className={classes.mv2}>
                                    <Button variant="outlined"
                                            color="primary"
                                            onClick={event => this.addBorrowerAddress(event, borrowerIndex)}>
                                      <FormattedMessage id="add_address" />
                                    </Button>
                                  </div>
                                }
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                  })}
                </Grid>
              </div>
            }

          </div>

          <div className={classes.content}>
            <LoanApplicationControls prev={this.previousMenuItem}
                                     next={{...this.nextMenuItem }}
                                     onNextClick={this.onNextClick} />
          </div>
        </div>
      </LoanApplicationPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    settings: state.loanSettings
  };
};


export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(AddressPage)));
