import React, {MouseEvent} from 'react';
import {
  AppBar as MUIAppBar,
  MenuItem,
  IconButton,
  Divider,
  Icon,
  Badge,
  Toolbar,
  ListItemIcon,
  ListItemText,
  Hidden,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  Button,
  Grid, WithStyles,
} from '@material-ui/core';
import {Redirect, Link, withRouter, RouteComponentProps} from 'react-router-dom';
import {connect} from "react-redux";
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import config from '../config';
import {logout} from '../actions/auth';
import {withStyles} from '@material-ui/core/styles';
import {Snackbar, UserAvatar, Dialog, Fab, SetupRecommendations, LoanOfficerProfileIncompleteAlert} from "./index";
import {AclUtil, DateUtil, ReduxUtil, RouteUtil, TextUtil, UserUtil} from "../utils";
import styles from '../theme/jss/components/appBarStyles';
import {getNotifications, updateNotification} from "../actions/notifications";
import {InviteApplicantForm, ResendApplicantInviteForm, SecureMessageThreadForm} from "../forms";
import IntlFormatter from "../intl";
import clsx from "clsx";
import {MixpanelConsumer} from "react-mixpanel";
import {ActionResponse, ReduxNotifications, ReduxUser, SnackbarState} from "../types";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ReduxState} from "../data/initialState";
import {Notification, NotificationUpdateRequest, SecureMessage} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  onMenuButtonPress?: () => void,
  user: ReduxUser,
  notifications: ReduxNotifications,
  getNotifications: () => ActionResponse,
  updateNotification: (id:string, data:NotificationUpdateRequest) => ActionResponse,
  logout: () => void
} & WrappedComponentProps
  & WithStyles<typeof styles>
  & RouteComponentProps

type State = {
  accountMenuEl?: Element,
  notificationsAnchorEl?: Element,
  addAnchorEl?: Element,
  redirectTo?: string,
  inviteApplicantDialogOpen: boolean,
  resendInviteDialogOpen: boolean,
  messageDialogOpen: boolean,
  snackbar?: SnackbarState,
  secureMessage?: SecureMessage
}

class AppBar extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      inviteApplicantDialogOpen: false,
      resendInviteDialogOpen: false,
      messageDialogOpen: false
    };
  }

  /*componentDidMount = () => {
    if(!ReduxUtil.hasData(this.props.notifications) && ReduxUtil.isIdle(this.props.notifications)) {
      this.props.getNotifications().send().then(() => {

      }).catch(err => {
        // @todo handle error
        console.log(err);
      });
    }
  };*/

  onMenuButtonPress = () => {
    if(this.props.onMenuButtonPress) {
      this.props.onMenuButtonPress();
    }
  };

  toggleAccountMenuClick = (event:MouseEvent) => {
    this.setState({ accountMenuEl: event.currentTarget });
  };

  toggleAccountMenuClose = () => {
    this.setState({ accountMenuEl: undefined });
  };

  onNotificationsClick = (event:MouseEvent) => {
    this.setState({ notificationsAnchorEl: event.currentTarget });
  };

  onNotificationsClose = () => {
    this.setState({ notificationsAnchorEl: undefined });
  };

  onAddClick = (event:MouseEvent) => {
    this.setState({ addAnchorEl: event.currentTarget });
  };

  onAddClose = () => {
    this.setState({ addAnchorEl: undefined });
  };

  logout = (event:MouseEvent) => {
    event.preventDefault();
    this.props.logout();
    this.setState({ redirectTo: '/login' });
  };

  onInviteApplicantClick = () => {
    this.setState({ inviteApplicantDialogOpen: true, addAnchorEl: undefined });
  };

  onInviteApplicantSubmit = () => {
    const snackbar:SnackbarState = {
      open: true,
      variant: 'success',
      message: IntlFormatter.formatMessage(this.props.intl, 'invitation_sent')
    };
    this.setState({ inviteApplicantDialogOpen: false, snackbar });
  };

  onInviteApplicantCancel = () => {
    this.setState({ inviteApplicantDialogOpen: false });
  };

  onResendInviteClick = () => {
    this.setState({ resendInviteDialogOpen: true, addAnchorEl: undefined });
  };

  onResendInviteSubmit = () => {
    const snackbar:SnackbarState = {
      open: true,
      variant: 'success',
      message: IntlFormatter.formatMessage(this.props.intl, 'invitation_sent')
    };
    this.setState({ resendInviteDialogOpen: false, snackbar });
  };

  onResendInviteCancel = () => {
    this.setState({ resendInviteDialogOpen: false });
  };

  onSnackbarClose = () => {
    this.setState({ snackbar: undefined });
  };

  onShowMessageDialog = () => {
    this.setState({ messageDialogOpen: true, addAnchorEl: undefined });
  };

  onHideMessageDialog = () => {
    this.setState({ messageDialogOpen: false, secureMessage: undefined });
  };

  onMessageSubmit = () => {
    const snackbar:SnackbarState = {
      open: true,
      variant: 'success',
      message: IntlFormatter.formatMessage(this.props.intl, 'message_sent')
    };
    this.setState({ messageDialogOpen: false, snackbar });
  };

  getApplyLink = () => {
    let applyLink = '/apply';

    if(AclUtil.hasRole(this.props.user.data, 'ROLE_LOAN_OFFICER')) {
      applyLink += `?loanOfficer=${encodeURIComponent(this.props.user.data.email)}`;
    }

    return applyLink;
  };
  
  onNotificationClick = async (notification:Notification) => {
    try {
      await this.props.updateNotification(notification._id, { status: 'read' });
      if(notification.url) {
        if(notification.url.includes('http')) {
          window.open(notification.url, '_blank');
        } else {
          this.setState({ redirectTo: notification.url });
        }
      }
    } catch(err) {
      console.log(err);
      // @todo handle error

    }
  };

  render() {
    const { intl, classes, children, onMenuButtonPress, user, notifications } = this.props;
    const { redirectTo, accountMenuEl, notificationsAnchorEl, addAnchorEl, inviteApplicantDialogOpen, snackbar, messageDialogOpen, resendInviteDialogOpen } = this.state;
    const unreadNotifications = ReduxUtil.hasData(notifications) ? notifications.data.filter(n => n.status === 'unread') : [];

    if (redirectTo) {
      return (
        <Redirect to={redirectTo}/>
      )
    }

    return (
      <div className={classes.root}>
        <MixpanelConsumer>
          {(mixpanel:Mixpanel) => {
            return (
              <div>
                <Snackbar open={!!snackbar}
                          variant={snackbar ? snackbar.variant : 'success'}
                          onClose={this.onSnackbarClose}
                          message={snackbar ? snackbar.message : ''}
                          action={[
                            <IconButton
                              key="close"
                              aria-label="close"
                              color="inherit"
                              onClick={this.onSnackbarClose}>
                              <Icon>close</Icon>
                            </IconButton>
                          ]} />
                <Dialog open={inviteApplicantDialogOpen}
                        onClose={this.onInviteApplicantCancel}
                        fullWidth={true}
                        maxWidth="sm"
                        variant="confirm"
                        color="primaryAlt"
                        title={IntlFormatter.formatMessage(intl, 'invite_borrower')}
                        icon={<Icon>person</Icon>}>
                    <InviteApplicantForm mixpanel={mixpanel}
                                         onSubmit={this.onInviteApplicantSubmit}
                                         onCancel={this.onInviteApplicantCancel} />
                </Dialog>

                <Dialog open={resendInviteDialogOpen}
                        onClose={this.onResendInviteCancel}
                        fullWidth={true}
                        maxWidth="sm"
                        variant="confirm"
                        color="primaryAlt"
                        title={IntlFormatter.formatMessage(intl, 'resend_borrower_invite')}
                        icon={<Icon>person</Icon>}>
                  <ResendApplicantInviteForm mixpanel={mixpanel}
                                             onSubmit={this.onResendInviteSubmit}
                                             onCancel={this.onResendInviteCancel} />
                </Dialog>

                <Dialog open={messageDialogOpen}
                        title={IntlFormatter.formatMessage(intl, 'send_message')}
                        icon={<Icon>messages</Icon>}
                        color="primaryAlt"
                        onClose={this.onHideMessageDialog}
                        fullWidth={true}
                        maxWidth="sm">
                  <SecureMessageThreadForm mixpanel={mixpanel}
                                           onCancel={this.onHideMessageDialog}
                                           onSubmit={this.onMessageSubmit}/>
                </Dialog>

                <MUIAppBar variant="elevation" className={onMenuButtonPress ? classes.appBar : undefined}>

                  {children}
                  <Toolbar style={{alignItems: 'center', justifyContent: onMenuButtonPress ? 'space-between' : 'flex-end'}}>
                    <div className={classes.row}>
                      {onMenuButtonPress &&
                        <div>
                          <Hidden mdUp implementation="css">
                            <IconButton color="inherit" aria-label="Menu" onClick={this.onMenuButtonPress}>
                              <Icon>menu</Icon>
                            </IconButton>
                          </Hidden>
                        </div>
                      }
                    </div>

                    <div className={classes.rightContainer}>
                      <div className={classes.itemsContainer}>
                        {AclUtil.isOwner(user.data) &&
                          <SetupRecommendations className={classes.itemContainer} />
                        }
                        {unreadNotifications.length > 0 &&
                          <div className={classes.itemContainer}>
                            <Badge badgeContent={unreadNotifications.length} color="error">
                              <Fab color="successAlt"
                                   onClick={this.onNotificationsClick}
                                   size="small"
                                   rounded
                                   flat>
                                <Icon>notifications_active</Icon>
                              </Fab>
                            </Badge>
                            <Popover open={Boolean(notificationsAnchorEl)}
                                     anchorEl={notificationsAnchorEl}
                                     onClose={this.onNotificationsClose}
                                     anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'right',
                                     }}
                                     transformOrigin={{
                                       vertical: 'top',
                                       horizontal: 'right',
                                     }}>

                              <div className={classes.notificationsHeader}>
                                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                  <Grid item>
                                    <Typography variant="h5">
                                      <FormattedMessage id="notifications" />
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Button variant="outlined" size="small" color="primary">
                                      <FormattedMessage id="mark_all_read" />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </div>
                              <Divider/>

                              {unreadNotifications.length === 0 &&
                                <List>
                                  <ListItem>
                                    <ListItemText primary={IntlFormatter.formatMessage(intl, 'no_new_notifications')} />
                                  </ListItem>
                                </List>
                              }
                              <List>
                                {unreadNotifications.map((notification, i) => {
                                  return (
                                    <div key={i}>
                                      <MenuItem onClick={() => this.onNotificationClick(notification)} button>
                                        <ListItemIcon>
                                          <Icon className={
                                            clsx(classes.notificationIcon,
                                            // @ts-ignore
                                            classes[`notificationIcon${TextUtil.ucWords(notification.level)}`])}>message</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={notification.message} secondary={DateUtil.formatDate(notification.created)} />
                                      </MenuItem>
                                      {unreadNotifications.length - 1 < i &&
                                      <Divider />
                                      }
                                    </div>
                                  )
                                })}
                              </List>
                            </Popover>
                          </div>
                        }

                        {(this.props.location.pathname !== '/account' && user.data.additionalProps && user.data.additionalProps.loanOfficer) &&
                          <LoanOfficerProfileIncompleteAlert loanOfficer={user.data.additionalProps.loanOfficer}
                                                             className={classes.itemContainer} />
                        }

                        {UserUtil.isGranted(user.data, ['ROLE_SUPER_ADMIN', 'ROLE_OWNER', 'ROLE_LOAN_OFFICER', 'ROLE_BRANCH_MANAGER']) &&
                          <div className={classes.itemContainer}>
                            <Fab color="infoAlt"
                                 onClick={this.onAddClick}
                                 size="small"
                                 rounded
                                 flat>
                              <Icon>add_circle_outline</Icon>
                            </Fab>
                            <Popover open={Boolean(addAnchorEl)}
                                     anchorEl={addAnchorEl}
                                     onClose={this.onAddClose}
                                     anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'right',
                                     }}
                                     transformOrigin={{
                                       vertical: 'top',
                                       horizontal: 'right',
                                     }}>
                              <List>
                                {UserUtil.isGranted(user.data, ['ROLE_OWNER', 'ROLE_LOAN_OFFICER', 'ROLE_BRANCH_MANAGER']) &&
                                  <MenuItem onClick={this.onInviteApplicantClick}>
                                    <ListItemAvatar>
                                      <Icon>mail</Icon>
                                    </ListItemAvatar>
                                    <ListItemText primary={IntlFormatter.formatMessage(intl, 'invite_borrower')} />
                                  </MenuItem>
                                }
                                {UserUtil.isGranted(user.data, ['ROLE_OWNER', 'ROLE_LOAN_OFFICER', 'ROLE_BRANCH_MANAGER']) &&
                                <MenuItem onClick={this.onResendInviteClick}>
                                  <ListItemAvatar>
                                    <Icon>forward_to_inbox</Icon>
                                  </ListItemAvatar>
                                  <ListItemText primary={IntlFormatter.formatMessage(intl, 'resend_borrower_invite')} />
                                </MenuItem>
                                }
                                <MenuItem component={Link} to={this.getApplyLink()}>
                                  <ListItemAvatar>
                                    <Icon>library_books</Icon>
                                  </ListItemAvatar>
                                  <ListItemText primary={IntlFormatter.formatMessage(intl, 'new_loan_application')} />
                                </MenuItem>
                                <MenuItem onClick={this.onShowMessageDialog}>
                                  <ListItemAvatar>
                                    <Icon>message</Icon>
                                  </ListItemAvatar>
                                  <ListItemText primary={IntlFormatter.formatMessage(intl, 'send_message')} />
                                </MenuItem>
                                {UserUtil.isGranted(user.data, ['ROLE_SUPER_ADMIN', 'ROLE_OWNER']) &&
                                  <MenuItem component={Link} to="/admin/users/add">
                                    <ListItemAvatar>
                                      <Icon>people</Icon>
                                    </ListItemAvatar>
                                    <ListItemText primary={IntlFormatter.formatMessage(intl, 'add_user')} />
                                  </MenuItem>
                                }
                                {UserUtil.isGranted(user.data, ['ROLE_SUPER_ADMIN', 'ROLE_OWNER']) &&
                                <MenuItem component={Link} to="/admin/loan-officers/add">
                                  <ListItemAvatar>
                                    <Icon>people_outline</Icon>
                                  </ListItemAvatar>
                                  <ListItemText primary={IntlFormatter.formatMessage(intl, 'add_loan_officer')} />
                                </MenuItem>
                                }
                                {UserUtil.isGranted(user.data, ['ROLE_SUPER_ADMIN', 'ROLE_OWNER']) &&
                                <MenuItem component={Link} to="/admin/branches/add">
                                  <ListItemAvatar>
                                    <Icon>business</Icon>
                                  </ListItemAvatar>
                                  <ListItemText primary={IntlFormatter.formatMessage(intl, 'add_branch')} />
                                </MenuItem>
                                }
                              </List>
                            </Popover>
                          </div>
                        }
                      </div>

                      <div>
                      {ReduxUtil.hasData(user) &&
                        <div className={classes.userMenu} onClick={this.toggleAccountMenuClick}>
                          <UserAvatar user={user.data}
                                      className={classes.userMenuAvatar}
                                      popoverEnabled={false}
                                      variant="square" />
                          <Hidden smDown>
                            <div className={classes.userMenuTextContainer}>
                              <div>
                                <Typography variant="body1"
                                            color="inherit"
                                            className={classes.userMenuText}>
                                  {UserUtil.getFullName(user.data)}
                                </Typography>
                              </div>
                              <Typography variant="body2"
                                          color="inherit"
                                          className={classes.userMenuSecondaryText}>
                                {UserUtil.getPrimaryRole(user.data)!.name} {/* Fix this so it has a null check */}
                              </Typography>
                            </div>
                          </Hidden>
                          <Icon color="inherit" className={classes.userMenuArrow}>keyboard_arrow_down</Icon>
                        </div>
                      }

                      <Popover open={Boolean(accountMenuEl)}
                               anchorEl={accountMenuEl}
                               anchorOrigin={{
                                 vertical: 'bottom',
                                 horizontal: 'right',
                               }}
                               transformOrigin={{
                                 vertical: 'top',
                                 horizontal: 'right',
                               }}
                               className={classes.userPopper}
                               onClose={this.toggleAccountMenuClose}>
                        <List>
                          {config.menus.appMenu.map((item, i) => {
                            if(!RouteUtil.isGranted(user.data, item.to)) {
                              return null;
                            }
                            return (
                              <Link key={i} to={item.to}>
                                <MenuItem>
                                  <ListItemIcon>
                                    <Icon>{item.icon}</Icon>
                                  </ListItemIcon>
                                  <ListItemText primary={<FormattedMessage id={item.labelId} />} />
                                </MenuItem>
                              </Link>
                            )
                          })}
                        </List>
                        <Divider />
                        <List>
                          <MenuItem onClick={this.logout}>
                            <ListItemIcon>
                              <Icon color="error">power_settings_new</Icon>
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="sign_out" />} />
                          </MenuItem>
                        </List>
                      </Popover>
                      </div>
                    </div>

                  </Toolbar>
                </MUIAppBar>
              </div>
            )
          }}
        </MixpanelConsumer>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => ({
  user: state.user,
  notifications: state.notifications
});

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getNotifications() {
    return dispatch(getNotifications())
  },
  updateNotification(id:string, data:NotificationUpdateRequest) {
    return dispatch(updateNotification(id, data))
  },
  logout() {
    dispatch(logout())
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(AppBar))));