import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  withStyles,
  Button,
  Stepper, Step, StepLabel, FormGroup, FormControlLabel, Checkbox, FormControl, Divider, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {SubmitButton, TextField, PasswordField, ErrorList} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {updateLosIntegration} from "../../../actions/integrations";
import {AccountUtil, ErrorUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {FixMeLater, ReduxApp, ActionProps, ErrorState, ReduxAccount} from "../../../types";
import {AxiosResponse} from "axios";
import {AccountRequest, EncompassSettings, LosIntegration, LosIntegrationRequest} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateAccount} from "../../../actions/account";
import {Mixpanel} from "mixpanel-browser";
import EncompassApi from "../../../lib/EncompassApi";
import {EncompassLoanTemplateSelect} from "../components";
import {EncompassLoanTemplateItem} from "../components/EncompassLoanTemplateViewer";

type Props = {
  mixpanel: Mixpanel,
  settings?: LosIntegration|null,
  onSubmit: (data:AxiosResponse<LosIntegration>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  account: ReduxAccount,
  updateLosIntegration: ActionProps["updateLosIntegration"],
  updateAccount: ActionProps["updateAccount"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = EncompassSettings;

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  activeStep: number,
  personas: FixMeLater[],
}

type FormMap = {
  encompass: () => void
}

class LosSettingsForm extends BaseForm<Props, State> {
  formMap:FormMap;

  constructor(props:Props) {
    super(props);

    let form:Form = {
      clientId: '',
      clientSecret: '',
      instanceId: '',
      username: '',
      password: '',
      loanFolder: '',
      loanOfficerPersonas: [],
      loanTemplates: {
        purchase: {
          conventional: '',
          fha: '',
          "usda-rd": '',
          va: '',
          other: ''
        },
        refinance: {
          conventional: '',
          fha: '',
          "usda-rd": '',
          va: '',
          other: ''
        }
      }
    }

    if(props.settings) {
      const {clientId, clientSecret, instanceId, username, password, loanFolder, loanOfficerPersonas, loanTemplates} = props.settings.data;
      form = {
        clientId,
        clientSecret,
        instanceId,
        username,
        password,
        loanFolder,
        loanOfficerPersonas,
        loanTemplates: {
          purchase: {
            conventional: loanTemplates && loanTemplates.purchase && loanTemplates.purchase.conventional ? loanTemplates.purchase.conventional : '',
            fha: loanTemplates && loanTemplates.purchase && loanTemplates.purchase.fha ? loanTemplates.purchase.fha : '',
            "usda-rd": loanTemplates && loanTemplates.purchase && loanTemplates.purchase['usda-rd'] ? loanTemplates.purchase['usda-rd'] : '',
            va: loanTemplates && loanTemplates.purchase && loanTemplates.purchase.va ? loanTemplates.purchase.va : '',
            other: loanTemplates && loanTemplates.purchase && loanTemplates.purchase.other ? loanTemplates.purchase.other : '',
          },
          refinance: {
            conventional: loanTemplates && loanTemplates.refinance && loanTemplates.refinance.conventional ? loanTemplates.refinance.conventional : '',
            fha: loanTemplates && loanTemplates.refinance && loanTemplates.refinance.fha ? loanTemplates.refinance.fha : '',
            "usda-rd": loanTemplates && loanTemplates.refinance && loanTemplates.refinance['usda-rd'] ? loanTemplates.refinance['usda-rd'] : '',
            va: loanTemplates && loanTemplates.refinance && loanTemplates.refinance.va ? loanTemplates.refinance.va : '',
            other: loanTemplates && loanTemplates.refinance && loanTemplates.refinance.other ? loanTemplates.refinance.other : '',
          }
        }
      }
    }

    this.state = {
      loading: false,
      form,
      activeStep: 0,
      personas: [],
      errors: []
    };
  }


  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const {clientId, clientSecret, instanceId, username, password, loanFolder, loanOfficerPersonas, loanTemplates} = this.state.form;
      const requestData:LosIntegrationRequest = {
        provider: 'encompass',
        data: {
          clientId: clientId.trim(),
          clientSecret: clientSecret.trim(),
          instanceId: instanceId.trim(),
          username: username.trim(),
          password: password.trim(),
          loanFolder: loanFolder.trim(),
          loanOfficerPersonas,
          loanTemplates: {
            purchase: {
              conventional: loanTemplates!.purchase!.conventional !== '' ? loanTemplates!.purchase!.conventional : null,
              fha: loanTemplates!.purchase!.fha !== '' ? loanTemplates!.purchase!.fha : null,
              "usda-rd": loanTemplates!.purchase!['usda-rd'] !== '' ? loanTemplates!.purchase!['usda-rd'] : null,
              va: loanTemplates!.purchase!.va !== '' ? loanTemplates!.purchase!.va : null,
              other: loanTemplates!.purchase!.other !== '' ? loanTemplates!.purchase!.other : null
            },
            refinance: {
              conventional: loanTemplates!.refinance!.conventional !== '' ? loanTemplates!.refinance!.conventional : null,
              fha: loanTemplates!.refinance!.fha !== '' ? loanTemplates!.refinance!.fha : null,
              "usda-rd": loanTemplates!.refinance!['usda-rd'] !== '' ? loanTemplates!.refinance!['usda-rd'] : null,
              va: loanTemplates!.refinance!.va !== '' ? loanTemplates!.refinance!.va : null,
              other: loanTemplates!.refinance!.other !== '' ? loanTemplates!.refinance!.other : null
            }
          }
        }
      };

      const result = await this.props.updateLosIntegration(requestData).send();
      this.props.mixpanel.track(this.props.settings ? 'LOS integration updated' : 'LOS integration created');
      if(!AccountUtil.isSetupRecommendationUsed('integrations', this.props.account.data)) {
        let requestData:AccountRequest = AccountUtil.addSetupRecommendationToRequest('integrations', {}, this.props.account.data);
        await this.props.updateAccount(requestData).send();
      }
      this.setState({ loading: false }, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onNextStepClick = async () => {
    this.setState({ loading: true });
    const nextStep:number = this.state.activeStep + 1;
    const state:any = { activeStep: nextStep };

    if(nextStep === 1) {
      try {
        // connect to LOS and fetch the information needed. loan templates, personas etc
        const {clientId, clientSecret, instanceId, username, password} = this.state.form;
        const tokenResult = await EncompassApi.getToken(clientId, clientSecret, instanceId, username, password);
        EncompassApi.token = tokenResult.data.access_token;
        const personasResult = await EncompassApi.getPersonas();
        state.personas = personasResult.data;
        state.loading = false;
        this.setState(state);
      } catch (e) {
        let errors:ErrorState[];
        if(e.response && e.response.data && e.response.data.error_description) {
          errors = [{message:e.response.data.error_description}];
        } else {
          errors = ErrorUtil.formatErrors(e);
        }
        this.setState({loading:false, errors})
      }
    } else {
      state.loading = false;
      this.setState(state);
    }
  };

  onPreviousStepClick = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  onArrayCheckboxChange = (event:ChangeEvent<{value:any}>, key:string) => {
    const value = event.target.value;
    let state = _.cloneDeep(this.state);
    let data = _.get(state, key);
    if(!data) {
      data = [];
    }

    const index = data.indexOf(value);
    if(index === -1) {
      data.push(value);
    } else {
      data.splice(index, 1);
    }

    state = _.set(state, key, data);
    this.setState(state);
  };

  onLoanTemplateChange = (key:string, item:EncompassLoanTemplateItem) => {
    const state:State = _.cloneDeep(this.state);
    _.set(state, key, decodeURIComponent(item.entityUri || ''));
    this.setState(state);
  }

  render() {

    const { intl, classes, app } = this.props;
    const { form, loading, errors, activeStep, personas } = this.state;
    const loanPurposes = ['purchase', 'refinance'];

    return (
      <div>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step>
            <StepLabel>
              <FormattedMessage id="connect" />
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <FormattedMessage id="settings" />
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <FormattedMessage id="loan_officer_personas" />
            </StepLabel>
          </Step>
        </Stepper>

        <div>
          <ErrorList errors={errors}
                     className={classes.mv2} />
          {activeStep === 0 &&
          <div>
            <Form onSubmit={this.onNextStepClick}>
              <div className={classes.mb2}>
                <TextField name="encompassClientId"
                           label={IntlFormatter.formatMessage(intl, 'client_id')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.clientId')}
                           value={form.clientId}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
              </div>
              <div className={classes.mb2}>
                <TextField name="encompassApiSecret"
                           label={IntlFormatter.formatMessage(intl, 'api_secret')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.clientSecret')}
                           value={form.clientSecret}
                           fullWidth={true}
                           validators={['required']}
                           errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
              </div>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField name="encompassInstanceId"
                               label={IntlFormatter.formatMessage(intl, 'instance_id')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.instanceId')}
                               value={form.instanceId}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField name="encompassUsername"
                               label={IntlFormatter.formatMessage(intl, 'username')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.username')}
                               value={form.username}
                               fullWidth={true}
                               validators={['required']}
                               errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <PasswordField name="encompassPassword"
                                   label={IntlFormatter.formatMessage(intl, 'password')}
                                   onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.password')}
                                   value={form.password}
                                   fullWidth={true}
                                   validators={['required']}
                                   errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                  </Grid>
                </Grid>
              </div>

              <div className={classes.mt2}>
                <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                  {this.props.onCancel &&
                  <Grid item>
                    <Button onClick={this.props.onCancel}>
                      <FormattedMessage id="cancel" />
                    </Button>
                  </Grid>
                  }
                  <Grid item>
                    <SubmitButton loading={loading}>
                      <FormattedMessage id="next" />
                    </SubmitButton>
                  </Grid>
                </Grid>
              </div>
            </Form>
          </div>
          }
          {activeStep === 1 &&
          <Form onSubmit={this.onNextStepClick}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField name="encompassLoanFolder"
                           label={IntlFormatter.formatMessage(intl, 'loan_folder')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loanFolder')}
                           value={form.loanFolder}
                           helperText={IntlFormatter.formatMessage(intl, 'encompass_loan_folder_help')}
                           fullWidth={true} />
              </Grid>
            </Grid>

            {loanPurposes.map((purpose, i) => {
              return (
                <div key={i}>
                  {app.data.enums.loanTypes.map((type, i) => {
                    return (
                      <div key={i}>
                        <div className={classes.pv2}>
                          <EncompassLoanTemplateSelect title={IntlFormatter.formatMessage(intl, `${purpose}_loan_template_${type}`)}
                                                       templatePath={form.loanTemplates![purpose as keyof Form["loanTemplates"]][type]}
                                                       clientId={form.clientId}
                                                       clientSecret={form.clientSecret}
                                                       instanceId={form.instanceId}
                                                       username={form.username}
                                                       password={form.password}
                                                       onTemplateClick={(item) => this.onLoanTemplateChange(`form.loanTemplates.${purpose}.${type}`, item)} />
                        </div>
                        <Divider />
                      </div>
                    )
                  })}

                </div>
              );
            })}

            <div className={classes.mt2}>
              <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item>
                  <Button onClick={this.onPreviousStepClick}>
                    <FormattedMessage id="back" />
                  </Button>
                </Grid>
                <Grid item>
                  <SubmitButton loading={loading}>
                    <FormattedMessage id="next" />
                  </SubmitButton>
                </Grid>
              </Grid>
            </div>
          </Form>
          }

          {activeStep === 2 &&
          <Form onSubmit={this.onSubmit}>
            <FormControl component="fieldset">
              <FormGroup>

                {personas.map((persona:FixMeLater, i:number) => {
                  return (
                    <FormControlLabel key={i}
                                      label={persona.name}
                                      control={
                                        <Checkbox checked={form.loanOfficerPersonas && form.loanOfficerPersonas.includes(persona.id)}
                                                  value={persona.id}
                                                  onChange={event => this.onArrayCheckboxChange(event, 'form.loanOfficerPersonas')}/>
                                      } />
                  )
                })}
              </FormGroup>
            </FormControl>
            <div className={classes.mt2}>
              <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item>
                  <Button onClick={this.onPreviousStepClick}>
                    <FormattedMessage id="back" />
                  </Button>
                </Grid>
                <Grid item>
                  <SubmitButton loading={loading}>
                    <FormattedMessage id="save" />
                  </SubmitButton>
                </Grid>
              </Grid>
            </div>
          </Form>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLosIntegration(data:LosIntegrationRequest) {
    return dispatch(updateLosIntegration(data));
  },
  updateAccount(data:AccountRequest) {
    return dispatch(updateAccount(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LosSettingsForm)));
