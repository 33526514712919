import axios from 'axios';
import {ErrorState} from "../types";

class ErrorUtil {
  static formatErrors = (error:any) => {
    const errors:ErrorState[] = [];

    const err = error.response ? error.response : error;
    if(process.env.NODE_ENV === 'development') {
      console.log(err);
    }

    if(!axios.isCancel(err)) {
      if(typeof err.error === 'string') {
        errors.push({
          message: err.error
        });
      } else if(err.error && err.error.message) {
        errors.push({
          message: err.error.message
        });
      } else if(err.response && err.response.data && typeof err.response.data.error === 'string') {
        errors.push({
          message: err.response.data.error
        });
      } else if(err.error && err.error.errors) {
        if(typeof err.error.errors === 'object') {
          for(let key in err.error.errors) {
            if(err.error.errors.hasOwnProperty(key)) {
              errors.push({
                message: `'${key}': ${err.error.errors[key].msg}`
              });
            }
          }
        } else {
          errors.push({
            message: err.error.errors
          });
        }
      } else if(err.data && err.data.error && err.data.error.message) {
        errors.push({
          message: err.data.error.message
        });
      } else if(err.data && err.data.errors) {
        for(let key in err.data.errors) {
          if(err.data.errors.hasOwnProperty(key)) {
            errors.push({
              message: `'${key}': ${err.data.errors[key].msg}`
            });
          }
        }
      } else if(err.data && err.data.error && err.data.error.errors) {
        if (typeof err.data.error.errors === 'object') {
          for (let key in err.data.error.errors) {
            if (err.data.error.errors.hasOwnProperty(key)) {
              errors.push({
                message: `'${key}': ${err.data.error.errors[key].msg}`
              });
            }
          }
        } else {
          errors.push({
            message: err.data.error.errors
          });
        }
      } else if(err.data && err.data.errors && err.data.errors.errors) {
        for (let key in err.data.errors.errors) {
          if (err.data.errors.errors.hasOwnProperty(key)) {
            errors.push({
              message: `'${key}': ${err.data.errors.errors[key].msg}`
            });
          }
        }
      } else if(err.data && err.data.errors && err.data.errors.message) {

        let message = err.data.errors.message;
        if (typeof err.data.errors.message === 'object') {
          message = JSON.stringify(err.data.errors.message);
        }
        errors.push({
          message
        });
      } else if(err.errors && typeof err.errors === 'object' && Object.keys(err.errors).length > 0) {
        if(err.errors._error && err.errors._error.nestedErrors) {
          err.errors._error.nestedErrors.forEach((e:any) => {
            errors.push({
              message: `'${e.param}': ${e.msg}`
            });
          });
        } else {
          for(let key in err.errors) {
            if(err.errors.hasOwnProperty(key)) {
              errors.push({
                message: `'${key}': ${err.errors[key].msg}`
              });
            }
          }
        }
      } else if(err.data && err.data.message) {
        errors.push({
          message: err.data.message
        });
      } else {
        errors.push({
          message: err.message ? err.message : 'An error occurred. Please contact support.'
        });
      }
    }

    return errors;
  };
}

export default ErrorUtil;