import React, {Component, MouseEvent} from 'react';
import {
  Icon,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Grid,
  Divider,
  Hidden,
  withStyles,
  List,
  ListItem,
  TableSortLabel,
  Fab,
  Checkbox,
  FormControlLabel,
  WithStyles
} from '@material-ui/core';
import {connect} from 'react-redux';
import {Link as RouterLink, Redirect, RouteComponentProps} from 'react-router-dom';
import {AccountPage} from '../../../layouts';
import {
  UserAvatar,
  SearchBar,
  PageTitle,
  AutoComplete,
  DeleteDialog,
  NoResultsCard,
  LoanStatusBadge,
  ErrorList,
  PaginationHeader,
  Badge,
  SortField,
  Tooltip,
  LoanOfficerProfileReference,
  AddButton,
  Pagination
} from '../../../components';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  ReduxUtil,
  DateUtil,
  SearchUtil,
  LoanUtil,
  ErrorUtil,
  AclUtil,
  TextUtil,
  ObjectUtil,
  IntegrationUtil
} from '../../../utils';
import _ from 'lodash';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import Api from "../../../lib/Api";
import clsx from "clsx";
import {colors} from '@material-ui/core';
import qs from "query-string";
import {
  ActionProps,
  ActionResponse,
  DialogState,
  ErrorState,
  KeyNamePairType,
  LabelValuePairType,
  LoanExportFormat,
  LoanStatusType,
  ReduxApp, ReduxIntegrations,
  ReduxLoanApplications,
  ReduxLoanSettings,
  ReduxToken,
  ReduxUser,
  SearchCriteriaWithPagingType,
  SnackbarState,
  SortDirectionType
} from "../../../types";
import {
  Branch,
  LoanApplication2009, Borrower2009,
  LoanApplication, Borrower,
  LoanApplicationSearchRequest, LoanOfficer
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {AxiosResponse} from "axios";

type Props = {
  routeProps: ActionProps,
  app: ReduxApp,
  user: ReduxUser,
  loanApplications: ReduxLoanApplications,
  settings: ReduxLoanSettings,
  integrations: ReduxIntegrations,
  token: ReduxToken
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type LoanSearchCriteria = {
  branches: Branch[],
  loanOfficers: LoanOfficer[],
  status: KeyNamePairType[],
  completed: boolean,
  owner?: string,
  losFailed?: boolean
} & SearchCriteriaWithPagingType

type State = {
  pageLoaded: boolean,
  loading: boolean,
  hasInitialResults: boolean,
  deleteDialog: DialogState,
  selectedLoanApplication: LoanApplication2009|LoanApplication|null,
  selectedActionMenu: Element|null,
  selectedExportMenu: Element|null,
  searchCriteria: LoanSearchCriteria,
  errors: ErrorState[],
  errorStatusCode?: number,
  redirectTo?: string,
  snackbar?: SnackbarState
}

class LoanApplicationsPage extends Component<Props, State> {

  loanSearch:ActionResponse|null;
  loanStatuses:KeyNamePairType[] = [];
  sortOptions:LabelValuePairType[] = [];
  defaultSearchCriteria:LoanSearchCriteria = {
    searchText: '',
    branches: [],
    loanOfficers: [],
    status: [],
    order: {
      column: 'created',
      direction: 'desc'
    },
    limit: 10,
    skip: 0,
    totalCount: 0,
    page: 1,
    completed: false
  };

  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      hasInitialResults: false,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      },
      selectedLoanApplication: null,
      selectedActionMenu: null,
      selectedExportMenu: null,
      searchCriteria: _.clone(this.defaultSearchCriteria),
      errors: []
    };

    props.app.data.enums.loanStatuses.forEach((status:string) => {
      this.loanStatuses.push({ key: status, name: IntlFormatter.formatMessage(props.intl, status) });
    });

    this.sortOptions = [
      { label: IntlFormatter.formatMessage(props.intl, 'loan_purpose'), value: 'loan.loanPurpose' },
      { label: IntlFormatter.formatMessage(props.intl, 'status'), value: 'status' },
      { label: IntlFormatter.formatMessage(props.intl, 'created'), value: 'created' },
    ];

    if(props.settings.data.tridFields.includes('loanAmount')) {
      this.sortOptions.splice(1, 0, { label: IntlFormatter.formatMessage(props.intl, 'loan_amount'), value: 'loan.loanAmount' });
    }
  }

  componentDidMount = async () => {
    try {
      if(!ReduxUtil.hasData(this.props.integrations) && ReduxUtil.isIdle(this.props.integrations)) {
        await this.props.routeProps.getIntegrations().send();
      }

      await this.addSearchCriteriaFromParams();
      const result = await this.filterLoanApplications();
      let hasInitialResults = result.data.length > 0;
      if(!ObjectUtil.isEqual(this.defaultSearchCriteria, this.state.searchCriteria)) {
        hasInitialResults = true;
      }
      this.setState({ pageLoaded: true, hasInitialResults });
    } catch(err) {
      this.onError(err);
    }
  };

  addSearchCriteriaFromParams = ():Promise<void> => {
    return new Promise((resolve) => {
      const params = qs.parse(this.props.location.search); // @todo add other search criteria to url params
      if(Object.keys(params).length > 0) {
        const searchCriteria = _.cloneDeep(this.state.searchCriteria);
        if(params.status) {
          let status = params.status as string;
          let paramStatuses:LoanStatusType[];
          if(status.includes(',')) {
            paramStatuses = status.split(',') as LoanStatusType[];
          } else {
            paramStatuses = [status.trim() as LoanStatusType];
          }

          // make sure its a valid status
          paramStatuses.forEach((status) => {
            if(this.props.app.data.enums.loanStatuses.includes(status as string)) {
              const loanStatus = this.loanStatuses.find(s => s.key === status);
              if(loanStatus) {
                searchCriteria.status.push(loanStatus);
              }
            }
          });
        }

        if(params.completed === 'true') {
          searchCriteria.completed = true;
        }

        if(params.losFailed === 'true') {
          searchCriteria.losFailed = true;
        }

        this.setState({ searchCriteria }, () => {
          resolve()
        });

      } else {
        resolve();
      }
    });
  };

  searchTextChanged = (value:string) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.searchText = value;
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.searchLoanApplications);
  };

  searchTextRemove = () => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.searchText = '';
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.searchLoanApplications);
  };

  setBranches = (branches:Branch[]) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.branches = _.cloneDeep(branches);
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.searchLoanApplications);
  };

  searchBranches = async (searchText:string) => {
    const result = await Api.searchBranches({searchText});
    return result.data;
  };

  setLoanOfficers = (loanOfficers:LoanOfficer[]) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.loanOfficers = _.cloneDeep(loanOfficers);
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.searchLoanApplications);
  };

  searchLoanOfficers = async (searchText:string) => {
    const result = await Api.searchLoanOfficers({searchText});
    return result.data;
  };

  setLoanStatus = (status:KeyNamePairType[]) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.status = _.clone(status);
    searchCriteria.skip = 0;
    searchCriteria.page = 1;
    this.setState({ searchCriteria }, this.searchLoanApplications);
  };

  searchLoanStatus = (searchText:string) => {
    let results = this.loanStatuses;
    if(searchText !== '') {
      const regex = new RegExp(TextUtil.escapeRegEx(searchText), 'i');
      results = this.loanStatuses.filter(a => regex.test(a.name));
    }

    return results;
  };

  searchLoanApplications = async () => {
    try {
      await this.filterLoanApplications();
    } catch (e) {
      this.onError(e);
    }
  };

  filterLoanApplications = ():Promise<AxiosResponse<LoanApplication2009[]|LoanApplication[]>> => {
    return new Promise((resolve, reject) => {
      const { searchCriteria } = this.state;

      this.setState({ loading: true }, () => {
        const criteria:LoanApplicationSearchRequest = {
          searchText: searchCriteria.searchText,
          owner: searchCriteria.owner
        };

        if(searchCriteria.branches.length > 0) {
          criteria.branches = _.map(searchCriteria.branches, '_id');
        }

        if(searchCriteria.loanOfficers.length > 0) {
          criteria.loanOfficers = _.map(searchCriteria.loanOfficers, '_id');
        }

        if(searchCriteria.status.length > 0) {
          criteria.status = _.map(searchCriteria.status, 'key') as LoanApplicationSearchRequest["status"];
        }

        if(searchCriteria.completed) {
          criteria.completed = true;
        }

        if(searchCriteria.losFailed) {
          criteria.losFailed = true;
        }

        const params = SearchUtil.getParamsFromSearchCriteria(searchCriteria);

        if(this.loanSearch) {
          this.loanSearch.cancel('Another request was made');
        }

        this.loanSearch = this.props.routeProps.searchLoanApplications(criteria, params);
        this.loanSearch.send().then(result => {
          const searchCriteria = _.cloneDeep(this.state.searchCriteria);
          searchCriteria.totalCount = parseInt(result.headers['total-count']);
          this.setState({ loading: false, searchCriteria });
          this.loanSearch = null;
          resolve(result)
        }).catch(err => {
          this.loanSearch = null;
          reject(err);
        });
      });
    });
  };

  onError = (err:any) => {
    this.setState({ pageLoaded: true, loading: false, errors: ErrorUtil.formatErrors(err) });
  };

  onShowDeleteDialogClick = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = true;
    this.setState({ deleteDialog, selectedActionMenu: null });
  };

  onHideDeleteDialog = () => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    deleteDialog.open = false;

    this.setState({
      deleteDialog,
      selectedLoanApplication: null
    });
  };

  onDeleteLoanApplicationConfirm = async (loanApplication:LoanApplication2009|LoanApplication) => {
    const deleteDialog = _.clone(this.state.deleteDialog);
    try {
      deleteDialog.loading = true;
      this.setState({ deleteDialog });
      await this.props.routeProps.deleteLoanApplication(loanApplication._id).send();
      deleteDialog.open = false;
      deleteDialog.errors = [];
      deleteDialog.loading = false;
      this.setState({ deleteDialog });
      await this.filterLoanApplications();
    } catch(err) {
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(err);
      this.setState({ deleteDialog });
    }

  };

  onLoanActionMenuClick = (event:MouseEvent, loanApplication:LoanApplication2009|LoanApplication) => {
    event.preventDefault();
    event.stopPropagation();

    const selectedActionMenu = event.currentTarget;
    this.setState({ selectedActionMenu, selectedLoanApplication: loanApplication });
  };

  onLoanActionMenuClose = () => {
    this.setState({ selectedActionMenu: null, selectedLoanApplication: null });
  };

  onLoanExportMenuClick = (event:MouseEvent, loanApplication:LoanApplication2009|LoanApplication) => {
    event.preventDefault();
    event.stopPropagation();

    const selectedExportMenu = event.currentTarget;
    this.setState({ selectedExportMenu, selectedLoanApplication: loanApplication });
  };

  onLoanExportMenuClose = () => {
    this.setState({ selectedExportMenu: null, selectedLoanApplication: null });
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'loan_applications');
  };

  renderTitleBar = () => {
    const { classes, intl } = this.props;
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <PageTitle title={this.getPageTitle()} icon="library_books" />
        </Grid>
        <Grid item>
          <div className={classes.textRight}>
            <Hidden mdUp>
              <Tooltip title={IntlFormatter.formatMessage(intl, 'new_loan')}>
                <Fab color="primary"
                     component={RouterLink}
                     size="small"
                     to={this.getApplyLink()}>
                  <Icon>add</Icon>
                </Fab>
              </Tooltip>
            </Hidden>
            <Hidden smDown>
              <AddButton variant="contained"
                         color="primary"
                         to={this.getApplyLink()}>
                <FormattedMessage id="new_loan" />
              </AddButton>
            </Hidden>
          </div>
        </Grid>
      </Grid>
    )
  };

  getApplyLink = () => {
    let applyLink = '/apply';

    if(AclUtil.hasRole(this.props.user.data, 'ROLE_LOAN_OFFICER')) {
      applyLink += `?loanOfficer=${encodeURIComponent(this.props.user.data.email)}`;
    }

    return applyLink;
  };

  exportLoanApplication = (format:LoanExportFormat) => {
    if(this.state.selectedLoanApplication) {
      const {accessToken} = this.props.token.data;
      const url = LoanUtil.getExportUrl(this.state.selectedLoanApplication._id, format, accessToken);
      window.location.assign(url);
    }
    this.setState({ selectedExportMenu: null, selectedLoanApplication: null });
  };

  renderProgressIndicator = (loanApplication:LoanApplication2009|LoanApplication) => {
    const { classes } = this.props;
    const percentComplete = loanApplication.completed ? 100 : LoanUtil.getCompletedPercent(loanApplication);

    const colorsMap:any = {
      '25': colors.red[500],
      '50': colors.orange[600],
      '75': colors.yellow[700],
      '100': colors.green[500]
    };

    let color = colorsMap['25'];
    const keys = Object.keys(colorsMap);
    for(let i=0;i<keys.length;i++) {
      if(percentComplete <= parseInt(keys[i])) {
        color = colorsMap[keys[i]];
        break;
      }
    }

    return (
      <div className={clsx(classes.textCenter)}>
        <strong style={{ color }}>{percentComplete}%</strong>
      </div>
    )
  };

  onPageChange = (event:MouseEvent, value:number) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.skip = (value - 1) * searchCriteria.limit;
    searchCriteria.page = value;
    this.setState({ searchCriteria }, this.searchLoanApplications);
  };

  onLoanApplicationClick = (loanApplication:LoanApplication2009|LoanApplication) => {
    this.setState({ redirectTo: `/admin/loan-applications/detail/${loanApplication._id}`  });
  };

  onTableHeadingSortColumnChange = (column:string) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.order.column = column;
    searchCriteria.order.direction = searchCriteria.order.direction === 'asc' ? 'desc' : 'asc';
    this.setState({ searchCriteria }, this.filterLoanApplications);
  };

  onSortFieldOptionChange = (data:LabelValuePairType) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.order.column = data ? data.value : null;
    this.setState({ searchCriteria }, this.filterLoanApplications);
  };

  onSortFieldDirectionChange = (direction:SortDirectionType) => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.order.direction = direction;
    this.setState({ searchCriteria }, this.filterLoanApplications);
  };

  getFilterCount = () => {
    let count = 0;
    const { loanOfficers, branches, status, completed, losFailed } = this.state.searchCriteria;
    count += loanOfficers.length;
    count += branches.length;
    count += status.length;
    if(completed) {
      count++;
    }
    if(losFailed) {
      count++;
    }
    return count;
  };

  clearFilters = () => {
    this.setState({
      searchCriteria: _.clone(this.defaultSearchCriteria)
    }, this.filterLoanApplications);
  };

  onToggleCompleted = () => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.completed = !searchCriteria.completed;
    this.setState({ searchCriteria }, this.filterLoanApplications);
  };

  onToggleLosFailed = () => {
    const searchCriteria = _.cloneDeep(this.state.searchCriteria);
    searchCriteria.losFailed = !searchCriteria.losFailed;
    this.setState({ searchCriteria }, this.filterLoanApplications);
  };

  render() {

    const { intl, loanApplications, user, classes, settings, integrations } = this.props;
    const { pageLoaded, loading, deleteDialog, selectedLoanApplication, searchCriteria, snackbar, selectedExportMenu, selectedActionMenu, redirectTo, hasInitialResults, errors } = this.state;
    const pages = Math.ceil(searchCriteria.totalCount / searchCriteria.limit);
    const hasLosIntegration = ReduxUtil.hasData(integrations) ? IntegrationUtil.hasIntegration(integrations.data, 'los') : false;
    const filterCount = this.getFilterCount();

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   loading={loading}
                   pageLoaded={pageLoaded}
                   titleBar={this.renderTitleBar()}
                   snackbar={snackbar}
                   breadcrumbs={[
                     {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                     {title: this.getPageTitle() }
                   ]}>

        {selectedLoanApplication &&
          <DeleteDialog open={deleteDialog.open}
                        loading={deleteDialog.loading}
                        errors={deleteDialog.errors}
                        title={IntlFormatter.formatMessage(intl, 'delete_loan_application')}
                        item={IntlFormatter.formatMessage(intl, 'loan')}
                        onCancel={this.onHideDeleteDialog}
                        onSubmit={() =>  this.onDeleteLoanApplicationConfirm(selectedLoanApplication)} />
        }

        {selectedLoanApplication &&
          <Menu anchorEl={selectedExportMenu}
                open={Boolean(selectedExportMenu)}
                onClose={this.onLoanExportMenuClose}>
            {/*<MenuItem onClick={() => this.exportLoanApplication('pdf')}>
              <ListItemIcon>
                <Icon>picture_as_pdf</Icon>
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="pdf" />
              </ListItemText>
            </MenuItem>*/}
            <MenuItem onClick={() => this.exportLoanApplication(selectedLoanApplication.__t === 'Rev1' ? 'fnm' : 'mismo')}>
              <ListItemIcon>
                <Icon>insert_drive_file</Icon>
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id={selectedLoanApplication.__t === 'Rev1' ? "fannie_mae32" : "mismo"} />
              </ListItemText>
            </MenuItem>
          </Menu>
        }

        {selectedLoanApplication &&
          <Menu anchorEl={selectedActionMenu}
                open={Boolean(selectedActionMenu)}
                onClose={this.onLoanActionMenuClose}>
            <MenuItem component={RouterLink} to={`/admin/loan-applications/detail/${selectedLoanApplication._id}`}>
              <ListItemIcon>
                <Icon>remove_red_eye</Icon>
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="view" />
              </ListItemText>
            </MenuItem>
            {LoanUtil.canEdit(selectedLoanApplication) &&
              <MenuItem component={RouterLink} to={LoanUtil.getEditUrl(selectedLoanApplication)}>
                <ListItemIcon>
                  <Icon>mode_edit</Icon>
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="edit" />
                </ListItemText>
              </MenuItem>
            }
            {AclUtil.isOwner(user.data) &&
              <MenuItem onClick={() => this.onShowDeleteDialogClick()}>
                <ListItemIcon>
                  <Icon>cancel</Icon>
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="delete"/>
                </ListItemText>
              </MenuItem>
            }
          </Menu>
        }

        <ErrorList errors={errors}
                   className={classes.p2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        {hasInitialResults &&
          <div className={classes.pageHeaderContainer}>
            <SearchBar onSearchTextChanged={this.searchTextChanged}
                       onSearchTextRemove={this.searchTextRemove}
                       filterDrawerTitle={IntlFormatter.formatMessage(intl, 'filter_loans')}
                       filterCount={filterCount}>

              <div>
                {AclUtil.hasAnyRole(user.data, ['ROLE_SUPER_ADMIN', 'ROLE_OWNER']) &&
                  <div className={classes.mb2}>
                    <AutoComplete value={searchCriteria.branches}
                                  getOptionLabel={(item:Branch) => {
                                    return item && item.name ? item.name : '';
                                  }}
                                  getOptionSelected={(option:Branch, value:Branch) => {
                                    return option._id === value._id;
                                  }}
                                  limitTags={2}
                                  multiple={true}
                                  fullWidth={true}
                                  onChange={this.setBranches}
                                  onTextChange={this.searchBranches}
                                  label={IntlFormatter.formatMessage(intl, 'branches')}
                                  placeholder={IntlFormatter.formatMessage(intl, 'search')}
                                  openOnFocus={true}
                                  debounce={500} />
                  </div>
                }
                {AclUtil.hasAnyRole(user.data, ['ROLE_SUPER_ADMIN', 'ROLE_OWNER', 'ROLE_BRANCH_MANAGER']) &&
                  <div className={classes.mb2}>
                    <AutoComplete value={searchCriteria.loanOfficers}
                                  getOptionLabel={(item:LoanOfficer) => {
                                    return item && item.fullName ? item.fullName : '';
                                  }}
                                  getOptionSelected={(option:LoanOfficer, value:LoanOfficer) => {
                                    return option._id === value._id;
                                  }}
                                  limitTags={2}
                                  multiple={true}
                                  fullWidth={true}
                                  onChange={this.setLoanOfficers}
                                  onTextChange={this.searchLoanOfficers}
                                  label={IntlFormatter.formatMessage(intl, 'loan_officers')}
                                  placeholder={IntlFormatter.formatMessage(intl, 'search')}
                                  openOnFocus={true}
                                  debounce={500} />
                  </div>
                }
                <div className={classes.mb2}>
                  <AutoComplete value={searchCriteria.status}
                                getOptionLabel={(item:KeyNamePairType) => item && item.name ? item.name : ''}
                                getOptionSelected={(option:KeyNamePairType, value:KeyNamePairType) => {
                                  return option.key === value.key;
                                }}
                                limitTags={2}
                                multiple={true}
                                fullWidth={true}
                                onChange={this.setLoanStatus}
                                onTextChange={this.searchLoanStatus}
                                label={IntlFormatter.formatMessage(intl, 'status')}
                                placeholder={IntlFormatter.formatMessage(intl, 'search')}/>
                </div>
                <div className={classes.mb2}>
                  <FormControlLabel label={IntlFormatter.formatMessage(intl, 'completed_by_borrower')} control={
                    <Checkbox checked={searchCriteria.completed}
                              onChange={this.onToggleCompleted}
                              color="primary" />
                  }
                  />
                </div>
                {hasLosIntegration &&
                  <div className={classes.mb2}>
                    <FormControlLabel label={IntlFormatter.formatMessage(intl, 'failed_to_send_to_name', {name: integrations.data!.los!.provider})} control={
                      <Checkbox checked={searchCriteria.losFailed}
                                onChange={this.onToggleLosFailed}
                                color="primary" />
                    }
                    />
                  </div>
                }

                <div className={classes.mb3}>
                  <Divider />
                </div>

                <SortField label={IntlFormatter.formatMessage(intl, 'sort_by')}
                           placeholder={IntlFormatter.formatMessage(intl, 'none')}
                           value={searchCriteria.order.column ? this.sortOptions.find(o => o.value === searchCriteria.order.column) : ''}
                           fullWidth={true}
                           options={this.sortOptions}
                           getOptionLabel={(item:LabelValuePairType) => item.label}
                           direction={searchCriteria.order.direction}
                           onChange={this.onSortFieldOptionChange}
                           onDirectionChange={this.onSortFieldDirectionChange}/>
              </div>

            </SearchBar>
          </div>
        }

        {(ReduxUtil.hasData(loanApplications) && loanApplications.data.length > 0) &&
          <div>
            <div className={clsx(classes.ph2, classes.pv1)}>
              <PaginationHeader totalResults={searchCriteria.totalCount}
                                currentPage={searchCriteria.page}
                                totalPages={pages} />
            </div>
            <div>
              <Hidden mdUp implementation="css">
                <Divider />

                <List>
                  {loanApplications.data.map((loanApplication:LoanApplication2009|LoanApplication, i:number) => {

                    let borrowerText = IntlFormatter.formatMessage(intl, 'no_borrower');
                    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
                      borrowerText = `${loanApplication.borrowers[0].firstName} ${loanApplication.borrowers[0].lastName}`;
                      /*if(loanApplication.borrowers.length === 2) {
                        borrowerText += `/${loanApplication.borrowers[1].firstName} ${loanApplication.borrowers[1].lastName}`;
                      }*/
                    }

                    return (
                      <ListItem key={i}>
                        <Grid container justifyContent="space-between" alignItems="center">
                          <Grid item>
                            <div className={classes.row}>
                              <Tooltip title={IntlFormatter.formatMessage(intl, 'applicant')}>
                                <UserAvatar user={loanApplication.user}/>
                              </Tooltip>
                              <div className={classes.ml1}>
                                <Typography variant="body1">
                                  {borrowerText}
                                </Typography>
                                {loanApplication.loanOfficer &&
                                  <Typography variant="body2">
                                    {loanApplication.loanOfficer.firstName} {loanApplication.loanOfficer.lastName}
                                  </Typography>
                                }
                                <div className={classes.rowCenter}>
                                  <LoanStatusBadge status={loanApplication.status} />
                                  <div className={classes.ml1}>
                                    {this.renderProgressIndicator(loanApplication)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item>
                            <IconButton onClick={event => this.onLoanActionMenuClick(event, loanApplication)}>
                              <Icon>more_vert</Icon>
                            </IconButton>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )
                  })}
                </List>
              </Hidden>
              <Hidden smDown implementation="css">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="borrowers"/>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel active={searchCriteria.order.column === 'loan.loanPurpose'}
                                        direction={searchCriteria.order.direction}
                                        onClick={() => this.onTableHeadingSortColumnChange('loan.loanPurpose')}>
                          <FormattedMessage id="loan_purpose"/>
                        </TableSortLabel>
                      </TableCell>
                      {settings.data.tridFields.includes('loanAmount') &&
                        <TableCell>
                          <TableSortLabel active={searchCriteria.order.column === 'loan.loanAmount'}
                                          direction={searchCriteria.order.direction}
                                          onClick={() => this.onTableHeadingSortColumnChange('loan.loanAmount')}>
                            <FormattedMessage id="loan_amount"/>
                          </TableSortLabel>
                        </TableCell>
                      }
                      <TableCell>
                        <FormattedMessage id="loan_officer"/>
                      </TableCell>
                      <TableCell className={classes.textCenter}>
                        <TableSortLabel active={searchCriteria.order.column === 'status'}
                                        direction={searchCriteria.order.direction}
                                        onClick={() => this.onTableHeadingSortColumnChange('status')}>
                          <FormattedMessage id="status"/>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel active={searchCriteria.order.column === 'created'}
                                        direction={searchCriteria.order.direction}
                                        onClick={() => this.onTableHeadingSortColumnChange('created')}>
                          <FormattedMessage id="created"/>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.textCenter}>
                        <FormattedMessage id="progress"/>
                      </TableCell>
                      <TableCell/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loanApplications.data.map((loanApplication:LoanApplication2009|LoanApplication) => {
                      if(!loanApplication.borrowers) {
                        return null;
                      }
                      return (
                        <TableRow key={loanApplication._id}>
                          <TableCell component="th" scope="row">
                            {loanApplication.borrowers.map((form:Borrower2009|Borrower, i:number) => {
                              return (
                                <div key={i} className={classes.rowCenter}>
                                  <div className={classes.mr1}>
                                    <Tooltip title={IntlFormatter.formatMessage(intl, form.primaryBorrower ? 'borrower' : 'co_borrower')}>
                                      <Icon fontSize="small">{form.primaryBorrower ? 'person' : 'person_outline'}</Icon>
                                    </Tooltip>
                                  </div>
                                  <Typography variant="body1">
                                    <RouterLink to={`/admin/loan-applications/detail/${loanApplication._id}`}>
                                      {form.firstName} {form.lastName}
                                    </RouterLink>
                                  </Typography>
                                </div>
                              )
                            })}
                          </TableCell>
                          <TableCell>
                            {loanApplication.loan && loanApplication.loan.loanPurpose &&
                              <Badge color={loanApplication.loan.loanPurpose === 'purchase' ? 'infoAlt': 'successAlt'}>
                                <FormattedMessage id={loanApplication.loan.loanPurpose}/>
                              </Badge>
                            }
                          </TableCell>
                          {settings.data.tridFields.includes('loanAmount') &&
                            <TableCell>
                              <strong>
                                {loanApplication.loan && loanApplication.loan.loanAmount ? loanApplication.loan.loanAmount.toMoney() : ''}
                              </strong>
                            </TableCell>
                          }
                          <TableCell>
                            {loanApplication.loanOfficer &&
                              <LoanOfficerProfileReference loanOfficer={loanApplication.loanOfficer} />
                            }
                          </TableCell>
                          <TableCell>
                            <div className={classes.row}>
                              <LoanStatusBadge status={loanApplication.status} />
                              {(hasLosIntegration && loanApplication.los && loanApplication.los.status === 'failed') &&
                                <div className={classes.ml1}>
                                  <Tooltip title={IntlFormatter.formatMessage(intl, 'failed_to_send_to_name', { name: integrations.data!.los!.provider})}>
                                    <Icon fontSize="small" className={classes.danger}>warning</Icon>
                                  </Tooltip>
                                </div>
                              }
                            </div>
                          </TableCell>
                          <TableCell>{DateUtil.formatUserDate(user.data, loanApplication.created)}</TableCell>
                          <TableCell>
                            {this.renderProgressIndicator(loanApplication)}
                          </TableCell>
                          <TableCell align="right">
                            {loanApplication.__t === 'Rev1' &&
                              <IconButton onClick={event => this.onLoanExportMenuClick(event, loanApplication)}>
                                <Icon>save_alt</Icon>
                              </IconButton>
                            }

                            <IconButton onClick={event => this.onLoanActionMenuClick(event, loanApplication)}>
                              <Icon>more_vert</Icon>
                            </IconButton>

                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Hidden>

              {pages > 1 &&
                <Pagination count={pages}
                            color="primary"
                            onChange={this.onPageChange} />
              }
            </div>
          </div>
        }

        {(!loading && ReduxUtil.hasData(loanApplications) && loanApplications.data.length === 0) &&
          <div className={clsx(classes.p2, classes.mt2)}>
            <NoResultsCard message={IntlFormatter.formatMessage(intl, 'no_loan_applications_found')}>
              <div>
                {!hasInitialResults &&
                  <div className={classes.mt1}>
                    <AddButton color="primary"
                            variant="contained"
                            to="/apply">
                      <FormattedMessage id="new_loan" />
                    </AddButton>
                  </div>
                }
              </div>
            </NoResultsCard>
          </div>
        }
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    user: state.user,
    loanApplications: state.loanApplications,
    settings: state.loanSettings,
    integrations: state.integrations,
    token: state.token
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoanApplicationsPage)));
