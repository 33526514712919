import React, {useEffect, useState} from "react";
import {Icon} from "@material-ui/core";
import IntlFormatter from "../intl";
import {ErrorUtil, ReduxUtil} from "../utils";
import {Dialog, ErrorList, LicensesUsedBadge, PropagateLoader} from "./index";
import {MixpanelConsumer} from "react-mixpanel";
import {Mixpanel} from "mixpanel-browser";
import {NumberOfLicensesForm} from "../forms";
import {ErrorState} from "../types";
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from "../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {getSubscription} from "../actions/subscription";
import {useStyles} from "../theme/jss/layouts/pageStyles";
import {useIntl} from "react-intl";
import clsx from "clsx";

type Props = {
  open: boolean,
  onClose?: () => void
}

const LicensesDialogForm = (props:Props) => {
  const {open} = props;
  const classes = useStyles();
  const intl = useIntl();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const subscription = useSelector((state:ReduxState) => state.subscription);

  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<ErrorState[]>([]);


  useEffect(() => {
    async function init() {
      try {
        if(!ReduxUtil.hasData(subscription) && ReduxUtil.isIdle(subscription)) {
          await dispatch(getSubscription()).send();
        }
      } catch (e) {
        setErrors(ErrorUtil.formatErrors(e));
      }

      setLoading(false);
    }

    init();
  }, [dispatch, subscription]);

  const onDialogClose = () => {
    setLoading(false);
    setErrors([]);
    if(props.onClose) {
      props.onClose();
    }
  };

  return (
    <Dialog open={open}
            icon={<Icon>person_add</Icon>}
            title={IntlFormatter.formatMessage(intl, 'add_licenses')}
            color="primaryAlt"
            onClose={onDialogClose}
            fullWidth={true}
            maxWidth="sm">
      <ErrorList errors={errors}
                 className={classes.mb2} />
      {loading &&
        <div className={clsx(classes.mv2, classes.textCenter)}>
          <PropagateLoader loading={true} />
        </div>
      }
      {(subscription && subscription.data) &&
      <div className={classes.textRight}>
        <LicensesUsedBadge subscription={subscription.data} />
      </div>
      }

      <MixpanelConsumer>
        {(mixpanel:Mixpanel) => {
          return <NumberOfLicensesForm mixpanel={mixpanel}
                                       onSubmit={onDialogClose} />
        }}
      </MixpanelConsumer>
    </Dialog>
  )
}

export default LicensesDialogForm;