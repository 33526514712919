import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const styles = (theme:Theme) => createStyles({
  horizontal: {
    color: theme.palette.neutral.main,
    padding: `${theme.spacing(4)}px`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& $avatarContainer': {
      marginRight: theme.spacing(2)
    },
    '& $content': {
      [theme.breakpoints.up("sm")]: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
      }
    },
    '& $actions': {
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(2),
        marginTop: 0,
      }
    }
  },
  vertical: {
    textAlign: 'center',
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
    '& $avatarContainer': {
      marginBottom: theme.spacing(2)
    },
  },
  content: {

  },
  info: {
    padding: `${theme.spacing(2)}`,
    fontSize: 18
  },
  name: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 4
  },
  title: {
    fontSize: 15,
  },
  avatarContainer: {
    border: `solid 2px ${theme.palette.primary.main}`,
    padding: 2,
    borderRadius: '50%',
    display: 'inline-block',
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  action: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  archivedUser: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    maxWidth: 200,
    display: 'inline-block'
  },
  actions: {
    marginTop: theme.spacing(2),
  }
});

export default styles;
const useStyles = makeStyles(styles);
export {useStyles}