import React, {Component} from 'react';
import {
  withStyles, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Button, List, WithStyles,
} from '@material-ui/core';
import {AclUtil} from "../utils";
import styles from '../theme/jss/components/userListStyles';
import {FormattedMessage} from "react-intl";
import {UserAvatar} from "./index";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {ReduxState} from "../data/initialState";
import {ReduxUser} from "../types";
import {User, UserLean} from "@jerseydev/orca-loans";

type Props = {
  users: User[]|UserLean[],
  loggedInUser: ReduxUser,
  className?: string
} & WithStyles<typeof styles>

class UserList extends Component<Props> {
  render() {

    const { users, classes, className, loggedInUser } = this.props;

    return (
      <List className={className}>
        {users.map((user:User|UserLean, i:number) => {
          return (
            <ListItem key={i} className={classes.listItem}>
              <ListItemIcon>
                <UserAvatar user={user} />
              </ListItemIcon>
              <ListItemText primary={`${user.firstName} ${user.lastName}`}
                            secondary={user.email} />
              {AclUtil.isOwner(loggedInUser.data) &&
              <ListItemSecondaryAction>
                <Button variant="outlined"
                        color="primary"
                        size="small"
                        component={Link}
                        to={`/admin/users/detail/${user._id}`}>
                  <FormattedMessage id="view" />
                </Button>
              </ListItemSecondaryAction>
              }
            </ListItem>
          )
        })}
      </List>
    )
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loggedInUser: state.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(UserList));
