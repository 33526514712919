import {ExtendedFile} from "../types";

class FileUtil {
  // image must be a File object
  static getImageDimensions = (file:ExtendedFile) => {
   return new Promise((resolve) => {
      const i = new Image();

      i.onload = () => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve({
            src: file.tmpUrl,
            width: i.width,
            height: i.height,
            data: reader.result
          })
        }
      };

      i.src = file.tmpUrl;
    });
  };
}

export default FileUtil;