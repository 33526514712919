import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  low: {
    color: theme.palette.success.main
  },
  medium: {
    color: theme.palette.info.main
  },
  high: {
    color: theme.palette.danger.light
  },
  urgent: {
    color: theme.palette.danger.main
  }
});

export default styles;
