import React, {ChangeEvent, MouseEvent} from 'react';
import Form, {SelectValidator} from "../../../forms/Form";
import {
  Grid,
  IconButton,
  Icon,
  Button,
  withStyles, MenuItem, ListItemText, WithStyles
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {ErrorList, SubmitButton, TextField} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {addLoanOfficers} from "../../../actions/loanOfficers";
import {getBranches} from "../../../actions/branches";
import {ErrorUtil, ReduxUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import moment from "moment-timezone";
import {ReduxBranches, ActionProps, ErrorState} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {Branch, LoanOfficer, LoanOfficerRequest} from "@jerseydev/orca-loans";
import {AxiosResponse} from "axios";

type Props = {
  onSubmit: (data:AxiosResponse<LoanOfficer[]>) => void,
  onCancel?: () => void,
  branches: ReduxBranches,
  addLoanOfficers: ActionProps["addLoanOfficers"],
  getBranches: ActionProps["getBranches"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type FormItem = {
  firstName: string,
  lastName: string,
  email: string,
  nmlsId: string,
  timezone: string,
  branch: Branch|null
}

type State = {
  loading: boolean,
  form: FormItem[],
  errors: ErrorState[],
  managerSearchText: string
}

class MultipleLoanOfficersForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: true,
      form: [{
        firstName: '',
        lastName: '',
        email: '',
        nmlsId: '',
        timezone: moment.tz.guess(),
        branch: null
      }],
      errors: [],
      managerSearchText: ''
    };
  }

  componentDidMount = async () => {
    try {
      await this.props.getBranches().send();
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  onAddLoanOfficer = () => {
    const form = _.cloneDeep(this.state.form);
    form.push({ firstName: '', lastName: '', email: '', nmlsId: '', timezone: moment.tz.guess(), branch: null });
    this.setState({ form });
  };

  onRemoveLoanOfficer = (index:number) => {
    const form = _.cloneDeep(this.state.form);
    form.splice(index, 1);
    this.setState({ form });
  };

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({ loading: true, errors: [] });
      const data:LoanOfficerRequest[] = [];
      this.state.form.forEach(lo => {
        const {firstName, lastName, email, nmlsId, timezone, branch} = lo;
        data.push({
          firstName,
          lastName,
          email,
          nmlsId,
          timezone,
          branch: branch!._id
        })
      });
      const result = await this.props.addLoanOfficers(data).send();
      this.setState({ loading: false }, () => {
        this.props.onSubmit(result);
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, classes, branches } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } } />
          {form.map((branch, i) => {
            return (
              <Grid key={i} container spacing={2}>
                <Grid item xs={2}>
                  <TextField name="firstName"
                             label={IntlFormatter.formatMessage(intl, 'first_name')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form[${i}].firstName`)}
                             value={form[i].firstName}
                             fullWidth={true}
                             validators={['required']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                </Grid>
                <Grid item xs={2}>
                  <TextField name="lastName"
                             label={IntlFormatter.formatMessage(intl, 'last_name')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form[${i}].lastName`)}
                             value={form[i].lastName}
                             fullWidth={true}
                             validators={['required']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                </Grid>
                <Grid item xs={3}>
                  <TextField name="email"
                             label={IntlFormatter.formatMessage(intl, 'email')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form[${i}].email`)}
                             value={form[i].email}
                             fullWidth={true}
                             inputProps={{
                               autoCapitalize: 'none',
                             }}
                             validators={['required', 'isEmail']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required'), IntlFormatter.formatMessage(intl, 'validation_email_invalid')]} />
                </Grid>
                <Grid item xs={1}>
                  <TextField name="nmlsId"
                             label={IntlFormatter.formatMessage(intl, 'nmls_id')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form[${i}].nmlsId`)}
                             value={form[i].nmlsId}
                             fullWidth={true}
                             validators={['required']}
                             errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                </Grid>
                <Grid item xs={2}>
                  {/* @todo - change SelectValidator to Select component */}
                  {(ReduxUtil.hasData(branches) && branches.data.length > 0) &&
                    <SelectValidator
                      name="branch"
                      label={IntlFormatter.formatMessage(intl, 'branch')}
                      value={branches.data.length === 1 ? branches.data[0] : form[i].branch}
                      fullWidth={true}
                      onChange={(event:ChangeEvent<{value:Branch}>) => this.onSelectChange(event, `form[${i}].branch`)}
                      validators={['required']}
                      errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]}>
                      {branches.data.map((branch, i) => {
                        return (
                          // @ts-ignore
                          <MenuItem key={i} value={branch}>
                            <ListItemText primary={branch.name}/>
                          </MenuItem>
                        )
                      })}
                    </SelectValidator>
                  }
                </Grid>
                <Grid item xs={1}>
                  {i !== 0 &&
                    <IconButton onClick={() => this.onRemoveLoanOfficer(i)}>
                      <Icon>close</Icon>
                    </IconButton>
                  }
                </Grid>
              </Grid>
            )
          })}

          <div className={classes.mt2}>
            <Button variant="contained" color="primary" onClick={this.onAddLoanOfficer}>
              <FormattedMessage id="add_another_loan_officer" />
            </Button>
          </div>

          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
                <Grid item>
                  <Button onClick={this.props.onCancel}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    branches: state.branches
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addLoanOfficers(data:LoanOfficerRequest[]) {
    return dispatch(addLoanOfficers(data));
  },
  getBranches() {
    return dispatch(getBranches());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(MultipleLoanOfficersForm)));
