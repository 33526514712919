import React from 'react';
import {
  withStyles,
  WithStyles,
} from '@material-ui/core';
import {connect} from 'react-redux';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {AccountPage} from "../../../layouts";
import BaseLoanApplicationPage from "./BaseLoanApplicationPage";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {ErrorUtil} from "../../../utils";
import {IncompleteLoanAlert, LoanStartedWelcome, PageTitle} from "../../../components";
import {ReduxState} from "../../../data/initialState";
import {ActionProps, ErrorState, ReduxAccount, ReduxLoanSettings, ReduxUser} from "../../../types";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  account: ReduxAccount,
  user: ReduxUser,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps<{id:string}>

type State = {
  loading: boolean,
  redirectTo?: string,
  errors: ErrorState[]
}

class StartPage extends BaseLoanApplicationPage<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      errors: []
    };
  }

  componentDidMount = async () => {
    this.props.routeProps.clearLoanApplication();
  };

  onGetStartedClick = async () => {
    try {
      this.props.mixpanel.track("Loan application started");
      this.setState({ redirectTo: `/apply/personal${this.props.location.search}` });
    } catch (e) {
      this.onError(e);
    }
  };

  onError = (err:any) => {
    this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'getting_started');
  };

  renderTitleBar = () => {
    return <PageTitle title={this.getPageTitle()} icon="dashboard" />
  };

  render() {

    const { intl, classes, account, user, settings, mixpanel } = this.props;
    const { loading, redirectTo } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <AccountPage pageTitle={IntlFormatter.formatMessage(intl, 'getting_started')}
                   titleBar={this.renderTitleBar()}
                   loading={loading}>
        <div className={classes.content}>
          <IncompleteLoanAlert className={classes.mb2} />

          <LoanStartedWelcome loanSettings={settings.data}
                              user={user.data}
                              account={account.data}
                              mixpanel={mixpanel} />
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    account: state.account,
    user: state.user,
    settings: state.loanSettings
  };
};


export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(StartPage)));
