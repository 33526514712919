import React, {ChangeEvent, MouseEvent} from 'react';
import Form from './Form';
import {Grid, Button, Typography, withStyles, InputAdornment, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../intl';
import BaseForm from "./BaseForm";
import {ErrorList, SubmitButton, TextField} from "../components";
import {connect} from "react-redux";
import {verifyBankAccount} from "../actions/bankAccounts";
import {ErrorUtil} from '../utils';
import pageStyles from "../theme/jss/layouts/pageStyles";
import {
  BankAccount,
  BankAccountVerificationRequest
} from "@jerseydev/orca-loans";
import {
  ActionResponse,
  ErrorState
} from "../types";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {AxiosResponse} from "axios";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  bankAccount: BankAccount,
  onCancel?: () => void,
  onSubmit?: (data:AxiosResponse<BankAccount>) => void,
  verifyBankAccount: (id:string, data:BankAccountVerificationRequest) => ActionResponse
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  transaction1: '',
  transaction2: ''
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class BankAccountVerificationForm extends BaseForm<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        transaction1: '',
        transaction2: '',
      },
      errors: []
    };
  }

  onSubmit = async (event:MouseEvent) => {
    try {
      event.preventDefault();
      this.setState({ loading: true, errors: [] });
      const {form} = this.state;

      const data:BankAccountVerificationRequest = {
        transaction1: parseInt(form.transaction1),
        transaction2: parseInt(form.transaction2)
      };

      const result = await this.props.verifyBankAccount(this.props.bankAccount.id, data).send();
      this.props.mixpanel.track('Bank account verified');
      this.setState({ loading: false });
      if(this.props.onSubmit) {
        this.props.onSubmit(result);
      }
    } catch (e) {
      this.setState({
        loading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  render() {

    const { intl, classes, onCancel } = this.props;
    const { form, loading, errors } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] })}} />

          <div className={classes.mb2}>
            <Typography variant="body1" gutterBottom>
             <FormattedMessage id="bank_verification_text" />
            </Typography>
          </div>

          <div className={classes.mv2}>
            <Grid container spacing={2}>
              <Grid item>
                <TextField name="transaction1"
                           label={IntlFormatter.formatMessage(intl, 'transaction1')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.transaction1')}
                           value={form.transaction1}
                           placeholder="05"
                           InputProps={{
                             startAdornment: (
                               <InputAdornment position="start">$ 0.</InputAdornment>
                             ),
                           }}
                           fullWidth={true}
                           validators={['required', 'maxStringLength:2']}
                           type="number"
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required'),
                             IntlFormatter.formatMessage(intl, 'validation_maxlength', {length: 2})
                           ]} />
              </Grid>
              <Grid item>
                <TextField name="transaction2"
                           label={IntlFormatter.formatMessage(intl, 'transaction2')}
                           onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.transaction2')}
                           value={form.transaction2}
                           fullWidth={true}
                           placeholder="05"
                           type="number"
                           InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">$ 0.</InputAdornment>
                              ),
                           }}
                           validators={['required', 'maxStringLength:2']}
                           errorMessages={[
                             IntlFormatter.formatMessage(intl, 'validation_required'),
                             IntlFormatter.formatMessage(intl, 'validation_maxlength', {length: 2})
                           ]} />
              </Grid>
            </Grid>
          </div>

          <div className={classes.mv2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {onCancel &&
                <Grid item>
                  <Button onClick={onCancel}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}>
                  <FormattedMessage id="save" />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  verifyBankAccount(id:string, data:BankAccountVerificationRequest) {
    return dispatch(verifyBankAccount(id, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(BankAccountVerificationForm)));
