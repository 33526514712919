import React from 'react';
import {
  FormHelperText,
} from '@material-ui/core';
import {ValidatorComponent, ValidatorComponentProps} from "react-material-ui-form-validator";
import GooglePlacesAutoComplete from "./GooglePlacesAutoComplete";

type Props = ValidatorComponentProps;

type State = {
  isValid: boolean
}

class GooglePlacesAutoCompleteValidator extends ValidatorComponent<Props, State> {

  isRequired = () => {
    let required = false;
    if(this.props.validators && this.props.validators.indexOf('required') > -1) {
      required = true;
    }

    return required;
  };

  renderError = () => {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <FormHelperText error={true}>
        {this.getErrorMessage()}
      </FormHelperText>
    );
  };

  renderValidatorComponent() {
    const { value, label, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
    const { isValid } = this.state;

    return (
      <div>
        <GooglePlacesAutoComplete {...rest}
                                  label={this.isRequired() ? `${label} *`: label}
                                  value={value}
                                  error={!isValid} />
        {this.renderError()}
      </div>
    );
  }
}

export default GooglePlacesAutoCompleteValidator;
