import React, {ChangeEvent} from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {
  withStyles,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  Accordion,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  Typography,
  Hidden,
  Button, WithStyles
} from "@material-ui/core";
import styles from "../../../theme/jss/layouts/pageStyles";
import {ErrorList, Fab, Loader, NoResultsCard} from "../../../components";
import {PricingForm} from "../forms";
import {ErrorUtil} from "../../../utils";
import _ from 'lodash';
import {addLoanApplicationPricing, updateLoanApplicationPricing, deleteLoanApplicationPricing} from "../../../actions/loanApplication";
import {connect} from "react-redux";
import IntlFormatter from "../../../intl";
import {
  LoanApplicationPrice, LoanApplicationPriceUpdateRequest,
  LoanApplicationPriceRequest, LoanApplicationPriceSearchResult,
  LoanApplication2009,
  LoanApplication
} from "@jerseydev/orca-loans";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ActionProps, ErrorState, FixMeLater} from "../../../types";
import {Form as PricingFormProps} from '../forms/PricingForm';
import {AxiosResponse} from "axios";
import {PricingScenarioList, PricingSearchSummary} from "./index";
import clsx from "clsx";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  addLoanApplicationPricing: ActionProps["addLoanApplicationPricing"],
  deleteLoanApplicationPricing: ActionProps["deleteLoanApplicationPricing"],
  updateLoanApplicationPricing: ActionProps["updateLoanApplicationPricing"]
} & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  loading: boolean,
  pricingData: {
    appraisalValue: number,
    loanAmount: number,
    salePrice: number,
    county: string
  },
  activePanel: string|null,
  products: LoanApplicationPriceSearchResult[],
  searchFormVisible: boolean,
  errors: ErrorState[],
  searchCriteria?: PricingFormProps
}

class Pricing extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      pricingData: {
        appraisalValue: props.loanApplication.property && props.loanApplication.property.propertyValue ? props.loanApplication.property.propertyValue : 250000,
        loanAmount: props.loanApplication.loan && props.loanApplication.loan.loanAmount ? props.loanApplication.loan.loanAmount : 200000,
        salePrice: props.loanApplication.property && props.loanApplication.property.propertyValue ? props.loanApplication.property.propertyValue : 250000,
        county: props.loanApplication.property && props.loanApplication.property.address && props.loanApplication.property.address.county ? props.loanApplication.property.address.county : ''
      },
      activePanel: null,
      products: [],
      searchFormVisible: true,
      errors: []
    };
  }

  onPanelChange = (panel:string, event:ChangeEvent<{}>, expanded:boolean) => {
    if(expanded) {
      this.setState({ activePanel: panel });
    } else {
      this.setState({ activePanel: null });
    }
  };

  onPricingSubmit = (result:AxiosResponse<LoanApplicationPriceSearchResult[]>, searchCriteria:PricingFormProps) => {
    this.setState({ products:result.data, searchCriteria, searchFormVisible: false });
  };

  onScenarioFavoritePress = async (product:LoanApplicationPriceSearchResult, scenario:LoanApplicationPrice, savedScenario:LoanApplicationPrice|null) => {
    try {
      this.setState({ loading: true });
      if(savedScenario) {
        await this.props.deleteLoanApplicationPricing(this.props.loanApplication._id, savedScenario._id).send();
      } else {
        // @ts-ignore
        const data:LoanApplicationPriceRequest = {
          ..._.cloneDeep(scenario),
          name: product.name,
          searchCriteria: _.clone(this.state.searchCriteria) as FixMeLater,
          provider: {
            id: product.id,
            name: product.source as FixMeLater
          }
        };
        await this.props.addLoanApplicationPricing(this.props.loanApplication._id, data).send();
      }

      this.setState({ loading: false });
    } catch (e) {
      this.setState({
        loading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  };

  getSavedScenario = (product:LoanApplicationPriceSearchResult, scenario:LoanApplicationPrice) => {
    const { loanApplication } = this.props;
    let savedScenario = null;
    if(loanApplication.pricing) {
      for(let i=0;i<loanApplication.pricing.length;i++) {
        if(
          product.id === loanApplication.pricing[i].provider.id &&
          scenario.rate === loanApplication.pricing[i].rate &&
          scenario.apr === loanApplication.pricing[i].apr &&
          scenario.principalAndInterest === loanApplication.pricing[i].principalAndInterest &&
          scenario.closingCost === loanApplication.pricing[i].closingCost &&
          scenario.rebate === loanApplication.pricing[i].rebate &&
          scenario.price === loanApplication.pricing[i].price) {

          savedScenario = loanApplication.pricing[i];
        }
      }
    }

    return savedScenario;
  };

  onRefineSearchClick = () => {
    this.setState({ searchFormVisible: true, products: [] });
  };

  render() {

    const { intl, classes, loanApplication } = this.props;
    const { products, pricingData, errors, loading, activePanel, searchFormVisible, searchCriteria } = this.state;

    return (
      <div>
        <Loader visible={loading}/>

        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <div>
            <Grid container spacing={2}>
              {loanApplication.status !== 'closed' &&
                <Grid item xs={12} sm={12} md={8}>
                  {!searchFormVisible &&
                  <div>
                    {searchCriteria &&
                    <div className={classes.mb2}>
                      <PricingSearchSummary searchCriteria={searchCriteria}/>
                    </div>
                    }

                    <Button variant="contained" color="primary" onClick={this.onRefineSearchClick}>
                      <FormattedMessage id="refine_search"/>
                    </Button>
                  </div>
                  }
                  {searchFormVisible &&
                  <PricingForm loanApplication={loanApplication}
                               data={pricingData}
                               onSubmit={this.onPricingSubmit}/>
                  }

                  {(products.length === 0 && !searchFormVisible) &&
                    <NoResultsCard message={IntlFormatter.formatMessage(intl, 'no_results_found')}
                                   className={classes.mt2} />
                  }

                  {products.length > 0 &&
                  <div className={classes.mt2}>
                    {products.map((product, i) => {
                      return (
                        <Accordion key={i}
                                   expanded={activePanel === product.id}
                                   onChange={(event, expanded) => this.onPanelChange(product.id, event, expanded)}>

                          <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                            {product.name}
                          </AccordionSummary>

                          <Hidden lgUp>
                            <List style={{width: '100%'}}>
                              {product.data.map((d:FixMeLater, i:number) => {
                                const savedScenario = this.getSavedScenario(product, d);
                                return (
                                  <ListItem key={i} style={{display: 'block'}}>
                                    <div className={classes.rowCenter}>
                                      <IconButton onClick={() => this.onScenarioFavoritePress(product, d, savedScenario)}>
                                        <Icon className={savedScenario ? classes.yield : undefined}>{savedScenario ? 'star' : 'star_border'}</Icon>
                                      </IconButton>
                                      <Grid container spacing={2}>
                                        <Grid item>
                                          <Typography variant="caption">
                                            <FormattedMessage id="rate"/>
                                          </Typography>
                                          <Typography variant="body1">{d.rate}%</Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography variant="caption">
                                            <FormattedMessage id="apr"/>
                                          </Typography>
                                          <Typography variant="body1">{d.apr}%</Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography variant="caption">
                                            <FormattedMessage id="P&I"/>
                                          </Typography>
                                          <Typography variant="body1">{d.principalAndInterest.toMoney()}</Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography variant="caption">
                                            <FormattedMessage id="price"/>
                                          </Typography>
                                          <Typography variant="body1">{d.price}</Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography variant="caption">
                                            <FormattedMessage id="closing_cost"/>
                                          </Typography>
                                          <Typography variant="body1">{d.closingCost.toMoney()}</Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography variant="caption">
                                            <FormattedMessage id="discount_rebate"/>
                                          </Typography>
                                          <Typography variant="body1">{d.rebate.toMoney()}</Typography>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </ListItem>
                                )
                              })}
                            </List>
                          </Hidden>
                          <Hidden mdDown>
                            <div>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      <FormattedMessage id="rate"/>
                                    </TableCell>
                                    <TableCell>
                                      <FormattedMessage id="apr"/>
                                    </TableCell>
                                    <TableCell>
                                      <FormattedMessage id="P&I"/>
                                    </TableCell>
                                    <TableCell>
                                      <FormattedMessage id="closing_cost"/>
                                    </TableCell>
                                    <TableCell>
                                      <FormattedMessage id="discount_rebate"/>
                                    </TableCell>
                                    <TableCell>
                                      <FormattedMessage id="price"/>
                                    </TableCell>
                                    <TableCell/>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {product.data.map((d:FixMeLater, i:number) => {
                                    const savedScenario = this.getSavedScenario(product, d);
                                    return (
                                      <TableRow key={i}>
                                        <TableCell>{d.rate}%</TableCell>
                                        <TableCell>{d.apr}%</TableCell>
                                        <TableCell>{d.principalAndInterest.toMoney()}</TableCell>
                                        <TableCell>{d.closingCost.toMoney()}</TableCell>
                                        <TableCell>{d.rebate.toMoney()}</TableCell>
                                        <TableCell>{d.price}</TableCell>
                                        <TableCell>
                                          <div className={classes.rowCenter}>
                                            <IconButton
                                              onClick={() => this.onScenarioFavoritePress(product, d, savedScenario)}>
                                              <Icon className={savedScenario ? classes.yield : undefined}>{savedScenario ? 'star' : 'star_border'}</Icon>
                                            </IconButton>

                                          </div>

                                        </TableCell>
                                      </TableRow>
                                    )
                                  })}
                                </TableBody>
                              </Table>
                            </div>
                          </Hidden>

                        </Accordion>
                      )
                    })}
                  </div>
                }
              </Grid>
            }

            <Grid item xs={12} sm={12} md={4}>
              <div className={clsx(classes.rowCenter, classes.mb2)}>
                <div className={classes.mr1}>
                  <Fab color="secondaryAlt" flat rounded>
                    <Icon>save</Icon>
                  </Fab>
                </div>
                <Typography variant="h4">
                  <FormattedMessage id="saved_scenarios"/>
                </Typography>
              </div>
              <PricingScenarioList loanApplication={loanApplication} />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  addLoanApplicationPricing(loanId:string, data:LoanApplicationPriceRequest) {
    return dispatch(addLoanApplicationPricing(loanId, data));
  },
  deleteLoanApplicationPricing(loanId:string, pricingId:string) {
    return dispatch(deleteLoanApplicationPricing(loanId, pricingId));
  },
  updateLoanApplicationPricing(loanId:string, pricingId:string, data:LoanApplicationPriceUpdateRequest) {
    return dispatch(updateLoanApplicationPricing(loanId, pricingId, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(injectIntl(Pricing)));
