import React from 'react';
import {FormattedMessage} from "react-intl";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  Grid,
  withStyles,
  Typography,
  WithStyles
} from "@material-ui/core";
import styles from "../../../theme/jss/components/calculatorResultSetStyles";
import {AmortizationSchedule, Section} from "../../../components";
import clsx from "clsx";
import {DateUtil, TextUtil} from "../../../utils";
import {Amortization} from "@jerseydev/orca-loans";

type Item = {
  label: string,
  value: string,
  align?: 'left'|'right',
  size?: 'normal'|'large',
  color?: string,
}

export type CalculatorResultSetProps = {
  items: Item[],
  title?: string,
  amortization?: Amortization
  chart?: React.ReactNode,
}

type Props = CalculatorResultSetProps & WithStyles<typeof styles>

class CalculatorResultSet extends React.Component<Props> {
  render() {
    const { classes, title, items, amortization, chart } = this.props;

    return (
      <Section title={title}>
        <div className={classes.itemsContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={8}>
              <div>
                {items.map((item, i) => {
                  return (
                    <div key={i} className={clsx(
                      classes.item,
                      // @ts-ignore
                      item.align ? classes[`item${TextUtil.ucWords(item.align)}`] : null,
                      // @ts-ignore
                      item.size ? classes[`item${TextUtil.ucWords(item.size)}`] : null
                    )}>
                      {item.color &&
                        <div className={classes.itemIndicator} style={{ backgroundColor: item.color }} />
                      }
                      <div className={classes.itemContent}>
                        <div className={classes.itemLabel}>
                          <div>
                            <Typography variant="body1">
                              {item.label}
                            </Typography>
                          </div>

                        </div>
                        <div className={classes.itemValue}>
                          <Typography variant="body1">
                            {item.value}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Grid>
            {chart &&
              <Grid item xs={12} sm={4}>
                {chart}
              </Grid>
            }
          </Grid>
        </div>

        {(amortization && amortization.balance > 0) &&
          <div className={classes.amortizationSchedule}>
            <Accordion>
              <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <div className={classes.amortizationScheduleTitle}>
                  <div className={classes.amortizationScheduleTitleIcon}>
                    <Icon>event_note</Icon>
                  </div>
                  <FormattedMessage id="amortization_schedule" />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="caption">
                      <FormattedMessage id="payment_period" />
                    </Typography>
                    <Typography variant="body1">
                      {DateUtil.formatDate(amortization.startDate)} <FormattedMessage id="to" /> {DateUtil.formatDate(amortization.endDate)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="caption">
                      <FormattedMessage id="loan_amount" />
                    </Typography>
                    <Typography variant="body1">
                      {amortization.balance.toMoney()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="caption">
                      <FormattedMessage id="total_interest" />
                    </Typography>
                    <Typography variant="body1">
                      {amortization.totalInterest.toMoney()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Typography variant="caption">
                      <FormattedMessage id="total_payment" />
                    </Typography>
                    <Typography variant="body1">
                      {amortization.totalPayment.toMoney()}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <AmortizationSchedule schedule={amortization.schedule} />
            </Accordion>
          </div>
        }
      </Section>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CalculatorResultSet);
