import React, {useEffect, useState} from 'react';
import {
  Button,
} from '@material-ui/core';
import {FormattedMessage} from "react-intl";
import {DialogState, ErrorState} from "../types";
import {Alert, ErrorList, LicensesDialogForm} from "./index";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../data/initialState";
import {ErrorUtil, ReduxUtil, UserUtil} from "../utils";
import {useStyles} from "../theme/jss/layouts/pageStyles";
import {getSubscription} from "../actions/subscription";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";

type Props = {
  className?: string
}

const NoLicensesAlert = (props:Props) => {
  const {className} = props;
  const classes = useStyles();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const {subscription, user} = useSelector((state:ReduxState) => ({
    subscription: state.subscription,
    user: state.user
  }));

  const [licenseDialog, setLicenseDialog] = useState<DialogState>({
    open: false,
    loading: false,
    errors: []
  });

  const [errors, setErrors] = useState<ErrorState[]>([]);

  useEffect(() => {
    async function init() {
      try {
        if(!ReduxUtil.hasData(subscription) && ReduxUtil.isIdle(subscription)) {
          await dispatch(getSubscription()).send();
        }
      } catch (e) {
        setErrors(ErrorUtil.formatErrors(e));
      }
    }

    if(user && UserUtil.hasRole(user.data, 'ROLE_OWNER')) {
      init();
    }
  }, [dispatch, subscription, user]);

  const onDialogClick = () => {
    setLicenseDialog({
      open: true,
      loading: false,
      errors: []
    });
  };

  const onDialogClose = () => {
    setLicenseDialog({
      open: false,
      loading: false,
      errors: []
    });
  };

  if(user && !UserUtil.hasRole(user.data, 'ROLE_OWNER')) {
    return null;
  }

  if(!subscription || (subscription && !subscription.data) || (subscription && subscription.data && subscription.data.licensesAvailable > 0)) {
    return null;
  }

  return (
    <div className={className}>
      <ErrorList errors={errors}
                 className={classes.mb2} />

      <LicensesDialogForm open={licenseDialog.open}
                          onClose={onDialogClose} />
      <Alert severity="danger" action={
        <Button variant="outlined"
                color="inherit"
                onClick={onDialogClick}>
          <FormattedMessage id="add_licenses" />
        </Button>
      }>
        <FormattedMessage id="no_user_licenses_available" />
      </Alert>
    </div>
  );
}

export default NoLicensesAlert;
