import React from 'react';
import styles from '../theme/jss/components/alertStyles';
import {WithStyles, withStyles, Typography, Icon, Grid} from "@material-ui/core";
import clsx from 'clsx';

type Props = {
  severity: 'success'|'info'|'warning'|'danger',
  title?: string,
  children: any,
  action?: React.ReactNode
} & WithStyles<typeof styles>;

type IconMap = Record<Props["severity"], string>

const Alert = (props:Props) => {
  const {classes, title, children, severity, action} = props;
  const iconMap:IconMap = {
    info: "info",
    warning: "warning",
    success: "done",
    danger: "error"
  };

  return (
    <div className={clsx(classes.root, classes[severity])}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={action ? 9 : 12}>
          <div className={classes.content}>
            <div className={classes.icon}>
              <Icon color="inherit">{iconMap[severity]}</Icon>
            </div>
            <div>
                {title &&
                  <Typography variant="subtitle1">
                    {title}
                  </Typography>
                }

                {children}
            </div>
          </div>
        </Grid>
        {action &&
          <Grid item xs={3}>
            <div className={classes.actionContainer}>
            {action}
            </div>
          </Grid>
        }
      </Grid>
    </div>
  )
};

export default withStyles(styles, { withTheme: true })(Alert);