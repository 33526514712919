import React, {useState, ChangeEvent} from "react";
import {Alert, Dialog, ErrorList, SubmitButton, TextField} from "../../../components";
import {Button, Grid, Icon, withStyles, WithStyles} from "@material-ui/core";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {ErrorUtil, TextUtil} from "../../../utils";
import {ErrorState, ReduxIntegrations} from "../../../types";
import styles from "../../../theme/jss/layouts/pageStyles";
import {LOSLoanOfficer} from "@jerseydev/orca-loans";
import Api from "../../../lib/Api";
import {ReduxState} from "../../../data/initialState";
import {connect} from "react-redux";
import _ from "lodash";
import IntlFormatter from "../../../intl";
import {Form} from "../../../forms";

type Props = {
  email?: string,
  integrations: ReduxIntegrations,
  onSubmit: (losLoanOfficer:LOSLoanOfficer) => void
} & WithStyles<typeof styles>
  & WrappedComponentProps


const LoanOfficerLosImportAlert = (props:Props) => {
  const {classes, integrations, intl} = props;
  const [email, setEmail] = useState<string>(props.email ? _.clone(props.email) : "");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);
  const [dialogErrors, setDialogErrors] = useState<ErrorState[]>([]);

  const onSubmit = async () => {
    try {
      setDialogLoading(true);
      const result = await Api.getLoanOfficerFromLosByEmail(email);
      props.onSubmit(_.cloneDeep(result.data));
      setDialogLoading(false);
    } catch (e) {
      setDialogErrors(ErrorUtil.formatErrors(e));
      setDialogLoading(false);
    }
  };

  const onEmailChange = (event:ChangeEvent<{value:string}>) => {
    setDialogErrors([]);
    setEmail(event.target.value)
  }

  if(integrations.data && !integrations.data.los) {
    return null;
  }

  const losName = TextUtil.ucWords(integrations.data.los!.provider);
  return (
    <div className={classes.mb3}>
      <Dialog open={dialogOpen}
              title={IntlFormatter.formatMessage(intl, 'import_loan_officer_info_from_name', {name: losName})}
              icon={<Icon>messages</Icon>}
              color="primaryAlt"
              onClose={() => setDialogOpen(false)}
              fullWidth={true}
              maxWidth="sm">
        {dialogErrors.length > 0 &&
          <ErrorList errors={[{
              message: IntlFormatter.formatMessage(intl, 'email_was_not_found_in_name', {
                name: TextUtil.ucWords(integrations.data.los!.provider),
                email
              })
          }]} className={classes.mb2} />
        }
        <Form onSubmit={onSubmit}>
          <div className={classes.mb2}>
            <TextField name="email"
                       label={IntlFormatter.formatMessage(intl, 'email')}
                       onChange={onEmailChange}
                       value={email}
                       fullWidth={true}
                       validators={['required', 'isEmail']}
                       errorMessages={[
                         IntlFormatter.formatMessage(intl, 'validation_required'),
                         IntlFormatter.formatMessage(intl, 'validation_email_invalid')
                       ]} />
          </div>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button onClick={() => setDialogOpen(false)}>
                <FormattedMessage id="cancel" />
              </Button>
            </Grid>
            <Grid item>
              <SubmitButton loading={dialogLoading}>
                <FormattedMessage id="import" />
              </SubmitButton>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
      <Alert severity="info" action={
        <Button variant="outlined" onClick={() => setDialogOpen(true)}>
          <FormattedMessage id="search" />
        </Button>
      }>
        <FormattedMessage id="import_loan_officer_info_from_name"
                          values={{name: losName}} />
      </Alert>
    </div>
  )
};

const mapStateToProps = (state:ReduxState) => {
  return {
    integrations: state.integrations
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(injectIntl(LoanOfficerLosImportAlert)));

