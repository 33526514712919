import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from './data';
import _ from "lodash";
import {AnyAction, Dispatch} from "redux";
import {ReduxState} from "../data/initialState";
import {ReduxAsyncOperation} from "../enums";
import {ActionResponse} from "../types";
import {ThunkAction} from "redux-thunk";
import {AxiosResponse} from "axios";

export function getInvoices(limit:number = 20):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getInvoices({limit}, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const invoices = state.invoices && state.invoices.data ? _.cloneDeep(state.invoices.data) : [];

      dispatch(setData(types.SET_INVOICES, ReduxAsyncOperation.FETCHING, invoices));

      apiRequest.then(response => {
        dispatch(setData(types.SET_INVOICES, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_INVOICES, ReduxAsyncOperation.IDLE, invoices));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}