import {defaultRadius, hexToRgb} from '../index';
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    padding: theme.spacing(1),
    borderRadius: defaultRadius
  },
  info: {
    backgroundColor: '#CFF3F8',
    color: '#166C7E',
    '& .MuiTypography-root, .MuiIconButton-root ': {
      color: '#166C7E'
    }
  },
  success: {
    backgroundColor: '#D1F4D9',
    color: '#166D30',
    '& .MuiTypography-root, .MuiIconButton-root ': {
      color: '#166D30'
    }
  },
  warning: {
    backgroundColor: '#FDE4D5',
    color: '#824224',
    '& .MuiTypography-root, .MuiIconButton-root ': {
      color: '#824224',
    }
  },
  danger: {
    backgroundColor: '#FDD6DA',
    color: '#841E30',
    '& .MuiTypography-root, .MuiIconButton-root ': {
      color: '#841E30'
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  actionContainer: {
    textAlign: 'right'
  },
  icon: {
    borderRadius: defaultRadius,
    backgroundColor: "rgba(" + hexToRgb(theme.palette.white) + ", .45)",
    height: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2)
  }
});

export default styles;
