import React, {Component} from 'react';
import {
  Icon,
  Tooltip, Typography, WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/loanTitleStyles';
import IntlFormatter from "../intl";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {
  LoanApplication2009,
  Borrower2009,
  LoanApplication,
  Borrower
} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
} & WrappedComponentProps
  & WithStyles<typeof styles>

class LoanTitle extends Component<Props> {
  renderTitle = () => {
    const {intl, classes, loanApplication} = this.props;
    let title = (
      <div className={classes.title}>
        <Icon fontSize="small">library_books</Icon>
        <Typography variant="body1">
          {IntlFormatter.formatMessage(intl, 'new_loan')}
        </Typography>
      </div>
    );
    if(loanApplication.borrowers && loanApplication.borrowers.length > 0) {
      title = (
        <div>
          {loanApplication.borrowers.map((borrower:Borrower2009|Borrower, i:number) => {
            return (
              <div key={i} className={classes.title}>
                <Tooltip title={IntlFormatter.formatMessage(intl, borrower.primaryBorrower ? 'borrower' : 'co_borrower')}>
                  <Icon fontSize="small">{borrower.primaryBorrower ? 'person' : 'person_outline'}</Icon>
                </Tooltip>

                <Typography variant="body1">
                  {borrower.fullName}
                </Typography>
              </div>
            )
          })}
        </div>
      )
    }

    return title;
  };

  render() {
    return (
      <div>
        {this.renderTitle()}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(LoanTitle));
