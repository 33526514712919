import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const styles = (theme:Theme) => createStyles({
  root: {
    color: theme.palette.neutral.main
  }
});

export default makeStyles(styles);
