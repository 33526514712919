import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {AdminDashboardPage} from '../pages';
import {Alert} from '../../../components';
import {AccountPage} from "../../../layouts";
import IntlFormatter from "../../../intl";
import {WithStyles, withStyles} from "@material-ui/core";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, ReduxUser} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  routeProps: ActionProps,
  user: ReduxUser,
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

class BranchManagerDashboardPage extends Component<Props> {

  render() {

    const { intl, user, classes, ...rest } = this.props;
    if(user.data.additionalProps && user.data.additionalProps.branch !== null && user.data.additionalProps.branch !== undefined) {
      return <AdminDashboardPage {...rest} />
    } else {
      return (
        <AccountPage pageTitle={IntlFormatter.formatMessage(intl, 'dashboard')}
                     loading={false}>
          <div className={classes.content}>
            <Alert severity="warning">
              <FormattedMessage id="branch_manager_not_assigned_to_branch_dashboard_text" />
            </Alert>
          </div>
        </AccountPage>
      )
    }
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(BranchManagerDashboardPage)));
