import React, {ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {
  FormControlLabel,
  Grid,
  Checkbox,
  withStyles, Typography, WithStyles,
} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl';
import {BaseForm, AddressFormFields} from "../../../forms";
import {
  ErrorList,
  SubmitButton,
  MoneyInput,
  PhoneNumberInput,
  TextField,
  RadioGroup,
  DatePicker,
} from '../../../components';
import _ from 'lodash';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import moment from "moment";
import {ActionProps, ErrorState, FixMeLater} from "../../../types";
import {
  Borrower,
  BorrowerCurrentEmployment,
  BorrowerCurrentEmploymentUpdateRequest,
  LoanApplication,
  LoanApplicationRequest,
  LoanApplicationUpdateRequest,
  Location,
} from "@jerseydev/orca-loans";
import {Moment} from "moment-timezone";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {connect} from "react-redux";
import {AxiosResponse} from "axios";
import {ErrorUtil} from "../../../utils";

type Props = {
  loanApplication: LoanApplication,
  borrower: Borrower,
  employment?: BorrowerCurrentEmployment|null,
  actions?: React.ReactNodeArray,
  onSubmit: (data:AxiosResponse<LoanApplication>) => void,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  name: string,
  title: string,
  selfEmployed: boolean,
  phoneNumber: string,
  address: {
    street1: string,
    city: string,
    province: string,
    postalCode: string
  },
  grossIncome: {
    base: string,
    overtime: string,
    bonus: string,
    commission: string,
    militaryEntitlements: string,
    other: string
  },
  employedByPartyInTransaction: boolean,
  ownership: {
    owner: boolean,
    ownershipOver25Percent: string,
    monthlyIncome: string
  },
  startDate: string|null,
  timeInLineOfWork: {
    years: string,
    months: string
  }
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class CurrentEmploymentForm extends BaseForm<Props, State> {
  beforeDateValidation:Moment = moment();

  constructor(props:Props) {
    super(props);

    let form:Form = {
      name: '',
      title: '',
      selfEmployed: false,
      phoneNumber: '',
      address: {
        street1: '',
        city: '',
        province: '',
        postalCode: ''
      },
      grossIncome: {
        base: '',
        overtime: '',
        bonus: '',
        commission: '',
        militaryEntitlements: '',
        other: ''
      },
      employedByPartyInTransaction: false,
      ownership: {
        owner: false,
        ownershipOver25Percent: '',
        monthlyIncome: ''
      },
      startDate: null,
      timeInLineOfWork: {
        years: '',
        months: ''
      }
    };

    if(props.employment) {
      const {name, title, selfEmployed, phoneNumber, address, grossIncome, employedByPartyInTransaction, ownership, startDate, timeInLineOfWork} = props.employment;
      form = {
        name: name ? name : '',
        title: title ? title : '',
        selfEmployed: typeof selfEmployed === 'boolean' ? selfEmployed : false,
        phoneNumber: phoneNumber ? phoneNumber : '',
        address: {
          street1: address && address.street1 ? address.street1 : '',
          city: address && address.city ? address.city : '',
          province: address && address.province ? address.province : '',
          postalCode: address && address.postalCode ? address.postalCode : ''
        },
        grossIncome: {
          base: grossIncome && grossIncome.base ? grossIncome.base.toString() : '',
          overtime: grossIncome && grossIncome.overtime ? grossIncome.overtime.toString() : '',
          bonus: grossIncome && grossIncome.bonus ? grossIncome.bonus.toString() : '',
          commission: grossIncome && grossIncome.commission ? grossIncome.commission.toString() : '',
          militaryEntitlements: grossIncome && grossIncome.militaryEntitlements ? grossIncome.militaryEntitlements.toString() : '',
          other: grossIncome && grossIncome.other ? grossIncome.other.toString() : ''
        },
        employedByPartyInTransaction: typeof employedByPartyInTransaction === 'boolean' ? employedByPartyInTransaction : false,
        ownership: {
          owner: ownership && ownership.owner ? ownership.owner : false,
          ownershipOver25Percent: ownership && typeof ownership.ownershipOver25Percent === 'boolean' ? ownership.ownershipOver25Percent  ? '1' : '0'  : '',
          monthlyIncome: ownership && ownership.monthlyIncome ? ownership.monthlyIncome.toString() : ''
        },
        startDate: startDate ? startDate : null,
        timeInLineOfWork: {
          years: timeInLineOfWork && timeInLineOfWork.years ? timeInLineOfWork.years.toString() : '',
          months: timeInLineOfWork && timeInLineOfWork.months ? timeInLineOfWork.months.toString() : ''
        }
      }
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };
  }
  
  onAddressChange = (address:Location) => {
    const form = _.cloneDeep(this.state.form);
    form.address = {
      street1: address && address.street1 ? address.street1 : '',
      city: address && address.city ? address.city : '',
      province: address && address.province ? address.province : '',
      postalCode: address && address.postalCode ? address.postalCode : ''
    };
    this.setState({ form });
  };

  setOwnershipOver25Percent = (event: ChangeEvent<HTMLInputElement>) => {
    const form = _.cloneDeep(this.state.form);
    form.ownership.ownershipOver25Percent = event.target.value;
    this.setState({ form });
  };

  onSubmit = async () => {
    try {
      const {loanApplication, borrower, employment} = this.props;
      this.setState({loading:true, errors: []});

      const {name, title, selfEmployed, phoneNumber, address, grossIncome, employedByPartyInTransaction, ownership, startDate, timeInLineOfWork} = this.state.form;
      let employmentRequest:BorrowerCurrentEmploymentUpdateRequest = {
        _id: employment ? employment._id : undefined,
        name,
        title,
        selfEmployed,
        phoneNumber,
        address: {
          street1: address.street1 !== '' ? address.street1 : null,
          city: address.city !== '' ? address.city : null,
          province: address.province !== '' ? address.province : null,
          postalCode: address.postalCode !== '' ? address.postalCode : null
        },
        grossIncome: {
          base: grossIncome.base ? parseInt(grossIncome.base) : null,
          overtime: grossIncome.overtime ? parseInt(grossIncome.overtime) : null,
          bonus: grossIncome.bonus ? parseInt(grossIncome.bonus) : null,
          commission: grossIncome.commission ? parseInt(grossIncome.commission) : null,
          militaryEntitlements: grossIncome.militaryEntitlements ? parseInt(grossIncome.militaryEntitlements) : null,
          other: grossIncome.other ? parseInt(grossIncome.other) : null
        },
        employedByPartyInTransaction,
        ownership: {
          owner: ownership.owner ? ownership.owner : null,
          ownershipOver25Percent: ownership.ownershipOver25Percent === '' ? null : ownership.ownershipOver25Percent === '1',
          monthlyIncome: ownership.monthlyIncome ? parseInt(ownership.monthlyIncome) : null
        },
        startDate,
        timeInLineOfWork: {
          years: parseInt(timeInLineOfWork.years),
          months: parseInt(timeInLineOfWork.months)
        }
      };

      const requestData:LoanApplicationUpdateRequest = {
        borrowers: [{
          _id: borrower._id,
          currentEmployment: [employmentRequest]
        }]
      };

      const result = await this.props.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      this.setState({loading:false}, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({loading:false, errors:ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, actions, classes } = this.props;
    const { loading, form, errors } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={6}>
              <TextField name="name"
                         label={IntlFormatter.formatMessage(intl, 'company_name')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.name')}
                         value={form.name}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField name="title"
                         label={IntlFormatter.formatMessage(intl, 'job_title')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.title')}
                         value={form.title}
                         fullWidth={true}
                         validators={['required']}
                         errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControlLabel label={IntlFormatter.formatMessage(intl, 'self_employed')} control={
                <Checkbox checked={form.selfEmployed}
                          onChange={() => this.onCheckboxChanged('form.selfEmployed')}
                          color="primary" />
              }
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.mb2}>
          <div className={classes.mb1}>
            <Typography variant="caption">
              <FormattedMessage id="time_in_this_line_of_work" />
            </Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2}>
              <TextField name="yearsInLineOfWork"
                         label={IntlFormatter.formatMessage(intl, 'years')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.timeInLineOfWork.years')}
                         value={form.timeInLineOfWork.years}
                         fullWidth={true}
                         validators={['required', 'minNumber:0', 'maxNumber:100']}
                         type="number"
                         errorMessages={[
                           IntlFormatter.formatMessage(intl, 'validation_required'),
                           IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                           IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                         ]} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField name="monthsInLineOfWork"
                         label={IntlFormatter.formatMessage(intl, 'months')}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.timeInLineOfWork.months')}
                         value={form.timeInLineOfWork.months}
                         fullWidth={true}
                         validators={['required', 'minNumber:0', 'maxNumber:11']}
                         type="number"
                         errorMessages={[
                           IntlFormatter.formatMessage(intl, 'validation_required'),
                           IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                           IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 11 })
                         ]} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker id="startDate"
                          label={IntlFormatter.formatMessage(intl, 'start_date')}
                          value={form.startDate}
                          onChange={(date:FixMeLater) => this.onDateChange(date, `form.startDate`)}
                          validators={[
                            'required',
                            `isBeforeDate:${this.beforeDateValidation.toISOString()}`,
                          ]}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_before_today')
                          ]}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <PhoneNumberInput name="phoneNumber"
                                label={IntlFormatter.formatMessage(intl, 'phone_number')}
                                value={form.phoneNumber}
                                fullWidth={true}
                                onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.phoneNumber')}
                                validators={['required']}
                                errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
            </Grid>
          </Grid>
        </div>

        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <AddressFormFields address={form.address}
                                 required={true}
                                 onChange={this.onAddressChange} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControlLabel label={IntlFormatter.formatMessage(intl, 'employed_by_party_in_transaction')} control={
                <Checkbox checked={form.employedByPartyInTransaction}
                          onChange={() => this.onCheckboxChanged('form.employedByPartyInTransaction')}
                          color="primary"/>
              }
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'base_income')}
                          value={form.grossIncome.base}
                          fullWidth={true}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.grossIncome.base')}
                          decimalScale={0}
                          validators={['required', 'minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'overtime')}
                          value={form.grossIncome.overtime}
                          fullWidth={true}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.grossIncome.overtime')}
                          decimalScale={0}
                          validators={['minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'bonus')}
                          value={form.grossIncome.bonus}
                          fullWidth={true}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.grossIncome.bonus')}
                          decimalScale={0}
                          validators={['minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'commission')}
                          value={form.grossIncome.commission}
                          fullWidth={true}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.grossIncome.commission')}
                          decimalScale={0}
                          validators={['minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'military_entitlements')}
                          value={form.grossIncome.militaryEntitlements}
                          fullWidth={true}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.grossIncome.militaryEntitlements')}
                          decimalScale={0}
                          validators={['minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'other')}
                          value={form.grossIncome.other}
                          fullWidth={true}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.grossIncome.other')}
                          decimalScale={0}
                          validators={['minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                          ]}/>
            </Grid>
          </Grid>
        </div>

        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControlLabel label={IntlFormatter.formatMessage(intl, 'business_owner_or_self_employed')} control={
                <Checkbox checked={form.ownership.owner}
                          onChange={() => this.onCheckboxChanged('form.ownership.owner')}
                          color="primary" />
              }
              />
            </Grid>
            {form.ownership.owner &&
            <Grid item xs={12} sm={6}>
              <div className={classes.mb2}>
                <RadioGroup name="ownershipOver25Percent"
                            itemValueProp="value"
                            value={form.ownership.ownershipOver25Percent}
                            onChange={this.setOwnershipOver25Percent}
                            items={[
                              { label: IntlFormatter.formatMessage(intl, 'ownership_less_25_percent'), value: '0' },
                              { label: IntlFormatter.formatMessage(intl, 'ownership_over_25_percent'), value: '1' }
                            ]}
                            validators={['required']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required')
                            ]} />
              </div>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'monthly_income_or_loss')}
                          value={form.ownership.monthlyIncome}
                          fullWidth={true}
                          validators={['required']}
                          decimalScale={0}
                          allowNegative={true}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required')
                          ]}
                          helperText={IntlFormatter.formatMessage(intl, 'enter_negative_number_for_loss')}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.ownership.monthlyIncome')}/>
            </Grid>
            }
          </Grid>
        </div>

        <div className={classes.mt3}>
          <Grid container alignItems="center" justifyContent="flex-end" >
            {actions && actions.length > 0 &&
            <Grid item>
              <div className={classes.mr1}>
                {actions.map((action,i) => {
                  return <span key={i}>{action}</span>
                })}
              </div>
            </Grid>
            }
            <Grid item>
              <SubmitButton loading={loading}>
                <FormattedMessage id="submit" />
              </SubmitButton>
            </Grid>
          </Grid>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(CurrentEmploymentForm)));
