import React from "react";
import {Integrations, LoanOfficer} from "@jerseydev/orca-loans";
import {Tooltip} from "../../../components";
import IntlFormatter from "../../../intl";
import {useIntl} from "react-intl";
import {Icon} from "@material-ui/core";
import {LoanOfficerUtil} from "../../../utils";
import useStyles from "../../../theme/jss/components/loanOfficerProfileCompleteIcon";

type Props = {
  loanOfficer: LoanOfficer,
  integrations: Integrations
}

const LoanOfficerProfileCompleteIcon = (props:Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const {loanOfficer, integrations} = props;

  const isProfileComplete = LoanOfficerUtil.getProfileIncompleteWarnings(loanOfficer, integrations).length === 0;
  if(isProfileComplete) {
    return null;
  }

  return (
    <Tooltip title={IntlFormatter.formatMessage(intl, 'profile_incomplete')}>
      <Icon className={classes.icon}>warning</Icon>
    </Tooltip>
  )
};

export default LoanOfficerProfileCompleteIcon;
