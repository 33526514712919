import React, {ChangeEvent} from 'react';
import {Form} from '../../../forms';
import {Grid, WithStyles, withStyles} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import BaseForm from "../../../forms/BaseForm";
import {SubmitButton, MoneyInput, Select, ErrorList} from '../../../components';
import _ from 'lodash';
import IntlFormatter from "../../../intl";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ActionProps, ErrorState, LabelValuePairType, ReduxApp} from "../../../types";
import {
  Borrower,
  BorrowerOtherIncome, BorrowerOtherIncomeRequest, BorrowerOtherIncomeUpdateRequest,
  LoanApplication,
  LoanApplicationRequest,
  LoanApplicationUpdateRequest
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {ErrorUtil} from "../../../utils";
import {AxiosResponse} from "axios";

type Props = {
  loanApplication: LoanApplication,
  borrower: Borrower,
  otherIncome?: BorrowerOtherIncome|null,
  actions?: React.ReactNodeArray,
  onSubmit: (data:AxiosResponse<LoanApplication>) => void,
  app: ReduxApp,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  type: string,
  value: string
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class IncomeForm extends BaseForm<Props, State> {
  incomeTypes:LabelValuePairType[] = [];

  constructor(props:Props) {
    super(props);

    let form:Form = {
      type: '',
      value: ''
    };

    if(props.otherIncome) {
      const {type, value} = props.otherIncome;
      form = {
        type: type ? type : '',
        value: value ? value.toString() : ''
      }
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };

    props.app.data.enums.otherIncomeSources.forEach(type => {
      this.incomeTypes.push({ value: type, label: IntlFormatter.formatMessage(props.intl, `income_type_${type}`) })
    });
  }

  setIncomeType = (type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.type = type ? type.value : null;
    this.setState({ form });
  };

  onSubmit = async () => {
    try {
      const {loanApplication, borrower, otherIncome} = this.props;
      this.setState({loading:true, errors: []});
      const {type, value} = this.state.form;

      const otherIncomeRequest:BorrowerOtherIncomeUpdateRequest = {
        _id: otherIncome ? otherIncome._id : undefined,
        type: type as BorrowerOtherIncomeRequest["type"],
        value: parseInt(value)
      };

      const requestData:LoanApplicationUpdateRequest = {
        borrowers: [{
          _id: borrower._id,
          otherIncome: [otherIncomeRequest]
        }]
      };

      const result = await this.props.updateLoanApplication(loanApplication._id, requestData, {merge:'append'}).send();
      this.setState({loading:false}, () => {
        if(this.props.onSubmit) {
          this.props.onSubmit(result);
        }
      });
    } catch (e) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const { intl, actions, classes } = this.props;
    const { loading, form, errors } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <div className={classes.mb2}>
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Select name="incomeType"
                        options={this.incomeTypes}
                        onChange={this.setIncomeType}
                        value={form.type ? this.incomeTypes.find(t => t.value === form.type) : ''}
                        validators={['required']}
                        errorMessages={[
                          IntlFormatter.formatMessage(intl, 'validation_required'),
                        ]}
                        label={IntlFormatter.formatMessage(intl, 'type')}
                        placeholder={IntlFormatter.formatMessage(intl, 'select')} />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'amount')}
                            value={form.value}
                            fullWidth={true}
                            decimalScale={0}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.value')} />
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid container alignItems="center" justifyContent="flex-end" >
          {actions && actions.length > 0 &&
          <Grid item>
            <div className={classes.mr1}>
              {actions.map((action,i) => {
                return <span key={i}>{action}</span>
              })}
            </div>
          </Grid>
          }
          <Grid item>
            <SubmitButton loading={loading}>
              <FormattedMessage id="submit" />
            </SubmitButton>
          </Grid>
        </Grid>

      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(IncomeForm)));
