import React, {Component} from 'react';
import {
  Card,
  CardContent, WithStyles,
  withStyles,
} from '@material-ui/core';
import {LoanSummary} from "./index";
import styles from "../theme/jss/components/loanCardStyles";
import {LoanApplication2009, LoanApplication} from "@jerseydev/orca-loans";

type Props = {
  loanApplication: LoanApplication2009|LoanApplication,
  actions: React.ReactNode
} & WithStyles<typeof styles>

class LoanCard extends Component<Props> {
  render() {
    const { classes, ...rest } = this.props;

    return (
      <Card className={classes.root}>
        <CardContent>
          <LoanSummary {...rest} />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LoanCard);
