import React, {ChangeEvent, Component} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {
  PageTitle,
  ErrorList,
  SubmitButton,
  AutoComplete,
  TextField,
  Alert,
  LinearProgress
} from '../../../components';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl/index";
import {
  ListItemIcon,
  ListItemText,
  Typography,
  Checkbox,
  withStyles,
  List,
  ListItem,
  Divider,
  Grid,
  Button,
  WithStyles, Dialog
} from "@material-ui/core";
import {ReduxUtil, ErrorUtil, IntegrationUtil, sleep} from "../../../utils";
import pageStyles from '../../../theme/jss/layouts/pageStyles';
import Api from "../../../lib/Api";
import _ from 'lodash';
import {Form} from '../../../forms';
import moment from "moment-timezone";
import {Link as RouterLink} from "react-router-dom";
import {
  ReduxBranches,
  ReduxSubscription,
  ActionProps,
  ErrorState,
  ReduxIntegrations
} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Branch, LoanOfficerLosImportRequest, LOSLoanOfficer} from "@jerseydev/orca-loans";
import {Mixpanel} from "mixpanel-browser";
import {AxiosPromise} from "axios";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel
  branches: ReduxBranches,
  integrations: ReduxIntegrations,
  subscription: ReduxSubscription,
  getSubscription: ActionProps["getSubscription"],
  importLoanOfficerFromLOS: ActionProps["importLoanOfficerFromLOS"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type LOSLoanOfficerState = {
  checked: boolean,
  branch: Branch|null
} & LOSLoanOfficer

type State = {
  pageLoaded: boolean,
  loading: boolean,
  errors: ErrorState[],
  losLoanOfficers: LOSLoanOfficerState[],
  importStatus: {
    total: number,
    completed: number,
    failed: number
  }
}

class LoanOfficersPage extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      pageLoaded: false,
      loading: false,
      errors: [],
      losLoanOfficers: [],
      importStatus: {
        total: 0,
        completed: 0,
        failed: 0
      }
    }
  }

  componentDidMount = async () => {
    if(IntegrationUtil.hasIntegration(this.props.integrations.data, 'los')) {
      try {
        const requests:AxiosPromise[] = [
          this.props.routeProps.getBranches().send()
        ]
        if(!ReduxUtil.hasData(this.props.subscription)) {
          requests.push(this.props.routeProps.getSubscription().send());
        }

        await Promise.all(requests);
        const losLoanOfficers = await this.getLosLoanOfficers();
        this.setState({ pageLoaded: true, losLoanOfficers });
      } catch(e) {
        this.setState({ pageLoaded: true, errors: ErrorUtil.formatErrors(e) });
      }
    } else {
      this.setState({ pageLoaded: true });
    }

  };

  getLosLoanOfficers = async ():Promise<LOSLoanOfficerState[]> => {
    const result = await Api.getLoanOfficersFromLosForImport();
    const losLoanOfficers:LOSLoanOfficerState[] = [];
    const activeLoanOfficers = result.data.filter(l => l.active);

    activeLoanOfficers.forEach(lo => {
      const losLoanOfficer:LOSLoanOfficerState = _.cloneDeep(lo) as LOSLoanOfficerState;
      losLoanOfficer.checked = false;
      if(!losLoanOfficer.nmlsId) {
        losLoanOfficer.nmlsId = '';
      }

      if(ReduxUtil.hasData(this.props.branches) && this.props.branches.data.length === 1) {
        losLoanOfficer.branch = this.props.branches.data[0];
      } else {
        losLoanOfficer.branch = null;
      }

      losLoanOfficers.push(losLoanOfficer);
    });

    return losLoanOfficers;
  };

  getPageTitle = () => {
    return IntlFormatter.formatMessage(this.props.intl, 'import_loan_officers');
  };

  renderTitleBar = () => {
    return <PageTitle title={this.getPageTitle()} icon="people_outline" />
  };

  onLoanOfficerChange = (loanOfficer:LOSLoanOfficerState) => {
    const losLoanOfficers = _.cloneDeep(this.state.losLoanOfficers);
    const index = losLoanOfficers.findIndex(l => l.id === loanOfficer.id);
    losLoanOfficers[index].checked = !losLoanOfficers[index].checked;

    this.setState({ losLoanOfficers });
  };

  searchBranches = async (searchText:string) => {
    const result = await Api.searchBranches({searchText});
    return result.data;
  };

  onBranchChange = (index:number, branch:Branch) => {
    const losLoanOfficers = _.cloneDeep(this.state.losLoanOfficers);
    losLoanOfficers[index].branch = _.cloneDeep(branch);
    this.setState({ losLoanOfficers });
  };

  onCheckAllChange = (event:ChangeEvent<HTMLInputElement>) => {
    const losLoanOfficers = _.cloneDeep(this.state.losLoanOfficers);

    for(let i=0;i<losLoanOfficers.length;i++) {
      losLoanOfficers[i].checked = event.target.checked;
    }

    this.setState({ losLoanOfficers });
  };

  onNmlsIdTextChange = (event:ChangeEvent<{value:string}>, index:number) => {
    const losLoanOfficers = _.cloneDeep(this.state.losLoanOfficers);
    losLoanOfficers[index].nmlsId = event.target.value;
    this.setState({ losLoanOfficers });
  };

  onImportSubmit = async () => {
    try {
      const losLoanOfficersToImport = _.cloneDeep(this.state.losLoanOfficers).filter(l => l.checked && l.branch && l.nmlsId !== '');

      this.setState({
        loading: true,
        importStatus: {
          total: losLoanOfficersToImport.length,
          completed: 0,
          failed: 0
        }
      }, async () => {
        const losLoanOfficers:LOSLoanOfficerState[] = _.cloneDeep(this.state.losLoanOfficers);
        await Promise.all(losLoanOfficersToImport.map(async (losLoanOfficer, i) => {
          const importStatus = _.clone(this.state.importStatus);
          try {
            const loanOfficerRequest:LoanOfficerLosImportRequest = {
              email: losLoanOfficer.email,
              branch: losLoanOfficer.branch!._id,
              timezone: moment.tz.guess()
            };
            await this.props.routeProps.importLoanOfficerFromLOS(loanOfficerRequest).send();

            importStatus.completed = importStatus.completed + 1;
            const index = losLoanOfficers.findIndex(l => l.email === losLoanOfficer.email);
            losLoanOfficers.splice(index, 1);
          } catch (e) {
            importStatus.failed = importStatus.failed + 1;
          }

          this.setState({importStatus});
        }));

        await sleep(1000);

        this.setState({
          losLoanOfficers,
          loading: false
        });
      });
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  isLoanOfficerValid = (loanOfficer:LOSLoanOfficerState) => {
    return loanOfficer.branch && loanOfficer.nmlsId !== '';
  };

  getImportPercentage = () => {
    const {importStatus} = this.state;
    let percentage = 0;
    if(importStatus.total > 0 && (importStatus.completed > 0 || importStatus.failed > 0)) {
      const recordsProcessed = importStatus.completed + importStatus.failed;
      percentage = Math.round((recordsProcessed / importStatus.total) * 100);
    }
    return percentage;
  };

  render() {
    const { intl, classes, integrations, branches, subscription } = this.props;
    const { pageLoaded, loading, losLoanOfficers, errors, importStatus } = this.state;

    const importCount = losLoanOfficers.filter(l => l.checked && l.branch && l.nmlsId !== '').length;
    const importPercentage = this.getImportPercentage();
    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   pageLoaded={pageLoaded}
                   titleBar={this.renderTitleBar()}
                   breadcrumbs={[
                    {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                    {title: IntlFormatter.formatMessage(intl, 'loan_officers'), to: '/admin/loan-officers' },
                    {title: this.getPageTitle() }
                  ]}>
        {(!loading && importStatus.total > 0) &&
          <div className={classes.p2}>
            <Alert severity={importStatus.failed === 0 ? "success" : "danger"}>
              <FormattedMessage id={importStatus.failed === 0 ? "successfully_imported_num_loan_officers" : "failed_to_import_num_loan_officers"}
                                values={{ num: importStatus.failed === 0 ? importStatus.completed : importStatus.failed}} />
            </Alert>
          </div>
        }
        {pageLoaded &&
          <div>
            <Dialog open={loading} maxWidth="sm" fullWidth={true}>
              <div className={classes.p4}>
                <Typography variant="body1">
                  <FormattedMessage id="importing_loan_officers_please_wait" />
                </Typography>
                <LinearProgress variant="determinate" value={importPercentage} />
              </div>
            </Dialog>

            {(subscription.data.licensesAvailable <= 0 || subscription.data.licensesAvailable < importCount) &&
              <div className={classes.p2}>
                <Alert severity="danger" action={
                  <Button variant="outlined" color="inherit" component={RouterLink} to="/subscription">
                    <FormattedMessage id="add_licenses"/>
                  </Button>
                }>
                  <div>
                    {subscription.data.licensesAvailable <= 0 &&
                      <FormattedMessage id="no_user_licenses_available"/>
                    }
                    {subscription.data.licensesAvailable > 0 &&
                    <FormattedMessage id="not_enough_user_licenses_available"
                                      values={{num: subscription.data.licensesAvailable}}/>
                    }
                  </div>
                </Alert>
              </div>
            }

            <ErrorList errors={errors}
                       className={classes.m2}
                       onClose={() => { this.setState({ errors: [] }); } } />

              {!IntegrationUtil.hasIntegration(integrations.data, 'los') &&
                <div className={classes.m2}>
                  <Alert severity="warning">
                    <FormattedMessage id="no_los_integration_to_import_from" />
                  </Alert>
                </div>
              }

              {losLoanOfficers.length > 0 &&
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        defaultChecked={false}
                        disableRipple
                        onChange={this.onCheckAllChange}
                      />

                    </ListItemIcon>
                    {importCount > 0 &&
                      <SubmitButton onClick={this.onImportSubmit} disabled={subscription.data.licensesAvailable < importCount}>
                        <FormattedMessage id="import_num_loan_officers" values={{ num: importCount }} />
                      </SubmitButton>
                    }
                  </ListItem>
                  <Divider />
                  <div className={classes.mt2}>
                    {losLoanOfficers.map((loanOfficer, i) => {
                      return (
                        <ListItem key={i}>
                          <Form onSubmit={() => {}} style={{ width: '100%' }}>
                            <Grid container spacing={2}>
                              <Grid item>
                                <Checkbox
                                  edge="start"
                                  checked={loanOfficer.checked}
                                  tabIndex={-1}
                                  disableRipple
                                  onChange={() => this.onLoanOfficerChange(loanOfficer)}
                                  disabled={!this.isLoanOfficerValid(loanOfficer)}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <ListItemText primary={`${loanOfficer.firstName} ${loanOfficer.lastName} (${loanOfficer.id})`}
                                              secondary={loanOfficer.email} />
                              </Grid>
                              <Grid item xs={3}>
                                {ReduxUtil.hasData(branches) &&
                                  <div>
                                    <AutoComplete value={loanOfficer.branch}
                                                  getOptionLabel={(item:Branch) => item.name ? item.name : ''}
                                                  onChange={(data:Branch) => this.onBranchChange(i, data)}
                                                  onTextChange={this.searchBranches}
                                                  label={IntlFormatter.formatMessage(intl, 'branch')}
                                                  getOptionSelected={(option:Branch, value:Branch) => {
                                                    return option._id === value._id;
                                                  }}
                                                  placeholder={IntlFormatter.formatMessage(intl, 'search')}
                                                  openOnFocus={true}
                                                  debounce={500}
                                                  validators={['required']}
                                                  errorMessages={[IntlFormatter.formatMessage(intl, 'validation_required')]} />
                                  </div>
                                }

                              </Grid>
                              <Grid item xs={3}>
                                <TextField name={`nmlsId${i}`}
                                           label={IntlFormatter.formatMessage(intl, 'nmls_id')}
                                           value={loanOfficer.nmlsId}
                                           fullWidth={true}
                                           onChange={(event:ChangeEvent<HTMLInputElement>) => this.onNmlsIdTextChange(event, i)}
                                           validators={['required']}
                                           errorMessages={[
                                             IntlFormatter.formatMessage(intl, 'validation_required')
                                           ]}/>
                              </Grid>
                            </Grid>
                          </Form>
                        </ListItem>
                      )
                    })}
                  </div>
                </List>
              }

            {(losLoanOfficers.length === 0) &&
              <div className={classes.content}>
                <Typography variant="body1">
                  <FormattedMessage id="no_results_found"/>
                </Typography>
              </div>
            }
          </div>
        }
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    branches: state.branches,
    integrations: state.integrations,
    subscription: state.subscription
  };
};

export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoanOfficersPage)));
