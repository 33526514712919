import React, {MouseEvent} from 'react';
import {
  Drawer,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemText,
  Hidden, ListItemSecondaryAction,
  Collapse,
  Badge, ListItemIcon, WithStyles,
} from '@material-ui/core';
import {withRouter, NavLink, Link, RouteComponentProps} from 'react-router-dom';
import {connect} from "react-redux";
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {AppLogo, AppBar} from "./index";
import {withStyles} from '@material-ui/core/styles';
import styles from '../theme/jss/components/drawerMenuStyles';
import IntlFormatter from "../intl";
import {RouteUtil} from "../utils";
import _ from 'lodash';
import clsx from "clsx";
import {ConfigMenuItem, ReduxAccount, ReduxUser} from "../types";
import {ReduxState} from "../data/initialState";

type DefaultProps = {
  open: boolean
}

type Props = {
  menuItems: ConfigMenuItem[],
  header?: React.ReactNode,
  user: ReduxUser,
  account: ReduxAccount
} & Partial<DefaultProps>
  & WrappedComponentProps
  & RouteComponentProps
  & WithStyles<typeof styles>

type State = {
  redirectToLogin: boolean,
  childMenus:any,
  open: boolean,
  accountMenuEl: Element|null
}

class DrawerMenu extends React.Component<Props, State> {
  static defaultProps:DefaultProps = {
    open: false
  };

  constructor(props:Props) {
    super(props);

    const childMenus:any = {};
    props.menuItems.forEach(item => {
      if(item.regex && props.location.pathname.match(item.regex)) {
        childMenus[item.id] = true;
      }
    });

    this.state = {
      open: props.open ? props.open : false,
      accountMenuEl: null,
      redirectToLogin: false,
      childMenus
    };

  }

  toggleMainMenu = () => this.setState({open: !this.state.open});

  toggleChildMenu = (event:MouseEvent, id:string) => {
    event.preventDefault();
    const { childMenus } = this.state;
    childMenus[id] = !childMenus[id];
    this.setState({ childMenus });
  };

  renderListItem = (item:ConfigMenuItem, key?:number) => {
    const { user, intl, location, classes, account } = this.props;
    const { childMenus } = this.state;
    let selected:boolean = location.pathname === item.to;
    if(!selected && item.children) { // check if the children are selected
      const childUrls:string[] = _.map(item.children.items, 'to');
      selected = childUrls.includes(location.pathname);
    }

    // check if it matches the regex
    if(!selected && item.regex) {
      selected = !!location.pathname.match(item.regex);
    }

    let itemLinkClasses = selected ? ' ' + classes.itemLinkActive : '';

    if(!RouteUtil.isGranted(user.data, item.to) || !RouteUtil.hasPlan(account.data, item.to)) {
      return null;
    }

    return (
      <div key={key}>
        <ListItem className={clsx(classes.itemLink, itemLinkClasses, item.disabled ? classes.itemLinkDisabled: null)}
                  // @ts-ignore
                  button={!item.disabled}
                  onClick={event => {
                    if(item.children) {
                      this.toggleChildMenu(event, item.id);
                    }
                  }}>
          {item.icon &&
            <ListItemIcon className={classes.itemIconContainer}>
              <div>
                {!item.badge &&
                  <Icon className={classes.itemIcon}>{item.icon}</Icon>
                }

                {item.badge &&
                  <Badge badgeContent={item.badge} color="secondary">
                    <Icon className={classes.itemIcon}>{item.icon}</Icon>
                  </Badge>
                }
              </div>
            </ListItemIcon>
          }
          <ListItemText primary={IntlFormatter.formatMessage(intl, item.labelId)}
                        className={childMenus[item.id] ? classes.itemText : classes.itemText}
                        disableTypography={true} />
          {(item.secondaryAction || item.children) &&
          <ListItemSecondaryAction>
            <div className={classes.itemSecondaryContainer}>
              {item.secondaryAction}
              {item.children && item.children.visible &&
                <IconButton onClick={event => this.toggleChildMenu(event, item.id)}>
                  <Icon className={classes.nestedItemExpandIcon}>
                    {childMenus[item.id] ? 'expand_more' : 'chevron_right'}
                  </Icon>
                </IconButton>
              }
            </div>
          </ListItemSecondaryAction>
          }
        </ListItem>
        {item.children && item.children.visible &&
          <Collapse in={childMenus[item.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.children.items.map(childItem => {
                const childSelected = childItem.to === location.pathname;

                if(!RouteUtil.isGranted(user.data, childItem.to) || !RouteUtil.hasPlan(account.data, childItem.to)) {
                  return null;
                }

                return (
                  <ListItem key={childItem.id}
                            className={childSelected ? clsx(classes.nestedItem, classes.nestedItemActive) : classes.nestedItem}
                            component={Link}
                            to={childItem.to}
                            button>
                    <ListItemIcon>
                      <Icon className={classes.nestedItemIcon}>lens</Icon>
                    </ListItemIcon>
                    {childItem.icon &&
                    <Icon className={classes.itemIcon}>{childItem.icon}</Icon>
                    }
                    <ListItemText primary={IntlFormatter.formatMessage(intl, childItem.labelId)}
                                  className={classes.nestedItemText}
                                  disableTypography={true} />
                  </ListItem>
                )
              })}
            </List>
          </Collapse>
        }
      </div>
    )
  };

  renderMenu = () => {

    const { classes, menuItems, header } = this.props;

    return (
      <div className={classes.menu}>

        <div className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <AppLogo size="sm" color="light" />
          </div>
          <div className={classes.divider}/>
        </div>
        <div>
          {header}
        </div>
        <div className={classes.menuItems}>
          <List className={classes.list}>
            {menuItems.map((item, i) => {
              if(item.disabled || item.children) {
                return this.renderListItem(item, i)
              } else {
                return (
                  <NavLink key={i} to={item.to} className={classes.navLink}>
                    {this.renderListItem(item)}
                  </NavLink>
                )
              }
            })}
          </List>
        </div>
      </div>
    )
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <AppBar onMenuButtonPress={this.toggleMainMenu}>
        <Hidden smUp implementation="css">
          <Drawer classes={{ paper: classes.drawerPaper }} anchor="left" open={open} variant="temporary" onClose={this.toggleMainMenu}>
            <IconButton onClick={this.toggleMainMenu}>
              <Icon className="drawer-link">chevron_left</Icon>
            </IconButton>
            <div className={classes.drawerItemsContainer}>
              {this.renderMenu()}
            </div>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer classes={{ paper: classes.drawerPaper }} anchor="left" open variant="permanent" onClose={this.toggleMainMenu}>
            <div className={classes.drawerItemsContainer}>
              {this.renderMenu()}
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    );
  }
}

const mapStateToProps = (state:ReduxState) => ({
  user: state.user,
  account: state.account
});

export default withRouter(connect(mapStateToProps)(withStyles(styles, { withTheme: true })(injectIntl(DrawerMenu))));