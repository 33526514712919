import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  WithStyles,
  withStyles
} from '@material-ui/core';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import {connect} from "react-redux";
import IntlFormatter from '../../../intl';
import BaseForm from "../../../forms/BaseForm";
import {
  ErrorList,
  AutoComplete,
  PhoneNumberInput,
  SubmitButton,
  MoneyInput,
  Select,
  RadioGroup,
  TextField,
  HelperTooltip, LoanOfficerProfileReference
} from '../../../components';
import _ from 'lodash';
import Api from '../../../lib/Api';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ErrorUtil, TextUtil} from "../../../utils";
import {
  ActionProps,
  ErrorState,
  LabelValuePairType,
  ReduxApp,
  ReduxLoanSettings,
  ReduxUser
} from "../../../types";
import {
  LoanApplicationLoanRefinance,
  LoanApplication,
  LoanApplicationLoan,
  LoanApplicationRequest,
  LoanSettings,
  LoanOfficerProfile
} from "@jerseydev/orca-loans";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {updateLoanApplication} from "../../../actions/loanApplication";
import {AxiosResponse} from "axios";

type Props = {
  loanApplication: LoanApplication,
  onSubmit?: (data:AxiosResponse<LoanApplication>) => void,
  onSubmitClick?: (e:MouseEvent) => void,
  onChange?: (data:Form) => void,
  app: ReduxApp,
  settings: ReduxLoanSettings,
  user: ReduxUser,
  updateLoanApplication: ActionProps["updateLoanApplication"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  loanOfficer: LoanOfficerProfile|null,
  loan: {
    loanPurpose: 'purchase' | 'refinance' | '',
    term: string,
    loanType: "fha" | "conventional" | "usda-rd" | "va" | "other" | '',
    loanAmount: string,
    downPayment: string,
    refinance?: {
      purpose: "cashOutDebtConsolidation" | "cashOutHomeImprovement" | "cashOutOther" | "noCashOut" | "limitedCashOut" | "",
      yearAcquired: string,
      originalCost: string,
      existingLiens: string,
      valueOfLot: string,
      costOfImprovements: string
    } | null
  },
  realtor?: {
    company: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  } | null
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[],
  hasRealtor: 'yes'|'no'|'',
  hasLoanOfficer: 'yes'|'no'|''
}

class LoanInfoForm extends BaseForm<Props, State> {
  loanTypes:LabelValuePairType[] = [];
  refinancePurposes:LabelValuePairType[] = [];
  loanTerms:LabelValuePairType[] = [];
  currentYear:number;

  constructor(props:Props) {
    super(props);

    this.currentYear = new Date().getFullYear();

    let form:Form = {
      loanOfficer: null,
      loan: {
        loanAmount: '',
        loanPurpose: '',
        loanType: '',
        downPayment: '',
        term: ''
      }
    }

    if(props.loanApplication) {
      const {loanOfficer, loan, realtor} = props.loanApplication;
      form = {
        loanOfficer: loanOfficer ? loanOfficer : null,
        loan: {
          loanAmount: loan && loan.loanAmount ? loan.loanAmount.toString() : '',
          loanPurpose: loan && loan.loanPurpose ? loan.loanPurpose : '',
          loanType: loan && loan.loanType ? loan.loanType : '',
          downPayment: loan && loan.downPayment ? loan.downPayment.toString() : '',
          term: loan && loan.term ? loan.term.toString() : ''
        }
      };

      if(loan && loan.refinance) {
        const {originalCost, existingLiens, costOfImprovements, purpose, valueOfLot, yearAcquired} = loan.refinance;
        form.loan.refinance = {
          originalCost: originalCost ? originalCost.toString() : '',
          existingLiens: existingLiens ? existingLiens.toString() : '',
          costOfImprovements: costOfImprovements ? costOfImprovements.toString() : '',
          purpose: purpose ? purpose : '',
          valueOfLot: valueOfLot ? valueOfLot.toString() : '',
          yearAcquired: yearAcquired ? yearAcquired.toString() : ''
        }
      }

      if(realtor) {
        const {firstName, lastName, company, email, phoneNumber} = realtor;
        form.realtor = {
          firstName: firstName ? firstName : '',
          lastName: lastName ? lastName : '',
          email: email ? email : '',
          company: company ? company : '',
          phoneNumber: phoneNumber ? phoneNumber : ''
        };
      }
    }

    const {realtor, loanOfficer} = props.loanApplication;

    this.state = {
      loading: false,
      form,
      hasRealtor: realtor === undefined ? '' : props.loanApplication.realtor ? 'yes' : 'no',
      hasLoanOfficer: loanOfficer === undefined || loanOfficer === null ? '' : props.loanApplication.loanOfficer ? 'yes' : 'no',
      errors: []
    };

    props.app.data.enums.loanTypes.forEach(type => {
      if(props.settings.data.loanTypes.includes(type as LoanSettings["loanTypes"][0])) {
        this.loanTypes.push({
          value: type,
          label: IntlFormatter.formatMessage(props.intl, `loan_types_${type}`),
          helpText: IntlFormatter.formatMessage(props.intl, `loan_types_${type}_help`)
        })
      }
    });

    props.app.data.enums.refinancePurposes.forEach(type => {
      if(props.settings.data.refinancePurposes.includes(type as LoanSettings["refinancePurposes"][0])) {
        this.refinancePurposes.push({
          value: type,
          label: IntlFormatter.formatMessage(props.intl, `refinance_purpose_${type}`),
          helpText: IntlFormatter.formatMessage(props.intl, `refinance_purpose_${type}_help`)
        })
      }
    });

    this.loanTerms = [
      { value: '120', label: IntlFormatter.formatMessage(props.intl, 'number_years', { number: 10 })},
      { value: '180', label: IntlFormatter.formatMessage(props.intl, 'number_years', { number: 15 })},
      { value: '360', label: IntlFormatter.formatMessage(props.intl, 'number_years', { number: 30 })}
    ];

    if(!this.state.form.loanOfficer && props.user.data.additionalProps && props.user.data.additionalProps.defaultLoanOfficer) {
      this.state.form.loanOfficer = _.cloneDeep(props.user.data.additionalProps.defaultLoanOfficer);
    }
  }

  componentDidUpdate = (nextProps:Props, nextState:State) => {
    if(this.props.onChange && !_.isEqual(this.state.form, nextState.form)) {
      this.props.onChange(_.cloneDeep(nextState.form));
    }
  };

  setLoanPurpose = (event:ChangeEvent<{value:string}>) => {
    const form = _.cloneDeep(this.state.form);
    form.loan.loanPurpose = event.target.value as Form["loan"]["loanPurpose"];

    if(form.loan.loanPurpose === 'purchase') {
      delete form.loan.refinance;
    } else {
      form.loan.refinance = {
        purpose: '',
        yearAcquired: '',
        originalCost: '',
        existingLiens: '',
        valueOfLot: '',
        costOfImprovements: ''
      };
    }

    this.setState({ form });
  };

  setLoanOfficer = (loanOfficer:LoanOfficerProfile) => {
    const form = _.cloneDeep(this.state.form);
    form.loanOfficer = loanOfficer;
    this.setState({ form });
  };

  searchLoanOfficers = async (searchText:string):Promise<LoanOfficerProfile[]> => {
    const result = await Api.searchLoanOfficerProfiles({ searchText });
    return result.data;
  };

  setHasLoanOfficer = (event:ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as 'yes'|'no';
    this.setState({ hasLoanOfficer: value });
  };

  setRealtor = (event:ChangeEvent<HTMLInputElement>) => {
    const form = _.cloneDeep(this.state.form);
    const value = event.target.value as 'yes'|'no';
    if(value === 'no') {
      form.realtor = null;
    } else {
      form.realtor = {
        company: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      };
    }

    this.setState({ hasRealtor: value, form });
  };

  setLoanType = (type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.loan.loanType = type ? type.value : null;

    this.setState({ form });
  };

  setLoanTerm = (term:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    form.loan.term = term ? term.value : null;
    this.setState({ form });
  };

  setRefinancePurpose = (type:LabelValuePairType) => {
    const form = _.cloneDeep(this.state.form);
    if(form.loan.refinance) {
      form.loan.refinance.purpose = type ? type.value : null;
    }

    this.setState({ form });
  };

  onSubmit = async (event:MouseEvent) => {
    try {
      if(this.props.onSubmitClick) {
        this.props.onSubmitClick(event);
      }
      this.setState({ loading: true, errors: [] });

      const {loanApplication} = this.props;
      const {loan, realtor, loanOfficer} = this.state.form;
      const {loanAmount, loanPurpose, loanType, refinance, term, downPayment} = loan;
      const loanRequest:LoanApplicationLoan = {
        loanAmount: parseInt(loanAmount),
        loanPurpose: loanPurpose as LoanApplicationLoan["loanPurpose"],
        loanType: loanType as LoanApplicationLoan["loanType"],
        term: parseInt(term),
        downPayment: downPayment !== '' ? parseInt(downPayment)  : null
      };

      if(refinance) {
        const {originalCost, existingLiens, purpose, costOfImprovements, valueOfLot, yearAcquired} = refinance;
        loanRequest.refinance = {
          originalCost: parseInt(originalCost),
          existingLiens: parseInt(existingLiens),
          purpose: purpose as LoanApplicationLoanRefinance["purpose"],
          costOfImprovements: costOfImprovements !== '' ? parseInt(costOfImprovements) : null,
          valueOfLot: valueOfLot !== '' ? parseInt(valueOfLot) : null,
          yearAcquired: yearAcquired !== '' ? parseInt(yearAcquired) : null
        }
      } else {
        loanRequest.refinance = null;
      }

      const requestData:LoanApplicationRequest = {
        loan: loanRequest,
        loanOfficer: loanOfficer ? loanOfficer._id : null,
        realtor: realtor ? realtor : null
      };

      const result = await this.props.updateLoanApplication(loanApplication._id, requestData).send();
      this.setState({ loading: false });
      if(this.props.onSubmit) {
        this.props.onSubmit(result);
      }
    } catch (e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  render() {

    const { intl, children, settings, classes } = this.props;
    const { form, hasRealtor, hasLoanOfficer, errors } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>

        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => { this.setState({ errors: [] }); } } />

        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12} sm={12}>
              <RadioGroup name="loanPurpose"
                          label={IntlFormatter.formatMessage(intl, 'loan_purpose')}
                          itemValueProp="value"
                          value={form.loan.loanPurpose}
                          onChange={this.setLoanPurpose}
                          items={[
                            { label: IntlFormatter.formatMessage(intl, 'purchase'), value: 'purchase' },
                            { label: IntlFormatter.formatMessage(intl, 'refinance'), value: 'refinance' }
                          ]}
                          validators={['required']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required')
                          ]}
                          row />
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Select name="loanType"
                      options={this.loanTypes}
                      onChange={this.setLoanType}
                      value={form.loan.loanType ? this.loanTypes.find(t => t.value === form.loan.loanType) : ''}
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required')
                      ]}
                      label={IntlFormatter.formatMessage(intl, 'loan_type')}
                      placeholder={IntlFormatter.formatMessage(intl, 'select')} />
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Select name="loanTerm"
                      options={this.loanTerms}
                      onChange={this.setLoanTerm}
                      label={IntlFormatter.formatMessage(intl, 'loan_term')}
                      placeholder={IntlFormatter.formatMessage(intl, 'select')}
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required')
                      ]}
                      value={form.loan.term ? this.loanTerms.find(t => t.value === form.loan.term) : ''}/>
            </Grid>
            {form.loan.loanPurpose === 'refinance' &&
            <Grid item md={3} xs={12} sm={4}>
              <Select name="refinancePurpose"
                      options={this.refinancePurposes}
                      onChange={this.setRefinancePurpose}
                      value={form.loan.refinance && form.loan.refinance.purpose ? this.refinancePurposes.find(t => t.value === form.loan.refinance!.purpose) : ''}
                      validators={['required']}
                      errorMessages={[
                        IntlFormatter.formatMessage(intl, 'validation_required')
                      ]}
                      label={IntlFormatter.formatMessage(intl, 'refinance_purpose')}
                      placeholder={IntlFormatter.formatMessage(intl, 'select')}/>
            </Grid>
            }
          </Grid>
        </div>
        <div className={classes.mb2}>
          <Grid container spacing={2}>
            {settings.data.tridFields.includes('loanAmount') &&
            <Grid item xs={12} sm={4} md={3}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'loan_amount')}
                          value={form.loan.loanAmount}
                          fullWidth={true}
                          decimalScale={0}
                          validators={['required', 'minNumber:0', 'maxNumber:500000000', `isGreaterThan:${form.loan.downPayment}`]}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                            IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 500000000 }),
                            IntlFormatter.formatMessage(intl, 'validation_greater_than_field', { field: IntlFormatter.formatMessage(intl, 'down_payment') })
                          ]}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loan.loanAmount')} />

            </Grid>
            }
            {form.loan.loanPurpose === 'purchase' &&
            <Grid item xs={12} sm={4} md={3}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'down_payment')}
                          value={form.loan.downPayment}
                          fullWidth={true}
                          decimalScale={0}
                          validators={['required', 'minNumber:0', `isLessThan:${form.loan.loanAmount}`]}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                            IntlFormatter.formatMessage(intl, 'validation_less_than_field', { field: IntlFormatter.formatMessage(intl, 'loan_amount') })
                          ]}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loan.downPayment')}/>

            </Grid>
            }

            {(form.loan.loanPurpose === 'refinance' && form.loan.refinance) &&
            <Grid item xs={12} sm={4} md={3}>
              <MoneyInput label={IntlFormatter.formatMessage(intl, 'original_cost')}
                          value={form.loan.refinance.originalCost}
                          fullWidth={true}
                          decimalScale={0}
                          validators={['required', 'minNumber:0']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required'),
                            IntlFormatter.formatMessage(intl, 'validation_minvalue', {value: 0})
                          ]}
                          onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loan.refinance.originalCost')}/>
            </Grid>
            }

            {(form.loan.loanPurpose === 'refinance' && form.loan.refinance) &&
            <Grid item xs={12} sm={4} md={3}>
              <TextField name="yearAcquired"
                         label={IntlFormatter.formatMessage(intl, 'year_acquired')}
                         value={form.loan.refinance.yearAcquired}
                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loan.refinance.yearAcquired')}
                         type="number"
                         fullWidth={true}
                         validators={['required', 'minNumber:1900', `maxNumber:${this.currentYear}`]}
                         errorMessages={[
                           IntlFormatter.formatMessage(intl, 'validation_required'),
                           IntlFormatter.formatMessage(intl, 'validation_minvalue', {value: 1900}),
                           IntlFormatter.formatMessage(intl, 'validation_maxvalue', {value: this.currentYear})
                         ]}/>
            </Grid>
            }
          </Grid>
        </div>

        {(form.loan.loanPurpose === 'refinance' && form.loan.refinance) &&
        <div>
          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={2}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'existing_liens')}
                            value={form.loan.refinance.existingLiens}
                            fullWidth={true}
                            helperText={<HelperTooltip tooltip={IntlFormatter.formatMessage(intl, 'existing_liens_help')}
                                                       text={IntlFormatter.formatMessage(intl, 'whats_this')} />}
                            decimalScale={0}
                            validators={['required', 'minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required'),
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loan.refinance.existingLiens')} />
              </Grid>
              {settings.data.tridFields.includes('propertyValue') &&
              <Grid item xs={12} sm={4} md={2}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'value_of_land')}
                            value={form.loan.refinance.valueOfLot}
                            fullWidth={true}
                            decimalScale={0}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loan.refinance.valueOfLot')}
                            helperText={TextUtil.ucWords(IntlFormatter.formatMessage(intl, 'optional'))}
                            validators={['minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]}/>
              </Grid>
              }
              {settings.data.tridFields.includes('propertyValue') &&
              <Grid item xs={12} sm={4} md={2}>
                <MoneyInput label={IntlFormatter.formatMessage(intl, 'value_of_home')}
                            value={form.loan.refinance.costOfImprovements}
                            fullWidth={true}
                            decimalScale={0}
                            onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loan.refinance.costOfImprovements')}
                            helperText={TextUtil.ucWords(IntlFormatter.formatMessage(intl, 'optional'))}
                            validators={['minNumber:0']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                            ]} />
              </Grid>
              }
            </Grid>
          </div>
        </div>
        }

        <div className={classes.mb2}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <RadioGroup name="workingWithLoanOfficer"
                          label={IntlFormatter.formatMessage(intl, 'working_with_loan_officer')}
                          itemValueProp="value"
                          value={hasLoanOfficer}
                          onChange={this.setHasLoanOfficer}
                          items={[
                            {label: IntlFormatter.formatMessage(intl, 'yes'), value: 'yes'},
                            {label: IntlFormatter.formatMessage(intl, 'no'), value: 'no'}
                          ]}
                          validators={['required']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required')
                          ]}
                          row/>
              {hasLoanOfficer === 'yes' &&
                <div className={classes.mt2}>
                  <AutoComplete value={form.loanOfficer}
                                getOptionLabel={(item:LoanOfficerProfile) => {
                                  return `${item.firstName} ${item.lastName}`;
                                }}
                                onChange={this.setLoanOfficer}
                                onTextChange={this.searchLoanOfficers}
                                renderOption={(option:LoanOfficerProfile) => (
                                  <React.Fragment>
                                    <LoanOfficerProfileReference loanOfficer={option} />
                                  </React.Fragment>
                                )}
                                getOptionSelected={(option:LoanOfficerProfile, value:LoanOfficerProfile) => {
                                  return option._id === value._id;
                                }}
                                label={IntlFormatter.formatMessage(intl, 'loan_officer')}
                                placeholder={IntlFormatter.formatMessage(intl, 'search_loan_officers')}
                                openOnFocus={true}
                                debounce={500}/>
                </div>
              }
            </Grid>

            {form.loan.loanPurpose === 'purchase' &&
            <Grid item md={6} xs={12} sm={12}>
              <RadioGroup name="workingWithRealtor"
                          label={IntlFormatter.formatMessage(intl, 'working_with_realtor')}
                          itemValueProp="value"
                          value={hasRealtor}
                          onChange={this.setRealtor}
                          items={[
                            {label: IntlFormatter.formatMessage(intl, 'yes'), value: 'yes'},
                            {label: IntlFormatter.formatMessage(intl, 'no'), value: 'no'}
                          ]}
                          validators={['required']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required')
                          ]}
                          row/>

              {form.realtor &&
              <div className={classes.mt2}>
                <div className={classes.mb2}>
                  <TextField name="realtor-company"
                             label={IntlFormatter.formatMessage(intl, 'company')}
                             value={form.realtor.company}
                             fullWidth={true}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.realtor.company')}/>
                </div>
                <div className={classes.mb2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField name="realtorFirstName"
                                 label={IntlFormatter.formatMessage(intl, 'first_name')}
                                 value={form.realtor.firstName}
                                 fullWidth={true}
                                 onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.realtor.firstName')}
                                 validators={['required']}
                                 errorMessages={[
                                   IntlFormatter.formatMessage(intl, 'validation_required')
                                 ]}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name="realtorLastName"
                                 label={IntlFormatter.formatMessage(intl, 'last_name')}
                                 value={form.realtor.lastName}
                                 fullWidth={true}
                                 onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.realtor.lastName')}
                                 validators={['required']}
                                 errorMessages={[
                                   IntlFormatter.formatMessage(intl, 'validation_required')
                                 ]}/>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.mb2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField name="realtorEmail"
                                 label={IntlFormatter.formatMessage(intl, 'email')}
                                 onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.realtor.email')}
                                 value={form.realtor.email}
                                 fullWidth={true}
                                 validators={['isEmail']}
                                 errorMessages={[IntlFormatter.formatMessage(intl, 'validation_email_invalid')]}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <PhoneNumberInput name="realtorPhoneNumber"
                                        label={IntlFormatter.formatMessage(intl, 'phone_number')}
                                        value={form.realtor.phoneNumber}
                                        fullWidth={true}
                                        onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.realtor.phoneNumber')}/>
                    </Grid>
                  </Grid>
                </div>
              </div>
              }
            </Grid>
            }
          </Grid>

        </div>


        {children}

        {!children &&
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <SubmitButton>
              <FormattedMessage id="save" />
            </SubmitButton>
          </Grid>
        </Grid>
        }
      </Form>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    app: state.app,
    settings: state.loanSettings,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanApplication(id:string, data:LoanApplicationRequest, params?:any) {
    return dispatch(updateLoanApplication(id, data, params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(LoanInfoForm)));
