import * as types from './types';
import Api, {createCancelSource} from '../lib/Api';
import {setData} from "./data";
import _ from "lodash";
import {ReduxAsyncOperation} from "../enums";
import {ReduxState} from "../data/initialState";
import {AnyAction, Dispatch} from "redux";
import {AxiosResponse} from "axios";
import {ThunkAction} from "redux-thunk";
import {ActionResponse} from "../types";

export function getRoles(queryParams?:any):ThunkAction<ActionResponse, ReduxState, any, AnyAction> {
  return (dispatch:Dispatch, getState:()=>ReduxState) => {
    const cancelSource = createCancelSource();
    const apiRequest = Api.getRoles(queryParams, {cancelToken:cancelSource.token});
    const request:Promise<AxiosResponse> = new Promise((resolve, reject) => {
      const state = getState();
      const roles = state.roles && state.roles.data ? _.cloneDeep(state.roles.data) : [];
      dispatch(setData(types.SET_ROLES, ReduxAsyncOperation.FETCHING, roles));

      apiRequest.then(response => {
        dispatch(setData(types.SET_ROLES, ReduxAsyncOperation.IDLE, response.data));
        resolve(response);
      }).catch((ex) => {
        dispatch(setData(types.SET_ROLES, ReduxAsyncOperation.IDLE, roles));
        reject(ex);
      });
    });

    return { send: () => request, cancel: cancelSource.cancel };
  };
}
