import React, {ChangeEvent, MouseEvent} from 'react';
import {
  Button,
  Divider,
  Grid,
  Icon,
  Card,
  withStyles,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  ListItemIcon, Menu, FormControlLabel, Checkbox, WithStyles
} from '@material-ui/core';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {injectIntl, FormattedMessage, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {LoanApplicationPage} from "../layouts";
import {ErrorUtil, LoanRev2Util, ReduxUtil} from "../../../utils";
import BaseLoanApplicationPage from "./BaseLoanApplicationPage";
import _ from 'lodash';
import {GiftForm} from '../forms';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {ErrorList, Dialog, Fab, DeleteDialog} from "../../../components";
import {LoanApplicationControls} from "../components";
import {ReduxState} from "../../../data/initialState";
import {
  ActionProps,
  DialogState,
  ErrorState,
  ReduxLoanApplication,
  ReduxLoanSettings
} from "../../../types";
import {Mixpanel} from "mixpanel-browser";
import {Borrower, BorrowerGift, LoanApplicationUpdateRequest} from "@jerseydev/orca-loans";

type Props = {
  routeProps: ActionProps,
  mixpanel: Mixpanel,
  loanApplication: ReduxLoanApplication,
  settings: ReduxLoanSettings
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps

type State = {
  loading: boolean,
  selectedBorrower: Borrower|null,
  selectedGift: BorrowerGift|null,
  redirectTo?: string,
  errors: ErrorState[],
  actionMenuEl: Element|null,
  giftDialog: DialogState,
  deleteDialog: DialogState,
  isStepCompleted?: boolean
}

class GiftPage extends BaseLoanApplicationPage<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      loading: false,
      selectedBorrower: null,
      selectedGift: null,
      errors: [],
      actionMenuEl: null,
      giftDialog: {
        open: false,
        loading: false,
        errors: []
      },
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      }
    };
  }

  static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: Readonly<State>){
    if(nextProps.loanApplication && nextProps.loanApplication.data && prevState.isStepCompleted === undefined){
      return {isStepCompleted: LoanRev2Util.isGiftsCompleted(nextProps.loanApplication.data)};
    }
    return null;
  };

  componentWillUnmount = () => {
    const {mixpanel, loanApplication} = this.props;
    if(loanApplication.data && !this.state.isStepCompleted && LoanRev2Util.isGiftsCompleted(loanApplication.data)) {
      mixpanel.track("Loan application step completed", {step: 'gifts'});
    }
  };

  onAddClick = (borrower:Borrower) => {
    this.setState({
      selectedBorrower: _.cloneDeep(borrower),
      giftDialog: {
        open: true,
        loading: false,
        errors: []
      }
    });
  };

  onEditClick = () => {
    this.setState({
      actionMenuEl: null,
      giftDialog: {
        open: true,
        loading: false,
        errors: []
      }
    });
  };

  hideFormDialog = () => {
    this.setState({
      selectedGift: null,
      giftDialog: {
        open: false,
        loading: false,
        errors: []
      }
    });
  };

  onGiftFormSubmit = () => {
    this.setState({
      loading: false,
      selectedGift: null,
      selectedBorrower: null,
      giftDialog: {
        open: false,
        loading: false,
        errors: []
      }
    });
  };

  onShowActionMenu = (event:MouseEvent, borrower:Borrower, gift:BorrowerGift) => {
    this.setState({
      selectedBorrower: _.cloneDeep(borrower),
      actionMenuEl: event.currentTarget,
      selectedGift: _.cloneDeep(gift)
    });
  };

  onCloseActionMenu = () => {
    this.setState({
      selectedBorrower: null,
      actionMenuEl: null,
      selectedGift: null,
      giftDialog: {
        open: false,
        loading: false,
        errors: []
      }
    });
  };

  onDeleteCancel = () => {
    this.setState({
      selectedBorrower: null,
      selectedGift: null,
      deleteDialog: {
        open: false,
        loading: false,
        errors: []
      }
    });
  };

  onDeleteClick = () => {
    this.setState({
      actionMenuEl: null,
      deleteDialog: {
        open: true,
        loading: false,
        errors: []
      }
    });
  };

  onDeleteSubmit = async () => {
    try {
      const {loanApplication} = this.props;
      const {selectedBorrower, selectedGift} = this.state;

      if(loanApplication.data.borrowers && selectedBorrower && selectedGift) {
        const deleteDialog = _.clone(this.state.deleteDialog);
        deleteDialog.loading = true;
        this.setState({deleteDialog});

        if(selectedBorrower.gifts) {
          const borrowerIndex = loanApplication.data.borrowers.findIndex(b => b._id === selectedBorrower._id);
          const index = selectedBorrower.gifts.findIndex(e => e._id === selectedGift._id);
          await this.props.routeProps.updateLoanApplication(loanApplication.data._id, [`borrowers[${borrowerIndex}].gifts[${index}]`], {merge:'delete'}).send();
        }
      }

      this.setState({
        selectedGift: null,
        deleteDialog: {
          open: false,
          loading: false,
          errors: []
        }
      });
    } catch (e) {
      const deleteDialog = _.clone(this.state.deleteDialog);
      deleteDialog.loading = false;
      deleteDialog.errors = ErrorUtil.formatErrors(e);
      this.setState({deleteDialog});
    }
  };

  onNoGiftsChange = async (event:ChangeEvent<HTMLInputElement>, borrowerIndex:number) => {
    try {
      const checked = event.target.checked;
      this.setState({ loading: true });
      const {loanApplication} = this.props;
      if(loanApplication.data.borrowers && loanApplication.data.borrowers[borrowerIndex]) {
        const borrowerRequestData = LoanRev2Util.borrowerToUpdateRequest(loanApplication.data.borrowers[borrowerIndex]);
        borrowerRequestData.gifts = checked ? null : [];
        const requestData:LoanApplicationUpdateRequest = {
          borrowers: [borrowerRequestData]
        };
        await this.props.routeProps.updateLoanApplication(loanApplication.data._id, requestData, {merge:'append'}).send();
      }
      this.setState({ loading: false });
    } catch (e) {
      this.setState({
        loading: false,
        errors: ErrorUtil.formatErrors(e)
      });
    }
  }

  onNextClick = () => {
    this.setState({ loading: false, redirectTo: this.nextMenuItem.to });
  };

  render() {

    const { intl, classes, loanApplication } = this.props;
    const { loading, selectedBorrower, selectedGift, redirectTo, errors, actionMenuEl, giftDialog, deleteDialog } = this.state;

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <LoanApplicationPage menuId="gifts"
                           loading={loading}>

        <Dialog open={giftDialog.open}
                icon={<Icon>attach_money</Icon>}
                title={IntlFormatter.formatMessage(intl, selectedGift ? 'edit_gift' : 'add_gift')}
                color="primaryAlt"
                onClose={this.hideFormDialog}
                fullWidth={true}
                maxWidth="sm"
                fullScreen>
          {selectedBorrower &&
            <GiftForm loanApplication={loanApplication.data}
                      borrower={selectedBorrower}
                      gift={selectedGift}
                      onSubmit={this.onGiftFormSubmit}
                      actions={[
                        <Button onClick={this.hideFormDialog}>
                          <FormattedMessage id="cancel" />
                        </Button>
                      ]}/>
          }
        </Dialog>

        <DeleteDialog open={deleteDialog.open}
                      title={IntlFormatter.formatMessage(intl, 'delete_gift')}
                      item={selectedGift ? IntlFormatter.formatMessage(intl, `gift_source_${selectedGift.source}`) : ''}
                      loading={deleteDialog.loading}
                      errors={deleteDialog.errors}
                      onCancel={this.onDeleteCancel}
                      onSubmit={this.onDeleteSubmit} />

        <Menu anchorEl={actionMenuEl}
              open={Boolean(actionMenuEl)}
              onClose={this.onCloseActionMenu}>

          <MenuItem onClick={this.onEditClick}>
            <ListItemIcon>
              <Icon>mode_edit</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'edit')} />
          </MenuItem>
          <MenuItem onClick={this.onDeleteClick}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            <ListItemText primary={IntlFormatter.formatMessage(intl, 'delete')} />
          </MenuItem>
        </Menu>

        <div>
          <div className={classes.content}>

            <ErrorList errors={errors}
                       className={classes.mv2}
                       onClose={() => { this.setState({ errors: [] }); } } />

            {(ReduxUtil.hasData(loanApplication) && loanApplication.data.borrowers) &&
            <Grid container spacing={4}>
              {loanApplication.data.borrowers.map((borrower, i) => {
                return (
                  // @ts-ignore
                  <Grid key={i} item xs={12} sm={12} md={loanApplication.data.borrowers.length > 1 ? Math.floor(12 / loanApplication.data.borrowers.length) : 6}>
                    <Card className={classes.card}>
                      <CardHeader title={`${borrower.firstName} ${borrower.lastName}`}
                                  subheader={IntlFormatter.formatMessage(intl, borrower.primaryBorrower ? 'borrower' : 'co_borrower')}
                                  avatar={<Icon>{borrower.primaryBorrower ? 'person' : 'person_outline'}</Icon>}
                                  action={
                                    <Fab color="primary"
                                         size="small"
                                         flat
                                         rounded
                                         onClick={() => this.onAddClick(borrower)}>
                                      <Icon>add</Icon>
                                    </Fab>
                                  } />
                      {borrower.gifts &&
                      <div>
                        <Divider/>
                        <CardContent>
                          {borrower.gifts.length > 0 &&
                          <List>
                            {borrower.gifts.map((gift, i) => {
                              return (
                                <ListItem key={i}>
                                  <ListItemText primary={IntlFormatter.formatMessage(intl, gift.source ? `gift_source_${gift.source}` : 'gift')}
                                                secondary={gift.value ? gift.value.toMoney() : undefined}/>
                                  <ListItemSecondaryAction>
                                    <IconButton onClick={event => this.onShowActionMenu(event, borrower, gift)}>
                                      <Icon>more_vert</Icon>
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            })}
                          </List>
                          }

                          {(borrower.gifts.length === 0) &&
                          <div className={classes.mt2}>
                            <Button variant="outlined"
                                    color="primary"
                                    onClick={() => this.onAddClick(borrower)}>
                              <FormattedMessage id="add_gift"/>
                            </Button>
                          </div>
                          }
                        </CardContent>
                      </div>
                      }

                      <Divider />
                      <div className={classes.p2}>
                        <FormControlLabel label={IntlFormatter.formatMessage(intl, 'no_gifts_label')} control={
                          <Checkbox checked={!borrower.gifts}
                                    onChange={(event) => this.onNoGiftsChange(event, i)}
                                    color="primary" />
                        }
                        />
                      </div>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
            }
          </div>
          <div className={classes.content}>
            <LoanApplicationControls prev={this.previousMenuItem}
                                     next={this.nextMenuItem}
                                     onNextClick={this.onNextClick} />
          </div>
        </div>

      </LoanApplicationPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    loanApplication: state.loanApplication,
    settings: state.loanSettings
  };
};


export default connect(mapStateToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(GiftPage)));
