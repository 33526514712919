import React, { Component, MouseEvent } from 'react';
import {
  Button, DialogProps,
  Icon, WithStyles,
  withStyles
} from '@material-ui/core';
import styles from '../theme/jss/components/deleteDialogStyles';
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {ColorButton, ErrorList, Dialog} from "./index";
import IntlFormatter from "../intl";
import {ErrorState} from "../types";

type DefaultProps = {
  fullWidth: boolean,
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
  errors: ErrorState[],
  loading: boolean
}

type Props = {
  title: string,
  item?: string|null,
  message?: string|null,
  confirmationMessage?: string|null,
  secondConfirmationMessage?: string|null,
  cancelButtonLabel?: string|null,
  deleteButtonLabel?: string|null,
  onCancel?: () => void,
  onSubmit: (e:MouseEvent) => void
} & Partial<DefaultProps>
  & DialogProps
  & WrappedComponentProps
  & WithStyles<typeof styles>

type State = {
  firstConfirmation: boolean
}

class DeleteDialog extends Component<Props, State> {
  /*static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    item: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
    loading: PropTypes.bool,
    errors: PropTypes.array,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    confirmationMessage: PropTypes.string,
    secondConfirmationMessage: PropTypes.string,
    deleteButtonLabel: PropTypes.string
  };*/

  static defaultProps:DefaultProps = {
    fullWidth: true,
    maxWidth: 'xs',
    loading: false,
    errors: []
  };

  constructor(props:Props) {
    super(props);

    this.state = {
      firstConfirmation: false
    };
  }

  componentDidUpdate = (prevProps:Props) => {
    if(prevProps.open !== this.props.open){
      this.setState({ firstConfirmation: false });
    }
  };

  onFirstConfirmation = () => {
    this.setState({ firstConfirmation: true });
  };

  onCancel = () => {
    this.setState({ firstConfirmation: false }, () => {
      if(this.props.onCancel) {
        this.props.onCancel();
      }
    });
  };

  getConfirmationMessage = ():string => {
    const {intl, confirmationMessage, item} = this.props;

    return confirmationMessage ? confirmationMessage : IntlFormatter.formatMessage(intl, 'delete_confirmation', { value: item });
  };

  getSecondConfirmationMessage = ():string => {
    const {intl, secondConfirmationMessage, item} = this.props;

    return secondConfirmationMessage ? secondConfirmationMessage : IntlFormatter.formatMessage(intl, 'delete_second_confirmation', { value: item });
  };

  onSubmit = (event:MouseEvent) => {
    if(this.props.onSubmit && !this.props.loading) {
      this.props.onSubmit(event);
    }
  }

  render() {
    const { intl, classes, open, errors, loading, cancelButtonLabel, deleteButtonLabel, maxWidth, fullWidth, fullScreen } = this.props;
    const { firstConfirmation } = this.state;

    return (
      <Dialog open={open}
              onClose={this.onCancel}
              title={firstConfirmation ? this.getSecondConfirmationMessage() : this.getConfirmationMessage()}
              subtitle={firstConfirmation ? IntlFormatter.formatMessage(intl, 'are_you_sure_you_want_to_continue') : undefined}
              color="dangerAlt"
              icon={<Icon>warning</Icon>}
              variant="confirm"
              maxWidth={maxWidth}
              fullWidth={fullWidth}
              fullScreen={fullScreen}>
        <ErrorList errors={errors ? errors : []}
                   className={classes.errors} />

        <div className={classes.actions}>
          <Button onClick={this.onCancel} className={classes.cancelButton}>
            {cancelButtonLabel}
            {!cancelButtonLabel &&
            <FormattedMessage id="cancel"/>
            }
          </Button>
          {!firstConfirmation &&
            <ColorButton color="success" onClick={this.onFirstConfirmation}>
              <FormattedMessage id="yes"/>
            </ColorButton>
          }
          {firstConfirmation &&
            <ColorButton color="danger"
                         variant="contained"
                         onClick={this.onSubmit}
                         loading={loading}>
              {deleteButtonLabel}
              {!deleteButtonLabel &&
                <FormattedMessage id="delete"/>
              }
            </ColorButton>
          }
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(DeleteDialog));
