import React, {ChangeEvent, MouseEvent} from 'react';
import {Form} from '../../../forms';
import {
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  withStyles,
  Button,
  MenuItem,
  ListItemText,
  InputLabel,
  Select, WithStyles,
} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {
  AutoComplete,
  ErrorList, HelperTooltip,
  SubmitButton,
  TextField,
} from "../../../components";
import _ from 'lodash';
import {connect} from "react-redux";
import {updateLoanSettings} from "../../../actions/settings";
import {AccountUtil, ErrorUtil} from "../../../utils";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import Api from "../../../lib/Api";
import clsx from 'clsx';
import {ReduxApp, ActionProps, ErrorState, ReduxAccount} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {AxiosResponse} from "axios";
import {AccountRequest, Branch, LoanOfficer, LoanSettings, LoanSettingsRequest} from "@jerseydev/orca-loans";
import {updateAccount} from "../../../actions/account";
import {Mixpanel} from "mixpanel-browser";

type Props = {
  mixpanel: Mixpanel,
  settings: LoanSettings,
  onSubmit: (data: AxiosResponse<LoanSettings>) => void,
  onCancel?: () => void,
  app: ReduxApp,
  account: ReduxAccount,
  updateLoanSettings: ActionProps["updateLoanSettings"],
  updateAccount: ActionProps["updateAccount"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  tridFields: LoanSettings["tridFields"],
  loanTypes: LoanSettings["loanTypes"],
  stateLicenses: LoanSettings["stateLicenses"],
  refinancePurposes: LoanSettings["refinancePurposes"],
  loanStartedTitle?: LoanSettings["loanStartedTitle"],
  loanStartedMessage?: LoanSettings["loanStartedMessage"],
  defaultLoanOfficer?: LoanOfficer,
  defaultBranch?: Branch,
  loanCompletedMessage?: LoanSettings["loanCompletedMessage"],
  abandonedLoanDays: number
}

type State = {
  loading: boolean,
  form: Form,
  errors: ErrorState[]
}

class LoanSettingsForm extends BaseForm<Props, State> {
  constructor(props: Props) {
    super(props);

    let form: Form = {
      tridFields: [],
      loanTypes: [],
      stateLicenses: [],
      refinancePurposes: [],
      abandonedLoanDays: 7
    };

    if (props.settings) {
      const {
        tridFields,
        loanTypes,
        stateLicenses,
        refinancePurposes,
        abandonedLoanDays,
        defaultLoanOfficer,
        loanStartedTitle,
        loanStartedMessage,
        loanCompletedMessage
      } = props.settings;

      form = {
        tridFields,
        loanTypes,
        stateLicenses,
        refinancePurposes,
        abandonedLoanDays,
        defaultLoanOfficer,
        loanCompletedMessage,
        loanStartedTitle,
        loanStartedMessage,
      }
    }

    this.state = {
      loading: false,
      form,
      errors: []
    };
  }

  onTridFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const form = _.cloneDeep(this.state.form);
    const value: LoanSettings["tridFields"][0] = event.target.value as LoanSettings["tridFields"][0];
    const index = form.tridFields.indexOf(value);
    if (index === -1) {
      form.tridFields.push(value);
    } else {
      form.tridFields.splice(index, 1);
    }

    this.setState({form});
  };

  onLoanTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const form = _.cloneDeep(this.state.form);
    const value: LoanSettings["loanTypes"][0] = event.target.value as LoanSettings["loanTypes"][0];
    const index = form.loanTypes.indexOf(value);
    if (index === -1) {
      form.loanTypes.push(value);
    } else {
      form.loanTypes.splice(index, 1);
    }

    this.setState({form});
  };

  onRefinancePurposeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const form = _.cloneDeep(this.state.form);
    const value: LoanSettings["refinancePurposes"][0] = event.target.value as LoanSettings["refinancePurposes"][0];
    const index = form.refinancePurposes.indexOf(value);
    if (index === -1) {
      form.refinancePurposes.push(value);
    } else {
      form.refinancePurposes.splice(index, 1);
    }

    this.setState({form});
  };

  setLoanOfficer = (loanOfficer: LoanOfficer) => {
    const form = _.cloneDeep(this.state.form);
    form.defaultLoanOfficer = _.cloneDeep(loanOfficer);
    this.setState({form});
  };

  searchLoanOfficers = async (searchText: string) => {
    const result = await Api.searchLoanOfficers({searchText});
    return result.data;
  };

  onStateLicenseChange = (event: ChangeEvent<{ value: LoanSettings["stateLicenses"] }>) => {
    const form = _.cloneDeep(this.state.form);
    form.stateLicenses = _.clone(event.target.value);
    this.setState({form});
  };

  renderStateLicenses = (selected: LoanSettings["stateLicenses"]) => {
    let abbreviations: string[] = [];

    selected.forEach(s => {
      abbreviations.push(s.toUpperCase());
    });

    return abbreviations.join(',');
  };

  onSubmit = async (event: MouseEvent) => {
    event.preventDefault();
    try {
      this.setState({loading: true, errors: []});
      const {
        loanTypes,
        tridFields,
        refinancePurposes,
        stateLicenses,
        defaultLoanOfficer,
        loanCompletedMessage,
        loanStartedTitle,
        loanStartedMessage,
        abandonedLoanDays
      } = this.state.form;
      const settingsRequest: LoanSettingsRequest = {
        loanTypes,
        tridFields,
        refinancePurposes,
        stateLicenses,
        defaultLoanOfficer: defaultLoanOfficer!._id,
        loanCompletedMessage,
        loanStartedTitle,
        loanStartedMessage,
        abandonedLoanDays
      }
      const result = await this.props.updateLoanSettings(settingsRequest).send();
      this.props.mixpanel.track('Loan settings updated');
      const account = this.props.account.data;
      if (!AccountUtil.isSetupRecommendationUsed('loanSettings', account)) {
        let requestData: AccountRequest = AccountUtil.addSetupRecommendationToRequest('loanSettings', {}, this.props.account.data);
        await this.props.updateAccount(requestData).send();
      }
      this.setState({loading: false}, () => {
        this.props.onSubmit(_.cloneDeep(result));
      });
    } catch (e) {
      this.setState({loading: false, errors: ErrorUtil.formatErrors(e)});
    }
  };

  render() {

    const {intl, classes, app} = this.props;
    const {form, loading, errors} = this.state;

    return (
      <div>
        <ErrorList errors={errors}
                   className={classes.mv2}
                   onClose={() => {
                     this.setState({errors: []});
                   }}/>

        <Form onSubmit={this.onSubmit}>
          <div className={classes.mb2}>
            <AutoComplete value={form.defaultLoanOfficer}
                          getOptionLabel={(item: LoanOfficer) => {
                            return item.firstName ? `${item.firstName} ${item.lastName}` : '';
                          }}
                          onChange={this.setLoanOfficer}
                          onTextChange={this.searchLoanOfficers}
                          getOptionSelected={(option: LoanOfficer, value: LoanOfficer) => {
                            return option._id === value._id;
                          }}
                          label={IntlFormatter.formatMessage(intl, 'default_loan_officer')}
                          placeholder={IntlFormatter.formatMessage(intl, 'search_loan_officers')}
                          helperText={<HelperTooltip text={IntlFormatter.formatMessage(intl, 'whats_this')}
                                                     tooltip={IntlFormatter.formatMessage(intl, 'default_loan_officer_settings_help')} />}
                          required={true}
                          openOnFocus={true}
                          debounce={500}/>

          </div>

          <div className={classes.mb2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <HelperTooltip text={IntlFormatter.formatMessage(intl, 'trid_fields')}
                                   tooltip={IntlFormatter.formatMessage(intl, 'trid_fields_help')}/>
                  </FormLabel>
                  <FormGroup>
                    {app.data.enums.tridFields.map((field: LoanSettings["tridFields"][0], i: number) => {
                      return (
                        <FormControlLabel key={i}
                                          control={<Checkbox checked={form.tridFields.includes(field)}
                                                             onChange={this.onTridFieldChange} value={field}/>}
                                          label={IntlFormatter.formatMessage(intl, `trid_field_${field}`)}/>
                      )
                    })}
                  </FormGroup>
                  <FormHelperText>
                    <FormattedMessage id="select_all_to_include"/>
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl component="fieldset" error={form.loanTypes.length === 0}>
                  <FormLabel component="legend">
                    <HelperTooltip text={IntlFormatter.formatMessage(intl, 'loan_types')}
                                   tooltip={IntlFormatter.formatMessage(intl, 'loan_types_settings_help')}/>
                  </FormLabel>
                  <FormGroup>
                    {app.data.enums.loanTypes.map((type: LoanSettings["loanTypes"][0], i: number) => {
                      return (
                        <FormControlLabel key={i}
                                          control={<Checkbox checked={form.loanTypes.includes(type)}
                                                             onChange={this.onLoanTypeChange} value={type}/>}
                                          label={IntlFormatter.formatMessage(intl, `loan_type_${type}`)}/>
                      )
                    })}
                  </FormGroup>
                  <FormHelperText>
                    {form.loanTypes.length === 0 &&
                    <span>
                        <FormattedMessage id="validation_at_least_one_value_is_required"/><br/>
                      </span>
                    }
                    <FormattedMessage id="select_all_to_include"/>
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl component="fieldset" error={form.refinancePurposes.length === 0}>
                  <FormLabel component="legend">
                    <HelperTooltip text={IntlFormatter.formatMessage(intl, 'refinance_purposes')}
                                   tooltip={IntlFormatter.formatMessage(intl, 'refinance_purposes_settings_help')}/>
                  </FormLabel>
                  <FormGroup>
                    {app.data.enums.refinancePurposes.map((type: LoanSettings["refinancePurposes"][0], i: number) => {
                      return (
                        <FormControlLabel key={i}
                                          control={<Checkbox checked={form.refinancePurposes.includes(type)}
                                                             onChange={this.onRefinancePurposeChange} value={type}/>}
                                          label={IntlFormatter.formatMessage(intl, `refinance_purpose_${type}`)}/>
                      )
                    })}
                  </FormGroup>
                  <FormHelperText>
                    {form.loanTypes.length === 0 &&
                    <span>
                        <FormattedMessage id="validation_at_least_one_value_is_required"/><br/>
                      </span>
                    }
                    <FormattedMessage id="select_all_to_include"/>
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.mb2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="state-licenses-label">
                <FormattedMessage id="company_state_licenses"/>
              </InputLabel>
              <Select
                labelId="state-licenses-label"
                id="state-licenses-select"
                multiple
                value={form.stateLicenses}
                renderValue={this.renderStateLicenses}
                onChange={this.onStateLicenseChange}
                label={IntlFormatter.formatMessage(intl, 'company_state_licenses')}
              >
                {app.data.enums.states.map((state: LoanSettings["stateLicenses"][0]) => (
                  <MenuItem key={state} value={state}>
                    <Checkbox checked={form.stateLicenses.indexOf(state) > -1}/>
                    <ListItemText primary={IntlFormatter.formatMessage(intl, `state_${state}`)}/>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={clsx(classes.rowCenter, classes.mt2)}>
            <div className={classes.mr1}>
              <FormattedMessage id="abandon_loan_after"/>
            </div>
            <div className={classes.mr1} style={{width: 50}}>
              <TextField name="abandonLoanDays"
                         onChange={(event: ChangeEvent<{ value: string }>) => this.onTextChange(event, `form.abandonedLoanDays`)}
                         value={form.abandonedLoanDays}
                         type="number"
                         validators={['required', 'minNumber:0']}
                         errorMessages={[
                           IntlFormatter.formatMessage(intl, 'validation_required'),
                           IntlFormatter.formatMessage(intl, 'validation_minvalue', {value: 0})
                         ]}/>
            </div>
            <FormattedMessage id="days_without_changes"/>
          </div>

          <div className={classes.mt2}>
            <TextField fullWidth={true}
                       name="loanStartedTitle"
                       value={form.loanStartedTitle}
                       helperText={IntlFormatter.formatMessage(intl, 'loan_application_start_title_helper_text')}
                       label={IntlFormatter.formatMessage(intl, 'loan_application_start_title')}
                       onChange={(event: ChangeEvent<{ value: string }>) => this.onTextChange(event, 'form.loanStartedTitle')}/>
          </div>

          <div className={classes.mt2}>
            <TextField rows={6}
                       multiline={true}
                       fullWidth={true}
                       name="loanStartedMessage"
                       value={form.loanStartedMessage}
                       label={IntlFormatter.formatMessage(intl, 'loan_application_start_message')}
                       helperText={IntlFormatter.formatMessage(intl, 'loan_application_start_message_helper_text')}
                       onChange={(event: ChangeEvent<{ value: string }>) => this.onTextChange(event, 'form.loanStartedMessage')}/>
          </div>

          <div className={classes.mt2}>
            <TextField name="loanCompletedMessage"
                       label={IntlFormatter.formatMessage(intl, 'loan_completed_message')}
                       helperText={IntlFormatter.formatMessage(intl, 'loan_completed_message_helper_text', {
                         firstName: '{firstName}',
                         lastName: '{lastName}',
                         email: '{email}'
                       })}
                       value={form.loanCompletedMessage}
                       multiline={true}
                       rows={6}
                       fullWidth={true}
                       onChange={(event: ChangeEvent<{ value: string }>) => this.onTextChange(event, 'form.loanCompletedMessage')}/>
          </div>
          <div className={classes.mt2}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              {this.props.onCancel &&
              <Grid item>
                <Button onClick={this.props.onCancel}>
                  <FormattedMessage id="cancel"/>
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading} disabled={(!form.defaultLoanOfficer || form.loanTypes.length === 0)}>
                  <FormattedMessage id="save"/>
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    app: state.app,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  updateLoanSettings(data: LoanSettingsRequest) {
    return dispatch(updateLoanSettings(data));
  },
  updateAccount(data: AccountRequest) {
    return dispatch(updateAccount(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, {withTheme: true})(injectIntl(LoanSettingsForm)));
