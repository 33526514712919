import React, {RefObject, MouseEvent, ChangeEvent} from 'react';
import {Form} from '../../../forms';

import {Grid, Button, withStyles, Icon, WithStyles} from '@material-ui/core';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from '../../../intl/index';
import BaseForm from "../../../forms/BaseForm";
import {
  ErrorList, FormSection,
  MoneyInput, PercentageTextField,
  Select,
  SubmitButton,
  TextField
} from "../../../components/index";
import {CalculatorUtil, ErrorUtil} from '../../../utils';
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import Api from '../../../lib/Api';
import {Bar, ChartComponentProps} from "react-chartjs-2";
import CalculatorResults, {CalculatorResultsProps} from "../components/CalculatorResults";
import {ErrorState, FixMeLater, LabelNumberType} from "../../../types";
import themePalette from "../../../theme/jss/palette";
import {RefinanceCalculatorRequest, RefinanceCalculatorResponse} from "@jerseydev/orca-loans";
import {CalculatorResultSetProps} from "../components/CalculatorResultSet";
import {Mixpanel} from "mixpanel-browser";

const palette:FixMeLater = themePalette;

type Props = {
  mixpanel: Mixpanel,
  onSubmit?: (data:RefinanceCalculatorResponse) => void,
  onCancel?: () => void
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>

type Form = {
  loanAmount: string,
  currentInterestRate: string,
  currentTerm: string,
  refiInterestRate: string,
  refiTerm: string,
  monthsPaid: string,
  yearsBeforeSale: string,
  points: string,
  originationFees: string,
  closingCosts: string,
  taxRate: string,
  stateTaxRate: string
}

type State = {
  loading: boolean,
  form: Form,
  result?: CalculatorResultsProps|null,
  errors: ErrorState[],
}

class RefinanceForm extends BaseForm<Props, State> {
  terms:LabelNumberType[] = [];
  resultsRef:RefObject<HTMLDivElement>;

  chartColors:string[] = [
    palette.info.main,
    palette.success.main,
  ];

  chartOptions = CalculatorUtil.getBarChartOptions();

  constructor(props:Props) {
    super(props);

    this.resultsRef = React.createRef();

    /*const testFormData = {
      loanAmount: 250000,
      currentInterestRate: 5.875,
      currentTerm: 30,
      refiInterestRate: 4.875,
      refiTerm: 30,
      monthsPaid: 36,
      yearsBeforeSale: 5,
      points: 1,
      originationFees: 0,
      closingCosts: 1200,
      taxRate: 26,
      stateTaxRate: .5
    };*/

    this.state = {
      loading: false,
      form: {
        loanAmount: '',
        currentInterestRate: '',
        currentTerm: '',
        refiInterestRate: '',
        refiTerm: '',
        monthsPaid: '',
        yearsBeforeSale: '',
        points: '',
        originationFees: '',
        closingCosts: '',
        taxRate: '',
        stateTaxRate: ''
      },
      result: null,
      errors: [],
    };

    //this.state.form = testFormData;

    this.terms = CalculatorUtil.getSelectLoanTerms(props.intl);
  }

  onSubmit = async (event:MouseEvent) => {
    event.preventDefault();

    try {
      this.setState({ loading: true, result: null, errors: [] });
      const {loanAmount, currentInterestRate, currentTerm, refiInterestRate, refiTerm, monthsPaid, yearsBeforeSale, points, originationFees, closingCosts, taxRate, stateTaxRate} = this.state.form;
      const requestData:RefinanceCalculatorRequest = {
        loanAmount: parseInt(loanAmount),
        currentInterestRate: parseFloat(currentInterestRate),
        currentTerm: parseInt(currentTerm),
        refiInterestRate: parseFloat(refiInterestRate),
        refiTerm: parseInt(refiTerm),
        monthsPaid: parseInt(monthsPaid),
        yearsBeforeSale: parseInt(yearsBeforeSale),
        points: parseFloat(points),
        originationFees: parseFloat(originationFees),
        closingCosts: parseInt(closingCosts),
        taxRate: parseFloat(taxRate),
        stateTaxRate: parseFloat(stateTaxRate)
      };
      const result = await Api.calculateRefinance(requestData);
      this.setState({
        loading: false,
        result: {
          summary: result.data.description,
          results: [{
            amortization: result.data.refi.amortization,
            chart: <Bar data={this.getChartData(result.data)} options={this.chartOptions} redraw />,
            items: this.getResultItems(result.data)
          }]
        }
      });

      this.resultsRef.current!.scrollIntoView({behavior:'smooth'});
      this.props.mixpanel.track("Mortgage Calculator", { name: "Refinance", action: "submitted" });
      if(this.props.onSubmit) {
        this.props.onSubmit(result.data);
      }
    } catch(e) {
      this.onError(e);
    }
  };

  onError = (err:any) => {
    this.setState({ loading: false, errors: ErrorUtil.formatErrors(err) });
  };

  getChartData = (resultData:RefinanceCalculatorResponse):ChartComponentProps["data"] => {
    const { intl } = this.props;

    return {
      datasets: [
        {
          label: IntlFormatter.formatMessage(intl, 'current_monthly_payment'),
          data: [
            resultData.current.monthlyPayment.toFixed(2),
          ],
          backgroundColor: this.chartColors[0]
        },
        {
          label: IntlFormatter.formatMessage(intl, 'refinance_monthly_payment'),
          data: [
            resultData.refi.monthlyPayment.toFixed(2),
          ],
          backgroundColor: this.chartColors[1]
        }
      ],
      labels: [IntlFormatter.formatMessage(intl, 'monthly_payments')]
    };
  };

  getResultItems = (result:RefinanceCalculatorResponse) => {
    const {intl} = this.props;

    const items:CalculatorResultSetProps["items"] = [
      { color: this.chartColors[0], label: IntlFormatter.formatMessage(intl, 'current_monthly_payment'), value: Math.abs(result.current.monthlyPayment).toMoney() },
      { color: this.chartColors[1], label: IntlFormatter.formatMessage(intl, 'refinance_monthly_payment'), value: Math.abs(result.refi.monthlyPayment).toMoney() },
      { label: IntlFormatter.formatMessage(intl, result.monthlyPaymentSavings >= 0 ? 'monthly_payment_savings' : 'monthly_payment_losses'), value: Math.abs(result.monthlyPaymentSavings).toMoney() },
      { label: IntlFormatter.formatMessage(intl, result.totalBenefit >= 0 ? 'total_benefit' : 'total_losses'), value: Math.abs(result.totalBenefit).toMoney() },
    ];

    return items;
  };

  render() {

    const { intl, classes, onCancel } = this.props;
    const { form, loading, errors, result } = this.state;

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] })}} />
          <FormSection title={IntlFormatter.formatMessage(intl, 'current_loan')} className={classes.mb3}>
            <div className={classes.pv1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <MoneyInput label={IntlFormatter.formatMessage(intl, 'loan_amount')}
                              value={form.loanAmount}
                              fullWidth={true}
                              onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.loanAmount')}
                              validators={['required', 'minNumber:1']}
                              errorMessages={[
                                IntlFormatter.formatMessage(intl, 'validation_required'),
                                IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 1 })
                              ]} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Select name="term"
                          options={this.terms}
                          placeholder={IntlFormatter.formatMessage(intl, 'select_term')}
                          onChange={(item:LabelNumberType) => this.onItemChange(item ? item.value : '', 'form.currentTerm')}
                          value={form.currentTerm !== '' ? this.terms.find(m => m.value === parseInt(form.currentTerm)) : ''}
                          validators={['required']}
                          errorMessages={[
                            IntlFormatter.formatMessage(intl, 'validation_required')
                          ]}
                          label={IntlFormatter.formatMessage(intl, 'term')} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <PercentageTextField name="currentInterestRate"
                                       label={IntlFormatter.formatMessage(intl, 'interest_rate')}
                                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.currentInterestRate')} value={form.currentInterestRate} fullWidth={true}
                                       validators={['required', 'minFloat:0', 'maxFloat:100']}
                                       errorMessages={[
                                         IntlFormatter.formatMessage(intl, 'validation_required'),
                                         IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                         IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                       ]} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField name="monthsPaid"
                             label={IntlFormatter.formatMessage(intl, 'months_paid')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.monthsPaid`)}
                             value={form.monthsPaid}
                             type="number"
                             fullWidth={true}
                             validators={['required', 'minNumber:0']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required'),
                               IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                             ]}/>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField name="yearsBeforeSale"
                             label={IntlFormatter.formatMessage(intl, 'years_before_refinance')}
                             onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.yearsBeforeSale`)}
                             value={form.yearsBeforeSale}
                             type="number"
                             fullWidth={true}
                             validators={['required', 'minNumber:0', 'maxNumber:100']}
                             errorMessages={[
                               IntlFormatter.formatMessage(intl, 'validation_required'),
                               IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                               IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                             ]}/>
                </Grid>
              </Grid>
            </div>
          </FormSection>

          <FormSection title={IntlFormatter.formatMessage(intl, 'refinance_loan')}>
            <div className={classes.pv1}>
              <div className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Select name="term"
                            options={this.terms}
                            placeholder={IntlFormatter.formatMessage(intl, 'select_term')}
                            onChange={(item:LabelNumberType) => this.onItemChange(item ? item.value : '', 'form.refiTerm')}
                            value={form.refiTerm !== '' ? this.terms.find(m => m.value === parseInt(form.refiTerm)) : ''}
                            validators={['required']}
                            errorMessages={[
                              IntlFormatter.formatMessage(intl, 'validation_required')
                            ]}
                            label={IntlFormatter.formatMessage(intl, 'term')} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <PercentageTextField name="refiInterestRate"
                                         label={IntlFormatter.formatMessage(intl, 'interest_rate')}
                                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.refiInterestRate')}
                                         value={form.refiInterestRate}
                                         fullWidth={true}
                                         validators={['required', 'minFloat:0', 'maxFloat:100']}
                                         errorMessages={[
                                           IntlFormatter.formatMessage(intl, 'validation_required'),
                                           IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                           IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                         ]} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField name="points"
                               label={IntlFormatter.formatMessage(intl, 'points')}
                               onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, `form.points`)}
                               value={form.points}
                               type="number"
                               fullWidth={true}
                               validators={['required', 'minFloat:0', 'maxFloat:100']}
                               errorMessages={[
                                 IntlFormatter.formatMessage(intl, 'validation_required'),
                                 IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                 IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                               ]}/>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <PercentageTextField name="originationFees"
                                         label={IntlFormatter.formatMessage(intl, 'origination_fees')}
                                         value={form.originationFees}
                                         fullWidth={true}
                                         onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.originationFees')}
                                         validators={['required', 'minFloat:0', 'maxFloat:100']}
                                         errorMessages={[
                                           IntlFormatter.formatMessage(intl, 'validation_required'),
                                           IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                           IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                         ]} />
                  </Grid>

                </Grid>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <MoneyInput label={IntlFormatter.formatMessage(intl, 'closing_costs')}
                              value={form.closingCosts}
                              fullWidth={true}
                              onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.closingCosts')}
                              validators={['required', 'minNumber:0']}
                              errorMessages={[
                                IntlFormatter.formatMessage(intl, 'validation_required'),
                                IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 })
                              ]} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <PercentageTextField name="taxRate"
                                       label={IntlFormatter.formatMessage(intl, 'federal_tax_rate')}
                                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.taxRate')}
                                       value={form.taxRate}
                                       fullWidth={true}
                                       validators={['required', 'minFloat:0', 'maxFloat:100']}
                                       errorMessages={[
                                         IntlFormatter.formatMessage(intl, 'validation_required'),
                                         IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                         IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                       ]} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <PercentageTextField name="stateTaxRate"
                                       label={IntlFormatter.formatMessage(intl, 'state_tax_rate')}
                                       onChange={(event:ChangeEvent<{value:string}>) => this.onTextChange(event, 'form.stateTaxRate')}
                                       value={form.stateTaxRate}
                                       fullWidth={true}
                                       validators={['required', 'minFloat:0', 'maxFloat:100']}
                                       errorMessages={[
                                         IntlFormatter.formatMessage(intl, 'validation_required'),
                                         IntlFormatter.formatMessage(intl, 'validation_minvalue', { value: 0 }),
                                         IntlFormatter.formatMessage(intl, 'validation_maxvalue', { value: 100 })
                                       ]} />
                </Grid>
              </Grid>
            </div>
          </FormSection>
          <div className={classes.mt3}>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              {onCancel &&
              <Grid item>
                <Button onClick={onCancel}>
                  <FormattedMessage id="cancel" />
                </Button>
              </Grid>
              }
              <Grid item>
                <SubmitButton loading={loading}
                              size="large"
                              startIcon={<Icon>calculate</Icon>}>
                  <FormattedMessage id={result ? 'recalculate' : 'calculate'} />
                </SubmitButton>
              </Grid>
            </Grid>
          </div>
        </Form>

        <div ref={this.resultsRef}>
          {result &&
            <div className={classes.mt3}>
              <CalculatorResults summary={result.summary}
                                 results={result.results} />

            </div>
          }
        </div>
      </div>
    );
  }
}

export default withStyles(pageStyles, { withTheme: true })(injectIntl(RefinanceForm));
