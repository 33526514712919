import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {

  },
  heading: {

  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

export default styles;
