import {Location} from '@jerseydev/orca-loans';

export default class LocationUtil {
  static formatLocation = (location:Location) => {
    let formattedLocation = '';

    if(location) {
      if(location.street1) {
        formattedLocation = location.street1;
      }

      if(location.unit) {
        formattedLocation += ` ${location.unit}`;
        formattedLocation = formattedLocation.trim();
      }

      if(location.street1 || location.unit) {
        formattedLocation += " ";
      }

      if(location.city) {
        formattedLocation += `${location.city}`;
      }

      if(location.province) {
        if(location.city) {
          formattedLocation += `, ${location.province}`;
        } else {
          formattedLocation += `${location.province}`;
        }
      }

      if(location.postalCode) {
        formattedLocation += ` ${location.postalCode}`;
      }
    }

    return formattedLocation;
  }
}
