import {hexToRgb} from "../index";
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2)}px`,
  },
  title: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  content: {
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    '& .MuiTypography-root': {
      color: theme.palette.neutral.main,
      fontSize: 16
    }
  },
  action: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  link: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ", .025)",
    }
  },
  icon: {
    fontSize: 50,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  footer: {

  }
});

export default styles;
