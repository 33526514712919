import {defaultRadius} from '../index';
import {createStyles, Theme} from "@material-ui/core";

const styles = (theme:Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    maxHeight: 400,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  left: {
    display: 'inline-flex',
    flexDirection: 'row',
    '& $message': {
      marginLeft: theme.spacing(1),
      borderTopLeftRadius: defaultRadius
    }
  },
  right: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    '& $message': {
      marginRight: theme.spacing(1),
      borderTopRightRadius: defaultRadius
    },
    '& $messageInnerContainer': {
      textAlign: 'right'
    },
    '& $created': {
      marginRight: defaultRadius * 3,
    }
  },
  item: {
    display: 'flex',
    padding: theme.spacing(1),
    '& $message': {
      backgroundColor: theme.palette.neutral.extraLight,
      color: theme.palette.neutral.mainDark,
    }
  },
  itemRight: {
    justifyContent: 'flex-end',
    '& $message': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  },
  messageContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  message: {
    padding: theme.spacing(2),
    display: 'inline-block',
    fontSize: 14,
    borderRadius: defaultRadius * 3
  },
  messageInnerContainer: {

  },
  created: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: defaultRadius * 3,
    color: theme.palette.neutral.light,
    '& .MuiIcon-root': {
      fontSize: 18,
      marginRight: 5
    }
  },
  createdText: {
    fontSize: 12
  }
});

export default styles;
