import React, {Component} from 'react';
import {
  Icon,
  withStyles,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  LinearProgress,
  Button, WithStyles
} from '@material-ui/core';
import ReactDropzone, {DropzoneProps} from 'react-dropzone';
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import styles from "../theme/jss/components/dropzoneStyles";
import _ from "lodash";
import IntlFormatter from "../intl";
import {CircularIcon, ErrorList, HelperTooltip} from "./index";
import config from "../config";
import clsx from "clsx";
import {ErrorState} from "../types";

interface FileWithPreview extends File {
  preview?: string,
  tmpUrl?: string
}

type DefaultProps = {
  multiple: boolean,
  preview: boolean,
  replaceFiles: boolean,
  loading: boolean,
  condensed?: boolean
}

type Props = {
  label?: string,
  message?: string,
  onAdd: (file:FileWithPreview|FileWithPreview[]) => void,
  onRemove?: (file:FileWithPreview[]|null) => void,
  imageDimensions?: {
    width: number,
    height: number
  },
  helperText?: string
} & Partial<DefaultProps>
  & WithStyles<typeof styles>
  & WrappedComponentProps
  & DropzoneProps

type State = {
  message?: string,
  active: boolean,
  files: FileWithPreview[],
  errors: ErrorState[]
}

class Dropzone extends Component<Props, State> {
  static defaultProps:DefaultProps = {
    multiple: false,
    preview: true,
    replaceFiles: true,
    loading: false,
    condensed: false
  };

  constructor(props:Props) {
    super(props);

    this.state = {
      message: props.message ? props.message : IntlFormatter.formatMessage(this.props.intl, 'dropzone_message'),
      active: false,
      files: [],
      errors: []
    };
  }

  componentDidUpdate = (prevProps:Props) => {
    if(prevProps.message !== this.props.message) {
      this.setState({ message: this.props.message });
    }
  };

  componentWillUnmount = () => {
    if(this.state.files.length > 0) {
      this.state.files.forEach(file => {
        if(file.preview) {
          window.URL.revokeObjectURL(file.preview);
        }
      });
    }
  };

  onDragEnter = () => {
    this.setState( { active: true });
  };

  onDragLeave = () => {
    this.setState( { active: false });
  };

  onDragRejected = (files:FileWithPreview[]) => {
    const errors = [];
    const { intl, multiple } = this.props;
    if(!multiple && files.length > 1) {
      errors.push({ message: IntlFormatter.formatMessage(intl, 'validation_one_file_allowed') })
    } else {
      files.forEach(file => {
        if(file.size > config.maxUploadSize.bytes) {
          errors.push({ message: `${file.name} ${IntlFormatter.formatMessage(intl, 'validation_too_large')}` });
        } else {
          errors.push({ message: `${file.name} ${IntlFormatter.formatMessage(intl, 'validation_is_invalid')}` });
        }
      });
    }
    this.setState( { errors });
  };

  onFileDrop = (droppedFiles:FileWithPreview[]) => {
    if(droppedFiles.length === 0) {
      this.setState({ active: false, errors: [{message: IntlFormatter.formatMessage(this.props.intl, 'validation_is_invalid')}]});
    } else {
      let files = _.cloneDeep(this.state.files);
      if(this.props.replaceFiles) {
        files = droppedFiles;
      } else {
        files = [...files, ...droppedFiles];
      }
      for(let i=0;i<files.length;i++) {
        files[i].tmpUrl = URL.createObjectURL(files[i]);
      }

      this.setState({ active: false, files, errors: [] });

      if(this.props.onAdd) {
        const data = this.props.multiple ? files : files[0];
        this.props.onAdd(data);
      }
    }

  };

  removeFile = (index:number) => {
    const preview = this.state.files[index].preview;
    if(preview) {
      window.URL.revokeObjectURL(preview);
    }

    const files = _.cloneDeep(this.state.files);
    files.splice(index, 1);

    this.setState({ files });

    if(this.props.onRemove) {
      const data = this.props.multiple ? files : null;
      this.props.onRemove(data);
    }
  };

  render() {
    const { classes, accept, preview, imageDimensions, loading, label, helperText, condensed, ...rest} = this.props;
    const { files, active, message, errors } = this.state;

    if(loading) {
      return <LinearProgress />
    }

    return (
      <div>
        <div className={classes.errorContainer}>
          <ErrorList errors={errors}
                     className={classes.mv2}
                     onClose={() => { this.setState({ errors: [] }); } } />
        </div>

        {label &&
          <Typography className={classes.label} gutterBottom>
            {label}
            {helperText &&
              <HelperTooltip tooltip={helperText} />
            }
          </Typography>
        }
        <ReactDropzone
                      // @ts-ignore
                      acceptClassName={classes.dropzoneAccepted}
                       accept={accept}
                       onDrop={this.onFileDrop}
                       onDragEnter={this.onDragEnter}
                       onDragLeave={this.onDragLeave}
                       onDropRejected={this.onDragRejected}
                       maxSize={config.maxUploadSize.bytes}
                       {...rest}>
          {({getRootProps, getInputProps}) => (
            <section className={active ? clsx(classes.dropzone, classes.dropzoneActive) : classes.dropzone}>
              <div className={classes.dropzoneMessage} {...getRootProps()}>
                <input {...getInputProps()} />

                {!condensed &&
                  <div className={classes.icon}>
                    <CircularIcon icon={<Icon>{active ? 'done' : 'cloud_upload'}</Icon>}
                                  color={active ? 'success' : 'primaryAlt2'}
                                  size="large" />
                  </div>
                }

                <Typography color="inherit" className={classes.primaryText}>
                  {message}
                </Typography>
                {accept &&
                  <Typography color="inherit" className={classes.secondaryText}>
                    <FormattedMessage id="only_file_types_accepted" values={{ types: accept }} />
                  </Typography>
                }
                <Typography color="inherit" className={classes.secondaryText}>
                  <FormattedMessage id="files_must_be_less_than_size" values={{ size: config.maxUploadSize.label }} />
                </Typography>
                {imageDimensions &&
                  <Typography color="inherit" className={classes.secondaryText}>
                    <FormattedMessage id="validation_size_recommended" values={{ size: `${imageDimensions.width}x${imageDimensions.height}px` }} />
                  </Typography>
                }

                <div className={classes.or}>
                  <Typography color="inherit" className={classes.orText}>
                    <FormattedMessage id="or" />
                  </Typography>
                </div>

                <Button variant="contained" color="primary">
                  <FormattedMessage id="browse_files" />
                </Button>
              </div>
            </section>
          )}
        </ReactDropzone>
        {preview &&
          <List>
            {files.map((file, i) => {
              return (
                <ListItem key={i} className={classes.fileContainer}>
                  {file.tmpUrl && file.type.includes('image') &&
                    <img src={file.tmpUrl} alt={file.name} className={classes.imgPreview} />
                  }
                  <ListItemText primary={file.name}
                                secondary={file.type} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => this.removeFile(i)}>
                      <Icon>cancel</Icon>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        }
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(Dropzone));
