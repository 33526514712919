import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AccountPage} from '../../../layouts';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import IntlFormatter from "../../../intl";
import {
  Divider,
  Grid,
  Icon,
  IconButton, LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import pageStyles from "../../../theme/jss/layouts/pageStyles";
import {DeleteDialog, Dropzone, MessageList, PageTitle, Alert, ErrorList, NoResultsCard} from "../../../components";
import {DateUtil, ErrorUtil, ReduxUtil} from "../../../utils";
import {SecureMessageForm} from "../../../forms";
import {Link, RouteComponentProps} from "react-router-dom";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import {
  SecureMessageThreadAttachment,
  SecureMessageThread, SecureMessageThreadAttachmentRequest,
} from "@jerseydev/orca-loans";
import {
  FileWithPreview,
  ReduxSecureMessages,
  ReduxUser,
  ActionProps,
  ErrorState,
  ReduxSecureMessageThreads, ActionResponse
} from "../../../types";
import {ReduxState} from "../../../data/initialState";
import {Mixpanel} from "mixpanel-browser";
import {MessageSubscribersList} from "../components";
import {AxiosResponse} from "axios";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {getSecureMessageThread, readAllSecureMessagesInThread, addSecureMessageAttachment, deleteSecureMessageAttachment} from "../../../actions/secureMessageThreads";
import {getSecureMessages} from "../../../actions/secureMessages";

type Props = {
  mixpanel: Mixpanel,
  user: ReduxUser,
  secureMessageThreads: ReduxSecureMessageThreads,
  secureMessages: ReduxSecureMessages,
  getSecureMessageThread: ActionProps["getSecureMessageThread"],
  getSecureMessages: ActionProps["getSecureMessages"],
  readAllSecureMessagesInThread: ActionProps["readAllSecureMessagesInThread"],
  addSecureMessageAttachment: ActionProps["addSecureMessageAttachment"],
  deleteSecureMessageAttachment: ActionProps["deleteSecureMessageAttachment"]
} & WrappedComponentProps
  & WithStyles<typeof pageStyles>
  & RouteComponentProps<{id:string}, any, any>

type Attachment = {
  name: string,
  loading: boolean
}

type State = {
  loading: boolean,
  secureMessageThread: SecureMessageThread|null|undefined,
  errors: ErrorState[],
  selectedAttachment: SecureMessageThreadAttachment|null,
  deleteDialogOpen: boolean,
  deleteLoading: boolean,
  attachments: Attachment[],
  errorStatusCode: number|null,
  pageLoaded: boolean
}

class MessageDetailPage extends Component<Props, State> {
  mountRequests:ActionResponse[] = [];

  constructor(props:Props) {
    super(props);
    const secureMessageThread = ReduxUtil.hasData(props.secureMessageThreads) ? props.secureMessageThreads.data.find(m => m._id === props.match.params.id) : null;
    this.state = {
      loading: false,
      secureMessageThread: secureMessageThread ? _.cloneDeep(secureMessageThread) : null,
      errors: [],
      selectedAttachment: null,
      deleteDialogOpen: false,
      deleteLoading: false,
      attachments: [],
      errorStatusCode: null,
      pageLoaded: false
    };
  }

  componentDidMount = async () => {
    try {
      this.mountRequests = [
        this.props.getSecureMessages(this.props.match.params.id)
      ];

      if(!this.state.secureMessageThread) {
        this.mountRequests.push(this.props.getSecureMessageThread(this.props.match.params.id));
      }

      const results:AxiosResponse[] = [];
      await Promise.all(this.mountRequests.map(async (req) => {
        const result = await req.send();
        results.push(result);
      }));
      this.mountRequests = [];
      const state:any = {pageLoaded:true};
      if(results.length === 2) {
        state.secureMessageThread = Array.isArray(results[1].data) ? results[0].data : results[1].data;
      }
      this.setState(state);
    } catch (e) {
      this.setState({pageLoaded: true, errorStatusCode: e.response ? e.response.status : e.status});
    }
  };

  componentWillUnmount = async () => {
    this.mountRequests.forEach(req => {
      req.cancel('Component was unmounted');
    });
    await this.readAllMessages();
  };

  readAllMessages = async () => {
    if(this.hasUnreadMessages()) {
      try {
        await this.props.readAllSecureMessagesInThread(this.props.match.params.id).send();
        this.props.mixpanel.track("Secure message read");
      } catch (e) {
        Sentry.withScope(() => {
          Sentry.captureException(e);
        });
      }
    }
  };

  getPageTitle = () => {
    return this.state.secureMessageThread ? this.state.secureMessageThread.subject : '';
  };

  renderTitleBar = () => {
    return (
      <PageTitle title={this.getPageTitle()} icon="message" />
    )
  };

  onAddAttachment = async (files:FileWithPreview[]) => {
    this.setState({errors: []});

    for(let file of files) {
      let attachments = _.cloneDeep(this.state.attachments);
      attachments.push({ name: file.name, loading: true });
      const index = attachments.length - 1;

      try {
        this.setState({ attachments });
        if(this.state.secureMessageThread) {
          await this.props.addSecureMessageAttachment(this.state.secureMessageThread._id, {attachment:file}).send();
          this.props.mixpanel.track("Secure message attachment added");
          attachments = _.cloneDeep(this.state.attachments);
          attachments[index].loading = false;

          const secureMessageThread = this.props.secureMessageThreads.data.find(m => m._id === this.state.secureMessageThread!._id);
          this.setState({ attachments, secureMessageThread });
        }

      } catch (e) {
        attachments = _.cloneDeep(this.state.attachments);
        attachments[index].loading = false;
        const errors = [..._.cloneDeep(this.state.errors), ...ErrorUtil.formatErrors(e)];
        this.setState({ attachments, errors });
      }
    }
  };

  onDownloadAttachmentClick = async (attachment:SecureMessageThreadAttachment) => {
    this.props.mixpanel.track("Secure message attachment downloaded");
    window.open(attachment.attachment.url, '_blank');
  };

  onDeleteAttachmentClick = (attachment:SecureMessageThreadAttachment) => {
    this.setState({ selectedAttachment: _.cloneDeep(attachment), deleteDialogOpen: true });
  };

  onDeleteAttachmentCancel = () => {
    this.setState({ selectedAttachment: null, deleteDialogOpen: false });
  };

  onDeleteAttachmentConfirm = async () => {
    try {
      this.setState({ deleteLoading: true });
      if(this.state.secureMessageThread && this.state.selectedAttachment) {
        await this.props.deleteSecureMessageAttachment(this.state.secureMessageThread._id, this.state.selectedAttachment._id).send();
        this.props.mixpanel.track("Secure message attachment deleted");
        const secureMessageThread = this.props.secureMessageThreads.data.find(m => m._id === this.state.secureMessageThread!._id);
        this.setState({ secureMessageThread, selectedAttachment: null, deleteDialogOpen: false, deleteLoading: false });
      }
    } catch(err) {
      this.setState({ deleteLoading: false, errors: ErrorUtil.formatErrors(err) });
    }
  };

  onSetAllStatus = async () => {
    this.setState({ loading: true });
    try {
      await this.props.readAllSecureMessagesInThread(this.props.match.params.id).send();
      this.props.mixpanel.track("Secure message status updated");
      this.setState({ loading: false });
    } catch(e) {
      this.setState({ loading: false, errors: ErrorUtil.formatErrors(e) });
    }
  };

  hasEnabledRecipients = ():boolean => {
    const {user} = this.props;
    const {secureMessageThread} = this.state;
    if(secureMessageThread) {
      const enabledSubscribers = secureMessageThread.subscribers.filter(u => !u.deleted && u._id !== user.data._id);
      return enabledSubscribers.length > 0;
    }
    return false;
  };

  hasUnreadMessages = ():boolean => {
    let hasUnreadMessages:boolean = false;
    const {secureMessages, user} = this.props;
    if(secureMessages && secureMessages.data && secureMessages.data.length > 0) {
      hasUnreadMessages = secureMessages.data.filter(m => !m.readBy.includes(user.data.email) && m.from._id !== user.data._id).length > 0
    }
    return hasUnreadMessages
  };

  render() {
    const { intl, classes, user, secureMessages, mixpanel } = this.props;
    const { pageLoaded, loading, secureMessageThread, attachments, deleteDialogOpen, deleteLoading, selectedAttachment, errors, errorStatusCode } = this.state;

    const hasEnabledRecipients = this.hasEnabledRecipients();

    return (
      <AccountPage pageTitle={this.getPageTitle()}
                   titleBar={this.renderTitleBar()}
                   loading={loading}
                   pageLoaded={pageLoaded}
                   errorStatusCode={errorStatusCode}
                   breadcrumbs={[
                    {icon: 'dashboard', color: 'primary', to: '/dashboard' },
                    {title: IntlFormatter.formatMessage(intl, 'messages'), to: '/messages' },
                    {title: this.getPageTitle() }
                  ]}>
        <DeleteDialog open={deleteDialogOpen}
                      title={IntlFormatter.formatMessage(intl, 'delete_document')}
                      item={selectedAttachment ? selectedAttachment.attachment.name : ''}
                      loading={deleteLoading}
                      errors={errors}
                      onCancel={this.onDeleteAttachmentCancel}
                      onSubmit={this.onDeleteAttachmentConfirm} />
        <div className={classes.content}>
          <ErrorList errors={errors} className={classes.mb2} />
          {(ReduxUtil.hasData(secureMessages) && secureMessageThread) &&
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <IconButton component={Link} to="/messages">
                        <Icon>arrow_back</Icon>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">
                        <MessageSubscribersList secureMessageThread={secureMessageThread}
                                                exclude={user.data} />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <div>
                    <MessageList secureMessages={secureMessages.data} />
                    {hasEnabledRecipients &&
                      <SecureMessageForm secureMessageThread={secureMessageThread}
                                         mixpanel={mixpanel}
                                         onMessageFocus={this.readAllMessages} />
                    }
                    {!hasEnabledRecipients &&
                      <div className={classes.mt2}>
                        <Alert severity="warning">
                          <FormattedMessage id="recipients_are_no_longer_active_user" />
                        </Alert>
                      </div>
                    }
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  {hasEnabledRecipients &&
                  <div className={classes.mb2}>
                    <Dropzone label={IntlFormatter.formatMessage(intl, 'attachments')}
                              multiple={true}
                              preview={false}
                              onAdd={this.onAddAttachment}/>
                  </div>
                  }

                  {attachments.length > 0 &&
                    <List>
                      {attachments.filter(a => a.loading).map((a,i) => {
                        return (
                          <ListItem key={i} className={classes.rowCenter}>
                            <Typography>{a.name}</Typography>
                            <div className={classes.ml2} style={{ width: '100%' }}>
                              <LinearProgress />
                            </div>
                          </ListItem>
                        )
                      })}
                    </List>
                  }

                  {(secureMessageThread.attachments && secureMessageThread.attachments.length === 0 && hasEnabledRecipients) &&
                    <div className={classes.mt2}>
                      <NoResultsCard message={IntlFormatter.formatMessage(intl, 'no_attachments')}
                                     icon={<Icon>insert_drive_file</Icon>} />
                    </div>
                  }
                  {(secureMessageThread.attachments && secureMessageThread.attachments.length > 0) &&
                    <List>
                      {secureMessageThread.attachments.map((a, i) => {
                        return (
                          <ListItem key={i}>
                            <ListItemText primary={a.attachment.name} secondary={DateUtil.formatDate(a.created)}/>
                            <ListItemSecondaryAction>
                              <IconButton onClick={() => this.onDownloadAttachmentClick(a)}>
                                <Icon>save_alt</Icon>
                              </IconButton>
                              <IconButton onClick={() => this.onDeleteAttachmentClick(a)}>
                                <Icon>cancel</Icon>
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      })}
                    </List>
                  }

                </Grid>
              </Grid>
            </div>
          }
        </div>
      </AccountPage>
    );
  }
}

const mapStateToProps = (state:ReduxState) => {
  return {
    user: state.user,
    secureMessageThreads: state.secureMessageThreads,
    secureMessages: state.secureMessages
  };
};

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, AnyAction>) => ({
  getSecureMessageThread(id:string) {
    return dispatch(getSecureMessageThread(id));
  },
  getSecureMessages(threadId:string) {
    return dispatch(getSecureMessages(threadId));
  },
  readAllSecureMessagesInThread(id:string) {
    return dispatch(readAllSecureMessagesInThread(id));
  },
  addSecureMessageAttachment(id:string, attachment:SecureMessageThreadAttachmentRequest) {
    return dispatch(addSecureMessageAttachment(id, attachment));
  },
  deleteSecureMessageAttachment(secureMessageId:string, attachmentId:string) {
    return dispatch(deleteSecureMessageAttachment(secureMessageId, attachmentId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles, { withTheme: true })(injectIntl(MessageDetailPage)));
