import React from "react";
import {SecureMessageThread, User} from "@jerseydev/orca-loans";
import _ from "lodash";
import {FormattedMessage} from "react-intl";

type Props = {
  secureMessageThread: SecureMessageThread,
  exclude?: User|User[]
};

const MessageSubscribersList = (props:Props) => {
  const {secureMessageThread, exclude} = props;

  const getSubscribers = ():User[] => {
    if(exclude) {
      if(Array.isArray(exclude)) {
        return secureMessageThread.subscribers.filter(u => !_.map(exclude, '_id').includes(u._id));
      } else {
        return secureMessageThread.subscribers.filter(u => u._id !== exclude._id);
      }
    }

    return secureMessageThread.subscribers;
  };

  const names = _.map(getSubscribers(), 'fullName');

  return (
    <FormattedMessage id="conversation_with_name" values={{ name: names.join(', ') }} />
  )
};

export default MessageSubscribersList;